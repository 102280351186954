<template>
  <div>
    <b-modal
      class="dwcoincost"
      id="dwcoincost"
      :hide-footer="true"
      :hide-header="true"
      @shown="resetModal"
    >
      <div>
        <div class="mh">
          <span class="close" @click="$bvModal.hide('dwcoincost')">
            <img src="@/assets/svg/close.svg" role="button" />
          </span>
          <p class="sub-h" v-if="DWDetail.transactionType == 0">ฝากเหรียญ</p>
          <p class="sub-h" v-if="DWDetail.transactionType == 1">ถอนเหรียญ</p>
          <h2 style="margin-top: 20px">
            {{ $MyFunc.NumberFormat(DWDetail.coinAmount, 8) }}
            {{ DWDetail.coinUnit }}
          </h2>
          <p class="orange-h" v-if="DWDetail.status == 7">
            <span>
              <img src="@/assets/svg/clock-orange.svg" />
            </span>
            รอระบุต้นทุน
          </p>

          <form>
            <div class="row col-8" style="margin: auto; margin-top: 30px">
              <div class="col-6 title" style="text-align: right">
                ต้นทุนเหรียญ
              </div>
              <div class="col-6 value" style="text-align: left">
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="costRadio"
                    value="0"
                    @input="selectCost"
                    :disabled="flagSubmit"
                  />
                  <label>ระบุราคาที่ต้องการ</label>
                </div>
                <div class="form-check">
                  <input
                    class="form-check-input"
                    type="radio"
                    v-model="costRadio"
                    value="1"
                    @input="selectCost"
                    :disabled="flagSubmit"
                  />
                  <label>ใช้ราคาตลาด ณ ปัจจุบัน</label>
                </div>
                <div
                  class="form-check"
                  style="background-color: #222b2f; border-radius: 7px"
                >
                  <input
                    style="
                      width: 70%;
                      text-align: right;
                      padding: 7px;
                      color: #eee;
                    "
                    v-model="costValue"
                    :disabled="costChkValue || flagSubmit"
                    @input="validateInput"
                    placeholder="0.00"
                    type="text"
                  />
                  <span class="currency">THB</span>
                </div>
              </div>
            </div>
          </form>
        </div>

        <div class="d-box">
          <div class="row">
            <div class="col-6 title">Confirmation</div>
            <div class="col-6 value">{{ DWDetail.confirmations }}</div>
            <div class="col-6 title">Network</div>
            <div class="col-6 value">{{ DWDetail.network }}</div>
            <div class="col-6 title">หมายเลขธุรกรรม</div>
            <div class="col-6 value" style="overflow-wrap: break-word">
              {{ DWDetail.transactionId ? DWDetail.transactionId : "-" }}
            </div>
            <div class="col-6 title">วันที่</div>
            <div class="col-6 value">
              {{
                DWDetail.transactionDate < DWDetail.updatedTimestamp
                  ? getFormatDate(DWDetail.updatedTimestamp)
                  : getFormatDate(DWDetail.transactionDate)
              }}
            </div>
          </div>
        </div>

        <div
          class="row col-8"
          style="margin: auto; margin-top: 20px; margin-bottom: 30px"
        >
          <div class="row buttons-row" style="margin-top: 8px">
            <div class="col-6" style="padding-right: 8px">
              <button
                type="submit"
                class="yellow-btn text-center"
                @click="updateCost"
                :disabled="flagSubmit"
              >
                <span v-if="!flagSubmit" style="color: black">ยืนยัน</span>
                <span v-else style="color: black">กำลังส่งคำขอ ...</span>
              </button>
            </div>
            <div class="col-6" style="padding-left: 8px">
              <button
                type="button"
                class="grey-btn text-center"
                @click="costCancelModal()"
                :disabled="flagSubmit"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import moment from "moment";
import { HTTP } from "@/config/axios";
/* Socket */
var Stomp = require("stompjs");
var SockJS = require("sockjs-client");

export default {
  components: {},
  props: ["DWDetail"],
  data() {
    return {
      costRadio: 0,
      costValue: null,
      costChkValue: false,
      coinBtnDisable: true,
      flagSubmit: false,
      /* Socket */
      stompClient: null,
    };
  },
  methods: {
    updateDataTrade(coin = "BTC") {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      let self = this;
      let url = this.MARKET_WS_URL + "/market/market-ws";
      this.stompClient = Stomp.over(new SockJS(url));
      this.stompClient.debug = false;
      this.stompClient.connect({}, () => {
        this.stompClient.subscribe(
          "/topic/market/trade/" + coin + "/THB",
          function (msg) {
            const data = JSON.parse(msg.body);
            if (self.costRadio == 1 && data.length > 0) {
              self.costValue = self.$MyFunc.NumberFormat(data[0].price, self.DWDetail.bathScale);
            }
          }
        );
      });
    },
    updateCost() {
      if (this.costValue) {
        const costValueClear = parseFloat(this.costValue.replace(/,/g, ""));
        if (costValueClear > 0) {
          let self = this;
          self.flagSubmit = true;
          HTTP.post("/uc/member/set-cost-price", {
            depositCoinId: self.DWDetail.id,
            price: costValueClear,
          })
            .then((res) => {
              if (res.data.code === 0) {
                self.costCancelModal();
                self.$emit("getAllCashReports");
                self.$notify({
                  group: "notification",
                  type: "success",
                  title: "Success",
                  text: res.data.message,
                });
              } else {
                self.$notify({
                  group: "notification",
                  type: "error",
                  title: "Error",
                  text: res.data.message,
                });
              }
              self.flagSubmit = false;
            })
            .catch(function (err) {
              self.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: err,
              });
            });
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "กรุณาระบุราคาต้นทุนมากกว่า 0",
          });
        }
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "กรุณาระบุราคาต้นทุน",
        });
      }
    },
    async getSymbolThumb() {
      let self = this;
      await HTTP.post("/market/symbol-thumb", {
        emulateJSON: true,
      }).then((response) => {
        if (response.data) {
          response.data.map((item) => {
            if (item.symbol.split("/")[0] == self.DWDetail.coinUnit) {
              self.updateDataTrade(self.DWDetail.coinUnit);
              self.costValue = self.$MyFunc.NumberFormat(item.close, this.DWDetail.bathScale);
            }
          });
        } else {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: response.data.message,
          });
        }
      });
    },
    getFormatDate(date) {
      return moment(date).format(this.TIMEPICKER + " HH:mm:ss");
    },
    selectCost() {
      console.log("DWDetail=",this.DWDetail);
      this.costValue = 0;
      if (this.costRadio == 1) {
        this.costChkValue = false;
        if (this.stompClient) {
          this.stompClient.disconnect();
        }
      } else {
        this.costChkValue = true;
        this.getSymbolThumb();
      }
    },
    validateInput() {
      this.costValue = this.costValue.replace(/[^0-9.]/g, "");
    },
    costCancelModal() {
      this.$bvModal.hide("dwcoincost");
    },
    resetModal() {
      this.costRadio = 0;
      this.costValue = null;
      this.costChkValue = false;
      this.flagSubmit = false;
    },
  },
  created() {},
};
</script>
<style lang="scss">
.form-check-input:checked {
  background-color: #09BE8B !important;
  border-color: #09BE8B !important;
}

#dwcoincost___BV_modal_content_ {
  width: 600px;
}
#dwcoincost___BV_modal_body_ {
  .mh {
    text-align: center;
    p {
      margin: 0;
    }
    .sub-h {
      font-family: "Noto Sans Thai";
      font-style: normal;
      font-weight: 600;
      font-size: 16px;
      line-height: 24px;
      text-align: center;
      color: #9bacb6;
      margin-top: 40px;
    }
    h2 {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
      color: #ffffff;
    }
    .green-h {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-top: 20px;
      color: #0c9;
    }
    .red-h {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-top: 20px;
      color: #de2d40;
    }
    .orange-h {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-top: 20px;
      color: #09BE8B;
    }
    .close {
      position: absolute;
      right: 31px;
      top: 29px;
      cursor: pointer;
    }
  }
  .d-box {
    padding-left: 24px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: 500px;
    background-color: #1c262b;
    border-radius: 4px;
    margin: auto;
    margin-top: 20px;
    margin-bottom: 20px;
    .title {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #677f8e;
      padding-top: 3px;
      width: 28%;
    }
    .value {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: #d6dde1;
      padding-top: 3px;
      width: 70%;
    }
  }
}
</style>
