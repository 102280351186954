<template>
  <div class="padding-table scroller by-detail-table">
    <table class="table ma-3" id="myTable">
      <thead v-for="(item, index) in TableHeader" :key="index">
        <!-- Heading Date -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex" style="gap: 6px;">
            <div class="align-Icon-Heading">
              {{ item.Date }}
            </div>
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('time')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downDatetimes"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upDatetimes"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </div>
        </th>
        <!-- End Heading Date -->

        <!-- Heading Coin -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex" style="gap: 6px;">
            <div class="align-Icon-Heading">
              {{ item.Coin }}
            </div>
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('coinSymbol')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downCoins"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upCoins"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </div>
        </th>
        <!-- End Heading Coin -->

        <!-- Heading B/S -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex" style="gap: 6px;">
            <div class="align-Icon-Heading">
              {{ item.BS }}
            </div>
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('direction')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downSides"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upSides"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </div>
        </th>
        <!-- End Heading B/S -->

        <!-- Heading Actual -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end" style="gap: 6px;">
            <div class="align-Icon-Heading">{{ item.Actual }}</div>
            <div class="question-circle"></div>
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('amount')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downActuals"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="UpActuals"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </div>
        </th>
        <!-- End Heading Actual -->

        <!-- Heading AvrgCost -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex justify-content-end" style="gap: 6px;">
            <div class="align-Icon-Heading">{{ item.AvrgCost }}</div>
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('averageFillPrice')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downAvgCosts"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upAvgCosts"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </div>
        </th>
        <!-- End Heading AvrgCost -->

        <!-- Heading FeeVAT -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex  justify-content-end" style="gap: 6px;">
            <div class="align-Icon-Heading">
              {{ item.FeeVAT }}
            </div>
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('feeVatTotal')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downFeeVats"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upFeeVats"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </div>
        </th>
        <!-- End Heading FeeVAT -->    

      <!-- Heading Total -->
      <th scope="col" class="textGreyDark">
        <div class="d-flex justify-content-end">
          <div class="align-Icon-Heading">{{ item.Total }}</div>
        </div>
      </th>
      <!-- End Heading Total -->

        <!-- Heading ProfitLoss -->
        <th scope="col" class="textGreyDark" style="padding-right: 40px">
          <div class="d-flex justify-content-end" style="gap: 6px;">
            <div class="align-Icon-Heading">{{ item.ProfitLoss }}</div>
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('pl')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downPls"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upPls"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </div>
        </th>
        <!-- End Heading ProfitLoss -->
      </thead>
      <tbody class="mt-2">
        <tr class="textDarkgrey-Border" v-if="filteredItems?.length == 0">
          <td colspan="8" class="textGrey text-center">-- ไม่พบรายการ --</td>
        </tr>
        <tr
          class="textDarkgrey-Border"
          v-for="(item, index) in filteredItems"
          :key="index"
        >
          <!-- {{ index }}: {{ item.lastPrice }} -->

          <!-- Datetime -->
          <td style="padding-left: 5px">
            <p class="Datetime-one table_margin">
              <span class="textGrey Coin-Symbol">
                <!-- {{ item.createTime }} -->
                {{ item.datetime }}
              </span>
            </p>            
          </td>
          <!-- End Datetime -->

          <!-- CoinSymbol -->
          <td class="textGrey">
            <p class="table_margin">{{ item.coin }}</p>
          </td>
          <!-- End CoinSymbol -->

          <!-- B/S -->
          <td>
            <p :class="item.side == 'BUY' ? 'green' : 'red'" style="margin: 0; margin-top: 3px;">
            {{ item.side == 'BUY' ? 'B' : 'S' }}
            </p>
          </td>
          <!-- End B/S -->

          <!-- last ActualValue -->
          <td class="textGrey text-right">
            <p class="table_margin">{{$MyFunc.NumberFormat(item.actual,8)    }}</p>           
          </td>
          <!-- End ActualValue -->
 
          <!-- last AvrgCostValue -->
          <td class="textGrey text-right">
            <p class="table_margin">{{ $MyFunc.NumberFormat(item.avgCost, item.baseCoinScale)   }}</p>           
          </td>
          <!-- End AvrgCostValue -->

          <!-- last FeeVAT -->
          <td class="textGrey text-right">
            <p class="table_margin">{{  $MyFunc.NumberFormat(item.feeVat, 2)  }}</p>
          </td>
          <!-- End FeeVAT -->

          <!-- last TotalValue -->
          <td class="textGrey text-right">
            <p class="table_margin">{{ $MyFunc.NumberFormat(item.total, 2) }}</p>
          </td>
          <!-- End TotalValue -->

          <!-- profitloss -->
          <td class="align-bottom text-right">
            <p class="profitloss-two" style="margin:0;">
              <span class="textGrey Coin-Symbol" :class="item.pl < '0' ? 'red' : item.pl > '0' ? 'green' : '' ">
                {{ item.pl != 0 ?  $MyFunc.priceFormat($MyFunc.mathFloorPrice(item.pl, 2)) + " (" + $MyFunc.priceFormat($MyFunc.calPersent($MyFunc.mathFloorPrice(item.total, 2), $MyFunc.mathFloorPrice(item.pl, 2))) + "%)": '0.00 (0.00%)' }}
              </span>
            </p>
          </td>
          <!-- end profitloss -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "tableBydetail",
  props: [
    "TableHeader", "TableData","filteredItems"
    ,"downDatetimes"
    ,"upDatetimes"
    ,"downCoins"
    ,"upCoins"
    ,"downSides"
    ,"upSides"
    ,"downActuals"
    ,"UpActuals"
    ,"downAvgCosts"
    ,"upAvgCosts"
    ,"downFeeVats"
    ,"upFeeVats"
    ,"downPls"
    ,"upPls"
  ],
  data() {
    return {
      child_msg: true,
      pageFields: "",
      pageSorts: "desc",
    };
  },

  methods: {
    childMethod() {
      this.$emit("child-method", this.child_msg);
    },
    handleBuySell() {
      this.$router.push("/exchange");
    },
    handleDetail() {
      this.$router.push("/coin-detail");
    },
    getHistorys(secField = "datetime"){
      if(secField == this.pageFields){
        if(this.pageSorts == "asc"){
          this.pageSorts = "desc";
        }else{
          this.pageSorts = "asc";
        }
      }else{
        this.pageFields = secField;
        this.pageSorts = "desc";
      }
      this.$emit('getHistoryDetail', { pageSort: this.pageSorts, pageField:  this.pageFields });
    }
  },
};
</script>
<style lang="scss" scoped>
.table_margin{
  margin: 0px; 
  margin-top: 5px;
}
.dateChevron {
  margin-left: 5px;
  top: 3px;
  position: relative;
}
.textGreen {
  color: #0c9;
  text-align: center;
  // vertical-align: middle;
}
.textRed {
  color: #de2d40;
  text-align: center;
  // vertical-align: middle;
}
.textYellow {
  color: #09BE8B;
  cursor: pointer;
}
.textYellow:hover {
  color: #d6dde1;
}

.textGrey {
  color: rgb(255, 255, 255);
  line-height: 24px;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  // vertical-align: middle;
}
.textGreyDark {
  color: #677f8e;
  line-height: 14px;
  // vertical-align: middle;
}
.textGreyDark-table {
  color: #677f8e;
  // vertical-align: sub;
  font-size: 14px;
  font-family: "Roboto Flex";
  font-weight: 400;
  line-height: 24px;
}
.textGreyDark-description {
  color: #677f8e;
  // vertical-align: middle;
  font-family: "Sarabun";
}
.textDarkgrey-Border {
  border-color: #28363e;
}

.align-Icon-Heading {
  font-size: 12px;
  font-weight: 400;
  color: #677f8e;
  line-height: 14px;
  font-family: "Roboto Flex";
}

.Coin-Symbol {
  font-family: "Roboto Flex";
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
}
.question-circle {
  position: relative;
  margin-top: -3px;
  left: 8px;
  cursor: pointer;
}
.red {
  color: #de2d40;
}
.green {
  color: #0c9;
}
// %%%  scroller  start %%%%

.scroller {
  min-height: 200px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #192126;
}
</style>
