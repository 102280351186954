<template>
  <div>
    <b-modal
      class="del-wallet"
      id="del-wallet"
      :hide-footer="true"
      :hide-header="true"
      centered
    >
      <div>
        <h4>
          ยืนยันการลบ Whitelist
          <span
            ><svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </h4>
        <span class="d-close-btn" @click="$bvModal.hide('del-wallet')">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <p>คุณต้องการลบ Wallet “{{ selectedWallet.walletName }}” หรือไม่</p>
        <div class="dd-btns">
          <button class="o" @click="deleteWallet()">ยืนยัน</button>
          <button class="g" @click="$bvModal.hide('del-wallet')">ยกเลิก</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
export default {
  props: ["selectedWallet"],
  data() {
    return {};
  },
  methods: {
    deleteWallet() {
      let self = this;
      HTTP.post("/uc/member/remove-member-whitelist", {
        walletWhitelistId: this.selectedWallet.id,
      })
        .then((res) => {
          if (res.data.code == 0) {
            this.$emit("deleteDone", this.selectedWallet.id);
            self.$notify({
              group: "notification",
              type: "success",
              title: "Success",
              text: res.data.message,
            });
            this.$bvModal.hide("del-wallet");
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
};
</script>
<style lang="scss">
#del-wallet___BV_modal_content_ {
  height: 205px;
  width: 482.5px;
}
#del-wallet___BV_modal_body_ {
  h4 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #d6dde1;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 35px;
  }
  .d-close-btn {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 30px;
  }
  p {
    text-align: center;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
  }
  .dd-btns {
    text-align: center;
    margin-top: 19px;
    button {
      width: 140px;
      height: 40px;
      border-radius: 4px;
      margin-left: 8px;
      font-family: "Sarabun";
      font-style: normal;
      font-size: 14px;
      line-height: 24px;
    }
    button.o {
      background-color: #09BE8B;
      color: #0a0d10;
      font-weight: 500;
    }
    button.g {
      background-color: #222b2f;
      color: #ffffff;
      font-weight: 400;
    }
  }
}
</style>
