<template>
  <div class="grid order-history">
    <div class="row m-0">
      <h1 class="p-0">Order History</h1>
      <div class="menu-tabs p-0">
        <ul>
          <li
            @click="active_tab = 'Limit & Market Orders'"
            :class="active_tab === 'Limit & Market Orders' ? 'active' : ''"
          >
            Limit & Market Orders
          </li>
          <li
          v-if="!$store.state.hide_menu"
            @click="active_tab = 'Trailing Stop'"
            :class="active_tab === 'Trailing Stop' ? 'active' : ''"
          >
            Trailing Stop
          </li>
        </ul>
      </div>
    </div>
    <div v-if="active_tab === 'Limit & Market Orders'" class="row m-0">
      <div class="col-12 filter-bar p-0">
        <div class="search-input">
          <input type="text" v-model="searchTerm" placeholder="ค้นหาเหรียญ" />
          <span><img src="@/assets/svg/search.svg"></span>
        </div>
        <div class="filter-buttons">
          <button class="btn-all"
            @click="activeBtn = 99"
            :class="activeBtn == 99 ? 'active' : ''"
          >
            ทั้งหมด
          </button>
          <button class="btn-buy"
            @click="activeBtn = 0"
            :class="activeBtn == 0 ? 'active' : ''"
          >
            ซื้อ
          </button>
          <button class="btn-sell"
            @click="activeBtn = 1"
            :class="activeBtn == 1 ? 'active' : ''"
          >
            ขาย
          </button>
        </div>
        <div class="select-status">
          <div class="second-selectbox">
            <div
              class="select" tabindex="0"
              @click="open_dropdown = !open_dropdown"
              @blur="open_dropdown = false"
            >
              <span class="text" style="left: 15px">{{ $MyFunc.recHistory(current_status) }}</span>
              <span class="icon" :class="open_dropdown ? 'rotate-sc-icon' : ''">
                <img src="@/assets/svg/arrow-down.svg">
              </span>
              <div class="options" v-show="open_dropdown">
                <ul>
                  <li @click="current_status = '99'">All Status</li>
                  <li @click="current_status = '1'">Match</li>
                  <li @click="current_status = '2'">Canceled</li>
                  <li @click="current_status = '0'">Pending</li>
                  <li @click="current_status = '4'">Rejected</li>
                  <li @click="current_status = '3'">Overtimed</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-date-picker">
          <date-picker
            :lang="lang"
            v-model="historyTime"
            :placeholder="historyTimeplaceholder"
            :editable="false"
            :format=TIMEPICKER
            range
            style="width: 221px"
            popup-class="date-picker-style"
            :show-week-number="true"
            @change="historyData()"
          >
            <template v-slot:icon-calendar>
              <img src="@/assets/svg/calendar.svg" role="button">
            </template>
          </date-picker>
        </div>
        <div class="export-button">
          <button @click="exportToExcel">Export to Excel</button>
        </div>
      </div>
      <div class="col-md-12 table-responsive p-0 m-0" style="overflow: auto">
        <table class="table table-borderless text-start p-0" id="myTable">
          <thead>
            <tr>
              <th scope="col">
                Date <img src="@/assets/svg/arrow.svg" @click="historyData('time')" role="button">
              </th>
              <th style="padding-right: 20px" scope="col">B/S</th>
              <th style="padding-right: 20px" scope="col">Coin</th>
              <th scope="col">
                Type <img src="@/assets/svg/arrow.svg" @click="historyData('type')" role="button">
              </th>
              <th scope="col">
                Status
              </th>
              <th scope="col" class="text-end">
                Price <img src="@/assets/svg/arrow.svg" @click="historyData('averageFillPrice')" role="button">
              </th>
              <th scope="col" class="text-end">
                Volume <img src="@/assets/svg/arrow.svg" @click="historyData('netQuantity')" role="button">
              </th>
              <th scope="col" class="text-end">
                Fee + VAT <img src="@/assets/svg/arrow.svg" @click="historyData('feeVatTotal')" role="button">
              </th>
              <th scope="col" class="text-end" style="padding-right: 15px">
                Total <img src="@/assets/svg/arrow.svg" @click="historyData('totalIncome')" role="button">
              </th>
              <th style="padding-left: 30px" scope="col">
                Conditions
              </th>
            </tr>
          </thead>

          <tbody v-if="isUserLogin">
            <!-- skaleton start -->
            <template v-if="pageLoading">
              <tr v-for="(item, index) in 10" :key="index + 'e'">
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
                <td>
                  <b-skeleton class="skc custom-skeleton-wave" animation="wave" width="80%"></b-skeleton>
                </td>
              </tr>
            </template>
            <!-- skaleton end -->
            <template v-else>
              <template v-if="filteredOrderHistory.length == 0">
                <tr>
                  <td colspan="10" class="text-center">-- ไม่มีข้อมูล --</td>
                </tr>
              </template>
              <template v-else>
                <tr v-for="(order, i) in filteredOrderHistory" :key="i">
                  <td>
                    {{
                      String(new Date(order.time).getDate()).padStart(2, "0")
                    }}/{{
                      String(new Date(order.time).getMonth() + 1).padStart(
                        2,
                        "0"
                      )
                    }}/{{ String(new Date(order.time).getFullYear()) }}
                    <span>
                      {{
                        String(new Date(order.time).getHours()).padStart(2, "0")
                      }}:{{
                        String(new Date(order.time).getMinutes()).padStart(2, "0")
                      }}
                    </span>
                  </td>
                  <td style="padding-right: 20px">
                    <span :class="order.direction == 0 ? 'green' : 'red'">
                      {{ $MyFunc.BuySellDirection(order.direction,true) }}
                    </span>
                  </td>
                  <td style="padding-right: 20px">{{ order.coinSymbol }}</td>
                  <td>
                    {{ $MyFunc.BuySellType(order.type) }}
                  </td>
                  <td :class="$MyFunc.recHistoryColor(order.status)">
                    {{ $MyFunc.recHistory(order.status) }}
                  </td>
                  <td class="text-end">
                    {{ order.status == 1 ? $MyFunc.NumberFormat(order.averageFillPrice, order.baseCoinScale) : $MyFunc.NumberFormat(order.price, order.baseCoinScale) }}
                  </td>
                  <td class="text-end">
                    {{ $MyFunc.NumberFormat(order.netQuantity, order.coinScale) }}
                  </td>
                  <td class="text-end">
                    {{ $MyFunc.NumberFormat(order.feeVatTotal) }} 
                  </td>
                  <td class="text-end" style="padding-right: 15px">
                    {{ $MyFunc.NumberFormat(order.totalIncome) }}
                  </td>
                  <td style="padding-left: 30px">
                    {{ getLimitMarketCondition(order,order.baseCoinScale) }}
                  </td>
                </tr>
              </template>
            </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="10"></td>
            </tr>
            <tr>
              <td colspan="10" style="text-align: center; color: #677f8e"> 
                Please <a href="/login" style="color:rgb(9 190 139)">Login</a> or <a href="/register" style="color:rgb(9 190 139)">Register</a> Now to Trade
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div v-if="active_tab === 'Trailing Stop'" class="row m-0">
      <div class="col-12 filter-bar p-0">
        <div class="search-input">
          <input
            type="text"
            v-model="trailingSearchTerm"
            placeholder="ค้นหาเหรียญ"
          />
          <span><img src="@/assets/svg/search.svg"></span>
        </div>
        <div class="filter-buttons">
          <button
            @click="trailingActiveBtn = 'ALL'"
            class="btn-all"
            :class="trailingActiveBtn == 'ALL' ? 'active' : ''"
          >
            ทั้งหมด
          </button>
          <button
            @click="trailingActiveBtn = 'BUY'"
            class="btn-buy"
            :class="trailingActiveBtn == 'BUY' ? 'active' : ''"
          >
            ซื้อ
          </button>
          <button
            @click="trailingActiveBtn = 'SELL'"
            class="btn-sell"
            :class="trailingActiveBtn == 'SELL' ? 'active' : ''"
          >
            ขาย
          </button>
        </div>
        <div class="select-status">
          <div class="second-selectbox">
            <div
              class="select"
              tabindex="0"
              @click="open_dropdown = !open_dropdown"
              @blur="open_dropdown = false"
            >
              <span class="text" style="left: 15px">{{
                trailingCurrentStatus
              }}</span>
              <span class="icon" :class="open_dropdown ? 'rotate-sc-icon' : ''">
                <img src="@/assets/svg/arrow-down.svg">
              </span>
              <div class="options" v-show="open_dropdown">
                <ul>
                  <li @click="trailingCurrentStatus = 'All Status'">
                    All Status
                  </li>
                  <li @click="trailingCurrentStatus = 'Matched'">
                    Matched
                  </li>
                  <li @click="trailingCurrentStatus = 'Cancel'">
                    Cancel
                  </li>
                  <li @click="trailingCurrentStatus = 'Open'">
                    Open
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div class="filter-date-picker">
          <date-picker
            :lang="lang"
            v-model="trailingHistoryTime"
            :placeholder="historyTimeplaceholder"
            :editable="false"
            :format=TIMEPICKER
            range
            style="width: 221px"
            popup-class="date-picker-style"
            :show-week-number="true"
          >
            <template v-slot:icon-calendar>
              <img src="@/assets/svg/calendar.svg" role="button">
            </template>
          </date-picker>
        </div>
        <div class="export-button">
          <button @click="exportToExcelTrailing">Export to Excel</button>
        </div>
      </div>
      <div class="col-md-12 table-responsive p-0" style="overflow: auto">
        <table class="table table-borderless text-start p-0" id="myTable">
          <thead>
            <tr>
              <th scope="col">
                Date <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                B/S <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Coin <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Status <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Start Price (THB) <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Trailing Cond. <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Price <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Volume <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Fee + VAT <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Total <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Conditions <img src="@/assets/svg/arrow.svg" @click="sortTable(0)" role="button">
              </th>
              <th scope="col">
                Expired Date
              </th>
            </tr>
          </thead>
          <tbody v-if="isUserLogin">
            <template v-if="filteredTrailingHistory.length == 0">
                <tr>
                  <td colspan="12" class="text-center">-- ไม่มีข้อมูล --</td>
                </tr>
            </template>
            <template v-else>
            <tr v-for="(order, index) in filteredTrailingHistory" :key="index">
              <td>
                {{ creationDate(order.creationDate) }}
                <span>{{ creationTime(order.creationDate) }}</span>
              </td>

              <td>
                <span class="green" v-if="order.direction == '0'"> B </span>
                <span class="red" v-else> S </span>
              </td>
              <td>{{ order.symbol.split("/")[0] }}</td>
              <td>
                <span v-if="order.status == '0'"> Open </span>
                <span v-else-if="order.status == '1'"> Activated</span>
                <span v-else-if="order.status == '2'"> Matched </span>
                <span v-else-if="order.status == '3'"> Cancel </span>
                <span v-else-if="order.status == '4'"> Overtimed </span>
                <span v-else-if="order.status == '5'" class="red"> Rejected</span>
                <span v-else> EXPIRED </span>
              </td>
              <td>{{ $MyFunc.NumberFormat(order.triggerPrice) }}</td>
              <td>
                <span
                  v-if="order.trailingStep"
                  style="color: rgb(255, 255, 255)"
                  v-text="order.direction == '0' ? '+' : '-'"
                ></span>
                <span v-if="order.trailingStepType == 0" style="color: rgb(255, 255, 255);margin-left:0 !important;">
                  {{ $MyFunc.NumberFormat(order.trailingStep) }}
                </span>
                <span v-else style="color: rgb(255, 255, 255);margin-left:0 !important;">
                  {{ $MyFunc.NumberFormat(order.trailingStep) }}%
                </span>
              </td>

              <td>{{ $MyFunc.NumberFormat(order.basePrice) }}</td>
              <td>{{ $MyFunc.NumberFormat(order.amount) }}</td>
              <td>{{ $MyFunc.NumberFormat(order.fee + order.vat) }}</td>
              <td>{{ $MyFunc.NumberFormat(order.total) }}</td>
              <td>{{ getConditions(order) }}</td>
              <td v-if="order.expirationDate">
                {{ exactDifference(order.expirationDate) }} ({{
                  formatExpirationDate(order.expirationDate)
                }})
              </td>
              <td v-else></td>
            </tr>
          </template>
          </tbody>
          <tbody v-else>
            <tr>
              <td colspan="12"></td>
            </tr>
            <tr>
              <td colspan="12" style="text-align: center; color: #677f8e">
                Please
                <a href="/login">Login</a>
                or
                <a :href="REGISTER_URL">Register Now</a>
                to Trade
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
var XLSX = require("xlsx");
import { HTTP } from "@/config/axios";
import moment from "moment";
export default {
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      open_dropdown: false,
      current_status: 99,
      trailingCurrentStatus: "All Status",
      trailingCurrentStatus_num: 99,
      historyTime: null,
      historyTimeplaceholder: null,
      trailingHistoryTime: [0,0],
      active_tab: "Limit & Market Orders",
      activeBtn: 99,
      trailingActiveBtn: "ALL",
      trailingActiveBtn_num: 99,
      order_history: [],
      trailingHistory: [],
      searchTerm: "",
      trailingSearchTerm: "",
      isUserLogin: false,
      pageLoading: false,
      pageField: "",
      pageSort: "desc",
    };
  },
  methods: {
    getHistoryTime(){
      let D1 = moment(new Date()).add(-1, "M");
      let D2 = moment(new Date());
      this.historyTime = [D1, D2];
      this.historyTimeplaceholder = moment(this.historyTime[0]).format(this.TIMEPICKER) + " ~ " + moment(this.historyTime[1]).format(this.TIMEPICKER);
    },
    getConditions(item) {
      if (item.profitPrice > 0 && item.stopLossPrice > 0) {
        return `TP: ${this.$MyFunc.NumberFormat(item.profitPrice)}, SL: ${this.$MyFunc.NumberFormat(item.stopLossPrice)}`;
      } else if (item.profitPrice > 0 && !item.stopLossPrice > 0) {
        return `TP: ${this.$MyFunc.NumberFormat(item.profitPrice)}`;
      } else if (item.stopLossPrice > 0 && !item.profitPrice > 0) {
        return `SL: ${this.$MyFunc.NumberFormat(item.stopLossPrice)}`;
      } else if (!item.stopLossPrice > 0 && !item.profitPrice > 0) {
        return "-";
      }
    },
    getLimitMarketCondition(item,baseCoinScale) {
      if ( item.takeprofit > 0 && item.takeprofitPercent
         && item.stoploss > 0 && item.stoplossPercent ) {

        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit,baseCoinScale)}
              (+${item.takeprofitPercent}%) 
              | SL: ${this.$MyFunc.NumberFormat(item.stoploss,baseCoinScale)}
              (-${item.stoplossPercent}%)`;

      }else if(item.takeprofit > 0 && item.takeprofitPercent 
              && item.stoploss > 0 && !item.stoplossPercent){

        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit,baseCoinScale)}
                (+${item.takeprofitPercent}%) 
                | SL: ${this.$MyFunc.NumberFormat(item.stoploss,baseCoinScale)}`; 

      }else if(item.takeprofit > 0 && !item.takeprofitPercent 
              && item.stoploss > 0 && item.stoplossPercent){

        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit,baseCoinScale)}
                | SL: ${this.$MyFunc.NumberFormat(item.stoploss,baseCoinScale)}
                (-${item.stoplossPercent}%)`; 

      }else if(item.takeprofit > 0 && !item.takeprofitPercent 
              && item.stoploss > 0 && !item.stoplossPercent){

        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit,baseCoinScale)}
                | SL: ${this.$MyFunc.NumberFormat(item.stoploss,baseCoinScale)}`; 

      }else if (item.takeprofit > 0 && item.takeprofitPercent) {
        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit,baseCoinScale)}(+${
          item.takeprofitPercent
        }%)`;
      } else if (item.takeprofit > 0 && !item.takeprofitPercent) {
        return `TP: ${this.$MyFunc.NumberFormat(item.takeprofit,baseCoinScale)}`;
      } else if (item.stoploss > 0 && !item.stoplossPercent) {
        return `SL: ${this.$MyFunc.NumberFormat(item.stoploss,baseCoinScale)}`;
      } else if (item.stoploss > 0 && item.stoplossPercent) {
        return ` SL: ${this.$MyFunc.NumberFormat(item.stoploss,baseCoinScale)}(-${
          item.stoplossPercent
        }%)`;
      } else if (!item.stoploss > 0 && !item.takeprofit > 0) {
        return "-";
      }
    },
    creationDate(timestamp) {
      return moment.unix( timestamp/1000 ).format(this.TIMEPICKER); // this.TIMEPICKER
    },
    creationTime(timestamp) {
      return moment.unix(timestamp).format("HH:mm");
    },
    exactDifference(timestamp) {
      const expirationDate = moment.unix(timestamp);
      const now = moment();
      const daysDifference = Math.abs(expirationDate.diff(now, "days"));
      const monthsDifference = Math.abs(expirationDate.diff(now, "months"));
      if (monthsDifference > 0) {
        return `${monthsDifference} month${monthsDifference > 1 ? "s" : ""}`;
      }
      return `${daysDifference} day${daysDifference > 1 ? "s" : ""}`;
    },
    formatExpirationDate(timestamp) {
      return moment.unix(timestamp).add(1, "months").format(this.TIMEPICKER);
    },
    exportToExcel() {
      const OrderHistory = this.filteredOrderHistory.map(order => ({
        "Date": String(new Date(order.time).getDate()).padStart(2, "0") + '/' +
                String(new Date(order.time).getMonth() + 1).padStart(2, "0") + '/' +
                String(new Date(order.time).getFullYear()) + ' ' +
                String(new Date(order.time).getHours()).padStart(2, "0") + ':' +
                String(new Date(order.time).getMinutes()).padStart(2, "0"),
        "Coin": order.coinSymbol,
        "B/S":  this.$MyFunc.BuySellDirection(order.direction,true),
        "Type": this.$MyFunc.BuySellType(order.type),
        "Status": this.$MyFunc.recHistory(order.status),
        "Price": {
          v: order.status == 1 ? this.$MyFunc.NumberFormat(order.averageFillPrice, order.baseCoinScale) : this.$MyFunc.NumberFormat(order.price, order.baseCoinScale),
          t: 'n',
          //z: '#,##0.00'
          z: '#,##0.' + '0'.repeat(order.baseCoinScale)
        },
        "Volume": {
          v: this.$MyFunc.NumberFormat(order.netQuantity, 8),
          t: 'n',
          z: '#,##0.00000000'
        },
        "Fee + VAT":  {
          v: this.$MyFunc.NumberFormat(order.feeVatTotal),
          t: 'n',
          z: '#,##0.00'
        },
        "Total": {
          v: this.$MyFunc.NumberFormat(order.totalIncome),
          t: 'n',
          z: '#,##0.00'
        }, 
        "Conditions": this.getLimitMarketCondition(order), 
      }));
      /* create a new empty workbook */
      const wb = XLSX.utils.book_new();
      /* convert your array of objects to a worksheet */
      const ws = XLSX.utils.json_to_sheet(OrderHistory);
      /* add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, "Orders");
      /* save the workbook to an Excel file */
      XLSX.writeFile(wb, "orders.xlsx");
    },
    exportToExcelTrailing() {
      const TrailingHistory = this.filteredTrailingHistory.map(order => ({
        "Date": String(new Date(order.time).getDate()).padStart(2, "0") + '/' +
                String(new Date(order.time).getMonth() + 1).padStart(2, "0") + '/' +
                String(new Date(order.time).getFullYear()) + ' ' +
                String(new Date(order.time).getHours()).padStart(2, "0") + ':' + 
                String(new Date(order.time).getMinutes()).padStart(2, "0"),
        "B/S": order.direction == '0' ? 'B' : 'S',
        "Coin": order.symbol.split("/")[0],
        "Status": order.status == '0' ? 'Open' :
                  order.status == '1' ? 'Activated' :
                  order.status == '2' ? 'Matched' :
                  order.status == '3' ? 'Cancel' :
                  order.status == '4' ? 'Overtimed' :
                  order.status == '5' ? 'Rejected' : 'EXPIRED',
        "Start Price (THB)": this.$MyFunc.NumberFormat(order.triggerPrice),
        "Trailing Cond.": order.trailingStep ?
        `${order.direction == '0' ? '+' : '-'} ${
          order.trailingStepType == 0 ?
            this.$MyFunc.NumberFormat(order.trailingStep) :
            this.$MyFunc.NumberFormat(order.trailingStep) + " %"
          }` : '',
        "Price": this.$MyFunc.NumberFormat(order.basePrice),
        "Volume": this.$MyFunc.NumberFormat(order.amount),
        "Fee + VAT": this.$MyFunc.NumberFormat(order.fee + order.vat),
        "Total": this.$MyFunc.NumberFormat(order.total),
        "Conditions": this.getConditions(order),
        "Expired Date": order.expirationDate ? this.exactDifference(order.expirationDate) + this.formatExpirationDate(order.expirationDate) : '',
      }));
      /* create a new empty workbook */
      const wb = XLSX.utils.book_new();
      /* convert your array of objects to a worksheet */
      const ws = XLSX.utils.json_to_sheet(TrailingHistory);
      /* add the worksheet to the workbook */
      XLSX.utils.book_append_sheet(wb, ws, "Orders");
      /* save the workbook to an Excel file */
      XLSX.writeFile(wb, "orders.xlsx");
    },
    historyData(secField="time") {
      this.pageLoading = true;
      if(secField == this.pageField){
        if(this.pageSort == "asc"){
          this.pageSort = "desc";
        }else{
          this.pageSort = "asc";
        }
      }else{
        this.pageField = secField;
        this.pageSort = "desc";
      }
      if(!this.historyTime[0] || !this.historyTime[1]){
        this.getHistoryTime();
      }
      HTTP.post("/exchange/order/personal/history", {
        startDate: moment(this.historyTime[0]).format("YYYY-MM-DD")+"T00:00:00",
        endDate: moment(this.historyTime[1]).format("YYYY-MM-DD")+"T23:59:59",
        field: this.pageField, sort: this.pageSort
      })
        .then((res) => {
          this.order_history = res.data.content;
          this.pageLoading = false;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getTrailingData() {
      HTTP.post("/exchange/order/get-all-exchange-order-condition", {
        type: "TPLS",
      })
        .then((res) => {
          this.trailingHistory = res.data.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // calPersentTrailingCond(triggerStep, amount){
    //   return this.$MyFunc.NumberFormat((triggerStep*100)/amount);
    // },
  },
  computed: {
    filteredOrderHistory() {
      const searchTerm = this.searchTerm.toLowerCase().trim();
      let filtered = this.order_history.filter((order) => {
        let isStatusMatch = this.current_status == 99 || order.status == this.current_status;
        let isDirectionMatch = this.activeBtn == 99 || order.direction == this.activeBtn;
        let isSymbolMatch = order.symbol && order.symbol.toLowerCase().startsWith(searchTerm);
        return isStatusMatch && isDirectionMatch && isSymbolMatch;
      });
      return filtered;
    },
    filteredTrailingHistory() {
      console.log("trailingActiveBtn=",this.trailingActiveBtn);
      const searchTerm = this.trailingSearchTerm.toLowerCase().trim();
      if(this.trailingCurrentStatus == 'Matched'){
        this.trailingCurrentStatus_num = 2;
      }else if(this.trailingCurrentStatus == 'Cancel'){
        this.trailingCurrentStatus_num = 3;
      }else if(this.trailingCurrentStatus == 'Open'){
        this.trailingCurrentStatus_num = 0;
      }

      if(this.trailingActiveBtn == 'BUY'){
        this.trailingActiveBtn_num = 0;
      }else if(this.trailingActiveBtn == 'SELL'){
        this.trailingActiveBtn_num = 1;
      }
      let filtered = this.trailingHistory.filter((order) => {
        const isStatusMatch = this.trailingCurrentStatus === "All Status" || order.status === this.trailingCurrentStatus_num;
        const isDirectionMatch = this.trailingActiveBtn == "ALL" || order.direction == this.trailingActiveBtn_num;
        const isSymbolMatch = order.symbol && order.symbol.toLowerCase().startsWith(searchTerm);
        return isStatusMatch && isDirectionMatch && isSymbolMatch;
      });
      return filtered;
    },
  },
  created() {
    this.getHistoryTime();
    // check if user if login
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
      this.historyData();
      this.getTrailingData();
    }
  },
  watch: {
    // data_chart(value) {
    //   console.log(value)
    // },
  },
};
</script>
<style scoped>
.select {
  background: #191b1c;
  border-radius: 4px;
  width: 142px;
  height: 40px;
}

.select .options {
  position: absolute;
  top: 42px;
  z-index: 1;
  width: 100%;
  background-color: rgb(34, 43, 47);
  border-radius: 4px;
}

.select .options ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}

.select .options ul li {
  padding: 8px;
  cursor: pointer;
}

.select .options ul li:hover {
  background-color: #191b1c;
}

.order-history {
  padding: 24px 25px 24px 26px !important;
}

.order-history table {
  border-spacing: 0px 24px;
  border-collapse: separate;
  margin-top: 0px;
}

.order-history table th svg {
  cursor: pointer;
  margin-left: 2px;
}

.order-history table th:nth-child(1) {
  padding-left: 0px !important;
}

.order-history table th {
  text-align: left;
  padding: 0px;
}

.order-history table td {
  padding: 0px;
}

.order-history table td:nth-child(1) {
  padding-left: 0px !important;
}
</style>
<style lang="scss">
.filter-bar {
  display: flex;
  align-items: flex-end;
  flex-wrap: wrap;
  padding-top: 15px;
}

::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #28363e;

  border-radius: 20px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #3c515d;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(255, 255, 255);
  border-radius: 20px;
}

.mx-input-wrapper .mx-input {
  background-color: #191b1c !important;
  color: rgb(255, 255, 255);
  border: 1px solid #191b1c;
  height: 40px;
  line-height: 24px;
  &::placeholder {
    color: rgb(255, 255, 255) !important;
  }
}

.mx-input-wrapper .mx-input:hover {
  border: 1px solid #191b1c !important;
}

.mx-icon-calendar,
.mx-icon-clear {
  color: #677f8e !important;
  display: flex !important;
  align-items: center !important;
}

.order-history {
  color: rgb(255, 255, 255);

  // white-space: nowrap;
  h1 {
    font-size: 24px;
    font-weight: 500;
    line-height: 24px;
    margin-bottom: 18px;
  }

  .menu-tabs ul {
    padding: 0;
    border-bottom: 1px solid #28363e;

    li {
      display: inline-block;
      color: #677f8e;
      font-size: 16px;
      line-height: 19px;
      margin-right: 20px;
      padding: 10px 0;
      cursor: pointer;
    }

    li.active {
      color: rgb(255, 255, 255);
      border-bottom: 2px solid #09BE8B;
    }
  }

  .search-input {
    position: relative;
    display: inline;
    margin-right: 15px;

    input {
      line-height: 24px;
      width: 142px;
      height: 40px;
      padding-left: 40px;
      padding-right: 5px;
      border-radius: 4px;
      background-color: #191b1c;
      color: white;

      &::placeholder {
        font-family: "sarabun";
        color: #566a76;
      }
    }

    span {
      position: absolute;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
      pointer-events: none;
    }
  }

  .filter-buttons {
    display: inline;
    margin-right: 15px;

    button {
      line-height: 24px;
      color: #9bacb6;
      width: 73px;
      height: 40px;
      margin-left: 1px;
      background-color: #191b1c;
    }

    button.active {
      color: white;
      background-color: #38454d;
    }

    .btn-all {
      border-radius: 4px 0px 0px 4px;
    }

    .btn-buy {
      background-color: #191b1c;
    }

    .btn-sell {
      border-radius: 0px 4px 4px 0px;
    }
  }

  .select-status {
    display: inline-block;
    position: relative;
    margin-right: 15px;
    cursor: pointer;

    select {
      width: 142px;
      height: 40px;
      background-color: #191b1c;
      border-radius: 4px;
      color: rgb(255, 255, 255);
      padding: 0 15px;
    }

    span {
      position: absolute;
      right: 15px;
      margin-top: 10px;
    }
  }

  .filter-date-picker {
    display: inline-block;
    position: relative;
    margin-right: 40px;
  }

  .export-button {
    display: inline-block;

    button {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      width: 124px;
      height: 40px;
      color: #677f8e;
      border-radius: 4px;
      border: 1px solid #28363e;

      &:active {
        border: 1px solid white;
        color: white;
        background-color: #38454d;
      }
    }
  }

  table {
    width: 100%;
    margin-top: 10px;

    th {
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      /* identical to box height */

      /* Text + Icon/Content - Sub#2, Icon */

      color: #677f8e;

      svg {
        // margin-left: 4px;
      }
    }

    tr td span {
      color: #677f8e;
      margin-left: 5px;
    }

    tr td {
      // line-height: 30px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    input {
      background: #191b1c;
      border: 1px solid #3c515d;
      border-radius: 2px;
      height: 17px;
      width: 17px;
    }

    input:checked {
      background-color: #09BE8B;
      border: 1px solid #3c515d;
    }

    td {
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      color: rgb(255, 255, 255);
      vertical-align: middle;
    }

    .green {
      color: #0c9;
    }

    .red {
      color: #f72000;
    }

    .orange {
      color: #09BE8B;
      font-weight: 400;
      font-family: "sarabun";
      cursor: pointer;
    }

    button {
      color: rgb(255, 255, 255);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      padding: 8px 16px;
      background: #38454d;
      border-radius: 4px;
      border: none;
    }

    button:hover {
      color: rgb(255, 255, 255);
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      padding: 8px 16px;
      background: #38454d;
      border-radius: 4px;
      border: none;
    }
  }
}

@media only screen and (min-width: 100px) {
  .select-status {
    margin-top: 10px;
  }

  .filter-date-picker {
    margin-top: 10px;
  }

  .export-button {
    margin-top: 10px;
  }

  .search-input {
    margin-top: 10px;
  }

  .filter-buttons {
    margin-top: 10px;
  }
}

@media only screen and (min-width: 1024px) {
  .select-status {
    margin-top: 0;
  }

  .filter-date-picker {
    margin-top: 0;
  }

  .export-button {
    margin-top: 0;
  }

  .search-input {
    margin-top: 0;
  }

  .filter-buttons {
    margin-top: 0;
  }

  .order-history .mx-input-wrapper .mx-input {
    // margin-top: 15px;
    margin-left: 10px;
  }
}

.date-picker-style {
  background-color: #191b1c;
  color: white;
}

.mx-calendar-content .cell.in-range,
.cell:hover {
  background-color: #141b1f !important;
}

.mx-calendar-content .cell.active {
  background-color: #09BE8B !important;
}

.mx-calendar-content .cell.hover-in-range {
  background-color: #141b1f !important;
}

.mx-table-date .cell.not-current-month {
  background-color: #191b1c !important;
  // color: #38454d;
}

.text-right {
  text-align: right !important;
}
</style>