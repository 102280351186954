import Vue from "vue";
import App from "./App.vue";
import router from "./config/routes.js";
import store from "./config/store.js";
import { MyFunc } from "./config/MyFormatFunction.js";

Vue.prototype.$MyFunc = MyFunc;
Vue.prototype.MARKET_WS_URL = process.env.VUE_APP_API_URL;
Vue.prototype.KNOWLEDGE_QUIZ_URL = process.env.VUE_APP_KNOWLEDGE_URL;
Vue.prototype.REGISTER_URL = process.env.VUE_APP_REGISTER_URL;
Vue.prototype.TIMEPICKER = "DD/MM/YYYY";
Vue.prototype.CALLCENTER = "02-088-4688";
Vue.prototype.VERSION = "v3.09";

import vueResource from "vue-resource";
import "@fortawesome/fontawesome-free/css/all.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./assets/css/style.fda.css";
import VueProgressBar from "vue-progressbar";
import DatePicker from "vue2-datepicker";
import "vue2-datepicker/index.css";
import "vue2-datepicker/locale/en.js";
import { vMaska } from "maska";

Vue.component("date-picker", DatePicker);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueProgressBar, {
  color: "#09BE8B",
  failedColor: "red",
  height: "2px",
});
import Cleave from "vue-cleave-component";
Vue.use(Cleave);
import HighchartsVue from "highcharts-vue";
import Highcharts from "highcharts";
import highchartsMore from "highcharts/highcharts-more";
import seriesLable from "highcharts/modules/series-label";
seriesLable(Highcharts);
highchartsMore(Highcharts);
Vue.use(HighchartsVue);
import ToggleButton from "vue-js-toggle-button";
Vue.use(ToggleButton);
import Notifications from "vue-notification";
Vue.use(Notifications);
import VueEllipseProgress from "vue-ellipse-progress";
Vue.use(VueEllipseProgress);
import VModal from "vue-js-modal";
import ReviewKYCModel from "./pages/ProfileSetting/ReviewKYCModel.vue";
import PasswordAlertModal from "@/pages/ProfileSetting/PasswordAlertModal.vue";
Vue.use(VModal);
Vue.component("ReviewKYCModel", ReviewKYCModel);
Vue.component("PasswordAlertModal", PasswordAlertModal);

import VueApexCharts from "vue-apexcharts";
import Verte from "verte";
import "verte/dist/verte.css";
import VueMask from "v-mask";

Vue.use(VueMask);
Vue.use(VueApexCharts);
Vue.component("apexchart", VueApexCharts);
Vue.component(Verte.name, Verte);
Vue.use(vueResource);
Vue.directive("maska", vMaska);
let previousPath = '';

router.beforeEach((to, from, next) => {
  previousPath = from.path;
  next();
});

Vue.prototype.$previousPath = () => previousPath;
new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount("#app");