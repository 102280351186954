<template>
  <div>
    <div >
      <div class="cp-header">
      <div class="row top-search-row pt-4">
        <div
          class="col-12 col-md-2 col-xl-3"
          style="padding-left: 15px !important; width: 250px"
        >
        <div class="position-relative search-container">
                    <div>
                      <span class="search-svg">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                            fill="#677F8E"
                          />
                        </svg>
                      </span>
                      <span v-if="!showSearchBox" class="coin-image">
                        <img
                          v-if="filteredCoinsImages?.logoFileId"
                          :src="filteredCoinsImages.logoFileId"
                          width="24"
                        />
                      </span>
                      <div
                        ref="coinDropdown"
                        class="td"
                        tabindex="0"
                        @focusin="
                          showSearchBox = true;
                          showBtcText = false;
                        "
                        @blur="showSearchBox = false"
                      >
                        <input
                          v-model="coinSearchText"
                          class="ml-4 coins-search-input text-uppercase btc-text-true"
                          :class="
                            showBtcText ? 'btc-text-true' : 'btc-text-false'
                          "
                          type="text"
                          :placeholder="showBtcText ? coinName + ' / THB' : ''"
                          maxlength="10"
                          @blur="setCoinText()"
                          @keydown.enter="handleEnterKey"
                        />
                        <div v-if="showSearchBox" class="searching-box">
                          <p class="search-title">ค้นหา</p>
                          <div
                            @click="
                              showBtcText = true;
                              coinSearchText = '';
                            "
                          >
                            <div
                              class="row"
                              v-for="(item, index) in filteredCoins"
                              :key="index"
                              @click="selectMarketSymbol(item.url.toString())"
                            >
                              <div class="col-2" style="width: 12%">
                                <img
                                  v-if="item.logoFileId"
                                  :src="item.logoFileId"
                                  width="24"
                                  style="margin-left: 7px"
                                />
                              </div>
                              <div class="col-5" style="width: 44%">
                                <p class="coin-text">
                                  {{ item.symbol.split("/")[0]
                                  }}<span class="pair-text"
                                    >/ {{ item.symbol.split("/")[1] }}</span
                                  >
                                </p>
                                <p class="vol">
                                  Vol.
                                  {{
                                    $MyFunc.NumberFormat(
                                      item.volume,
                                      currentScale.fixScale
                                    )
                                  }}M
                                </p>
                              </div>
                              <div class="col-5 text-right" style="width: 44%">
                                <p class="price">
                                  {{
                                    $MyFunc.NumberFormat(
                                      item.price,
                                      item.bathScale
                                    )
                                  }}
                                </p>
                                <p :class="item.color + ' percent'">
                                  {{ $MyFunc.PercentChg(item.pChg) }}%
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
        </div>
        <div class="col-12 col-md-7 col-xl-6 my-auto trading-scores">
          <span class="live-amount-title red" :class="priceInfo.textColor">
            {{
                      $MyFunc.NumberFormat(
                        priceInfo.last,
                        currentScale.bathScale
                      )
                    }}
          </span>
          <span class="live-amount-value red" :class="priceInfo.textColor">
            ({{ $MyFunc.PercentChg(priceInfo.pChg) }}%)
          </span>
          <span class="title">24h High:</span>
          <span class="value">{{
                    $MyFunc.NumberFormat(
                      priceInfo.high24,
                      currentScale.bathScale
                    )
                  }}</span>
                  <span class="title">24h Low:</span>
                  <span class="value">{{
                    $MyFunc.NumberFormat(
                      priceInfo.low24,
                      currentScale.bathScale
                    )
                  }}</span>
                  <span class="title">24h Volume:</span>
                  <span class="value">{{
                    $MyFunc.NumberFormat(priceInfo.vol24, currentScale.fixScale)
                  }}</span>
                </div>
        <div class="col-12 col-md-3 col-xl-3 tsrb">
          <button
            @click="$router.push('/exchange/' + defaultPath)"
            class="green"
          >
            ซื้อ
          </button>
          <button @click="$router.push('/exchange/' + defaultPath)" class="red">
            ขาย
          </button>
        </div>
      </div>
    </div>
    <div class="chart-topbar-page row m-0">
      <div class="col-6 p-0">
        <div
          class="ctype-sc"
          tabindex="0"
          @click="show_chart_type_options = !show_chart_type_options"
          @blur="show_chart_type_options = false"
        >
          <span class="text" :class="selected_chart_type_option"></span>
          <span class="icon"
            ><svg
              width="7"
              height="5"
              viewBox="0 0 7 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                fill="#677F8E"
              />
            </svg>
          </span>
          <div
            class="options"
            :class="show_chart_type_options ? 'show' : 'hidden'"
          >
            <ul>
              <li
                @click="
                  selected_chart_type_option = 'hollow-candles';
                  changeChartType('candle');
                "
              >
                Candlestick <span class="chart-icon hollow-candles"></span>
              </li>
              <li
                @click="
                  selected_chart_type_option = 'line';
                  changeChartType('line');
                "
              >
                Line <span class="chart-icon line"></span>
              </li>
              <li
                @click="
                  selected_chart_type_option = 'mountain';
                  changeChartType('mountain');
                "
              >
                Area <span class="chart-icon mountain"></span>
              </li>
            </ul>
          </div>
        </div>
        <div class="timeframe">
          <button
            :class="active_timeframe === '15m' ? 'active' : ''"
            @click="
              active_timeframe = '15m';
              changeTime('15', '');
            "
          >
            15m
          </button>
          <button
            :class="active_timeframe === '30m' ? 'active' : ''"
            @click="
              active_timeframe = '30m';
              changeTime('30', '');
            "
          >
            30m
          </button>
          <button
            :class="active_timeframe === '1hr' ? 'active' : ''"
            @click="
              active_timeframe = '1hr';
              changeTime('1', 'h');
            "
          >
            1hr
          </button>
          <button
            :class="active_timeframe === '4hr' ? 'active' : ''"
            @click="
              active_timeframe = '4hr';
              changeTime('4', 'h');
            "
          >
            4hr
          </button>
          <button
            :class="active_timeframe === '1d' ? 'active' : ''"
            @click="
              active_timeframe = '1d';
              changeTime('1', 'd');
            "
          >
            1d
          </button>
          <div
            class="tf-sc"
            tabindex="0"
            @click="
              (show_timeline_options = !show_timeline_options),
                (active_timeframe = null)
            "
            @blur="show_timeline_options = false"
          >
            <span
              class="text"
              :class="{
                active: show_timeline_options,
                active: !active_timeframe,
              }"
            >
              {{ selected_timeline_option }}
            </span>
            <span class="icon"
              ><svg
                width="7"
                height="5"
                viewBox="0 0 7 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <div
              class="options"
              :class="show_timeline_options ? 'show' : 'hidden'"
            >
              <ul>
                <li
                  @click="
                    selected_timeline_option = '1 m';
                    changeTime('1', '');
                  "
                >
                  1 m
                </li>
                <li
                  @click="
                    selected_timeline_option = '5 m';
                    changeTime('5', '');
                  "
                >
                  5 m
                </li>
                <li
                  @click="
                    selected_timeline_option = '10 m';
                    changeTime('10', '');
                  "
                >
                  10 m
                </li>
                <!-- <li
                  @click="
                    selected_timeline_option = '15 m';
                    changeTime('15', '');
                  "
                >
                  15 m
                </li>
                <li
                  @click="
                    selected_timeline_option = '30 m';
                    changeTime('30', '');
                  "
                >
                  30 m
                </li>
                <li
                  @click="
                    selected_timeline_option = '1 hr';
                    changeTime('1', 'h');
                  "
                >
                  1 hr
                </li> -->
                <li
                  @click="
                    selected_timeline_option = '2 hr';
                    changeTime('2', 'h');
                  "
                >
                  2 hr
                </li>
                <!-- <li
                  @click="
                    selected_timeline_option = '4 hr';
                    changeTime('4', 'h');
                  "
                >
                  4 hr
                </li>
                <li
                  @click="
                    selected_timeline_option = '1 day';
                    changeTime('1', 'd');
                  "
                >
                  1 day
                </li> -->
                <li
                  @click="
                    selected_timeline_option = '1 week';
                    changeTime('1', 'w');
                  "
                >
                  1 week
                </li>
                <li
                  @click="
                    selected_timeline_option = '1 mon';
                    changeTime('1', 'm');
                  "
                >
                  1 month
                </li>
                <li
                  @click="
                    selected_timeline_option = '1 year';
                    changeTime('1', 'y');
                  "
                >
                  1 year
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="ic-sc"
          v-if="selected_chart_category_option != 'Depth Chart'"
          tabindex="0"
          @click="show_indicator_options = !show_indicator_options"
          @blur="show_indicator_options = false"
        >
          <span
            @click="iaddIndicators()"
            class="text"
            :class="show_indicator_options ? 'active' : ''"
            >{{ selected_indicator_option }}</span
          >
          <span class="icon"
            ><svg
              width="7"
              height="5"
              viewBox="0 0 7 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
        <div
          class="ic-sc"
          style="width: 108px"
          tabindex="0"
          @click="show_chart_category_options = !show_chart_category_options"
          @blur="show_chart_category_options = false"
        >
          <span
            class="text"
            :class="show_chart_category_options ? 'active' : ''"
            >{{ selected_chart_category_option }}</span
          >
          <span class="icon"
            ><svg
              width="7"
              height="5"
              viewBox="0 0 7 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                fill="#677F8E"
              />
            </svg>
          </span>
          <div
            class="options"
            :class="show_chart_category_options ? 'show' : 'hidden'"
          >
            <ul>
              <li @click="change_chart('finansia')">BITBULL Chart</li>
              <li @click="change_chart('deepchart')">Depth Chart</li>
            </ul>
          </div>
        </div>
      </div>
      <div class="col-6 p-0" style="display: flex; justify-content: end">
        <span class="toc-btn">
          <button @click="show_trade_on_chart = !show_trade_on_chart">
            Trade on Chart
          </button>
        </span>
        <!-- <span class="ct-alert" @click="handelAlertPopup()">
          <svg
            width="12"
            height="15"
            viewBox="0 0 12 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M4.67333 13.0067C4.67333 13.74 5.26667 14.3333 6 14.3333C6.73333 14.3333 7.32667 13.74 7.32667 13.0067H4.67333ZM6 3C7.84 3 9.33333 4.49333 9.33333 6.33333V11H2.66667V6.33333C2.66667 4.49333 4.16 3 6 3ZM6 0C5.44667 0 5 0.446667 5 1V1.78C2.90667 2.23333 1.33333 4.1 1.33333 6.33333V10.3333L0 11.6667V12.3333H12V11.6667L10.6667 10.3333V6.33333C10.6667 4.1 9.09333 2.23333 7 1.78V1C7 0.446667 6.55333 0 6 0Z"
              fill="#677F8E"
            />
          </svg>
          Alert
        </span> -->
        <span class="favorite" @click="selectFav(currentCoin.symbol)">
          <svg
            v-if="favCoinRows == true"
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M 13.6663 5.7876 L 8.873 5.3743 L 6.9997 0.9609 L 5.1263 5.3809 L 0.333 5.7876 L 3.973 8.9409 L 2.8797 13.6276 L 6.9997 11.1409 L 11.1197 13.6276 L 10.033 8.9409 L 13.6663 5.7876 Z Z"
              fill="rgb(255, 255, 255)"
            />
          </svg>
          <svg
            v-else
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.6693 5.23438L8.87594 4.82105L7.0026 0.407715L5.12927 4.82771L0.335938 5.23438L3.97594 8.38772L2.8826 13.0744L7.0026 10.5877L11.1226 13.0744L10.0359 8.38772L13.6693 5.23438ZM7.0026 9.34105L4.49594 10.8544L5.1626 8.00105L2.94927 6.08105L5.86927 5.82771L7.0026 3.14105L8.1426 5.83438L11.0626 6.08772L8.84927 8.00771L9.51594 10.861L7.0026 9.34105Z"
              fill="#677F8E"
            />
          </svg>
          <button>Favorite</button>
        </span>
        <!-- <span
          class="save"
          style="padding: 0; text-align: center; padding-top: 5px"
          ><button>Save</button></span
        > -->
        <span class="capture" @click="saveChartImage()">
          <svg
            width="16"
            height="14"
            viewBox="0 0 16 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M8.0744 11.074C9.00032 11.074 9.78749 10.75 10.4359 10.1021C11.0838 9.45375 11.4077 8.66659 11.4077 7.74066C11.4077 6.81473 11.0838 6.02757 10.4359 5.37918C9.78749 4.73128 9.00032 4.40733 8.0744 4.40733C7.14847 4.40733 6.36131 4.73128 5.71292 5.37918C5.06502 6.02757 4.74107 6.81473 4.74107 7.74066C4.74107 8.66659 5.06502 9.45375 5.71292 10.1021C6.36131 10.75 7.14847 11.074 8.0744 11.074ZM8.0744 9.59251C7.55588 9.59251 7.11761 9.4135 6.75958 9.05547C6.40156 8.69745 6.22255 8.25918 6.22255 7.74066C6.22255 7.22214 6.40156 6.78387 6.75958 6.42585C7.11761 6.06782 7.55588 5.88881 8.0744 5.88881C8.59292 5.88881 9.03119 6.06782 9.38921 6.42585C9.74724 6.78387 9.92625 7.22214 9.92625 7.74066C9.92625 8.25918 9.74724 8.69745 9.38921 9.05547C9.03119 9.4135 8.59292 9.59251 8.0744 9.59251ZM2.14847 13.6666C1.74107 13.6666 1.39242 13.5216 1.10255 13.2318C0.812177 12.9414 0.666992 12.5925 0.666992 12.1851V3.29622C0.666992 2.88881 0.812177 2.54017 1.10255 2.25029C1.39242 1.95992 1.74107 1.81473 2.14847 1.81473H4.48181L5.85218 0.333252H10.2966L11.667 1.81473H14.0003C14.4077 1.81473 14.7566 1.95992 15.047 2.25029C15.3369 2.54017 15.4818 2.88881 15.4818 3.29622V12.1851C15.4818 12.5925 15.3369 12.9414 15.047 13.2318C14.7566 13.5216 14.4077 13.6666 14.0003 13.6666H2.14847ZM14.0003 12.1851V3.29622H11.0003L9.64847 1.81473H6.50032L5.14847 3.29622H2.14847V12.1851H14.0003Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <span class="expand" @click="chartFullScreen">
          <svg
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <mask
              id="mask0_3353_2144"
              style="mask-type: alpha"
              maskUnits="userSpaceOnUse"
              x="0"
              y="0"
              width="16"
              height="16"
            >
              <rect width="16" height="16" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_3353_2144)">
              <path
                d="M8.81613 13.226C8.81613 12.8002 9.14951 12.4519 9.55696 12.4519H11.4757L8.8163 9.82018C8.52738 9.51831 8.52738 9.03066 8.8163 8.72879C9.10523 8.42691 9.57196 8.42691 9.86088 8.72879L12.5203 11.3605V9.35576C12.5203 8.93004 12.8537 8.58172 13.2611 8.58172C13.6686 8.58172 14.002 8.93004 14.002 9.35576V13.226C14.002 13.6517 13.6686 14 13.2611 14H9.55696C9.14951 14 8.81613 13.6517 8.81613 13.226Z"
                fill="#677F8E"
              />
              <path
                d="M7.18485 2.77404C7.18485 3.19976 6.85147 3.54808 6.44401 3.54808H4.52524L7.18467 6.17982C7.4736 6.48169 7.4736 6.96934 7.18467 7.27121C6.89575 7.57309 6.42902 7.57309 6.1401 7.27121L3.48067 4.63948V6.64424C3.48067 7.06996 3.14729 7.41828 2.73983 7.41828C2.33237 7.41828 1.999 7.06996 1.999 6.64424V2.77404C1.999 2.34832 2.33237 2 2.73983 2H6.44401C6.85147 2 7.18485 2.34832 7.18485 2.77404Z"
                fill="#677F8E"
              />
            </g>
          </svg>
        </span>
        <span
          class="setting"
          @click="
            $bvModal.show('settings-modal');
            getCheckStatusModel();
            closeModalIndicators();
          "
          ><svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M11.7617 7.62701C11.7884 7.42701 11.8017 7.22034 11.8017 7.00034C11.8017 6.78701 11.7884 6.57367 11.755 6.37367L13.1084 5.32034C13.2284 5.22701 13.2617 5.04701 13.1884 4.91367L11.9084 2.70034C11.8284 2.55368 11.6617 2.50701 11.515 2.55367L9.9217 3.19367C9.58837 2.94034 9.23503 2.72701 8.8417 2.56701L8.6017 0.873675C8.57503 0.713675 8.4417 0.600342 8.2817 0.600342H5.7217C5.5617 0.600342 5.43503 0.713675 5.40837 0.873675L5.16837 2.56701C4.77503 2.72701 4.41503 2.94701 4.08837 3.19367L2.49503 2.55367C2.34837 2.50034 2.1817 2.55368 2.1017 2.70034L0.828367 4.91367C0.748367 5.05367 0.775034 5.22701 0.908367 5.32034L2.2617 6.37367C2.22837 6.57367 2.2017 6.79367 2.2017 7.00034C2.2017 7.20701 2.21503 7.42701 2.24837 7.62701L0.895034 8.68034C0.775034 8.77367 0.741701 8.95367 0.815034 9.08701L2.09503 11.3003C2.17503 11.447 2.3417 11.4937 2.48837 11.447L4.0817 10.807C4.41503 11.0603 4.76837 11.2737 5.1617 11.4337L5.4017 13.127C5.43503 13.287 5.5617 13.4003 5.7217 13.4003H8.2817C8.4417 13.4003 8.57503 13.287 8.59503 13.127L8.83503 11.4337C9.22837 11.2737 9.58837 11.0603 9.91503 10.807L11.5084 11.447C11.655 11.5003 11.8217 11.447 11.9017 11.3003L13.1817 9.08701C13.2617 8.94034 13.2284 8.77367 13.1017 8.68034L11.7617 7.62701ZM7.0017 9.40034C5.6817 9.40034 4.6017 8.32034 4.6017 7.00034C4.6017 5.68034 5.6817 4.60034 7.0017 4.60034C8.3217 4.60034 9.4017 5.68034 9.4017 7.00034C9.4017 8.32034 8.3217 9.40034 7.0017 9.40034Z"
              fill="#677F8E"
            />
          </svg>
        </span>
      </div>
    </div>

    <iframe
      id="myIframec"
      v-if="selected_chart_category_option === 'Bitbull Chart'"
      :src="chartSymbolUrl"
      style="width: 100%"
    ></iframe>
    <!-- src="/libchart/index.php?symbol=BTC%2FTHB&uid=1&user=1" -->
    <div class="cc" v-if="selected_chart_category_option === 'Depth Chart'">
      <apexchart
        style="padding-left: 40px"
        type="area"
        :height="700"
        :options="chartOptions"
        :series="series"
      ></apexchart>
      <div class="cbar">
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M15.511 5.17991C15.294 4.96296 14.9423 4.96296 14.7253 5.17991C14.5084 5.39687 14.5084 5.74861 14.7253 5.96556C14.9423 6.18251 15.294 6.18251 15.511 5.96556C15.7279 5.74861 15.7279 5.39686 15.511 5.17991ZM16.2966 4.39426C15.6458 3.74341 14.5905 3.74341 13.9397 4.39426C13.2888 5.04512 13.2888 6.10036 13.9397 6.75121C14.5905 7.40207 15.6458 7.40207 16.2966 6.75121C16.9475 6.10036 16.9475 5.04512 16.2966 4.39426Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M6.08325 14.6076C5.8663 14.3907 5.51456 14.3907 5.2976 14.6076C5.08065 14.8246 5.08065 15.1763 5.2976 15.3933C5.51456 15.6102 5.8663 15.6102 6.08325 15.3933C6.30021 15.1763 6.30021 14.8246 6.08325 14.6076ZM6.8689 13.822C6.21805 13.1711 5.16281 13.1711 4.51195 13.822C3.8611 14.4729 3.8611 15.5281 4.51195 16.1789C5.16281 16.8298 6.21805 16.8298 6.8689 16.1789C7.51976 15.5281 7.51976 14.4729 6.8689 13.822Z"
              fill="#677F8E"
            />
            <path
              d="M6.47559 15L5.68994 14.2144L14.3321 5.5722L15.1177 6.35785L6.47559 15Z"
              fill="#677F8E"
            />
          </svg>
        </div>
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.2222 9.9994C17.2222 9.69259 16.9734 9.44386 16.6666 9.44386C16.3598 9.44386 16.1111 9.69259 16.1111 9.9994C16.1111 10.3062 16.3598 10.5549 16.6666 10.5549C16.9734 10.5549 17.2222 10.3062 17.2222 9.9994ZM18.3332 9.9994C18.3332 9.07895 17.5871 8.33279 16.6666 8.33279C15.7462 8.33279 15 9.07895 15 9.9994C15 10.9198 15.7462 11.666 16.6666 11.666C17.5871 11.666 18.3332 10.9198 18.3332 9.9994Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3.88914 9.9994C3.88914 9.69259 3.64042 9.44386 3.33361 9.44386C3.02679 9.44386 2.77807 9.69259 2.77807 9.9994C2.77807 10.3062 3.02679 10.5549 3.33361 10.5549C3.64042 10.5549 3.88915 10.3062 3.88914 9.9994ZM5.00022 9.9994C5.00022 9.07895 4.25405 8.33279 3.33361 8.33279C2.41316 8.33279 1.66699 9.07895 1.66699 9.9994C1.66699 10.9198 2.41316 11.666 3.33361 11.666C4.25405 11.666 5.00022 10.9198 5.00022 9.9994Z"
              fill="#677F8E"
            />
            <path
              d="M3.88965 10.5547L3.88965 9.44361L16.1115 9.44361L16.1115 10.5547L3.88965 10.5547Z"
              fill="#677F8E"
            />
          </svg>
        </div>
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.9994 2.77785C9.69259 2.77785 9.44386 3.02657 9.44386 3.33339C9.44386 3.6402 9.69259 3.88892 9.9994 3.88892C10.3062 3.88892 10.5549 3.6402 10.5549 3.33339C10.5549 3.02657 10.3062 2.77785 9.9994 2.77785ZM9.9994 1.66677C9.07895 1.66677 8.33279 2.41294 8.33279 3.33339C8.33279 4.25383 9.07895 5 9.9994 5C10.9198 5 11.666 4.25383 11.666 3.33339C11.666 2.41294 10.9198 1.66677 9.9994 1.66677Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M9.9994 16.1109C9.69259 16.1109 9.44386 16.3596 9.44386 16.6664C9.44386 16.9732 9.69259 17.2219 9.9994 17.2219C10.3062 17.2219 10.5549 16.9732 10.5549 16.6664C10.5549 16.3596 10.3062 16.1109 9.9994 16.1109ZM9.9994 14.9998C9.07895 14.9998 8.33279 15.7459 8.33279 16.6664C8.33279 17.5868 9.07895 18.333 9.9994 18.333C10.9198 18.333 11.666 17.5868 11.666 16.6664C11.666 15.7459 10.9198 14.9998 9.9994 14.9998Z"
              fill="#677F8E"
            />
            <path
              d="M10.5557 16.1113L9.44459 16.1113L9.44459 3.88949L10.5557 3.88949L10.5557 16.1113Z"
              fill="#677F8E"
            />
          </svg>
        </div>
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6057 4.13372C11.2927 4.13372 11.039 4.38743 11.039 4.70039C11.039 5.01335 11.2927 5.26706 11.6057 5.26706C11.9186 5.26706 12.1723 5.01335 12.1723 4.70039C12.1723 4.38743 11.9186 4.13372 11.6057 4.13372ZM11.6057 3.00038C10.6668 3.00038 9.90566 3.7615 9.90566 4.70039C9.90566 5.63927 10.6668 6.40039 11.6057 6.40039C12.5445 6.40039 13.3057 5.63927 13.3057 4.70039C13.3057 3.7615 12.5445 3.00038 11.6057 3.00038Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M11.6066 17.7333C11.2937 17.7333 11.04 17.987 11.04 18.3C11.04 18.613 11.2937 18.8667 11.6066 18.8667C11.9196 18.8667 12.1733 18.613 12.1733 18.3C12.1733 17.987 11.9196 17.7333 11.6066 17.7333ZM11.6066 16.6C10.6678 16.6 9.90663 17.3611 9.90663 18.3C9.90663 19.2389 10.6678 20 11.6066 20C12.5455 20 13.3066 19.2389 13.3066 18.3C13.3066 17.3611 12.5455 16.6 11.6066 16.6Z"
              fill="#677F8E"
            />
            <path
              d="M12.1729 17.7324L11.0395 17.7324L11.0395 5.26573L12.1728 5.26573L12.1729 17.7324Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M4.13274 11.0535C4.13274 11.3665 4.38645 11.6202 4.69941 11.6202C5.01237 11.6202 5.26608 11.3665 5.26608 11.0535C5.26608 10.7406 5.01237 10.4869 4.69941 10.4869C4.38645 10.4869 4.13274 10.7406 4.13274 11.0535ZM2.99941 11.0535C2.99941 11.9924 3.76053 12.7535 4.69941 12.7535C5.6383 12.7535 6.39941 11.9924 6.39941 11.0535C6.39941 10.1146 5.6383 9.35352 4.69941 9.35352C3.76053 9.35352 2.99941 10.1146 2.99941 11.0535Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.7333 11.0535C17.7333 11.3665 17.987 11.6202 18.3 11.6202C18.613 11.6202 18.8667 11.3665 18.8667 11.0535C18.8667 10.7406 18.613 10.4869 18.3 10.4869C17.987 10.4869 17.7333 10.7406 17.7333 11.0535ZM16.6 11.0535C16.6 11.9924 17.3611 12.7535 18.3 12.7535C19.2389 12.7535 20 11.9924 20 11.0535C20 10.1146 19.2389 9.35352 18.3 9.35352C17.3611 9.35352 16.6 10.1146 16.6 11.0535Z"
              fill="#677F8E"
            />
            <path
              d="M17.7334 10.4863L17.7334 11.6197L5.26671 11.6197L5.26671 10.4863L17.7334 10.4863Z"
              fill="#677F8E"
            />
          </svg>
        </div>
        <div>
          <svg
            width="20"
            height="23"
            viewBox="0 0 20 23"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.9271 2.63564C17.6912 2.39979 17.3088 2.39979 17.0729 2.63564C16.8371 2.87149 16.8371 3.25389 17.0729 3.48974C17.3088 3.7256 17.6912 3.7256 17.9271 3.48974C18.1629 3.25389 18.1629 2.87149 17.9271 2.63564ZM18.7812 1.78154C18.0736 1.07398 16.9264 1.07398 16.2188 1.78154C15.5113 2.4891 15.5113 3.63628 16.2188 4.34385C16.9264 5.05141 18.0736 5.05141 18.7812 4.34385C19.4887 3.63628 19.4887 2.4891 18.7812 1.78154Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.67705 12.8856C7.4412 12.6498 7.0588 12.6498 6.82295 12.8856C6.58709 13.1215 6.58709 13.5039 6.82295 13.7397C7.0588 13.9756 7.4412 13.9756 7.67705 13.7397C7.9129 13.5039 7.9129 13.1215 7.67705 12.8856ZM8.53115 12.0315C7.82359 11.324 6.67641 11.324 5.96884 12.0315C5.26128 12.7391 5.26128 13.8863 5.96885 14.5938C6.67641 15.3014 7.82359 15.3014 8.53115 14.5938C9.23872 13.8863 9.23872 12.7391 8.53115 12.0315Z"
              fill="#677F8E"
            />
            <path
              d="M8.10449 13.3125L7.25039 12.4584L16.6455 3.06327L17.4996 3.91737L8.10449 13.3125Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M17.9271 10.0126C17.6912 9.77674 17.3088 9.77674 17.0729 10.0126C16.8371 10.2484 16.8371 10.6308 17.0729 10.8667C17.3088 11.1026 17.6912 11.1026 17.9271 10.8667C18.1629 10.6308 18.1629 10.2484 17.9271 10.0126ZM18.7812 9.15849C18.0736 8.45093 16.9264 8.45093 16.2188 9.15849C15.5113 9.86605 15.5113 11.0132 16.2188 11.7208C16.9264 12.4284 18.0736 12.4284 18.7812 11.7208C19.4887 11.0132 19.4887 9.86605 18.7812 9.15849Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M7.67705 20.2606C7.4412 20.0248 7.0588 20.0248 6.82295 20.2606C6.58709 20.4965 6.58709 20.8789 6.82295 21.1147C7.0588 21.3506 7.4412 21.3506 7.67705 21.1147C7.9129 20.8789 7.9129 20.4965 7.67705 20.2606ZM8.53115 19.4065C7.82359 18.699 6.67641 18.699 5.96884 19.4065C5.26128 20.1141 5.26128 21.2613 5.96885 21.9688C6.67641 22.6764 7.82359 22.6764 8.53115 21.9688C9.23872 21.2613 9.23872 20.1141 8.53115 19.4065Z"
              fill="#677F8E"
            />
            <path
              d="M8.10449 20.6875L7.25039 19.8334L16.6455 10.4383L17.4996 11.2924L8.10449 20.6875Z"
              fill="#677F8E"
            />
          </svg>
        </div>
        <div>
          <svg
            width="28"
            height="21"
            viewBox="0 0 28 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M8.37499 8.35342C8.09384 8.19109 7.73432 8.28742 7.572 8.56858C7.40967 8.84973 7.506 9.20925 7.78716 9.37157C8.06831 9.5339 8.42783 9.43757 8.59015 9.15641C8.75248 8.87525 8.65615 8.51574 8.37499 8.35342ZM8.96283 7.33526C8.11936 6.84828 7.04081 7.13727 6.55384 7.98074C6.06686 8.82421 6.35585 9.90276 7.19932 10.3897C8.04279 10.8767 9.12134 10.5877 9.60831 9.74425C10.0953 8.90078 9.8063 7.82223 8.96283 7.33526Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.91828 17.5702C2.61473 17.395 2.22659 17.499 2.05133 17.8025C1.87608 18.1061 1.98008 18.4942 2.28363 18.6695C2.58718 18.8447 2.97533 18.7407 3.15058 18.4372C3.32583 18.1336 3.22183 17.7455 2.91828 17.5702ZM3.55293 16.471C2.64229 15.9452 1.47785 16.2572 0.952088 17.1679C0.426327 18.0785 0.738337 19.243 1.64898 19.7687C2.55963 20.2945 3.72407 19.9825 4.24983 19.0718C4.77559 18.1612 4.46358 16.9968 3.55293 16.471Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M13.7161 17.5702C13.4126 17.395 13.0244 17.499 12.8492 17.8025C12.6739 18.1061 12.7779 18.4942 13.0815 18.6695C13.385 18.8447 13.7732 18.7407 13.9484 18.4372C14.1237 18.1336 14.0197 17.7455 13.7161 17.5702ZM14.3508 16.471C13.4401 15.9452 12.2757 16.2572 11.7499 17.1679C11.2242 18.0785 11.5362 19.243 12.4468 19.7687C13.3575 20.2945 14.5219 19.9825 15.0477 19.0718C15.5734 18.1612 15.2614 16.9968 14.3508 16.471Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M2.6748 16.8985L7.07281 9.42188L8.15906 10.0608L9.24532 9.42188L13.6433 16.8985L12.3504 17.659L8.15906 10.5337L3.96771 17.659L2.6748 16.8985Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22 2H13V1H22V2Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22 5H13V4H22V5Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22 8H13V7H22V8Z"
              fill="#677F8E"
            />
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M22 11H13V10H22V11Z"
              fill="#677F8E"
            />
            <g clip-path="url(#clip0_12_44707)">
              <path
                d="M24.297 12.295L26.587 10L24.297 7.705L25.002 7L28.002 10L25.002 13L24.297 12.295Z"
                fill="#677F8E"
              />
            </g>
            <defs>
              <clipPath id="clip0_12_44707">
                <rect
                  width="4"
                  height="12"
                  fill="white"
                  transform="translate(24 4)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div>
          <svg
            width="28"
            height="20"
            viewBox="0 0 28 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.2006 2.50127C13.128 2.50127 13.058 2.5276 13.0016 2.58338L3.90018 11.6729C3.86515 11.708 3.84013 11.7513 3.82762 11.7952L2.50953 16.5428C2.482 16.6411 2.50953 16.7464 2.58084 16.8185C2.63464 16.8712 2.70596 16.9 2.77853 16.9C2.80355 16.9 2.82983 16.8962 2.85673 16.8887L7.59355 15.5412C7.62545 15.5325 7.65986 15.5124 7.69927 15.4773C7.74056 15.4403 7.74619 15.4359 7.71804 15.4654L7.73368 15.4491L7.7512 15.4359C7.7806 15.4128 7.78748 15.4065 7.77872 15.4146L16.8164 6.40705C16.8696 6.3544 16.899 6.28295 16.899 6.20774C16.899 6.13379 16.8702 6.06171 16.8171 6.00844L13.3976 2.58148C13.3419 2.5257 13.2731 2.5 13.1999 2.5L13.2006 2.50127ZM13.1987 3.17816L16.2215 6.20657L14.9547 7.46758L11.9332 4.44174L12.9823 3.39383L13.1987 3.17816ZM11.5359 4.83781L12.8734 6.17716L5.85943 13.2055L4.51194 11.8524L11.3195 5.05411L11.5359 4.83781ZM13.27 6.57512L14.5561 7.86244L7.52345 14.8763L6.25604 13.6034L13.0547 6.79201L13.27 6.57512ZM4.24602 12.3813L6.98229 15.1302L3.18248 16.2101L4.1052 12.8871L4.24602 12.3813Z"
              fill="#677F8E"
            />
            <g clip-path="url(#clip0_12_44710)">
              <path
                d="M24.297 12.295L26.587 10L24.297 7.705L25.002 7L28.002 10L25.002 13L24.297 12.295Z"
                fill="#677F8E"
              />
            </g>
            <defs>
              <clipPath id="clip0_12_44710">
                <rect
                  width="4"
                  height="12"
                  fill="white"
                  transform="translate(24 4)"
                />
              </clipPath>
            </defs>
          </svg>
        </div>
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M13.8544 2.40439C13.993 2.26572 14.2067 2.26573 14.3454 2.40439L17.5956 5.65449C17.7343 5.79314 17.7343 6.00686 17.5956 6.14552L5.34486 18.3959C5.27527 18.4655 5.17418 18.5004 5.09934 18.5004C5.02449 18.5004 4.9234 18.4655 4.85381 18.3959L1.60362 15.1458C1.60361 15.1458 1.60361 15.1458 1.60361 15.1458M13.8544 2.40439L1.67432 14.7255C1.57471 14.8251 1.57471 14.9755 1.67432 15.0751L1.60361 15.1458M13.8544 2.40439L1.60361 14.6548C1.46494 14.7934 1.46494 15.0071 1.60361 15.1458M13.8544 2.40439L1.60361 15.1458M14.6044 6.64559L15.3589 7.4001L14.3499 8.40912L13.5954 7.6546C13.4567 7.51594 13.243 7.51593 13.1043 7.65461C12.9656 7.79327 12.9656 8.00698 13.1043 8.14564L13.8589 8.90015L12.8498 9.90917L12.0953 9.15465C11.9566 9.01599 11.7429 9.01598 11.6042 9.15465C11.4656 9.29331 11.4655 9.50703 11.6042 9.64569L12.3588 10.4002L11.3497 11.4092L10.5952 10.6547C10.4565 10.516 10.2428 10.516 10.1041 10.6547C9.96546 10.7934 9.96546 11.0071 10.1041 11.1457L10.8587 11.9002L9.84962 12.9093L9.09508 12.1547C8.95641 12.0161 8.7427 12.0161 8.60403 12.1547C8.46537 12.2934 8.46536 12.5071 8.60404 12.6458L9.35857 13.4003L8.34953 14.4093L7.59499 13.6548C7.45632 13.5161 7.2426 13.5161 7.10394 13.6548C6.96528 13.7935 6.96527 14.0072 7.10395 14.1458L7.85848 14.9003L6.59942 16.1594L5.84488 15.4048C5.70622 15.2662 5.4925 15.2662 5.35384 15.4048C5.21517 15.5435 5.21517 15.7572 5.35384 15.8959L6.10838 16.6504L5.09933 17.6594L2.34008 14.9002L14.0999 3.14079L16.8591 5.89996L15.85 6.90907L15.0954 6.15456C14.9568 6.01589 14.7431 6.01589 14.6044 6.15456C14.4657 6.29322 14.4657 6.50693 14.6044 6.64559Z"
              fill="#677F8E"
              stroke="#677F8E"
              stroke-width="0.2"
            />
          </svg>
        </div>
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M12.0285 2.55818C11.8271 2.34779 11.5019 2.34779 11.3006 2.55818L1.74965 12.6025C1.5496 12.8143 1.5496 13.1563 1.74965 13.3681L5.62175 17.4402C5.71791 17.542 5.84891 17.5997 5.98572 17.6004H9.08334C9.22015 17.5997 9.35116 17.542 9.44731 17.4402L17.4496 9.02453C17.6496 8.81277 17.6496 8.47073 17.4496 8.25899L12.0285 2.55818ZM10.761 14.5246L6.07067 9.59193L9.98656 5.47374L14.6769 10.4064L10.761 14.5246ZM8.86893 16.5145H6.19986L2.84419 12.9855L5.34029 10.3604L10.0307 15.2931L8.86893 16.5145ZM15.4075 9.63815L10.7171 4.70545L11.6645 3.70915L16.3548 8.64184L15.4075 9.63815Z"
              fill="#677F8E"
            />
          </svg>
        </div>
        <div>
          <svg
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <g clip-path="url(#clip0_12_44715)">
              <path
                d="M18.3337 7.70033L12.342 7.18366L10.0003 1.66699L7.65866 7.19199L1.66699 7.70033L6.21699 11.642L4.85033 17.5003L10.0003 14.392L15.1503 17.5003L13.792 11.642L18.3337 7.70033ZM10.0003 12.8337L6.86699 14.7253L7.70033 11.1587L4.93366 8.75866L8.58366 8.44199L10.0003 5.08366L11.4253 8.45033L15.0753 8.76699L12.3087 11.167L13.142 14.7337L10.0003 12.8337Z"
                fill="#677F8E"
              />
            </g>
            <defs>
              <clipPath id="clip0_12_44715">
                <rect width="20" height="20" fill="white" />
              </clipPath>
            </defs>
          </svg>
        </div>
      </div>
    </div>
    </div>

    <PriceAlert1 :currentCoin="currentCoin" :priceInfo="priceInfo" />
    <PriceAlert2
      :currentCoin="currentCoin"
      :priceInfo="priceInfo"
      @createAlertPopup="showCreateAlert"
    />
    <TradeOnChart
      @show_trade_on_chart="
        (e) => {
          this.show_trade_on_chart = e;
        }
      "
      v-if="show_trade_on_chart"
      :currentCoin="currentCoin"
      :current_tab="current_tab"
      :data_success="data_success"
      :coinCurrentValue="coinCurrentValue"
      :iframeContainer="iframeContainer"
    />
    <SettingsModalVue
      @getChartSettings="getChartSettings($event)"
      @resetChartSettings="resetChartSettings($event)"
      @updateChartSettings="updateChartSettings($event)"
      @getIndicators="getIndicators($event)"
      @getThemeIndicators="getThemeIndicators($event)"
      @getActiceIndicatorsDetails="getActiceIndicatorsDetails($event)"
      @addIndicators="addIndicators($event)"
      @child-event="triggerChildMethod()"
      @child-event-indicator="triggerChildMethodForIndicator()"
      @child-setting-indicator="ichecksettingindicator()"
      @child-event-active-indicator="triggerChildMethodForActiveIndicator()"
      @active-indicator-details="activeIndicatorDetails()"
    />
    <AlertTutorial @handelAlert="handelAlert" />
  </div>
</template>

<script>
import PriceAlert1 from "./components/PriceAlert1";
import PriceAlert2 from "./components/PriceAlert2";
import TradeOnChart from "./components/TradeOnChart";
import SettingsModalVue from "./components/SettingsModal";
import AlertTutorial from "./components/AlertTutorialModal";
import { mapMutations } from "vuex";
import { HTTP } from "../../config/axios";
import EventBus from "../../event-bus";
import moment from "moment";
var Stomp = require("stompjs");
var SockJS = require("sockjs-client");
export default {
  components: {
    PriceAlert1,
    PriceAlert2,
    TradeOnChart,
    SettingsModalVue,
    AlertTutorial,
  },
  data() {
    return {
      currentScale: { coinScale: 8, bathScale: 2, fixScale: 2 ,minVolume:0, maxVolume:0 },
      isLoading: true,
      data_chart: null,
      show_chart: false,
      active_timeframe: "1d",
      show_timeline_options: false,
      selected_timeline_option: "More",
      show_indicator_options: false,
      selected_indicator_option: "Indicator",
      show_chart_type_options: false,
      selected_chart_type_option: "hollow-candles",
      show_chart_category_options: false,
      selected_chart_category_option: "Bitbull Chart",

      showBtcText: true,
      coinSearchText: "",
      showSearchBox: false,
      coinCurrentValue: 0,
      selected_buy_score: 0,
      selected_sell_score: 0,
      plate: {
        askRows: [],
        bidRows: [],
      },
      currentCoin: {
        base: "BTC",
        coin: "THB",
        symbol: "BTC/THB",
        fee: 0,
        spreadOrder: 0,
      },
      priceInfo: {
        last: 0,
        chg: 0,
        high24: 0,
        low24: 0,
        vol24: 0,
        pChg: 0,
        textColor: "",
      },
      coinMarket: [],
      chartSymbolUrl: "/libchart/index.php?symbol=BTC%2FTHB",
      iframeContainer: null,
      show_trade_on_chart: false,
      bid: [],
      ask: [],
      chartOptions: {
        chart: {
          type: "area",
          zoom: {
            enabled: false,
          },
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },

        stroke: {
          curve: "straight",
        },
        colors: ["#0c9", "#EB4751"],
        fill: {
          type: "gradient",
          gradient: {
            shadeIntensity: 1,
            inverseColors: false,
            opacityFrom: 0.3,
            opacityTo: 0.05,
            stops: [50, 100, 100, 100],
          },
        },
        grid: {
          show: true, // you can either change hear to disable all grids
          xaxis: {
            lines: {
              show: true, //or just here to disable only x axis grids
            },
          },
          yaxis: {
            lines: {
              show: false, //or just here to disable only y axis
            },
          },
        },
        xaxis: {
          show: true,

          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            formatter: this.$MyFunc.NumberFormat,
            rotate: -90,
            style: {
              colors: [],
              fontSize: "11px",
              fontFamily: "Roboto Flex",
              fontWeight: 400,
            },
          },
        },
        yaxis: {
          opposite: true,
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          labels: {
            show: true,
            align: "right",
            minWidth: 0,
            maxWidth: 160,
            style: {
              colors: ["#677F8E"],
              fontSize: "11px",
              fontFamily: "Roboto Flex",
              fontWeight: 400,
            },
          },
        },
        //X => price
        current_tab: "buy",
        labels: [],
        legend: {
          position: "top",
          fontFamily: "Roboto Flex",
        },
        tooltip: {
          enabled: false,
        },

        stroke: {
          width: 1,
          curve: "stepline",
        },
        deep: true,
      },
      //Y
      series: [
        {
          //amount askRows
          name: "Buy",
          data: [],
        },
        {
          //amount bidRows
          name: "Sell",
          data: [],
        },
      ],
      data_success: [
        {
          status: "fail",
          type: "",
          theme: "",
          price: 0,
        },
      ],
      favCoinRows: false,
      isUserLogin: false,
      memberFavorite: [],
    };
  },
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
      this.getFov();

      setTimeout(() => {
        this.getHistory();
      }, 2000);
    }
    this.defaultSymbol();
    this.getSymbolThumb();
    this.getTickers();
    this.updateDataTrade();
    this.getBidAsk();
    // setTimeout(() => {
    //   if (this.active_timeframe == "4hr") {
    //     this.changeTime("4", "h");
    //   }
    // }, 1500);
  },
  computed: {
    defaultPath() {
      return this.$store.state.currentPair;
    },
    filteredCoins() {
      const searchTerm = this.coinSearchText.toLowerCase();
      
      return this.coinMarket.filter((coin) =>
        coin.symbol.toLowerCase().includes(searchTerm)
      );
    },
    filteredCoinsImages() {
      return this.coinMarket.filter((coin) =>
        coin.symbol
          .toLowerCase()
          .includes(this.currentCoin.symbol.toLowerCase())
      )[0];
    },
  },
  mounted() {
    const domain = window.origin;

    window.addEventListener("message", (event) => {
      if (event.origin !== domain)
         return;

      if (event.data.orderType == 0) {
        this.get_tradeonchart_buy(event.data);
      }
      if (event.data.orderType == 1) {
        this.get_tradeonchart_sell(event.data);
      }
      if (event.data.orderType == 2) {
        this.get_tradeonchart_sell(event.data);
      }
      if (event.data.orderType == 3) {
        this.get_tradeonchart_buy(event.data);
      }
      return false;
    });

    window.addEventListener("click", this.checkIfClickedOutside);

    const iframe = document.getElementById("myIframec");
    iframe.contentWindow.postMessage("hello", domain);
    iframe.onload = () => {
      if (iframe.contentWindow) {
        this.iframeContainer = iframe.contentWindow;
      }
    };
   
  },
  // beforeDestroy() {
  //   window.removeEventListener("click", this.checkIfClickedOutside);
  // },
  methods: {
    getHistoryTime() {
      let D1 = moment().format("YYYY-MM-DD") + " 00:00:00";
      let D2 = moment().format("YYYY-MM-DD") + " 23:59:59";
      this.historyTime = [D1, D2];
      this.historyTimeplaceholder =
        moment(this.historyTime[0]).format(this.TIMEPICKER) +
        " ~ " +
        moment(this.historyTime[1]).format(this.TIMEPICKER);
    },
    getHistory() {
      let self = this;

      HTTP.post("/exchange/order/personal/current", {
        pageNo: 1,
        pageSize: 100,
      })
        .then((res) => {
          let data = res.data.content;
          this.data_chart = [];
          if (data != "") {
            if (data == undefined) {
              return false;
            }
            data.forEach((item) => {
              if (this.currentCoin.symbol == item.symbol) {
                if (item.direction == 0) {
                  let data = {
                    percent: 0,
                    id: 0,
                    type: null,
                    price: 0,
                    theme: null,
                  };
                  if (item.stoploss != 0 && item.takeprofit != 0) {
                    this.data_chart.push({
                      percent:
                        ((item.stoploss - item.price) / item.price) * 100,
                      type: "stoploss",
                      id: item.orderId,
                      price: item.stoploss,
                      theme: "#e04152",
                    });
                    this.data_chart.push({
                      percent:
                        ((item.takeprofit - item.price) / item.price) * 100,
                      type: "takeprofit",
                      id: item.orderId,
                      price: item.takeprofit,
                      theme: "#0c9",
                    });
                    this.data_chart.push({
                      percent: 0,
                      type: "buy",
                      id: item.orderId,
                      price: item.price,
                      theme: "#9bacb6",
                    });
                  } else {
                    if (item.stoploss == 0 && item.takeprofit != 0) {
                      this.data_chart.push({
                        percent:
                          ((item.takeprofit - item.price) / item.price) * 100,
                        type: "takeprofit",
                        id: item.orderId,
                        price: item.takeprofit,
                        theme: "#0c9",
                      });
                      this.data_chart.push({
                        percent: 0,
                        type: "buy",
                        id: item.orderId,
                        price: item.price,
                        theme: "#9bacb6",
                      });
                    }
                    if (item.stoploss != 0 && item.takeprofit == 0) {
                      this.data_chart.push({
                        percent:
                          ((item.stoploss - item.price) / item.price) * 100,
                        type: "stoploss",
                        id: item.orderId,
                        price: item.stoploss,
                        theme: "#e04152",
                      });

                      this.data_chart.push({
                        percent: 0,
                        type: "buy",
                        id: item.orderId,
                        price: item.price,
                        theme: "#9bacb6",
                      });
                    }
                    if (item.stoploss == 0 && item.takeprofit == 0) {
                      this.data_chart.push({
                        percent: 0,
                        type: "buy",
                        id: item.orderId,
                        price: item.price,
                        theme: "#9bacb6",
                      });
                    }
                  }
                }
                //sell
                if (item.direction == 1) {
                  let data = {
                    percent: 0,
                    id: 0,
                    type: null,
                    price: 0,
                    theme: null,
                  };
                  if (item.stoploss != 0 && item.takeprofit != 0) {
                    this.data_chart.push({
                      percent:
                        ((item.stoploss - item.price) / item.price) * 100,
                      type: "stoploss",
                      id: item.orderId,
                      price: item.stoploss,
                      theme: "#ff2500",
                    });
                    this.data_chart.push({
                      percent:
                        ((item.takeprofit - item.price) / item.price) * 100,
                      type: "takeprofit",
                      id: item.orderId,
                      price: item.takeprofit,
                      theme: "#0c9",
                    });
                    this.data_chart.push({
                      percent: 0,
                      type: "buy",
                      id: item.orderId,
                      price: item.price,
                      theme: "#9bacb6",
                    });
                  } else {
                    if (item.stoploss == 0 && item.takeprofit != 0) {
                      this.data_chart.push({
                        percent:
                          ((item.takeprofit - item.price) / item.price) * 100,
                        type: "takeprofit",
                        id: item.orderId,
                        price: item.takeprofit,
                        theme: "#0c9",
                      });
                      this.data_chart.push({
                        percent: 0,
                        type: "sell",
                        id: item.orderId,
                        price: item.price,
                        theme: "#ff2500",
                      });
                    }
                    if (item.stoploss != 0 && item.takeprofit == 0) {
                      this.data_chart.push({
                        percent:
                          ((item.stoploss - item.price) / item.price) * 100,
                        type: "stoploss",
                        id: item.orderId,
                        price: item.stoploss,
                        theme: "#ff2500",
                      });

                      this.data_chart.push({
                        percent: 0,
                        type: "sell",
                        id: item.orderId,
                        price: item.price,
                        theme: "#ff2500",
                      });
                    }
                    if (item.stoploss == 0 && item.takeprofit == 0) {
                      this.data_chart.push({
                        percent: 0,
                        type: "sell",
                        id: item.orderId,
                        price: item.price,
                        theme: "#ff2500",
                      });
                    }
                  }
                }
              }
              //buy
            });
            if(this.$store.state.hide_menu == false){
              if (
              typeof this.iframeContainer.removeallTrendlineOrder === "function"
            ) {
              var l = this.data_chart.length;
              var remove = this.iframeContainer.removeallTrendlineOrder();
              if (l > 20) {
                l = 20;
              }
              if (l > 0) {
                for (let i = 0; i < l; i++) {
                  var buy = this.iframeContainer.addTrendlineOrder(
                      this.$MyFunc.NumberFormat(this.data_chart[i].percent),
                      this.data_chart[i].id + "_" + this.data_chart[i].type,
                      this.data_chart[i].type,
                      this.$MyFunc.toFixedDigits(this.data_chart[i].price, 2),
                      this.$MyFunc.NumberFormat(this.data_chart[i].price, 2),
                      this.data_chart[i].theme,
                      true,
                      true
                    );
                }
              }
            }
            }
          }
          // this.setLimitOrderData(data);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // setTimeout((self.isLoading = false), 2000);
        });
    },
    get_tradeonchart_buy(value) {
      this.show_trade_on_chart = true;
      this.current_tab = "buy";
      this.coinCurrentValue = value.price;
      this.data_success = [
        {
          status: "fail",
          type: "buy",
          price: value.price,
          theme: "#9bacb6",
          percent: 0,
        },
      ];
      // None = 0,
      // Market = 1,
      // Condition = 2,
      // OpenOrder = 3
      // var buy = this.iframeContainer.addTrendlineOrder("buy", "buy", this.coinCurrentValue, "#9bacb6", 2, true, true)
      // var takeprofit = this.iframeContainer.addTrendlineOrder("takeprofit", "takeprofit", this.coinCurrentValue, "#0c9", 2, true, true)
      // var stoploss = this.iframeContainer.addTrendlineOrder("stoploss", "stoploss", this.coinCurrentValue, "#e04152", 2, true, true)
    },
    get_tradeonchart_sell(value) {
      this.show_trade_on_chart = true;
      this.current_tab = "sell";
      this.coinCurrentValue = value.price;
      // addTrendlineOrder("11", "title", 2500000, "#111110", 2, true, true)
      this.data_success = [
        {
          id: 0,
          status: "fail",
          type: "sell",
          price: value.price,
          theme: "#e04152",
          percent: 0,
        },
      ];
      // var buy = this.iframeContainer.addTrendlineOrder("buy", "buy", this.coinCurrentValue, "#9bacb6", 2, true, true)
      // var takeprofit = this.iframeContainer.addTrendlineOrder("takeprofit", "takeprofit", this.coinCurrentValue, "#0c9", 2, true, true)
      // var stoploss = this.iframeContainer.addTrendlineOrder("stoploss", "stoploss", this.coinCurrentValue, "#e04152", 2, true, true)
    },
    change_chart(value) {
      if (value == "finansia") {
        this.selected_chart_category_option = "Bitbull Chart";
        this.selected_chart_type_option = "hollow-candles";
        setTimeout(() => {
          if (this.currentCoin.symbol != "") {
            this.chartSymbolUrl =
              "/libchart/index.php?symbol=" + this.currentCoin.symbol;
          }
          const iframe = document.getElementById("myIframec");
          iframe.onload = () => {
            if (iframe.contentWindow) {
              this.iframeContainer = iframe.contentWindow;
            }
          };
        }, 500);
        setTimeout(() => {
          if (this.active_timeframe == "15m") {
            this.changeTime("15", "");
          }
          if (this.active_timeframe == "30m") {
            this.changeTime("30", "");
          }
          if (this.active_timeframe == "1hr") {
            this.changeTime("1", "h");
          }
          if (this.active_timeframe == "4hr") {
            this.changeTime("4", "h");
          }
          if (this.active_timeframe == "1d") {
            this.changeTime("1", "d");
          }
        }, 1500);
      } else {
        this.chartSymbolUrl = "";
        this.selected_chart_category_option = "Depth Chart";
        this.selected_chart_type_option = "line";
      }
    },
    getBidAsk() {
      HTTP.post(
        "/market/exchange-plate",
        { symbol: this.currentCoin.symbol },
        { emulateJSON: true }
      ).then((response) => {
        let resp = response.data;
        let ask = resp.ask.slice(0, 20);
        let bid = resp.bid.slice(0, 20);

        ask.sort((a, b) => a.price - b.price);
        bid.sort((a, b) => a.price - b.price);

        this.ask = ask;
        this.bid = bid;

        let xData = [];
        xData = [
          ...bid.map((item) => item.price),
          ...ask.map((item) => item.price),
        ];
        this.chartOptions.labels = xData;
        this.chartOptions.xaxis.labels.style.colors = Array(xData.length).fill(
          "#4E606B"
        );
        const buyIndex = this.series.findIndex((item) => item.name === "Buy");
        if (buyIndex !== -1) {
          let bidVol = [];
          let volSum = 0;
          for (let i = bid.length - 1; i >= 0; i--) {
            volSum += bid[i].amount;
            bidVol.push(volSum);
          }
          bidVol.reverse();
          this.series[buyIndex].data = bidVol; //[...bid.map(item => item.amount)];
        }

        const sellIndex = this.series.findIndex((item) => item.name === "Sell");
        if (sellIndex !== -1) {
          const nullArray = Array(bid.length).fill(null);
          let volSum = 0;
          let askVol = [];
          ask.forEach((item) => {
            volSum += item.amount;
            askVol.push(volSum);
          });
          this.series[sellIndex].data = [...nullArray, ...askVol];
        }

        let data = [];
        if (resp.ask) {
          let buy_score = 0;
          resp.ask.forEach((item, index) => {
            if (buy_score == 0) {
              buy_score = item.price;
            }
            data.push({
              price: item.price,
              amount: item.amount,
              total: item.price * item.amount,
            });
          });
          this.selected_buy_score = buy_score;
          this.plate.askRows = data.reverse();
        }
        if (resp.bid) {
          let sell_score = 0;
          data = [];
          resp.bid.forEach((item, index) => {
            if (sell_score == 0) {
              sell_score = item.price;
            }
            data.push({
              price: item.price,
              amount: item.amount,
              total: item.price * item.amount,
            });
          });
          this.selected_sell_score = sell_score;
          this.plate.bidRows = data;
      
        }
        this.isLoading = false
      });
    },
    triggerChildMethod(payload) {
      // Emit the custom event to trigger a method in the child component
      this.$emit("child-event", payload);
    },
    triggerChildMethodForIndicator(payload) {
      // Emit the custom event to trigger a method in the child component
      this.$emit("child-event-indicator", payload);
    },
    ichecksettingindicator(payload) {
      // Emit the custom event to trigger a method in the child component
      this.$emit("child-setting-indicator", payload);
    },
    triggerChildMethodForActiveIndicator() {
      this.$emit("child-event-active-indicator", payload);
    },
    activeIndicatorDetails() {
      this.$emit("active-indicator-details", payload);
    },
    checkIfClickedOutside(event) {
      const CoinDropdown = this.$refs.coinDropdown;
      if (CoinDropdown && !CoinDropdown.contains(event.target)) {
        this.showSearchBox = false;
      }
    },
    handelAlertPopup() {
      let showTut = localStorage.getItem("alertTut");
      // this.$bvModal.show("price-alert-2");
      if (!showTut) {
        this.$bvModal.show("alert-tutorial");
      } else {
        this.$bvModal.show("price-alert-2");
      }
    },
    handelAlert(e) {
      if (e) {
        this.$bvModal.show("price-alert-2");
      }
    },
    showCreateAlert(e) {
      if (e) {
        this.$bvModal.show("price-alert-1");
      }
    },
    ...mapMutations(["setCurrentPair"]),
    getChartSettings(event) {
      if (typeof this.iframeContainer.iGetChartSettings === "function") {
        const Theme = this.iframeContainer.iGetChartSettings();
        this.triggerChildMethod(Theme);
      }
    },
    resetChartSettings() {
      if (typeof this.iframeContainer.iResetChartSettings === "function") {
       var a =  this.iframeContainer.iResetChartSettings();
      }
    },
    updateChartSettings(payload) {
      if (typeof this.iframeContainer.iUpdateChartSettings === "function") {
        this.iframeContainer.iUpdateChartSettings(payload);
      }
    },
    getIndicators() {
      if (typeof this.iframeContainer.iGetAllIndicators === "function") {
        const indicator = this.iframeContainer.iGetAllIndicators();
        this.triggerChildMethodForIndicator(indicator);
      }
    },
    getThemeIndicators(value) {
      if (typeof this.iframeContainer.iGetAllIndicators === "function") {
        const indicator = this.iframeContainer.iGetsettingIndicatorstheme(
          value,
          false
        );
        this.ichecksettingindicator(indicator);
        // this.triggerChildMethodForIndicator(indicator);
      }
    },
    getActiveIndicators() {
      if (typeof this.iframe.iGetActiveIndicators === "function") {
        const activeIndicators = this.iframe.iGetActiveIndicators();
        this.triggerChildMethodForActiveIndicator(activeIndicators);
      }
    },
    getActiceIndicatorsDetails(key) {
      if (
        typeof this.iframeContainer.iGetActiveIndicatorsDetails === "function"
      ) {
        const Details = this.iframeContainer.iGetActiveIndicatorsDetails(key);
        this.activeIndicatorDetails(Details);
      }
    },
    updateIndicator(key, indicator) {
      if (typeof this.iframeContainer.iUpdateIndicatorParams === "function") {
        this.iframeContainer.iUpdateIndicatorParams(key, indicator);
      }
    },
    addIndicators(indicator) {
      if (typeof this.iframeContainer.iAddIndicators === "function") {
        this.iframeContainer.iAddIndicators_webtrade(indicator);
      }
    },
    iaddIndicators() {
      if (typeof this.iframeContainer.iAddIndicators === "function") {
        this.iframeContainer.iAddIndicators();
      }
    },
    changeChartType(type) {
      if (typeof this.iframeContainer.iPriceStyleChange === "function") {
        this.iframeContainer.iPriceStyleChange(type);
      }
    },
    changeTime(time, unit) {
      if (typeof this.iframeContainer.iTimeFrameChange === "function") {
        this.iframeContainer.iTimeFrameChange(time, unit);
        this.isLoading = false
      }
      if (typeof this.iframeContainer.iChangecoinscale === "function") {
        this.iframeContainer.iChangecoinscale(this.currentScale)
      }
    },
    saveChartImage() {
      if (typeof this.iframeContainer.iSaveImageClick === "function") {
        this.iframeContainer.iSaveImageClick();
      }
    },
    closeModalIndicators() {
      if (typeof this.iframeContainer.iCloseModalIndicators === "function") {
        this.iframeContainer.iCloseModalIndicators();
      }
    },
    chartFullScreen() {
      if (typeof this.iframeContainer.iChartFullScreen === "function") {
        this.iframeContainer.iChartFullScreen();
      }
    },
    defaultSymbol() {
      if (this.$route.params.pair == undefined) {
        this.currentCoin.symbol = "BTC/THB";
        this.$route.params.pair = "btc_thb";
      } else {
        let dataArr = this.$route.params.pair.split("_");
        this.currentCoin.symbol = (dataArr[0] + "/" + dataArr[1]).toUpperCase();
        this.chartSymbolUrl =
          "/libchart/index.php?symbol=" +
          encodeURIComponent(this.currentCoin.symbol);
      }
      this.coinName = this.currentCoin.symbol.split("/")[0];
    },
    async getCoinInfo() {
      let self = this;
      await HTTP.post("/market/symbol-info-all")
        .then((res) => {
          // console.log(11)
          res.data.forEach((item, index) => {
            for(let i =0 ;i< self.coinMarket.length;i++){
              if (item.symbol == self.coinMarket[i].symbol) {
                self.coinMarket[i].bathScale = item.baseCoinScale
              }
            }
            if (item.coinSymbol == self.coinName) {
              //console.log("item=", item);
              self.currentCoin.fee = item.fee * 100;
              self.currentCoin.spreadOrder = item.spreadOrder;
              self.currentScale.coinScale = item.coinScale == 0 ? 8 : item.coinScale;
              self.currentScale.bathScale = item.baseCoinScale == 0 ? 2 : item.baseCoinScale;
              self.currentScale.minVolume = item.minVolume;
              self.currentScale.maxVolume = item.maxVolume;
              self.currentScale.symbol = item.symbol;
            }
            // self.coinMarket.push({
            //   symbol: item.symbol,
            //   coinScale: item.coinScale,
            //   bathScale: item.baseCoinScale,
            // });
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    handleEnterKey(event) {
      // ตรวจสอบว่าปุ่ม Enter ถูกกดหรือไม่
      if (event.key === "Enter") {
        // ตรวจสอบจำนวนรายการที่แสดง
        if (this.filteredCoins.length === 1) {
          // เลือกรายการแรก
          const selectedCoin = this.filteredCoins[0];
          // ทำสิ่งที่ต้องการ เช่น เรียกเมธอด selectMarketSymbol() หรืออื่นๆ
          this.selectMarketSymbol(
            selectedCoin.url.toString(),
            selectedCoin.svgHtmlString ? selectedCoin.svgHtmlString : null
          );
        }
      }
    },
    updateBidAsk(response) {
      let resp = response;

      let bid = "";
      let ask = "";

      if (resp.direction == "BUY") {
        bid = resp.items.slice(0, 20);
        bid.sort((a, b) => a.price - b.price);
        this.bid = bid;
        ask = this.ask;
      } else {
        ask = resp.items.slice(0, 20);
        ask.sort((a, b) => a.price - b.price);
        this.ask = ask;
        bid = this.bid;
      }

      let xData = [];
      xData = [
        ...bid.map((item) => item.price),
        ...ask.map((item) => item.price),
      ];

      //Update Chart
      this.chartOptions = {
        ...this.chartOptions,
        xaxis: {
          categories: xData,
          labels: {
            formatter: this.$MyFunc.NumberFormat,
            rotate: -90,
            style: {
              colors: Array(xData.length).fill("#4E606B"),
            },
          },
        },
      };

      const buyIndex = this.series.findIndex((item) => item.name == "Buy");
      if (buyIndex !== -1) {
        let bidVol = [];
        let volSum = 0;
        for (let i = bid.length - 1; i >= 0; i--) {
          volSum += bid[i].amount;
          bidVol.push(volSum);
        }
        bidVol.reverse();

        this.series[buyIndex].data = bidVol; //[...bid.map(item => item.amount)];
      }

      const sellIndex = this.series.findIndex((item) => item.name == "Sell");
      if (sellIndex !== -1) {
        const nullArray = Array(bid.length).fill(null);
        let volSum = 0;
        let askVol = [];
        ask.forEach((item) => {
          volSum += item.amount;
          askVol.push(volSum);
        });
        this.series[sellIndex].data = [...nullArray, ...askVol];
      }
    },
    updateDataTrade() {
      if (this.stompClient) {
        this.stompClient.ws.close();
      }
      let self = this;
      let url = this.MARKET_WS_URL + "/market/market-ws";
      this.stompClient = Stomp.over(new SockJS(url));
      this.stompClient.debug = false;
      this.stompClient.connect({}, () => {
        this.stompClient.subscribe("/topic/market/thumb", function (msg) {
          self.updatePriceInfo(JSON.parse(msg.body));
        });
        this.stompClient.subscribe(
          "/topic/market/trade/" + this.currentCoin.symbol,
          function (msg) {
            self.updateTickers(JSON.parse(msg.body));
          }
        );
        this.stompClient.subscribe(
          "/topic/market/trade-plate/" + this.currentCoin.symbol,
          function (msg) {
            self.updateBidAsk(JSON.parse(msg.body));
          }
        );
      });
    },
    getTickers() {
      HTTP.post(
        "/market/latest-trade",
        { symbol: this.currentCoin.symbol, size: 1 },
        { emulateJSON: true }
      ).then((response) => {
        if (response.data.length > 0) {
          this.priceInfo.last = response.data[0].price;
          this.coinCurrentValue = response.data[0].price;
        }
      });
    },
    updateTickers(resp) {
      if (resp.length > 0) {
        resp.forEach((item, index) => {
          this.priceInfo.last = item.price;
        });
      }
    },
    getSymbolThumb() {
      HTTP.post("/market/symbol-thumb", {
        emulateJSON: true,
      }).then((response) => {
        let resp = response.data;
        this.coinMarket = [];
        for (let i = 0; i < resp.length; i++) {
          let symbol = resp[i].symbol.replace("/", "_").toLowerCase();
          let color = "";
          if (resp[i].chg > 0) {
            color = "green";
          } else if (resp[i].chg < 0) {
            color = "red";
          }
          let coinMarket = {
            symbol: resp[i].symbol,
            price: resp[i].close,
            pChg: resp[i].chg,
            volume: resp[i].volume,
            url: "/chart/" + symbol,
            color: color,
            logoFileId:
              this.MARKET_WS_URL + "/market/coin/image/" + resp[i].logoFileId,
          };
          this.coinMarket.push(coinMarket);
          if (this.currentCoin.symbol == resp[i].symbol) {
            if (resp[i].chg > 0) {
              this.priceInfo.textColor = "green";
            } else if (resp[i].chg < 0) {
              this.priceInfo.textColor = "red";
            }
            this.priceInfo.chg = resp[i].change;
            this.priceInfo.high24 = resp[i].high;
            this.priceInfo.low24 = resp[i].low;
            this.priceInfo.vol24 = resp[i].volume;
            this.priceInfo.pChg = resp[i].chg;
          }
          /*
          if (this.isUserLogin) {
            this.memberFavorite.forEach((item, index)=>{
              if(item.coinId == this.currentCoin.symbol && item.favorite){
                this.favCoinRows = true;
              }
            });
            HTTP.post("uc/member/get-member-favorite").then((result) => {
              this.favCoinRows = false;
              let memberFavorite = result.data;
              if (memberFavorite.code == 0) {
                for (let key in memberFavorite.data) {
                  if (
                    memberFavorite.data[key].coinId == this.currentCoin.symbol
                  ) {
                    if (memberFavorite.data[key].favorite) {
                      this.favCoinRows = true;
                    } else {
                      this.favCoinRows = false;
                    }
                  }
                }
              }
            });
          }
          */
          if (this.isUserLogin) {
            this.favCoinRows = false;
            this.memberFavorite.forEach((item, index) => {
              if (item.coinId == this.currentCoin.symbol && item.favorite) {
                this.favCoinRows = true;
              }
            });
          }
        }
        this.getCoinInfo() 
      });
    },
    getFov() {
      HTTP.post("uc/member/get-member-favorite").then((result) => {
        if (result.data.code == 0) {
          this.memberFavorite = result.data.data;
          //console.log("memberFavorite=",this.memberFavorite);
          this.memberFavorite.forEach((item, index) => {
            if (item.coinId == this.currentCoin.symbol && item.favorite) {
              this.favCoinRows = true;
            }
          });
        }
      });
    },
    updatePriceInfo(resp) {
      for (let i = 0; i < this.coinMarket.length; i++) {
        if (this.coinMarket[i].symbol == resp.symbol) {
          this.coinMarket[i].price = resp.close;
          this.coinMarket[i].pChg = resp.chg;
          if (resp.chg > 0) {
            this.coinMarket[i].color = "green";
          } else if (resp.chg < 0) {
            this.coinMarket[i].color = "red";
          }
          break;
        }
      }
      if (this.currentCoin.symbol == resp.symbol) {
        if (resp.chg > 0) {
          this.priceInfo.textColor = "green";
        } else if (resp.chg < 0) {
          this.priceInfo.textColor = "red";
        }
        this.priceInfo.chg = resp.change;
        this.priceInfo.high24 = resp.high;
        this.priceInfo.low24 = resp.low;
        this.priceInfo.vol24 = resp.volume;
        this.priceInfo.pChg = resp.chg;
      }
    },
    selectMarketSymbol(url) {
      let pair = url.split("/").pop();
      this.setCurrentPair(pair);
      this.showBtcText = true;
      this.showSearchBox = false;
      this.coinSearchText = "";
      this.show_trade_on_chart = false;
      if (url != this.$route.path) {
        this.$router.push(url);
      }
      this.defaultSymbol();
      this.getCoinInfo().then(() => {
        this.getSymbolThumb();
      this.getTickers();
      this.updateDataTrade();
      this.getBidAsk();

      });
    

      setTimeout(() => {
        this.getHistory();
        if (this.active_timeframe == "15m") {
          this.changeTime("15", "");
        }
        if (this.active_timeframe == "30m") {
          this.changeTime("30", "");
        }
        if (this.active_timeframe == "1hr") {
          this.changeTime("1", "h");
        }
        if (this.active_timeframe == "4hr") {
          this.changeTime("4", "h");
        }
        if (this.active_timeframe == "1d") {
          this.changeTime("1", "d");
        }
      }, 1500);
    },
    setCoinText() {
      setTimeout(() => {
        if (this.coinSearchText == "") {
          this.showBtcText = true;
        }
      }, 500);
    },
    getCheckStatusModel() {
      EventBus.$emit("get-Reset-Model");
    },
    selectFav(symbol) {
      if (this.favCoinRows) {
        this.favCoinRows = false;
      } else {
        this.favCoinRows = true;
      }
      if (this.isUserLogin) {
        HTTP.post("/uc/member/update-member-favorite", {
          coinId: symbol,
          isFavorite: this.favCoinRows,
        }).then((response) => {
          if (response.data.code == 0) {
            this.memberFavorite.forEach((item, index) => {
              if (item.coinId == this.currentCoin.symbol) {
                item.favorite = true;
              }
            });
          }
        });
      }
    },
  },
  watch: {
    data_success(value) {
      if (value[0].status == "success") {
        this.show_trade_on_chart = false;
      }
    },
  },
};
</script>

<style scoped>
@media (min-width: 992px) {
  div.col-xl-6 {
    width: 47%;
  }
}
@media (min-width: 1200px) {
  div.col-xl-6 {
    width: 51%;
  }
}
@media (min-width: 1280px) {
  div.col-xl-6 {
    width: 53%;
    padding: 0;
  }
}
@media only screen and (min-width: 1500px) {
  div.col-xl-6 {
    width: 56%;
  }
}
</style>

<style lang="scss">
.cp-header {
  .top-search-row {
    padding: 12px 0;
    margin-right: -9px;
    .tsrb {
      text-align: right;
      margin-left: 10px;
      margin-right: 15px;
      button {
        width: 46%;
        max-width: 150px;
        height: 40px;
        border-radius: 4px;
        font-size: 16px;
        font-family: Noto Sans Thai;
        font-weight: 600;
      }
      button.green {
        background: var(--status-positive, #226afd);
        color: white;
        margin-right: 16px;
      }
      button.red {
        background: var(--status-negative, #ff2500);
        color: white;
      }
    }
  }
  .search-container {
    position: relative;
    width: 100%;
    margin-left: 7px;
    .row {
      padding: 4.5px 0;
      &:hover {
        background-color: #191b1c;
        cursor: pointer;
      }
    }
    .searching-box {
      position: absolute;
      width: 360px;
      height: 480px;
      background-color: #191b1c;
      top: 44px;
      z-index: 90;
      padding: 10px;
      overflow-x: hidden;
      overflow-y: scroll;

      .search-title {
        font-family: "Noto Sans Thai";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: rgb(255, 255, 255);
        margin-bottom: 7px;
        margin-top: 2px;
      }
      p {
        margin-bottom: 0;
      }
      .vol {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #677f8e;
        width: 140px;
      }
      .coin-img {
        width: 23px;
      }
      .price {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: rgb(255, 255, 255);
      }
      .percent {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      .coin-text {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: rgb(255, 255, 255);
        span.pair-text {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #677f8e;
        }
      }
    }
    .coins-search-input {
      background-color: #191b1c;
      color: white;
      width: 219px;
      font-size: 18px;
      line-height: 24px;
      caret-color: #09BE8B;
      font-weight: 600;
      border-radius: 4px !important;
      padding-top: 7.9px;
      padding-bottom: 7.9px;
      padding-right: 60px;
      border: none;
    }
    .btc-text-true {
      padding-left: 40px;
    }
    .btc-text-false {
      padding-left: 40px;
    }
    .coins-search-input::placeholder {
      color: rgb(255, 255, 255);
    }
    .search-svg {
      position: absolute;
      left: 186px;
      margin-right: 11px;
      margin-top: 8px;
      pointer-events: none;
    }
    .coin-image {
      position: absolute;
      font-size: 18px;
      left: 10px;
      top: 4.5px;
      pointer-events: none;
      .coin-img {
        width: 23px;
      }
      .btc-text {
        color: white;
        position: relative;
        top: 1.5px;
        margin-left: 7px;
        display: inline-block;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .trading-scores {
    .live-amount-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
    .live-amount-title.red {
      color: #ff2500;
    }
    .live-amount-title.green {
      color: #0c9;
    }
    .live-amount-value {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-right: 10px;
    }
    .live-amount-value.red {
      color: #ff2500;
    }
    .live-amount-value.green {
      color: #0c9;
    }
    .title {
      color: #9bacb6;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-right: 6px;
    }
    .value {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin-right: 14px;
    }
  }
}
.cc {
  .apexcharts-legend-marker {
    border-radius: 0 !important;
    height: 16px !important;
    width: 16px !important;
  }
  .apexcharts-legend-text {
    color: #677f8e !important;
    margin-left: -10px !important;
  }
  .apexcharts-legend-series {
    margin: 10px 10px !important;
  }
  .cbar {
    width: 36px;
    background-color: #191b1c;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: absolute;
    top: 193px;
    height: 715px;

    div {
      margin: 10px 0;
      cursor: pointer;
    }
  }
}
.FDAp-12-24 {
  background-color: #141b1f;
}
@font-face {
  font-family: iconFont;
  src: url("../../assets/icons/chart-icon/scx-icons/scx-icons.woff");
}

.chart-topbar-page {
  position: relative;
  z-index: 50;
  width: 100%;
  border-bottom: 1px solid #28363e;
  border-top: 1px solid #28363e;
  min-height: 44px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 17px 24px;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  .timeframe {
    border: 1px solid #28363e;
    border-radius: 4px;
    padding: 2px;
    font-family: "Roboto Flex";
    margin-left: 8px;
    display: inline-block;
    button {
      width: 39px;
      height: 22px;
      color: #677f8e;
      background-color: transparent;
      border: none;
    }
    button.active {
      color: white;
      background-color: #2c3b44;
      border-radius: 2px;
    }
    .tf-sc {
      position: relative;
      display: inline-block;
      font-family: "Roboto Flex";
      border-radius: 4px;

      .text {
        display: inline-block;
        width: 57.83px;
        color: #677f8e;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 3px;
        cursor: pointer;
      }
      span.active {
        color: white;
        background-color: #2c3b44;
        border-radius: 2px;
      }
      svg {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      .options {
        position: absolute;
        left: 0;
        background-color: #191b1c;
        width: 100%;
        text-align: left;
        ul {
          padding: 0;
          margin: 0;
          li {
            display: flex;
            align-items: center;
            height: 30px;
            border-radius: 4px;
            padding-left: 5px;
            color: #677f8e;
            cursor: pointer;
            &:hover {
              color: white;
              background-color: #2c3b44;
            }
          }
        }
      }
    }
  }
  .ic-sc {
    border: 1px solid #28363e;
    border-radius: 4px;
    margin-left: 8px;
    font-family: "Roboto Flex";
    position: relative;
    width: 77px;
    height: 28px;
    display: inline-block;
    .text {
      width: 100%;
      height: 100%;
      padding-left: 8px;
      padding-top: 6px;
      display: inline-block;
      color: #677f8e;
      cursor: pointer;
    }
    span.active {
      color: white;
      background-color: #2c3b44;
      border-radius: 2px;
    }
    svg {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
    .options {
      position: absolute;
      left: 0;
      background-color: #191b1c;
      width: 100%;
      text-align: left;
      ul {
        padding: 0;
        margin: 0;
        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          padding-left: 5px;
          color: #677f8e;
          &:hover {
            color: white;
            background-color: #2c3b44;
          }
        }
      }
    }
  }
  .ctype-sc {
    border: 1px solid #28363e;
    font-family: "Roboto Flex";
    border-radius: 4px;
    position: relative;
    width: 33px;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    .text {
      display: inline-block;
      color: #677f8e;
      font-family: iconFont;
      width: 100%;
      height: 100%;
      font-size: 13px;
      cursor: pointer;
    }
    span.active {
      color: white;
      background-color: #2c3b44;
      border-radius: 2px;
    }
    svg {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
    .options {
      position: absolute;
      left: 0;
      background-color: #191b1c;
      width: 142px;
      border-radius: 4px;
      text-align: left;
      top: 31px;
      ul {
        padding: 0;
        margin: 0;
        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 40px;
          padding-left: 12px;
          color: rgb(255, 255, 255);
          &:hover {
            // color: white;
            background-color: #2c3b44;
            color: #677f8e;
          }
        }
      }
    }
  }

  .ct-alert {
    border: 1px solid #28363e;
    border-radius: 4px;
    color: #677f8e;
    display: inline-block;
    width: 62px;
    position: relative;
    padding-left: 26px;
    padding-top: 6px;
    cursor: pointer;
    padding-bottom: 6px;
    svg {
      position: absolute;
      left: 8px;
    }
  }
  .toc-btn {
    button {
      border: 1px solid #28363e;
      border-radius: 4px;
      color: white;
      background-color: #2c3b44;
      width: 97px;
      padding-top: 7px;
      padding-bottom: 7px;
      margin-right: 8px;
    }
  }
  span.favorite,
  span.save,
  span.capture,
  span.expand,
  span.setting {
    color: #677f8e;
    border: 1px solid #28363e;
    border-radius: 4px;
    padding: 6px;
    margin-left: 8px;
    cursor: pointer;
  }
  span.capture,
  span.expand,
  span.setting {
    width: 28px;
    height: 28px;
  }
  span.save {
    width: 42px;
    height: 28px;
    button {
      color: #677f8e;
    }
  }
  span.favorite {
    position: relative;
    padding-left: 21px;
    padding-right: 10px;
    width: 80px;
    svg {
      left: 8px;
      top: 5px;
      position: absolute;
    }
    button {
      color: #677f8e;
    }
  }
  span.chart-icon {
    font-family: iconFont;
    position: absolute;
    right: 10px;
  }
  span.bars::before {
    content: "\6a";
  }
  span.colored-bars::before {
    content: "\6f";
    color: green;
    margin-left: -6px;
  }
  span.colored-bars::after {
    content: "\6e";
    color: red;
  }
  span.candles::before {
    content: "\6b";
  }
  span.hollow-candles::before {
    content: "\49";
  }
  span.heikin-ashi::before {
    content: "\48";
  }
  span.renko::before {
    content: "\35";
  }
  span.line-break::before {
    content: "\51";
  }
  span.kagi::before {
    content: "\4e";
  }
  span.line::before {
    content: "\4f";
  }
  span.mountain::before {
    content: "\53";
  }
  span.point-figure::before {
    content: "\57";
  }
}
.show {
  display: block;
}
.hidden {
  display: none;
}
@media (min-width: 1402px) {
  #myIframec {
    height: 76vh !important;
  }
}
@media (max-width: 1402px) {
  #myIframec {
    height: 82vh !important;
  }
}
</style>
