<template>
  <div>
    <div class="container-fluid footer bs-bottom-section">
      <div class="row">
        <div class="col-md-12 p-0">
          <ul class="nav my-4 pills-tab" role="tablist">
            <li
              class="nav-item"
              v-for="(tab, index) in footertabs"
              :key="index"
            >
              <button
                type="button"
                @click="selectedfooter = tab"
                :class="{ active: selectedfooter == tab }"
              >
                {{ tab }}
              </button>
            </li>
            <li class="nav-item">
              <router-link to="/order-history">
                <button type="button">Order History</button>
              </router-link>
            </li>
          </ul>
        </div>
        <!-- Open Order -->
        <div
          v-if="selectedfooter == 'Open Orders'"
          class="tab-pane open-order b-order"
        >
          <ul class="nav mr-2 mb-3" role="tablist">
            <li class="nav-item">
              <button
                type="button"
                @click="selectedopenorder = 'Limit Orders'"
                :class="{ active: selectedopenorder == 'Limit Orders' }"
              >
                Limit & Market Orders
                <span v-if="this.$store.state.limitOrderData">
                  ({{ this.$store.state.limitOrderData.length }})</span
                >
              </button>
              <button
                v-if="!$store.state.hide_menu"
                type="button"
                @click="selectedopenorder = 'Trailing Stop'"
                :class="{ active: selectedopenorder == 'Trailing Stop' }"
              >
                Trailing Stop
                <span v-if="trailingStopDataOrder.length > 0"
                  >({{ trailingStopDataOrder.length }})</span
                >
              </button>
            </li>
          </ul>
          <div
            v-if="selectedopenorder == 'Limit Orders'"
            class="fade show active"
          >
            <div class="row lm-order-container">
              <div class="col-md-12 table-responsive p-0">
                <table class="table table-borderless p-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          class="form-check-input shadow"
                          v-model="selectAll"
                          @change="selectAllOrders"
                        />
                      </th>
                      <th scope="col">Coin</th>
                      <th scope="col">B/S</th>
                      <th scope="col">Type</th>
                      <th scope="col" class="text-right">Price</th>
                      <th scope="col" class="text-right">Volume</th>
                      <th scope="col" class="text-right">Matched</th>
                      <th scope="col" class="text-right">Unmatched</th>
                      <th scope="col" class="text-right">Total(THB)</th>
                      <th scope="col">Conditions</th>
                      <th scope="col"></th>
                    </tr>
                  </thead>
                  <tbody v-if="isUserLogin">
                    <!-- skaleton start -->
                    <template v-if="!this.$store.state.limitOrderData">
                      <tr v-for="(item, index) in 10" :key="index">
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="80%"
                          ></b-skeleton>
                        </td>
                      </tr>
                    </template>
                    <!-- skaleton end -->
                    <template v-else>
                      <tr v-if="this.$store.state.limitOrderData.length == 0">
                        <td colspan="11" class="text-center">
                          -- ไม่พบรายการ --
                        </td>
                      </tr>
                      <tr
                        v-for="(item, index) in this.$store.state
                          .limitOrderData"
                        :key="index"
                      >
                        <td>
                          <input
                            type="checkbox"
                            class="form-check-input shadow"
                            @change="getSelectedForCancel"
                            :value="item.orderId"
                            v-model="selectedForCancel"
                          />
                        </td>
                        <td>{{ item.coinSymbol }}</td>
                        <td
                          :class="item.direction == 0 ? 'p-0 green' : 'p-0 red'"
                        >
                          {{ $MyFunc.BuySellDirection(item.direction, true) }}
                        </td>
                        <td>
                          {{ $MyFunc.BuySellType(item.type) }}
                        </td>
                        <td class="text-right">
                          {{ $MyFunc.NumberFormat(item.price, item.bathScale) }}
                        </td>
                        <td class="text-right">
                          {{
                            $MyFunc.NumberFormat(item.amount, item.coinScale)
                          }}
                        </td>
                        <td class="text-right">
                          {{
                            $MyFunc.NumberFormat(
                              item.filledQuantity,
                              item.coinScale
                            )
                          }}
                        </td>
                        <td class="text-right">
                          {{
                            $MyFunc.NumberFormat(
                              item.leaveQuantity,
                              item.coinScale
                            )
                          }}
                        </td>
                        <td class="text-right" v-if="item.total != 0">
                          {{ $MyFunc.NumberFormat(item.total) }}
                        </td>
                        <td class="text-right" v-else>
                          {{ $MyFunc.NumberFormat(item.price * item.amount) }}
                        </td>
                        <td>
                          {{
                            getCheckCondition(
                              item.takeprofit,
                              item.takeprofitPercent,
                              item.stoploss,
                              item.stoplossPercent,
                              item.bathScale
                            )
                          }}
                        </td>
                        <td>
                          <button
                            class="btn"
                            @click="cancelOrderConfirmation(item)"
                          >
                            Cancel
                          </button>
                        </td>
                      </tr>
                    </template>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="11"></td>
                    </tr>
                    <tr>
                      <td colspan="11" class="text-center">
                        Please
                        <a href="/login" style="color: #09BE8B"
                          >Login</a
                        >
                        or
                        <a :href="REGISTER_URL" style="color: #09BE8B"
                          >Register Now</a
                        >
                        to Trade
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="selectedForCancel.length > 0"
                class="selected-cancel-bar"
              >
                <button
                  class="cancel-this-orders"
                  @click="$bvModal.show('cancel-selected-modal')"
                >
                  Cancel {{ selectedForCancel.length }} Orders
                </button>
              </div>
            </div>
          </div>
          <!-- Trailing Stop below -->
          <div
            v-if="selectedopenorder == 'Trailing Stop'"
            class="mb-4 fade show active"
          >
            <div>
              <div class="col-md-12 table-responsive mt-3 p-0">
                <table class="table table-borderless p-0">
                  <thead>
                    <tr>
                      <th scope="col">
                        <input
                          type="checkbox"
                          class="form-check-input shadow"
                          v-model="trailingSelectAll"
                          @change="trailingSelectAllOrders"
                        />
                      </th>
                      <th scope="col">B/S</th>
                      <th scope="col">Coin</th>
                      <th scope="col" class="text-right">Start Price</th>
                      <th scope="col" class="text-right">Trailing cond.</th>
                      <th scope="col" class="text-right">Price</th>
                      <th scope="col" class="text-right">Volume</th>
                      <th scope="col" class="text-right">Matched</th>
                      <th scope="col" class="text-right">Unmatched</th>
                      <th scope="col" class="text-right">Total (THB)</th>
                      <th scope="col" class="text-right">Condition</th>
                      <th scope="col" class="text-right">Expired Date</th>
                    </tr>
                  </thead>
                  <tbody v-if="isUserLogin">
                    <tr v-if="trailingStopDataOrder.length == 0">
                      <td colspan="12" class="text-center">
                        -- ไม่พบรายการ --
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in trailingStopDataOrder"
                      :key="index"
                    >
                      <td>
                        <input
                          @change="getTrailingSelectedForCancel"
                          :value="item.orderId"
                          v-model="trailingSelectedForCancel"
                          type="checkbox"
                          class="form-check-input shadow"
                        />
                      </td>
                      <td
                        :class="item.direction == 0 ? 'p-0 green' : 'p-0 red'"
                      >
                        {{ $MyFunc.BuySellDirection(item.direction, true) }}
                      </td>
                      <td>{{ item.symbol.split("/")[0] }}</td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.triggerPrice,
                            item.bathScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        <span
                          v-if="item.trailingStep"
                          style="color: rgb(255, 255, 255); margin-left: 0 !important"
                          v-text="item.direction == 0 ? '+' : '-'"
                        ></span>
                        <span
                          v-if="item.trailingStepType == 0"
                          style="color: rgb(255, 255, 255); margin-left: 0 !important"
                        >
                          {{
                            $MyFunc.NumberFormat(
                              Math.abs(item.trailingStep),
                              item.bathScale
                            )
                          }}
                        </span>
                        <span
                          v-else
                          style="color: rgb(255, 255, 255); margin-left: 0 !important"
                        >
                          {{ $MyFunc.NumberFormat(item.trailingStep, 2) }}%
                        </span>
                      </td>
                      <td class="text-right">
                        <span v-if="item.basePrice" style="color: rgb(255, 255, 255)">
                          {{
                            $MyFunc.NumberFormat(item.basePrice, item.bathScale)
                          }}
                        </span>
                        <span v-else style="color: rgb(255, 255, 255)">-</span>
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.amount, item.bathScale) }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.filledQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.leaveQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.total) }}
                      </td>
                      <td class="text-right">
                        <span style="color: rgb(255, 255, 255)" v-if="item.stopLossPrice">
                          SL: {{ $MyFunc.NumberFormat(item.stopLossPrice, 2) }}
                        </span>
                        <span v-else style="color: rgb(255, 255, 255)">-</span>
                      </td>
                      <td v-if="item.expirationDate" class="text-right">
                        {{ exactDifference(item.expirationDate) }} ({{
                          formatExpirationDate(item.expirationDate)
                        }})
                      </td>
                      <td v-else class="text-right"></td>
                      <td>
                        <button
                          class="btn"
                          @click="trailingCancelOrderConfirmation(item)"
                        >
                          Cancel
                        </button>
                      </td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="12"></td>
                    </tr>
                    <tr>
                      <td colspan="12" class="text-center">
                        Please
                        <a href="/login" style="color: #09BE8B"
                          >Login</a
                        >
                        or
                        <a :href="REGISTER_URL" style="color: #09BE8B"
                          >Register Now</a
                        >
                        to Trade
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div
                v-if="trailingSelectedForCancel.length > 0"
                class="selected-cancel-bar"
              >
                <button
                  class="cancel-this-orders"
                  @click="$bvModal.show('trailing-cancel-selected-modal')"
                >
                  Cancel {{ trailingSelectedForCancel.length }} Orders
                </button>
              </div>
            </div>
          </div>
        </div>
        <!-- mached tab start -->
        <div
          v-if="selectedfooter == 'Matched'"
          class="tab-pane open-order b-order"
        >
          <ul class="nav mr-2" role="tablist">
            <li class="nav-item">
              <button
                type="button"
                @click="selectedMatchedTab = 'Limit Orders'"
                :class="{ active: selectedMatchedTab == 'Limit Orders' }"
              >
                Limit Orders
                <span v-if="isUserLogin"
                  >({{ matched_limit_orders.length }})</span
                >
              </button>
              <button
                type="button"
                @click="selectedMatchedTab = 'Market Order'"
                :class="{ active: selectedMatchedTab == 'Market Order' }"
              >
                Market Orders
                <span v-if="isUserLogin"
                  >({{ matched_market_orders.length }})</span
                >
              </button>
              <button
                v-if="!$store.state.hide_menu"
                type="button"
                @click="selectedMatchedTab = 'Traling Stop'"
                :class="{ active: selectedMatchedTab == 'Traling Stop' }"
              >
                Traling Stop
                <span v-if="isUserLogin"
                  >({{ matchedTrailingStopData.length }})</span
                >
              </button>
            </li>
            <div
              class="filter-date-picker"
              style="padding-left: 10px; width: 221px"
              hidden
            >
              <date-picker
                :lang="lang"
                v-model="historyTime"
                :placeholder="historyTimeplaceholder"
                :editable="false"
                format="DD/MM/YYYY"
                range
                style="width: 221px"
                popup-class="date-picker-style"
                :show-week-number="true"
                @change="getHistory()"
              >
                <template v-slot:icon-calendar>
                  <img src="@/assets/svg/calendar.svg" role="button" />
                </template>
              </date-picker>
            </div>
          </ul>
          <div v-if="selectedMatchedTab == 'Limit Orders'">
            <div class="row m-0">
              <div
                v-if="matched_limit_orders.length > 10"
                class="col-md-12 table-responsive mt-3 p-0 scrollit"
              >
                <table class="table table-borderless p-0 matchTable">
                  <thead>
                    <tr>
                      <th scope="col">Coin</th>
                      <th scope="col">B/S</th>
                      <th scope="col">Type</th>
                      <th scope="col" class="text-right">Price</th>
                      <th scope="col" class="text-right">Volume</th>
                      <th scope="col" class="text-right">Matched</th>
                      <th scope="col" class="text-right">Unmatched</th>
                      <th scope="col" class="text-right">Total(THB)</th>
                      <th scope="col">Conditions</th>
                      <th scope="col" class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody v-if="isUserLogin">
                    <tr v-if="matched_limit_orders.length == 0">
                      <td colspan="10" class="text-center">
                        -- ไม่พบรายการ --
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in matched_limit_orders"
                      :key="index"
                    >
                      <td>{{ item.coinSymbol }}</td>
                      <td
                        :class="item.direction == 0 ? 'p-0 green' : 'p-0 red'"
                      >
                        {{ $MyFunc.BuySellDirection(item.direction, true) }}
                      </td>
                      <td>{{ $MyFunc.BuySellType(item.type) }}</td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.averageFillPrice,
                            item.bathScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.amount, item.coinScale) }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.filledQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.leaveQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.total) }}
                      </td>
                      <td>
                        {{
                          getCheckCondition(
                            item.takeprofit,
                            item.takeprofitPercent,
                            item.stoploss,
                            item.stoplossPercent,
                            item.bathScale
                          )
                        }}
                      </td>
                      <td
                        v-if="item.status == 1 && item.exchangeOrderConditions"
                        class="text-right"
                      >
                        <button
                          class="btn"
                          @click="
                            cancelOrderCondition(
                              item,
                              item.exchangeOrderConditions[0].orderId
                            )
                          "
                        >
                          Cancel
                        </button>
                      </td>
                      <td v-else class="text-right"></td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10"></td>
                    </tr>
                    <tr>
                      <td colspan="10" style="text-align: center">
                        Please
                        <a href="/login" style="color: #09BE8B"
                          >Login</a
                        >
                        or
                        <a :href="REGISTER_URL" style="color: #09BE8B"
                          >Register Now</a
                        >
                        to Trade
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="col-md-12 table-responsive mt-3 p-0">
                <table class="table table-borderless p-0 matchTable">
                  <thead>
                    <tr>
                      <th scope="col">Coin</th>
                      <th scope="col">B/S</th>
                      <th scope="col">Type</th>
                      <th scope="col" class="text-right">Price</th>
                      <th scope="col" class="text-right">Volume</th>
                      <th scope="col" class="text-right">Matched</th>
                      <th scope="col" class="text-right">Unmatched</th>
                      <th scope="col" class="text-right">Total(THB)</th>
                      <th scope="col">Conditions</th>
                      <th scope="col" class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody v-if="isUserLogin">
                    <tr v-if="matched_limit_orders.length == 0">
                      <td colspan="10" class="text-center">
                        -- ไม่พบรายการ --
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in matched_limit_orders"
                      :key="index"
                    >
                      <td>{{ item.coinSymbol }}</td>
                      <td
                        :class="item.direction == 0 ? 'p-0 green' : 'p-0 red'"
                      >
                        {{ $MyFunc.BuySellDirection(item.direction, true) }}
                      </td>
                      <td>{{ $MyFunc.BuySellType(item.type) }}</td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.averageFillPrice,
                            item.bathScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.amount, item.coinScale) }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.filledQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.leaveQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.total) }}
                      </td>
                      <td>
                        {{
                          getCheckCondition(
                            item.takeprofit,
                            item.takeprofitPercent,
                            item.stoploss,
                            item.stoplossPercent,
                            item.bathScale
                          )
                        }}
                      </td>
                      <td
                        v-if="item.status == 1 && item.exchangeOrderConditions"
                        class="text-right"
                      >
                        <button
                          class="btn"
                          @click="
                            cancelOrderCondition(
                              item,
                              item.exchangeOrderConditions[0].orderId
                            )
                          "
                        >
                          Cancel
                        </button>
                      </td>
                      <td v-else class="text-right"></td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10"></td>
                    </tr>
                    <tr>
                      <td colspan="10" style="text-align: center">
                        Please
                        <a href="/login" style="color: #09BE8B"
                          >Login</a
                        >
                        or
                        <a :href="REGISTER_URL" style="color: #09BE8B"
                          >Register Now</a
                        >
                        to Trade
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="selectedMatchedTab === 'Market Order'">
            <div class="row m-0">
              <div
                v-if="matched_market_orders.length > 10"
                class="col-md-12 table-responsive mt-3 p-0 scrollit"
              >
                <table class="table table-borderless p-0 matchTable">
                  <thead>
                    <tr>
                      <th scope="col">Coin</th>
                      <th scope="col">B/S</th>
                      <th scope="col">Type</th>
                      <th scope="col" class="text-right">Price</th>
                      <th scope="col" class="text-right">Volume</th>
                      <th scope="col" class="text-right">Matched</th>
                      <th scope="col" class="text-right">Unmatched</th>
                      <th scope="col" class="text-right">Total(THB)</th>
                      <th scope="col">Conditions</th>
                      <th scope="col" class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody v-if="isUserLogin">
                    <tr v-if="matched_market_orders.length == 0">
                      <td colspan="10" class="text-center">
                        -- ไม่พบรายการ --
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in matched_market_orders"
                      :key="index"
                    >
                      <td>{{ item.coinSymbol }}</td>
                      <td
                        :class="item.direction == 0 ? 'p-0 green' : 'p-0 red'"
                      >
                        {{ $MyFunc.BuySellDirection(item.direction, true) }}
                      </td>
                      <td>{{ $MyFunc.BuySellType(item.type) }}</td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.averageFillPrice,
                            item.bathScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.amount, item.coinScale) }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.filledQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.leaveQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.total) }}
                      </td>
                      <td>
                        {{
                          getCheckCondition(
                            item.takeprofit,
                            item.takeprofitPercent,
                            item.stoploss,
                            item.stoplossPercent,
                            item.bathScale
                          )
                        }}
                      </td>
                      <td
                        v-if="item.status == 1 && item.exchangeOrderConditions"
                        class="text-right"
                      >
                        <button
                          class="btn"
                          @click="
                            cancelMarketOrderCondition(
                              item,
                              item.exchangeOrderConditions[0].orderId
                            )
                          "
                        >
                          Cancel
                        </button>
                      </td>
                      <td v-else class="text-right"></td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10"></td>
                    </tr>
                    <tr>
                      <td colspan="10" class="text-center">
                        Please
                        <a href="/login" style="color: #09BE8B"
                          >Login</a
                        >
                        or
                        <a :href="REGISTER_URL" style="color: #09BE8B"
                          >Register Now</a
                        >
                        to Trade
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <div v-else class="col-md-12 table-responsive mt-3 p-0">
                <table class="table table-borderless p-0 matchTable">
                  <thead>
                    <tr>
                      <th scope="col">Coin</th>
                      <th scope="col">B/S</th>
                      <th scope="col">Type</th>
                      <th scope="col" class="text-right">Price</th>
                      <th scope="col" class="text-right">Volume</th>
                      <th scope="col" class="text-right">Matched</th>
                      <th scope="col" class="text-right">Unmatched</th>
                      <th scope="col" class="text-right">Total(THB)</th>
                      <th scope="col">Conditions</th>
                      <th scope="col" class="text-right"></th>
                    </tr>
                  </thead>
                  <tbody v-if="isUserLogin">
                    <tr v-if="matched_market_orders.length == 0">
                      <td colspan="10" class="text-center">
                        -- ไม่พบรายการ --
                      </td>
                    </tr>
                    <tr
                      v-for="(item, index) in matched_market_orders"
                      :key="index"
                    >
                      <td>{{ item.coinSymbol }}</td>
                      <td
                        :class="item.direction == 0 ? 'p-0 green' : 'p-0 red'"
                      >
                        {{ $MyFunc.BuySellDirection(item.direction, true) }}
                      </td>
                      <td>{{ $MyFunc.BuySellType(item.type) }}</td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.averageFillPrice,
                            item.bathScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.amount, item.coinScale) }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.filledQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(
                            item.leaveQuantity,
                            item.coinScale
                          )
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(item.total) }}
                      </td>
                      <td>
                        {{
                          getCheckCondition(
                            item.takeprofit,
                            item.takeprofitPercent,
                            item.stoploss,
                            item.stoplossPercent,
                            item.bathScale
                          )
                        }}
                      </td>
                      <td
                        v-if="item.status == 1 && item.exchangeOrderConditions"
                        class="text-right"
                      >
                        <button
                          class="btn"
                          @click="
                            cancelMarketOrderCondition(
                              item,
                              item.exchangeOrderConditions[0].orderId
                            )
                          "
                        >
                          Cancel
                        </button>
                      </td>
                      <td v-else class="text-right"></td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="10"></td>
                    </tr>
                    <tr>
                      <td colspan="10" class="text-center">
                        Please
                        <a href="/login" style="color: #09BE8B"
                          >Login</a
                        >
                        or
                        <a :href="REGISTER_URL" style="color: #09BE8B"
                          >Register Now</a
                        >
                        to Trade
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <div v-if="selectedMatchedTab === 'Traling Stop'">
            <div class="row m-0">
              <div class="col-md-12 table-responsive mt-3 p-0">
                <table class="table table-borderless p-0 matchTable">
                  <thead>
                    <tr>
                      <th scope="col">Coin</th>
                      <th scope="col">B/S</th>
                      <th scope="col" class="text-right">Start Price</th>
                      <th scope="col" class="text-right">Trailing cond.</th>
                      <th scope="col" class="text-right">Price</th>
                      <th scope="col" class="text-right">Volume</th>
                      <th scope="col" class="text-right">Matched</th>
                      <th scope="col" class="text-right">Unmatched</th>
                      <th scope="col" class="text-right">Total (THB)</th>
                      <th scope="col" class="text-right">Condition</th>
                      <th scope="col" class="text-right">Expired Date</th>
                    </tr>
                  </thead>
                  <tbody v-if="isUserLogin">
                    <tr v-if="matchedTrailingStopData.length == 0">
                      <td colspan="11" class="text-center">
                        -- ไม่พบรายการ --
                      </td>
                    </tr>
                    <tr
                      v-for="(order, index) in matchedTrailingStopData"
                      :key="index"
                    >
                      <td
                        :class="order.direction == 0 ? 'p-0 green' : 'p-0 red'"
                      >
                        {{ $MyFunc.BuySellDirection(order.direction, true) }}
                      </td>
                      <td>{{ order.symbol.split("/")[0] }}</td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(order.triggerPrice, bathScale)
                        }}
                      </td>
                      <td class="text-right">
                        <span
                          v-if="item.trailingStep"
                          style="color: rgb(255, 255, 255); margin-left: 0 !important"
                          v-text="item.direction == 0 ? '+' : '-'"
                        ></span
                        ><span
                          v-if="item.trailingStepType == 0"
                          style="color: rgb(255, 255, 255); margin-left: 0 !important"
                        >
                          {{
                            $MyFunc.NumberFormat(
                              Math.abs(item.trailingStep),
                              item.bathScale
                            )
                          }}
                        </span>
                        <span
                          v-else
                          style="color: rgb(255, 255, 255); margin-left: 0 !important"
                        >
                          {{ $MyFunc.NumberFormat(item.trailingStep, 2) }}%
                        </span>
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(order.basePrice, bathScale) }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(order.amount, bathScale) }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(order.filledQuantity, bathScale)
                        }}
                      </td>
                      <td class="text-right">
                        {{
                          $MyFunc.NumberFormat(order.leaveQuantity, bathScale)
                        }}
                      </td>
                      <td class="text-right">
                        {{ $MyFunc.NumberFormat(order.total) }}
                      </td>
                      <td class="text-right">
                        <span
                          style="color: rgb(255, 255, 255)"
                          v-if="order.stopLossPrice"
                          v-text="
                            'SL: ' +
                            $MyFunc.NumberFormat(order.stopLossPrice, bathScale)
                          "
                        ></span>
                        <span style="color: rgb(255, 255, 255)" v-else v-text="'-'"></span>
                      </td>
                      <td v-if="order.expirationDate" class="text-right">
                        {{ exactDifference(order.expirationDate) }} ({{
                          formatExpirationDate(order.expirationDate)
                        }})
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                  <tbody v-else>
                    <tr>
                      <td colspan="11"></td>
                    </tr>
                    <tr>
                      <td colspan="11" class="text-center">
                        Please
                        <a href="/login" style="color: #09BE8B"
                          >Login</a
                        >
                        or
                        <a :href="REGISTER_URL" style="color: #09BE8B"
                          >Register Now</a
                        >
                        to Trade
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <!-- mached tab end -->
        <!-- portfolio tab start -->
        <div
          v-if="selectedfooter == 'Portfolio'"
          class="tab-pane open-order b-order"
        >
          <div class="row m-0">
            <div
              class="col-md-12 table-responsive p-0"
              :class="show_port ? 'scrollit' : ''"
            >
              <table class="table table-borderless p-0 portfolioTable">
                <thead>
                  <tr>
                    <th scope="col">Coin</th>
                    <th scope="col" class="text-right">
                      Actual
                      <div class="h-info-c">
                        <svg
                          width="20"
                          height="20"
                          viewBox="0 0 20 20"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M9 16H11V14H9V16ZM10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM10 18C5.59 18 2 14.41 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 14.41 14.41 18 10 18ZM10 4C7.79 4 6 5.79 6 8H8C8 6.9 8.9 6 10 6C11.1 6 12 6.9 12 8C12 10 9 9.75 9 13H11C11 10.75 14 10.5 14 8C14 5.79 12.21 4 10 4Z"
                            fill="#677F8E"
                          />
                        </svg>
                        <span class="hover-info"
                          >Actual คือ จำนวนเหรียญที่มีในพอร์ต</span
                        >
                      </div>
                    </th>
                    <th scope="col" class="text-right">Avg. Cost</th>
                    <th scope="col" class="text-right">Last Price</th>
                    <th scope="col" class="text-right">Total (THB)</th>
                    <th scope="col" class="text-right">Unrealized</th>
                    <th scope="col" class="text-right">Realized (Today)</th>
                  </tr>
                </thead>
                <tbody v-if="isUserLogin">
                  <tr v-if="portfolioData.length == 0">
                    <td colspan="7" class="text-center">-- ไม่พบรายการ --</td>
                  </tr>
                  <tr v-for="(item, index) in portfolioData" :key="index">
                    <td>
                      {{ item.coin.name }}<span>{{ item.coin.fullName }}</span>
                    </td>
                    <td class="text-right">
                      {{
                        $MyFunc.NumberFormat(
                          item.actual,
                          item.coin.name == "THB"
                            ? item.baseCoinScale
                            : item.coinScale
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        $MyFunc.NumberFormat(
                          item.averagePrice,
                          item.baseCoinScale
                        )
                      }}
                    </td>
                    <td class="text-right">
                      {{
                        $MyFunc.NumberFormat(item.lastPrice, item.baseCoinScale)
                      }}
                    </td>
                    <td class="text-right">
                      {{ $MyFunc.NumberFormat(item.total) }}
                    </td>
                    <td
                      :class="
                        item.unrealized >= 0
                          ? 'text-right green'
                          : 'text-right red'
                      "
                    >
                      {{ $MyFunc.NumberFormat(item.unrealized) }}
                    </td>
                    <td
                      :class="
                        item.realized >= 0
                          ? 'text-right green'
                          : 'text-right red'
                      "
                    >
                      {{ $MyFunc.NumberFormat(item.realized) }}
                    </td>
                    <td
                      @click="
                        $bvModal.show('portfolio-detail'),
                          (portfolioDetail = item)
                      "
                      class="text-center orange"
                    >
                      ดูรายละเอียด
                    </td>
                  </tr>
                </tbody>
                <tbody v-else>
                  <tr>
                    <td colspan="7"></td>
                  </tr>
                  <tr>
                    <td colspan="7" style="text-align: center; color: #677f8e">
                      Please
                      <a href="/login" style="color: #09BE8B"
                        >Login</a
                      >
                      or
                      <a :href="REGISTER_URL" style="color: #09BE8B"
                        >Register Now</a
                      >
                      to Trade
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>
        <!-- portfolio tab end -->
        <!-- order historoy tab start -->
        <div
          class="tab-pane open-order b-order"
          v-if="selectedfooter == 'history'"
        >
          <ul class="nav mx-2" id="" role="tablist">
            <li class="nav-item">
              <button
                type="button"
                v-for="(tab, index) in HistoryTabs"
                :key="index"
                @click="selectedHistoryTab = tab"
                :class="{ active: selectedHistoryTab === tab }"
              >
                {{ tab }}
              </button>
            </li>
          </ul>
          <div v-if="selectedMatchedTab == 'Limit Orders'">Limit Orders</div>
          <div v-if="selectedMatchedTab == 'Market Order'">Market Order</div>
          <div v-if="selectedMatchedTab == 'Traling Stop'">Traling Stop</div>
        </div>
        <!-- order history tab end -->
        <PortfolioDetailModal
          :portfolioDetail="portfolioDetail"
          :currentCoin="currentCoin"
        />
        <!-- cancel selected modal -->
        <b-modal
          class="bs-cancel-all-modal"
          id="cancel-selected-modal"
          :hide-footer="true"
          :hide-header="true"
        >
          <form @submit.prevent="submitCancelSelected">
            <div class="bs-cancel-all-modal">
              <p class="ca-modal-title">
                <span>Cancel</span>
                <span
                  style="cursor: pointer"
                  @click="$bvModal.hide('cancel-selected-modal')"
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div class="cencel-body">
                <p class="content-text text-center">
                  คุณต้องการยกเลิกคำสั่งทั้ง
                  <span>{{ selectedForCancel.length }} รายการ</span> หรือไม่
                </p>
                <div
                  class="row mb-3"
                  style="width: 275px; margin: auto"
                  v-if="!showPin"
                >
                  <div class="col-4 p-3" style="position: relative; top: 5px">
                    <p class="c-title" style="margin-bottom: 16px">PIN</p>
                  </div>
                  <div class="col-8 p-3">
                    <input
                      class="pin-input"
                      :type="cancelShowPin ? 'text' : 'password'"
                      maxlength="6"
                      v-model="cancelSelectedPin"
                      @input="validateInputAll"
                      required
                    />
                    <span
                      @click="cancelShowPin = !cancelShowPin"
                      style="
                        position: absolute;
                        right: 75px;
                        top: 130px;
                        cursor: pointer;
                      "
                    >
                      <svg
                        v-if="cancelShowPin"
                        font-scale="1"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                          fill="#677F8E"
                        />
                      </svg>
                      <svg
                        v-else
                        font-scale="1"
                        width="24"
                        height="24"
                        viewBox="0 0 24 24"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <mask
                          id="mask0_3_154"
                          style="mask-type: alpha"
                          maskUnits="userSpaceOnUse"
                          x="0"
                          y="0"
                          width="24"
                          height="24"
                        >
                          <rect width="24" height="24" fill="#D9D9D9" />
                        </mask>
                        <g mask="url(#mask0_3_154)">
                          <path
                            d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                            fill="#677F8E"
                          />
                        </g>
                      </svg>
                    </span>
                  </div>
                </div>
                <div
                  v-else
                  class="col-12 form-group form-check"
                  style="margin-top: 24px"
                >
                  <label class="checkbox-input" style="margin-bottom: 16px">
                    <input type="checkbox" class="form-check-input" />
                    <span style="color: #9bacb6"
                      >ไม่ต้องแจ้งเตือนอีก</span
                    ></label
                  >
                </div>
                <div
                  class="cencel-btn"
                  style="margin-top: 9px; margin-bottom: 16px"
                >
                  <div>
                    <button
                      @click="cancelChk = true"
                      class="orange-btn"
                      type="submit"
                    >
                      ยืนยัน
                    </button>
                  </div>
                  <div>
                    <button
                      class="gray-btn"
                      @click="
                        $bvModal.hide('cancel-selected-modal');
                        cancelChk = false;
                      "
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>
        <!-- trailing cancel selected modal -->
        <b-modal
          class="bs-cancel-all-modal"
          id="trailing-cancel-selected-modal"
          :hide-footer="true"
          :hide-header="true"
        >
          <form @submit.prevent="trailingSubmitCancelSelected">
            <div class="bs-cancel-all-modal">
              <p class="ca-modal-title">
                <span>Cancel</span>
                <span
                  style="cursor: pointer"
                  @click="$bvModal.hide('trailing-cancel-selected-modal')"
                >
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div class="cencel-body">
                <p class="content-text">
                  คุณต้องการยกเลิกคำสั่งทั้ง
                  <span>{{ trailingSelectedForCancel.length }} รายการ</span>
                  หรือไม่
                </p>
                <div
                  class="row mb-3"
                  style="width: 275px; margin: auto"
                  v-if="!showPin"
                >
                  <div
                    class="col-4 m-0 p-0"
                    style="position: relative; top: 5px"
                  >
                    <p class="c-title" style="margin-bottom: 16px">PIN</p>
                  </div>
                  <div class="col-8">
                    <input
                      class="pin-input"
                      type="password"
                      maxlength="6"
                      v-model="trailingCancelSelectedPin"
                      required
                    />
                  </div>
                </div>
                <div
                  v-else
                  class="col-12 form-group form-check"
                  style="margin-top: 24px"
                >
                  <label class="checkbox-input" style="margin-bottom: 16px">
                    <input type="checkbox" class="form-check-input" />
                    <span style="color: #9bacb6">ไม่ต้องแจ้งเตือนอีก</span>
                  </label>
                </div>
                <div
                  class="cencel-btn"
                  style="margin-top: 9px; margin-bottom: 16px"
                >
                  <div>
                    <button class="orange-btn" type="submit">ยืนยัน</button>
                  </div>
                  <div>
                    <button
                      class="gray-btn"
                      @click="$bvModal.hide('trailing-cancel-selected-modal')"
                    >
                      ยกเลิก
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>
        <!-- cancel modal -->
        <b-modal
          class="bs-cancel-modal"
          id="cancel-modal"
          :hide-footer="true"
          :hide-header="true"
          centered
          @show="bsCancelModal"
        >
          <form @submit.prevent="submitCancel">
            <div class="bs-cancel-modal">
              <p class="c-modal-title">
                <span>
                  Cancel
                  <svg
                    style="margin-left: 6px"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <span @click="$bvModal.hide('cancel-modal')">
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div class="cencel_model_main">
                <div class="row m-0">
                  <div class="col-4 title p-0">
                    <p>Coin</p>
                    <p>Order</p>
                    <p>Price</p>

                    <!-- Amount -->
                    <template v-if="selectedOrder.type === 1">
                      <p>Amount</p>
                    </template>

                    <!-- Stop Loss (Limit Order => Sell)-->
                    <template
                      v-if="
                        selectedOrder.type === 1 &&
                        selectedOrder.direction === 1 &&
                        selectedOrder.stoploss > 0
                      "
                    >
                      <p>Stop Loss</p>
                    </template>

                    <p>Total</p>

                    <!-- Take Profit / Stop Loss -->
                    <template
                      v-if="
                        selectedOrder.direction === 0 &&
                        (selectedOrder.takeprofit > 0 ||
                          selectedOrder.stoploss > 0)
                      "
                    >
                      <p>Order</p>
                      <template v-if="selectedOrder.takeprofit > 0">
                        <p>Take Profit</p>
                      </template>
                      <template v-if="selectedOrder.stoploss > 0">
                        <p>Stop Loss</p>
                      </template>
                      <template v-if="selectedOrder.type === 1">
                        <p>Amount</p>
                      </template>
                    </template>
                  </div>
                  <div class="col-8 value p-0">
                    <p>{{ selectedOrder.symbol }}</p>
                    <p>
                      <span
                        :class="
                          selectedOrder.direction == 0 ? 'buy-tag' : 'sell-tag'
                        "
                      >
                        {{ $MyFunc.BuySellDirection(selectedOrder.direction) }}
                      </span>
                    </p>
                    <!-- Price -->
                    <p>
                      {{ selectedOrder.type === 1 ? $MyFunc.NumberFormat(selectedOrder.price, selectedOrder.bathScale)
              + ' THB
                (' + $MyFunc.BuySellType(selectedOrder.type) + ')' : 'Market Price' }}
                    </p>
                    <!-- Amount -->
                    <template v-if="selectedOrder.type === 1">
                      <p>
                        {{
                          $MyFunc.NumberFormat(
                            selectedOrder.amount * selectedOrder.price,
                            selectedOrder.bathScale
                          )
                        }}
                        THB
                      </p>
                    </template>
                    <!-- Stop Loss (Limit Order => Sell)-->
                    <template
                      v-if="
                        selectedOrder.type === 1 &&
                        selectedOrder.direction === 1 &&
                        selectedOrder.stoploss > 0
                      "
                    >
                      <p>
                        {{
                          $MyFunc.NumberFormat(
                            selectedOrder.stoploss,
                            selectedOrder.bathScale
                          )
                        }}
                        THB
                      </p>
                    </template>
                    <!-- Total -->
                    <p>
                      {{
                        $MyFunc.NumberFormat(
                          selectedOrder.amount,
                          selectedOrder.coinScale
                        )
                      }}
                      {{ selectedOrder.coinSymbol }}
                    </p>

                    <!-- Take Profit / Stop Loss -->
                    <template
                      v-if="
                        selectedOrder.direction === 0 &&
                        (selectedOrder.takeprofit > 0 ||
                          selectedOrder.stoploss > 0)
                      "
                    >
                      <!-- Order -->
                      <p>
                        <span
                          :class="
                            selectedOrder.direction == 0
                              ? 'sell-tag'
                              : 'buy-tag'
                          "
                        >
                          {{
                            $MyFunc.BuySellDirection(
                              selectedOrder.direction == 0 ? 1 : 0
                            )
                          }}
                        </span>
                      </p>

                      <!-- Take Profit -->
                      <template v-if="selectedOrder.takeprofit > 0">
                        <p>
                          {{
                            $MyFunc.NumberFormat(
                              selectedOrder.takeprofit,
                              selectedOrder.bathScale
                            )
                          }}
                        </p>
                      </template>

                      <!-- Stop Loss -->
                      <template v-if="selectedOrder.stoploss > 0">
                        <p>
                          {{
                            $MyFunc.NumberFormat(
                              selectedOrder.stoploss,
                              selectedOrder.bathScale
                            )
                          }}
                        </p>
                      </template>

                      <!-- Amount -->
                      <template v-if="selectedOrder.type === 1">
                        <p>
                          {{
                            $MyFunc.NumberFormat(
                              calAmountTpsl(
                                selectedOrder.amount * selectedOrder.price,
                                selectedOrder.amountCondition
                              ),
                              selectedOrder.bathScale
                            )
                          }}
                          THB
                        </p>
                      </template>
                    </template>
                  </div>
                  <div class="row" v-if="!showPin">
                    <div
                      class="col-4 m-0 p-0"
                      style="position: relative; top: 5px"
                    >
                      <p class="c-title" style="margin-bottom: 16px">PIN</p>
                    </div>
                    <div class="col-8 position-relative">
                      <input
                        class="pin-input"
                        :type="!cancelShowPin ? 'text' : 'password'"
                        v-model="cancelPin"
                        maxlength="6"
                        @input="validateInput"
                        required
                      />
                      <span
                        @click="cancelShowPin = !cancelShowPin"
                        class="pin-toggle-icon"
                      >
                        <svg
                          v-if="cancelShowPin"
                          font-scale="1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                            fill="#677F8E"
                          />
                        </svg>
                        <svg
                          v-else
                          font-scale="1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_3_154"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          >
                            <rect width="24" height="24" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_3_154)">
                            <path
                              d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                              fill="#677F8E"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>

                  <div
                    v-else
                    class="col-12 form-group form-check p-0"
                    style="margin-top: 12px; margin-bottom: 20px"
                  >
                    <label class="checkbox-input m-0">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        style="margin-left: 0px"
                      />
                      <span style="color: #9bacb6">ไม่ต้องแจ้งเตือนอีก</span>
                    </label>
                  </div>

                  <div class="cencel-btn p-0 mt-4">
                    <div>
                      <button
                        @click="cancelChk = true"
                        type="submit"
                        :disabled="btn_disable"
                        class="orange-btn"
                      >
                        ยืนยัน
                      </button>
                    </div>
                    <div>
                      <button
                        @click="
                          $bvModal.hide('cancel-modal');
                          cancelChk = false;
                        "
                        class="gray-btn"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>
        <!-- cancel modal Condition -->
        <b-modal
          class="bs-cancel-modal"
          id="cancel-modal-condition"
          :hide-footer="true"
          :hide-header="true"
          centered
          @show="bsCancelModal"
        >
          <form @submit.prevent="submitCancelOrderCondition">
            <div class="bs-cancel-modal">
              <p class="c-modal-title">
                <span>
                  Cancel
                  <svg
                    style="margin-left: 6px"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <span @click="$bvModal.hide('cancel-modal-condition')">
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div class="cencel_model_main">
                <div class="row m-0">
                  <div class="col-4 title p-0">
                    <p>Coin</p>
                    <p>Order</p>
                    <p>Price</p>

                    <!-- Amount -->
                    <template v-if="selectedOrderCondition.type === 1">
                      <p>Amount</p>
                    </template>

                    <!-- Stop Loss (Limit Order => Sell)-->
                    <template
                      v-if="
                        selectedOrderCondition.type === 1 &&
                        selectedOrderCondition.direction === 1 &&
                        selectedOrderCondition.stoploss > 0
                      "
                    >
                      <p>Stop Loss</p>
                    </template>

                    <p>Total</p>

                    <!-- Take Profit / Stop Loss -->
                    <template
                      v-if="
                        selectedOrderCondition.direction === 0 &&
                        (selectedOrderCondition.takeprofit > 0 ||
                          selectedOrderCondition.stoploss > 0)
                      "
                    >
                      <p>Order</p>
                      <template v-if="selectedOrderCondition.takeprofit > 0">
                        <p>Take Profit</p>
                      </template>
                      <template v-if="selectedOrderCondition.stoploss > 0">
                        <p>Stop Loss</p>
                      </template>
                      <template v-if="selectedOrderCondition.type === 1">
                        <p>Amount</p>
                      </template>
                    </template>
                  </div>
                  <div class="col-8 value p-0">
                    <p>{{ selectedOrderCondition.symbol }}</p>
                    <p>
                      <span
                        :class="
                          selectedOrderCondition.direction == 0
                            ? 'buy-tag'
                            : 'sell-tag'
                        "
                      >
                        {{
                          $MyFunc.BuySellDirection(
                            selectedOrderCondition.direction
                          )
                        }}
                      </span>
                    </p>
                    <!-- Price -->
                    <p>
                      {{
                        selectedOrderCondition.type === 1
                          ? $MyFunc.NumberFormat(
                              selectedOrderCondition.price,
                              selectedOrderCondition.bathScale
                            ) +
                            " THB (" +
                            $MyFunc.BuySellType(selectedOrderCondition.type) +
                            ")"
                          : "Market Price"
                      }}
                    </p>
                    <!-- Amount -->
                    <template v-if="selectedOrderCondition.type === 1">
                      <p>
                        {{
                          $MyFunc.NumberFormat(
                            selectedOrderCondition.amount *
                              selectedOrderCondition.price,
                            selectedOrderCondition.bathScale
                          )
                        }}
                        THB
                      </p>
                    </template>
                    <!-- Stop Loss (Limit Order => Sell)-->
                    <template
                      v-if="
                        selectedOrderCondition.type === 1 &&
                        selectedOrderCondition.direction === 1 &&
                        selectedOrderCondition.stoploss > 0
                      "
                    >
                      <p>
                        {{
                          $MyFunc.NumberFormat(
                            selectedOrderCondition.stoploss,
                            selectedOrderCondition.bathScale
                          )
                        }}
                        THB
                      </p>
                    </template>
                    <!-- Total -->
                    <p>
                      {{
                        $MyFunc.NumberFormat(
                          selectedOrderCondition.amount,
                          selectedOrderCondition.coinScale
                        )
                      }}
                      {{ selectedOrderCondition.coinSymbol }}
                    </p>

                    <!-- Take Profit / Stop Loss -->
                    <template
                      v-if="
                        selectedOrderCondition.direction === 0 &&
                        (selectedOrderCondition.takeprofit > 0 ||
                          selectedOrderCondition.stoploss > 0)
                      "
                    >
                      <!-- Order -->
                      <p>
                        <span
                          :class="
                            selectedOrderCondition.direction == 0
                              ? 'sell-tag'
                              : 'buy-tag'
                          "
                        >
                          {{
                            $MyFunc.BuySellDirection(
                              selectedOrderCondition.direction == 0 ? 1 : 0
                            )
                          }}
                        </span>
                      </p>

                      <!-- Take Profit -->
                      <template v-if="selectedOrderCondition.takeprofit > 0">
                        <p>
                          {{
                            $MyFunc.NumberFormat(
                              selectedOrderCondition.takeprofit,
                              selectedOrderCondition.bathScale
                            )
                          }}
                          (+{{ selectedOrderCondition.takeprofitPercent }}%)
                        </p>
                      </template>

                      <!-- Stop Loss -->
                      <template v-if="selectedOrderCondition.stoploss > 0">
                        <p>
                          {{
                            $MyFunc.NumberFormat(
                              selectedOrderCondition.stoploss,
                              selectedOrderCondition.bathScale
                            )
                          }}
                          (-{{ selectedOrderCondition.stoplossPercent }}%)
                        </p>
                      </template>

                      <!-- Amount -->
                      <template v-if="selectedOrderCondition.type === 1">
                        <p>
                          {{
                            $MyFunc.NumberFormat(
                              calAmountTpsl(
                                selectedOrderCondition.amount *
                                  selectedOrderCondition.price,
                                selectedOrderCondition.amountCondition
                              ),
                              selectedOrderCondition.bathScale
                            )
                          }}
                          THB
                        </p>
                      </template>
                    </template>
                  </div>

                  <div class="row" v-if="!showPin">
                    <div
                      class="col-4 m-0 p-0"
                      style="position: relative; top: 5px"
                    >
                      <p class="c-title" style="margin-bottom: 16px">PIN</p>
                    </div>
                    <div class="col-8 position-relative">
                      <input
                        class="pin-input"
                        :type="!cancelShowPin ? 'text' : 'password'"
                        v-model="cancelConditionPin"
                        maxlength="6"
                        @input="validateConditionInput"
                        required
                      />
                      <span
                        @click="cancelShowPin = !cancelShowPin"
                        class="pin-toggle-icon"
                      >
                        <svg
                          v-if="cancelShowPin"
                          font-scale="1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                            fill="#677F8E"
                          />
                        </svg>
                        <svg
                          v-else
                          font-scale="1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_3_154"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          >
                            <rect width="24" height="24" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_3_154)">
                            <path
                              d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                              fill="#677F8E"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    v-else
                    class="col-12 form-group form-check p-0"
                    style="margin-top: 12px; margin-bottom: 20px"
                  >
                    <label class="checkbox-input m-0">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        style="margin-left: 0px"
                      />
                      <span style="color: #9bacb6">ไม่ต้องแจ้งเตือนอีก</span>
                    </label>
                  </div>
                  <div class="cencel-btn p-0 mt-4">
                    <div>
                      <button
                        @click="cancelChk = true"
                        type="submit"
                        :disabled="btn_disable"
                        class="orange-btn"
                      >
                        ยืนยัน
                      </button>
                    </div>
                    <div>
                      <button
                        @click="
                          $bvModal.hide('cancel-modal-condition');
                          cancelChk = false;
                        "
                        class="gray-btn"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>
        <!-- cancel modal market Condition -->
        <b-modal
          class="bs-cancel-modal"
          id="cancel-modal-market-condition"
          :hide-footer="true"
          :hide-header="true"
          centered
          @show="bsCancelModal"
        >
          <form @submit.prevent="submitCancelMarketOrderCondition">
            <div class="bs-cancel-modal">
              <p class="c-modal-title">
                <span>
                  Cancel
                  <svg
                    style="margin-left: 6px"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <span @click="$bvModal.hide('cancel-modal-market-condition')">
                  <svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div class="cencel_model_main">
                <div class="row m-0">
                  <div class="col-4 title p-0">
                    <p>Coin</p>
                    <p>Order</p>
                    <p>Price</p>

                    <!-- Amount -->
                    <template v-if="selectedMarketOrderCondition.type === 1">
                      <p>Amount</p>
                    </template>

                    <!-- Stop Loss (Limit Order => Sell)-->
                    <template
                      v-if="
                        selectedMarketOrderCondition.type === 1 &&
                        selectedMarketOrderCondition.direction === 1 &&
                        selectedMarketOrderCondition.stoploss > 0
                      "
                    >
                      <p>Stop Loss</p>
                    </template>

                    <p>Total</p>

                    <!-- Take Profit / Stop Loss -->
                    <template
                      v-if="
                        selectedMarketOrderCondition.direction === 0 &&
                        (selectedMarketOrderCondition.takeprofit > 0 ||
                          selectedMarketOrderCondition.stoploss > 0)
                      "
                    >
                      <p>Order</p>
                      <template
                        v-if="selectedMarketOrderCondition.takeprofit > 0"
                      >
                        <p>Take Profit</p>
                      </template>
                      <template
                        v-if="selectedMarketOrderCondition.stoploss > 0"
                      >
                        <p>Stop Loss</p>
                      </template>
                      <template v-if="selectedMarketOrderCondition.type === 1">
                        <p>Amount</p>
                      </template>
                    </template>
                  </div>
                  <div class="col-8 value p-0">
                    <p>{{ selectedMarketOrderCondition.symbol }}</p>
                    <p>
                      <span
                        :class="
                          selectedMarketOrderCondition.direction == 0
                            ? 'buy-tag'
                            : 'sell-tag'
                        "
                      >
                        {{
                          $MyFunc.BuySellDirection(
                            selectedMarketOrderCondition.direction
                          )
                        }}
                      </span>
                    </p>
                    <!-- Price -->
                    <!-- <p>{{ $MyFunc.NumberFormat(selectedMarketOrderCondition.averageFillPrice, selectedMarketOrderCondition.bathScale) }} THB &nbsp; {{ $MyFunc.BuySellType(selectedMarketOrderCondition.type) }}</p> -->
                    <p>
                      {{
                        selectedMarketOrderCondition.type === 1
                          ? $MyFunc.NumberFormat(
                              selectedMarketOrderCondition.price,
                              selectedMarketOrderCondition.bathScale
                            ) +
                            " THB (" +
                            $MyFunc.BuySellType(
                              selectedMarketOrderCondition.type
                            ) +
                            ")"
                          : "Market Price"
                      }}
                    </p>
                    <!-- Amount -->
                    <!-- <p>{{ $MyFunc.NumberFormat(selectedMarketOrderCondition.amount * selectedMarketOrderCondition.averageFillPrice, selectedMarketOrderCondition.bathScale) }} THB</p> -->
                    <template v-if="selectedMarketOrderCondition.type === 1">
                      <p>
                        {{
                          $MyFunc.NumberFormat(
                            selectedMarketOrderCondition.amount *
                              selectedMarketOrderCondition.price,
                            selectedMarketOrderCondition.bathScale
                          )
                        }}
                        THB
                      </p>
                    </template>
                    <!-- Stop Loss (Limit Order => Sell)-->
                    <template
                      v-if="
                        selectedMarketOrderCondition.type === 1 &&
                        selectedMarketOrderCondition.direction === 1 &&
                        selectedMarketOrderCondition.stoploss > 0
                      "
                    >
                      <p>
                        {{
                          $MyFunc.NumberFormat(
                            selectedMarketOrderCondition.stoploss,
                            selectedMarketOrderCondition.bathScale
                          )
                        }}
                        THB
                      </p>
                    </template>
                    <!-- Total -->
                    <p>
                      {{
                        $MyFunc.NumberFormat(
                          selectedMarketOrderCondition.amount,
                          selectedMarketOrderCondition.coinScale
                        )
                      }}
                      {{ selectedMarketOrderCondition.coinSymbol }}
                    </p>

                    <!-- Take Profit / Stop Loss -->
                    <template
                      v-if="
                        selectedMarketOrderCondition.direction === 0 &&
                        (selectedMarketOrderCondition.takeprofit > 0 ||
                          selectedMarketOrderCondition.stoploss > 0)
                      "
                    >
                      <!-- Order -->
                      <p>
                        <span
                          :class="
                            selectedMarketOrderCondition.direction == 0
                              ? 'sell-tag'
                              : 'buy-tag'
                          "
                        >
                          {{
                            $MyFunc.BuySellDirection(
                              selectedMarketOrderCondition.direction == 0
                                ? 1
                                : 0
                            )
                          }}
                        </span>
                      </p>

                      <!-- Take Profit -->
                      <template
                        v-if="selectedMarketOrderCondition.takeprofit > 0"
                      >
                        <p>
                          {{
                            $MyFunc.NumberFormat(
                              selectedMarketOrderCondition.takeprofit,
                              selectedMarketOrderCondition.bathScale
                            )
                          }}
                          (+{{
                            selectedMarketOrderCondition.takeprofitPercent
                          }}%)
                        </p>
                      </template>

                      <!-- Stop Loss -->
                      <template
                        v-if="selectedMarketOrderCondition.stoploss > 0"
                      >
                        <p>
                          {{
                            $MyFunc.NumberFormat(
                              selectedMarketOrderCondition.stoploss,
                              selectedMarketOrderCondition.bathScale
                            )
                          }}
                          (-{{ selectedMarketOrderCondition.stoplossPercent }}%)
                        </p>
                      </template>

                      <!-- Amount -->
                      <template v-if="selectedMarketOrderCondition.type === 1">
                        <p>
                          {{
                            $MyFunc.NumberFormat(
                              calAmountTpsl(
                                selectedMarketOrderCondition.amount *
                                  selectedMarketOrderCondition.price,
                                selectedMarketOrderCondition.amountCondition
                              ),
                              selectedMarketOrderCondition.bathScale
                            )
                          }}
                          THB
                        </p>
                      </template>
                    </template>
                  </div>

                  <div class="row" v-if="!showPin">
                    <div
                      class="col-4 m-0 p-0"
                      style="position: relative; top: 5px"
                    >
                      <p class="c-title" style="margin-bottom: 16px">PIN</p>
                    </div>
                    <div class="col-8 position-relative">
                      <input
                        class="pin-input"
                        :type="!cancelShowPin ? 'text' : 'password'"
                        v-model="cancelMarketConditionPin"
                        maxlength="6"
                        @input="validateMarketConditionInput"
                        required
                      />
                      <span
                        @click="cancelShowPin = !cancelShowPin"
                        class="pin-toggle-icon"
                      >
                        <svg
                          v-if="cancelShowPin"
                          font-scale="1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                            fill="#677F8E"
                          />
                        </svg>
                        <svg
                          v-else
                          font-scale="1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_3_154"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          >
                            <rect width="24" height="24" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_3_154)">
                            <path
                              d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                              fill="#677F8E"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    v-else
                    class="col-12 form-group form-check p-0"
                    style="margin-top: 12px; margin-bottom: 20px"
                  >
                    <label class="checkbox-input m-0">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        style="margin-left: 0px"
                      />
                      <span style="color: #9bacb6">ไม่ต้องแจ้งเตือนอีก</span>
                    </label>
                  </div>
                  <div class="cencel-btn p-0 mt-4">
                    <div>
                      <button
                        @click="cancelChk = true"
                        type="submit"
                        :disabled="btn_disable"
                        class="orange-btn"
                      >
                        ยืนยัน
                      </button>
                    </div>
                    <div>
                      <button
                        @click="
                          $bvModal.hide('cancel-modal-market-condition');
                          cancelChk = false;
                        "
                        class="gray-btn"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>
        <!--trailing cancel modal -->
        <b-modal
          class="bs-cancel-modal"
          id="trailing-cancel-modal"
          :hide-footer="true"
          :hide-header="true"
          centered
        >
          <form @submit.prevent="trailingSubmitCancel">
            <div class="bs-cancel-modal">
              <p class="c-modal-title">
                <span
                  >Cancel<svg
                    style="margin-left: 6px"
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <span @click="$bvModal.hide('trailing-cancel-modal')"
                  ><svg
                    width="14"
                    height="15"
                    viewBox="0 0 14 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </p>
              <div class="cencel_model_main">
                <div class="row m-0">
                  <div class="col-4 title p-0">
                    <p>Coin</p>
                    <p>Order</p>
                    <p>Price</p>
                    <p>Amount</p>
                    <p>Total</p>
                  </div>
                  <div class="col-8 value p-0">
                    <p>
                      {{ trailingSelectedOrder.symbol }}
                    </p>
                    <p>
                      <span
                        :class="
                          trailingSelectedOrder.direction == 0
                            ? 'buy-tag'
                            : 'sell-tag'
                        "
                      >
                        {{
                          $MyFunc.BuySellDirection(
                            trailingSelectedOrder.direction
                          )
                        }}
                      </span>
                    </p>
                    <p>
                      {{
                        $MyFunc.NumberFormat(
                          trailingSelectedOrder.price,
                          trailingSelectedOrder.coinScale
                        )
                      }}
                      THB
                    </p>
                    <p>
                      {{
                        $MyFunc.NumberFormat(
                          trailingSelectedOrder.amount,
                          trailingSelectedOrder.coinScale
                        )
                      }}
                      THB
                    </p>
                    <p>
                      {{
                        $MyFunc.NumberFormat(
                          trailingSelectedOrder.amount /
                            trailingSelectedOrder.price,
                          trailingSelectedOrder.coinScale
                        )
                      }}
                      {{ trailingSelectedOrder.symbol }}
                    </p>
                  </div>
                  <div class="row mb-3" v-if="!showPin">
                    <div
                      class="col-4 m-0 p-0"
                      style="position: relative; top: 5px"
                    >
                      <p class="c-title" style="margin-bottom: 16px">PIN</p>
                    </div>
                    <div class="col-8">
                      <input
                        class="pin-input"
                        :type="cancelShowPin ? 'text' : 'password'"
                        maxlength="6"
                        required
                        v-model="trailingCancelPin"
                      />
                      <span
                        @click="cancelShowPin = !cancelShowPin"
                        style="
                          position: absolute;
                          right: 95px;
                          top: 245px;
                          cursor: pointer;
                        "
                      >
                        <svg
                          v-if="cancelShowPin"
                          font-scale="1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12 5.99922C15.79 5.99922 19.17 8.12922 20.82 11.4992C20.23 12.7192 19.4 13.7692 18.41 14.6192L19.82 16.0292C21.21 14.7992 22.31 13.2592 23 11.4992C21.27 7.10922 17 3.99922 12 3.99922C10.73 3.99922 9.51 4.19922 8.36 4.56922L10.01 6.21922C10.66 6.08922 11.32 5.99922 12 5.99922ZM10.93 7.13922L13 9.20922C13.57 9.45922 14.03 9.91922 14.28 10.4892L16.35 12.5592C16.43 12.2192 16.49 11.8592 16.49 11.4892C16.5 9.00922 14.48 6.99922 12 6.99922C11.63 6.99922 11.28 7.04922 10.93 7.13922ZM2.01 3.86922L4.69 6.54922C3.06 7.82922 1.77 9.52922 1 11.4992C2.73 15.8892 7 18.9992 12 18.9992C13.52 18.9992 14.98 18.7092 16.32 18.1792L19.74 21.5992L21.15 20.1892L3.42 2.44922L2.01 3.86922ZM9.51 11.3692L12.12 13.9792C12.08 13.9892 12.04 13.9992 12 13.9992C10.62 13.9992 9.5 12.8792 9.5 11.4992C9.5 11.4492 9.51 11.4192 9.51 11.3692V11.3692ZM6.11 7.96922L7.86 9.71922C7.63 10.2692 7.5 10.8692 7.5 11.4992C7.5 13.9792 9.52 15.9992 12 15.9992C12.63 15.9992 13.23 15.8692 13.77 15.6392L14.75 16.6192C13.87 16.8592 12.95 16.9992 12 16.9992C8.21 16.9992 4.83 14.8692 3.18 11.4992C3.88 10.0692 4.9 8.88922 6.11 7.96922Z"
                            fill="#677F8E"
                          />
                        </svg>
                        <svg
                          v-else
                          font-scale="1"
                          width="24"
                          height="24"
                          viewBox="0 0 24 24"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <mask
                            id="mask0_3_154"
                            style="mask-type: alpha"
                            maskUnits="userSpaceOnUse"
                            x="0"
                            y="0"
                            width="24"
                            height="24"
                          >
                            <rect width="24" height="24" fill="#D9D9D9" />
                          </mask>
                          <g mask="url(#mask0_3_154)">
                            <path
                              d="M12.0031 15.85C13.2198 15.85 14.2491 15.425 15.0911 14.575C15.9325 13.725 16.3531 12.7 16.3531 11.5C16.3531 10.2833 15.9325 9.254 15.0911 8.412C14.2491 7.57067 13.2198 7.15 12.0031 7.15C10.8031 7.15 9.77813 7.57067 8.92813 8.412C8.07813 9.254 7.65313 10.2833 7.65313 11.5C7.65313 12.7 8.07813 13.725 8.92813 14.575C9.77813 15.425 10.8031 15.85 12.0031 15.85ZM12.0031 13.85C11.3531 13.85 10.7991 13.6207 10.3411 13.162C9.88246 12.704 9.65312 12.15 9.65312 11.5C9.65312 10.85 9.88246 10.2957 10.3411 9.837C10.7991 9.379 11.3531 9.15 12.0031 9.15C12.6531 9.15 13.2075 9.379 13.6661 9.837C14.1241 10.2957 14.3531 10.85 14.3531 11.5C14.3531 12.15 14.1241 12.704 13.6661 13.162C13.2075 13.6207 12.6531 13.85 12.0031 13.85ZM12.0031 19.25C9.51979 19.25 7.26979 18.5377 5.25313 17.113C3.23646 15.6877 1.76146 13.8167 0.828125 11.5C1.76146 9.18333 3.23646 7.31233 5.25313 5.887C7.26979 4.46233 9.51979 3.75 12.0031 3.75C14.4865 3.75 16.7365 4.46233 18.7531 5.887C20.7698 7.31233 22.2448 9.18333 23.1781 11.5C22.2448 13.8167 20.7698 15.6877 18.7531 17.113C16.7365 18.5377 14.4865 19.25 12.0031 19.25Z"
                              fill="#677F8E"
                            />
                          </g>
                        </svg>
                      </span>
                    </div>
                  </div>
                  <div
                    v-else
                    class="col-12 form-group form-check p-0"
                    style="margin-top: 12px; margin-bottom: 20px"
                  >
                    <label class="checkbox-input m-0">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        style="margin-left: 0px"
                      />
                      <span style="color: #9bacb6">ไม่ต้องแจ้งเตือนอีก</span>
                    </label>
                  </div>
                  <div class="cencel-btn p-0">
                    <div>
                      <button
                        @click="cancelTls = true"
                        type="submit"
                        :disabled="btn_disable"
                        class="orange-btn"
                      >
                        ยืนยัน
                      </button>
                    </div>
                    <div>
                      <button
                        @click="
                          $bvModal.hide('trailing-cancel-modal');
                          cancelTls = false;
                        "
                        class="gray-btn"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </b-modal>
      </div>
    </div>

    <div class="bs-bb" v-if="priceInfo">
      <div class="row">
        <div class="col-auto" :class="isOnline ? 'green' : 'red'">
          <svg
            width="10"
            height="10"
            viewBox="0 0 10 10"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M0.916626 9.66683V6.16683H2.66663V9.66683H0.916626ZM4.41663 9.66683V3.25016H6.16663V9.66683H4.41663ZM7.91663 9.66683V0.333496H9.66663V9.66683H7.91663Z"
              fill="#677F8E"
            />
          </svg>
          <span class="ms-1">{{
            isOnline ? "Stable Connection" : "Unstable Connection"
          }}</span>
        </div>

        <div class="col marquee-container">
          <div class="marquee-content">
            <span v-for="(item, index) in coinmarketFooter" :key="index">
              <span>{{ item?.symbol }}</span>
              <span style="color: #9bacb6">
                {{ $MyFunc.NumberFormat(item?.price, item.bathScale) }}</span
              >
              <span
                :class="
                  item?.pChg > 0
                    ? 'live-amount-value green'
                    : 'live-amount-value red'
                "
              >
                ({{ $MyFunc.PercentChg(item?.pChg) }}%)
              </span>
              <span v-if="index < coinMarket.length - 1" class="gray">|</span>
            </span>
          </div>
        </div>

        <div class="col-auto">
          <router-link
            to="/download"
            class="orange"
            style="
              border-right: 1px solid #28363e;
              padding-right: 7px;
              color:#09BE8B;
            "
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.50456 9.66781C1.18372 9.66781 0.909168 9.55367 0.680891 9.32539C0.452224 9.09672 0.337891 8.82197 0.337891 8.50114V6.75114H1.50456V8.50114H8.50456V6.75114H9.67122V8.50114C9.67122 8.82197 9.55709 9.09672 9.32881 9.32539C9.10014 9.55367 8.82539 9.66781 8.50456 9.66781H1.50456ZM5.00456 7.33447L2.08789 4.41781L2.90456 3.57197L4.42122 5.08864V0.334473H5.58789V5.08864L7.10456 3.57197L7.92122 4.41781L5.00456 7.33447Z"
                fill="#677F8E"
              />
            </svg>
            Download
          </router-link>
          <a
            :href="liveSupportLink"
            target="_blank"
            class="orange"
            style="
              padding-right: 12px;
              padding-left: 6px;
              color: #09BE8B;
            "
          >
            <svg
              width="10"
              height="10"
              viewBox="0 0 10 10"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M3.13594 4.53398C3.26816 4.53398 3.37907 4.48918 3.46867 4.39958C3.55796 4.3103 3.6026 4.19954 3.6026 4.06732C3.6026 3.9351 3.55796 3.82418 3.46867 3.73458C3.37907 3.6453 3.26816 3.60065 3.13594 3.60065C3.00372 3.60065 2.8928 3.6453 2.8032 3.73458C2.71392 3.82418 2.66927 3.9351 2.66927 4.06732C2.66927 4.19954 2.71392 4.3103 2.8032 4.39958C2.8928 4.48918 3.00372 4.53398 3.13594 4.53398ZM5.0026 4.53398C5.13483 4.53398 5.24574 4.48918 5.33534 4.39958C5.42463 4.3103 5.46927 4.19954 5.46927 4.06732C5.46927 3.9351 5.42463 3.82418 5.33534 3.73458C5.24574 3.6453 5.13483 3.60065 5.0026 3.60065C4.87038 3.60065 4.75963 3.6453 4.67034 3.73458C4.58074 3.82418 4.53594 3.9351 4.53594 4.06732C4.53594 4.19954 4.58074 4.3103 4.67034 4.39958C4.75963 4.48918 4.87038 4.53398 5.0026 4.53398ZM6.86927 4.53398C7.00149 4.53398 7.11225 4.48918 7.20154 4.39958C7.29114 4.3103 7.33594 4.19954 7.33594 4.06732C7.33594 3.9351 7.29114 3.82418 7.20154 3.73458C7.11225 3.6453 7.00149 3.60065 6.86927 3.60065C6.73705 3.60065 6.62629 3.6453 6.537 3.73458C6.4474 3.82418 6.4026 3.9351 6.4026 4.06732C6.4026 4.19954 6.4474 4.3103 6.537 4.39958C6.62629 4.48918 6.73705 4.53398 6.86927 4.53398ZM0.335938 9.66732V1.26732C0.335938 1.01065 0.427404 0.790851 0.610338 0.607918C0.79296 0.425295 1.0126 0.333984 1.26927 0.333984H8.73594C8.99261 0.333984 9.21241 0.425295 9.39534 0.607918C9.57796 0.790851 9.66927 1.01065 9.66927 1.26732V6.86732C9.66927 7.12398 9.57796 7.34378 9.39534 7.52672C9.21241 7.70934 8.99261 7.80065 8.73594 7.80065H2.2026L0.335938 9.66732ZM1.26927 7.41565L1.8176 6.86732H8.73594V1.26732H1.26927V7.41565Z"
                fill="#677F8E"
              />
            </svg>
            Live Support
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import { mapMutations } from "vuex";
import moment from "moment";
import PortfolioDetailModal from "@/pages/exchange/components/PortfolioDetailModal.vue";
import EventBus from "@/event-bus";

export default {
  name: "ExchangeFooter",
  components: { PortfolioDetailModal },
  props: [
    "priceInfo",
    "coinMarket",
    "currentCoin",
    "currentScale",
    "assetsWallet",
    "iframeContainer",
  ],
  data() {
    return {
      lang: {
        formatLocale: {
          firstDayOfWeek: 1,
        },
        monthBeforeYear: false,
      },
      historyTime: null,
      historyTimeplaceholder: null,
      show_port: false,
      isLoading: false,
      memberId: null,
      deleyAssets: false,
      open_limit_market: [false, false, false, false, false],
      footertabs: ["Open Orders", "Matched", "Portfolio"],
      selectedfooter: "Open Orders",
      matchedTabs: ["Limit Orders", "Market Order", "Traling Stop"],
      selectedMatchedTab: "Limit Orders",
      openordertabs: ["Limit Orders", "Trailing Stop"],
      selectedopenorder: "Limit Orders",
      historyTabs: [],
      portfolioData: [],
      matched_limit_orders: [],
      matched_market_orders: [],
      isOnline: window.navigator.onLine, // initial value based on window.navigator.onLine property
      selectedOrder: {},
      selectedOrderCondition: {},
      selectedMarketOrderCondition: {},
      trailingSelectedOrder: {},
      btn_disable: false,
      selectedForCancel: [],
      trailingSelectedForCancel: [],
      selectAll: false,
      trailingSelectAll: false,
      portfolioDetail: {},
      assetItems: [],
      trailingCancelPin: null,
      trailingCancelSelectedPin: null,
      isUserLogin: false,
      trailingStopData: [],
      trailingStopDataOrder: [],
      cancelChk: false,
      cancelTls: false,
      cancelShowPin: false,
      cancelPin: null,
      cancelConditionPin: null,
      cancelMarketConditionPin: null,
      cancelSelectedPin: null,
      liveSupportLink: null,
      startDate: moment().format("YYYY-MM-DD") + "T00:00:00",
      endDate: moment().format("YYYY-MM-DD") + "T23:59:59",
      data_chart: null,
    };
  },
  watch: {
    assetsWallet: {
      handler: function (val, oldVal) {
        this.updatedData();
      },
      immediate: true,
    },
  },
  computed: {
    matchedTrailingStopData() {
      return this.trailingStopData.filter((item) => item.status === 2);
    },
    coinScale() {
      return this.currentScale.coinScale;
    },
    bathScale() {
      return this.currentScale.bathScale;
    },
    coinmarketFooter() {
      return this.coinMarket;
    },
    showPin() {
      let userpin = localStorage.getItem("user-pin");
      if (userpin) {
        return userpin;
      } else {
        return false;
      }
    },
  },
  methods: {
    getHistoryTime() {
      let D1 = moment().format("YYYY-MM-DD") + " 00:00:00";
      let D2 = moment().format("YYYY-MM-DD") + " 23:59:59";
      this.historyTime = [D1, D2];
      this.historyTimeplaceholder =
        moment(this.historyTime[0]).format(this.TIMEPICKER) +
        " ~ " +
        moment(this.historyTime[1]).format(this.TIMEPICKER);
    },
    getLiveSupportLink() {
      const url = JSON.parse(localStorage.getItem("web-url"));
      if (url?.aboutUs) {
        this.liveSupportLink = url.aboutUs;
      } else {
        this.liveSupportLink = "/exchange";
      }
    },
    calAmountTpsl(amount, persent) {
      let pValue = persent ? Number(persent) : Number(100);
      let calcPercent = (parseFloat(amount) * pValue) / 100;
      let balance = parseFloat(amount - calcPercent);
      return parseFloat(amount - balance);
    },
    bsCancelModal() {
      this.cancelShowPin = true;
      this.cancelPin = null;
      this.cancelConditionPin = null;
      this.cancelMarketConditionPin = null;
    },
    handleOnlineStatusChange() {
      // Update the isOnline property when online/offline status changes
      this.isOnline = window.navigator.onLine;
    },
    getValue(item) {
      if (
        item.profitPrice > 0 &&
        item.stopLossPrice > 0 &&
        item.profitPercent &&
        item.lossPercent
      ) {
        return `TP: ${this.$MyFunc.NumberFormat(
          item.profitPrice,
          this.bathScale
        )}(+${item.profitPercent}) | SL: ${this.$MyFunc.NumberFormat(
          item.stopLossPrice,
          this.bathScale
        )}(-${item.lossPercent})`;
      } else if (item.profitPrice > 0 && item.profitPercent) {
        return `TP: ${this.$MyFunc.NumberFormat(
          item.profitPrice,
          this.bathScale
        )}(+${item.profitPercent})`;
      } else if (item.profitPrice > 0 && !item.profitPercent) {
        return `TP: ${this.$MyFunc.NumberFormat(
          item.profitPrice,
          this.bathScale
        )}`;
      } else if (item.stopLossPrice > 0 && !item.lossPercent) {
        return `TP: ${this.$MyFunc.NumberFormat(
          item.profitPrice,
          this.bathScale
        )}`;
      } else if (item.stopLossPrice > 0 && item.lossPercent) {
        return ` SL: ${this.$MyFunc.NumberFormat(
          item.stopLossPrice,
          this.bathScale
        )}(-${item.lossPercent})`;
      } else if (!item.stopLossPrice > 0 && !item.profitPrice > 0) {
        return "-";
      }
    },
    exactDifference(timestamp) {
      const expirationDate = moment.unix(timestamp);
      const now = moment();
      const daysDifference = Math.abs(expirationDate.diff(now, "days"));
      const monthsDifference = Math.abs(expirationDate.diff(now, "months"));
      if (monthsDifference > 0) {
        return `${monthsDifference} month${monthsDifference > 1 ? "s" : ""}`;
      }
      return `${daysDifference} day${daysDifference > 1 ? "s" : ""}`;
    },
    formatExpirationDate(timestamp) {
      return moment.unix(timestamp).add(1, "months").format(this.TIMEPICKER);
    },
    selectAllOrders() {
      if (this.selectAll) {
        this.selectedForCancel = this.$store.state.limitOrderData.map(
          (item) => item.orderId
        );
      } else {
        this.selectedForCancel = [];
      }
    },
    trailingSelectAllOrders() {
      if (this.trailingSelectAll) {
        this.trailingSelectedForCancel = this.trailingStopDataOrder.map(
          (item) => item.orderId
        );
      } else {
        this.trailingSelectedForCancel = [];
      }
    },
    getSelectedForCancel() {
      //console.log(this.selectedForCancel);
    },
    getTrailingSelectedForCancel() {
      //console.log(this.trailingSelectedForCancel);
    },
    cancelOrderConfirmation(item) {
      this.$bvModal.show("cancel-modal");
      this.selectedOrder = item;
    },
    cancelOrderCondition(item, orderId) {
      this.$bvModal.show("cancel-modal-condition");
      this.selectedOrderCondition = item;
      this.orderConditionId = orderId;
    },
    cancelMarketOrderCondition(item, orderId) {
      this.$bvModal.show("cancel-modal-market-condition");
      this.selectedMarketOrderCondition = item;
      this.orderMarketConditionId = orderId;
    },
    trailingCancelOrderConfirmation(item) {
      this.$bvModal.show("trailing-cancel-modal");
      this.trailingSelectedOrder = item;
    },
    submitCancel() {
      if (this.cancelChk) {
        HTTP.post("/uc/validate-pin", {
          pin: this.showPin ? atob(this.showPin) : this.cancelPin,
        })
          .then((res) => {
            if (res.data.code === 0) {
              this.$bvModal.hide("cancel-modal");
              this.cancelOrder();
            } else if (res.data.code === 4000) {
              this.$store.dispatch("logout");
            } else {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    submitCancelOrderCondition() {
      if (this.cancelChk) {
        HTTP.post("/uc/validate-pin", {
          pin: this.showPin ? atob(this.showPin) : this.cancelConditionPin,
        })
          .then((res) => {
            if (res.data.code === 0) {
              this.$bvModal.hide("cancel-modal-condition");
              this.cancelSelectedOrdersCondition();
            } else if (res.data.code === 4000) {
              this.$store.dispatch("logout");
            } else {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    submitCancelMarketOrderCondition() {
      if (this.cancelChk) {
        HTTP.post("/uc/validate-pin", {
          pin: this.showPin
            ? atob(this.showPin)
            : this.cancelMarketConditionPin,
        })
          .then((res) => {
            if (res.data.code === 0) {
              this.$bvModal.hide("cancel-modal-market-condition");
              this.cancelSelectedMarketOrdersCondition();
            } else if (res.data.code === 4000) {
              this.$store.dispatch("logout");
            } else {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    trailingSubmitCancel() {
      if (this.cancelTls) {
        HTTP.post("/uc/validate-pin", {
          pin: this.showPin ? atob(this.showPin) : this.trailingCancelPin,
        })
          .then((res) => {
            if (res.data.code === 0) {
              this.$bvModal.hide("trailing-cancel-modal");
              this.trailingCancelOrder();
            } else if (res.data.code === 4000) {
              this.$store.dispatch("logout");
            } else {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    submitCancelSelected() {
      if (this.cancelChk) {
        HTTP.post("/uc/validate-pin", {
          pin: this.showPin ? atob(this.showPin) : this.cancelSelectedPin,
        })
          .then((res) => {
            if (res.data.code === 0) {
              this.$bvModal.hide("cancel-selected-modal");
              this.cancelSelectedOrders();
            } else if (res.data.code === 4000) {
              this.$store.dispatch("logout");
            } else {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    //
    trailingSubmitCancelSelected() {
      if (this.showPin) {
        HTTP.post("/uc/validate-pin", {
          pin: this.trailingCancelSelectedPin,
        })
          .then((res) => {
            if (res.data.code === 0) {
              this.trailingCancelSelectedPin = null;
              this.$bvModal.hide("trailing-cancel-selected-modal");
              this.trailingCancelSelectedOrders();
            } else if (res.data.code === 4000) {
              this.$store.dispatch("logout");
            } else {
              this.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.trailingCancelSelectedOrders();
        this.$bvModal.hide("trailing-cancel-selected-modal");
      }
    },
    cancelOrder() {
      this.btn_disable = true;
      let self = this;
      HTTP.post("/exchange/order/cancel/" + this.selectedOrder.orderId)
        .then((res) => {
          this.btn_disable = false;
          if (res.data.message == "success") {
            self.$bvModal.hide("cancel-modal");
            if (res.data.code === 0) {
              this.cancelPin = null;
              let index = this.$store.state.limitOrderData.findIndex(
                (obj) => obj.orderId === this.selectedOrder.orderId
              );
              if (index > -1) {
                this.$store.state.limitOrderData.splice(index, 1);
              }
            }
            self.$notify({
              group: "notification",
              type: "success",
              title: "success",
              // text: res.data.message,
            });
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
      if (this.selectedOrder.hasExchange) {
        HTTP.post("/exchange/order/cancel-exchange-order-condition", {
          orderId: this.selectedOrder.orderId,
        });
      }
    },
    trailingCancelOrder() {
      let self = this;
      HTTP.post("/exchange/order/cancel-exchange-order-condition", {
        orderId: this.trailingSelectedOrder.orderId,
      }).then((res) => {
        if (res.data.code === 0) {
          this.trailingCancelPin = null;
          let index = this.trailingStopDataOrder.findIndex(
            (obj) => obj.orderId === this.trailingSelectedOrder.orderId
          );
          if (index > -1) {
            this.trailingStopDataOrder.splice(index, 1);
          }
          this.getTrailingStopData();
        }
        self.$notify({
          group: "notification",
          type: res.data.code === 0 ? "success" : "error",
          title: res.data.code === 0 ? "Success" : "Error",
          text: res.data.message,
        });
      });
    },
    cancelSelectedOrders() {
      this.btn_disable = true;
      let self = this;
      HTTP.post("/exchange/order/cancelmulti", this.selectedForCancel)
        .then((res) => {
          this.btn_disable = false;
          if (res.status == 200) {
            this.$store.state.limitOrderData =
              this.$store.state.limitOrderData.filter(
                (obj) => !this.selectedForCancel.includes(obj.orderId)
              );
            self.$bvModal.hide("cancel-selected-modal");
            this.selectedForCancel = [];
            this.selectAll = false;
            this.cancelSelectedPin = false;
            self.$notify({
              group: "notification",
              type: "success",
              title: "success",
              text: "Canceled successfully",
            });
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelSelectedOrdersCondition() {
      this.btn_disable = true;
      let self = this;
      HTTP.post("/exchange/order/cancel-exchange-order-condition", {
        orderId: this.orderConditionId,
      })
        .then((res) => {
          this.btn_disable = false;
          if (res.status == 200) {
            self.$bvModal.hide("cancel-modal-condition");
            this.getHistory();
            this.cancelSelectedPin = false;
            self.$notify({
              group: "notification",
              type: "success",
              title: "success",
              text: "Canceled successfully",
            });
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    cancelSelectedMarketOrdersCondition() {
      this.btn_disable = true;
      let self = this;
      HTTP.post("/exchange/order/cancel-exchange-order-condition", {
        orderId: this.orderMarketConditionId,
      })
        .then((res) => {
          this.btn_disable = false;
          if (res.status == 200) {
            self.$bvModal.hide("cancel-modal-market-condition");
            this.getHistory();
            this.cancelSelectedPin = false;
            self.$notify({
              group: "notification",
              type: "success",
              title: "success",
              text: "Canceled successfully",
            });
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    //
    trailingCancelSelectedOrders() {
      this.btn_disable = true;
      let self = this;
      HTTP.post("/exchange/order/cancel-muilti-exchange-order-condition", {
        orderIds: this.trailingSelectedForCancel.join(","),
      }).then((res) => {
        if (res.data.code === 0) {
          this.trailingStopDataOrder = this.trailingStopDataOrder.filter(
            (obj) => !this.trailingSelectedForCancel.includes(obj.orderId)
          );
          this.trailingSelectedForCancel = [];
        }
        self.$notify({
          group: "notification",
          type: res.data.code === 0 ? "success" : "error",
          title: res.data.code === 0 ? "Success" : "Error",
          text: res.data.message,
        });
      });
    },
    updateOnlineStatus() {
      this.isOnline = window.navigator.onLine;
    },
    formatDate(value, format) {
      return moment(value).format(format);
    },
    updatedData() {
      if (!this.deleyAssets) {
        this.deleyAssets = true;
        if (this.assetsWallet != undefined) {
          if (this.assetsWallet.length == 0) {
            setTimeout(() => {
              this.deleyAssets = false;
              this.updatedData();
            }, 100);
          } else {
            HTTP.post("/uc/asset/my-port/").then((res) => {
              if (res.data.code === 0) {
                this.portfolioData = res.data.data.myPort.sort((a, b) => {
                  return a.coin.name.localeCompare(b.coin.name);
                });
                if (this.portfolioData.length > 10) {
                  this.show_port = true;
                }
              } else if (res.data.code === 4000) {
                this.$store.dispatch("logout");
              }
            });
            this.deleyAssets = false;
            this.getHistory();
            this.getTrailingStopData();
          }
        }
      }
    },
    formatDate_tostring(dateString, value) {
      const date = new Date(dateString);
      const year = date.getFullYear();
      const month = String(date.getMonth() + 1).padStart(2, "0"); // Add leading zero for single-digit months
      const day = String(date.getDate()).padStart(2, "0"); // Add leading zero for single-digit days
      var d_date = "00:00:00";
      if (value != "start") {
        d_date = "23:59:59";
      }

      return `${year}-${month}-${day}T${d_date}`;
    },
    getHistory() {
      if (!this.isLoading) {
        this.isLoading = true;
        let self = this;

        HTTP.post("/exchange/order/personal/current-unmarket", {
          pageNo: 1,
          pageSize: 100,
        })
          .then((res) => {
            let data = res.data.content;
            this.data_chart = [];
            if (data != "") {
              data.forEach((item) => {
                self.assetsWallet.forEach((asset) => {
                  if (item.coinSymbol == asset.coin.unit) {
                    item.bathScale = asset.bathScale;
                    item.coinScale = asset.coinScale;
                  }
                });
                // item.orderId

                if (this.currentCoin.symbol == item.symbol) {
                  if (item.direction == 0) {
                    let data = {
                      percent: 0,
                      id: 0,
                      type: null,
                      price: 0,
                      theme: null,
                    };
                    if (item.stoploss != 0 && item.takeprofit != 0) {
                      this.data_chart.push({
                        percent:
                          ((item.stoploss - item.price) / item.price) * 100,
                        type: "stoploss",
                        id: item.orderId,
                        price: item.stoploss,
                        theme: "#e04152",
                      });
                      this.data_chart.push({
                        percent:
                          ((item.takeprofit - item.price) / item.price) * 100,
                        type: "takeprofit",
                        id: item.orderId,
                        price: item.takeprofit,
                        theme: "#0c9",
                      });
                      this.data_chart.push({
                        percent: 0,
                        type: "buy",
                        id: item.orderId,
                        price: item.price,
                        theme: "#9bacb6",
                      });
                    } else {
                      if (item.stoploss == 0 && item.takeprofit != 0) {
                        this.data_chart.push({
                          percent:
                            ((item.takeprofit - item.price) / item.price) * 100,
                          type: "takeprofit",
                          id: item.orderId,
                          price: item.takeprofit,
                          theme: "#0c9",
                        });
                        this.data_chart.push({
                          percent: 0,
                          type: "buy",
                          id: item.orderId,
                          price: item.price,
                          theme: "#9bacb6",
                        });
                      }
                      if (item.stoploss != 0 && item.takeprofit == 0) {
                        this.data_chart.push({
                          percent:
                            ((item.stoploss - item.price) / item.price) * 100,
                          type: "stoploss",
                          id: item.orderId,
                          price: item.stoploss,
                          theme: "#e04152",
                        });

                        this.data_chart.push({
                          percent: 0,
                          type: "buy",
                          id: item.orderId,
                          price: item.price,
                          theme: "#9bacb6",
                        });
                      }
                      if (item.stoploss == 0 && item.takeprofit == 0) {
                        this.data_chart.push({
                          percent: 0,
                          type: "buy",
                          id: item.orderId,
                          price: item.price,
                          theme: "#9bacb6",
                        });
                      }
                    }
                  }
                  //sell
                  if (item.direction == 1) {
                    let data = {
                      percent: 0,
                      id: 0,
                      type: null,
                      price: 0,
                      theme: null,
                    };
                    if (item.stoploss != 0 && item.takeprofit != 0) {
                      this.data_chart.push({
                        percent:
                          ((item.stoploss - item.price) / item.price) * 100,
                        type: "stoploss",
                        id: item.orderId,
                        price: item.stoploss,
                        theme: "#e04152",
                      });
                      this.data_chart.push({
                        percent:
                          ((item.takeprofit - item.price) / item.price) * 100,
                        type: "takeprofit",
                        id: item.orderId,
                        price: item.takeprofit,
                        theme: "#0c9",
                      });
                      this.data_chart.push({
                        percent: 0,
                        type: "buy",
                        id: item.orderId,
                        price: item.price,
                        theme: "#9bacb6",
                      });
                    } else {
                      if (item.stoploss == 0 && item.takeprofit != 0) {
                        this.data_chart.push({
                          percent:
                            ((item.takeprofit - item.price) / item.price) * 100,
                          type: "takeprofit",
                          id: item.orderId,
                          price: item.takeprofit,
                          theme: "#0c9",
                        });
                        this.data_chart.push({
                          percent: 0,
                          type: "sell",
                          id: item.orderId,
                          price: item.price,
                          theme: "#e04152",
                        });
                      }
                      if (item.stoploss != 0 && item.takeprofit == 0) {
                        this.data_chart.push({
                          percent:
                            ((item.stoploss - item.price) / item.price) * 100,
                          type: "stoploss",
                          id: item.orderId,
                          price: item.stoploss,
                          theme: "#e04152",
                        });

                        this.data_chart.push({
                          percent: 0,
                          type: "sell",
                          id: item.orderId,
                          price: item.price,
                          theme: "#e04152",
                        });
                      }
                      if (item.stoploss == 0 && item.takeprofit == 0) {
                        this.data_chart.push({
                          percent: 0,
                          type: "sell",
                          id: item.orderId,
                          price: item.price,
                          theme: "#e04152",
                        });
                      }
                    }
                  }
                }
                //buy
              });
              if (this.$store.state.hide_menu == false) {
                if (
                  typeof this.iframeContainer.removeallTrendlineOrder ===
                  "function"
                ) {
                  var l = this.data_chart.length;
                  var remove = this.iframeContainer.removeallTrendlineOrder();
                  if (l > 0) {
                    for (let i = 0; i < l; i++) {
                      var buy = this.iframeContainer.addTrendlineOrder(
                        this.$MyFunc.NumberFormat(this.data_chart[i].percent),
                        this.data_chart[i].id + "_" + this.data_chart[i].type,
                        this.data_chart[i].type,
                        this.$MyFunc.toFixedDigits(this.data_chart[i].price, 2),
                        this.$MyFunc.NumberFormat(this.data_chart[i].price, 2),
                        this.data_chart[i].theme,
                        true,
                        true
                      );
                    }
                  }
                }
              }
            }
            this.setLimitOrderData(data);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setTimeout((self.isLoading = false), 2000);
          });
        HTTP.post("/exchange/order/personal/history", {
          startDate: this.historyTime[0]
            ? this.formatDate_tostring(this.historyTime[0], "start")
            : moment().format("YYYY-MM-DD") + "T00:00:00",
          endDate: this.historyTime[1]
            ? this.formatDate_tostring(this.historyTime[1], "end")
            : moment().format("YYYY-MM-DD") + "T23:59:59",
          field: "time",
          sort: "desc",
        })
          .then((res) => {
            let data = res.data.content;
            if (data != undefined) {
              data.forEach((item) => {
                self.assetsWallet.forEach((asset) => {
                  if (item.coinSymbol == asset.coin.unit) {
                    item.bathScale = asset.bathScale;
                    item.coinScale = asset.coinScale;
                  }
                });
                self.matched_limit_orders = data.filter((item) => {
                  let chkType = item.type == 1;
                  let chkStatus = item.status == 1;
                  return chkType && chkStatus;
                });
                //console.log("matched_limit_orders=",self.matched_limit_orders);

                self.matched_market_orders = data.filter((item) => {
                  let chkType = item.type == 0;
                  let chkStatus = item.status == 1;
                  return chkType && chkStatus;
                });
              });
            }

            // 2024-02-22T00:00:00
            //console.log("assetsWallet=",self.assetsWallet);

            //console.log("matched_market_orders=",self.matched_market_orders);
          })
          .catch((err) => {
            console.log(err);
          })
          .finally(() => {
            setTimeout((self.isLoading = false), 2000);
          });
      }
    },
    getTrailingStopData() {
      let self = this;
      //exchange/order/get-all-exchange-order-condition
      HTTP.post("/exchange/order/get-current-trailing-stop", {
        type: "TPLS",
      })
        .then((res) => {
          let data = res.data.data;
          if (data.length > 0) {
            data.forEach((item) => {
              self.assetsWallet.forEach((asset) => {
                if (item.symbol.split("/")[0] == asset.coin.unit) {
                  item.coinScale = asset.coinScale;
                  item.bathScale = asset.bathScale;
                }
              });
            });
          }

          self.trailingStopDataOrder = data; // .filter((item) => item.status === 0);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    validateInput() {
      this.cancelPin = this.cancelPin.replace(/\D/g, "");
    },
    validateConditionInput() {
      this.cancelConditionPin = this.cancelConditionPin.replace(/\D/g, "");
    },
    validateMarketConditionInput() {
      this.cancelMarketConditionPin = this.cancelMarketConditionPin.replace(
        /\D/g,
        ""
      );
    },
    validateInputAll() {
      this.cancelSelectedPin = this.cancelSelectedPin.replace(/\D/g, "");
    },
    getCheckCondition(tp, tpPercent, sl, slPercent, bathScale) {
      let condition = "";
      if (tp != 0) {
        condition = "TP: " + this.$MyFunc.NumberFormat(tp, bathScale);
        if (tpPercent != 0) {
          condition += " (+" + tpPercent + "%)";
        }
      }
      if (tp != 0 && sl != 0) {
        condition += " | ";
        condition += "SL: " + this.$MyFunc.NumberFormat(sl, bathScale);
        if (slPercent != 0) {
          condition += " (-" + slPercent + "%)";
        }
      } else if (sl != 0) {
        condition = "SL: " + this.$MyFunc.NumberFormat(sl, bathScale);
        if (slPercent != 0) {
          condition += " (-" + slPercent + "%)";
        }
      }
      return condition;
    },
    ...mapMutations(["setLimitOrderData"]),
  },
  beforeDestroy() {
    window.removeEventListener("online", this.handleOnlineStatusChange);
    window.removeEventListener("offline", this.handleOnlineStatusChange);
  },
  created() {
    this.getHistoryTime();
    window.addEventListener("online", this.handleOnlineStatusChange);
    window.addEventListener("offline", this.handleOnlineStatusChange);
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
      this.memberId = user_data.id;
      this.updatedData();
    }
  },
  mounted() {
    EventBus.$on("get-Trailing-Stop-Data", () => {
      this.getTrailingStopData();
    });

    setTimeout(() => {
      this.getLiveSupportLink();
    }, 1000);
  },
};
</script>
<style scoped>
.scrollit {
  /* overflow:scroll; */
  height: 516.3px;
  overflow-y: scroll;
}

#cancel-all-modal___BV_modal_body_,
#cancel-selected-modal___BV_modal_body_,
#trailing-cancel-selected-modal___BV_modal_body_,
#cancel-modal___BV_modal_body_,
#trailing-cancel-modal___BV_modal_body_ {
  padding: 0px !important;
}

#cancel-modal___BV_modal_content_,
#trailing-cancel-modal___BV_modal_content_ {
  margin-top: -45px !important;
}

#cancel-selected-modal___BV_modal_content_,
#trailing-cancel-selected-modal___BV_modal_content_ {
  margin-top: 150px;
}
</style>
<style scoped>
.text-right {
  text-align: right;
}

.portfolioTable thead tr {
  border-bottom: 1px solid #28363e;
}

.matchTable tr th:nth-child(1),
.portfolioTable tr th:nth-child(1) {
  padding-left: 0px !important;
}

.matchTable tr td:nth-child(1),
.matchTable tr td {
  padding-top: 12px;
  padding-bottom: 12px;
}

.portfolioTable tr td:nth-child(1) {
  padding-left: 0px !important;
}

.portfolioTable tr td {
  padding-top: 12px;
  padding-bottom: 12px;
}

.cencel-body {
  padding: 20px 16px 16px;
}

.cencel-btn {
  display: flex;
}

.cencel-btn button {
  width: 155px !important;
  height: 36px !important;
  margin-right: 16px;
}

.c-modal-title {
  margin: 0px;
  padding: 24px 0px;
}

.cencel_model_main {
  padding: 0px 0px 0px 16px !important;
}

.cencel_model_main p {
  margin-bottom: 8px !important;
}
</style>
<style scoped lang="scss">
// custome
.pills-tab {
  margin-top: 29px !important;
  margin-bottom: 26 !important;
}

.portfolioTable tr th svg {
  height: 12px;
  width: 12px;
  margin-top: -2px;
}

.footer .pills-tab li button {
  /* EN/Sub-body 12 - Regular */

  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  background: none;
  align-items: center;
  color: #677f8e;
  border: none;
  padding-bottom: 14px;

  span {
    position: relative;
    top: -1px;
    margin-right: 5px;
  }
}

.footer .pills-tab li .active {
  border-bottom: 2px solid #09BE8B;
  color: rgb(255, 255, 255);
  width: auto;
  font-style: normal;
  padding-bottom: 12px;
  line-height: 19px;
}

.footer .pills-tab li button:hover {
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.footer .open-order {
  min-height: 270px;
}

.footer .open-order li {
  border-radius: 4px;
  border: 1px solid #28363e;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}

.footer .open-order ul li button:last-child {
  margin-right: 0px !important;
}

.footer .open-order ul li button {
  background: none;
  border: none;
  border-radius: 2px;
  height: 32px;
  margin-right: 13px !important;
  color: #677f8e;
  margin: 1px;
  padding: 0px 20px;
}

.footer .open-order ul li .active {
  color: rgb(255, 255, 255);
  background-color: #191b1c;
  border-radius: 2px;
}

/* Footer Table */
.footer table {
  width: 100%;
}

.footer table th {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  /* identical to box height */

  /* Text + Icon/Content - Sub#2, Icon */

  color: #677f8e;
}

.footer table tr td span {
  color: #677f8e;
  margin-left: 5px;
}

.footer table tr {
  line-height: 30px;
}

.footer table input {
  background: #222b2f;
  border: 1px solid #3c515d;
  border-radius: 2px;
  height: 17px;
  width: 17px;
}

.footer table input:checked {
  background-color: #09BE8B;
  border: 1px solid #3c515d;
}

.footer table td {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgb(255, 255, 255);
  border-bottom: 1px solid #28363e;
  vertical-align: middle;
}

.footer table .green {
  color: #0c9;
  display: table-cell !important;
}

.footer table .red {
  color: #f72000;
  display: table-cell !important;
}

.footer table .orange {
  color: #09BE8B;
  font-weight: 400;
  font-family: "sarabun";
  cursor: pointer;
}

.footer table button {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 76px;
  height: 36px;
  padding: 0 16px;
  background: #38454d;
  border-radius: 4px;
  border: none;

  &:hover {
    background-color: #38454db2;
  }
}

.bs-cancel-all-btn {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 500;
  width: 96px;
  height: 36px;
  font-size: 14px;
  line-height: 24px;
  padding: 0 16px;
  background: #38454d;
  border-radius: 4px;
  border: none;

  &:hover {
    background-color: #38454db2;
  }
}

.cancel-this-orders {
  background-color: #f72000;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  width: 133px;
  height: 36px;
  margin: 15px 0;

  &:hover {
    background-color: #e04152;
  }
}

.selected-cancel-bar {
  background-color: #202b32;
  position: fixed;
  bottom: 0;
  z-index: 10;
  margin: 0 -12px;
}

.bs-bottom-section {
  border-top: 1px solid #28363e;
  padding: 0 10px;
}

.bs-cancel-all-modal {
  .ca-modal-title {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    text-align: center;
    color: rgb(255, 255, 255);
    margin: 0px;
    padding-top: 24px;
    padding-bottom: 2px;
    padding-left: 0;
    padding-right: 0;

    span:nth-child(2) {
      position: absolute;
      margin-left: 108px;
    }
  }

  .content-text {
    font-family: "sarabun";
    margin-bottom: 8px !important;

    span {
      color: #f72000;
    }
  }

  .cb-container {
    span {
      margin-left: 5px;
    }
  }

  .orange-btn {
    width: 100%;
    border-radius: 4px;
    color: #0a0d10;
    background-color: #09BE8B;
    height: 40px;
    line-height: 24px;
    font-weight: 500;
    font-family: "Sarabun";

    &:hover {
      background-color: #66cdb0;
    }
  }

  .gray-btn {
    width: 100%;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background-color: #222b2f;
    height: 40px;
    line-height: 24px;
    font-family: "Sarabun";

    &:hover {
      background-color: #38454d;
    }
  }
}

.bs-cancel-modal {
  .c-modal-title {
    color: rgb(255, 255, 255);
    text-align: center;
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;

    span:nth-child(2) {
      position: absolute;
      margin-left: 97px;
      cursor: pointer;
    }
  }

  .orange-btn {
    width: 100%;
    border-radius: 4px;
    color: #0a0d10;
    background-color: #09BE8B;
    height: 40px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    &:hover {
      background-color: #66cdb0;
    }
  }

  .gray-btn {
    width: 100%;
    border-radius: 4px;
    color: rgb(255, 255, 255);
    background-color: #222b2f;
    height: 40px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;

    &:hover {
      background-color: #38454d;
    }
  }

  .title {
    p {
      color: #677f8e;
    }
  }

  .value {
    .password {
      width: 148px;
      height: 36px;
      background: #38454d;
      padding: 0 8px;
      border-radius: 4px;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: #ffffff;
    }

    p {
      color: rgb(255, 255, 255);

      span.buy-tag {
        color: #0c9;
        background: rgba(82, 154, 96, 0.16);
        border-radius: 2px;
        font-size: 12px;
        font-weight: 600;
        padding: 2px 4px;
        margin-right: 6px;
      }

      span.sell-tag {
        color: #eb4751;
        background: #eb475129;
        border-radius: 2px;
        font-size: 12px;
        font-weight: 600;
        padding: 2px 4px;
        margin-right: 6px;
      }
    }
  }

  .cb-container {
    span {
      margin-left: 5px;
    }
  }
}

@media only screen and (min-width: 100px) {
  .bs-table-s {
    height: 200px;
    overflow: auto;
  }

  .bs-table-l {
    height: 250px;
    overflow: auto;
  }

  .bs-cancel-all-btn {
    margin-top: 20px;
  }

  .footer .pills-tab li {
    position: relative;

    button {
      margin-right: 6px;
    }
  }

  .lm-order-container {
    width: 100%;
    margin: 0%;
    padding-bottom: 5px;
  }
}

.bs-bb {
  position: fixed;
  bottom: 0;
  width: 100%;
  background-color: #0a0d10;
  padding-top: 8px;
  padding-bottom: 8px;
  padding-left: 23px;

  //padding-right: 3px;
  //margin-top: 30px;
  .conection {
    //margin-right: 16px;
  }

  span {
    margin-right: 4px;

    svg {
      margin-right: 10px;
    }
  }

  span.red {
    color: #f72000;
  }

  span.gray {
    color: #28363e;
  }

  span.green {
    color: #0c9;
  }

  span.orange {
    color: #09BE8B;
    cursor: pointer;
  }

  div {
    //display: inline;
    //float: right;
  }
}

.pin-toggle-icon {
  position: absolute;
  top: 50%;
  right: 50px;
  transform: translateY(-50%);
  cursor: pointer;
}

@media only screen and (min-width: 768px) {
  .bs-cancel-all-btn {
    // float: right;
    position: absolute;
    right: 60px;
    margin-top: -35px;
    // margin-right: 40px;
  }

  .pills-tab {
    padding: 0 15px;
  }

  .bs-bb {
    //display: none;
  }

  .footer .pills-tab li button {
    margin-right: 13px;
  }
}

@media only screen and (min-width: 1024px) {
  .bs-bb {
    display: block;
  }

  .lm-order-container {
    width: 100%;
  }
}

@media only screen and (max-width: 1024px) {
  .pills-tab {
    padding: 0 11px;
  }
}

.h-info-c {
  position: relative;
  display: inline;

  &:hover .hover-info {
    display: flex;
  }

  span.hover-info {
    display: none;
    position: absolute;
    z-index: 9999;
    width: 240px;
    left: 130%;
    top: -30%;
    background-color: rgb(255, 255, 255);
    border-radius: 4px;
    justify-content: center;
    align-items: center;
    font-family: "Sarabun";
    font-style: normal;
    color: #0a0d10;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }
}

.marquee-container {
  overflow: hidden;
}

.marquee-content {
  display: inline-block;
  white-space: nowrap;
  animation: marquee-animation 50s linear infinite;
}

.marquee-content:hover {
  animation-play-state: paused;
}

@keyframes marquee-animation {
  0% {
    transform: translateX(42%);
  }

  100% {
    transform: translateX(-105%);
  }
}
</style>
