<template>
  <div class="wraper">
    <div class="container-fluid p-0">
      <div class="row m-0">
        <!-- <div class="col-3 background-tabs"> -->
        <div class="col-lg-2 col-md-3 background-tabs">
          <div class="setting-heading">
            <h1 class="tab-heading">
              การตั้งค่า
              <span style="float: right; font-size: 14px; color: #192126">{{ VERSION }}</span>
            </h1>
          </div>
          <ul class="nav flex-column mx-auto">
            <li class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle"
                  :to="{ path: '/ProfileSetting' }"
                  :active="$route.name == 'PersonalInformation'">ข้อมูลส่วนตัว</b-link>
              </div>
            </li>
            <li class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle d-flex justify-content-between"
                  :to="{ path: '/ProfileSetting/BankAccountSuccessful' }"
                  :active="$route.name == 'BankAccountSuccessful'">
                  <span>บัญชีธนาคาร</span>
                  <span v-if="BankAccountShow" :class="BankAccountcolor">{{ BankAccountStatus }}</span>
                </b-link>
              </div>
            </li>
            <li class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle rf"
                  :to="{ path: '/ProfileSetting/PinPassword' }"
                  :active="$route.name == 'PinPassword'">PIN / Password</b-link>
              </div>
            </li>
            <li v-if="filteredKycStatus.isShow" class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle d-flex justify-content-between"
                  :to="
                    filteredKycStatus.status == 'รอดำเนินการ'
                      ? { path: '/ProfileSetting/ReviewKYC' }
                      : null
                  "
                  :active="$route.name == 'ReviewKYC'"
                  @click="resetKycData()"
                >
                  <span>Review KYC</span>
                  <span :class="filteredKycStatus.statusColor">{{ filteredKycStatus.status }}</span>
                </b-link>
              </div>
            </li>
            <li class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle"
                  :to="{ path: '/ProfileSetting/CloseAccount' }"
                  :active="$route.name == 'CloseAccount'">ปิดบัญชี</b-link>
              </div>
            </li>
            <div class="hr"></div>
            <li class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle"
                  :to="{ path: '/ProfileSetting/DetermineCost' }"
                  :active="$route.name == 'DetermineCost'">กำหนดต้นทุนการฝากเหรียญ</b-link>
              </div>
            </li>
            <li v-if="!$store.state.hide_menu" class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle"
                  :to="{ path: '/ProfileSetting/ReceivingInformation' }"
                  :active="$route.name == 'ReceivingInformation'">การรับข้อมูลข่าวสาร</b-link>
              </div>
            </li>
            <li class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle"
                  :to="{ path: '/ProfileSetting/LoginHistory' }"
                  :active="$route.name == 'LoginHistory'">ประวัติการเข้าสู่ระบบ</b-link>
              </div>
            </li>
            <li class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle"
                  :to="{ path: '/ProfileSetting/SetNotifications' }"
                  :active="$route.name == 'SetNotifications'">ตั้งค่าการแจ้งเตือน</b-link>
              </div>
            </li>
            <!-- <li class="nav-item menu-item">
              <div class="menu-item">
                <b-link
                  class="colormiddle"
                  :to="{ path: '/ProfileSetting/SetAlerts' }"
                  :active="$route.name == 'SetAlerts'">แจ้งเตือน (All Alerts)</b-link>
              </div>
            </li> -->
            <div class="hr"></div>
            <li class="nav-item menu-item">
              <div class="menu-item">
                <!-- :to="{ path: '/ProfileSetting/AboutUs' }" -->
                <b-link
                  class="colormiddle"
                  :href="url.aboutUs"
                  target="_blank"
                  :active="$route.name == 'AboutUs'">เกี่ยวกับเรา</b-link>
              </div>
            </li>
            <li class="nav-item menu-item">
              <div class="menu-item">
                <!-- :to="{ path: '/ProfileSetting/ContactUs' }" -->
                <b-link
                  class="colormiddle"
                  :href="url.contactUs"
                  target="_blank"
                  :active="$route.name == 'ContactUs'">ติดต่อเรา</b-link>
              </div>
            </li>
            <li class="nav-item menu-item">
              <div class="menu-item">
                <b-link class="colormiddle rf" @click="logout()">Logout</b-link>
              </div>
            </li>
          </ul>
        </div>
        <div class="col-9 p-0">
          <section>
            <router-view></router-view>
          </section>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { HTTP } from "../../config/axios";
export default {
  data() {
    return {
      isLoading: false,
      filteredKycStatus: {
        isShow: false,
        status: null,
        statusColor: null,
      },
      BankAccountShow: false,
      BankAccountStatus: "",
      BankAccountcolor: "",
      url: [],
    };
  },
  watch: {
    $route(to, from) {
      this.getBankAccountStatus();
    },
  },
  methods: {
    async resetKycData() {
      await this.resetKyc();
    },
    resetKyc() {
      localStorage.removeItem("kycReviewData");
    },
    getFilteredKycStatus() {
      let kycReviewStatus = localStorage.getItem("kycReviewStatus");
      let data = JSON.parse(localStorage.getItem("resMember"));
      let requireKycReview = data?.resMember.requireKycReview;

      if (requireKycReview && kycReviewStatus != 0) {
        //รอดำเนินการ - สีเหลือง
        this.filteredKycStatus.isShow = true;
        this.filteredKycStatus.status = "รอดำเนินการ";
        this.filteredKycStatus.statusColor = "yellow";
      } else if (kycReviewStatus == 0) {
        //รอตรวจสอบ - สีส้ม
        this.filteredKycStatus.isShow = true;
        this.filteredKycStatus.status = "รอตรวจสอบ";
        this.filteredKycStatus.statusColor = "orange";
      } else {
        //ไม่โชว์
        this.filteredKycStatus.isShow = false;
        this.filteredKycStatus.status = null;
        this.filteredKycStatus.statusColor = null;
      }
    },
    getBankAccountStatus() {
      if (!this.isLoading) {
        this.isLoading = true;
        let self = this;
        HTTP.post("/uc/member/get-member-info")
          .then(function (response) {
            let data = response.data.data.bankListsStatus;
            if (data == 0) {
              self.BankAccountShow = true;
              self.BankAccountStatus = "รอตรวจสอบ";
              self.BankAccountcolor = "orange";
            } else if (data == 1) {
              self.BankAccountShow = true;
              self.BankAccountStatus = "สำเร็จ";
              self.BankAccountcolor = "green";
            } else {
              self.BankAccountShow = false;
              self.BankAccountStatus = "";
              self.BankAccountcolor = "";
            }
          })
          .catch(function (error) {});
        setTimeout(() => {
          self.isLoading = false;
        }, 10000);
      }
    },
    logout() {
      this.$store.dispatch("logout");
    },
    getUrl(){
      let self = this;
      HTTP.get("/market/pageSite/get-page")
        .then(function (res) {
          if(res.data.data){
            localStorage.setItem("web-url", JSON.stringify(res.data.data));
            self.url = res.data.data;
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            text: error,
            title: "Unable to get list of URLs",
          });
        });
    },
  },
  mounted() {},
  created() {
    //localStorage.removeItem("fatcaSet");
    this.getUrl();
    this.getFilteredKycStatus();
    this.getBankAccountStatus();

  },
};
</script>

<style scoped>
.nav .nav-item .rf {
  font-family: Roboto Flex !important;
}
.background-tabs {
  background-color: #191b1c;
  padding-left: 0px;
  width: 272px !important;
  padding-right: 8px;
  padding-bottom: 15%;
  /* width: 272px; */
}
.setting-heading {
  padding: 24px 0px 13px 24px;
}
.tab-heading {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  margin: 0px;
}
.green {
  color: rgb(0, 255, 0);
}
.yellow {
  color: yellow;
}
.orange {
  color: orange;
}
.hr {
  border-bottom: 1px solid #677f8e;
  margin: 16px auto;
  width: 218px;
}

.mx-auto {
  display: flex;
  gap: 0px !important;
}
.colormiddle {
  text-decoration: none;
  color: #677f8e !important;
}
.menu-item {
  /* margin-right: 32px !important; */
  position: relative !important;
  width: 264px;
  height: 40px;
}

.mx-auto .colormiddle {
  padding: 8px 24px;
  font-family: "Sarabun" !important;
  line-height: 24px;
  font-weight: 400 !important;
  font-size: 14px !important;
  text-align: center;
  /* height: 64px; */
  display: flex;
  transition: 0.4s;
  cursor: pointer !important;
}
.mx-auto .colormiddle.active {
  border-left: 4px solid #09BE8B !important;
  /* text-align: center; */
  padding: 8px 20px;
  color: var(--primary) !important;
  background-color: #38454d;
}
.mx-auto .colormiddle:hover {
  /* border-left-color: #09BE8B !important; */
  /* border-left: 4px solid #09BE8B !important; */
  text-align: center;
  color: var(--primary) !important;
  /* font-weight: 400 !important;
  font-size: 14px !important; */
  /* font-family: Sarabun; */
  /* background-color: #38454D; */
}

@media (max-width: 1090px) {
  .background-tabs {
    width: 190px !important;
  }
  .menu-item {
    width: 190px;
  }
  .hr {
    width: 150px;
  }
  .mx-auto .colormiddle,
  .mx-auto .colormiddle.active {
    padding: 8px 8px;
  }
}

@media only screen and (max-device-width: 768px) {
  .menu-item {
    font-size: 12px !important;
  }
  .background-tabs {
    padding-bottom: 35%;
  }
}
</style>
