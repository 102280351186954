<template>
    <div>
      <b-modal
        class="dwmodalcode"
        id="dwmodalcode"
        :hide-footer="true"
        :hide-header="true"
      >
      <div>
        <div class="mh">
          <span class="close" @click="$bvModal.hide('dwmodalcode')">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#677F8E"
              />
            </svg>
          </span>
          <p class="sub-h">{{ getDWDetailStatus(DWDetail.transactionType, 'topic') }}</p>
          <h2 style="margin-top: 20px;">{{ $MyFunc.NumberFormat(DWDetail.amount) }} THB</h2>

          <p :class="getSubTitleStatus(DWDetail.status).textColor">
            <span>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  :d="getSubTitleStatus(DWDetail.status).icon"
                  :fill="getSubTitleStatus(DWDetail.status).iconColor" />
              </svg>
            </span>
            {{ getSubTitleStatus(DWDetail.status, DWDetail.transactionType).textStatus }}
          </p>

        </div>
        <div class="d-box">
          <div class="row">
            <div class="col-6 title">{{ DWDetail.transactionType != null ? getDWDetailStatus(DWDetail.transactionType, 'subtopic') : '' }}</div>
            <div class="col-6 value" v-if="DWDetail.transactionType == 0">
              BITBULL <br /> 
              {{ DWDetail.fromBankName }} <br />
              {{ DWDetail.bankAccount }}
            </div>
            <div class="col-6 value" v-else-if="DWDetail.transactionType == 1">
              {{ DWDetail.fromBankName ? DWDetail.fromBankName : '' }} <br />
              {{ DWDetail.bankAccount ? DWDetail.bankAccount : '' }}
            </div>
            <div class="col-6 title">ช่องทางการ{{ DWDetail.transactionType != null ? getDWDetailStatus(DWDetail.transactionType) : '' }}</div>
            <div class="col-6 value">{{ DWDetail.transactionMethod != null ? getTransactionMethod(DWDetail.transactionMethod) : '' }}</div>
            <div class="col-6 title">หมายเลขธุรกรรม</div>
            <div class="col-6 value">  {{DWDetail.billerId? DWDetail.billerId: DWDetail.transactionId? DWDetail.transactionId: DWDetail.reference2}}</div>
            <div class="col-6 title">วันที่</div>
            <div class="col-6 value">{{ DWDetail.transactionDate < DWDetail.updatedTimestamp ? getFormatDate(DWDetail.updatedTimestamp) : getFormatDate(DWDetail.transactionDate) }}</div>
            <div v-if="DWDetail.status==2 || DWDetail.status==5 || DWDetail.status==6 || DWDetail.status==8 || DWDetail.status==9" class="col-6 value btext center">
            <br>
              <p v-if="DWDetail.status==2 || DWDetail.status==5 || DWDetail.status==8">
                ถูกยกเลิก 
              </p>
              <p class="red-h" v-else-if="DWDetail.status==6">
                รอการคืนเงิน 
              </p>
              <p class="red-h" v-else-if="DWDetail.status==9">
                {{ DWDetail.transactionType != null ? getDWDetailStatus(DWDetail.transactionType) : '' }}ไม่สำเร็จ
              </p>
              <p class="red-h">
                เนื่องจาก {{DWDetail.reason}}
              </p>
            </div>
          </div>
        </div>
      </div>
      </b-modal>
    </div>
  </template>
  <script>
  import moment from "moment";
  export default {
    components:{},
    props:["DWDetail"],
    data(){
      return {
      }
    },
    methods:{
      getFormatDate(date){
        return moment(date).format(this.TIMEPICKER+" HH:mm:ss")
      },
      getDWDetailStatus(status, type){
        //status => ฝาก = 0, ถอน = 1
        let statusText = null;
        if(status == 0){
          statusText = "ฝาก";
        }else if(status == 1){
          statusText = "ถอน";
        }
        if(type == 'topic'){
          statusText += "เงิน";
        }else if(type == 'subtopic'){
          if(status == 0){
          statusText += "เข้าบัญชี";
          }else if(status == 1){
            statusText += "ไปยัง";
          }
        }
        return statusText; 
      },
      getTransactionMethod(idMethod){
        if(idMethod == 0){
          return "QR Code (Prompt Pay)";
        }else if(idMethod == 1){
          return "Bill Payment";
        }else if(idMethod == 2){
          return "การทำรายการโดยพนักงาน";
        }else if(idMethod == 3){
          return "Bank Transfer";
        }else{
          return null;
        }
      },
      getSubTitleStatus(status, type){
        let textColor = "";
        let textStatus = "";
        let icon = "";
        let iconColor = "";
        /* กำลังดำเนินการ => รอตรวจสอบ */
        if(status == 0){
          textColor = "orange-h";
          textStatus = "รอตรวจสอบ";
          icon = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          iconColor = "#09BE8B";
        /* สำเร็จ => ฝากสำเร็จ */
        }else if(status == 1){
          textColor = "green-h";
          textStatus = `${this.getDWDetailStatus(type)}เงินสำเร็จ`;
          icon = "M3.99896 7.80007L1.19896 5.00006L0.265625 5.9334L3.99896 9.66673L11.999 1.66673L11.0656 0.733398L3.99896 7.80007Z";
          iconColor = "#0c9";
        /* ไม่สำเร็จ => ถูกยกเลิก */
        }else if(status == 2){
          textColor = "red-h";
          textStatus = "ถูกยกเลิก";
          icon = "M9.66732 1.27301L8.72732 0.333008L5.00065 4.05967L1.27398 0.333008L0.333984 1.27301L4.06065 4.99967L0.333984 8.72634L1.27398 9.66634L5.00065 5.93967L8.72732 9.66634L9.66732 8.72634L5.94065 4.99967L9.66732 1.27301Z";
          iconColor = "#f72000";
        /* กำลังดำเนินการ => กำลังดำเนินการ */
        }else if(status == 4){
          textColor = "orange-h";
          // textStatus = `กำลังดำเนินการ${this.getDWDetailStatus(type)}`;
          textStatus="กำลังดำเนินการ";
          icon = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          iconColor = "#09BE8B";
        /* ไม่สำเร็จ => ถูกยกเลิก */
        }else if(status == 5){
          textColor = "red-h";
          textStatus = "ถูกยกเลิก";
          icon = "M9.66732 1.27301L8.72732 0.333008L5.00065 4.05967L1.27398 0.333008L0.333984 1.27301L4.06065 4.99967L0.333984 8.72634L1.27398 9.66634L5.00065 5.93967L8.72732 9.66634L9.66732 8.72634L5.94065 4.99967L9.66732 1.27301Z"; 
          iconColor = "#f72000";
        /* กำลังดำเนินการ => รอการคืนเงิน */
        }else if(status == 6){
          textColor = "orange-h";
          textStatus = "รอการคืนเงิน";
          icon = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          iconColor = "#09BE8B";
        /* รอระบุต้นทุน => รอระบุต้นทุน */
        }else if(status == 7){
          textColor = "orange-h";
          textStatus = "รอระบุต้นทุน";
          icon = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          iconColor = "#09BE8B";
        /* ไม่สำเร็จ => หมดอายุ */
        }else if(status == 8){
          textColor = "red-h";
          textStatus = "หมดอายุ";
          icon = "M9.66732 1.27301L8.72732 0.333008L5.00065 4.05967L1.27398 0.333008L0.333984 1.27301L4.06065 4.99967L0.333984 8.72634L1.27398 9.66634L5.00065 5.93967L8.72732 9.66634L9.66732 8.72634L5.94065 4.99967L9.66732 1.27301Z";
          iconColor = "#f72000";
        /* ไม่สำเร็จ => ฝากไม่สำเร็จ */
        }else if(status == 9){
          textColor = "red-h";
          textStatus = `${this.getDWDetailStatus(type)}ไม่สำเร็จ`;
          icon = "M9.66732 1.27301L8.72732 0.333008L5.00065 4.05967L1.27398 0.333008L0.333984 1.27301L4.06065 4.99967L0.333984 8.72634L1.27398 9.66634L5.00065 5.93967L8.72732 9.66634L9.66732 8.72634L5.94065 4.99967L9.66732 1.27301Z";
          iconColor = "#f72000";
        /* รอฝากเงิน => รอการฝากเงิน */
        }else if(status == 10){
          textColor = "orange-h";
          textStatus = "รอการฝากเงิน";
          icon = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          iconColor = "#09BE8B";
        /* สำเร็จ => คืนเงินสำเร็จ */
        }else if(status == 11){
          textColor = "green-h";
          textStatus = "คืนเงินสำเร็จ";
          icon = "M3.99896 7.80007L1.19896 5.00006L0.265625 5.9334L3.99896 9.66673L11.999 1.66673L11.0656 0.733398L3.99896 7.80007Z";
          iconColor = "#0c9";
        }else{
          textColor = "orange-h";
          textStatus = "ไม่มีสถานะ";
          icon = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          iconColor = "#09BE8B";
        }
        return { textColor, textStatus, icon, iconColor }
      }
    },
    created(){
      //console.log(this.DWDetail);
    }
  };
  </script>
  <style lang="scss">
  #dwmodalcode___BV_modal_content_ {
    width: 600px;
  }
  #dwmodalcode___BV_modal_body_ {
    .mh {
      text-align: center;
      p {
        margin: 0;
      }
      .sub-h {
        font-family: "Noto Sans Thai";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #9bacb6;
        margin-top: 40px;
      }
      h2 {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
      }
      .green-h {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 20px;
        color: #0c9;
      }.red-h {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 20px;
        color: #f72000;
      }.orange-h {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 20px;
        color: #09BE8B;
      }
      .close {
        position: absolute;
        right: 31px;
        top: 29px;
        cursor: pointer;
      }
    }
    .d-box {
      padding-left: 24px;
      padding-top: 15px;
      padding-bottom: 15px;
      width: 500px;
      background-color: #1c262b;
      border-radius: 4px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      .title {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #677f8e;
        padding-top: 3px;
        width: 28%;
      }
      .value {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
        padding-top: 3px;
        padding-right: 20px;
        width: 72%;
        overflow-wrap: break-word;
      }
      .btext {
        border-top: 1px solid #28363e;
        width: 480px;
        margin-top: 9px;
        padding: 10px;
        p{
          color: #FFFFFF;
          line-height: 10px;
        }
      }
    }
  }
  </style>
  