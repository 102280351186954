<template>
  <div>
    <transition name="modal" v-if="OtpResponse">
      <div class="modal-mask" style="background-color: #0a0d10">
        <div class="modal-wrapper">
          <div class="modal-dialog" role="document">
            <div class="modal-content">
              <div class="modal-header" style="border: none;">
                <div class="container p-0">
                  <div class="row m-0">
                    <div class="p-0">
                      <div
                        class="d-flex justify-content-between position-relative"
                      >
                        <h5 class="modal-title m-auto text-center">
                          ยืนยันการเปลี่ยนแปลงข้อมูล
                        </h5>
                        <button
                          type="button"
                          class="close"
                          data-dismiss="modal"
                          @click="CloseModal('OTP')"
                          aria-label="Close"
                          style="
                            position: absolute;
                            right: 30px;
                            padding: 0px;
                            line-height: 0px;
                            top: 14px;
                          "
                        >
                          <span
                            aria-hidden="true"
                            @click="showModalOtpEmail = false"
                            style="color: #677f8e; font-size: 30px"
                            >&times;</span
                          >
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                class="modal-body scroller-otp"
                style="padding-left: 40px; padding-right: 40px"
              >
                <div class="container p-0">
                  <div class="row m-0">
                    <div class="col p-0">
                      <div class="otp-number">
                        <p
                          class="text-center"
                          style="
                            margin-top: 24px;
                            margin-bottom: 20px;
                            font-family: 'Noto Sans Thai';
                            color: rgb(255, 255, 255);
                          "
                        >
                          <span v-if="OtpType == 'phone'">
                            ระบุ OTP ที่ได้รับจาก Email ({{ user.email }})
                            <br />
                            Ref Code : {{ refCode }}
                          </span>
                          <span v-else>
                            ระบุ OTP ที่ไดัรับจาก SMS ({{
                              user.phone.replace(
                                /(\d{3})(\d{3})(\d{4})/,
                                "$1-$2-$3"
                              )
                            }})
                            <br />
                            Ref Code : {{ refCode }}
                          </span>
                        </p>
                      </div>
                      <div
                        id="otp"
                        class="OTPInput d-flex justify-content-center"
                        style="margin-bottom: 48px"
                      >
                        <input
                          type="number"
                          maxlength="1"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          @input="next"
                          v-model="otp_code.digit1"
                          autofocus
                          ref="input1"
                          v-focusOnBackspace
                          v-focusOnLeftArrow
                          v-focusOnRightArrow
                          @keydown.enter="otpSubmit"
                          onClick="this.select();"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                        <input
                          type="number"
                          maxlength="1"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          @input="next"
                          v-model="otp_code.digit2"
                          v-focusOnBackspace
                          v-focusOnLeftArrow
                          v-focusOnRightArrow
                          @keydown.enter="otpSubmit"
                          onClick="this.select();"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                        <input
                          type="number"
                          maxlength="1"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          @input="next"
                          v-model="otp_code.digit3"
                          v-focusOnBackspace
                          v-focusOnLeftArrow
                          v-focusOnRightArrow
                          @keydown.enter="otpSubmit"
                          onClick="this.select();"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                        <input
                          type="number"
                          maxlength="1"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          @input="next"
                          v-model="otp_code.digit4"
                          v-focusOnBackspace
                          v-focusOnLeftArrow
                          v-focusOnRightArrow
                          @keydown.enter="otpSubmit"
                          onClick="this.select();"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                        <input
                          type="number"
                          maxlength="1"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          @input="next"
                          v-model="otp_code.digit5"
                          v-focusOnBackspace
                          v-focusOnLeftArrow
                          v-focusOnRightArrow
                          @keydown.enter="otpSubmit"
                          onClick="this.select();"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                        <input
                          type="number"
                          maxlength="1"
                          oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                          @input="next"
                          v-model="otp_code.digit6"
                          v-focusOnBackspace
                          v-focusOnLeftArrow
                          v-focusOnRightArrow
                          @keydown.enter="otpSubmit"
                          onClick="this.select();"
                          onkeypress="return event.charCode >= 48 && event.charCode <= 57"
                        />
                      </div>
                      <div class="button-confirm" style="margin-left: 0px">
                        <div
                          class="buttonConfirm d-grid gap-2 mb-4"
                          style="width: 100%"
                        >
                          <button
                            type="button"
                            class="btn btn-primary btn-text-sarabun"
                            style="width: 341px; margin: auto"
                            @click="otpSubmit"
                            :disabled="isOTPButtonDisabled"
                          >
                            ยืนยัน
                          </button>
                        </div>
                      </div>

                        <div class="text-center px-5 mb-3">
                          <label>
                            กรุณาตรวจสอบรหัส OTP 
                            <span v-if="OtpType == 'phone'">ในอีเมลของท่าน</span>
                            <span v-else>บนโทรศัพท์มือถือ</span>
                            ของท่าน ภายใน 5 นาที หากท่านไม่ได้รับรหัส OTP
                            <span v-if="OtpType == 'phone'" @click="resendEmail" class="text-primary" role="button">กรุณาคลิกที่นี่ เพื่อขอรับรหัส OTP ใหม่อีกครั้ง</span>
                            <span v-else @click="resendOtp" class="text-primary" role="button">กรุณาคลิกที่นี่ เพื่อขอรับรหัส OTP ใหม่อีกครั้ง</span>
                            <template v-if="otp_delay_2 > 0"> in {{ otp_delay_2 / 1000 }} second</template><br>
                            กรณีไม่สามารถยืนยัน OTP ได้ โทร. {{ CALLCENTER }}
                          </label>
                        </div>

                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" v-if="OtpError">
      <div class="modal-mask" style="background-color: #0a0d10">
        <div class="modal-wrapper">
          <div class="modal-dialog otp-error" role="document">
            <div class="modal-content">
              <div class="modal-body scroller-otp">
                <div class="container">
                  <div class="row">
                    <div class="col text-center" style="height: 337px">
                      <span>
                        <svg
                          style="margin-top: 61px"
                          width="68"
                          height="70"
                          viewBox="0 0 68 70"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g filter="url(#filter0_di_1067_133588)">
                            <circle
                              cx="34"
                              cy="32"
                              r="30"
                              fill="url(#paint0_linear_1067_133588)"
                            />
                          </g>
                          <g filter="url(#filter1_d_1067_133588)">
                            <path
                              fill-rule="evenodd"
                              clip-rule="evenodd"
                              d="M25.1213 18.8787C23.9497 17.7071 22.0503 17.7071 20.8787 18.8787C19.7071 20.0503 19.7071 21.9497 20.8787 23.1213L29.6363 31.8789L20.8791 40.636C19.7076 41.8076 19.7076 43.7071 20.8791 44.8787C22.0507 46.0503 23.9502 46.0503 25.1218 44.8787L33.8789 36.1215L42.0919 44.3345C43.2635 45.5061 45.163 45.5061 46.3345 44.3345C47.5061 43.163 47.5061 41.2635 46.3345 40.0919L38.1215 31.8789L46.335 23.6655C47.5065 22.4939 47.5065 20.5944 46.335 19.4228C45.1634 18.2513 43.2639 18.2513 42.0923 19.4228L33.8789 27.6363L25.1213 18.8787Z"
                              fill="url(#paint1_linear_1067_133588)"
                            />
                          </g>
                          <defs>
                            <filter
                              id="filter0_di_1067_133588"
                              x="0"
                              y="0"
                              width="68"
                              height="70"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1067_133588"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1067_133588"
                                result="shape"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="-2" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite
                                in2="hardAlpha"
                                operator="arithmetic"
                                k2="-1"
                                k3="1"
                              />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.486369 0 0 0 0 0.789038 0 0 0 0 0.588355 0 0 0 0.4 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="shape"
                                result="effect2_innerShadow_1067_133588"
                              />
                            </filter>
                            <filter
                              id="filter1_d_1067_133588"
                              x="16"
                              y="18"
                              width="35.2109"
                              height="35.7573"
                              filterUnits="userSpaceOnUse"
                              color-interpolation-filters="sRGB"
                            >
                              <feFlood
                                flood-opacity="0"
                                result="BackgroundImageFix"
                              />
                              <feColorMatrix
                                in="SourceAlpha"
                                type="matrix"
                                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                result="hardAlpha"
                              />
                              <feOffset dy="4" />
                              <feGaussianBlur stdDeviation="2" />
                              <feComposite in2="hardAlpha" operator="out" />
                              <feColorMatrix
                                type="matrix"
                                values="0 0 0 0 0.721569 0 0 0 0 0.117647 0 0 0 0 0.180392 0 0 0 0.25 0"
                              />
                              <feBlend
                                mode="normal"
                                in2="BackgroundImageFix"
                                result="effect1_dropShadow_1067_133588"
                              />
                              <feBlend
                                mode="normal"
                                in="SourceGraphic"
                                in2="effect1_dropShadow_1067_133588"
                                result="shape"
                              />
                            </filter>
                            <linearGradient
                              id="paint0_linear_1067_133588"
                              x1="33.5082"
                              y1="62"
                              x2="33.5082"
                              y2="2"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#DE2D40" />
                              <stop offset="1" stop-color="#EE5D6D" />
                            </linearGradient>
                            <linearGradient
                              id="paint1_linear_1067_133588"
                              x1="33.6068"
                              y1="49.5"
                              x2="33.6068"
                              y2="28.5"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stop-color="#E6BDBD" />
                              <stop offset="1" stop-color="white" />
                            </linearGradient>
                          </defs>
                        </svg>
                      </span>
                      <div class="success-message">
                        <p
                          style="
                            font-family: 'Noto Sans Thai';
                            color: rgb(255, 255, 255);
                            font-size: 16px;
                            margin-bottom: 0;
                          "
                        >
                          <span v-if="OtpType == 'phone'">
                            ไม่สามารถใช้เบอร์นี้ได้
                            <br />เนื่องจากเบอร์โทรศัพท์นี้ถูกใช้ในระบบแล้ว
                          </span>
                          <span v-else-if="OtpType == 'email'">
                            ไม่สามารถใช้งานอีเมลนี้ได้
                            <br />เนื่องจากถูกใช้งานในระบบแล้ว
                          </span>
                          <span v-else-if="OtpType == 'name'">
                            <!-- Disable now open at UAT#3 -->
                            <!-- ความเหมือน {{ OtpValue }}% <br>  -->
                            กรุณายืนยันตัวตนใหม่อีกครั้ง !!
                          </span>
                          <span v-else-if="OtpType == 'pin'">
                            ไม่สามารถเปลี่ยนพินได้ <br />
                            เนื่องจากพินเก่าไม่ถูกต้อง
                          </span>
                          <span v-else-if="OtpType == 'password'">
                            ไม่สามารถเปลี่ยนรหัสผ่าน <br />
                            เนื่องจากรหัสผ่านเดิมไม่ถูกต้อง
                          </span>
                        </p>
                      </div>
                      <div
                        class="finisButton-SuccesOtp"
                        @click="showModalOTPError = false"
                      >
                        <button
                          style="color: white; width: 160px"
                          type="button"
                          class="btn btn-cancel"
                          @click="CloseModal('OTPError')"
                        >
                          ย้อนกลับ
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
    <transition name="modal" v-if="OtpSuccess">
      <div class="modal-mask" style="background-color: #090d10">
        <div class="modal-wrapper">
          <div class="modal-dialog otp-success" role="document">
            <div class="modal-content">
              <div class="modal-body scroller-otp p-0">
                <div class="container p-0">
                  <div class="row m-0">
                    <div class="col text-center p-0">
                      <div class="checkTick">
                        <img
                          class="image-tick-modal"
                          src="../../../assets/images/profile-setting/tick.png"
                          alt=""
                        />
                      </div>
                      <div class="success-message">
                        <p
                          class="text-grey mb-0"
                          style="
                            font-family: 'Noto Sans Thai' !important;
                            font-style: normal;
                            font-weight: 600;
                            font-size: 18px;
                            line-height: 27px;
                            color: rgb(255, 255, 255);
                          "
                        >
                          <span v-if="OtpType == 'name'">
                            <!-- Disable now open at UAT#3 -->
                            <!-- ความเหมือน {{ OtpValue }}% <br>  -->
                            ระบบได้ทำการเปลี่ยนแปลงข้อมูลเรียบร้อยเเล้ว !!
                          </span>
                          <span v-else> ระบบได้รับข้อมูลของคุณแล้ว </span>
                        </p>
                      </div>
                      <div
                        style="margin-bottom: 123px"
                        @click="showModalOTPSuccess = false"
                      >
                        <button
                          type="button"
                          class="btn-text-sarabun btn-cancel buttonSuccess"
                          style="
                            height: 44px;
                            width: 163px;
                            color: white;
                            border-radius: 4px;
                          "
                          @click="CloseModal('OTPSuccess')"
                        >
                          เสร็จสิ้น
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
export default {
  components: {},
  props: [
    "OtpResponse",
    "OtpType",
    "OtpValue",
    "OtpError",
    "OtpSuccess",
    "refCode",
  ],
  data() {
    return {
      user: {
        email: "",
        phone: "",
      },
      isOTPButtonDisabled: false,
      otp_lock: true,
      otp_delay_2: 0,
      otp_delayTime: 120000,
      otp_code: {
        digit1: null,
        digit2: null,
        digit3: null,
        digit4: null,
        digit5: null,
        digit6: null,
      },
    };
  },
  directives: {
    focusOnBackspace: {
      inserted(el, binding) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "Backspace" && event.target.value === "") {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnLeftArrow: {
      inserted(el) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "ArrowLeft") {
            event.preventDefault();
            const prevInput = el.previousElementSibling;
            if (prevInput) {
              prevInput.select();
            }
          }
        });
      },
    },
    focusOnRightArrow: {
      inserted(el) {
        el.addEventListener("keydown", (event) => {
          if (event.key === "ArrowRight") {
            event.preventDefault();
            const nextInput = el.nextElementSibling;
            if (nextInput) {
              nextInput.select();
            }
          }
        });
      },
    },
  },
  methods: {
    next(e) {
      if (e.data == null) {
        e.target?.previousSibling?.select();
      } else {
        e.target?.nextSibling?.select();
      }
    },
    otpSubmit() {
      let self = this;
      if (this.validateOTP()) {
        this.isOTPButtonDisabled = true;
        let otp =
          this.otp_code.digit1 +
          this.otp_code.digit2 +
          this.otp_code.digit3 +
          this.otp_code.digit4 +
          this.otp_code.digit5 +
          this.otp_code.digit6;
        let Message =
          this.OtpType == "phone"
            ? "Successfully Updated Phone"
            : "Successfully Updated Email";
        if (this.OtpType == "phone" || this.OtpType == "email") {
          HTTP.post("/uc/member/verifyOtp", {
            verificationCode: otp,
            value: this.OtpValue,
            type: this.OtpType,
            refCode: this.refCode,
          })
            .then((res) => {
              self.isOTPButtonDisabled = false;
              // this.ClearInput();
              if (res.data.code == 0) {
                self.getMemberInfo();
                self.$emit("OtpResponseUpdate", "OtpSuccess");
              } else if (
                res.data.code == 500 &&
                res.data.message != "Invalid verification code"
              ) {
                self.$emit("OtpResponseUpdate", "OtpError");
              }
              self.$notify({
                group: "notification",
                type: res.data.code == "0" ? "success" : "error",
                title:
                  res.data.code == "0" ? Message : "Data Update Unsuccessfully",
                text: res.data.message,
              });
            })
            .catch((err) => {
              console.log(err);
              self.ClearInput();
              self.isOTPButtonDisabled = false;
            });
        } else {
          HTTP.post("/uc/member/validatePersonOtp", {
            verificationCode: otp,
            refCode: this.refCode,
          })
            .then((res) => {
              self.isOTPButtonDisabled = false;
              if (res.data.code === 0) {
                self.$emit("OtpResponseUpdate", "OtpSuccess");
              } else if (
                res.data.code == 500 &&
                res.data.message != "Invalid verification code"
              ) {
                self.$emit("OtpResponseUpdate", "OtpError");
              }
              self.$notify({
                group: "notification",
                type: res.data.code == "0" ? "success" : "error",
                title:
                  res.data.code == "0"
                    ? "OTP Validated !"
                    : "Data Update Unsuccessfully",
                text: res.data.message,
              });
            })
            .catch((err) => {
              self.isOTPButtonDisabled = false;
              self.ClearInput();
              console.log(err);
            });
        }
      }
    },
    resendOtp() {
      if (!this.otp_lock) {
        let self = this;
        HTTP.post("/uc/member/resendPersonOtp")
          .then((res) => {
            if (res.data.code == 0) {
              self.$emit("UpdateRefCode", res.data.data);
              self.otp_lock = true;
              self.onModalShown();
            }
            self.$notify({
              group: "notification",
              type: res.data.code == "0" ? "success" : "error",
              title:
                res.data.code == "0"
                  ? "Successfully Resend the OTP"
                  : "OTP Resend Unsuccessfully",
              text: res.data.message,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "ไม่สามารถส่งได้",
          text: "กรุณารอเป็นเวลา " + this.otp_delay_2 / 1000 + " วินาที",
        });
      }
    },
    onModalShown() {
      this.otp_delay_2 = this.otp_delayTime;
      this.otpDelay();
      this.$nextTick(() => {
        this.$refs.input1.focus();
      });
    },
    otpDelay() {
      this.otpTimeCount();
      setTimeout(() => {
        this.otp_lock = false;
      }, this.otp_delayTime);
    },
    otpTimeCount() {
      if (this.otp_delay_2 > 0) {
        setTimeout(() => {
          this.otp_delay_2 -= 1000;
          if(this.otp_delay_2 > 0){
            this.otpTimeCount();
          }
        }, 1000);
      }
    },
    resendEmail() {
      if (!this.otp_lock) {
        let self = this;
        HTTP.post("/uc/member/resendEmail")
          .then((res) => {
            if (res.data.code === 0) {
              self.$emit("update:refCode", res.data.data.refCode);
              self.otp_lock = true;
              self.onModalShown();
            }
            self.$notify({
              group: "notification",
              type: res.data.code == "0" ? "success" : "error",
              title:
                res.data.code == "0"
                  ? "Successfully Resend the Email"
                  : "Email Resend Unsuccessfully",
              text: res.data.message,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "ไม่สามารถส่งได้",
          text: "กรุณารอเป็นเวลา " + this.otp_delay_2 / 1000 + " วินาที",
        });
      }
    },
    validateOTP() {
      let self = this;
      let valid = true;
      if (
        this.otp_code.digit1 == null ||
        this.otp_code.digit2 == null ||
        this.otp_code.digit3 == null ||
        this.otp_code.digit4 == null ||
        this.otp_code.digit5 == null ||
        this.otp_code.digit6 == null ||
        this.otp_code.digit1 == "" ||
        this.otp_code.digit2 == "" ||
        this.otp_code.digit3 == "" ||
        this.otp_code.digit4 == "" ||
        this.otp_code.digit5 == "" ||
        this.otp_code.digit6 == ""
      ) {
        self.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "Please Enter OTP to Verify Change",
        });
        valid = false;
      }
      return valid;
    },
    ClearInput() {
      this.otp_code.digit1 = null;
      this.otp_code.digit2 = null;
      this.otp_code.digit3 = null;
      this.otp_code.digit4 = null;
      this.otp_code.digit5 = null;
      this.otp_code.digit6 = null;
    },
    CloseModal(Type) {
      this.otp_delay_2 = 0;
      this.ClearInput();
      this.$emit("OtpResponseUpdate", Type);
    },
    getMemberInfo() {
      let self = this;
      HTTP.post("/uc/member/get-member-info")
        .then(function (response) {
          if (response.data.code === 0) {
            const resMember = response.data.data.member;
            const reslockStatus = response.data.data.reslockStatus;
            let combinedDataMemberStatus = {
                                              resMember: resMember,
                                              reslockStatus: reslockStatus
                                            };
            localStorage.setItem("resMember", JSON.stringify(combinedDataMemberStatus));
            self.user.phone = resMember?.mobilePhone || "";
            self.user.email = resMember?.email || "";
          }
        })
    },
  },
  mounted() {
    this.ClearInput();
  },
  watch: {
    OtpResponse(value) {
      if (value) {
        this.ClearInput();
        this.onModalShown();
      }
    },
  },
  created() {
    const resMember = JSON.parse(localStorage.getItem("resMember")) || "{}";
    this.user.phone = resMember?.resMember.mobilePhone || "";
    this.user.email = resMember?.resMember.email || "";
  },
};
</script>
<style scoped>
.OTPInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 16px;
}

.OTPInput input {
  width: 44px;
  height: 56px;
  background-color: #222b2f;
  caret-color: #09BE8B;
  border-radius: 4px;
  color: #fff;
  padding: 12px;
  font-size: 30px;
  border-style: none;
  outline: none;
}

.button-confirm {
  justify-content: center;
  display: flex;
  margin-left: 30px;
}
.text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.otp-success {
  max-width: 409px !important;
}
.otp-error {
  max-width: 480px !important;
}
.image-tick-modal {
  background-color: #277c44;
  border-radius: 50%;
  height: 67.5px;
  width: 67.5px;
  padding: 11px;
}
.image-cross-modal {
  background-color: linear-gradient(360deg, #de2d40 0%, #ee5d6d 100%);
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.checkTick {
  padding-top: 100px;
}
.success-message {
  padding-top: 24px;
  padding-bottom: 40px;
}
.finisButton-SuccesOtp {
  padding-bottom: 100px;
}

.buttonSuccess {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: "Noto Sans Thai" !important;
}

.btn-cancel {
  background-color: #222b2f;
}

input {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgb(255, 255, 255) !important;
  caret-color: #09BE8B;
  line-height: 24px;
}

table tr td:nth-child(2) p {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}

.modal-header {
  padding: 30px !important;
}
.modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}

.btn-text-sarabun {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 68px;
  height: 40px;
}

.form-control {
  outline: none;
}

.modal-title {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  font-family: "Noto Sans Thai" !important;
  line-height: 27px;
}

.text-grey {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}

.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-mask p {
  line-height: 24px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-dialog {
  background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}

.btn-cancel {
  background-color: #222b2f;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}
.paswordInput-width {
  width: 240px !important;
}
.SurnameWidth {
  width: 240px !important;
}

select > option:checked,
select > option:hover {
  /* box-shadow: 0 0 10px 100px #dc6900 inset; */
  color: #09BE8B !important;
}
/* select:focus{
    border-color: #09BE8B;
    outline:none;
} */

select {
  /* padding: 5px; */
  border-radius: 4px;
  height: 40px;
  width: 240px !important;
  color: #ffffff;
  /* padding: 8px 12px; */
  /* padding-right: 30px; */
  font-size: 14px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #28363e !important;
  background: #677f8e
    url("../../../../src/assets/images/profile-setting/dropdownChevron.png");
  background-repeat: no-repeat;
  background-position: right 7px top 50%;
}
/* modal select box end */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.modal-content {
  border-radius: 8px !important;
}
</style>
