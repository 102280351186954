<template>
  <div class="wrapper">
    <div class="container p-0">
      <div class="row m-0">
        <div class="col p-0">
          <div class="Heading">
            <h4 class="page-heading">ตั้งค่าการแจ้งเตือน</h4>
          </div>
        </div>
      </div>
      <div v-if="isLoading"> <div class="loader-wrapper"> <div class="loader"></div> </div> </div>
      <div v-else>
        <div
        class="row m-0 p-0"
        style="margin-left: 48px !important; max-width: 550px"
      >
        <div class="col-10 p-0">
          <div v-if="configResponse == null">
            <div
              v-for="(_, index) in toggleTitles"
              :key="index"
              class="py-3 d-flex justify-content-between align-content-center border-bottom-table noti"
            >
              <div class="skeleton-title" style="height: 18px; width: 100%">
                <b-skeleton
                  class="skc custom-skeleton-wave"
                  animation="wave"
                  width="100%"
                >
                </b-skeleton>
              </div>
              <div
                class="skeleton-toggle"
                style="height: 24px; width: 55px"
              ></div>
            </div>
          </div>
          <div v-if="configResponse">
            <div
              v-for="(item, index) in toggleTitles"
              :key="index"
              class="py-3 d-flex justify-content-between align-content-center border-bottom-table noti"
            >
              <p class="table-text-white">{{ item.title }}</p>
              <toggle-button
                :value="getToggleValue(item.key, index)"
                :color="{ checked: '#0c9', unchecked: '#3C515D' }"
                :labels="{ checked: 'ON', unchecked: 'OFF' }"
                :height="24"
                :width="55"
                class="mb-0"
                @change="toggleChanged(item.key, index)"
                :sync="true"
              />
            </div>
          </div>
        </div>
      </div>
      </div>
      
    </div>
    <SetPinConfirmModal ref="setPinConfirmModal" @validatePin="validatePin" />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { HTTP } from "../../config/axios";
import EventBus from "../../event-bus";
import memberConfigMixin from "./MemberConfig.js";
import SetPinConfirmModal from "./SetPinConfirmModal.vue";
export default {
  mixins: [memberConfigMixin],
  data() {
    return {
      toggle: false,
      toggleTitles: [
        {
          title: "เตือนเมื่อมีการฝาก/ถอนเงินบาท",
          key: "DEPOSIT_WITHDRAW_BHAT_ALERT",
        },
        {
          title: "เตือนเมื่อมีการฝาก/ถอนเหรียญ",
          key: "DEPOSIT_WITHDRAW_COIN_ALERT",
        },
        {
          title: "เตือนเมื่อมีการซื้อขายสำเร็จ (Order Matched)",
          key: "ORDER_MATCH_ALERT",
        },
        {
          title: "ยืนยันก่อนส่งคำสั่งซื้อขาย",
          key: "ORDER_CONFIRM_BEFORE_SUBMIT",
        },
        {
          title: "เตือนเมื่อมีการล็อคอิน",
          key: "LOGGED_IN_NOTIFY",
        },
        {
          title: "ไม่ต้องแจ้งเตือนอีก",
          key: "DONT_NOTIFY_AGAIN",
        },
      ],
      keyAlert: '',
      keyValue: null,
    };
  },
  components: {
    SetPinConfirmModal
  },
  mounted() {

  },
  methods: {
    ...mapMutations(["setChkOrderConfirm"]),
    getToggleValue(Key, index) {
      const toggleValue = this.Config[Key];
      return toggleValue == 1 ? true : false;
    },
    toggleChanged(Key) {
      const currentValue = this.getToggleValue(Key);
      const newValue = currentValue == true ? 0 : 1;
      //if((Key == 'ORDER_CONFIRM_BEFORE_SUBMIT' && newValue == 0) || (Key == 'DONT_NOTIFY_AGAIN' && newValue == 1)){
      if(Key == 'ORDER_CONFIRM_BEFORE_SUBMIT' && newValue == 0){
        this.keyAlert = Key;
        this.keyValue = newValue;

        let userpin = localStorage.getItem("user-pin");
        if(!userpin){
          this.$refs.setPinConfirmModal.resetValuePin();
          this.$bvModal.show("pin-confirm-modal"); 
        }else{
          this.updateMemberConfigs(Key, newValue);
          this.setModalOrderConfirm(true);
        }
      }else if(Key == 'ORDER_CONFIRM_BEFORE_SUBMIT' && newValue == 1){
        this.updateMemberConfigs(Key, newValue);
        this.setModalOrderConfirm(false);
      }else{
        this.updateMemberConfigs(Key, newValue);
      }
    },
    validatePin(pin) { 
      HTTP.post("/uc/validate-pin", { pin })
        .then((res) => {
          if (res.data.code === 0) {
            localStorage.setItem("user-pin", btoa(pin));
            this.updateMemberConfigs(this.keyAlert, this.keyValue);
            this.setModalOrderConfirm(true);
          } else if (res.data.code === 4000){
            this.$store.dispatch("logout");
          }else {
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    setModalOrderConfirm(status){
      //true = Hide, false = Show
      if(status){
        this.setChkOrderConfirm(status);
      }else{
        this.setChkOrderConfirm(status);
      }
    }
  },
  created() {
    this.getMemberConfigs();
  },
};
</script>

<style scoped>
.bg-box {
  background-color: #1c262b;
}
p {
  margin-bottom: 0;
}
.Heading {
  padding: 24px 0px 18px 48px;
}
.page-heading {
  font-weight: 600 !important;
  font-size: 24px !important;
  margin: 0px;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  color: #d6dde1;
}
.table-text-white {
  color: #ffffff;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.border-bottom-table {
  border-bottom: 1px solid #28363e;
}
.noti .vue-js-switch .v-switch-label.v-left {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 700;
  font-size: 10px;
  /* identical to box height */
  letter-spacing: 0.2px;
  /* Text + Icon/Content - Primary */
  color: #d6dde1;
}
</style>
