<template>
   <div>
    
    <div v-if="showModalOpenCamera">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 591px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            ถ่ายรูปบัตรประชาชน
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="showModalOpenCamera = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number">
                          <!-- <p
                            class="text-grey text-center"
                            style="margin-top: 16px; margin-bottom: 37px"
                          >
                            ถ่ายรูปเซลฟี่หน้าตรง
                          </p> -->
                        </div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <!-- <img
                            src="../../assets/images/profile-setting/skullImage.png"
                            alt=""
                            style="cursor: pointer"
                          /> -->
                        </div>
                        <div style="margin-left: 27px">
                          <p class="image-note m-0">ข้อแนะนำ</p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">1.</span>
                            เตรียมบัตรประชาชน
                            <span
                              class="d-block"
                              style="padding-left: 18px; color: #9bacb6"
                              >a. อยู่ในพื้นที่แสงสว่างเพียงพอ</span
                            >
                            <span
                              class="d-block"
                              style="padding-left: 18px; color: #9bacb6"
                              >b. ระวังแสงสะท้อนบนบัตรประชาชน</span
                            >
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">2.</span>
                            ถ่ายรูปบัตรประชาชน
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">3.</span>
                            ถ่ายวิดีโอ Selfie คู่กับบัตร
                          </p>
                        </div>
                        <div class="button-camera-open">
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="
                                showModalOpenCamera = false;
                                ModalIDCard = true;
                              "
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                border-radius: 4px;
                                font-weight: 500;
                              "
                            >
                              <!-- <img
                                src="../../assets/images/profile-setting/photo_camera_black.png"
                                alt=""
                                style="margin-right: 10px"
                              /> -->
                              เริ่มต้น
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="ModalIDCard">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 650px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            กรุณาทำตามขั้นตอน
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                ModalIDCard = false
                                StopCamera()
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                      <div class="oc2">
                        <p>ขั้นตอนที่ 1</p>
                        <p>จัดบัตรประชาชนให้อยู่ในกรอบ</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number">
                        </div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <video ref="camera" autoplay width="100%" height="100%" style="object-fit: cover; border-radius: 4px;"></video>
                        </div>
                        <div style="margin-left: 27px">
                          <p class="image-note m-0">หมายเหตุ</p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">1.</span>
                            กดปุ่ม “ถ่ายรูป” เพื่อเปิดกล้อง
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">2.</span>
                            หากท่านยังไม่สามารถกดถ่ายรูปได้ กรุณาตรวจสอบ การตั้งค่า ได้
                          </p>
                          <p style="margin-left: 24px; color: #9bacb6">
                            ได้ <span style="color: #09BE8B">ที่นี่</span>
                          </p>
                        </div>
                        <div
                          class="button-camera-open"
                          style="padding-top: 70px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="TakePicture"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                margin: auto;
                                margin-bottom : 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                position: relative;
                              "
                            >
                              <img
                                style="position: absolute; left: 89px; top: 5px"
                                src="../../assets/images/profile-setting/photo_camera_black.png"
                                alt=""
                              />
                              ถ่ายรูป
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="ModalSelfi">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 650px"
            >
              <div class="modal-content" style="margin-bottom: 200px">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            กรุณาทำตามขั้นตอน
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="
                                ModalSelfi = false
                                StopCamera()
                              "
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                      <div class="oc2">
                        <p>ขั้นตอนที่ 2</p>
                        <p>“ถ่ายรูปเซลฟี่” เพื่อยืนยันตัวตนของคุณ</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number">
                        </div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <video ref="camera" autoplay width="100%" height="100%" style="object-fit: cover; border-radius: 4px;"></video>
                        </div>
                        <div style="margin-left: 27px">
                          <p class="image-note m-0">ข้อแนะนำก่อนแสกนใบหน้า</p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">1.</span>
                            ไม่สวมหมวก, หน้ากาก หรือแว่นตาปิดบังใบหน้า
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">2.</span>
                            อยู่ในพื้นที่แสงสว่างเพียงพอ และไม่ติดคนด้านหลัง
                          </p>
                          <p class="note-list" style="margin-left: 7px">
                            <span style="color: #9bacb6">3.</span>
                            ไม่มีผมปิดบังใบหน้า
                          </p>
                        </div>
                        <div
                          class="button-camera-open"
                          style="padding-top: 70px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="TakePicture"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                margin: auto;
                                margin-bottom : 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                position: relative;
                              "
                            >
                              <img
                                style="position: absolute; left: 80px; top: 5px"
                                src="../../assets/images/profile-setting/photo_camera_black.png"
                                alt=""
                              />
                              ถ่ายวิดีโอ
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <div v-if="ModalShowPhoto">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow-y: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 100px auto; height: 443px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            <span v-if="CameraStep == 0">
                              ยืนยันรูปบัตรประชาชน
                            </span>
                            <span v-else>
                              การยืนยันการเซลฟี่
                            </span>
                            <!-- <span
                              ><svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalShowPhoto = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller" style="padding: 0px 24px">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="otp-number">
                        </div>
                        <div
                          class="attachment-wrap-skull d-flex justify-content-center py-5"
                        >
                          <img
                            :src="PhotoBase64"
                            alt="Captured Image"
                            style="cursor: pointer; object-fit: cover; border-radius: 4px;"
                            width="100%"
                            height="100%"
                          />
                        </div>

                        <div
                          class="button-camera-open"
                          style="padding-top: 40px"
                        >
                          <div
                            class="buttonConfirm d-grid gap-2"
                            style="width: 78%"
                          >
                            <button
                              @click="NexttoStartCamera"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                height: 40px;
                                margin: auto;
                                margin-bottom : 5px;
                                border-radius: 4px;
                                font-weight: 500;
                              "
                            >
                              ยืนยัน
                            </button>
                            <button
                              @click="BacktoStartCamera"
                              type="button"
                              class="btn-primary btn-text-sarabun"
                              style="
                                width: 293px;
                                background-color: #191b1c !important;
                                height: 40px;
                                margin: auto;
                                margin-bottom : 5px;
                                border-radius: 4px;
                                font-weight: 500;
                                color: white !important;
                              "
                            >
                              ถ่ายรูปอีกครั้ง
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End modal Edit Name-Surname change -->
    <!-- modal Edit Phone Number -->
    <div v-if="ModalEditPhoneNumber">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper" style="background-color: #0a0d10">
            <div class="modal-dialog" role="document" style="height: 327px">
              <div class="modal-content">
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงเบอร์โทรศัพท์
                            <span
                              ><svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span>
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalEditPhoneNumber = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body p-0">
                  <div class="container" style="padding: 0px 24px">
                    <div class="row m-0">
                      <div class="col p-0">
                        <div class="current-name d-flex borderBottom-in-modal">
                          <p
                            style="
                              margin-top: 8px;
                              margin-bottom: 28px;
                              color: rgb(255, 255, 255);
                            "
                          >
                            เบอร์โทรศัพท์ปัจจุบัน
                          </p>
                          <p
                            style="
                              margin-top: 8px;
                              margin-bottom: 28px;
                              margin-left: 28px;
                              font-family: 'Roboto Flex' !important;
                              color: #9bacb6;
                            "
                          >
                            {{ user.phone }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- new phone number input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%; margin-top: 24px"
                    >
                      <p class="mt-2 mb-0" style="color: rgb(255, 255, 255)">
                        เบอร์โทรศัพท์ใหม่
                      </p>
                      <div class="name-input" style="width: 66%">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุเบอร์โทรศัพท์</label
                        >
                        <input
                          style="margin-left: 16px"
                          v-model="NewPhone"
                          @input="validateInput"
                          required
                          type="text"
                          class="form-control paswordInput-width"
                          placeholder="ระบุเบอร์โทรศัพท์"
                          maxlength="10"
                        />
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin-top: 40px; margin-bottom: 48px"
                    >
                      <button
                        style="margin-left: 16px; width: 240px"
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changePhone"
                      >
                        <span v-if="isLoadingPhone" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                      <!-- <button
                        type="button"
                        class="btn btn-cancel btn-text-sarabun ms-3"
                        style="color: white"
                        @click="ModalEditPhoneNumber = false"
                      >
                        ยกเลิก
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- end modal Edit phone number -->
    <!-- modal Edit Email -->
    <div v-if="ModalEditEmail">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper">
            <div class="modal-dialog" role="document" style="height: 327px">
              <div class="modal-content">
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงอีเมล
                            <span>
                              <svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span>
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalEditEmail = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body p-0">
                  <div
                    class="container p-0"
                    style="padding-left: 24px !important"
                  >
                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex borderBottom-in-modal"
                          style="width: 432px"
                        >
                          <p
                            class="text-dark-grey"
                            style="margin-top: 8px; margin-bottom: 24px"
                          >
                            อีเมลปัจจุบัน
                          </p>
                          <p
                            class="text-grey"
                            style="
                              margin-left: 39px;
                              margin-top: 8px;
                              margin-bottom: 24px;
                              font-family: 'Roboto Flex' !important;
                            "
                          >
                            {{ user.email }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- new email input -->
                    <div
                      class="wraper-input d-flex"
                      style="width: 90%; margin-top: 24px"
                    >
                      <p class="text-dark-grey mt-2 mb-0">อีเมลใหม่</p>
                      <div class="name-input" style="width: 70%">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุอีเมล</label
                        >
                        <input
                          type="text"
                          v-model="NewEmail"
                          class="form-control"
                          style="max-width: 240px; margin-left: 56px"
                          placeholder="ระบุอีเมล"
                        />
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin: 40px 0px 48px"
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changeEmail"
                        style="width: 240px"
                      >
                        <span v-if="isLoadingEmail" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                      <!-- <button
                        type="button"
                        class="btn btn-cancel btn-text-sarabun ms-3 w-400"
                        style="color: white"
                        @click="ModalEditEmail = false"
                      >
                        ยกเลิก
                      </button> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End modal edit Email -->
    <!-- modal Edit Address -->
    <div v-if="ModalEditAddress">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              overflow-y: auto;
              display: block;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 591px"
            >
              <div class="modal-content">
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงที่อยู่ปัจจุบัน
                            <!-- <span
                              ><svg
                                width="20"
                                height="21"
                                viewBox="0 0 20 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalEditAddress = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller p-0">
                  <div
                    class="container p-0"
                    style="padding-left: 24px !important"
                  >
                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex borderBottom-in-modal"
                          style="width: 432px"
                        >
                          <p style="margin: 8px 0px 48px; color: rgb(255, 255, 255)">
                            ที่อยู่ปัจจุบัน
                          </p>
                          <p style="margin: 8px 0px 24px 42px; color: #9bacb6">
                            {{ user.addresscurrent }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- id card  address heading -->
                    <p class="mt-3 mb-0" style="color: rgb(255, 255, 255)">ที่อยู่ใหม่</p>
                    <!-- number and group two input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 34px">
                        เลขที่
                      </p>
                      <div class="name-input" style="margin-top: 28px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <div class="left-right-input d-flex">
                          <input
                            v-model="Address.HouseNO"
                            type="text"
                            class="form-control two-ipnuts"
                            placeholder="ระบุข้อมูล"
                            @input="onHouseNOInput"
                          />
                          <p
                            class="text-dark-grey mt-2 two-inputs-labelMargin"
                            style="margin: 0px 15px 0px 14px"
                          >
                            หมู่
                          </p>
                          <label for="inputPassword2" class="visually-hidden"
                            >ระบุข้อมูล</label
                          >
                          <input
                            v-model="Address.Group"
                            type="text"
                            style="width: 84px !important"
                            class="form-control two-ipnuts"
                            placeholder="ระบุข้อมูล"
                            @input="onGroupInput"
                          />
                        </div>
                      </div>
                    </div>
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        หมู่บ้าน / อาคาร
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Village"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ชั้น
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Floor"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                          @input="onFloorInput"
                        />
                      </div>
                    </div>

                    <!-- alley input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ซอย
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Alley"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>
                    <!-- road input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ถนน
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Road"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>
                    <!-- subdistrict select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <!-- {{ListDist}} -->
                      <!-- {{ListSubDist}} -->
                      <!-- {{ListProvince}} -->
                      <!-- <span v-for="(item, index) in ListSubDist" :key="index">
                      {{item.name}}
                     </span> -->

                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ตำบล/ แขวง
                      </p>
                      {{ Address.SubDistrict }}

                      <div
                        ref="subDistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="open_subDist_dropdown = true"
                        @blur="open_subDist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchSubDist"
                        />
                        <span
                          v-if="searchSubDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกตำบล/ แขวง
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_subDist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListSubDist"
                              :key="index"
                              @click.stop="
                                searchSubDist = item.name;
                                selectedSubDist = item.id;
                                open_subDist_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <!-- district select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        อำเภอ/ เขต
                      </p>
                      <!-- <div class="name-input" style="margin-top: 24px">
                        <div
                          class="select"
                          tabindex="0"
                          @click="open_dropdown2 = !open_dropdown2"
                          @blure="open_dropdown2 = false"
                        >
                          <span class="text" style="left: 10px; color: #677f8e"
                            >เลือกอำเภอ/ เขต</span
                          >
                          <span
                            class="icon"
                            :class="open_dropdown2 ? 'rotate-sc-icon' : ''"
                            ><svg
                              width="8"
                              height="8"
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                                fill="#677F8E"
                              />
                            </svg>
                          </span>
                          <div
                            class="options"
                            v-show="open_dropdown2"
                            style="left: 0px; top: 33px"
                          >
                            <ul>
                              <li style="color: white" @click="d2 = '0'">
                                เลือกอำเภอ/ เขต
                              </li>
                            </ul>
                          </div>
                        </div>
                       
                      </div> -->

                      <div
                        ref="DistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="open_Dist_dropdown = true"
                        @blur="open_Dist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchDist"
                        />
                        <span
                          v-if="searchDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกอำเภอ/ เขต
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Dist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListDist"
                              :key="index"
                              @click.stop="
                                searchDist = item.name;
                                selectedDist = item.id;
                                open_Dist_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <!-- Province select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        จังหวัด
                      </p>
                      <!-- <div class="name-input" style="margin-top: 24px">
                        <div
                          class="select"
                          tabindex="0"
                          @click="open_dropdown3 = !open_dropdown3"
                          @blure="open_dropdown3 = false"
                        >
                          <span class="text" style="left: 10px; color: #677f8e"
                            >เลือกจังหวัด</span
                          >
                          <span
                            class="icon"
                            :class="open_dropdown3 ? 'rotate-sc-icon' : ''"
                            ><svg
                              width="8"
                              height="8"
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                                fill="#677F8E"
                              />
                            </svg>
                          </span>
                          <div
                            class="options"
                            v-show="open_dropdown3"
                            style="left: 0px; top: 33px"
                          >
                            <ul>
                              <li style="color: white" @click="d3 = '0'">
                                เลือกตำบล/ แขวง
                              </li>
                            </ul>
                          </div>
                        </div>
                        
                      </div> -->

                      <div
                        ref="ProvinceDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="open_Province_dropdown = true"
                        @blur="open_Province_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchProvince"
                        />
                        <span
                          v-if="searchProvince.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกจังหวัด
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Province_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListProvince"
                              :key="index"
                              @click.stop="
                                searchProvince = item.name;
                                selectedProvince = item.id;
                                open_Province_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- zipcode input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        รหัสไปรษณีย์
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.ZipCode"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin: 42px 0px 40px"
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changeAddress('Address')"
                      >
                        <span v-if="isLoadingAddress" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-cancel btn-text-sarabun ms-3 w-400"
                        style="color: white"
                        @click="ModalEditAddress = false"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End modal edit Address -->
    <!-- modal Edit Address id card -->
    <div v-if="ModalEditAddressIdCard">
      <transition name="modal">
        <div class="modal-mask ModalEditAddressIdCard">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              display: block;
              overflow: auto;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 591px"
            >
              <div class="modal-content">
                <div class="modal-header p-0" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงที่อยู่ตามบัตรประชาชน
                            <!-- <span>
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            @click="
                              selectedSubDist = '';
                              selectedDist = '';
                              selectedProvince = '';
                            "
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalEditAddressIdCard = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller p-0">
                  <div
                    class="container p-0"
                    style="padding-left: 24px !important"
                  >
                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex borderBottom-in-modal"
                          style="width: 432px"
                        >
                          <p style="margin: 8px 0px 48px; color: rgb(255, 255, 255)">
                            ที่อยู่เดิม
                          </p>
                          <p style="margin: 8px 0px 24px 62px; color: #9bacb6">
                            {{ user.addressidcard }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- id card  address heading -->
                    <p class="mt-3 mb-0" style="color: rgb(255, 255, 255)">ที่อยู่ใหม่</p>
                    <!-- number and group two input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 34px">
                        เลขที่
                      </p>
                      <div class="name-input">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <div
                          class="left-right-input d-flex"
                          style="margin-top: 28px"
                        >
                          <input
                            v-model="Address.HouseNO"
                            type="text"
                            class="form-control two-ipnuts"
                            placeholder="ระบุข้อมูล"
                            @input="onHouseNOInput"
                          />
                          <p
                            class="text-dark-grey mt-2 two-inputs-labelMargin"
                            style="margin: 0px 14px 0px 15px"
                          >
                            หมู่
                          </p>
                          <label for="inputPassword2" class="visually-hidden"
                            >ระบุข้อมูล</label
                          >
                          <input
                            v-model="Address.Group"
                            type="text"
                            style="width: 84px !important"
                            class="form-control two-ipnuts"
                            placeholder="ระบุข้อมูล"
                            @input="onGroupInput"
                          />
                        </div>
                      </div>
                    </div>

                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        หมู่บ้าน / อาคาร
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Village"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>

                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 37px">
                        ชั้น
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Floor"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                          @input="onFloorInput"
                        />
                      </div>
                    </div>

                    <!-- alley input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 40px">
                        ซอย
                      </p>
                      <div class="name-input" style="margin-top: 25px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Alley"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>
                    <!-- road input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 36px">
                        ถนน
                      </p>
                      <div class="name-input" style="margin-top: 27px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Road"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>
                    <!-- subdistrict select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 30px">
                        ตำบล/ แขวง
                      </p>
                      <div
                        ref="subDistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="open_subDist_dropdown = true"
                        @blur="open_subDist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchSubDist"
                        />
                        <span
                          v-if="searchSubDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกตำบล/ แขวง
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_subDist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListSubDist"
                              :key="index"
                              @click.stop="
                                searchSubDist = item.name;
                                selectedSubDist = item.id;
                                open_subDist_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <!-- district select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 30px">
                        อำเภอ/ เขต
                      </p>
                      <div
                        ref="DistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="open_Dist_dropdown = true"
                        @blur="open_Dist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchDist"
                        />
                        <span
                          v-if="searchDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกอำเภอ/ เขต
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Dist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListDist"
                              :key="index"
                              @click.stop="
                                searchDist = item.name;
                                selectedDist = item.id;
                                open_Dist_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!-- <div class="name-input" style="margin-top: 24px">
                        <div
                          class="select"
                          tabindex="0"
                          @click="open_dropdown8 = !open_dropdown8"
                          @blure="open_dropdown8 = false"
                        >
                          <span class="text" style="left: 10px; color: #677f8e"
                            >เลือกอำเภอ/ เขต</span
                          >
                          <span
                            class="icon"
                            :class="open_dropdown8 ? 'rotate-sc-icon' : ''"
                            ><svg
                              width="8"
                              height="8"
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                                fill="#677F8E"
                              />
                            </svg>
                          </span>
                          <div
                            class="options"
                            v-show="open_dropdown8"
                            style="left: 0px; top: 33px"
                          >
                            <ul>
                              <li @click="d8 = '0'">เลือกอำเภอ/ เขต</li>
                            </ul>
                          </div>
                        </div>
                      </div> -->
                    </div>

                    <!-- Province select box -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 30px">
                        จังหวัด
                      </p>
                      <div
                        ref="ProvinceDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -3px;
                        "
                        @click="open_Province_dropdown = true"
                        @blur="open_Province_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchProvince"
                        />
                        <span
                          v-if="searchProvince.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกจังหวัด
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Province_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListProvince"
                              :key="index"
                              @click.stop="
                                searchProvince = item.name;
                                selectedProvince = item.id;
                                open_Province_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!-- <div class="name-input" style="margin-top: 24px">
                        <div
                          class="select"
                          tabindex="0"
                          @click="open_dropdown9 = !open_dropdown9"
                          @blure="open_dropdown9 = false"
                        >
                          <span class="text" style="left: 10px; color: #677f8e"
                            >เลือกจังหวัด</span
                          >
                          <span
                            class="icon"
                            :class="open_dropdown9 ? 'rotate-sc-icon' : ''"
                            ><svg
                              width="8"
                              height="8"
                              viewBox="0 0 12 8"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <path
                                d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                                fill="#677F8E"
                              />
                            </svg>
                          </span>
                          <div
                            class="options"
                            v-show="open_dropdown9"
                            style="left: 0px; top: 33px"
                          >
                            <ul>
                              <li @click="d9 = '0'">เลือกจังหวัด</li>
                            </ul>
                          </div>
                        </div>
                      </div> -->
                    </div>
                    <!-- zipcode input -->
                    <div
                      class="wraper-input d-flex justify-content-between mr-101"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 30px">
                        รหัสไปรษณีย์
                      </p>
                      <div class="name-input" style="margin-top: 24px">
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.ZipCode"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin: 42px 0px 40px"
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changeAddress('AddressIDCard')"
                      >
                        <span v-if="isLoadingAddress" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-cancel btn-text-sarabun ms-3 w-400"
                        style="color: white"
                        @click="ModalEditAddressIdCard = false"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End modal edit Address id card -->
    <!-- modal Edit workplace -->
    <div v-if="ModalEditWorkplace">
      <transition name="modal">
        <div class="modal-mask">
          <div
            class="modal-wrapper"
            style="
              height: 100%;
              overflow-y: auto;
              display: block;
              background-color: #0a0d10;
            "
          >
            <div
              class="modal-dialog"
              role="document"
              style="margin: 60px auto; height: 591px"
            >
              <div class="modal-content">
                <div class="modal-header" style="border-bottom: none">
                  <div class="container p-0">
                    <div
                      class="row m-0"
                      style="padding-top: 24px; padding-bottom: 24px"
                    >
                      <div class="p-0">
                        <div
                          class="d-flex justify-content-between position-relative"
                        >
                          <h5 class="modal-title m-auto text-center">
                            เปลี่ยนแปลงสถานที่ทำงาน
                            <!-- <span>
                              <svg
                                width="21"
                                height="21"
                                viewBox="0 0 21 21"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                              >
                                <path
                                  d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                                  fill="#677F8E"
                                />
                              </svg>
                            </span> -->
                          </h5>
                          <button
                            type="button"
                            class="close"
                            data-dismiss="modal"
                            aria-label="Close"
                            style="
                              position: absolute;
                              right: 30px;
                              padding: 0px;
                              line-height: 0px;
                              top: 14px;
                            "
                          >
                            <span
                              aria-hidden="true"
                              @click="ModalEditWorkplace = false"
                              style="color: #677f8e; font-size: 30px"
                              >&times;</span
                            >
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="modal-body scroller">
                  <div
                    class="container p-0"
                    style="padding-left: 24px !important"
                  >
                    <div class="row m-0">
                      <div class="col p-0">
                        <div
                          class="current-name d-flex borderBottom-in-modal"
                          style="width: 432px"
                        >
                          <p
                            class="text-dark-grey mb-0"
                            style="margin-top: 28px; color: rgb(255, 255, 255) !important"
                          >
                            สถานที่ทำงานปัจจุบัน
                          </p>
                          <p
                            class="text-grey"
                            style="margin: 28px 0px 39px 26px; color: #9bacb6"
                          >
                            {{ user.placeofwork }} <br />
                            {{ user.detailofwork }} <br />
                            {{ user.detailofwork2 }}
                          </p>
                        </div>
                      </div>
                    </div>
                    <!-- new workplace heading -->
                    <p class="mb-0 mt-4" style="color: rgb(255, 255, 255)">
                      อาขีพและรายได้
                    </p>
                    <!-- work name -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 42px">
                        ชื่อที่ทำงาน
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 36px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                          v-model="Address.WorkName"
                        />
                      </div>
                    </div>
                    <!-- occupation -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <!-- uocupation -->
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        อาชีพ
                      </p>
                      <div
                        ref="ocupationDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="open_Ocupation_dropdown = true"
                        @blur="open_Ocupation_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchOcupation"
                        />
                        <span
                          v-if="searchOcupation.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกอาชีพ
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Ocupation_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListOcupation"
                              :key="index"
                              @click.stop="
                                searchOcupation = item.name;
                                selectedOcupation = item.id;
                                open_Ocupation_dropdown = false;
                                getBussinesstype();
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                      <!-- <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div> -->
                    </div>
                    <!-- job position -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ตำแหน่งงาน
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                          v-model="Address.JobTitle"
                        />
                      </div>
                    </div>
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <!-- Bussiness -->
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ประเภทธุรกิจ
                      </p>
                      <div
                        ref="bussinesstypeDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="open_Bussinesstype_dropdown = true"
                        @blur="open_Bussinesstype_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchBussinesstype"
                          :disabled="filteredListBussinesstype.length <= 0"
                          :class="{ 'd-dropdown': filteredListBussinesstype.length <= 0 }"
                        />
                        <span
                          v-if="searchBussinesstype.length == 0 && filteredListBussinesstype.length > 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #566a76;
                            pointer-events: none;
                          "
                        >
                          เลือกประเภทธุรกิจ
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="
                            open_Bussinesstype_dropdown ? 'show' : 'hidden'
                          "
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListBussinesstype"
                              :key="index"
                              @click.stop="
                                searchBussinesstype = item.name;
                                selectedBussinesstype = item.id;
                                open_Bussinesstype_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- income -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <!-- Bussiness -->
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        รายได้
                      </p>
                      <div
                        ref="monthlyIncomeDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="open_MonthlyIncome_dropdown = true"
                        @blur="open_MonthlyIncome_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchMonthlyIncome"
                        />
                        <span
                          v-if="searchMonthlyIncome.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #566a76;
                            pointer-events: none;
                          "
                        >
                          เลือกรายได้
                        </span>
                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="
                            open_MonthlyIncome_dropdown ? 'show' : 'hidden'
                          "
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListMonthlyIncome"
                              :key="index"
                              @click.stop="
                                searchMonthlyIncome = item.monthlyIncome;
                                selectedMonthlyIncome = item.id;
                                open_MonthlyIncome_dropdown = false;
                              "
                            >
                              {{ item.monthlyIncome }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- ocupation -->
                    <!-- new workplace heading -->
                    <p class="text-dark-grey mt-4 mb-0">ที่ตั้งที่ทำงานใหม่</p>
                    <!-- number and group two input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 42px">
                        เลขที่
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 36px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <div class="left-right-input d-flex">
                          <input
                            type="text"
                            class="form-control two-ipnuts"
                            placeholder="ระบุข้อมูล"
                            v-model="Address.HouseNO"
                            @input="onHouseNOInput"
                          />
                          <p
                            class="text-dark-grey mt-2 two-inputs-labelMargin"
                            style="margin: 0px 15px 0px 14px"
                          >
                            หมู่
                          </p>
                          <label for="inputPassword2" class="visually-hidden"
                            >ระบุข้อมูล</label
                          >
                          <input
                            v-model="Address.Group"
                            type="text"
                            style="width: 84px !important"
                            class="form-control two-ipnuts"
                            placeholder="ระบุข้อมูล"
                            @input="onGroupInput"
                          />
                        </div>
                      </div>
                    </div>
                    <!-- village workplace -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        หมู่บ้าน / อาคาร
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                          v-model="Address.Village"
                        />
                      </div>
                    </div>
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ชั้น
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.Floor"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                          @input="onFloorInput"
                        />
                      </div>
                    </div>
                    <!-- alley input workplace-->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ซอย
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                          v-model="Address.Alley"
                        />
                      </div>
                    </div>
                    <!-- road input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ถนน
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                          v-model="Address.Road"
                        />
                      </div>
                    </div>
                    <!-- subdistrict select box -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        ตำบล/ แขวง
                      </p>
                      <div
                        ref="subDistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="open_subDist_dropdown = true"
                        @blur="open_subDist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchSubDist"
                        />
                        <span
                          v-if="searchSubDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกตำบล/ แขวง
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_subDist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListSubDist"
                              :key="index"
                              @click.stop="
                                searchSubDist = item.name;
                                selectedSubDist = item.id;
                                open_subDist_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <!-- district select box -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-0" style="margin-top: 30px">
                        อำเภอ/ เขต
                      </p>
                      <div
                        ref="DistDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="open_Dist_dropdown = true"
                        @blur="open_Dist_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchDist"
                        />
                        <span
                          v-if="searchDist.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกอำเภอ/ เขต
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Dist_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListDist"
                              :key="index"
                              @click.stop="
                                searchDist = item.name;
                                selectedDist = item.id;
                                open_Dist_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>

                    <!-- Province select box -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        จังหวัด
                      </p>
                      <div
                        ref="ProvinceDropdown"
                        class="col-7 col-md-8 input-container"
                        style="
                          line-height: 24px;
                          margin-top: 21px;
                          position: relative;
                          left: -12px;
                        "
                        @click="open_Province_dropdown = true"
                        @blur="open_Province_dropdown = false"
                        tabindex="0"
                      >
                        <input
                          style="
                            width: 240px;
                            padding: 16px;
                            padding-left: 10px;
                          "
                          type="text"
                          v-model="searchProvince"
                        />
                        <span
                          v-if="searchProvince.length == 0"
                          style="
                            position: absolute;
                            left: 40px;
                            top: 7px;
                            color: #677f8e;
                            pointer-events: none;
                          "
                        >
                          เลือกจังหวัด
                        </span>
                        <!-- :value="selectedSubDist" -->

                        <span>
                          <svg
                            width="8"
                            height="8"
                            viewBox="0 0 12 8"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <path
                              d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                              fill="#677F8E"
                            />
                          </svg>
                          <!-- there -->

                          <!-- search? -->
                        </span>
                        <div
                          style="width: 240px"
                          class="d-options"
                          :class="open_Province_dropdown ? 'show' : 'hidden'"
                        >
                          <ul
                            style="
                              max-height: 200px;
                              position: relative;
                              z-index: 999;
                              overflow: auto;
                            "
                          >
                            <li
                              v-for="(item, index) in filteredListProvince"
                              :key="index"
                              @click.stop="
                                searchProvince = item.name;
                                selectedProvince = item.id;
                                open_Province_dropdown = false;
                              "
                            >
                              {{ item.name }}
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                    <!-- zipcode input -->
                    <div
                      class="wraper-input d-flex justify-content-between"
                      style="width: 90%"
                    >
                      <p class="text-dark-grey mb-2" style="margin-top: 30px">
                        รหัสไปรษณีย์
                      </p>
                      <div
                        class="name-input"
                        style="width: 70%; margin-top: 24px"
                      >
                        <label for="inputPassword2" class="visually-hidden"
                          >ระบุข้อมูล</label
                        >
                        <input
                          v-model="Address.ZipCode"
                          type="text"
                          class="form-control address-input"
                          placeholder="ระบุข้อมูล"
                        />
                      </div>
                    </div>

                    <!-- modal button bottom -->
                    <div
                      class="button text-center"
                      style="margin: 40px 0px 32px"
                    >
                      <button
                        type="button"
                        class="btn btn-primary btn-text-sarabun"
                        @click="changeAddress('Workplace')"
                      >
                        <span v-if="isLoadingAddress" style="color: black"
                          >กำลังส่งคำขอ ...</span
                        >
                        <span v-else style="color: black">บันทึก</span>
                      </button>
                      <button
                        type="button"
                        class="btn btn-cancel btn-text-sarabun ms-3 w-400"
                        style="color: white"
                        @click="ModalEditWorkplace = false"
                      >
                        ยกเลิก
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- End modal workplace -->
    <OtpModalSettings
      :OtpResponse="OtpResponse"
      :OtpType="OtpType"
      @OtpResponseUpdate="handleOtpResponseUpdate"
      :OtpValue="OtpValue"
      :OtpError="OtpError"
      :OtpSuccess="OtpSuccess"
      :refCode="refCode"
      @UpdateRefCode="UpdateRefCode"
    />
  </div>
</template>

<script>
import Vue from "vue";
import { HTTP } from "../../config/axios";
import OtpModalSettings from "./components/OtpModalSettings.vue";
export default Vue.extend({
  components: { OtpModalSettings },
  name: "PersonalInformation",
  data() {
    return {
      coin: [],
      searchTitleName: "",
      selectedTitleName: "",
      open_titleName_dropdown: false,
      ListTitleName: [],
      //
      searchSubDist: "",
      selectedSubDist: "",
      open_subDist_dropdown: false,
      //
      searchDist: "",
      selectedDist: "",
      open_Dist_dropdown: false,
      //
      searchProvince: "",
      selectedProvince: "",
      open_Province_dropdown: false,
      //
      ListOcupation: [],
      searchOcupation: "",
      selectedOcupation: "",
      open_Ocupation_dropdown: false,
      //
      ListBussinesstype: [],
      searchBussinesstype: "",
      selectedBussinesstype: "",
      open_Bussinesstype_dropdown: false,
      //
      ListMonthlyIncome: [],
      searchMonthlyIncome: "",
      selectedMonthlyIncome: "",
      open_MonthlyIncome_dropdown: false,
      //
      open_dropdown1: false,
      d1: "",
      open_dropdown2: false,
      d2: "",
      open_dropdown3: false,
      d3: "",
      open_dropdown4: false,
      d4: "",
      open_dropdown5: false,
      d5: "",
      open_dropdown6: false,
      d6: "",
      open_dropdown7: false,
      d7: "",
      open_dropdown8: false,
      d8: "",
      open_dropdown9: false,
      d9: "",
      ModalEditName: false,
      showModalOpenCamera: false,
      ModalIDCard: false,
      ModalSelfi: false,
      ModalShowPhoto: false,
      ModalEditPhoneNumber: false,
      ModalEditEmail: false,
      ModalEditAddress: false,
      ModalEditAddressIdCard: false,
      ModalEditWorkplace: false,
      user: {
        realname: "",
        username: "",
        tradelimit: "",
        balance: "",
        surname: "",
        phone: "",
        email: "",
        addresscurrent: "",
        addressidcard: "",
        placeofwork: "",
        detailofwork: "",
        detailofwork2: "",
      },
      isLoadingPhone: false,
      isLoadingEmail: false,
      isLoadingName: false,
      NewPhone: "",
      NewEmail: "",
      NewName: "",
      NewSurName: "",
      titleId: "",
      NewLaserCode: "",
      OtpType: "",
      OtpValue: "",
      OtpResponse: false,
      refCode: "",
      OtpError: false,
      OtpSuccess: false,
      ListProvince: [],
      ListDist: [],
      ListSubDist: [],
      Address: {
        HouseNO: "",
        Group: "",
        Village: "",
        Alley: "",
        Road: "",
        SubDistrict: "",
        District: "",
        Province: "",
        ZipCode: "",
        WorkName: "",
        JobTitle: "",
        Income: "",
        Occupation: 0,
      },
      laserIdOptions : {
        tokens: {
          A: {
            pattern: /[A-Z]/,
            multiple: false,
            transform: chr => chr.toUpperCase()
          }
        }
      },
      VideoStream: null,
      CameraVideo: null,
      PhotoBase64: null,
      IDCardBase64: null,
      SelfiBase64: null,
      CameraStep: 0,
      CameraReady: false,
      NameSimilarity: 0,
      BankAccountShow: false,
      BankAccountStatus: "",
      BankAccountcolor: "",
    };
  },
  methods: {
    BacktoStartCamera(){
      this.ModalShowPhoto = false;
      (this.CameraStep == 1) ? this.ModalSelfi = true : this.ModalIDCard = true;
      this.PhotoBase64 = null;
      this.CameraVideoStream = null,
      this.CameraVideo = null;
      this.CameraReady = false;
      this.StartCamera();
    },
    NexttoStartCamera(){
      this.CameraStep += 1;
      if (this.CameraStep == 1){
        this.ModalShowPhoto = false;
        this.ModalSelfi = true;
        this.IDCardBase64 = this.PhotoBase64;
        this.CameraVideoStream = null,
        this.CameraVideo = null;
        this.CameraReady = false;
        this.PhotoBase64 = null;
        this.StartCamera();
      } else if (this.CameraStep == 2){
        this.ModalSelfi  = false;
        this.ModalIDCard = false;
        this.ModalEditName = false;
        this.FaceRecognize();
      }
    },
    async StartCamera() {
      try {
        this.CameraVideoStream = await navigator.mediaDevices.getUserMedia({ video: true });
        this.CameraVideo = this.$refs.camera;
        this.CameraVideo.srcObject = this.CameraVideoStream;
        
        this.CameraVideo.addEventListener('loadedmetadata', () => {
          // Video's metadata is loaded; it's now safe to work with this.CameraVideo
          this.CameraReady = true;
          console.log('Camera MetaData Loaded');
        });
      } catch (error) {
        console.log('Camera MetaData Loaded' + error);
        this.$notify({
              group: "notification",
              type: "error",
              title: "Unable to Start Camera for Verification",
              text: error,
            });
      }
    },
    StopCamera()
    {
      if (this.CameraVideoStream) {
        this.CameraVideoStream.getTracks().forEach(track => track.stop());
      }
    },
    TakePicture() {
      if (this.CameraVideo && this.CameraVideoStream && this.CameraReady == true) {
        const canvas = document.createElement('canvas');
        canvas.width = this.CameraVideo.videoWidth;
        canvas.height = this.CameraVideo.videoHeight;
        const context = canvas.getContext('2d');
        context.drawImage(this.CameraVideo, 0, 0, canvas.width, canvas.height);
        const base64Image = canvas.toDataURL('image/jpeg');
        console.log('Base64 image:', base64Image);
        this.PhotoBase64 = base64Image;
        if (this.CameraVideoStream) {
          this.CameraVideoStream.getTracks().forEach(track => track.stop());
        }
        if (this.CameraStep == 1) {
          this.SelfiBase64 = this.PhotoBase64;
          this.CheckLiveness();
        }
        else{
          this.ModalShowPhoto = true;
          this.ModalSelfi = false;
        }
      }
      else {
        this.$notify({
              group: "notification",
              type: "error",
              title: "Unable to Take Picture for Verification",
              text: "Please Wait for the Camera to be Ready.",
            });
      }
    },
    CheckLiveness() {
      let self = this;
      console.log("this.SelfiBase64");
      HTTP.post("/uc/recognize/liveness",{
        imageBase64: this.processBase64(this.SelfiBase64),
      })
      .then(function (response) {
        if (response?.data?.data?.result == true) {
          self.ModalShowPhoto = true;
          self.ModalSelfi = false;
        }
        self.$notify({
          group: "notification",
          type: response?.data?.data?.result == true ? "success" : "error",
          title:
            response?.data?.data?.result == true
              ? "Success"
              : "Error",
          text: response?.data?.data?.reason,
        });
        if (response?.data?.data?.result == false){
          self.BacktoStartCamera();
        }

        console.log("CameraStep",self.CameraStep);
      })
      .catch(function (error) {
        self.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: error,
        });
      });
    },
    FaceRecognize() {
      let self = this;
      console.log("this.SelfiBase64");
      HTTP.post("/uc/recognize/face-recognize",{
        imgSource: this.processBase64(this.IDCardBase64),
        imgTarget: this.processBase64(this.SelfiBase64),
      })
      .then(function (response) {
        if (response?.data?.data?.reason == "Complete") {
          self.NameSimilarity = (response?.data?.data?.similarity);
          //console.log(self.NameSimilarity.toFixed(2));
          if (self.NameSimilarity > 95)
          {
            self.changeNameOTP();
            self.ModalShowPhoto = false;
          }
          else
          {
            self.ModalShowPhoto = false;
            self.OtpError = true;
            self.OtpValue = self.NameSimilarity;
            self.OtpType  = "name";
          }
        }
        else {
          self.$notify({
            group: "notification",
            type: response?.data?.data?.reason == "Complete" ? "success" : "error",
            title:
              response?.data?.data?.reason == "Complete"
                ? "Success"
                : "Error",
            text: response?.data?.data?.reason,
          });
          self.CameraStep -= 1;
          self.BacktoStartCamera();
        }
      })
      .catch(function (error) {
        self.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: error,
        });
      });
    },
    processBase64(Base64String) {
      const prefix = "data:image/jpeg;base64,";
      if (Base64String.startsWith(prefix)) {
        return Base64String.substring(prefix.length);
      }
    },
    UpdateRefCode(newRefCode){
      this.refCode = newRefCode;
    },
    onHouseNOInput(event) {
      const inputValue = event.target.value;
      this.Address.HouseNO = inputValue.replace(/\D/g, '');
    },
    onGroupInput(event) {
      const inputValue = event.target.value;
      this.Address.Group = inputValue.replace(/\D/g, '');
    },
    onFloorInput(event) {
      const inputValue = event.target.value;
      this.Address.Floor = inputValue.replace(/[^a-zA-Z0-9/-]/g, '');
    },
    ValidateThaiInput(event, type) {
      const inputValue = event.target.value;
      const cleanedValue = inputValue
        .replace(/[^\u0E00-\u0E7F\s]/g, '')
        .replace(/(\..*)\./g, '$1')
        .replace(/^\s+/,'')
        .replace(/[๐-๙฿]/g, '');
      if (type === 'surname') {
        this.NewSurName = cleanedValue;
      } else if (type === 'name') {
        this.NewName = cleanedValue;
      }
    },
    validateInput() {
      this.NewPhone = this.NewPhone.replace(/[^0-9]/g, "").slice(0, 10);
    },
    OpenCameraModel() {
      this.ModalEditName = !this.ModalEditName;
      this.showModalOpenCamera = !this.showModalOpenCamera;
    },
    handleOtpResponseUpdate(Value) {
      if (Value == "OtpSuccess") {
        if (this.OtpType == "name") {
          this.changeNameSuccess();
        } else if (this.OtpType == "address") {
          this.changeAddressSuccess("current");
        } else if (this.OtpType == "addressidcard") {
          this.changeAddressSuccess("addressidcard");
        } else if (this.OtpType == "workplace") {
          this.changeAddressSuccess("workplace");
        } else {
          this.OtpResponse = false;
          this.OtpSuccess = true;
          this.getMemberInfo();
        }
      } else if (Value == "OtpError") {
        this.OtpResponse = false;
        this.OtpError = true;
      } else if (Value == "OTP") {
        this.OtpResponse = false;
        if (this.OtpType == "phone") {
          this.handleModal("Phone", "true");
        } else if (this.OtpType == "email") {
          this.handleModal("Email", "true");
        } else if (this.OtpType == "name") {
          this.handleModal("Name", "true");
        }
      } else if (Value == "OTPSuccess") {
        this.OtpSuccess = false;
      } else if (Value == "OTPError") {
        this.OtpError = false;
      }
    },
    getMemberInfo() {
      let self = this;
      HTTP.post("/uc/member/get-member-info")
        .then(function (response) {
          if (response.data.code == "0") {
            const resData = response.data.data;
            var resMember = response.data.data.member;
            var reslockStatus = response.data.data.lockStatus;
            let combinedDataMemberStatus = {
                                              resMember: resMember,
                                              reslockStatus: reslockStatus
                                            };
            var resWallet = response.data.data.wallets;
            var occupationText = response.data.data.occupation;
            localStorage.setItem("resMember", JSON.stringify(combinedDataMemberStatus));
            var kycReviewStatus = response.data.data.kycReviewStatus;
            localStorage.setItem("kycReviewStatus", JSON.stringify(kycReviewStatus));
            self.user.realname = resMember?.resMember.realName;
            self.user.username = resMember?.resMember.username;
            self.user.tradelimit = "";
            self.user.balance = resWallet[0]["balance"].toLocaleString(
              undefined,
              {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              }
            );
            self.user.surname = resMember?.resMember.realName;
            self.user.phone = resMember?.resMember.mobilePhone;
            

            self.user.email = resMember?.resMember.email;
            if (response.data.data && "userBasicInfo" in response.data.data) {
              var resBasicInfo = response.data.data.userBasicInfo;
              //localStorage.setItem("resBasicInfo", JSON.stringify(resBasicInfo));
              self.user.titleName = resBasicInfo.prefixt;
              self.user.districtName = resBasicInfo.currentdistricttext;
              self.user.provinceName = resBasicInfo.currentprovincetext;
              self.user.amphoeName = resBasicInfo.currentamphoetext;

              self.user.districtName = resBasicInfo.currentdistricttext;
              self.user.provinceName = resBasicInfo.currentprovincetext;
              self.user.amphoeName = resBasicInfo.currentamphoetext;

              self.user.idCardDistrictName = resBasicInfo.idcarddistrictext;
              self.user.idCardProvinceName = resBasicInfo.idcardprovincetext;
              self.user.idCardAmphoeName = resBasicInfo.idcardamphoetext;

              self.user.workDistrictName = resBasicInfo.workdistrictext;
              self.user.workProvinceName = resBasicInfo.workprovincetext;
              self.user.workAmphoeName = resBasicInfo.workamphoetext;
              self.user.addresscurrent = resData.currentAddress;
              self.user.addressidcard = resData.idCardAddress;
              self.user.placeofwork = resData.workAddress;
              self.user.detailofwork =
                "ชื่อที่ทำงาน " +
                (resBasicInfo.workplace ? resBasicInfo.workplace : "-") +
                "   อาชีพ " +
                occupationText;
              self.user.detailofwork2 =
                "ตำแหน่งงาน " +
                (resBasicInfo.position ? resBasicInfo.position : "-") +
                "   รายได้ " +
                resBasicInfo.monthlyIncome;
            }
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Member Info Successful"
                  : "Unable to Get Current Member Info",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Current Member Info",
            text: error,
          });
        });
    },
    changeName() {
      if (this.validateForm("Name")) {
        this.ModalIDCard = true;
        this.StartCamera();
      }
    },
    changeNameOTP() {
      this.isLoadingName = true;
      if (this.validateForm("Name")) {
        let self = this;
        HTTP.post("/uc/sendOtp")
        .then(function (response) {
          self.isLoadingName = false;
          if (response.data.code == "0") {
            self.OtpType = "name";
            self.OtpValue = self.NameSimilarity;
            self.ModalEditName = false;
            self.OtpResponse = true;
            self.refCode = response.data.data;
          }
          self.$notify({
            group: "notification",
            type: response.data.code == "0" ? "success" : "error",
            title:
              response.data.code == "0"
                ? "Success"
                : "Error",
            text: response.data.message,
          });
        })
        .catch(function (error) {
          self.isLoadingName = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Change Name Unsuccessful",
            text: error,
          });
        });
      } else {
        this.isLoadingName = false;
      }
    },
    changeNameSuccess() {
      let self = this;
      let obj = ["นาย", "นาง", "นางสาว"];
      HTTP.post("/uc/member/change-name", {
        name: this.NewName,
        lastName: this.NewSurName,
        titleId: obj.indexOf(this.searchTitleName) + 1, // this.titleId
      })
        .then(function (response) {
          if (response.data.code == "0") {
            self.OtpSuccess = true;
            self.OtpResponse = false;
          } else {
            self.OtpError = true;
            self.OtpResponse = false;
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Change Name Unsuccessful",
            text: error,
          });
        });
    },
    changePhone() {
      this.isLoadingPhone = true;
      if (this.validateForm("Phone")) {
        let self = this;
        HTTP.post("/uc/member/change-mobile", {
          mobile: this.NewPhone,
        })
          .then(function (response) {
            self.isLoadingPhone = false;
            if (response.data.code == "0") {
              self.OtpType = "phone";
              self.OtpValue = self.NewPhone;
              self.ModalEditPhoneNumber = false;
              self.OtpResponse = true;
              self.refCode = response.data.data;
            }
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Success"
                  : "Error",
              text: 
                response.data.message != ""
                ? response.data.message
                : "Phone Number Already Exist",
            });
          })
          .catch(function (error) {
            self.isLoadingPhone = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: error,
            });
          });
      } else {
        this.isLoadingPhone = false;
      }
    },
    changeEmail() {
      this.isLoadingEmail = true;
      if (this.validateForm("Email")) {
        let self = this;
        HTTP.post("/uc/member/change-email", {
          email: this.NewEmail,
        })
          .then(function (response) {
            self.isLoadingEmail = false;
            if (response.data.code == "0") {
              self.OtpType = "email";
              self.OtpValue = self.NewEmail;
              self.ModalEditEmail = false;
              self.OtpResponse = true;
              self.refCode = response.data.data;
            }
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Success"
                  : "Error",
              text: response.data.message,
            });
          })
          .catch(function (error) {
            self.isLoadingEmail = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: "Success",
              text: error,
            });
          });
      } else {
        self.isLoadingEmail = false;
      }
    },
    getAllProvinces() {
      let self = this;
      HTTP.post("/uc/member/get-all-provinces")
        .then((response) => {
          if (response.data.code == "0") {
            this.ListProvince = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Change Email Successful"
                  : "Get All Provinces Unsuccessful",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get All Provinces Unsuccessful",
            text: error,
          });
        });
    },
    getTitleName() {
      let self = this;
      HTTP.post("/uc/member/get-title")
        .then((response) => {
          if (response.data.code == "0") {
            this.ListTitleName = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Change Email Successful"
                  : "Get Title Name Unsuccessful",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Amphures By Provinces Unsuccessful",
            text: error,
          });
        });
    },
    getDistrict() {
      let self = this;
      HTTP.post("/uc/member/get-all-districts")
        .then((response) => {
          if (response.data.code == "0") {
            this.ListDist = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Change Email Successful"
                  : "Get Amphures By Provinces Unsuccessful",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Amphures By Provinces Unsuccessful",
            text: error,
          });
        });
    },
    getSubDistrict() {
      let self = this;
      HTTP.post("/uc/member/get-sub-districts")
        .then((response) => {
          if (response.data.code == "0") {
            this.ListSubDist = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Change Email Successful"
                  : "Get Amphures By Provinces Unsuccessful",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Amphures By Provinces Unsuccessful",
            text: error,
          });
        });
    },
    getOcupation() {
      let self = this;
      HTTP.post("/uc/member/get-occupation")
        .then((response) => {
          if (response.data.code == "0") {
            this.ListOcupation = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Ocupation Unsuccessful",
            text: error,
          });
        });
    },
    getBussinesstype() {
      let self = this;
      HTTP.post("/uc/member/get-bussinesstype/" + this.selectedOcupation)
        .then((response) => {
          if (response.data.code == "0") {
            this.ListBussinesstype = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Ocupation Unsuccessful",
            text: error,
          });
        });
    },
    getMonthlyIncome() {
      let self = this;
      HTTP.post("/uc/member/get-monthlyincome")
        .then((response) => {
          if (response.data.code == "0") {
            this.ListMonthlyIncome = response.data.data;
          } else {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title: "success",
              text: response.data.message,
            });
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Ocupation Unsuccessful",
            text: error,
          });
        });
    },
    validateForm(Type) {
      let self = this;
      let valid = true;

      if (Type == "Name") {
        if (!this.selectedTitleName) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Select คำนำหน้า",
          });
          valid = false;
        }
        if (!this.NewName) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter ชื่อ",
          });
          valid = false;
        }
        if (!this.NewSurName) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter นามสกุล",
          });
          valid = false;
        }
        //LaserCode
        const RGXLaserCode = /^[a-zA-Z]{2}\d{1}-\d{7}-\d{2}$/;
        if (RGXLaserCode.test(this.NewLaserCode) == false) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter รหัสหลังบัตรประชาชน (Laser Code)",
          });
          valid = false;
        }
      } else if (Type == "Phone") {
        let CheckPhone = this.NewPhone;
        if (!this.NewPhone) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter เบอร์โทรศัพท์ใหม่",
          });
          valid = false;
        }
        if (CheckPhone.substring(0, 1) != 0 || !CheckPhone.match(/^\d+$/)) {
          valid = false;
        } else if (
          ["0", "1", "2", "3", "4", "5", "7"].includes(CheckPhone.substring(1, 2))
        ) {
          valid = false;
        } else if (CheckPhone.length !== 10) {
          valid = false;
        }

        if (this.NewPhone && valid == false)
        {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter Valid เบอร์โทรศัพท์ใหม่",
          });
        }
      } else if (Type == "Email") {
        if (!this.NewEmail) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter อีเมลใหม่",
          });
          valid = false;
        } else if (!/\S+@\S+\.\S+/.test(this.NewEmail)) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter a valid อีเมลใหม่",
          });
          valid = false;
        }
      } else if (Type == "Workplace") {
        if (!this.Address.WorkName) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter ชื่อที่ทำงาน",
          });
          valid = false;
        } else if (this.selectedOcupation < 0) {
          //else if (!this.Address.Occupation) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter อาขีพ",
          });
          valid = false;
        } else if (!this.Address.JobTitle) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter ตำแหน่งงาน",
          });
          valid = false;
        } else if (this.selectedMonthlyIncome < 0) {
          //(!this.Address.Income) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter รายได้",
          });
          valid = false;
        }
      }
      if (Type == "Address" || Type == "Workplace") {
        if (!this.Address.HouseNO) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter เลขที่",
          });
          valid = false;
        } else if (!this.Address.Group) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter หมู่",
          });
          valid = false;
        } else if (!this.Address.Village) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter หมู่บ้าน / อาคาร",
          });
          valid = false;
        } else if (!this.Address.Floor) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter ชั้น",
          });
          valid = false;
        } else if (!this.Address.Alley) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter หมู่",
          });
          valid = false;
        } else if (!this.Address.Road) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter ถนน",
          });
          valid = false;
        } else if (!this.selectedSubDist) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter  ตำบล/ แขวง",
          });
          valid = false;
        } else if (!this.selectedDist) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter  อำเภอ/ เขต",
          });
          valid = false;
        } else if (!this.selectedProvince) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter จังหวัด",
          });
          valid = false;
        } else if (!this.Address.ZipCode) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "Please Enter  รหัสไปรษณีย์",
          });
          valid = false;
        }
      }
      return valid;
    },
    handleModal(Type, Value) {
      this.OtpResponse = false;
      this.isLoadingPhone = false;
      this.isLoadingEmail = false;
      this.isLoadingAddress = false;
      this.OtpType = "";
      this.OtpValue = "";
      if (Type == "Phone") {
        this.ModalEditPhoneNumber = Value;
        this.NewPhone = "";
      } else if (Type == "Email") {
        this.ModalEditEmail = Value;
        this.NewEmail = "";
      } else if (Type == "Name") {
        this.searchTitleName = this.user.titleName;
        this.ModalEditName = Value;
        this.NewName = "";
        this.NewSurName = "";
        this.titleId = "";
        this.NewLaserCode = "";
      } else if (Type == "Address") {
        this.ModalEditAddress = Value;
        this.Address.HouseNO = "";
        this.Address.Group = "";
        this.Address.Village = "";
        this.Address.Alley = "";
        this.Address.Road = "";
        this.Address.SubDistrict = "";
        this.Address.District = "";
        this.Address.Province = "";
        this.Address.ZipCode = "";
        this.selectedDist = "";
        this.selectedOcupation = "";
        this.selectedProvince = "";
        this.selectedSubDist = "";
        this.searchOcupation = "";
        this.searchDist = "";
        this.searchProvince = "";
        this.searchSubDist = "";

        // this.searchDist = this.user.districtName;
        // this.searchProvince = this.user.provinceName;
        // this.searchSubDist = this.user.amphoeName;
      } else if (Type == "AddressIDCard") {
        this.ModalEditAddressIdCard = Value;
        this.Address.HouseNO = "";
        this.Address.Group = "";
        this.Address.Village = "";
        this.Address.Alley = "";
        this.Address.Road = "";
        this.Address.SubDistrict = "";
        this.Address.District = "";
        this.Address.Province = "";
        this.Address.ZipCode = "";
        this.selectedDist = "";
        this.selectedOcupation = "";
        this.selectedProvince = "";
        this.selectedSubDist = "";
        this.searchOcupation = "";
        this.searchDist = "";
        this.searchProvince = "";
        this.searchSubDist = "";

        // this.searchDist = this.user.idCardDistrictName;
        // this.searchProvince = this.user.idCardProvinceName;
        // this.searchSubDist = this.user.idCardAmphoeName;
      } else if (Type == "Workplace") {
        this.ModalEditWorkplace = Value;
        this.Address.HouseNO = "";
        this.Address.Group = "";
        this.Address.Village = "";
        this.Address.Alley = "";
        this.Address.Road = "";
        this.Address.SubDistrict = "";
        this.Address.District = "";
        this.Address.Province = "";
        this.Address.ZipCode = "";
        this.Address.WorkName = "";
        this.Address.JobTitle = "";
        this.Address.Income = "";
        this.Address.Occupation = "";
        this.selectedDist = "";
        this.selectedOcupation = "";
        this.selectedProvince = ``;
        this.selectedSubDist = ``;
        this.searchOcupation = "";
        this.searchDist = "";
        this.searchProvince = "";
        this.searchSubDist = "";

        // this.searchProvince = this.user.workProvinceName;
        // this.searchSubDist = this.user.workAmphoeName;
        // this.searchDist = this.user.workDistrictName;
      }
    },
    changeAddress(Type) {
      this.isLoadingAddress = true;
      var Message = "";
      if (Type == "Address") {
        Message = "OTP for Change Address";
      } else if (Type == "AddressIDCard") {
        Message = "OTP for Change Address on ID Card";
      } else if (Type == "Workplace") {
        Message = "OTP for Change Address of Workplace";
      }
      if (this.validateForm(Type)) {
        let self = this;
        HTTP.post("/uc/sendOtp")
          .then(function (response) {
            self.isLoadingAddress = false;
            if (response.data.code == "0") {
              self.OtpType = Type.toLowerCase();
              self.OtpValue = "";
              if (Type == "Address") {
                self.ModalEditAddress = false;
              } else if (Type == "AddressIDCard") {
                self.ModalEditAddressIdCard = false;
              } else if (Type == "Workplace") {
                self.ModalEditWorkplace = false;
              }
              self.OtpResponse = true;
              self.refCode = response.data.data;
            }
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? Message + " Successful"
                  : Message + " Unsuccessful",
              text: response.data.message,
            });
          })
          .catch(function (error) {
            self.isLoadingAddress = false;
            self.$notify({
              group: "notification",
              type: "error",
              title: Message + " Unsuccessful",
              text: error,
            });
          });
      } else {
        this.isLoadingAddress = false;
      }
    },
    changeAddressSuccess(Type) {
      let self = this;
      if (Type == "current") {
        var Data = {
          houseNo: this.Address.HouseNO,
          group: this.Address.Group,
          village: this.Address.Village,
          addressFloor: this.Address.Floor,
          alley: this.Address.Alley,
          road: this.Address.Road,
          subDistrict: this.selectedSubDist,
          district: this.selectedDist,
          province: this.selectedProvince,
          subDistrictText: this.searchSubDist,
          districtText: this.searchDist,
          provinceText: this.searchProvince,
          zipCode: this.Address.ZipCode,
          type: "current",
        };
      }
      if (Type == "addressidcard") {
        var Data = {
          houseNo: this.Address.HouseNO,
          group: this.Address.Group,
          village: this.Address.Village,
          addressFloor: this.Address.Floor,
          alley: this.Address.Alley,
          road: this.Address.Road,
          subDistrict: this.selectedSubDist,
          district: this.selectedDist,
          province: this.selectedProvince,
          zipCode: this.Address.ZipCode,
          subDistrictText: this.searchSubDist,
          districtText: this.searchDist,
          provinceText: this.searchProvince,
          type: "idcard",
        };
      }
      if (Type == "workplace") {
        var Data = {
          houseNo: this.Address.HouseNO,
          group: this.Address.Group,
          village: this.Address.Village,
          addressFloor: this.Address.Floor,
          alley: this.Address.Alley,
          road: this.Address.Road,
          subDistrict: this.selectedSubDist,
          district: this.selectedDist,
          province: this.selectedProvince,
          zipCode: this.Address.ZipCode,
          nameTh: this.Address.WorkName,
          title: this.Address.JobTitle,
          income: this.selectedMonthlyIncome,
          occupation: this.selectedOcupation,
          bussinessType: this.selectedBussinesstype,
          subDistrictText: this.searchSubDist,
          districtText: this.searchDist,
          provinceText: this.searchProvince,
          type: "workplace",
        };
      }
      var Message = "";
      if (Type == "address") {
        Data.type = "current";
        Message = "Change Address";
      } else if (Type == "addressidcard") {
        Data.type = "idcard";
        Message = "Change Address on ID Card";
      } else if (Type == "workplace") {
        Data.type = "work";
        Message = "Change Address of Workplace";
      }

      HTTP.post("/uc/member/change-current-address", Data)
        .then(function (response) {
          if (response.data.code == "0") {
            self.OtpSuccess = true;
            self.OtpResponse = false;

            self.getMemberInfo();
          } else {
            self.OtpError = true;
            self.OtpResponse = false;
          }
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: Message + "Unsuccessful",
            text: error,
          });
        });
    },
    checkIfClickedOutside(event) {
      const TitleNameDropdown = this.$refs.titleNameDropdown;
      const subDistDropdown = this.$refs.subDistDropdown;
      const DistDropdown = this.$refs.DistDropdown;
      const ProvinceDropdown = this.$refs.ProvinceDropdown;
      const OcupationDropdown = this.$refs.ocupationDropdown;
      const BussinesstypeDropdown = this.$refs.bussinesstypeDropdown;
      const MonthlyIncomeDropdown = this.$refs.monthlyIncomeDropdown;
      if (TitleNameDropdown && !TitleNameDropdown.contains(event.target)) {
        this.open_titleName_dropdown = false;
      }
      if (subDistDropdown && !subDistDropdown.contains(event.target)) {
        this.open_subDist_dropdown = false;
      }
      if (DistDropdown && !DistDropdown.contains(event.target)) {
        this.open_Dist_dropdown = false;
      }
      if (ProvinceDropdown && !ProvinceDropdown.contains(event.target)) {
        this.open_Province_dropdown = false;
      }
      if (OcupationDropdown && !OcupationDropdown.contains(event.target)) {
        this.open_Ocupation_dropdown = false;
      }
      if (
        BussinesstypeDropdown &&
        !BussinesstypeDropdown.contains(event.target)
      ) {
        this.open_Bussinesstype_dropdown = false;
      }
      if (
        MonthlyIncomeDropdown &&
        !MonthlyIncomeDropdown.contains(event.target)
      ) {
        this.open_MonthlyIncome_dropdown = false;
      }
    },
  },
  mounted() {
    window.addEventListener("click", this.checkIfClickedOutside);
  },
  computed: {
    filteredFirstName: {
      get() {
        return this.NewName.replace(/[^\u0E00-\u0E7F]+/g, ''); // Replace non-Thai characters with an empty string
      },
      set(newValue) {
        this.NewName = newValue;
      },
    },
    filteredSurname: {
      get() {
        return this.NewSurName.replace(/[^\u0E00-\u0E7F]+/g, ''); // Replace non-Thai characters with an empty string
      },
      set(newValue) {
        this.NewSurName = newValue;
      },
    },
    filteredListTitleName() {
      if (!this.ListTitleName) return [];
      return this.ListTitleName.filter((item) =>
        item.nameTh
          ?.toLowerCase()
          .includes(this.searchTitleName?.toLowerCase() || "")
      );
    },
    filteredListSubDist() {
      if (!this.ListSubDist) return [];
      return this.ListSubDist.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchSubDist?.toLowerCase() || "")
      );
    },
    filteredListDist() {
      if (!this.ListDist) return [];
      return this.ListDist.filter((item) =>
        item.name?.toLowerCase().includes(this.searchDist?.toLowerCase() || "")
      );
    },
    filteredListProvince() {
      if (!this.ListProvince) return [];
      return this.ListProvince.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchProvince?.toLowerCase() || "")
      );
    },
    filteredListOcupation() {
      if (!this.ListOcupation) return [];
      return this.ListOcupation.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchOcupation?.toLowerCase() || "")
      );
    },
    filteredListBussinesstype() {
      if (!this.ListBussinesstype) return [];
      return this.ListBussinesstype.filter((item) =>
        item.name
          ?.toLowerCase()
          .includes(this.searchBussinesstype?.toLowerCase() || "")
      );
    },
    filteredListMonthlyIncome() {
      if (!this.ListMonthlyIncome) return [];
      return this.ListMonthlyIncome.filter((item) =>
        item.monthlyIncome
          ?.toLowerCase()
          .includes(this.searchMonthlyIncome?.toLowerCase() || "")
      );
    },
  },
  created() {
    this.getDistrict();
    this.getSubDistrict();
    this.getAllProvinces();
    this.getMemberInfo();
    this.getOcupation();
    this.getTitleName();
    // this.getBussinesstype();
    this.getMonthlyIncome();
  },
  beforeDestroy() {
    window.removeEventListener("click", this.checkIfClickedOutside);
    if (this.CameraVideoStream) {
      this.CameraVideoStream.getTracks().forEach(track => track.stop());
    }
  },
});
</script>

<style lang="scss" scoped>
.mr-101 {
  margin-right: 101px;
}
.font_sarabun {
  font-family: Sarabun !important;
}
.roboto_flex {
  font-family: Roboto Flex !important;
}
.w-400 {
  font-weight: 400 !important;
}
input {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgb(255, 255, 255) !important;
  caret-color: #09BE8B;
  line-height: 24px;
}
.select {
  width: 240px;
  height: 40px;
  background: #191b1c;
  border-radius: 4px;
  padding: 8px 12px;
  display: flex;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #677f8e;
  justify-content: space-between;
  position: relative;
}
.select .options {
  position: absolute;
}
.options {
  position: absolute;
  top: 41px !important;
  width: 100% !important;
  z-index: 1;
  width: 346px;
  background-color: #191b1c;
  border-radius: 4px;
}
.options ul {
  margin: 0;
  padding: 0;
}
.options ul li {
  display: flex;
  align-items: center;
  height: 40px;
  padding: 0 8px;
  border-radius: 4px;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.options ul li:hover {
  background-color: #191b1c;
}
.select svg {
  width: 12px;
  height: 8px;
}
table tr td:nth-child(2) p {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #ffffff;
}
.ModalEditAddressIdCard input::placeholder {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.modal-header {
  padding: 0px !important;
}
.modal-body {
  padding-top: 0px !important;
  padding-bottom: 0px !important;
}
.pb24 {
  padding-bottom: 24px !important;
}
.pt20 {
  padding-top: 20px !important;
}
.bg-box {
  background-color: #191b1c;
}
.page-heading {
  padding: 24px 0px 21px 105px;
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  color: rgb(255, 255, 255);
}
.box-personal-info {
  padding: 12px 28px 12px 29px;
  border-radius: 3px;
  /* remove width if not responsive */
  width: 359px;
  height: 179px;
  margin-left: 82px;
  .u-n {
    margin: 0;
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-bottom: 8px;
    color: white;
  }
}
.text-box {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.btn-text-sarabun {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 68px;
  height: 40px;
}
/* .text-box:hover {
  color: #ffff;
} */
.width-table {
  margin-left: 105px;
  margin-top: 41px;
  max-width: 764px;
}

.table-text {
  color: #9bacb6;
  font-size: 14px;
  line-height: 24px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.table-text-white {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.form-control {
  outline: none;
}
.table-text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.correct-button {
  text-decoration: underline;
  float: right;
}

.border-bottom-table {
  border-bottom: 1px solid #28363e;
}
.border-bottom-end {
  border-bottom: 1px solid #141b1f;
}
.button-camera-open {
  padding-top: 33px;
  // padding-bottom: 80px;
  justify-content: center;
  display: flex;
  /* margin-left: 30px; */
}
.attachment-wrap {
  background-color: #192126;
  /* padding: 48px 150px 48px 182px; */
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed #28363e;
  width: 432px;
  height: 120px;
}
.attachment-wrap-skull {
  background-color: #192126;
  padding-top: 0px !important;
  padding-bottom: 0px !important;
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed #28363e;
  width: 432px;
  margin: auto;
  height: 206px;
}
.image-note {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  padding-top: 43px;
  padding-bottom: 20px;
  color: rgb(255, 255, 255);
}
.note-list {
  color: #9bacb6;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  margin-bottom: 0px;
  font-family: "Sarabun" !important;
}
/* modal */
.marginTopBottom32 {
  margin-top: 48px;
  margin-bottom: 32px;
}

.modal-title {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  font-family: "Noto Sans Thai" !important;
  line-height: 27px;
}
.info-icon {
  margin-left: 3px;
}
.text-grey {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.text-dark-grey {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.borderBottom-in-modal {
  border-bottom: 1px solid #28363e;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #0a0d10;
  display: table;
  transition: opacity 0.3s ease;
}
.modal-mask p {
  line-height: 24px;
}
.pi {
  input::placeholder {
    color: #677f8e;
  }
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-dialog {
  background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}

.custom-checkbox {
  background: #191b1c;
  border: 1px solid #3c515d;
  border-radius: 2px;
  height: 17px;
  width: 17px;
}
.Get-started {
  margin: 20px;
}
.btn-cancel {
  background-color: #191b1c;
}
.input-container {
  position: relative;
  input {
    background-color: #191b1c;
    color: rgb(255, 255, 255);
    border-radius: 4px;
    height: 40px;
    padding: 0 40px;
    text-transform: capitalize;
    &::placeholder {
      color: #566a76;
    }
  }
  span {
    color: #9bacb6;
    margin-left: -30px;
  }
  .coin-image {
    position: absolute;
    left: 50px;
    top: 6px;
    pointer-events: none;
    img {
      width: 23px;
    }
  }
}
.hidden {
  display: none;
}
.show {
  display: block;
}
.d-options {
  position: absolute;
  top: 45px;
  width: 59%;
  background-color: #191b1c;
  border-radius: 4px;
  z-index: 10;
  img {
    width: 23px;
    margin-left: 26px;
    margin-right: 10px;
  }
  ul {
    margin: 0;
    padding: 0;
    li {
      display: flex;
      align-items: center;
      height: 40px;
      padding: 0 15px;
      border-radius: 4px;
      cursor: pointer;
      &:hover {
        background-color: #191b1c;
      }
    }
  }
}
.pass-modal {
  text-align: center;
  p {
    margin-bottom: 0;
  }
  .title {
    color: #0c9;
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    margin-top: 23px;
    margin-bottom: 30px;
  }
  .ph {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 36px;
    color: rgb(255, 255, 255);
    margin-top: 15px;
  }
  .psh {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: rgb(255, 255, 255);
  }
  .pb {
    width: 160px;
    height: 36px;
    background: #191b1c;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #ffffff;
    margin: auto;
    border-radius: 4px;
    margin-top: 46px;
  }
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}
.paswordInput-width {
  width: 240px !important;
}
.SurnameWidth {
  width: 240px !important;
}
.SurnameWidthPasword {
  width: 240px !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #192126;
}
.oc2 {
  text-align: center;
  margin-top: 17px;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  p {
    margin-bottom: 0;
  }
}
/* modal select box address */
.address-input {
  width: 240px !important;
}
.two-ipnuts {
  width: 109px !important;
  height: 40px !important;
}
.two-inputs-labelMargin {
  margin-left: 6px;
  margin-right: 9px;
}
select > option:checked,
select > option:hover {
  /* box-shadow: 0 0 10px 100px #dc6900 inset; */
  color: #09BE8B !important;
}
/* select:focus{
    border-color: #09BE8B;
    outline:none;
} */
select {
  /* padding: 5px; */
  border-radius: 4px;
  height: 40px;
  width: 240px !important;
  color: #ffffff;
  /* padding: 8px 12px; */
  /* padding-right: 30px; */
  font-size: 14px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #28363e !important;
  background: #677f8e
    url("../../../src/assets/images/profile-setting/dropdownChevron.png");
  background-repeat: no-repeat;
  background-position: right 7px top 50%;
}
.modal-padding {
  margin-left: 1rem;
}
/* modal select box end */

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-device-width: 768px) {
  .table-text {
    font-size: 10px;
  }
  .table-text-white {
    font-size: 10px;
  }

  .table-text-yellow {
    font-size: 10px;
  }
  .box-personal-info {
    width: 290px !important;
  }
  .text-box {
    font-size: 12px !important;
  }
}

.modal-content {
  border-radius: 8px !important;
  margin-top: 10px !important;
  margin-bottom: 100px !important;
  width: 100% !important;
  height: auto !important;
  background-color: #141b1f !important;
  overflow-x: hidden !important;
  // overflow-y: scroll !important;
  border-radius: 8px !important;
}

.d-dropdown
{
  background-color: lightgray !important;
}

</style>
