<template>
  <div>
    <b-modal
      id="market-order-sell-TPSLB"
      :hide-footer="true"
      :hide-header="true"
      @show="clearModal"
    >
      <div class="row">
        <div class="col-12 modal-th limitorder-TPSLB">
          <h5 style="margin-right: 0 !important">
            Order Confirmation
            <div class="h-info-c">
              <img src="@/assets/svg/question.svg" role="button">
              <span class="hover-info">Order Confirmation คือ การยืนยันคำสั่งซื้อ</span>
            </div>
          </h5>
          <span
            class="modal-close-button"
            @click="$bvModal.hide('market-order-sell-TPSLB')"
          >
            <img src="@/assets/svg/close.svg" role="button">
          </span>
        </div>
      </div>
    <form @submit.prevent="submit">
      <div class="row body order-confirm">
        <div class="col-12">
          <div class="row">
            <div class="col-4 m-0 p-0">
              <p style="margin-bottom: 20px">Coin</p>
            </div>
            <div class="col-8">
              <span>{{ coinName }}/{{ pairName }}</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4 m-0 p-0">
              <p style="margin-bottom: 10px">Order</p>
            </div>
            <div class="col-8"><span class="sell-badge">SELL</span></div>
          </div>
          <div class="row">
            <div class="col-4 m-0 p-0">
              <p style="margin-bottom: 8px">Price</p>
            </div>

            <div class="col-8">
              <span
                >{{ $MyFunc.NumberFormat(coinCurrentValue) }}
                {{ pairName }}&nbsp;(Market)</span
              >
            </div>
          </div>
          <div class="row" v-if="slTutMarket">
            <div class="col-4 m-0 p-0">
              <p style="margin-bottom: 8px">StopLoss</p>
            </div>
            <div class="col-8">
              {{ $MyFunc.NumberFormat(marketSlData.sl) }}
              <span v-if="this.marketSlData.slPercent > 0"
                >(-{{ marketSlData.slPercent }}%)</span
              ><span v-else>THB</span>
            </div>
          </div>
          <div class="row">
            <div class="col-4 m-0 p-0">
              <p style="margin-bottom: 8px">Amount</p>
            </div>
            <div class="col-8">
              <span
                >{{ $MyFunc.NumberFormat(market_selling_value) }}
                {{ pairName }}</span
              >
            </div>
          </div>
          <div class="row">
            <div class="col-4 m-0 p-0">
              <p style="margin-bottom: 8px">Total</p>
            </div>
            <div class="col-8">
              <span>
                {{ calculatedAllCoin }}
                {{ coinName }}
              </span>
            </div>
          </div>

          <div class="row">
            <div class="col-4"></div>
            <div class="col-8">
              <p style="margin-bottom: 16px"><small>*ราคาซื้อ/ขาย ไม่รวม Fee + Vat</small></p>
            </div>
          </div>

          <div class="row mb-3" v-if="!showPin">
            <div class="col-4 m-0 p-0" style="position: relative; top: 5px">
              <p class="c-title" style="margin-bottom: 16px">PIN</p>
            </div>
            <div class="col-8 position-relative">
              <span
                @click="isShowPin = !isShowPin"
                class="pin-toggle-icon"
              >
                <img v-if="isShowPin" src="@/assets/svg/eye-show.svg" role="button">
                <img v-else src="@/assets/svg/eye-hide.svg" role="button">
              </span>
              <input
                class="pin-input"
                required
                v-model="msPin"
                maxlength="6"
                v-mask="'######'"
                :type="isShowPin ? 'text' : 'password'"
              />
            </div>
          </div>
          
          <div class="form-group form-check" v-else>
            <label class="checkbox-input">
              <input type="checkbox" class="form-check-input" @click="FNorderConfirm" />
              <span style="color: #9bacb6">ไม่ต้องแจ้งเตือนอีก</span>
            </label>
          </div>
        
          <div class="row buttons-row" style="margin-top: 8px">
            <div class="col-6" style="padding-right: 8px">
              <button
                type="submit"
                class="yellow-btn text-center"
                :disabled="btn_disable"
              >
                ยืนยัน
              </button>
            </div>
            <div class="col-6" style="padding-left: 8px">
              <button
                type="button"
                class="grey-btn text-center"
                @click="SellMaketCancel()"
              >
                ยกเลิก
              </button>
            </div>
          </div>
        </div>
      </div>
            </form>
    </b-modal>
  </div>
</template>
<script>
import { mapMutations, mapActions } from "vuex"; 
import EventBus from "../../../event-bus";
export default {
  data() {
    return {
      msPin: null,
      isShowPin: false
    };
  },
  computed: {
    calculatedAllCoin() {
      let pValue = this.marketSlData.sellAmountPercentage ? Number(this.marketSlData.sellAmountPercentage) : Number(100);
      let calcPercent = (this.marketSell * pValue) / 100;
      let balance = parseFloat(this.marketSell - calcPercent);
      return  this.$MyFunc.mathFloorPrice(parseFloat(this.marketSell - balance),8);
    },
  },
  watch: {
    msPin(value) {
      this.$emit("msPin", value);
    },
  },
  props: [
    "coinName",
    "pairName",
    "coinCurrentValue",
    "slTutMarket",
    "marketSlData",
    "market_selling_value",
    "marketSell",
    "btn_disable",
    "showPin",
  ],
  methods: {
    clearPin(){
      this.msPin= null;
    },
    submit() {
      if(this.showPin){
        this.$emit("msPin", atob(this.showPin));
      }else{
        this.msPin = "";
      }
      this.$emit("submit", true);
    },
    SellMaketCancel() {
      this.$bvModal.hide('market-order-sell-TPSLB');
      this.msPin = "";
    },
    validateInput(){
      this.msPin = this.msPin.replace(/[^0-9]/g, "");
    },
    FNorderConfirm(e){
      this.setChkOrderConfirm(e.target.checked);
      this.updateMemberConfigOrderConfirm(e.target.checked);
    },
    clearModal(){
      this.msPin = null;
      this.isShowPin = false;
    },
    ...mapMutations(["setChkOrderConfirm"]),
    ...mapActions(["updateMemberConfigOrderConfirm"]),
  },
  mounted() {
    EventBus.$on('update-model-market-order-sell-tpslb', () => {
      this.clearPin();
    })
  },
};
</script>
<style scoped>
.h-info-c {
  position: relative;
  display: inline;
}
.h-info-c .hover-info {
  display: none;
  position: absolute;
  z-index: 9999;
  width: 240px;
  left: 130%;
  top: -30%;
  background-color: #d6dde1;
  border-radius: 4px;
  justify-content: center;
  align-items: center;
  font-family: "Sarabun";
  font-style: normal;
  color: #0a0d10;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.h-info-c:hover .hover-info {
  display: flex;
}
.row {
  font-family: "Sarabun";
}
.pin-toggle-icon {
    position: absolute;
    top: 50%;
    right: 100px;
    transform: translateY(-50%);
    cursor: pointer;
}
</style>