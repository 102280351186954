import { HTTP } from "../../config/axios";
export default {
  data() {
    return {
        Config:{
            COST_COIN_DEPOSIT: 0,
            NEWS_TYPE_ARTICLE: 0,
            NEWS_TYPE_PROMOTION: 0,
            DEPOSIT_WITHDRAW_BHAT_ALERT: 0,
            DEPOSIT_WITHDRAW_COIN_ALERT: 0,
            ORDER_MATCH_ALERT: 0,
            ORDER_CONFIRM_BEFORE_SUBMIT: 0,
            LOGGED_IN_NOTIFY: 0,
            DONT_NOTIFY_AGAIN: 0,
        },
        configResponse: null,
        articleChecked: false,
        promotionChecked: false,
        rdoCostDeposit: 0,
        SELECTED_COST_COIN_DEPOSIT: "",
        isLoading:true,
    };
  },
  methods: {
    async getMemberConfigs() {
        let self = this;
        HTTP.get("/uc/member/get-member-config")
        .then(function (response) {
          if (response.data.code == "0") {
            var Configs = response.data.data;
            Configs.forEach(item => {
                const configName = item.configName;
                const configValue = item.configValue;
                if (configName in self.Config) {
                    self.Config[configName] = parseInt(configValue);
                }
              });

            if(Configs.length < 1){
              self.Config.COST_COIN_DEPOSIT = 1;
            }

            self.articleChecked = (self.Config.NEWS_TYPE_ARTICLE == 1) ? true : false ;
            self.promotionChecked = (self.Config.NEWS_TYPE_PROMOTION == 1) ? true : false;
            self.rdoCostDeposit = self.Config.COST_COIN_DEPOSIT;
            self.SELECTED_COST_COIN_DEPOSIT = (self.rdoCostDeposit == 0) ? "ระบุต้นทุนด้วยตัวเอง" : "ระบุต้นทุนด้วยราคาตลาด";
            self.configResponse = response;
            self.isLoading = false
          }
          else
          {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Get Member Configs Successful"
                  : "Unable to Get Current Member Config",
              text: response.data.message,
            });
            self.isLoading = false
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Current Member Config",
            text: error,
          });
        });
    },
    updateMemberConfigs(Key,Value) {
        let self = this;
        HTTP.post("/uc/member/update-member-config",{
            name: Key,
            value: Value,
        })
        .then(function (response) {
          if (response.data.code == "0") {
            self.getMemberConfigs();
            if (Key == "NEWS_TYPE_PROMOTION" || Key == "NEWS_TYPE_ARTICLE" || Key == "COST_COIN_DEPOSIT")
            {
              self.ModalSuccess = true;
            }
            self.$bvModal.hide("pin-confirm-modal");
            self.isLoading = false
          }
          else
          {
            self.$notify({
              group: "notification",
              type: response.data.code == "0" ? "success" : "error",
              title:
                response.data.code == "0"
                  ? "Get Member Configs Successful"
                  : "Unable to Get Current Member Config",
              text: response.data.message,
            });
            self.isLoading = false
          }
        })
        .catch(function (error) {
          self.isLoadingPhone = false;
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Current Member Config",
            text: error,
          });
        });
    }
  },
};