<template>
  <div class="mpc wraper">
    <div
      class="container-fluid coin-nav justify-content-center align-items-center background-darkgrey"
    >
      <div class="container">
        <div
          class="d-flex justify-content-between align-items-center h-100 s-bar"
        >
          <div class="my-portfolio-header">
            <p class="font-18 light-grey">My Portfolio Value</p>
            <h4>{{ $MyFunc.NumberFormat(MyTotal) }} THB</h4>
          </div>
          <div class="my-portfolio-header">
            <p class="font-18 light-grey">Coin</p>
            <h4>{{ $MyFunc.NumberFormat(MyCoin) }} THB</h4>
          </div>
          <div class="my-portfolio-header">
            <p class="font-18 light-grey">Cash</p>
            <h4>{{ $MyFunc.NumberFormat(MyCash) }} THB</h4>
          </div>
          <div class="my-portfolio-header">
            <p class="font-18 light-grey">P/L Unrealized (Est.)</p>
            <h4 :class="MyUnrealized >= 0 ? 'greent-text' : 'red-text'">
              {{ $MyFunc.NumberFormat(MyUnrealized) }}
            </h4>
          </div>
          <div class="my-portfolio-header">
            <p class="font-18 light-grey">P/L Realized (Today)</p>
            <h4 :class="Myrealized >= 0 ? 'greent-text' : 'red-text'">
              {{ $MyFunc.NumberFormat(Myrealized) }}
            </h4>
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <section>
        <b-container
          class="pLR-24 bg-MarKetHeader d-flex justify-content-between border-bottom tab_bar"
          fluid
        >
          <div class="page-MarKetHeader">
            <b-navbar>
              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                  <div class="mx-auto">
                    <div class="menu-item">
                      <b-link
                        class="colormiddle"
                        @click="selectedtabs = 'MyCoin'"
                        :active="selectedtabs == 'MyCoin'"
                      >
                        My Port
                      </b-link>
                    </div>
                    <div v-if="!$store.state.hide_menu" class="menu-item">
                      <b-link
                        class="colormiddle"
                        @click="selectedtabs = 'CoinHeatmap'"
                        :active="selectedtabs == 'CoinHeatmap'"
                      >
                        Coin Heatmap
                      </b-link>
                    </div>
                  </div>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </div>
          <div class="checkbox d-flex align-items-center">
            <label>
              <input
                type="checkbox"
                class="form-check-input"
                v-model="ChkHiddenMinCoin"
                @change="FnHiddenMinCoin"
              />

              <span
                class="ms-2 mb-0 lh-24"
                style="
                  font-family: 'Sarabun';
                  font-style: normal;
                  font-weight: 400;
                  font-size: 14px;
                  cursor: pointer;
                "
              >
                ซ่อนเหรียญมูลค่าน้อย
              </span>
            </label>
          </div>
        </b-container>

        <div class="mcp wrapper" v-if="selectedtabs == 'MyCoin'">
          <div class="row chart_area">
            <div class="col-7 col-lg-6 col-xl-6">
              <div id="chart" class="custom-charts coin">
                <p class="text-position">Asset Allocation</p>
                <apexchart
                  type="donut"
                  width="400"
                  :options="chartOptions"
                  :series="series"
                  ref="chart"
                ></apexchart>
              </div>
            </div>
            <div class="col-1" style="line-height: 20px">
              <div class="chart-values">
                <div
                  v-for="(percentage, index) in tmp_percent"
                  :key="index"
                  class="d-flex justify-content-end"
                >
                  <p class="coinvalue">
                    {{ $MyFunc.NumberFormat(percentage) }}%
                  </p>
                </div>
              </div>
            </div>
            <div class="col-3" style="line-height: 20px">
              <div class="chart-values">
                <div
                  v-for="(value, index) in coinsData6"
                  :key="index"
                  class="d-flex justify-content-end"
                >
                  <p class="coinvalue">
                    {{ $MyFunc.NumberFormat(value, 2) }}
                  </p>
                  <p class="ms-2 coinshortform">THB</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mcp wrapper" v-if="selectedtabs == 'CoinHeatmap'">
          <div class="row">
            <div id="chart2" style="width: 100%; margin: auto">
              <div class="tab_bar">
                <p class="text-positions d-flex justify-content-start tab_bar">
                  My Coin's Profit / Loss
                </p>
              </div>
              <div v-if="isLoading == true" class="loader-wrapper">
                <div class="loader"></div>
              </div>
              <div v-else>
                <apexchart2
                  type="treemap"
                  name="chart-container"
                  height="400"
                  :options="chart_heatmap"
                  :series="series_heatmap"
                  ref="chart2"
                  class="tab_bar"
                ></apexchart2>
              </div>
            </div>
          </div>
        </div>

        <div class="mcp wrapper" v-if="selectedtabs == 'MyCoin'">
          <div class="row table">
            <div class="col mb-5 p-0">
              <Dynamic-Table
                :TableHeader="TableHeading"
                :TableData="coinsDataTable"
                :mycoins="true"
              ></Dynamic-Table>
            </div>
          </div>
        </div>

        <div class="mcp wrapper" v-if="selectedtabs == 'CoinHeatmap'">
          <div class="row table">
            <div class="col mb-5 p-0">
              <Dynamic-Table
                :TableHeader="TableHeading"
                :TableData="coinsData2Table"
                :mycoins="true"
              ></Dynamic-Table>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>
</template>

<script>
import VueApexCharts from "vue-apexcharts";
import { mapMutations } from "vuex";
import { HTTP } from "@/config/axios";
import Table from "@/pages/portfolio/table/Table.vue";
export default {
  name: "MyPortfolio",
  components: {
    apexchart: VueApexCharts,
    apexchart2: VueApexCharts,
    "Dynamic-Table": Table,
  },
  data() {
    return {
      isLoading: false,
      ChkHiddenMinCoin: this.$store.state.ChkHiddenMinCoin,
      selectedtabs: "MyCoin",
      MyTotal: 0,
      MyCoin: 0,
      MyCash: 0,
      MyUnrealized: 0,
      Myrealized: 0,
      coinsData: [],
      coinsDataTable: [],
      coinsData2: [],
      coinsData2Table: [],
      coinsData6: [],
      tmp_percent: [],
      series_heatmap: [
        {
          data: [
            {
              x: "INTC",
              y: 1.2,
            },
            {
              x: "GS",
              y: 0.4,
            },
            {
              x: "CVX",
              y: -1.4,
            },
            {
              x: "GE",
              y: 2.7,
            },
            {
              x: "CAT",
              y: -0.3,
            },
            {
              x: "RTX",
              y: 5.1,
            },
            {
              x: "CSCO",
              y: -2.3,
            },
            {
              x: "JNJ",
              y: 2.1,
            },
            {
              x: "PG",
              y: 0.3,
            },
            {
              x: "TRV",
              y: 0.12,
            },
            {
              x: "MMM",
              y: -2.31,
            },
            {
              x: "NKE",
              y: 3.98,
            },
            {
              x: "IYT",
              y: 1.67,
            },
          ],
        },
      ],
      series: [],
      series2: [
        {
          data: [
            {
              x: "INTC",
              y: 1.2,
            },
            {
              x: "GS",
              y: 0.4,
            },
            {
              x: "CVX",
              y: -1.4,
            },
            {
              x: "GE",
              y: 2.7,
            },
            {
              x: "CAT",
              y: -0.3,
            },
            {
              x: "RTX",
              y: 5.1,
            },
            {
              x: "CSCO",
              y: -2.3,
            },
            {
              x: "JNJ",
              y: 2.1,
            },
            {
              x: "PG",
              y: 0.3,
            },
            {
              x: "TRV",
              y: 0.12,
            },
            {
              x: "MMM",
              y: -2.31,
            },
            {
              x: "NKE",
              y: 3.98,
            },
            {
              x: "IYT",
              y: 1.67,
            },
          ],
        },
      ],
      chartOptionsDefault: {},
      chartOptions: {
        title: {
          text: "",
          align: "right",
          style: {
            fontSize: "16px",
            color: "#c2c9cd",
          },
        },
        dataLabels: {
          enabled: false,
          formatter: function (value, { seriesIndex, dataPointIndex, w }) {
            let total = 0;
            let scale = 10 ** 2;
            w.config.series.forEach((item, index) => {
              total += item;
            });
            return (
              (
                Math.floor(
                  Math.trunc(
                    (w.config.series[seriesIndex] / total) * 100 * scale
                  )
                ) / scale
              ).toFixed(2) + "%"
            );
          },
        },
        labels: [],
        colors: [
          "#0093bd",
          "#df9db2",
          "#e5a946",
          "#44a8b1",
          "#939598",
        ],
        stroke: {
          show: false,
        },
        tooltip: {
          enabled: true, // เปลี่ยนเป็น true เพื่อเปิดใช้งาน tooltip
          x: {
            show: false, // ซ่อนตำแหน่ง X
          },
          y: {
            formatter: undefined, // เอาค่า Y ออก
          },
          marker: {
            show: false, // ซ่อน marker
          },
          items: {
            display: "flex", // แสดงข้อมูลในรูปแบบ Flexbox
            flexDirection: "column", // เรียงข้อมูลเป็นคอลัมน์
          },
          fixed: {
            enabled: true, // ให้ tooltip ติดตามเม้าส์
            position: "right", // ตำแหน่งของ tooltip ที่จะแสดง
          },
        },
        responsive: [
          {
            breakpoint: 480,
            options: {
              chart: {
                width: 200,
              },
              legend: {
                position: "bottom",
              },
            },
          },
        ],
        legend: {
          position: "right",
          offsetY: 20,
          height: 230,
          fontSize: "16px",
          labels: {
            colors: "rgb(255, 255, 255)",
            useSeriesColors: false,
          },
          itemMargin: {
            horizontal: 0,
            vertical: 8,
          },
        },
      },
      chartOptionsDefault2: {
        legend: {
          show: false,
        },
        chart: {
          height: 350,
          type: "treemap",
        },
        // title: {
        //   text: 'Treemap with Color scale'
        // },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
          },
          formatter: function (text, op) {
            return [text, op.value];
          },
          offsetY: -4,
        },
        plotOptions: {
          treemap: {
            enableShades: false,
            shadeIntensity: 0.5,
            reverseNegativeShade: false,
            colorScale: {
              ranges: [
                {
                  from: -6,
                  to: 0,
                  color: "#f86b7d",
                },
                {
                  from: 0.001,
                  to: 6,
                  color: "#0cad6e",
                },
              ],
            },
          },
        },
      },
      chartOptions2: {
        legend: {
          show: false,
        },
        chart: {
          height: 350,
          type: "treemap",
        },
        // title: {
        //   text: 'Treemap with Color scale'
        // },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
          },
          formatter: function (text, op) {
            return [text, op.value];
          },
          offsetY: -4,
        },
        plotOptions: {
          treemap: {
            enableShades: false,
            shadeIntensity: 0.5,
            reverseNegativeShade: false,
            colorScale: {
              ranges: [
                {
                  from: -6,
                  to: 0,
                  color: "#f86b7d",
                },
                {
                  from: 0.001,
                  to: 6,
                  color: "#0cad6e",
                },
              ],
            },
          },
        },
      },
      TableHeading: [
        {
          headingCoin: "Coin ",
          Unrealized: "Unrealized (Est.)",
          Actual: "Actual",
          AvrgCost: "Avg. Cost",
          lastPrice: "Last Price",
          Total: "Total (THB)",
          realized: "Realized (Today)",
        },
      ],
    };
  },
  created() {
    this.chartOptionsDefault = this.chartOptions;
    // this.chartOptionsDefault2 = this.chartOptions2;
    this.getDefaultWallet();
  },
  computed: {},
  mounted() {},
  computed: {
    chart_heatmap() {
      this.isLoading = true;
      var chartOptions = {
        legend: {
          show: false,
        },
        chart: {
          toolbar: {
            show: false, // Set to false to hide the toolbar
          },
          height: 350,
          type: "treemap",
        },
        stroke: {
          show: true,
          curve: "straight",
          lineCap: "butt",
          colors: ["#141b1f"],
          width: 1,
          dashArray: 0,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "20px",
          },
          formatter: function (text, op) {
            var t = "B " + op.value;
            t=''
            return [text, t];
          },
          // formatter: function (val) {
          //   return val + "%";
          // },
          offsetY: 0,
        },
        plotOptions: {
          treemap: {
            distributed: false,
            enableShades: false,
            shadeIntensity: 0.5,
            reverseNegativeShade: false,
            inverse: false,
            dataLabels: {
              format: "scale",
            },

            colorScale: {
              ranges: [
                {
                  from: -10000000,
                  to: 0,
                  color: "#f86b7d",
                },
                {
                  from: 0.001,
                  to: 500000000,
                  color: "#0cad6e",
                },
              ],
            },
          },
        },
      };
      this.isLoading = false;
      return chartOptions;
    },
  },
  destroyed() {},
  methods: {
    ...mapMutations(["setPortfolioData"]),
    ...mapMutations(["setHiddenMinCoin"]),
    FnHiddenMinCoin() {
      this.isLoading = true
      this.setHiddenMinCoin(this.ChkHiddenMinCoin);
      this.getDefaultWallet();
      this.isLoading = false
    },

    getDefaultWallet() {
      this.isloading = true;
      let self = this;
      HTTP.post("/uc/asset/my-port/").then((res) => {
        if (res.data.code == 4000) {
          this.$store.dispatch("logout");
          this.$router.push({ name: "login" });
        }
        if (res.data.code == 0) {
          let coinsData = [];
          let coinsDataTable = [];
          let coinsData2 = [];
          let coinsData2Table = [];
          // let dataWallet = res.data.data.myPort.sort(
          //   (a, b) => (b.balance * (b.coin.name == "THB" ? 1 : b.averagePrice)) - (a.balance * (a.coin.name == "THB" ? 1 : a.averagePrice))
          // );
          let dataWallet = res.data.data.myPort.sort(
            (a, b) => b.total - a.total
          );
          //console.log("my-port=",dataWallet);
          dataWallet.forEach((item) => {
            if (self.$MyFunc.FnChkHiddenMinCoin(item, self.ChkHiddenMinCoin)) {
              coinsData.push(item);
              coinsData2.push(item);
              if (item.coin.name != "THB") {
                if (
                  item.actual > 0 ||
                  item.total > 0 ||
                  item.frozenBalance > 0 ||
                  item.realized != 0
                ) {
                  coinsDataTable.push(item);
                  coinsData2Table.push(item);
                }
              }
            }
          });
          self.MyCoin = res.data.data.myPortCoin;
          self.MyCash = res.data.data.myPortCash;
          self.MyTotal = res.data.data.myPortValue;
          self.Myrealized = res.data.data.realized;
          self.MyUnrealized = res.data.data.unRealized;
          self.coinsData = coinsData;
          self.coinsDataTable = coinsDataTable.sort((a, b) => {
            return a.coin.name.localeCompare(b.coin.name);
          });
          self.coinsData2 = coinsData2;
          self.coinsData2Table = coinsData2Table.sort((a, b) => {
            return a.coin.name.localeCompare(b.coin.name);
          });

          self.updateChart();
        }
      });
    },
    updateChart() {
      this.chartOptions = this.chartOptionsDefault;
      this.chartOptions2 = this.chartOptionsDefault2;
      let series = [];
      let series2 = [];
      let labels = [];
      let colors = [];
      let HeatmapData = [];
      let chkListHeatmap = false;
      let doNut6 = this.coinsData.length;
      let other = 0;
      let other6 = 0;
      let donutlist = [];
      this.series_heatmap[0].data = [];
      // series_heatmap: [
      //   {
      //     data: [
      //       {
      //         x: "INTC",
      //         y: 1.2,
      //       },
      //     ]
      //   }
      // ],

      this.coinsData.forEach((item, index) => {
        if (index >= 4 && doNut6 > 5) {
          if (item.total > 0) {
            other += item.total;
            other6 += item.total;
          }
        } else {
          if (item.total > 0) {
            var total = Number(this.$MyFunc.mathFloorPrice(item.total, 2));
            donutlist.push(total);
            series.push(total);
            labels.push(item.coin.name);
          }
        }
      });
      if (other > 0) {
        donutlist.push(other6);
        series.push(other);
        labels.push("Other");
      }
      this.coinsData6 = donutlist;
      //console.log("donutlist=", this.coinsData2);
      this.coinsData2.forEach((item, index) => {
        if (item.coin.name != "THB") {
          this.series_heatmap[0].data.push({
            x: item.coin.name,
            y: item.unrealized,
          });
          if (item.realized >= 0) {
            colors.push("#12D212");
          } else {
            colors.push("#D32828");
          }
          series2.push({ x: item.coin.name, y: item.total });
          chkListHeatmap = true;
        }
      });
      if (colors.length > 0) {
        this.chartOptions2.colors = colors;
      }
      this.series = series;
      let tmp = 0;
      let tmp2 = [];
      if (series.length > 0) {
        for (let i = 0; i < series.length; i++) {
          tmp += series[i];
        }
      }
      tmp = Number(this.$MyFunc.mathFloorPrice(tmp, 2));
      if (series.length > 0) {
        for (let i = 0; i < series.length; i++) {
          let percentage = this.$MyFunc.mathFloorPrice(
            (series[i] / tmp) * 100,
            2
          );
          tmp2.push(percentage);
        }
      }
      let sum = 0;
      for (let i = 0; i < tmp2.length; i++) {
        sum += Number(tmp2[i]);
      }
      this.tmp_percent = tmp2;
      // console.log()
      this.chartOptions.labels = labels;
      if (chkListHeatmap) {
        HeatmapData.push({ data: series2 });
        this.series2 = HeatmapData;
      }
      if (this.selectedtabs == "MyCoin") {
        this.$refs.chart.updateOptions(this.chartOptions);
      } else {
        // this.$refs.chart2.updateOptions(this.chartOptions2);
      }
    },
  },
};
</script>

<style scoped lang="scss">
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.m-close {
  position: absolute;
  right: 28px;
  top: 30px;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-dialog {
  background-color: #141b1f;
  width: 360px;
  height: 305px;
  border-radius: 8px;
}

.mpc {
  .s-bar {
    max-width: 960px;
    margin: auto;
    padding-top: 16px;
  }

  .tab_bar {
    max-width: 933px;
    margin: auto;
  }

  .my-portfolio-header {
    margin-right: 45px;
  }

  .my-portfolio-header:last-child {
    margin-right: 0;
  }

  .my-portfolio-header h3 {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    margin: 0px;
  }

  .right {
    margin-right: 60px;
  }

  .my-portfolio-header h4 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    margin: 0px;
  }

  .background-darkgrey {
    background-color: #121516;
  }

  .light-grey {
    color: #677f8e;
    font-size: 14px;
    font-family: "Roboto Flex";
  }

  .greent-text {
    color: #0c9;
    font-size: 16px;
    font-family: "Roboto Flex";
  }

  .red-text {
    color: #df3348;
    font-size: 16px;
    font-family: "Roboto Flex";
  }

  .font-18 {
    font-size: 14px;
    font-family: "Roboto Flex";
    margin-bottom: 0px;
    line-height: 24px;
    font-weight: 400;
  }

  .font-14 {
    font-size: 18px;
    font-family: "Roboto Flex";
  }

  .checkbox {
    margin-bottom: 12px;

    input {
      background: #191b1c;
      border: 1px solid #3c515d;
      border-radius: 2px;
      height: 17px;
      width: 17px;
    }

    input:checked {
      background-color: #09BE8B;
      border: 1px solid #3c515d;
    }
  }

  .custom-checkbox {
    background: #191b1c;
    border: 1px solid #3c515d;
    border-radius: 2px;
    height: 17px;
    width: 17px;
  }

  .border-bottom {
    border-bottom: 1px solid #28363e !important;
  }

  .pLR-24 {
    padding-left: 0px !important;
    padding-right: 0px !important;
  }

  .page-MarKetHeader {
    font-family: var(--bs-roboto-font-sans-serif);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
  }

  .bg-MarKetHeader {
    margin-top: 45px;
  }

  .mx-auto {
    display: flex;
    gap: 0px !important;
  }

  .colormiddle {
    text-decoration: none;
    color: #677f8e !important;
  }

  .menu-item {
    margin-right: 36px !important;
    position: relative !important;
    font-family: "Roboto Flex";
    font-weight: 400 !important;
    font-size: 16px !important;
    line-height: 19px !important;

    a {
      font-weight: 400;
    }
  }

  .mx-auto .colormiddle {
    padding: 0px 0px 12px;
    text-align: center;
    border-bottom: 2px solid transparent;
    display: flex;
    transition: 0.4s;
    cursor: pointer !important;
  }

  .mx-auto .colormiddle.active,
  .mx-auto .colormiddle:hover {
    border-bottom-color: #09BE8B !important;
    text-align: center;
    color: var(--primary) !important;
  }

  .field-container {
    position: relative;
    padding: 0;
    margin: 0;
    border: 0;
    width: 219px;
    height: 40px;
  }

  .icons-container {
    position: absolute;
    top: 8px;
    left: 8px;
    width: 35px;
    height: 35px;
    overflow: hidden;
  }

  .icon-search:after {
    content: url("@/assets/images/iconArrow/icon-search.png") !important;
  }

  input::placeholder {
    color: var(--sub-2) !important;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
  }

  .field {
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: var(--primary);
    border: 0;
    width: 219px;
    height: 40px;
    padding: 8px 40px;
    background-color: var(--ui-layer-0);
    border-radius: 4px;
    box-shadow: 0px 8px 15px rgba(75, 72, 72, 0.1);
    transition: all 0.4s ease;
  }

  .field:focus {
    outline: none;
    box-shadow: 0px 9px 20px rgba(75, 72, 72, 0.3);
  }

  .coin-nav {
    height: 80px;
  }
}

@media (max-width: 992px) {
  .my-portfolio-header h4 {
    font-size: 15px;
  }

  .container {
    max-width: 100% !important;
  }
}

@media (max-width: 880px) {
  .my-portfolio-header {
    margin-right: 17px;
  }
}

.coin .apexcharts-canvas::before {
  content: "Asset Allocation";
  position: absolute;
  right: 17px;
  top: 13px;
  color: #677f8e;
  font-weight: 400 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}

.coin .apexcharts-legend-marker {
  width: 14px;
  height: 14px;
}

.coin .apexcharts-legend-text {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: white !important;
  margin-left: 16px !important;
  font-family: "Roboto Flex" !important;
}

.coin .apexcharts-canvas {
  line-height: 24px;
}

.coin .apexcharts-legend-series {
  margin: 6px 0 !important;
}

.coin #SvgjsSvg1006 {
  overflow: visible;
}

.coin .apexcharts-inner {
  /* transform: translateY(-9px); */
}
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 43, 47, 0.7);
  /* สีดำที่โปร่งแสง 70% */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* สร้าง loader ที่หมุน */
.loader {
  border: 4px solid rgba(255, 255, 255, 0.4);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}
.coin .apexcharts-legend {
  top: 44px !important;
}

.mcp .table th > div {
  padding: 0px;
}

.mcp .table td {
  height: 56px;
  line-height: 56px;
  padding: 0px;
}

.mcp {
}

.table {
  max-width: 1200px;
  margin: auto;
}

.chart_area {
  max-width: 875px;
  margin: auto;
  transform: translateX(10%);
}

.wrapper {
  margin-top: 40px;
}

.light-grey {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-family: "Roboto Flex";
}

.coinvalue {
  color: rgb(255, 255, 255);
  font-size: 16px;
  font-family: "Roboto Flex";
  font-weight: 400;
}

.coinshortform {
  color: #677f8e;
  font-size: 16px;
  font-family: "Roboto Flex";
  font-weight: 400;
}

.chart-values {
  position: relative;
  top: 44px;
  width: fit-content;
}

.chart-values p {
  margin-bottom: 9px;
  margin-top: 5px;
}

.custom-checkbox {
  background: #191b1c;
  border: 1px solid #3c515d;
  border-radius: 2px;
  height: 17px;
  width: 17px;
}

.Get-started {
  margin: 20px;
}

.custom-charts {
  position: relative;
  margin-left: -35px;
}

.coin-allocation {
  position: absolute;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #677f8e;
  top: 14px;
  right: calc(1.45rem + 1px);
}

.text-position {
  position: absolute;
  font-size: 14px;
  font-weight: 400px;
  color: #677f8e;
  left: 340px;
  top: 18.5px;
}

.text-positions {
  position: absolute;
  font-family: "Roboto Flex";
  opacity: 1;
  font-weight: 500;
  color: rgb(255, 255, 255);
  font-size: 14px;
  top: 80px;
}
</style>
