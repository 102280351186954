<template>
  <div class="padding-table scroller">
    <table class="table ma-3 table-by-date" id="myTable">
      <thead v-for="(item, index) in TableHeader" :key="index">
        <!-- Heading Date -->
        <th scope="col" class="textGreyDark">
          <div class="d-flex p-0" style="gap: 6px;">
            <p class="align-Icon-Heading mb-0">
              {{ item.Date }}
            </p>
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('datetime')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downDateTimeDate"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upDateTimeDate"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </div>
        </th>
        <!-- End Heading Date -->

        <!-- Heading FreeVAT -->
        <th scope="col" class="textGreyDark">
          <p class="align-Icon-Heading mb-0 p-0 text-right" style="gap: 6px;">
            {{ item.FreeVAT }}
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('feeVat')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downFeeVatDate"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upFeeVatDate"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </p>
        </th>
        <!-- End Heading FreeVAT -->

        <!-- Heading TotalBuy -->
        <th scope="col" class="textGreyDark">
          <p class="align-Icon-Heading mb-0 p-0 text-right" style="gap: 6px;">
            {{ item.TotalBuy }}
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('totalBuy')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downTotalBuy"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upTotalBuy"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </p>
        </th>
        <!-- End Heading TotalBuy -->

        <!-- Heading TotalSell -->
        <th scope="col" class="textGreyDark">
          <p class="align-Icon-Heading mb-0 p-0 text-right" style="gap: 6px;">
            {{ item.TotalSell }}
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('totalSell')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downTotalSell"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upTotalSell"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </p>
        </th>
        <!-- End Heading TotalSell -->

        <!-- Heading ProfitLoss -->
        <th
          scope="col"
          class="textGreyDark"
          style="width: 50%; padding-right: 155px"
        >
          <p class="align-Icon-Heading mb-0 p-0 text-right" style="gap: 6px;">
            {{ item.ProfitLoss }}
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('pl')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downPlDate"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upPlDate"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </p>
        </th>
        <!-- End Heading ProfitLoss -->
      </thead>
      <tbody>
        <tr class="textDarkgrey-Border" v-if="filteredItems?.length == 0">
          <td colspan="5" class="textGrey text-center">-- ไม่พบรายการ --</td>
        </tr>
        <tr
          class="textDarkgrey-Border"
          v-for="(item, index) in filteredItems"
          :key="index"
        >
          <!-- {{ index }}: {{ item.lastPrice }} -->

          <!-- Datetime -->
          <td style="width: 110px">
            <p class="Datetime-one textGrey mb-0">
              <!-- {{ item.Date }} -->
              {{ item.datetime }}
            </p>
          </td>
          <!-- End Datetime -->

          <!-- last FreeVAT -->
          <td>
            <p class="mb-0 textGrey text-right">{{  $MyFunc.NumberFormat(item.feeVat, 2) }}</p>
          </td>
          <!-- End FreeVAT -->

          <!-- TotalBuy -->
          <td class="textGrey" style="width: 125px">
            <p class="mb-0 text-right">{{ $MyFunc.NumberFormat(item.totalBuy, 2) }}</p>
          </td>
          <!-- End TotalBuy -->

          <!-- TotalSellValue -->
          <td>
            <p class="mb-0 textGrey text-right">{{  $MyFunc.NumberFormat(item.totalSell, 2) }}</p>
          </td>
          <!-- End TotalSellValue -->

          <!-- profitloss -->
          <td class="text-right" style="padding-right: 155px">
            <span
              class="Coin-Symbol profitloss-one mb-0"
              :class="item.pl < '0' ? 'textRed' : item.pl > '0' ? 'textGreen' : 'textwhite'"
            >
              {{ item.pl != '0' ? $MyFunc.priceFormat($MyFunc.mathFloorPrice(item.pl, 2)) + " (" + $MyFunc.priceFormat($MyFunc.calPersent(item.totalSell, item.pl)) + "%)" : '0.00 (0.00%)' }}
            </span>
            <span class="textGrey profitloss-one mb-0" style="color: #0c9">
              {{ item.ProfitLossPercentage}}
            </span>
          </td>
          <!-- end profitloss -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "tableBydetail",
  props: ["TableHeader", "TableData","filteredItems"
          ,"upDateTimeDate"
          ,"downDateTimeDate"
          ,"upTotalBuy"
          ,"downTotalBuy"
          ,"upTotalSell"
          ,"downTotalSell"
          ,"upFeeVatDate"
          ,"downFeeVatDate"
          ,"upPlDate"
          ,"downPlDate"
  ],
  data() {
    return {
      // child_msg: "message from child"
      child_msg: true,
      pageFields: "",
      pageSorts: "desc",
    };
  },

  methods: {
    childMethod() {
      this.$emit("child-method", this.child_msg);
    },
    handleBuySell() {
      this.$router.push("/exchange");
    },
    handleDetail() {
      this.$router.push("/coin-detail");
    },
    getHistorys(secField = "datetime"){
      if(secField == this.pageFields){
        if(this.pageSorts == "asc"){
          this.pageSorts = "desc";
        }else{
          this.pageSorts = "asc";
        }
      }else{
        this.pageFields = secField;
        this.pageSorts = "desc";
      }
      this.$emit('getHistoryDate', { pageSort: this.pageSorts, pageField:  this.pageFields });
    }
  },
};
</script>
<style lang="scss" scoped>
.table-by-date {
  tbody tr td {
    padding-top: 12px !important;
    padding-bottom: 12px !important;
    padding: 0;
    line-height: 24px;
  }
  thead th {
    padding-bottom: 9px;
  }
}
.textGreen {
  color: #0c9;
  font-size: 14px !important; 
  font-weight: 400 !important;
  // text-align: center;
  // vertical-align: middle;
}
.textRed {
  color: #de2d40;
  font-size: 14px !important; 
  font-weight: 400 !important;
  // text-align: center;
  // vertical-align: middle;
}
.textYellow {
  color: #09BE8B;
  cursor: pointer;
}
.textYellow:hover {
  color: #d6dde1;
}
.textwhite{
  color: #d6dde1;
  font-size: 14px !important; 
  font-weight: 400 !important;
}
.dateChevron {
  margin-left: 50px;
  top: 3px;
  position: relative;
}

.textGrey {
  color: rgb(255, 255, 255);
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  // vertical-align: middle;
}
.textGreyDark {
  color: #677f8e;
  line-height: 14px;

  // vertical-align: middle;
}
.textGreyDark-table {
  color: #677f8e;
  // vertical-align: sub;
  font-size: 14px;
  // font-family: "Roboto Flex";
  font-weight: 400;
  line-height: 24px;
}
.textGreyDark-description {
  color: #677f8e;
  // vertical-align: middle;
  font-family: "Sarabun";
}
.textDarkgrey-Border {
  border-color: #28363e;
}

.align-Icon-Heading {
  font-size: 12px;
  font-weight: 400;
  color: #677f8e;
  font-family: "Roboto Flex";
}

.Coin-Symbol {
  font-size: 14px;
  font-weight: 600;
  line-height: 24px;
}
.question-circle {
  position: relative;
  margin-top: -3px;
  left: 8px;
  cursor: pointer;
}

// %%%  scroller  start %%%%

.scroller {
  min-height: 200px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #192126;
}
</style>
