<template>
    <div>
        <b-modal v-model="isShow" hide-footer>

            <template #modal-header="{ close }">
                <span class="fs-5 fw-semibold font">Review KYC</span>
                <b-button variant="danger" @click="close()"><i class="fa fa-times"></i></b-button>
            </template>

            <div class="container font tex-white">
                <div class="row my-4 text-white">
                    <div v-if="kycReviewStatus == 2">
                        <p class="text-white mb-0">การทบทวน KYC ของท่าน ยังไม่สมบูรณ์ กรุณาตรวจสอบรายการและดำเนินการให้แล้วเสร็จ</p>
                        <p class="text-danger mb-0 mt-2">{{ kycReviewReason ? kycReviewReason : 'โปรดกรอกข้อมูล KYC ให้ครบถ้วน' }}</p>
                    </div>
                    <div v-else class="text-center">
                        <p class="text-white mb-0">กรุณาดำเนินการทบทวนข้อมูล KYC ก่อนวันที่ 30/07/2024</p>
                        <p class="text-white mb-0">หากเลยเวลาดังกล่าวจะไม่สามารถทำรายการซื้อได้</p>
                    </div>
                </div>
                
                <div class="d-grid d-flex justify-content-around">
                    <button type="button" class="btn btn-primary" @click="continueModal()">ดำเนินการ</button>
                    <button type="button" class="btn b-g" @click="closeModal()">ยังไม่ดำเนินการ</button>
                </div>
            </div>

        </b-modal>
    </div>
</template>
  
<script>
import EventBus from '@/event-bus';
export default {
    data() {
        return {
            isShow: false,
            kycReviewStatus: null,
            kycReviewExpire: null,
            kycReviewReason: null,
        };
    },
    mounted() {
        EventBus.$on('openModal', this.openModal);
    },
    methods: {
        formatDate(inputDate) {
            const datePattern = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}$/;
            if (datePattern.test(inputDate)) {
                const parts = inputDate.split(' ');
                const dateParts = parts[0].split('-');
                const formattedDate = `${dateParts[2]}/${dateParts[1]}/${dateParts[0]}`;
                return formattedDate;
            } else {
                return "Invalid date information";
            }
        },
        openModal() {
            const data = JSON.parse(localStorage.getItem("resMember") || "{}");
            if (data) {
                this.kycReviewStatus = localStorage.getItem("kycReviewStatus");
                const requireKycReview = data?.resMember.requireKycReview;
                const kycReviewExpire = data?.resMember.kycReviewExpire;
                const nextReviewDate = data?.resMember.nextReviewDate;
                this.kycReviewReason = JSON.parse(localStorage.getItem("kycReviewReason"));
                
                if (requireKycReview && this.kycReviewStatus != 0) {
                    if(kycReviewExpire){
                        this.kycReviewExpire = kycReviewExpire;
                    }else{
                        this.kycReviewExpire = nextReviewDate;
                    }
                    this.isShow = true;
                }
            }
        },
        continueModal() {
            this.$router.push({
                name: 'ReviewKYC',
            })
        },
        closeModal() {
            this.isShow = false;
        },
    }
};
</script>
  
<style scoped>
.font {
    font-family: "Sarabun";
}
button.b-g {
    background-color: #191b1c;
    color: #ffffff;
}
</style>