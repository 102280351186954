<template>
  <div class="buy-sell-page">
    <div class="container-fluid buy-sell mb-3 p-0">
      <div class="row p-0">
        <div class="col-12 col-lg-9 col-xl-9">
          <div class="row">
            <!-- here -->
            <div class="col-12">
              <div class="row top-search-row">
                <div
                  class="col-12 col-md-4 col-lg-4 col-xl-3"
                  style="padding-left: 20px; !important; width: 250px;"
                >
                  <div class="position-relative search-container">
                    <div>
                      <span class="search-svg">
                        <svg
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M12.5 11H11.71L11.43 10.73C12.41 9.59 13 8.11 13 6.5C13 2.91 10.09 0 6.5 0C2.91 0 0 2.91 0 6.5C0 10.09 2.91 13 6.5 13C8.11 13 9.59 12.41 10.73 11.43L11 11.71V12.5L16 17.49L17.49 16L12.5 11ZM6.5 11C4.01 11 2 8.99 2 6.5C2 4.01 4.01 2 6.5 2C8.99 2 11 4.01 11 6.5C11 8.99 8.99 11 6.5 11Z"
                            fill="#677F8E"
                          />
                        </svg>
                      </span>
                      <span v-if="!showSearchBox" class="coin-image">
                        <img
                          v-if="filteredCoinsImages?.logoFileId"
                          :src="filteredCoinsImages.logoFileId"
                          width="24"
                        />
                      </span>
                      <div
                        ref="coinDropdown"
                        class="td"
                        tabindex="0"
                        @focusin="
                          showSearchBox = true;
                          showBtcText = false;
                        "
                        @blur="showSearchBox = false"
                      >
                        <input
                          v-model="coinSearchText"
                          class="ml-4 coins-search-input text-uppercase btc-text-true"
                          :class="
                            showBtcText ? 'btc-text-true' : 'btc-text-false'
                          "
                          type="text"
                          :placeholder="showBtcText ? coinName + ' / THB' : ''"
                          maxlength="10"
                          @blur="setCoinText()"
                          @keydown.enter="handleEnterKey"
                        />
                        <div v-if="showSearchBox" class="searching-box">
                          <p class="search-title">ค้นหา</p>
                          <div
                            @click="
                              showBtcText = true;
                              coinSearchText = '';
                            "
                          >
                            <div
                              class="row"
                              v-for="(item, index) in filteredCoins"
                              :key="index"
                              @click="selectMarketSymbol(item.url.toString())"
                            >
                              <div class="col-2" style="width: 12%">
                                <img
                                  v-if="item.logoFileId"
                                  :src="item.logoFileId"
                                  width="24"
                                  style="margin-left: 7px"
                                />
                              </div>
                              <div class="col-5" style="width: 44%">
                                <p class="coin-text">
                                  {{ item.symbol.split("/")[0]
                                  }}<span class="pair-text"
                                    >/ {{ item.symbol.split("/")[1] }}</span
                                  >
                                </p>
                                <p class="vol">
                                  Vol.
                                  {{
                                    $MyFunc.NumberFormat(
                                      item.volume,
                                      currentScale.fixScale
                                    )
                                  }}M
                                </p>
                              </div>
                              <div class="col-5 text-right" style="width: 44%">
                                <p class="price">
                                  {{
                                    $MyFunc.NumberFormat(
                                      item.price,
                                      item.bathScale
                                    )
                                  }}
                                </p>
                                <p :class="item.color + ' percent'">
                                  {{ $MyFunc.PercentChg(item.pChg) }}%
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div
                  id="maintrade"
                  class="col-12 col-md-8 col-lg-8 col-xl-9 my-auto trading-scores"
                >
                  <span
                    class="live-amount-title red"
                    :class="priceInfo.textColor"
                  >
                    {{
                      $MyFunc.NumberFormat(
                        priceInfo.last,
                        currentScale.bathScale
                      )
                    }}
                  </span>
                  <span
                    class="live-amount-value red"
                    :class="priceInfo.textColor"
                    >({{ $MyFunc.PercentChg(priceInfo.pChg) }}%)</span
                  >
                  <span class="title">24h High:</span>
                  <span class="value">{{
                    $MyFunc.NumberFormat(
                      priceInfo.high24,
                      currentScale.bathScale
                    )
                  }}</span>
                  <span class="title">24h Low:</span>
                  <span class="value">{{
                    $MyFunc.NumberFormat(
                      priceInfo.low24,
                      currentScale.bathScale
                    )
                  }}</span>
                  <span class="title">24h Volume:</span>
                  <span class="value">{{
                    $MyFunc.NumberFormat(priceInfo.vol24, currentScale.fixScale)
                  }}</span>
                </div>
              </div>
            </div>
            <div class="col-12 col-lg-4 col-xl-4 bs-left-table">
              <div
                class="bid-offer-container d-flex justify-content-between align-center"
              >
                <div class="d-flex justify-content-start align-center">
                  <div
                    class="select"
                    tabindex="0"
                    @click="open_dropdown_coin = !open_dropdown_coin"
                    @blur="open_dropdown_coin = false"
                  >
                    <span v-if="bidOffer == 99" class="text">All</span>
                    <span v-if="bidOffer == 0" class="text">Bid</span>
                    <span v-if="bidOffer == 1" class="text">Offer</span>
                    <span
                      :class="
                        open_dropdown_coin ? 'icon rotate-sc-icon' : 'icon'
                      "
                    >
                      <svg
                        width="8"
                        height="8"
                        viewBox="0 0 12 8"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                          fill="#677F8E"
                        />
                      </svg>
                    </span>
                    <div
                      class="options"
                      v-show="open_dropdown_coin"
                      style="left: 0px; top: 33px"
                    >
                      <ul>
                        <li
                          :class="{ 'orange-text': bidOffer == 99 }"
                          @click="bidOffer = 99"
                        >
                          All
                        </li>
                        <li
                          :class="{ 'orange-text': bidOffer == 0 }"
                          @click="bidOffer = 0"
                        >
                          Bid
                        </li>
                        <li
                          :class="{ 'orange-text': bidOffer == 1 }"
                          @click="bidOffer = 1"
                        >
                          Offer
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <!-- buy / sell live scores table start -->
              <div ref="scrollToMe" class="bs-table-l">
                <table class="table table-borderless">
                  <thead>
                    <tr>
                      <th>Price (THB)</th>
                      <th class="text-right">Vol ({{ coinName }})</th>
                      <th class="text-right">Total (THB)</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- loading skaleton start -->
                    <template v-if="tl_isLoading">
                      <tr v-for="(item, index) in 10" :key="'askL' + index">
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="100%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="100%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="100%"
                          ></b-skeleton>
                        </td>
                      </tr>
                    </template>
                    <!-- loading skaleton end -->
                    <template v-if="bidOffer != 0">
                      <tr
                        v-for="(item, index) in plate.askRows"
                        :key="'ask' + index"
                      >
                        <td class="red" @click="selectedSellScore(item.price)">
                          {{
                            $MyFunc.NumberFormat(
                              item.price,
                              currentScale.bathScale
                            )
                          }}
                        </td>
                        <td class="text-right">
                          {{
                            $MyFunc.NumberFormat(
                              item.amount,
                              currentScale.coinScale
                            )
                          }}
                        </td>
                        <td class="text-right">
                          {{ $MyFunc.NumberFormat(item.total) }}
                        </td>
                      </tr>
                    </template>
                    <template v-if="bidOffer == 99">
                      <tr>
                        <td
                          colspan="3"
                          :class="
                            'text-last-price text-center ' + priceInfo.textColor
                          "
                          @click="
                            selected_buy_score = priceInfo.last;
                            selected_sell_score = priceInfo.last;
                          "
                        >
                          <span v-if="priceInfo.last > 0">{{
                            $MyFunc.NumberFormat(
                              priceInfo.last,
                              currentScale.bathScale
                            )
                          }}</span>
                          <b-skeleton
                            v-else
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="100%"
                          ></b-skeleton>
                        </td>
                      </tr>
                    </template>
                    <template v-if="plate.bidRows.length <= 0">
                      <tr v-for="(item, index) in 20" :key="'bidL' + index">
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="100%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="100%"
                          ></b-skeleton>
                        </td>
                        <td>
                          <b-skeleton
                            class="skc custom-skeleton-wave"
                            animation="wave"
                            width="100%"
                          ></b-skeleton>
                        </td>
                      </tr>
                    </template>
                    <template v-if="bidOffer != 1">
                      <tr
                        v-for="(item, index) in plate.bidRows"
                        :key="'bid' + index"
                      >
                        <td
                          class="green"
                          @click="selectedSellScore(item.price)"
                        >
                          {{
                            $MyFunc.NumberFormat(
                              item.price,
                              currentScale.bathScale
                            )
                          }}
                        </td>
                        <td class="text-right">
                          {{
                            $MyFunc.NumberFormat(
                              item.amount,
                              currentScale.coinScale
                            )
                          }}
                        </td>
                        <td class="text-right">
                          {{ $MyFunc.NumberFormat(item.total) }}
                        </td>
                      </tr>
                    </template>
                  </tbody>
                </table>
              </div>
              <!-- buy / sell live scores table end -->
            </div>
            <div class="col-12 col-lg-8 col-xl-8 p-0">
              <div class="chart-topbar">
                <div
                  class="ctype-sc"
                  tabindex="0"
                  @click="show_chart_type_options = !show_chart_type_options"
                  @blur="show_chart_type_options = false"
                >
                  <span class="text" :class="selected_chart_type_option"></span>
                  <span class="icon">
                    <svg
                      width="7"
                      height="5"
                      viewBox="0 0 7 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E"
                      />
                    </svg>
                  </span>
                  <div
                    class="options"
                    :class="show_chart_type_options ? 'show' : 'hidden'"
                  >
                    <ul>
                      <li
                        @click="
                          selected_chart_type_option = 'candles';
                          changeChartType('candle');
                        "
                      >
                        <span class="chart-icon candles"></span> Candles
                      </li>
                      <li
                        @click="
                          selected_chart_type_option = 'line';
                          changeChartType('line');
                        "
                      >
                        <span class="chart-icon line"></span> Line
                      </li>
                      <li
                        @click="
                          selected_chart_type_option = 'mountain';
                          changeChartType('mountain');
                        "
                      >
                        <!-- <span class="chart-icon mountain"></span> Mountain -->
                        <span class="chart-icon mountain"></span> Area
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="timeframe">
                  <button
                    :class="active_timeframe === '15m' ? 'active' : ''"
                    @click="
                      active_timeframe = '15m';
                      changeTime('15', '');
                    "
                  >
                    15m
                  </button>
                  <button
                    :class="active_timeframe === '30m' ? 'active' : ''"
                    @click="
                      active_timeframe = '30m';
                      changeTime('30', '');
                    "
                  >
                    30m
                  </button>
                  <button
                    :class="active_timeframe === '1hr' ? 'active' : ''"
                    @click="
                      active_timeframe = '1hr';
                      changeTime('1', 'h');
                    "
                  >
                    1hr
                  </button>
                  <button
                    :class="active_timeframe === '4hr' ? 'active' : ''"
                    @click="
                      active_timeframe = '4hr';
                      changeTime('4', 'h');
                    "
                  >
                    4hr
                  </button>
                  <button
                    :class="active_timeframe === '1d' ? 'active' : ''"
                    @click="
                      active_timeframe = '1d';
                      changeTime('1', 'd');
                    "
                  >
                    1d
                  </button>
                  <div
                    class="tf-sc"
                    tabindex="0"
                    @click="
                      (show_timeline_options = !show_timeline_options),
                        (active_timeframe = null)
                    "
                    @blur="show_timeline_options = false"
                  >
                    <span
                      class="text"
                      :class="{
                        active: show_timeline_options,
                        active: !active_timeframe,
                      }"
                    >
                      {{ selected_timeline_option }}
                    </span>
                    <span class="icon">
                      <svg
                        width="7"
                        height="5"
                        viewBox="0 0 7 5"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                          fill="#677F8E"
                        />
                      </svg>
                    </span>
                    <div
                      :class="
                        show_timeline_options
                          ? 'options show'
                          : 'options hidden'
                      "
                    >
                      <ul>
                        <li
                          @click="
                            selected_timeline_option = '1 m';
                            changeTime('1', '');
                          "
                        >
                          1 m
                        </li>
                        <li
                          @click="
                            selected_timeline_option = '5 m';
                            changeTime('5', '');
                          "
                        >
                          5 m
                        </li>
                        <li
                          @click="
                            selected_timeline_option = '10 m';
                            changeTime('10', '');
                          "
                        >
                          10 m
                        </li>
                        <li
                          @click="
                            selected_timeline_option = '2 hr';
                            changeTime('2', 'h');
                          "
                        >
                          2 hr
                        </li>
                        <li
                          @click="
                            selected_timeline_option = '1 week';
                            changeTime('1', 'w');
                          "
                        >
                          1 week
                        </li>
                        <li
                          @click="
                            selected_timeline_option = '1 mon';
                            changeTime('1', 'm');
                          "
                        >
                          1 month
                        </li>
                        <li
                          @click="
                            selected_timeline_option = '1 year';
                            changeTime('1', 'y');
                          "
                        >
                          1 year
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div
                  class="ic-sc"
                  tabindex="0"
                  @click="show_indicator_options = !show_indicator_options"
                  @blur="show_indicator_options = false"
                >
                  <span
                    @click="iaddIndicators()"
                    class="text"
                    :class="show_indicator_options ? 'active' : ''"
                    >{{ selected_indicator_option }}</span
                  >
                  <span class="icon"
                    ><svg
                      width="7"
                      height="5"
                      viewBox="0 0 7 5"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                        fill="#677F8E"
                      />
                    </svg>
                  </span>
                </div>
                <!-- <span class="ct-alert" @click="handelAlertPopup()">
                  <svg
                    width="12"
                    height="15"
                    viewBox="0 0 12 15"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M4.67333 13.0067C4.67333 13.74 5.26667 14.3333 6 14.3333C6.73333 14.3333 7.32667 13.74 7.32667 13.0067H4.67333ZM6 3C7.84 3 9.33333 4.49333 9.33333 6.33333V11H2.66667V6.33333C2.66667 4.49333 4.16 3 6 3ZM6 0C5.44667 0 5 0.446667 5 1V1.78C2.90667 2.23333 1.33333 4.1 1.33333 6.33333V10.3333L0 11.6667V12.3333H12V11.6667L10.6667 10.3333V6.33333C10.6667 4.1 9.09333 2.23333 7 1.78V1C7 0.446667 6.55333 0 6 0Z"
                      fill="#677F8E"
                    />
                  </svg>
                  <button>Alert</button>
                </span> -->
                <!-- <span class="save"><button>Save</button></span> -->
                <span class="capture" @click="saveChartImage()">
                  <svg
                    width="16"
                    height="14"
                    viewBox="0 0 16 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M8.0744 11.074C9.00032 11.074 9.78749 10.75 10.4359 10.1021C11.0838 9.45375 11.4077 8.66659 11.4077 7.74066C11.4077 6.81473 11.0838 6.02757 10.4359 5.37918C9.78749 4.73128 9.00032 4.40733 8.0744 4.40733C7.14847 4.40733 6.36131 4.73128 5.71292 5.37918C5.06502 6.02757 4.74107 6.81473 4.74107 7.74066C4.74107 8.66659 5.06502 9.45375 5.71292 10.1021C6.36131 10.75 7.14847 11.074 8.0744 11.074ZM8.0744 9.59251C7.55588 9.59251 7.11761 9.4135 6.75958 9.05547C6.40156 8.69745 6.22255 8.25918 6.22255 7.74066C6.22255 7.22214 6.40156 6.78387 6.75958 6.42585C7.11761 6.06782 7.55588 5.88881 8.0744 5.88881C8.59292 5.88881 9.03119 6.06782 9.38921 6.42585C9.74724 6.78387 9.92625 7.22214 9.92625 7.74066C9.92625 8.25918 9.74724 8.69745 9.38921 9.05547C9.03119 9.4135 8.59292 9.59251 8.0744 9.59251ZM2.14847 13.6666C1.74107 13.6666 1.39242 13.5216 1.10255 13.2318C0.812177 12.9414 0.666992 12.5925 0.666992 12.1851V3.29622C0.666992 2.88881 0.812177 2.54017 1.10255 2.25029C1.39242 1.95992 1.74107 1.81473 2.14847 1.81473H4.48181L5.85218 0.333252H10.2966L11.667 1.81473H14.0003C14.4077 1.81473 14.7566 1.95992 15.047 2.25029C15.3369 2.54017 15.4818 2.88881 15.4818 3.29622V12.1851C15.4818 12.5925 15.3369 12.9414 15.047 13.2318C14.7566 13.5216 14.4077 13.6666 14.0003 13.6666H2.14847ZM14.0003 12.1851V3.29622H11.0003L9.64847 1.81473H6.50032L5.14847 3.29622H2.14847V12.1851H14.0003Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <span class="expand" @click="chartFullScreen">
                  <svg
                    width="16"
                    height="16"
                    viewBox="0 0 16 16"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_3353_2144"
                      style="mask-type: alpha"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="16"
                      height="16"
                    >
                      <rect width="16" height="16" fill="#D9D9D9" />
                    </mask>
                    <g mask="url(#mask0_3353_2144)">
                      <path
                        d="M8.81613 13.226C8.81613 12.8002 9.14951 12.4519 9.55696 12.4519H11.4757L8.8163 9.82018C8.52738 9.51831 8.52738 9.03066 8.8163 8.72879C9.10523 8.42691 9.57196 8.42691 9.86088 8.72879L12.5203 11.3605V9.35576C12.5203 8.93004 12.8537 8.58172 13.2611 8.58172C13.6686 8.58172 14.002 8.93004 14.002 9.35576V13.226C14.002 13.6517 13.6686 14 13.2611 14H9.55696C9.14951 14 8.81613 13.6517 8.81613 13.226Z"
                        fill="#677F8E"
                      />
                      <path
                        d="M7.18485 2.77404C7.18485 3.19976 6.85147 3.54808 6.44401 3.54808H4.52524L7.18467 6.17982C7.4736 6.48169 7.4736 6.96934 7.18467 7.27121C6.89575 7.57309 6.42902 7.57309 6.1401 7.27121L3.48067 4.63948V6.64424C3.48067 7.06996 3.14729 7.41828 2.73983 7.41828C2.33237 7.41828 1.999 7.06996 1.999 6.64424V2.77404C1.999 2.34832 2.33237 2 2.73983 2H6.44401C6.85147 2 7.18485 2.34832 7.18485 2.77404Z"
                        fill="#677F8E"
                      />
                    </g>
                  </svg>
                </span>
                <span
                  class="setting"
                  @click="
                    $bvModal.show('settings-modal');
                    getCheckStatusModel();
                    closeModalIndicators();
                  "
                  ><svg
                    width="14"
                    height="14"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.7617 7.62701C11.7884 7.42701 11.8017 7.22034 11.8017 7.00034C11.8017 6.78701 11.7884 6.57367 11.755 6.37367L13.1084 5.32034C13.2284 5.22701 13.2617 5.04701 13.1884 4.91367L11.9084 2.70034C11.8284 2.55368 11.6617 2.50701 11.515 2.55367L9.9217 3.19367C9.58837 2.94034 9.23503 2.72701 8.8417 2.56701L8.6017 0.873675C8.57503 0.713675 8.4417 0.600342 8.2817 0.600342H5.7217C5.5617 0.600342 5.43503 0.713675 5.40837 0.873675L5.16837 2.56701C4.77503 2.72701 4.41503 2.94701 4.08837 3.19367L2.49503 2.55367C2.34837 2.50034 2.1817 2.55368 2.1017 2.70034L0.828367 4.91367C0.748367 5.05367 0.775034 5.22701 0.908367 5.32034L2.2617 6.37367C2.22837 6.57367 2.2017 6.79367 2.2017 7.00034C2.2017 7.20701 2.21503 7.42701 2.24837 7.62701L0.895034 8.68034C0.775034 8.77367 0.741701 8.95367 0.815034 9.08701L2.09503 11.3003C2.17503 11.447 2.3417 11.4937 2.48837 11.447L4.0817 10.807C4.41503 11.0603 4.76837 11.2737 5.1617 11.4337L5.4017 13.127C5.43503 13.287 5.5617 13.4003 5.7217 13.4003H8.2817C8.4417 13.4003 8.57503 13.287 8.59503 13.127L8.83503 11.4337C9.22837 11.2737 9.58837 11.0603 9.91503 10.807L11.5084 11.447C11.655 11.5003 11.8217 11.447 11.9017 11.3003L13.1817 9.08701C13.2617 8.94034 13.2284 8.77367 13.1017 8.68034L11.7617 7.62701ZM7.0017 9.40034C5.6817 9.40034 4.6017 8.32034 4.6017 7.00034C4.6017 5.68034 5.6817 4.60034 7.0017 4.60034C8.3217 4.60034 9.4017 5.68034 9.4017 7.00034C9.4017 8.32034 8.3217 9.40034 7.0017 9.40034Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
              </div>
              <div class="p-0">
                <!-- <h1 class="text-white">Chart Here</h1> -->
                <iframe
                  id="myIframe"
                  ref="myid"
                  class="iframe-chart"
                  :src="chartSymbolUrl"
                ></iframe>
              </div>
              <!-- End Chart Area -->
              <ExchangeOrder
                :selected_sell_score="selected_sell_score"
                :selected_buy_score="selected_buy_score"
                :coinCurrntSell="plate.minSell"
                :coinCurrntBuy="plate.minBuy"
                @updateSellScore="selected_sell_score = $event"
                @updateBuyScore="selected_buy_score = $event"
                :coinCurrentValue="priceInfo.last"
                :currentCoin="currentCoin"
                :currentScale="currentScale"
                :assetsWallet="assetsWallet"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-lg-3 col-xl-3 bs-right-table pt-4">
          <!-- <div class="all-coins-container">
            <div
              class="select ratioSelect"
              tabindex="0"
              @click="open_dropdown_right = !open_dropdown_right"
              @blur="open_dropdown_right = false"
              style="width: 120px !important"
            >
              <span v-if="allCoin == 99" class="text">All Coins</span>
              <span v-if="allCoin == 0" class="text">Favorite</span>
              <span v-if="allCoin == 1" class="text">Coin Ticker</span>
              <span
                :class="open_dropdown_right ? 'icon rotate-sc-icon' : 'icon'"
              >
                <svg
                  width="8"
                  height="8"
                  viewBox="0 0 12 8"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                    fill="#677F8E"
                  />
                </svg>
              </span>
              <div
                class="options"
                v-show="open_dropdown_right"
                style="left: 0px; top: 33px"
              >
                <ul>
                  <li
                    :class="{ 'orange-text': allCoin == 99 }"
                    @click="allCoin = 99"
                  >
                    All Coins
                  </li>
                  <li
                    :class="{ 'orange-text': allCoin == 0 }"
                    @click="allCoin = 0"
                  >
                    Favorite
                  </li>
                  <li
                    :class="{ 'orange-text': allCoin == 1 }"
                    @click="allCoin = 1"
                  >
                    Coin Ticker
                  </li>
                </ul>
              </div>
            </div>
          </div> -->
          <div class="row">
            <div class="col text-center">
              <div class="menu-item">
                <b-link
                  :class="{
                    active: allCoin === 99,
                    colormiddle: allCoin !== 99,
                  }"
                  @click="allCoin = 99"
                  >THB</b-link
                >
              </div>
            </div>
            <div class="col text-center">
              <div class="menu-item">
                <b-link
                  :class="{
                    active: allCoin === 88,
                    colormiddle: allCoin !== 88,
                  }"
                  @click="allCoin = 88"
                  >USDT</b-link
                >
              </div>
            </div>
            <div class="col text-center">
              <div class="menu-item">
                <b-link
                  :class="{ active: allCoin === 1, colormiddle: allCoin !== 1 }"
                  @click="allCoin = 1"
                  >Balance</b-link
                >
              </div>
            </div>
            <div class="col text-center">
              <div class="menu-item">
                <b-link
                  :class="{ active: allCoin === 0, colormiddle: allCoin !== 0 }"
                  @click="allCoin = 0"
                  >Favorite</b-link
                >
              </div>
            </div>
          </div>
          <div class="bs-table-s fav-table pt-2">
            <table class="table table-borderless">
              <thead>
                <tr class="sticky-top fixed">
                  <th></th>
                  <th>
                    <span @click="sortCoin('coin')">
                      Coin
                      <svg
                        class="yellow"
                        width="6"
                        height="12"
                        viewBox="0 0 6 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_4623_9379)">
                          <path
                            d="M0 8L3 11L6 8H0Z"
                            :fill="
                              headCoinDown === null
                                ? '#808080'
                                : headCoinDown
                                ? '#09BE8B'
                                : '#808080'
                            "
                          />
                          <path
                            d="M6 4L3 1L0 4L6 4Z"
                            :fill="
                              headCoinDown === null
                                ? '#808080'
                                : headCoinDown
                                ? '#808080'
                                : '#09BE8B'
                            "
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4623_9379">
                            <rect width="6" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </th>
                  <th class="text-right">
                    <span @click="sortCoin('price')">
                      Price <span v-if="allCoin == 88">(BTC)</span><span v-else>(THB)</span>
                      <svg
                        width="6"
                        height="12"
                        viewBox="0 0 6 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_4623_9379)">
                          <path
                            d="M0 8L3 11L6 8H0Z"
                            :fill="
                              headPriceDown === null
                                ? '#808080'
                                : headPriceDown
                                ? '#09BE8B'
                                : '#808080'
                            "
                          />
                          <path
                            d="M6 4L3 1L0 4L6 4Z"
                            :fill="
                              headPriceDown === null
                                ? '#808080'
                                : headPriceDown
                                ? '#808080'
                                : '#09BE8B'
                            "
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4623_9379">
                            <rect width="6" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </th>
                  <th class="text-right">
                    <span @click="sortCoin('chg')">
                      %Chg
                      <svg
                        width="6"
                        height="12"
                        viewBox="0 0 6 12"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <g clip-path="url(#clip0_4623_9379)">
                          <path
                            d="M0 8L3 11L6 8H0Z"
                            :fill="
                              headChgDown === null
                                ? '#808080'
                                : headChgDown
                                ? '#09BE8B'
                                : '#808080'
                            "
                          />
                          <path
                            d="M6 4L3 1L0 4L6 4Z"
                            :fill="
                              headChgDown === null
                                ? '#808080'
                                : headChgDown
                                ? '#808080'
                                : '#09BE8B'
                            "
                          />
                        </g>
                        <defs>
                          <clipPath id="clip0_4623_9379">
                            <rect width="6" height="12" fill="white" />
                          </clipPath>
                        </defs>
                      </svg>
                    </span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="tr_isLoading">
                  <tr v-for="(item, index) in 14" :key="'coinL' + index">
                    <td>
                      <b-skeleton
                        class="skc custom-skeleton-wave"
                        animation="wave"
                        width="100%"
                      ></b-skeleton>
                    </td>
                    <td>
                      <b-skeleton
                        class="skc custom-skeleton-wave"
                        animation="wave"
                        width="100%"
                      ></b-skeleton>
                    </td>
                    <td>
                      <b-skeleton
                        class="skc custom-skeleton-wave"
                        animation="wave"
                        width="100%"
                      ></b-skeleton>
                    </td>
                    <td>
                      <b-skeleton
                        class="skc custom-skeleton-wave"
                        animation="wave"
                        width="100%"
                      ></b-skeleton>
                    </td>
                  </tr>
                </template>
                <template v-for="(item, index) in coinMarket">
                  <tr
                    v-if="
                      allCoin == 99 || allCoin == 1 || favCoinRows[item.symbol]
                    "
                    :key="'coin' + index"
                  >
                    <td @click="selectFav(item.symbol)">
                      <span style="cursor: pointer">
                        <svg
                          v-if="favCoinRows[item.symbol] == true"
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M 13.6663 5.7876 L 8.873 5.3743 L 6.9997 0.9609 L 5.1263 5.3809 L 0.333 5.7876 L 3.973 8.9409 L 2.8797 13.6276 L 6.9997 11.1409 L 11.1197 13.6276 L 10.033 8.9409 L 13.6663 5.7876 Z Z"
                            fill="#D6DDE1"
                          />
                        </svg>
                        <svg
                          v-else
                          width="14"
                          height="14"
                          viewBox="0 0 14 14"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M13.6693 5.23438L8.87594 4.82105L7.0026 0.407715L5.12927 4.82771L0.335938 5.23438L3.97594 8.38772L2.8826 13.0744L7.0026 10.5877L11.1226 13.0744L10.0359 8.38772L13.6693 5.23438ZM7.0026 9.34105L4.49594 10.8544L5.1626 8.00105L2.94927 6.08105L5.86927 5.82771L7.0026 3.14105L8.1426 5.83438L11.0626 6.08772L8.84927 8.00771L9.51594 10.861L7.0026 9.34105Z"
                            fill="#677F8E"
                          />
                        </svg>
                      </span>
                    </td>
                    <td
                      @click="selectMarketSymbol(item.url.toString())"
                      style="cursor: pointer"
                    >
                      {{ item.symbol }}
                    </td>
                    <td class="text-right">
                      {{ $MyFunc.NumberFormat(item.price, item.bathScale) }}
                    </td>
                    <td :class="item.color + ' text-right'">
                      {{ $MyFunc.PercentChg(item.pChg, 2) }}%
                    </td>
                  </tr>
                </template>
              </tbody>
            </table>
          </div>
          <p class="btc-ticker">
            {{ allCoin == 99 ? "All" : allCoin == 0 ? "Favorite" : coinName }}
            Ticker
          </p>
          <div class="bs-table-sb">
            <table class="table table-borderless p-0">
              <thead>
                <tr class="sticky-top">
                  <th>Time</th>
                  <th v-if="allCoin == 99 || allCoin == 0">Coin</th>
                  <th class="text-right">Price (THB)</th>
                  <th class="text-right">
                    Volume <span v-if="allCoin == 1">({{ coinName }})</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                <template v-if="trs_isLoading">
                  <tr v-for="(item, index) in 15" :key="'TicL' + index">
                    <td>
                      <b-skeleton
                        class="skc custom-skeleton-wave"
                        animation="wave"
                        width="100%"
                      ></b-skeleton>
                    </td>
                    <td>
                      <b-skeleton
                        class="skc custom-skeleton-wave"
                        animation="wave"
                        width="100%"
                      ></b-skeleton>
                    </td>
                    <td>
                      <b-skeleton
                        class="skc custom-skeleton-wave"
                        animation="wave"
                        width="100%"
                      ></b-skeleton>
                    </td>
                  </tr>
                </template>
                <tr v-for="(item, index) in ticker.datarows" :key="'a' + index">
                  <template
                    v-if="allCoin == 0 && favCoinRows[item.symbol] == true"
                  >
                    <td>{{ item.time }}</td>
                    <td v-if="allCoin == 99 || allCoin == 0">
                      {{ item.symbol?.split("/")[0] }}
                    </td>
                    <td class="text-right">
                      {{ $MyFunc.NumberFormat(item.price, item.bathScale) }}
                    </td>
                    <td class="text-right">
                      {{ $MyFunc.NumberFormat(item.amount, item.coinScale) }}
                    </td>
                  </template>
                  <template v-if="allCoin == 99">
                    <td>{{ item.time }}</td>
                    <td v-if="allCoin == 99 || allCoin == 0">
                      {{ item.symbol?.split("/")[0] }}
                    </td>
                    <td class="text-right">
                      {{ $MyFunc.NumberFormat(item.price, item.bathScale) }}
                    </td>
                    <td class="text-right">
                      {{ $MyFunc.NumberFormat(item.amount, item.coinScale) }}
                    </td>
                  </template>
                  <template
                    v-if="allCoin == 1 && coinName + '/THB' == item.symbol"
                  >
                    <td>{{ item.time }}</td>
                    <td v-if="allCoin == 99 || allCoin == 0">
                      {{ item.symbol?.split("/")[0] }}
                    </td>
                    <td class="text-right">
                      {{ $MyFunc.NumberFormat(item.price, item.bathScale) }}
                    </td>
                    <td class="text-right">
                      {{ $MyFunc.NumberFormat(item.amount, item.coinScale) }}
                    </td>
                  </template>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
      <ExchangeFooter
        :priceInfo="priceInfo"
        :coinMarket="coinMarket"
        :currentCoin="currentCoin"
        :currentScale="currentScale"
        :assetsWallet="assetsWallet"
        :iframeContainer="iframeContainer"
      />
    </div>
    <TradeOnChart
      @show_trade_on_chart="
        (e) => {
          this.show_trade_on_chart = e;
        }
      "
      v-if="show_trade_on_chart"
      :currentCoin="currentCoin"
      :current_tab="current_tab"
      :data_success="data_success"
      :coinCurrentValue="coinCurrentValue"
      :iframeContainer="iframeContainer"
    />
    <SettingsModalVue
      @getChartSettings="getChartSettings($event)"
      @resetChartSettings="resetChartSettings($event)"
      @updateChartSettings="updateChartSettings($event)"
      @getIndicators="getIndicators($event)"
      @getThemeIndicators="getThemeIndicators($event)"
      @getActiceIndicatorsDetails="getActiceIndicatorsDetails($event)"
      @addIndicators="addIndicators($event)"
      @child-event="triggerChildMethod()"
      @child-event-indicator="triggerChildMethodForIndicator()"
      @child-setting-indicator="ichecksettingindicator()"
      @child-event-active-indicator="triggerChildMethodForActiveIndicator()"
      @active-indicator-details="activeIndicatorDetails()"
    />
    <AlertTutorial @handelAlert="handelAlert" />
    <PriceAlert1 :currentCoin="currentCoin" :priceInfo="priceInfo" />
    <PriceAlert2
      :currentCoin="currentCoin"
      :priceInfo="priceInfo"
      @createAlertPopup="showCreateAlert"
    />
  </div>
</template>

<script>
import { mapMutations } from "vuex";
import { HTTP } from "@/config/axios";
import ExchangeFooter from "@/pages/exchange/components/ExchangeFooter.vue";
import ExchangeOrder from "@/pages/exchange/components/ExchangeOrder.vue";
import SettingsModalVue from "../../pages/chart/components/SettingsModal.vue";
import TradeOnChart from "@/pages/chart/components/TradeOnChart.vue";
import AlertTutorial from "@/pages/chart/components/AlertTutorialModal.vue";
import PriceAlert1 from "@/pages/chart/components/PriceAlert1.vue";
import PriceAlert2 from "@/pages/chart/components/PriceAlert2.vue";
import EventBus from "../../event-bus";
import { set } from "vue";
var Stomp = require("stompjs");
var SockJS = require("sockjs-client");
export default {
  name: "Exchange",
  components: {
    ExchangeOrder,
    ExchangeFooter,
    SettingsModalVue,
    TradeOnChart,
    AlertTutorial,
    PriceAlert1,
    PriceAlert2,
  },
  data() {
    return {
      headChgDown: null,
      headPriceDown: null,
      headCoinDown: null,
      isLoading: true,
      show_trade_on_chart: false,
      memberId: null,
      coinName: "BTC",
      currentScale: {
        coinScale: 8,
        bathScale: 2,
        fixScale: 2,
        minVolume: 0,
        maxVolume: 0,
      },
      stompClient: null,
      iframeContainer: null,
      //
      showBtcText: true,
      active_timeframe: "1d",
      show_timeline_options: false,
      selected_timeline_option: "More",
      show_indicator_options: false,
      selected_indicator_option: "Indicator",
      show_chart_type_options: false,
      selected_chart_type_option: "hollow-candles",
      //
      open_dropdown_right: false,
      open_dropdown_ratio: false,
      open_dropdown_coin: false,
      bidOffer: 99,
      allCoin: 99,
      coinSearchText: "",
      showSearchBox: false,
      selected_sell_score: 0,
      selected_buy_score: 0,
      user_data: "",
      currentCoin: {
        coin: "BTC",
        base: "THB",
        symbol: "BTC/THB",
        fee: 0,
        spreadOrder: 0,
      },
      plate: {
        askRows: [],
        bidRows: [],
        minSell: 0,
        minBuy: 0,
      },
      ticker: {
        datarows: [],
        numrows: 20,
      },
      priceInfo: {
        last: 0,
        chg: 0,
        high24: 0,
        low24: 0,
        vol24: 0,
        pChg: 0,
        textColor: "",
      },
      coinMarket: [],
      coinMarketScale: [],
      assetsWallet: [],
      chartSymbolUrl:
        "/libchart/index.php?symbol=BTC%2FTHB",
      tl_isLoading: true,
      tr_isLoading: true,
      trs_isLoading: true,
      favCoinRows: {},
      sortCoinValue: null,
      current_tab: "buy",
      sortSymbol: null,
      sortPrice: null,
      sortChg: null,
      isUserLogin: false,
      getAssetWalletLoading: false,
    };
  },
  computed: {
    filteredCoins() {
      return this.coinMarket.filter((coin) =>
        coin.symbol.toLowerCase().includes(this.coinSearchText.toLowerCase())
      );
    },
    filteredCoinsImages() {
      return this.coinMarket.filter((coin) =>
        coin.symbol
          .toLowerCase()
          .includes(this.currentCoin.symbol.toLowerCase())
      )[0];
    },
  },
  methods: {
    get_tradeonchart_buy(value) {
      this.show_trade_on_chart = true;
      this.current_tab = "buy";
      this.coinCurrentValue = value.price;
      this.data_success = [
        {
          status: "fail",
          type: "buy",
          price: value.price,
          theme: "#9bacb6",
          percent: 0,
        },
      ];
      // None = 0,
      // Market = 1,
      // Condition = 2,
      // OpenOrder = 3
    },
    get_tradeonchart_sell(value) {
      this.show_trade_on_chart = true;
      this.current_tab = "sell";
      this.coinCurrentValue = value.price;
      // addTrendlineOrder("11", "title", 2500000, "#111110", 2, true, true)
      this.data_success = [
        {
          id: 0,
          status: "fail",
          type: "sell",
          price: value.price,
          theme: "#e04152",
          percent: 0,
        },
      ];
    },
    handleEnterKey(event) {
      // ตรวจสอบว่าปุ่ม Enter ถูกกดหรือไม่
      if (event.key === "Enter") {
        // ตรวจสอบจำนวนรายการที่แสดง
        if (this.filteredCoins.length === 1) {
          // เลือกรายการแรก
          const selectedCoin = this.filteredCoins[0];
          // ทำสิ่งที่ต้องการ เช่น เรียกเมธอด selectMarketSymbol() หรืออื่นๆ
          this.selectMarketSymbol(
            selectedCoin.url.toString(),
            selectedCoin.svgHtmlString ? selectedCoin.svgHtmlString : null
          );
        }
      }
    },
    checkIfClickedOutside(event) {
      const CoinDropdown = this.$refs.coinDropdown;
      if (CoinDropdown && !CoinDropdown.contains(event.target)) {
        this.showSearchBox = false;
      }
    },
    handelAlertPopup() {
      let showTut = localStorage.getItem("alertTut");
      if (!showTut) {
        this.$bvModal.show("alert-tutorial");
      } else {
        this.$bvModal.show("price-alert-2");
      }
    },
    handelAlert(e) {
      if (e) {
        this.$bvModal.show("price-alert-2");
      }
    },
    showCreateAlert(e) {
      if (e) {
        this.$bvModal.show("price-alert-1");
      }
    },
    ...mapMutations(["setCurrentPair"]),
    iaddIndicators() {
      if (typeof this.iframeContainer.iAddIndicators === "function") {
        this.iframeContainer.iAddIndicators();
      }
    },
    addIndicators(indicator) {
      if (typeof this.iframeContainer.iAddIndicators === "function") {
        this.iframeContainer.iAddIndicators_webtrade(indicator);
      }
    },
    changeChartType(type) {
      if (typeof this.iframeContainer.iPriceStyleChange === "function") {
        this.iframeContainer.iPriceStyleChange(type);
      }
    },
    changeTime(time, unit) {
      if (typeof this.iframeContainer.iTimeFrameChange === "function") {
        this.iframeContainer.iTimeFrameChange(time, unit);
      }
      if (typeof this.iframeContainer.iChangecoinscale === "function") {
        this.iframeContainer.iChangecoinscale(this.currentScale);
      }
    },
    saveChartImage() {
      if (typeof this.iframeContainer.iSaveImageClick === "function") {
        this.iframeContainer.iSaveImageClick();
      }
    },
    chartFullScreen() {
      if (typeof this.iframeContainer.iChartFullScreen === "function") {
        this.iframeContainer.iChartFullScreen();
      }
    },
    defaultSymbol() {
      if (this.$route.params.pair == undefined) {
        this.currentCoin.symbol = "BTC/THB";
        this.$route.params.pair = "btc_thb";
      } else {
        let dataArr = this.$route.params.pair.split("_");
        this.currentCoin.symbol = (dataArr[0] + "/" + dataArr[1]).toUpperCase();
        this.chartSymbolUrl =
          "/libchart/index.php?symbol=" +
          encodeURIComponent(this.currentCoin.symbol);
      }
      this.coinName = this.currentCoin.symbol.split("/")[0];
    },
    updateDataTrade() {
      if (this.stompClient) {
        this.stompClient.disconnect();
      }
      let self = this;
      let url = this.MARKET_WS_URL + "/market/market-ws";
      this.stompClient = Stomp.over(new SockJS(url));
      this.stompClient.debug = false;
      this.stompClient.connect({}, () => {
        this.stompClient.subscribe("/topic/market/thumb", function (msg) {
          self.updatePriceInfo(JSON.parse(msg.body));
        });
        this.stompClient.subscribe(
          "/topic/market/trade-plate/" + this.currentCoin.symbol,
          function (msg) {
            self.updateBidAsk(JSON.parse(msg.body));
          }
        );
        this.stompClient.subscribe(
          "/topic/market/trade/" + this.currentCoin.symbol,
          function (msg) {
            if (self.allCoin == 1) {
              self.updateTickers(JSON.parse(msg.body));
            }
          }
        );
        this.stompClient.subscribe("/topic/market/trade/all", function (msg) {
          if (self.allCoin != 1) {
            self.updateTickers(JSON.parse(msg.body));
          }
        });

        if (self.memberId) {
          this.stompClient.subscribe(
            "/topic/market/order-canceled/" +
              this.currentCoin.symbol +
              "/" +
              self.memberId,
            function (msg) {
              if (JSON.parse(msg.body)) {
                self.getAssetWallet();
              }
            }
          );
          this.stompClient.subscribe(
            "/topic/market/order-completed/" +
              this.currentCoin.symbol +
              "/" +
              self.memberId,
            function (msg) {
              if (JSON.parse(msg.body)) {
                self.getAssetWallet();
              }
            }
          );
          this.stompClient.subscribe(
            "/topic/market/order-trade/" +
              this.currentCoin.symbol +
              "/" +
              self.memberId,
            function (msg) {
              if (JSON.parse(msg.body)) {
                self.getAssetWallet();
              }
            }
          );
        }
      });
    },
    async getBidAsk() {
      await HTTP.post(
        "/market/exchange-plate",
        { symbol: this.currentCoin.symbol },
        { emulateJSON: true }
      ).then((response) => {
        let resp = response.data;
        let data = [];
        if (resp.ask) {
          let buy_score = 0;
          this.plate.minSell = resp.ask[0]?.price;
          resp.ask.forEach((item, index) => {
            if (buy_score == 0) {
              buy_score = item.price;
            }
            data.push({
              price: item.price,
              amount: item.amount,
              total: item.price * item.amount,
            });
          });
          this.selected_buy_score = buy_score;
          this.plate.askRows = data.reverse();
        }
        if (resp.bid) {
          let sell_score = 0;
          data = [];
          this.plate.minBuy = resp.bid[0]?.price;
          resp.bid.forEach((item, index) => {
            if (sell_score == 0) {
              sell_score = item.price;
            }
            data.push({
              price: item.price,
              amount: item.amount,
              total: item.price * item.amount,
            });
          });
          this.selected_sell_score = sell_score;
          this.plate.bidRows = data;
        }
        this.tl_isLoading = false;
        this.isLoading = false;
      });
    },
    updateBidAsk(resp) {
      let data = [];
      resp.items.forEach((item, index) => {
        data.push({
          price: item.price,
          amount: item.amount,
          total: item.price * item.amount,
        });
      });
      if (resp.direction == "SELL") {
        this.plate.minSell = this.plate.bidRows[0].price;
        this.plate.askRows = data.reverse();
        var lastElement_buy = this.plate.askRows[this.plate.askRows.length - 1];
        // this.selected_buy_score = lastElement_buy.price;
      } else {
        this.plate.bidRows = data;
        this.plate.minBuy =
          this.plate.askRows[this.plate.askRows.length - 1].price;
        var lastElement_sell = this.plate.bidRows[0];
        // this.selected_sell_score = lastElement_sell.price;
      }
      this.isLoading = false;
      this.tl_isLoading = false;
    },
    getTickers() {
      HTTP.post(
        "/market/latest-trade",
        { symbol: this.currentCoin.symbol, size: this.ticker.numrows },
        { emulateJSON: true }
      ).then((response) => {
        let resp = response.data;
        if (resp.length > 0) {
          this.priceInfo.last = resp[0].price;
          this.ticker.datarows = [];
          let getScale = this.coinMarketScale.filter(
            (sc) => sc.symbol == this.currentCoin.symbol
          )[0];
          resp.forEach((item, index) => {
            let date = new Date(item.time);
            let hours = ("0" + date.getHours()).slice(-2);
            let minute = ("0" + date.getMinutes()).slice(-2);
            let second = ("0" + date.getSeconds()).slice(-2);
            this.ticker.datarows.push({
              time: hours + ":" + minute + ":" + second,
              symbol: item.symbol,
              price: item.price,
              amount: item.amount,
              coinScale: getScale.coinScale,
              bathScale: getScale.bathScale,
            });
          });
          this.isLoading = false;
          this.trs_isLoading = false;
        }
      });
    },
    updateTickers(resp) {
      if (resp.length > 0) {
        let CoinPrice = 0;
        resp.forEach((item, index) => {
          if (item.symbol == this.currentCoin.symbol) {
            CoinPrice = item.price;
          }
          let getScale = this.coinMarketScale.filter(
            (sc) => sc.symbol == item.symbol
          )[0];
          let date = new Date(item.time);
          let hours = ("0" + date.getHours()).slice(-2);
          let minute = ("0" + date.getMinutes()).slice(-2);
          let second = ("0" + date.getSeconds()).slice(-2);
          this.ticker.datarows = [
            {
              time: hours + ":" + minute + ":" + second,
              symbol: item.symbol,
              price: item.price,
              amount: item.amount,
              coinScale: getScale.coinScale,
              bathScale: getScale.bathScale,
            },
            ...this.ticker.datarows,
          ];
          this.coinMarket.map((coin) => {
            if (coin.symbol == item.symbol) {
              coin.price = item.price;
            }
          });
        });
        if (CoinPrice != 0) {
          this.priceInfo.last = CoinPrice;
        }
        if (this.ticker.datarows.length > this.ticker.numrows) {
          this.ticker.datarows = this.ticker.datarows.slice(
            0,
            this.ticker.numrows
          );
        }
      }
    },
    getSymbolThumb() {
      HTTP.post("/market/symbol-thumb", {
        emulateJSON: true,
      }).then((response) => {
        this.coinMarket = [];
        if (response.data.length > 0) {
          if (this.active_timeframe != "1d") {
            var time = "1";
            var type = "d";
            if (this.active_timeframe == "15m") {
              time = "15";
              type = "";
            }
            if (this.active_timeframe == "30m") {
              time = "30";
              type = "";
            }
            if (this.active_timeframe == "1hr") {
              time = "1";
              type = "h";
            }
            if (this.active_timeframe == "4hr") {
              time = "4";
              type = "h";
            }
            if (this.active_timeframe == "1d") {
              time = "1";
              type = "d";
            }
            this.isLoading = false;
            setTimeout(() => {
              this.changeTime(time, type);
            }, 1000);
          }
          response.data.forEach((item, index) => {
            let symbol = item.symbol.replace("/", "_").toLowerCase();
            let color = "";
            if (item.chg > 0) {
              color = "green";
            } else if (item.chg < 0) {
              color = "red";
            }
            let getScale = this.coinMarketScale.filter(
              (sc) => sc.symbol == item.symbol
            )[0];
            let coinMarket = {
              symbol: item.symbol,
              price: item.close,
              pChg: item.chg,
              volume: item.volume,
              url: "/exchange/" + symbol,
              color: color,
              logoFileId:
                this.MARKET_WS_URL + "/market/coin/image/" + item.logoFileId,
              coinScale: getScale.coinScale,
              bathScale: getScale.bathScale,
            };
            this.coinMarket.push(coinMarket);
            if (this.currentCoin.symbol == item.symbol) {
              if (item.chg > 0) {
                this.priceInfo.textColor = "green";
              } else if (item.chg < 0) {
                this.priceInfo.textColor = "red";
              }
              this.priceInfo.chg = item.change;
              this.priceInfo.high24 = item.high;
              this.priceInfo.low24 = item.low;
              this.priceInfo.vol24 = item.volume;
              this.priceInfo.pChg = item.chg;
            }
          });
        }

        this.tr_isLoading = false;
        if (this.isUserLogin) {
          this.getFov();
        } else {
          let fav_local = localStorage.getItem("fav");
          if (fav_local != null || fav_local != undefined) {
            var fav_local_s = fav_local.split(",");
          }
          if (fav_local != "" && fav_local != null) {
            for (let i = 0; i < this.coinMarket.length; i++) {
              for (let j = 0; j < fav_local_s.length; j++) {
                let typeSymbol = this.coinMarket[i].symbol;
                if (fav_local_s[j] == typeSymbol) {
                  this.$set(this.favCoinRows, typeSymbol, true);
                  break;
                } else {
                  this.$set(this.favCoinRows, typeSymbol, false);
                }
              }
            }
          } else {
            for (let i = 0; i < this.coinMarket.length; i++) {
              let typeSymbol = this.coinMarket[i].symbol;
              this.$set(this.favCoinRows, typeSymbol, false);
            }
          }
        }
      });
    },
    getFov() {
      HTTP.post("uc/member/get-member-favorite").then((result) => {
        let memberFavorite = result.data;
        if (memberFavorite.code == 0) {
          for (let keyy in this.coinMarket) {
            for (let key in memberFavorite.data) {
              let typeSymbol = this.coinMarket[keyy].symbol;
              if (memberFavorite.data[key].coinId == typeSymbol) {
                if (memberFavorite.data[key].favorite) {
                  this.$set(this.favCoinRows, typeSymbol, true);
                  break;
                } else {
                  this.$set(this.favCoinRows, typeSymbol, false);
                }
              } else {
                this.$set(this.favCoinRows, typeSymbol, false);
              }
            }
          }
        } else {
          this.$store.dispatch("logout");
        }
      });
    },
    updatePriceInfo(resp) {
      for (let i = 0; i < this.coinMarket.length; i++) {
        if (this.coinMarket[i].symbol == resp.symbol) {
          this.coinMarket[i].price = resp.close;
          this.coinMarket[i].pChg = resp.chg;
          if (resp.chg > 0) {
            this.coinMarket[i].color = "green";
          } else if (resp.chg < 0) {
            this.coinMarket[i].color = "red";
          }
          break;
        }
      }
      if (this.currentCoin.symbol == resp.symbol) {
        if (resp.chg > 0) {
          this.priceInfo.textColor = "green";
        } else if (resp.chg < 0) {
          this.priceInfo.textColor = "red";
        }
        this.priceInfo.chg = resp.change;
        this.priceInfo.high24 = resp.high;
        this.priceInfo.low24 = resp.low;
        this.priceInfo.vol24 = resp.volume;
        this.priceInfo.pChg = resp.chg;
      }
    },
    async selectMarketSymbol(url) {
      let pair = url.split("/").pop();
      this.setCurrentPair(pair);
      this.showBtcText = true;
      this.showSearchBox = false;
      this.coinSearchText = "";
      this.ticker.datarows = [];
      this.plate.askRows = [];
      this.plate.bidRows = [];
      if (url != this.$route.path) {
        this.$router.push(url);
      }
      await this.defaultSymbol();
      this.getCoinInfo().then(() => {
        if (this.isUserLogin) {
          this.getAssetWallet();
        }
        this.getSymbolThumb();
        this.getTickers();
        this.getBidAsk().then(() => {
          window.scrollTo(0, 0);
          let scrollToMe = this.$refs.scrollToMe;
          scrollToMe.scrollTo({ top: 250, behavior: "smooth" });
        });
      });
      this.updateDataTrade();
    },
    setCoinText() {
      setTimeout(() => {
        if (this.coinSearchText == "") {
          this.showBtcText = true;
        }
      }, 500);
    },
    selectedSellScore(price) {
      this.selected_sell_score = price;
      this.selected_buy_score = price;
    },
    selectFav(symbol) {
      if (this.isUserLogin == false) {
        let a = localStorage.getItem("fav");
        let c = [];
        if (a != null || a != undefined) {
          c = a.split(",");
        }
        if (this.favCoinRows[symbol]) {
          if (c != "") {
            for (let i = 0; i < c.length; i++) {
              if (symbol == c[i]) {
                c.splice(i, 1);
                let e = c.join();
                localStorage.setItem("fav", e);
                this.favCoinRows[symbol] = false;
                return false;
              }
            }
          } else {
            if (a == "") {
              let a = [];
              a = symbol;
              localStorage.setItem("fav", a);
              this.favCoinRows[symbol] = false;
              return false;
            } else {
              let a = [];
              a.push(symbol);
              let e = a.join();
              localStorage.setItem("fav", e);
              this.favCoinRows[symbol] = false;
              return false;
            }
          }
        } else {
          if (c != "") {
            if (a != null || a != undefined) {
              c.push(symbol);
              let d = c;
              let e = d.join();
              localStorage.setItem("fav", e);
            } else {
              let a = [];
              a.push(symbol);
              let e = a.join();
              localStorage.setItem("fav", e);
            }
          } else {
            if (a == "") {
              let a = [];
              a = symbol;
              localStorage.setItem("fav", a);
            } else {
              let a = [];
              a.push(symbol);
              let e = a.join();
              localStorage.setItem("fav", e);
            }
          }
          this.favCoinRows[symbol] = true;
          return false;
        }
      } else {
        if (this.favCoinRows[symbol]) {
          this.favCoinRows[symbol] = false;
        } else {
          this.favCoinRows[symbol] = true;
        }
        if (this.user_data?.id) {
          HTTP.post("/uc/member/update-member-favorite", {
            coinId: symbol,
            isFavorite: this.favCoinRows[symbol],
          }).then((response) => {
            if (response.data.code === 0) {
              this.getSymbolThumb();
            }
          });
        }
      }
    },
    getThemeIndicators(value) {
      if (typeof this.iframeContainer.iGetAllIndicators === "function") {
        const indicator = this.iframeContainer.iGetsettingIndicatorstheme(
          value,
          false
        );
        this.ichecksettingindicator(indicator);
        // this.triggerChildMethodForIndicator(indicator);
      }
    },
    triggerChildMethod(payload) {
      // Emit the custom event to trigger a method in the child component
      this.$emit("child-event", payload);
    },
    getChartSettings(event) {
      if (typeof this.iframeContainer.iGetChartSettings === "function") {
        const Theme = this.iframeContainer.iGetChartSettings();
        this.triggerChildMethod(Theme);
      }
    },
    triggerChildMethodForIndicator(payload) {
      // Emit the custom event to trigger a method in the child component
      this.$emit("child-event-indicator", payload);
    },
    ichecksettingindicator(payload) {
      // Emit the custom event to trigger a method in the child component
      this.$emit("child-setting-indicator", payload);
    },
    triggerChildMethodForActiveIndicator() {
      this.$emit("child-event-active-indicator", payload);
    },
    activeIndicatorDetails() {
      this.$emit("active-indicator-details", payload);
    },
    resetChartSettings() {
      if (typeof this.iframeContainer.iResetChartSettings === "function") {
        this.iframeContainer.iResetChartSettings();
      }
    },
    updateChartSettings(payload) {
      if (typeof this.iframeContainer.iUpdateChartSettings === "function") {
        this.iframeContainer.iUpdateChartSettings(payload);
      }
    },
    getIndicators() {
      if (typeof this.iframeContainer.iGetAllIndicators === "function") {
        const indicator = this.iframeContainer.iGetAllIndicators();
        this.triggerChildMethodForIndicator(indicator);
      }
    },
    sortCoin(value) {
      if (this.sortCoinValue == null) {
        if (value == "coin") {
          this.headCoinDown = !this.headCoinDown;
          this.coinMarket = this.coinMarket.sort((a, b) =>
            a.symbol.localeCompare(b.symbol)
          );
          this.sortSymbol = "asc";
          this.sortPrice = null;
          this.sortChg = null;
        } else if (value == "price") {
          this.headPriceDown = !this.headPriceDown;
          this.coinMarket = this.coinMarket.sort((a, b) => b.price - a.price);
          this.sortSymbol = null;
          this.sortPrice = "desc";
          this.sortChg = null;
        } else if (value == "chg") {
          this.headChgDown = !this.headChgDown;
          this.coinMarket = this.coinMarket.sort((a, b) => b.pChg - a.pChg);
          this.sortSymbol = null;
          this.sortPrice = null;
          this.sortChg = "desc";
        }
        this.sortCoinValue = value;
      } else {
        this.sortCoinValue = value;
        if (this.sortCoinValue == "coin") {
          this.headCoinDown = !this.headCoinDown;
          if (this.sortSymbol == "asc") {
            this.coinMarket = this.coinMarket.sort((a, b) =>
              b.symbol.localeCompare(a.symbol)
            );
          } else {
            this.coinMarket = this.coinMarket.sort((a, b) =>
              a.symbol.localeCompare(b.symbol)
            );
          }
          this.sortSymbol = this.sortSymbol == "asc" ? "desc" : "asc";
          this.sortPrice = null;
          this.sortChg = null;
        } else if (this.sortCoinValue == "price") {
          this.headPriceDown = !this.headPriceDown;
          if (this.sortPrice == "asc") {
            this.coinMarket = this.coinMarket.sort((a, b) => b.price - a.price);
          } else {
            this.coinMarket = this.coinMarket.sort((a, b) => a.price - b.price);
          }
          this.sortSymbol = null;
          this.sortPrice = this.sortPrice == "asc" ? "desc" : "asc";
          this.sortChg = null;
        } else if (this.sortCoinValue == "chg") {
          this.headChgDown = !this.headChgDown;
          if (this.sortChg == "asc") {
            this.coinMarket = this.coinMarket.sort((a, b) => b.pChg - a.pChg);
          } else {
            this.coinMarket = this.coinMarket.sort((a, b) => a.pChg - b.pChg);
          }
          this.sortSymbol = null;
          this.sortPrice = null;
          this.sortChg = this.sortChg == "asc" ? "desc" : "asc";
        }
      }
    },
    async fetchAssetWalletData() {
      try {
        const res = await HTTP.get("/uc/asset/wallet");
        return res.data.data;
      } catch (err) {
        console.error(err);
        throw err; // Rethrow the error to handle it outside the function if needed
      }
    },
    closeModalIndicators() {
      if (typeof this.iframeContainer.iCloseModalIndicators === "function") {
        this.iframeContainer.iCloseModalIndicators();
      }
    },
    async getAssetWallet() {
      const intervalTimes = [0, 1500, 3000, 4500]; // Define the interval times in milliseconds
      for (const interval of intervalTimes) {
        if (!this.getAssetWalletLoading) {
          this.getAssetWalletLoading = true;
        }
        try {
          const data = await this.fetchAssetWalletData();
          this.assetsWallet = data;
          this.assetsWallet.forEach((item, index) => {
            this.coinMarketScale.forEach((itemScale, indexScale) => {
              if (item.coin.unit == itemScale.symbol.split("/")[0]) {
                item.coinScale = itemScale.coinScale;
                item.bathScale = itemScale.bathScale;
              }
            });
          });
          // Uncomment the following line if you want to log the result
          // console.log("getAssetWallet =", this.assetsWallet);
        } catch (err) {
          // Handle errors if needed
        }
        setTimeout(() => {
          this.getAssetWalletLoading = false;
        }, interval);
      }
    },
    getCheckStatusModel() {
      EventBus.$emit("get-Reset-Model");
    },

    async getCoinInfo() {
      let self = this;
      await HTTP.post("/market/symbol-info-all")
        .then((res) => {
          self.coinMarketScale = [];
          res.data.forEach((item, index) => {
            if (item.coinSymbol == self.coinName) {
              //console.log("item=", item);
              self.currentCoin.fee = item.fee * 100;
              self.currentCoin.spreadOrder = item.spreadOrder;
              self.currentScale.coinScale =
                item.coinScale == 0 ? 8 : item.coinScale;
              self.currentScale.bathScale =
                item.baseCoinScale == 0 ? 2 : item.baseCoinScale;
              self.currentScale.minVolume = item.minVolume;
              self.currentScale.maxVolume = item.maxVolume;
              self.currentScale.symbol = item.symbol;
            }
            self.coinMarketScale.push({
              symbol: item.symbol,
              coinScale: item.coinScale,
              bathScale: item.baseCoinScale,
            });
          });
          //console.log("getCoinInfo=", self.coinMarketScale);
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    this.user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    this.defaultSymbol();
    this.getCoinInfo().then(() => {
      if (!this.user_data?.id) {
        this.isUserLogin = false;
      } else {
        this.isUserLogin = true;
        this.memberId = this.user_data.id;
        this.getAssetWallet();
      }
      this.getSymbolThumb();
      this.getTickers();
      this.getBidAsk().then(() => {
        window.scrollTo(0, 0);
        let scrollToMe = this.$refs.scrollToMe;
        scrollToMe.scrollTo({ top: 250, behavior: "smooth" });
      });
    });
  },
  mounted() {
    const domain = window.origin;
    var iframe = document.getElementById("myIframe");
    iframe.contentWindow.postMessage("hello", domain);
    iframe.onload = () => {
      if (iframe.contentWindow) {
        this.iframeContainer = iframe.contentWindow;
        // setTimeout(() => {
        //   console.log(111);
        //   this.changeTime("1", "d");
        // }, 2000);
      }
    };
    // console.log(this.$route.path)

    window.addEventListener("message", (event) => {
      if (event.origin !== domain) return;

      if (event.data.orderType == 0) {
        this.get_tradeonchart_buy(event.data);
      }
      if (event.data.orderType == 1) {
        this.get_tradeonchart_sell(event.data);
      }
      if (event.data.orderType == 2) {
        this.get_tradeonchart_sell(event.data);
      }
      if (event.data.orderType == 3) {
        this.get_tradeonchart_buy(event.data);
      }
      return false;
    });
    window.addEventListener("click", this.checkIfClickedOutside);

    this.updateDataTrade();
  },
  destroyed() {
    window.removeEventListener("click", this.checkIfClickedOutside);
    if (this.stompClient) {
      this.stompClient.disconnect();
    }
  },
};
</script>
<style>
@import url("https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,600&display=swap");
</style>
<style scoped>
.select {
  position: relative;
  width: 101px;
  height: 32px;
  border-radius: 2px;
  padding: 4px 8px;
  background-color: #191b1c;
  color: white;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.select .icon {
  position: absolute;
  /* top: 6px !important; */
  pointer-events: none;
  right: 8px !important;
}
.select .options {
  position: absolute;
  top: 42px;
  z-index: 10000;
  width: 100%;
  background-color: #191b1c;
  border-radius: 2px;
}
.select .options ul {
  list-style: none;
  padding: 0px;
  margin: 0px;
}
.select .options ul li {
  padding: 6px;
  cursor: pointer;
}
.select .options ul li:hover {
  background-color: #191b1c;
  border-radius: 2px;
}
</style>
<style lang="scss">
@font-face {
  font-family: iconFont;
  src: url("../../assets/icons/chart-icon/scx-icons/scx-icons.woff");
}
.chart-topbar {
  width: 99.6%;
  border-bottom: 1px solid #28363e;
  min-height: 44px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  row-gap: 5px;
  padding: 8px;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  .timeframe {
    border: 1px solid #28363e;
    border-radius: 4px;
    padding: 2px;
    margin-left: 8px;
    button {
      width: 38px;
      height: 22px;
      color: #677f8e;
      background-color: transparent;
      border: none;
    }
    button.active {
      color: white;
      background-color: #191b1c;
      border-radius: 2px;
    }
    .tf-sc {
      position: relative;
      display: inline;
      border-radius: 4px;
      .text {
        display: inline-block;
        width: 57.83px;
        color: #677f8e;
        padding-top: 3px;
        padding-bottom: 3px;
        padding-left: 3px;
        cursor: pointer;
      }
      span.active {
        color: white;
        background-color: #191b1c;
        border-radius: 2px;
      }
      svg {
        position: absolute;
        right: 5px;
        top: 50%;
        transform: translateY(-50%);
      }
      .options {
        position: absolute;
        left: 0;
        background-color: #191b1c;
        width: 100%;
        text-align: left;
        ul {
          padding: 0;
          margin: 0;
          li {
            display: flex;
            align-items: center;
            height: 30px;
            border-radius: 4px;
            padding-left: 5px;
            color: #677f8e;
            cursor: pointer;
            &:hover {
              color: white;
              background-color: #191b1c;
            }
          }
        }
      }
    }
  }
  .ic-sc {
    border: 1px solid #28363e;
    border-radius: 4px;
    margin-left: 8px;
    position: relative;
    width: 77px;
    height: 28px;
    .text {
      width: 100%;
      height: 100%;
      padding-left: 8px;
      padding-top: 6px;
      display: inline-block;
      color: #677f8e;
      cursor: pointer;
    }
    span.active {
      color: white;
      background-color: #191b1c;
      border-radius: 2px;
    }
    svg {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translateY(-50%);
    }
    .options {
      position: absolute;
      left: 0;
      background-color: #191b1c;
      width: 100%;
      text-align: left;
      ul {
        padding: 0;
        margin: 0;
        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          padding-left: 5px;
          color: #677f8e;
          &:hover {
            color: white;
            background-color: #191b1c;
          }
        }
      }
    }
  }
  .ctype-sc {
    border: 1px solid #28363e;
    border-radius: 4px;
    position: relative;
    width: 35px;
    padding-left: 5px;
    padding-right: 5px;
    padding-top: 5px;
    padding-bottom: 5px;
    .text {
      display: inline-block;
      color: #677f8e;
      font-family: iconFont;
      width: 100%;
      height: 100%;
      font-size: 13px;
      cursor: pointer;
    }
    span.active {
      color: white;
      background-color: #191b1c;
      border-radius: 2px;
    }
    svg {
      position: absolute;
      right: 4px;
      top: 50%;
      transform: translateY(-50%);
    }
    .options {
      position: absolute;
      left: 0;
      background-color: #191b1c;
      width: 172px;
      border-radius: 4px;
      text-align: left;
      ul {
        padding: 0;
        margin: 0;
        li {
          cursor: pointer;
          display: flex;
          align-items: center;
          height: 30px;
          border-radius: 4px;
          padding-left: 5px;
          color: #677f8e;
          &:hover {
            color: white;
            background-color: #191b1c;
          }
        }
      }
    }
  }
  .ct-alert {
    border: 1px solid #28363e;
    border-radius: 4px;
    position: relative;
    width: 60px;
    height: 28px;
    margin-left: 16px;
    padding-left: 12px;
    color: #677f8e;
    button {
      background-color: transparent;
      border: none;
      color: #677f8e;
      width: 100%;
      height: 100%;
    }
    svg {
      position: absolute;
      left: 5px;
      top: 5px;
    }
  }
  span.favorite,
  span.save,
  span.capture,
  span.expand,
  span.setting {
    color: #677f8e;
    border: 1px solid #28363e;
    border-radius: 4px;
    padding: 6px;
    margin-left: 8px;
    cursor: pointer;
    button {
      background-color: transparent;
      border: none;
      color: #677f8e;
    }
  }
  span.favorite {
    position: relative;
    padding-left: 25px;
    padding-right: 10px;
    svg {
      left: 8px;
      position: absolute;
    }
  }
  span.chart-icon {
    font-family: iconFont;
    padding-right: 10px;
  }
  span.bars::before {
    content: "\6a";
  }
  span.colored-bars::before {
    content: "\6f";
    color: green;
    margin-left: -6px;
  }
  span.colored-bars::after {
    content: "\6e";
    color: red;
  }
  span.candles::before {
    content: "\6b";
  }
  span.hollow-candles {
    transform: rotateY(180deg);
    right: 8px;
    position: relative;
  }
  span.hollow-candles::before {
    content: "\49";
  }
  span.heikin-ashi::before {
    content: "\48";
  }
  span.renko::before {
    content: "\35";
  }
  span.line-break::before {
    content: "\51";
  }
  span.kagi::before {
    content: "\4e";
  }
  span.line::before {
    content: "\4f";
  }
  span.mountain::before {
    content: "\53";
  }
  span.point-figure::before {
    content: "\57";
  }
}
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  border-radius: 20px;
}
::-webkit-scrollbar-track {
  background: #28363e;

  border-radius: 20px;
}
::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background: #3c515d;
}
::-webkit-scrollbar-thumb:hover {
  background: #d6dde1;
  border-radius: 20px;
}
.buy-sell {
  overflow-x: hidden;
  .top-search-row {
    padding: 12px 0;
    border-bottom: 1px solid #28363e;
    margin-right: -9px;
  }
  .search-container {
    position: relative;
    width: 100%;
    margin-left: 7px;
    .row {
      padding: 5px 0;
      &:hover {
        background-color: #28363e;
        cursor: pointer;
      }
    }
    .searching-box {
      position: absolute;
      width: 364px;
      height: 480px;
      overflow-x: hidden;
      overflow-y: scroll;
      background-color: #191b1c;
      top: 44px;
      z-index: 50;
      padding: 10px;
      .search-title {
        font-family: "Noto Sans Thai";
        font-style: normal;
        font-weight: 700;
        font-size: 16px;
        line-height: 24px;
        color: #d6dde1;
        margin-bottom: 7px;
        margin-top: 2px;
      }
      p {
        margin-bottom: 0;
      }
      .vol {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: #677f8e;
        width: 140px;
      }
      .coin-img {
        width: 24px;
        height: 24px;
      }
      .price {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
        color: #d6dde1;
      }
      .percent {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 20px;
      }
      .coin-text {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        color: #d6dde1;
        span.pair-text {
          font-family: "Roboto Flex";
          font-style: normal;
          font-weight: 400;
          font-size: 12px;
          line-height: 18px;
          color: #677f8e;
        }
      }
    }
    .coins-search-input {
      background-color: #191b1c;
      color: white;
      width: 219px;
      font-size: 18px;
      line-height: 24px;
      caret-color: #09be8b;
      font-weight: 600;
      border-radius: 4px !important;
      padding-top: 9px;
      padding-bottom: 7px;
      border: none;
    }
    .btc-text-true {
      padding-left: 40px;
    }
    .btc-text-false {
      padding-left: 40px;
    }
    .coins-search-input::placeholder {
      color: #d6dde1;
    }
    .search-svg {
      position: absolute;
      left: 186px;
      margin-right: 11px;
      margin-top: 8px;
      pointer-events: none;
    }
    .coin-image {
      position: absolute;
      font-size: 18px;
      left: 10px;
      top: 5px;
      pointer-events: none;
      .coin-img {
        width: 24px;
        height: 24px;
      }
      .btc-text {
        color: white;
        position: relative;
        top: 1.5px;
        margin-left: 7px;
        display: inline-block;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
      }
    }
  }
  .trading-scores {
    .live-amount-title {
      font-weight: 600;
      font-size: 18px;
      line-height: 24px;
    }
    .live-amount-title.red {
      color: #f72000;
    }
    .live-amount-title.green {
      color: rgb(0, 220, 0) #0c9;
    }
    .live-amount-value {
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-right: 8px;
    }
    .live-amount-value.red {
      color: #f72000;
    }
    .live-amount-value.green {
      color: #0c9;
    }
    .title {
      color: #9bacb6;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin-right: 2px;
    }
    .value {
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin-right: 10px;
    }
  }
}
.bs-left-table {
  border-right: 1px solid #28363e;
}
.bs-left-table table tr td {
  padding: 0;
  padding-bottom: 7px;
}
.buy-sell .col-md-9 {
  border-right: 1px solid #28363e;
}
.buy-sell header {
  border-bottom: 1px solid #28363e;
}
.buy-sell header ul {
  list-style: none;
  padding-top: 24px;
}
.buy-sell header ul li form .dropdown button {
  padding: 8px 12px;
  width: 219px;
  height: 40px;
  border: none;
  font-size: 18px;
  background: #191b1c;
  border-radius: 4px;
}
.buy-sell header ul li form .dropdown button i {
  margin-left: 70px;
  font-size: 18px;
  color: rgba(103, 127, 142, 1);
}
.buy-sell header ul li {
  display: inline-block;
}
.buy-sell header ul .red {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  align-items: center;
  color: #f72000;
}
.buy-sell header ul .red span {
  font-weight: 400;
  font-size: 14px;

  align-items: center;

  color: #f72000;
}
.buy-sell header ul li {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #9bacb6;
}
.buy-sell header ul li span {
  font-weight: 500;
  font-size: 14px;
  color: #d6dde1;
}
.buy-sell header ul input {
  align-items: center;
  padding: 8px 12px;
  width: 219px;
  height: 40px;
  border: none;
  background: #191b1c;
  border-radius: 4px;
}
.fav-table th {
  padding-left: 20px;
}
.fav-table td {
  position: relative;
  padding-left: 20px;
  span {
    position: absolute;
    left: 0;
    top: -2px;
  }
}
.buy-sell .col-md-9 .col-md-4 select {
  margin-top: 17px;
  width: 120px;
  height: 32px;
  background: #191b1c;
  border-radius: 2px;
  border: none;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 4px 8px;
  gap: 4px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.bid-offer-container {
  margin: 5px 13px;
  position: relative;

  span {
    position: absolute;
    pointer-events: none;
  }
  .ratioSelect {
    width: 80px;
    height: 32px;
    border-radius: 2px;
    padding: 4px 8px;
    background-color: #191b1c;
    color: white;
  }
  .coinSelect {
    width: 100px;
    height: 32px;
    border-radius: 2px;
    padding: 4px 8px;
    background-color: #191b1c;
    color: white;
  }
  select {
    color: white;
    background-color: #191b1c;
  }
  select:focus {
    outline: none !important;
    border: none !important;
  }
}
.all-coins-container {
  margin: 10px 5px;
  position: relative;
  width: 120px;

  span {
    position: absolute;
    pointer-events: none;
  }
  select {
    width: 100%;
    height: 32px;
    border-radius: 2px;
    padding: 4px 8px;
    background-color: #191b1c;
    color: white;
  }
}
.orange-text {
  color: #09be8b;
}
.buy-sell table {
  width: 98%;
  margin-bottom: 0;
  overflow: hidden;
}
.buy-sell table th {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #677f8e;
}
.buy-sell table td {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: rgb(255, 255, 255);
  svg {
    position: relative;
  }
}
.buy-sell table .red {
  color: rgb(400, 0, 0);
  cursor: pointer;
}
.buy-sell table .text-last-price {
  font-weight: 600;
  cursor: pointer;
}
.buy-sell table .green {
  color: rgb(0, 400, 0);
  cursor: pointer;
}
.buy-sell .col-md-3 select {
  margin-top: 17px;
  width: 120px;
  height: 32px;
  background: #191b1c;
  border-radius: 2px;
  border: none;
  padding: 4px 8px;
  color: #ffffff;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
.btc-ticker {
  margin: 0;
  margin-top: 20px;
  margin-left: 3px;
  padding: 0;
  font-size: 16px;
  font-weight: 600;
  line-height: 20px;
  color: white;
}
.buy-sell h3 {
  margin-top: 30px;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  align-items: center;
  color: #ffffff;
}
.stpsl-cb {
  input {
    background: #191b1c;
    border: 1px solid #3c515d;
    border-radius: 2px;
    height: 17px;
    width: 17px;
  }
  input:checked {
    background-color: #09be8b;
    border: 1px solid #3c515d;
  }
  span {
    display: inline-block;
    margin-left: 5px;
  }
}

#cancel-modal___BV_modal_content_,
#cancel-modal-condition___BV_modal_content_,
#cancel-modal-market-condition___BV_modal_content_,
#trailing-cancel-modal___BV_modal_content_,
#cancel-second-modal___BV_modal_content_,
#cancel-all-modal___BV_modal_content_,
#cancel-selected-modal___BV_modal_content_,
#trailing-cancel-selected-modal___BV_modal_content_,
#limitorder-buy-TPSLB___BV_modal_content_,
#TPSL-Confirmation___BV_modal_content_,
#TPSL-Confirmation-m___BV_modal_content_,
#limitorder-sell-TPSLB___BV_modal_content_,
#market-order-buy-TPSLB___BV_modal_content_,
#market-order-sell-TPSLB___BV_modal_content_,
#limitorder-TPSL___BV_modal_content_,
#limitorder-stop-loss___BV_modal_content_ {
  width: 390px;
  margin: auto;
  margin-top: 30%;
}

#limit-TPSL .modal-content .modal-body {
  width: 100%;
}
#limit-TPSL .top-col {
  border-bottom: 4px solid #0a0d10;
}
#limit-TPSL .modal-dialog .modal-content {
  background: #141b1f;
  border-radius: 8px;
  font-style: normal;
}
#limit-TPSL .modal-dialog .modal-content .modal-body {
  background: #141b1f;
  border-radius: 8px;
}
#limit-TPSL .modal-dialog .modal-content .modal-body p {
  color: #677f8e;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
#limit-TPSL .modal-dialog .modal-content .modal-body p .badge {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  background: #0c9;
  border-radius: 2px;
}
#limit-TPSL .modal-dialog .modal-content .modal-body p .span {
  margin-left: 30%;
}
#limit-TPSL input {
  background-color: #191b1c;
  border: 1px solid #3c515d;
  border-radius: 2px;
  padding: 0px;
  gap: 0px;
  height: 17px;
  width: 17px;
}
#limit-TPSL input:checked {
  background-color: #09be8b;
}
#limit-TPSL label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #d6dde1;
}
#limit-TPSL .top-btn {
  border: 1px solid #28363e;
  width: 100%;
}
#limit-TPSL .top-btn button {
  color: #677f8e;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border: none;
  text-align: center;
  height: 24px;
  top: 0;
  padding: 7px;
  margin-left: 0px;
}
#limit-TPSL .top-btn .active {
  background: #191b1c;
  border-radius: 2px;
  color: white;
  margin: 1px;
}
#limit-TPSL .second-one {
  border: none;
  width: 100%;
}
#limit-TPSL .second-one button {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 40px;
  text-align: center;
  background: #191b1c;
  color: #9bacb6;
  margin: 0.5px;
}
#limit-TPSL .second-one .padd {
  width: 200px;
}
#limit-TPSL .second-one .active {
  background: #38454d;
  border: none;
  color: white;
}
#limit-TPSL ul li {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9bacb6;
}
#limit-TPSL .border-col {
  border-bottom: 1px solid #28363e;
}
#limit-TPSL .submit {
  background: #0c9;
  border-radius: 4px;
  border: none;
  width: 255px;
  height: 40px;
  padding: 8px;
}
#limit-TPSL .modal-header {
  border: none;
}
#limit-TPSL .modal-header h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
#limit-TPSL .modal-header button {
  background: none;
}
#limit-stop .modal-content .modal-body {
  width: 100%;
}
#limit-stop .top-col {
  border-bottom: 4px solid #0a0d10;
}
#limit-stop .modal-dialog .modal-content {
  background: #141b1f;
  border-radius: 8px;
  font-style: normal;
}
#limit-stop .modal-dialog .modal-content .modal-body {
  background: #141b1f;
  border-radius: 8px;
}
#limit-stop .modal-dialog .modal-content .modal-body p {
  color: #677f8e;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
}
#limit-stop .modal-dialog .modal-content .modal-body p .badge {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  background: #f72000;
  border-radius: 2px;
}
#limit-stop .modal-dialog .modal-content .modal-body p .span {
  margin-left: 30%;
}
#limit-stop input {
  background-color: #191b1c;
  border: 1px solid #3c515d;
  border-radius: 2px;
  padding: 0px;
  gap: 0px;
  height: 17px;
  width: 17px;
}
#limit-stop input:checked {
  background-color: #09be8b;
}
#limit-stop label {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;

  color: #d6dde1;
}
#limit-stop .top-btn {
  border: 1px solid #28363e;
  width: 100%;
}
#limit-stop .top-btn button {
  color: #677f8e;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  border: none;
  text-align: center;
  height: 24px;
  top: 0;
  padding: 7px;
  margin-left: 0px;
}
#limit-stop .top-btn .active {
  background: #191b1c;
  border-radius: 2px;
  color: white;
  margin: 1px;
}
#limit-stop .second-one {
  border: none;
  width: 100%;
}
#limit-stop .second-one button {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  height: 40px;
  text-align: center;
  background: #191b1c;
  color: #9bacb6;
  margin: 0.5px;
}
#limit-stop .second-one .padd {
  width: 200px;
}
#limit-stop .second-one .active {
  background: #38454d;
  border: none;
  color: white;
}
#limit-stop ul li {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #9bacb6;
}
.green {
  color: #0c9;
}
.red {
  color: #f72000;
}
#limit-stop .border-col {
  border-bottom: 1px solid #28363e;
}
#limit-stop .submit {
  background: #0c9;
  border-radius: 4px;
  border: none;
  width: 255px;
  height: 40px;
  padding: 8px;
}
#limit-stop .modal-header {
  border: none;
}
#limit-stop .modal-header h5 {
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: #ffffff;
}
#limit-stop .modal-header button {
  background: none;
}
.buy-sell .chart {
  border-bottom: 1px solid #28363e;
}
.bs-right-table {
  border-left: 1px solid #28363e;
}
.bs-right-table table tr td {
  padding: 0;
  padding-bottom: 7px;
}
.yellow {
  color: #09be8b;
}
.active {
  color: #09be8b; /* สีเมื่อ active */
}
@media only screen and (min-width: 375px) {
  .trading-scores {
    margin-left: 16px;
  }
  .bs-table-s {
    height: 200px;
    overflow-y: scroll;
  }
  .bs-table-sb {
    height: 200px;
    overflow: auto;
  }
  .bs-table-l {
    height: 250px;
    overflow: auto;
  }
  .buy-sell .chart {
    height: 250px;
  }
  div.bs-right-table {
    padding: 0 25px;
  }
}
@media only screen and (min-width: 768px) {
  .trading-scores {
    margin-left: 0;
  }
  .bs-table-s {
    height: 404px;
    overflow-y: scroll;
  }
  .bs-table-sb {
    height: 444px;
    overflow: hidden;
  }
  .bs-table-l {
    height: 777px;
    overflow: auto;
    margin-left: 15px;
  }
  .buy-sell .chart {
    height: 226px;
  }
  div.bs-right-table {
    padding: 0 25px;
  }
}
@media only screen and (min-width: 848px) {
  div.bs-right-table {
    padding: 0 25px;
  }
  .bs-table-l {
    margin-left: 12px;
    margin-right: -10px;
  }
}
@media only screen and (max-width: 848px) {
  .pills-tab {
    padding-left: 30px !important;
  }
  .iframe-chart {
    padding: 0 30px;
  }
}
@media only screen and (min-width: 992px) {
  div.bs-right-table {
    padding: 0 5px;
    padding-right: 15px;
  }
}
@media only screen and (min-width: 1024px) {
  .bs-table-s {
    height: 404px;
    overflow-y: scroll;
  }
  .bs-table-l {
    height: 840px;
    overflow: auto;
  }
  .buy-sell .chart {
    height: 446px;
  }
  .buy-sell table .text-last-price {
    font-size: 18px;
  }
}
.colormiddle {
  color: white;
  text-decoration: none;
}

.colormiddle:hover {
  text-decoration: underline;
  text-decoration-color: rgb(9, 190, 139);
}
@media only screen and (min-width: 1200px) {
  .col-xl-4 {
    padding-left: 10px;
  }
  #maintrade.col-xl-9 {
    width: 73%;
  }
  .col-lg-4 {
    padding-left: 10px;
  }
}
@media only screen and (min-width: 1280px) {
  .bs-table-s {
    height: 404px;
    overflow-y: scroll;
  }
  .buy-sell .search-container {
    margin-left: 8px !important;
  }
  .iframe-chart {
    margin-left: 1.5px;
    height: 347px;
  }
}
@media only screen and (max-width: 1430px) {
  // span.favorite {
  //   display: none;
  // }
}
@media only screen and (min-width: 1536px) {
  .iframe-chart {
    height: 347px;
  }
}
.iframe-chart {
  width: 99%;
  margin-left: 1.5px;
}
</style>
