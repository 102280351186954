<template>
  <div>
    <div class="sells p-0">
      <div class="justify-content-center">
        <div
          class="col-md-12"
          style="border-top: 1px solid #28363e; margin-right: 3px"
        >
          <div class="menu-dynamic">
            <ul class="nav nav-ul">
              <li style="margin-left: 10px" class="nav-item">
                <button
                  style="margin-right: 24px"
                  type="button"
                  v-for="(tab, index) in ordertabs"
                  :key="index"
                  @click="selectedorder = tab"
                  :class="{ active: selectedorder === tab }"
                >
                  {{ tab }}
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-md-12 tab-content">
          <!-- Limit Order -->
          <div
            class="fade show active tab-pane"
            v-show="selectedorder === 'Limit Order'"
          >
            <div>
              <div class="row">
                <!-- limit buying start -->
                <div class="col-md-6">
                  <form
                    @submit.prevent="openLimitBuyConfirmationModal"
                    ref="limitbuyform"
                  >
                    <div class="form-group bs-lp-input">
                      <cleave
                        :options="options"
                        :class="limit_buy_bath === 0 ? 'c-dull' : 'c-white'"
                        v-model="limit_buy_bath"
                        placeholder="0.00"
                        required
                        @keyup.native="handleLimitBuyCash()"
                      ></cleave>
                      <span class="currency">{{ pairName }}</span>
                      <span class="text">จำนวนที่ต้องการซื้อ</span>
                    </div>
                    <div
                      class="input-group-bottom-text d-flex justify-content-between align-center"
                    >
                      <p>ยอดเงินที่ซื้อได้</p>
                      <p>
                        {{
                          $MyFunc.priceFormat(
                            $MyFunc.toFixedDigits(Thaibaht_balance, bathScale)
                          )
                        }}
                        {{ pairName }}
                      </p>
                    </div>
                    <!-- timeline start -->
                    <div class="timeline-bar">
                      <b-progress
                        height="2px"
                        :value="limit_buy_slide"
                        style="
                          margin: auto;
                          height: 2px;
                          background-color: #566a76;
                        "
                      >
                      </b-progress>
                      <div class="spots">
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(0, 'limitBuy')"
                        >
                          <span
                            class="dot align-self-baseline"
                            :class="{ active: limit_buy_slide >= 0 }"
                          ></span>
                          <span class="font-s">0%</span>
                        </div>
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(25, 'limitBuy')"
                        >
                          <span
                            class="dot"
                            :class="{ active: limit_buy_slide >= 25 }"
                          ></span>
                          <span class="font-s">25%</span>
                        </div>
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(50, 'limitBuy')"
                        >
                          <span
                            class="dot"
                            :class="{ active: limit_buy_slide >= 50 }"
                          ></span>
                          <span class="font-s">50%</span>
                        </div>
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(75, 'limitBuy')"
                        >
                          <span
                            class="dot"
                            :class="{ active: limit_buy_slide >= 75 }"
                          ></span>
                          <span class="font-s">75%</span>
                        </div>
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(100, 'limitBuy')"
                        >
                          <span
                            class="dot align-self-end"
                            :class="{ active: limit_buy_slide == 100 }"
                          ></span>
                          <span class="font-s">100%</span>
                        </div>
                      </div>
                    </div>
                    <!-- timeline end -->
                    <div class="form-group bs-lp-input">
                      <cleave
                        :options="options"
                        placeholder="0.00"
                        :class="limit_buy_score === 0 ? 'c-dull' : 'c-white'"
                        v-model="limit_buy_score"
                        required
                      ></cleave>

                      <span class="currency">{{ pairName }}</span>
                      <span class="text">ราคาต่อ {{ coinName }}</span>
                    </div>

                    <div class="form-group bs-lp-input">
                      <cleave
                        :options="options8decimal"
                        :class="limit_buy_coin === 0 ? 'c-dull' : 'c-white'"
                        type="text"
                        min="0"
                        v-model="limit_buy_coin"
                        placeholder="0.00"
                        @keyup.native="handleLimitBuyCoin()"
                      />

                      <span class="currency">{{ coinName }}</span>
                      <span class="text">จำนวน {{ coinName }} ที่จะได้รับ</span>
                    </div>
                    <div class="form-group form-check">
                      <label class="mb-0">
                        <!-- editte -->
                        <input
                          :class="profitLossLimit === 0 ? 'c-dull' : 'c-white'"
                          type="checkbox"
                          class="form-check-input"
                          value="false"
                          v-model="profitLossLimit"
                          :disabled="
                            this.limit_buy_bath <= 0 ||
                            this.limit_buy_score <= 0
                          "
                        />
                        <p>Take Profit / Stop Loss</p></label
                      >
                    </div>

                    <div class="form-group">
                      <button
                        type="submit"
                        class="buy-btn"
                        :disabled="isUserLogin == false"
                      >
                        <span v-if="isUserLogin">ซื้อ</span>
                        <span v-else>
                          <a href="/login" style="color: white !important"
                            >Login or Register Now</a
                          >
                        </span>
                      </button>
                      <p class="text-end p-0 m-0 fees">Fee {{ fee('limit_buy') }}%</p>
                    </div>
                  </form>
                </div>
                <!-- limit buying end -->
                <!-- limit selling start -->
                <div class="col-md-6">
                  <form
                    @submit.prevent="openLimitSellConfirmationModal"
                    ref="limitsellform"
                  >
                    <div class="form-group bs-lp-input">
                      <cleave
                        :options="options"
                        placeholder="0.00"
                        :class="limit_sell_bath === 0 ? 'c-dull' : 'c-white'"
                        v-model="limit_sell_bath"
                        required
                        @keyup.native="handleLimitSellCash()"
                      ></cleave>

                      <span class="currency">{{ pairName }}</span>
                      <span class="text">จำนวนที่ต้องการขาย</span>
                    </div>
                    <div
                      class="input-group-bottom-text d-flex justify-content-between align-center"
                    >
                      <p>มูลค่า {{ coinName }} ที่มี</p>
                      <p>
                        {{
                          $MyFunc.priceFormat(
                            $MyFunc.toFixedDigits(
                              CalculatedSellBalance,
                              bathScale
                            )
                          )
                        }}
                        {{ pairName }}
                      </p>
                    </div>
                    <!-- timeline start -->
                    <div class="timeline-bar">
                      <b-progress
                        height="2px"
                        :value="limit_sell_slide"
                        style="
                          margin: auto;
                          height: 2px;
                          background-color: #566a76;
                        "
                      >
                      </b-progress>
                      <div class="spots">
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(0, 'limitSell')"
                        >
                          <span
                            class="dot align-self-baseline"
                            :class="{ active: limit_sell_slide >= 0 }"
                          ></span>
                          <span class="font-s">0%</span>
                        </div>
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(25, 'limitSell')"
                        >
                          <span
                            class="dot"
                            :class="{ active: limit_sell_slide >= 25 }"
                          ></span>
                          <span class="font-s">25%</span>
                        </div>
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(50, 'limitSell')"
                        >
                          <span
                            class="dot"
                            :class="{ active: limit_sell_slide >= 50 }"
                          ></span>
                          <span class="font-s">50%</span>
                        </div>
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(75, 'limitSell')"
                        >
                          <span
                            class="dot"
                            :class="{ active: limit_sell_slide >= 75 }"
                          ></span>
                          <span class="font-s">75%</span>
                        </div>
                        <div
                          class="spot"
                          style="cursor: pointer"
                          @click="setSliderValues(100, 'limitSell')"
                        >
                          <span
                            class="dot align-self-end"
                            :class="{ active: limit_sell_slide == 100 }"
                          ></span>
                          <span class="font-s">100%</span>
                        </div>
                      </div>
                    </div>
                    <!-- timeline end -->
                    <div class="form-group bs-lp-input">
                      <cleave
                        :options="options"
                        placeholder="0.00"
                        :class="limit_sell_score === 0 ? 'c-dull' : 'c-white'"
                        v-model="limit_sell_score"
                        required
                      ></cleave>

                      <span class="currency">{{ pairName }}</span>
                      <span class="text">ราคาต่อ {{ coinName }}</span>
                    </div>

                    <div class="form-group bs-lp-input">
                      <cleave
                        :options="options8decimal"
                        :class="limit_sell_coin === 0 ? 'c-dull' : 'c-white'"
                        min="0"
                        placeholder="0.00"
                        v-model="limit_sell_coin"
                        @keyup.native="handleLimitSellCoin()"
                      />
                      <span class="currency">{{ coinName }}</span>
                      <span class="text">จำนวน {{ coinName }} ที่จะขาย</span>
                    </div>
                    <div class="form-group form-check">
                      <label class="mb-0">
                        <input
                          :class="SL === 0 ? 'c-dull' : 'c-white'"
                          type="checkbox"
                          class="form-check-input"
                          value="false"
                          v-model="slTut"
                          :disabled="
                            this.limit_sell_bath <= 0 ||
                            this.limit_sell_score <= 0
                          "
                        />
                        <p>Stop Loss</p>
                      </label>
                    </div>
                    <div class="form-group">
                      <button
                        type="submit"
                        class="sell-btn"
                        :disabled="isUserLogin == false"
                      >
                        <span v-if="isUserLogin">ขาย</span>
                        <span v-else>
                          <a href="/login" style="color: white !important"
                            >Login or Register Now</a
                          >
                        </span>
                      </button>
                      <p class="text-end p-0 m-0 fees">Fee {{ fee('limit_sell') }}%</p>
                    </div>
                  </form>
                </div>
                <!-- limit selling end -->
              </div>
            </div>
          </div>

          <!-- Market Order -->
          <div
            class="fade show active tab-pane"
            v-show="selectedorder === 'Market Order'"
          >
            <div class="row">
              <!-- market buying start -->
              <div class="col-md-6">
                <form @submit.prevent="openMarketBuyConfirmationModal">
                  <div class="form-group bs-lp-input">
                    <cleave
                      :options="options"
                      placeholder="0.00"
                      :class="market_buy_bath === 0 ? 'c-dull' : 'c-white'"
                      v-model="market_buy_bath"
                      required
                      @keyup.native="handleMarketBuyCash()"
                    ></cleave>
                    <span class="currency">{{ pairName }}</span>
                    <span class="text">จำนวนที่ต้องการซื้อ</span>
                  </div>
                  <div
                    class="input-group-bottom-text d-flex justify-content-between align-center"
                  >
                    <p>ยอดเงินที่ซื้อได้</p>
                    <p>
                      {{
                        $MyFunc.priceFormat(
                          $MyFunc.toFixedDigits(Thaibaht_balance, bathScale)
                        )
                      }}
                      {{ pairName }}
                    </p>
                  </div>
                  <!-- timeline start -->
                  <div class="timeline-bar">
                    <b-progress
                      height="2px"
                      :value="market_buy_slide"
                      style="
                        margin: auto;
                        height: 2px;
                        background-color: #566a76;
                      "
                    >
                    </b-progress>
                    <div class="spots">
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(0, 'marketBuy')"
                      >
                        <span
                          class="dot align-self-baseline"
                          :class="{ active: market_buy_slide >= 0 }"
                        ></span>
                        <span class="font-s">0%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(25, 'marketBuy')"
                      >
                        <span
                          class="dot"
                          :class="{ active: market_buy_slide >= 25 }"
                        ></span>
                        <span class="font-s">25%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(50, 'marketBuy')"
                      >
                        <span
                          class="dot"
                          :class="{ active: market_buy_slide >= 50 }"
                        ></span>
                        <span class="font-s">50%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(75, 'marketBuy')"
                      >
                        <span
                          class="dot"
                          :class="{ active: market_buy_slide >= 75 }"
                        ></span>
                        <span class="font-s">75%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(100, 'marketBuy')"
                      >
                        <span
                          class="dot align-self-end"
                          :class="{ active: market_buy_slide == 100 }"
                        ></span>
                        <span class="font-s">100%</span>
                      </div>
                    </div>
                  </div>
                  <!-- timeline end -->
                  <div class="form-group bs-lp-input">
                    <input
                      style="
                        padding: 10px 9px 8px 100px;
                        font-family: noto sans thai;
                      "
                      class="c-wt"
                      type="text"
                      :value="
                        market_text
                          ? 'Market Price (ซื้อราคาตลาด)'
                          : 'Market Price'
                      "
                      readonly
                    />
                    <!-- <span class="currency">{{pairName}}</span> -->
                    <span class="text">ราคาต่อ {{ coinName }}</span>
                  </div>
                  <div class="form-group bs-lp-input">
                    <cleave
                      :options="options8decimal"
                      :class="market_buy_coin === 0 ? 'c-dull' : 'c-white'"
                      type="text"
                      min="0"
                      v-model="market_buy_coin"
                      placeholder="0.00"
                      @keyup.native="handleMarketBuyCoin()"
                    />
                    <span class="currency">{{ coinName }}</span>
                    <span class="text">จำนวน {{ coinName }} ที่จะได้รับ</span>
                  </div>

                  <div class="form-group form-check">
                    <label class="mb-0">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        :disabled="this.market_buy_bath <= 0"
                        v-model="profitLossMarket"
                      />
                      <p>Take Profit / Stop Loss</p>
                    </label>
                  </div>

                  <div class="form-group">
                    <button
                      type="submit"
                      class="buy-btn"
                      data-toggle="modal"
                      :disabled="isUserLogin == false"
                    >
                      <span v-if="isUserLogin">ซื้อ</span>
                      <span v-else>
                        <a href="/login" style="color: white !important"
                          >Login or Register Now</a
                        >
                      </span>
                    </button>
                    <p class="text-end p-0 m-0 fees">Fee {{ fee('market_buy') }}%</p>
                  </div>
                </form>
              </div>
              <!-- market buying end -->
              <!-- market selling start -->
              <div class="col-md-6">
                <form @submit.prevent="openMarketSellConfirmationModal">
                  <div class="form-group bs-lp-input">
                    <cleave
                      :options="options"
                      placeholder="0.00"
                      :class="market_sell_bath === 0 ? 'c-dull' : 'c-white'"
                      v-model="market_sell_bath"
                      required
                      @keyup.native="handlemarketSellCash()"
                    ></cleave>

                    <span class="currency">{{ pairName }}</span>
                    <span class="text">จำนวนที่ต้องการขาย</span>
                  </div>
                  <div
                    class="input-group-bottom-text d-flex justify-content-between align-center"
                  >
                    <p>มูลค่า {{ coinName }} ที่มี</p>
                    <p>
                      {{
                        $MyFunc.priceFormat(
                          $MyFunc.toFixedDigits(
                            CalculatedSellBalance,
                            bathScale
                          )
                        )
                      }}
                      {{ pairName }}
                    </p>
                  </div>
                  <!-- timeline start -->
                  <div class="timeline-bar">
                    <b-progress
                      height="2px"
                      :value="market_sell_slide"
                      style="
                        margin: auto;
                        height: 2px;
                        background-color: #566a76;
                      "
                    >
                    </b-progress>
                    <div class="spots">
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(0, 'marketSell')"
                      >
                        <span
                          class="dot align-self-baseline"
                          :class="{ active: market_sell_slide >= 0 }"
                        ></span>
                        <span class="font-s">0%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(25, 'marketSell')"
                      >
                        <span
                          class="dot"
                          :class="{ active: market_sell_slide >= 25 }"
                        ></span>
                        <span class="font-s">25%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(50, 'marketSell')"
                      >
                        <span
                          class="dot"
                          :class="{ active: market_sell_slide >= 50 }"
                        ></span>
                        <span class="font-s">50%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(75, 'marketSell')"
                      >
                        <span
                          class="dot"
                          :class="{ active: market_sell_slide >= 75 }"
                        ></span>
                        <span class="font-s">75%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(100, 'marketSell')"
                      >
                        <span
                          class="dot align-self-end"
                          :class="{ active: market_sell_slide == 100 }"
                        ></span>
                        <span class="font-s">100%</span>
                      </div>
                    </div>
                  </div>
                  <!-- timeline end -->
                  <div class="form-group bs-lp-input">
                    <input
                      style="
                        padding: 10px 9px 8px 100px;
                        font-family: noto sans thai;
                      "
                      class="c-wt"
                      type="text"
                      :value="
                        market_text
                          ? 'Market Price (ขายราคาตลาด)'
                          : 'Market Price'
                      "
                      readonly
                    />
                    <!-- <span class="currency">{{pairName}}</span> -->
                    <span class="text">ราคาต่อ {{ coinName }}</span>
                  </div>
                  <div class="form-group bs-lp-input">
                    <cleave
                      :options="options8decimal"
                      :class="market_sell_coin === 0 ? 'c-dull' : 'c-white'"
                      min="0"
                      placeholder="0.00"
                      v-model="market_sell_coin"
                      @keyup.native="handlemarketSellCoin()"
                    />
                    <span class="currency">{{ coinName }}</span>
                    <span class="text">จำนวน {{ coinName }} ที่จะขาย</span>
                  </div>
                  <div style="height: 42px"></div>
                  <!-- 
                    <label class="mb-0">
                      <input
                        @click="showStopLoss = true"
                        type="checkbox"
                        class="form-check-input"
                        v-model="slTutMarket"
                        :disabled="market_sell_bath <= 0"
                      />
                      <p>Stop Loss</p>
                    </label>
                  -->

                  <div class="form-group">
                    <button
                      type="submit"
                      class="sell-btn"
                      :disabled="isUserLogin == false"
                    >
                      <span v-if="isUserLogin">ขาย</span>
                      <span v-else>
                        <a href="/login" style="color: white !important"
                          >Login or Register Now</a
                        >
                      </span>
                    </button>
                    <p class="text-end p-0 m-0 fees">Fee {{ fee('market_sell') }}%</p>
                  </div>
                </form>
              </div>
              <!-- market selling end -->
            </div>
          </div>

          <!-- Trailing Stop -->
          <div
            class="fade show active tab-pane"
            v-show="selectedorder === 'Trailing Stop'"
          >
            <div class="row">
              <div class="col-md-6">
                <form @submit.prevent="createTrailingBuyOrder">
                  <div class="form-group bs-lp-input">
                    <cleave
                      :options="options"
                      placeholder="0.00"
                      :class="
                        trailing_buying_value === 0 ? 'c-dull' : 'c-white'
                      "
                      v-model="trailing_buying_value"
                      required
                    ></cleave>
                    <!-- <input
                    :class="trailing_buying_value === 0 ? 'c-dull' : 'c-white'"
                    type="number"
                    placeholder="0.00"
                    v-model="trailing_buying_value"
                  /> -->
                    <span class="currency">{{ pairName }}</span>
                    <span class="text">จำนวนที่ต้องการซื้อ</span>
                  </div>
                  <div
                    class="input-group-bottom-text d-flex justify-content-between align-center"
                  >
                    <p>ยอดเงินที่ซื้อได้</p>
                    <p>
                      {{
                        $MyFunc.priceFormat(
                          $MyFunc.toFixedDigits(Thaibaht_balance, bathScale)
                        )
                      }}
                      {{ pairName }}
                    </p>
                  </div>
                  <!-- timeline start -->
                  <div class="timeline-bar">
                    <b-progress
                      height="2px"
                      :value="trailing_buy_slide"
                      style="
                        margin: auto;
                        height: 2px;
                        background-color: #566a76;
                      "
                    >
                    </b-progress>
                    <div class="spots">
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(0, 'trailingBuy')"
                      >
                        <span
                          class="dot align-self-baseline"
                          :class="{ active: trailing_buy_slide >= 0 }"
                        ></span>
                        <span class="font-s">0%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(25, 'trailingBuy')"
                      >
                        <span
                          class="dot"
                          :class="{ active: trailing_buy_slide >= 25 }"
                        ></span>
                        <span class="font-s">25%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(50, 'trailingBuy')"
                      >
                        <span
                          class="dot"
                          :class="{ active: trailing_buy_slide >= 50 }"
                        ></span>
                        <span class="font-s">50%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(75, 'trailingBuy')"
                      >
                        <span
                          class="dot"
                          :class="{ active: trailing_buy_slide >= 75 }"
                        ></span>
                        <span class="font-s">75%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(100, 'trailingBuy')"
                      >
                        <span
                          class="dot align-self-end"
                          :class="{
                            active: trailing_buy_slide == 100,
                          }"
                        ></span>
                        <span class="font-s">100%</span>
                      </div>
                    </div>
                  </div>
                  <!-- timeline end -->
                  <div class="form-group bs-lp-input">
                    <cleave
                      :options="options"
                      placeholder="0.00"
                      class="sml"
                      :class="trailingBuyPrice === 0 ? 'c-dull' : 'c-white'"
                      v-model="trailingBuyPrice"
                      :disabled="trailingBuyImmediately"
                      required
                    ></cleave>
                    <span class="currency">{{ pairName }}</span>
                    <span class="text">ราคาที่เริ่มให้ระบบทำงาน</span>
                  </div>
                  <div class="form-group form-check" style="margin-top: -10px">
                    <label style="margin: 0; padding: 0">
                      <input
                        type="checkbox"
                        v-model="trailingBuyImmediately"
                        @input="checkTrailingBuyImmediately"
                        class="form-check-input c-white"
                      />
                      <p style="margin: 0 0 10px 0; padding: 0">
                        ระบบทำงานทันทีที่ราคาปัจจุบัน
                      </p>
                    </label>
                  </div>

                  <div class="form-group bs-lp-input">
                    <input
                      placeholder="+0.00"
                      :class="trailingStepBuy === 0 ? 'c-dull' : 'c-white'"
                      v-model="trailingStepBuy"
                      style="padding-right: 60px"
                      maxlength="16"
                      required
                      @blur="handleInputBuy"
                      @input="handleInputBuyInput"
                    />
                    <div
                      class="select-container-1"
                      tabindex="0"
                      @click="
                        trailingConditionBuyDroptown =
                          !trailingConditionBuyDroptown
                      "
                      @blur="trailingConditionBuyDroptown = false"
                    >
                      <div style="display: inline" class="text">
                        {{ tcbOption }}
                      </div>
                      <div style="display: inline" class="icon">
                        <svg
                          width="7"
                          height="4"
                          viewBox="0 0 7 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.333984 0.666748L3.66732 4.00008L7.00065 0.666748H0.333984Z"
                            fill="#677F8E"
                          />
                        </svg>
                      </div>
                      <div
                        class="options"
                        :class="
                          trailingConditionBuyDroptown ? 'show' : 'hidden'
                        "
                      >
                        <ul>
                          <li
                            @click="
                              tcbOption = 'THB';
                              trailingStepBuy = null;
                            "
                          >
                            THB
                          </li>
                          <li
                            @click="
                              tcbOption = '%';
                              trailingStepBuy = null;
                            "
                          >
                            %
                          </li>
                        </ul>
                      </div>
                    </div>

                    <span class="text" style="pointer-events: all"
                      >ตั้งเงื่อนไข Trailing</span
                    >
                  </div>

                  <div
                    class="form-group mb-1.5 bs-lp-input"
                    style="margin-top: 27px"
                  >
                    <input type="text" readonly style="padding-right: 60px" />
                    <div
                      class="select-container-3"
                      tabindex="0"
                      @click="
                        trailingExpirationDateBuyDroptown =
                          !trailingExpirationDateBuyDroptown
                      "
                      @blur="trailingExpirationDateBuyDroptown = false"
                    >
                      <div style="display: inline" class="text">
                        {{ tedbOption }}
                      </div>
                      <div style="display: inline" class="icon">
                        <svg
                          width="7"
                          height="4"
                          viewBox="0 0 7 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.333984 0.666748L3.66732 4.00008L7.00065 0.666748H0.333984Z"
                            fill="#677F8E"
                          />
                        </svg>
                      </div>
                      <div
                        class="options"
                        :class="
                          trailingExpirationDateBuyDroptown ? 'show' : 'hidden'
                        "
                      >
                        <ul>
                          <li @click="tedbOption = '5 วัน'">5 วัน</li>
                          <li @click="tedbOption = '10 วัน'">10 วัน</li>
                          <li @click="tedbOption = '1 เดือน'">1 เดือน</li>
                          <li @click="tedbOption = '3 เดือน'">3 เดือน</li>
                          <li @click="tedbOption = '6 เดือน'">6 เดือน</li>
                        </ul>
                      </div>
                    </div>
                    <span class="text">ระยะเวลา</span>
                  </div>

                  <div class="form-group">
                    <button
                      v-b-modal.limitorder-TPSLB
                      @click="show_TPSLB_order = true"
                      type="submit"
                      class="buy-btn"
                      data-toggle="modal"
                      :disabled="isUserLogin == false"
                    >
                      <span v-if="isUserLogin">ซื้อ</span>
                      <span v-else>
                        <a href="/login" style="color: white !important"
                          >Login or Register Now</a
                        >
                      </span>
                    </button>
                    <p class="text-end p-0 m-0 fees">Fee {{ fee }}%</p>
                  </div>
                </form>
              </div>
              <div class="col-md-6">
                <form @submit.prevent="createTrailingSellOrder">
                  <div class="form-group bs-lp-input">
                    <cleave
                      :options="options"
                      placeholder="0.00"
                      :class="
                        trailing_selling_value === 0 ? 'c-dull' : 'c-white'
                      "
                      v-model="trailing_selling_value"
                      required
                    ></cleave>
                    <!-- <input
                    :class="trailing_selling_value === 0 ? 'c-dull' : 'c-white'"
                    type="number"
                    placeholder="0.00"
                    v-model="trailing_selling_value"
                  /> -->
                    <span class="currency">{{ pairName }}</span>
                    <span class="text">จำนวนที่ต้องการขาย</span>
                  </div>
                  <div
                    class="input-group-bottom-text d-flex justify-content-between align-center"
                  >
                    <p>มูลค่า {{ coinName }} ที่มี</p>
                    <p>
                      {{
                        $MyFunc.priceFormat(
                          $MyFunc.toFixedDigits(
                            CalculatedSellBalance,
                            bathScale
                          )
                        )
                      }}
                      {{ pairName }}
                    </p>
                  </div>
                  <!-- timeline start -->
                  <div class="timeline-bar">
                    <b-progress
                      height="2px"
                      :value="trailing_sell_slide"
                      style="
                        margin: auto;
                        height: 2px;
                        background-color: #566a76;
                      "
                    >
                    </b-progress>
                    <div class="spots">
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(0, 'trailingSell')"
                      >
                        <span
                          class="dot align-self-baseline"
                          :class="{ active: trailing_sell_slide >= 0 }"
                        ></span>
                        <span class="font-s">0%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(25, 'trailingSell')"
                      >
                        <span
                          class="dot"
                          :class="{
                            active: trailing_sell_slide >= 25,
                          }"
                        ></span>
                        <span class="font-s">25%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(50, 'trailingSell')"
                      >
                        <span
                          class="dot"
                          :class="{
                            active: trailing_sell_slide >= 50,
                          }"
                        ></span>
                        <span class="font-s">50%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(75, 'trailingSell')"
                      >
                        <span
                          class="dot"
                          :class="{
                            active: trailing_sell_slide >= 75,
                          }"
                        ></span>
                        <span class="font-s">75%</span>
                      </div>
                      <div
                        class="spot"
                        style="cursor: pointer"
                        @click="setSliderValues(100, 'trailingSell')"
                      >
                        <span
                          class="dot align-self-end"
                          :class="{
                            active: trailing_sell_slide == 100,
                          }"
                        ></span>
                        <span class="font-s">100%</span>
                      </div>
                    </div>
                  </div>
                  <!-- timeline end -->
                  <div class="form-group bs-lp-input">
                    <cleave
                      :options="options"
                      placeholder="0.00"
                      class="sml"
                      :class="trailingSellPrice === 0 ? 'c-dull' : 'c-white'"
                      v-model="trailingSellPrice"
                      :disabled="trailingSellImmediately"
                      required
                    ></cleave>
                    <span class="currency">{{ pairName }}</span>
                    <span class="text">ราคาที่เริ่มให้ระบบทำงาน</span>
                  </div>
                  <div class="form-group form-check" style="margin-top: -10px">
                    <label style="margin: 0; padding: 0">
                      <input
                        type="checkbox"
                        v-model="trailingSellImmediately"
                        @input="checkTrailingSellImmediately"
                        class="form-check-input c-white"
                      />
                      <p style="margin: 0 0 10px 0; padding: 0">
                        ระบบทำงานทันทีที่ราคาปัจจุบัน
                      </p>
                    </label>
                  </div>

                  <div class="form-group bs-lp-input">
                    <input
                      placeholder="-0.00"
                      :class="trailingStepSell === 0 ? 'c-dull' : 'c-white'"
                      v-model="trailingStepSell"
                      style="padding-right: 60px"
                      maxlength="16"
                      required
                      @blur="handleInputSell"
                      @input="handleInputSellInput"
                    />
                    <div
                      class="select-container-2"
                      tabindex="0"
                      @click="
                        trailingConditionSellDroptown =
                          !trailingConditionSellDroptown
                      "
                      @blur="trailingConditionSellDroptown = false"
                    >
                      <div style="display: inline" class="text">
                        {{ tcsOption }}
                      </div>
                      <div style="display: inline" class="icon">
                        <svg
                          width="7"
                          height="4"
                          viewBox="0 0 7 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.333984 0.666748L3.66732 4.00008L7.00065 0.666748H0.333984Z"
                            fill="#677F8E"
                          />
                        </svg>
                      </div>
                      <div
                        class="options"
                        :class="
                          trailingConditionSellDroptown ? 'show' : 'hidden'
                        "
                      >
                        <ul>
                          <li @click="tcsOption = 'THB'">THB</li>
                          <li @click="tcsOption = '%'">%</li>
                        </ul>
                      </div>
                    </div>
                    <span class="text">ตั้งเงื่อนไข Trailing</span>
                  </div>
                  <div
                    class="form-group form-check"
                    :class="SL ? 'in-h-off' : 'in-h-on'"
                    style="margin-top: -10px"
                  >
                    <label style="margin: 0; padding: 0">
                      <input
                        type="checkbox"
                        class="form-check-input"
                        data-toggle="modal"
                        data-target="#limit-stop"
                        value="false"
                        v-model="SL"
                      />
                      <p style="margin: 0 0 10px 0; padding: 0">
                        ตั้ง Stop Loss
                      </p>
                    </label>
                  </div>
                  <div class="form-group bs-lp-input" v-if="SL">
                    <cleave
                      :options="options"
                      placeholder="0.00"
                      class="sml"
                      :class="!trailingSL ? 'c-dull' : 'c-white'"
                      v-model="trailingSL"
                    ></cleave>
                    <!-- <input
                    type="number"
                    oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                    maxlength="12"
                    class="sml"
                    :class="!trailingSL ? 'c-dull' : 'c-white'"
                    v-model="trailingSL"
                  /> -->
                    <span class="currency">{{ pairName }}</span>
                    <span class="text">ตั้ง Stop Loss</span>
                  </div>
                  <div class="form-group bs-lp-input">
                    <input type="text" readonly style="padding-right: 60px" />
                    <div
                      class="select-container-3"
                      tabindex="0"
                      @click="
                        trailingExpirationDateSellDroptown =
                          !trailingExpirationDateSellDroptown
                      "
                      @blur="trailingExpirationDateSellDroptown = false"
                    >
                      <div style="display: inline" class="text">
                        {{ tedsOption }}
                      </div>
                      <div style="display: inline" class="icon">
                        <svg
                          width="7"
                          height="4"
                          viewBox="0 0 7 4"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M0.333984 0.666748L3.66732 4.00008L7.00065 0.666748H0.333984Z"
                            fill="#677F8E"
                          />
                        </svg>
                      </div>
                      <div
                        class="options"
                        :class="
                          trailingExpirationDateSellDroptown ? 'show' : 'hidden'
                        "
                      >
                        <ul>
                          <li @click="tedsOption = '5 วัน'">5 วัน</li>
                          <li @click="tedsOption = '10 วัน'">10 วัน</li>
                          <li @click="tedsOption = '1 เดือน'">1 เดือน</li>
                          <li @click="tedsOption = '3 เดือน'">3 เดือน</li>
                          <li @click="tedsOption = '6 เดือน'">6 เดือน</li>
                        </ul>
                      </div>
                    </div>
                    <span class="text">ระยะเวลา</span>
                  </div>
                  <div class="form-group">
                    <button
                      v-b-modal.limitorder-TPSLB
                      @click="show_TPSLB_order = true"
                      type="submit"
                      class="sell-btn"
                      :disabled="isUserLogin == false"
                    >
                      <span v-if="isUserLogin">ขาย</span>
                      <span v-else>
                        <a href="/login" style="color: white !important"
                          >Login or Register Now</a
                        >
                      </span>
                    </button>
                    <p class="text-end p-0 m-0 fees">Fee {{ fee }}%</p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LimitBuyConfirmation
      :coinName="coinName"
      :pairName="pairName"
      :updated_selected_buy_score="limit_buy_score"
      :buying_amount="limit_buy_bath"
      :limitBuy="limit_buy_coin"
      :btn_disable="btn_disable"
      :showPin="showPin"
      @submit="submitLimitBuy"
      @lbPin="lbConfirmationPin = $event"
    />
    <LimitSellConfirmation
      :coinName="coinName"
      :pairName="pairName"
      :updated_selected_sell_score="limit_sell_score"
      :selling_amount="limit_sell_bath"
      :slTut="slTut"
      :limitSlData="limitSlData"
      :btn_disable="btn_disable"
      :showPin="showPin"
      :limitSell="limit_sell_coin"
      @submit="submitLimitSell"
      @lsPin="lsConfirmationPin = $event"
    />
    <MarketBuyConfirmation
      :coinName="coinName"
      :pairName="pairName"
      :coinCurrentValue="coinCurrentValue"
      :market_buying_value="market_buy_bath"
      :marketBuy="market_buy_coin"
      :btn_disable="btn_disable"
      :showPin="showPin"
      @submit="submitMarketBuy"
      @mbPin="mbConfirmationPin = $event"
    />
    <MarketSellConfirmation
      :coinName="coinName"
      :pairName="pairName"
      :coinCurrentValue="coinCurrentValue"
      :slTutMarket="slTutMarket"
      :marketSlData="marketSlData"
      :market_selling_value="market_sell_bath"
      :marketSell="market_sell_coin"
      :showPin="showPin"
      :btn_disable="btn_disable"
      @submit="submitMarketSell"
      @msPin="msConfirmationPin = $event"
    />
    <TpslLimitBuyConfirmation
      :coinName="coinName"
      :pairName="pairName"
      :updated_selected_buy_score="limit_buy_score"
      :buying_amount="limit_buy_bath"
      :limitBuy="limit_buy_coin"
      :limitTpslData="limitTpslData"
      :showPin="showPin"
      @submit="submitTPSLLimitBuy"
      @tpsllbPin="tpslLimitBuyOrderPin = $event"
    />
    <TpslMarketBuyConfirmation
      :coinName="coinName"
      :pairName="pairName"
      :coinCurrentValue="coinCurrentValue"
      :market_buying_value="market_buy_bath"
      :marketBuy="market_buy_coin"
      :marketTpslData="marketTpslData"
      :showPin="showPin"
      @submit="submitTPSLMarketBuy"
      @tpslmbPin="tpslMarketBuyOrderPin = $event"
    />
    <!-- TP/SL tutorial modal -->
    <b-modal
      id="limitorder-TPSL"
      :hide-footer="true"
      :hide-header="true"
      centered
    >
      <div class="row">
        <div class="col-12 modal-th">
          <h5>
            Take Profit / Stop Loss
            <span class="cursor-pointer" style="right: 70px;" >
              <svg
                width="20"
                height="21"
                viewBox="0 0 20 21"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <span
              @click="$bvModal.hide('limitorder-TPSL')"
              style="position: absolute; right: 30px; cursor: pointer"
              ><svg
                width="14"
                height="15"
                viewBox="0 0 14 15"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
          </h5>
        </div>
      </div>

      <div class="row body thai-font">
        <div class="col-md-12" style="padding: 0 16px">
          <p style="margin-bottom: 16px; color: rgb(255, 255, 255)">
            ตั้งคำสั่งซื้อขายอัตโนมัติเพื่อ Take Profit หรือ Stop Loss
            เมื่อถึงเงื่อนไขที่กำหนด
          </p>
          <a
            @click="to_link_video('pdf')"
            href="javascript:void(0);"
            class="d-inline"
            style="
              width: 107px;
              height: 24px;
              margin-right: 16px;
              color: #09BE8B;
              line-height: 24px;
            "
          >
            อ่านคู่มือการใช้งาน
        </a>
          <p
            class="d-inline"
            style="
              width: 24;
              height: 24px;
              margin-right: 16px;
              line-height: 24px;
              color: #677f8e;
            "
          >
            หรือ
          </p>
          <a
            @click="to_link_video('video')"
            href="javascript:void(0);"
            class="d-inline"
            style="
              width: 99px;
              height: 24px;
              margin-right: 16px;
              color: #09BE8B;
              line-height: 24px;
            "
          >
            ดูวิดีโอสอนใช้งาน
        </a>

          <div
            class="form-group form-check"
            style="margin-top: 24px !important"
          >
            <label class="checkbox-input m-0">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="tpslTut"
              />
              <span style="line-height: 24px; color: #9bacb6"
                >ไม่ต้องแจ้งเตือนอีก</span
              ></label
            >
          </div>

          <button
            @click="handelTPSLTut()"
            type="button"
            class="tpsl-btn text-center"
          >
            เริ่มต้นใช้งาน
          </button>
        </div>
      </div>
    </b-modal>
    <b-modal
      id="limitorder-stop-loss"
      :hide-footer="true"
      :hide-header="true"
      centered
    >
      <div class="row">
        <div class="col-12 modal-th">
          <h5>Stop Loss</h5>
          <span class="cursor-pointer" style="right: 130px !important">
            <svg
              width="20"
              height="21"
              viewBox="0 0 20 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                fill="#677F8E"
              />
            </svg>
          </span>
          <span
            style="position: absolute; right: 30px; cursor: pointer"
            @click="$bvModal.hide('limitorder-stop-loss')"
          >
            <svg
              width="14"
              height="15"
              viewBox="0 0 14 15"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
                fill="#677F8E"
              />
            </svg>
          </span>
        </div>
      </div>

      <div class="row body thai-font">
        <div class="col-md-12">
          <p style="margin-bottom: 16px; color: rgb(255, 255, 255)">
            ตั้งค่าคำสั่งขายอัตโนมัติเพื่อ Stop Loss เมื่อถึงเงื่อนไขที่เรากำหนด
          </p>
          <a
            href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Apr/TPSL-SELL-_Manual_FDA_PC.pdf" 
            target="_blank"
            class="d-inline"
            style="
              width: 107px;
              height: 24px;
              margin-right: 16px;
              color: #09BE8B;
              line-height: 19px;
            "
          >
            อ่านคู่มือการใช้งาน
        </a>
          <p
            class="d-inline"
            style="
              width: 24;
              height: 24px;
              margin-right: 16px;
              line-height: 19px;
            "
          >
            หรือ
          </p>
          <a
            href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Video/2024/Mar/FDA-Tutorial-VDO-TP-SL-Buy_PC.mp4" 
            target="_blank"
            class="d-inline"
            style="
              width: 99px;
              height: 24px;
              margin-right: 16px;
              color: #09BE8B;
              line-height: 19px;
            "
          >
            ดูวิดีโอสอนใช้งาน
        </a>
          <div class="form-group form-check mt-3">
            <label class="checkbox-input">
              <input
                type="checkbox"
                class="form-check-input"
                v-model="showSlTut"
              />
              <span style="line-height: 24px">ไม่ต้องแจ้งเตือนอีก</span>
            </label>
          </div>

          <button
            @click="handelSLTut()"
            type="button"
            class="tpsl-btn text-center"
          >
            เริ่มต้นใช้งาน
          </button>
        </div>
      </div>
    </b-modal>
    <TpslLimit
      :coinCurrentValue="limit_buy_score"
      :coinName="coinName"
      :pairName="pairName"
      :amount="limit_buy_bath"
      :all_coin="limit_buy_coin"
      :totalCoin="limitBuy"
      :currentScale="currentScale"
      @tpsl="limitTpslData = $event"
      @event="openconfirmationTPSLLimitBuy"
    />
    <TpslMarket
      :coinCurrentValue="coinCurrentValue"
      :coinName="coinName"
      :pairName="pairName"
      :amount="market_buy_bath"
      :all_coin="market_buy_coin"
      :totalCoin="marketBuy"
      :currentScale="currentScale"
      @tpsl="marketTpslData = $event"
      @event="openconfirmationTPSLMarketBuy"
    />
    <SlLimit
      :coinName="coinName"
      :pairName="pairName"
      :amount="limit_sell_bath"
      :all_coin="limit_sell_coin"
      :coinCurrentValue="limit_sell_score"
      :totalCoin="limitSell"
      :currentScale="currentScale"
      @SLevent="openconfirmationLimitSell"
      @sl="limitSlData = $event"
    />
    <SlMarket
      :coinName="coinName"
      :pairName="pairName"
      :amount="market_sell_bath"
      :all_coin="market_sell_coin"
      :coinCurrentValue="coinCurrentValue"
      :totalCoin="marketSell"
      :currentScale="currentScale"
      @SLevent="openConfirmationMarketSell"
      @sl="marketSlData = $event"
    />
    <TrailingStopBuyConfirmation
      :coinName="coinName"
      :pairName="pairName"
      :trailingBuyPrice="trailingBuyPrice"
      :trailing_buying_value="trailing_buying_value"
      :btn_disable="btn_disable"
      :showPin="showPin"
      @submit="submitTrailingStopBuy"
      @lbPin="TSBCConfirmationPin = $event"
    />
    <TrailingStopSellConfirmation
      :coinName="coinName"
      :pairName="pairName"
      :trailingSellPrice="trailingSellPrice"
      :trailing_selling_value="trailing_selling_value"
      :btn_disable="btn_disable"
      :showPin="showPin"
      @submit="submitTrailingStopSell"
      @lbPin="TSSCConfirmationPin = $event"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../config/axios";
import { mapMutations } from "vuex";
import moment from "moment";
import TpslLimit from "./TpslLimit.vue";
import TpslMarket from "./TpslMarket.vue";
import SlLimit from "./SlLimit.vue";
import SlMarket from "./SlMarket.vue";
import LimitBuyConfirmation from "./LimitBuyConfirmation.vue";
import LimitSellConfirmation from "./LimitSellConfirmation.vue";
import MarketBuyConfirmation from "./MarketBuyConfirmation.vue";
import MarketSellConfirmation from "./MarketSellConfirmation.vue";
import TpslLimitBuyConfirmation from "./TpslLimitBuyConfirmation.vue";
import TpslMarketBuyConfirmation from "./TpslMarketBuyConfirmation.vue";

import TrailingStopBuyConfirmation from "./TrailingStopBuyConfirmation.vue";
import TrailingStopSellConfirmation from "./TrailingStopSellConfirmation.vue";

import EventBus from "../../../event-bus";
export default {
  name: "ExchangeOrder",
  components: {
    TpslLimit,
    SlLimit,
    TpslMarket,
    SlMarket,
    LimitBuyConfirmation,
    LimitSellConfirmation,
    MarketBuyConfirmation,
    MarketSellConfirmation,
    TpslLimitBuyConfirmation,
    TpslMarketBuyConfirmation,
    TrailingStopBuyConfirmation,
    TrailingStopSellConfirmation,
  },
  props: [
    "selected_buy_score",
    "selected_sell_score",
    "coinCurrntSell",
    "coinCurrntBuy",
    "coinCurrentValue",
    "currentCoin",
    "currentScale",
    "assetsWallet",
  ],
  data() {
    return {
      is_submit:false,
      stompClient: null,
      memberId: null,
      isUserLogin: false,
      deleyAssets: false,
      SL: false,
      showSlTut: false,
      profitLossLimit: false,
      profitLossMarket: false,
      systemBuySellLock: false,

      limitBuyType: 0,
      limit_buy_bath: null,
      limit_buy_score: 0,
      limit_buy_slide: 0,
      limit_buy_coin: null,
      limitSellType: 0,
      limit_sell_bath: null,
      limit_sell_score: 0,
      limit_sell_slide: 0,
      limit_sell_coin: null,
      marketBuyType: 0,
      market_buy_bath: null,
      market_buy_slide: 0,
      market_buy_coin: null,
      marketSellType: 0,
      market_sell_bath: null,
      market_sell_slide: 0,
      market_sell_coin: null,

      trailing_buying_value: null,
      trailing_buy_slide: 0,
      trailing_selling_value: null,
      trailing_sell_slide: 0,

      trailingBuyPrice: null,
      trailingStepBuy: null,
      trailingStepSell: null,
      trailingSellPrice: null,
      trailingSL: null,

      ordertabs: ["Limit Order", "Market Order"],
      selectedorder: "Limit Order",
      trailingConditionBuyDroptown: false,
      trailingExpirationDateBuyDroptown: false,
      trailingConditionSellDroptown: false,
      trailingExpirationDateSellDroptown: false,
      trailingBuyImmediately: false,
      trailingSellImmediately: false,
      tcbOption: "THB",
      tcsOption: "THB",
      tedbOption: "1 เดือน",
      tedsOption: "1 เดือน",

      btn_disable: false,
      Thaibaht_balance: 0,
      limitSellBalance: 0,
      market_text: false,
      coinName: "BTC",
      pairName: "THB",
      tpslTut: false,
      slTut: false,
      slTutMarket: false,
      limitTpslData: {},
      limitSlData: {},
      marketSlData: {},
      marketTpslData: {},
      tpslLimitBuyOrderPin: null,
      tpslMarketBuyOrderPin: null,
      lbConfirmationPin: null,
      lsConfirmationPin: null,
      mbConfirmationPin: null,
      msConfirmationPin: null,
      TSBCConfirmationPin: null,
      TSSCConfirmationPin: null,
      exchangeorderID: null,

      checkLimitBuyOneHundred: false,
      checkLimitSellOneHundred: false,
      checkMarketBuyOneHundred: false,
      checkMarketSellOneHundred: false,

      orderConfirm: false,
      tcbTrailingStepType: null,
      tcsTrailingStepType: null,
      reslockStatusBuy: false,
      reslockStatusSell: false,

      order: null,
      ModalNoti: [],

      commissions: [],
    };
  },
  watch: {
    "currentScale": {
      handler(newSymbol, oldSymbol) {
        this.minvolume_buy = newSymbol.minVolume
        this.maxvolume_buy = newSymbol.maxVolume
      },
      immediate: true,
    },
    "currentCoin.symbol": {
      handler(newSymbol, oldSymbol) {
        this.coinName = newSymbol.split("/")[0];
        this.pairName = newSymbol.split("/")[1];
      },
      immediate: true,
    },
    assetsWallet: {
      handler: function (val, oldVal) {
        this.getThbBalance();
      },
      immediate: true,
    },
    limit_buy_bath(value) {
      this.limit_buy_slide = this.calculatePercentage(
        this.Thaibaht_balance,
        value
      );
    },
    limit_sell_bath(value) {
      this.limit_sell_slide = this.calculatePercentage(
        this.CalculatedSellBalance,
        value
      );
    },
    market_buy_bath(value) {
      this.market_buy_slide = this.calculatePercentage(
        this.Thaibaht_balance,
        value
      );
    },
    market_sell_bath(value) {
      this.market_sell_slide = this.calculatePercentage(
        this.CalculatedSellBalance,
        value
      );
    },
    trailing_buying_value(value) {
      this.trailing_buy_slide = this.calculatePercentage(
        this.Thaibaht_balance,
        value
      );
    },
    trailing_selling_value(value) {
      this.trailing_sell_slide = this.calculatePercentage(
        this.CalculatedSellBalance,
        value
      );
    },
    profitLossLimit() {
      if (this.profitLossLimit) {
        let showTut = localStorage.getItem("TPSLTut");
        if (showTut) {
          EventBus.$emit("update-model-limitorder-STPSL");
          this.$bvModal.show("limitorder-STPSL");
        } else {
          EventBus.$emit("update-model-limitorder-STPSL");
          let ChekModalNoti = this.$store.state.chekModalNoti;

          if (ChekModalNoti) {
            let num = false;
            for (const item of ChekModalNoti) {
              console.log("ChekModalNoti.forEach=>", item);
              if (item.order == "limitTPSL" || item.order == "marketTPSL") {
                num = true;
                break;
              }
            }
            if (num) {
              this.$bvModal.show("limitorder-STPSL");
            } else {
              this.$bvModal.show("limitorder-TPSL");
              this.order = "limitTPSL";
            }
          } else {
            this.$bvModal.show("limitorder-TPSL");
            this.order = "limitTPSL";
          }
        }
      }
    },
    profitLossMarket() {
      if (this.profitLossMarket) {
        let showTut = localStorage.getItem("TPSLTut");
        if (showTut) {
          EventBus.$emit("update-model-limitorder-STPSL-market");
          this.$bvModal.show("limitorder-STPSL-market");
        } else {
          EventBus.$emit("update-model-limitorder-STPSL-market");
          let ChekModalNoti = this.$store.state.chekModalNoti;

          if (ChekModalNoti) {
            let num = false;
            for (const item of ChekModalNoti) {
              if (item.order == "limitTPSL" || item.order == "marketTPSL") {
                num = true;
                break;
              }
            }
            if (num) {
              this.$bvModal.show("limitorder-STPSL-market");
            } else {
              this.$bvModal.show("limitorder-TPSL");
              this.order = "marketTPSL";
            }
          } else {
            this.$bvModal.show("limitorder-TPSL");
            this.order = "marketTPSL";
          }
        }
      }
    },
    slTut(value) {
      if (value) {
        let showTut = localStorage.getItem("SLTut");
        if (showTut) {
          EventBus.$emit("update-model-limitorder-SSL");
          this.$bvModal.show("limitorder-SSL");
        } else {
          EventBus.$emit("update-model-limitorder-SSL");
          let ChekModalNoti = this.$store.state.chekModalNoti;
          if (ChekModalNoti) {
            let num = false;
            for (const item of ChekModalNoti) {
              if (item.order == "limitSL" || item.order == "marketSL") {
                num = true;
                break;
              }
            }
            if (num) {
              this.$bvModal.show("limitorder-SSL");
            } else {
              this.$bvModal.show("limitorder-stop-loss");
              this.order = "limitSL";
            }
          } else {
            this.$bvModal.show("limitorder-stop-loss");
            this.order = "limitSL";
          }
        }
      }
    },
    slTutMarket(value) {
      if (value) {
        let showTut = localStorage.getItem("SLTut");
        if (showTut) {
          EventBus.$emit("update-model-limitorder-SSLM");
          this.$bvModal.show("limitorder-SSLM");
        } else {
          EventBus.$emit("update-model-limitorder-SSLM");
          let ChekModalNoti = this.$store.state.chekModalNoti;
          if (ChekModalNoti) {
            let num = false;
            for (const item of ChekModalNoti) {
              if (item.order == "limitSL" || item.order == "marketSL") {
                num = true;
                break;
              }
            }
            if (num) {
              this.$bvModal.show("limitorder-SSLM");
            } else {
              this.$bvModal.show("limitorder-stop-loss");
              this.order = "marketSL";
            }
          } else {
            this.$bvModal.show("limitorder-stop-loss");
            this.order = "marketSL";
          }
        }
      }
    },
    SL(value) {
      if (value) {
        let showTut = localStorage.getItem("SLTut");
        if (!showTut) {
          this.$bvModal.show("limitorder-stop-loss");
        }
      }
    },
    formattedSellBalance() {
    return this.$MyFunc.priceFormat(
      this.$MyFunc.toFixedDigits(this.CalculatedSellBalance, this.bathScale)
    );
  },
    selected_buy_score(newValue) {
      this.limit_buy_score = newValue
      this.limit_buy_score = this.$MyFunc.NumberFormat(this.limit_buy_score,this.bathScale)
    },
    selected_sell_score(newValue) {
      this.limit_sell_score = newValue
      this.limit_sell_score = this.$MyFunc.NumberFormat(this.limit_sell_score,this.bathScale)
    },
    updated_selected_sell_score(value) {
      this.$emit("updateSellScore", value);
    },
    updated_selected_buy_score(value) {
      this.$emit("updateBuyScore", value);
    },
  },
  computed: {
    showPin() {
      let userpin = localStorage.getItem("user-pin");
      if (userpin) {
        return userpin;
      } else {
        return false;
      }
    },
    limitBuy() {
      if (this.limit_buy_score == 0) {
        this.limit_buy_bath = "";
        this.limit_buy_coin = "";
      } else if (this.limit_buy_score > 0) {
        if (this.limitBuyType == 1) {
          let result = this.limit_buy_bath / this.limit_buy_score;
          result = this.$MyFunc.mathFloorPrice(result, this.coinScale);
          this.limit_buy_coin =
            result > 0 && this.limit_buy_bath > 0 ? result : 0;
        } else if (this.limitBuyType == 2) {
          let result = this.limit_buy_coin * this.limit_buy_score;
          result = this.$MyFunc.mathFloorPrice(result, this.coinScale);
          this.limit_buy_bath =
            result >= 0 && this.limit_buy_coin > 0 ? result : 0;
        }
      }
    },
    limitSell() {
      if (this.limit_sell_score == 0) {
        this.limit_sell_bath = "";
        this.limit_sell_coin = "";
      } else if (this.limit_sell_score > 0) {
        if (this.limitSellType == 1) {
          let result = this.limit_sell_bath / this.limit_sell_score;
          result = this.$MyFunc.mathFloorPrice(result, this.coinScale);
          this.limit_sell_coin =
            result > 0 && this.limit_sell_bath > 0 ? result : 0;
        } else if (this.limitSellType == 2) {
          let result = (this.limitSellBalance * this.limit_sell_slide) / 100;
          result = this.$MyFunc.mathFloorPrice(result, this.coinScale);
          this.limit_sell_coin =
            result > 0 && this.limitSellBalance > 0 ? result : 0;
        } else if (this.limitSellType == 3) {
          let result = this.limit_sell_coin * this.limit_sell_score;
          result = this.$MyFunc.mathFloorPrice(result, this.coinScale);
          this.limit_sell_bath =
            result > 0 && this.limit_sell_coin > 0 ? result : 0;
        }
      }
    },
    marketBuy() {
      if (this.coinCurrentValue == 0) {
        this.market_buy_bath = "";
        this.market_buy_coin = "";
      } else if (this.coinCurrentValue > 0) {
        let result = 0;
        if (this.marketBuyType == 1) {
        
          if (this.spreadOrder > 0) {
            result =
              this.market_buy_bath /
              (this.coinCurrntBuy * (this.spreadOrder / 100 + 1));
          } else {
            result = this.market_buy_bath / this.coinCurrntBuy;
          }
          result = this.$MyFunc.mathFloorPrice(result, this.coinScale);
          this.market_buy_coin =
            result > 0 && this.market_buy_bath > 0 ? result : 0;
        } else if (this.marketBuyType == 2) {
          let result = this.$MyFunc.mathFloorPrice(
            this.coinCurrntBuy * this.market_buy_coin,
            this.coinScale
          );
          this.market_buy_bath =  result > 0 && this.market_buy_coin > 0 ? result : 0;
        }
      }
    },
    marketSell() {
      if (this.coinCurrentValue == 0) {
        this.market_sell_bath = "";
        this.market_sell_coin = "";
      } else if (this.coinCurrentValue > 0) {
        if (this.marketSellType == 1) {
          let result = this.market_sell_bath / this.coinCurrntSell;
          result = this.$MyFunc.mathFloorPrice(result, this.coinScale);
          this.market_sell_coin =
            result > 0 && this.market_sell_bath > 0 ? result : 0;
        } else if (this.marketSellType == 2) {
          let result = this.$MyFunc.mathFloorPrice(
            this.limitSellBalance * (this.market_sell_slide / 100),
            this.coinScale
          );
          this.market_sell_coin = result > 0 ? result : 0;
        } else if (this.marketSellType == 3) {
          let result = this.market_sell_coin * this.coinCurrntSell;
          result = this.$MyFunc.mathFloorPrice(result, this.coinScale);
          this.market_sell_bath =
            result > 0 && this.market_sell_coin > 0 ? result : 0;
        }
      }
    },
    CalculatedSellBalance() {
      let SellBalance = this.coinCurrentValue * this.limitSellBalance;
      if (SellBalance > 10) {
        return SellBalance;
      } else {
        return 0;
      }
    },
    coinScale() {
      return this.currentScale.coinScale;
    },
    bathScale() {
      return this.currentScale.bathScale;
    },
    spreadOrder() {
      return this.currentCoin.spreadOrder;
    },
    options() {
      this.limit_buy_bath = "";
      return {
        numeral: true,
        numeralIntegerScale: 14,
        numeralDecimalScale: this.currentScale.bathScale,
        numeralThousandsGroupStyle: "thousand",
        numeralPositiveOnly: true,
      };
    },
    optionConditionTrailingStepSell() {
      return {
        numeral: true,
        numeralIntegerScale: 14,
        numeralDecimalScale: this.currentScale.bathScale,
        numeralThousandsGroupStyle: "thousand",
      };
    },
    options8decimal() {
      return {
        numeral: true,
        numeralIntegerScale: 14,
        numeralDecimalScale: this.currentScale.coinScale,
        numeralPositiveOnly: true,
      };
    },
  },
  methods: {
    ...mapMutations(["setLimitOrderData"]),
    ...mapMutations(["setChekModalNoti"]),
    ...mapMutations(["setChkOrderConfirm"]),
    washSellCheck(type, price) {
      let myHistory = this.$store.state.limitOrderData;
      let myCheck = true;
      myHistory.forEach((item) => {
        if (item.direction == 1 && type == 0) {
          if (item.price == price) {
            myCheck = false;
          }
        } else if (!item.direction && type == 1) {
          if (item.price == price) {
            myCheck = false;
          }
        }
      });
      return myCheck;
    },
    calculatedBuyTimestamp() {
      let curTime = moment().unix();
      if (this.tedbOption === "5 วัน") {
        curTime = moment().add(5, "days").unix();
      } else if (this.tedbOption === "10 วัน") {
        curTime = moment().add(10, "days").unix();
      } else if (this.tedbOption === "1 เดือน") {
        curTime = moment().add(1, "months").unix();
      } else if (this.tedbOption === "3 เดือน") {
        curTime = moment().add(3, "months").unix();
      } else if (this.tedbOption === "6 เดือน") {
        curTime = moment().add(6, "months").unix();
      }
      return curTime;
    },
    calculatedSellTimestamp() {
      let curTime = moment().unix();
      if (this.tedsOption === "5 วัน") {
        curTime = moment().add(5, "days").unix();
      } else if (this.tedsOption === "10 วัน") {
        curTime = moment().add(10, "days").unix();
      } else if (this.tedsOption === "1 เดือน") {
        curTime = moment().add(1, "months").unix();
      } else if (this.tedsOption === "3 เดือน") {
        curTime = moment().add(3, "months").unix();
      } else if (this.tedsOption === "6 เดือน") {
        curTime = moment().add(6, "months").unix();
      }
      return curTime;
    },
    handleInputBuy() {
      setTimeout(() => {
        if (this.trailingStepBuy) {
          let trailingBuy = parseFloat(
            this.trailingStepBuy.replace(/[^+\d.]/g, "")
          );
          if (this.tcbOption == "%") {
            if (trailingBuy > 100) {
              this.trailingStepBuy = "+100.00";
            } else if (trailingBuy < 0) {
              this.trailingStepBuy = "";
            } else {
              this.trailingStepBuy =
                "+" + this.$MyFunc.NumberFormat(trailingBuy, 2);
            }
          }
          if (this.tcbOption === "THB") {
            if (isNaN(trailingBuy)) {
              this.trailingStepBuy = "";
            } else if (trailingBuy == 0) {
              this.trailingStepBuy = "";
            } else {
              this.trailingStepBuy =
                "+" + this.$MyFunc.NumberFormat(trailingBuy, this.bathScale);
            }
          }
        } else {
          this.trailingStepBuy = "";
        }
      }, 100);
    },
    handleInputSell() {
      setTimeout(() => {
        if (this.trailingStepSell) {
          let trailingSell = parseFloat(
            this.trailingStepSell.replace(/[^-\d.]/g, "")
          );
          console.log("trailingSell=>", trailingSell);
          if (this.tcsOption == "%") {
            if (trailingSell > 100) {
              this.trailingStepSell = "-100.00";
            } else {
              if (this.trailingStepSell.startsWith("-")) {
                this.trailingStepSell = this.$MyFunc.NumberFormat(
                  trailingSell,
                  2
                );
              } else {
                this.trailingStepSell =
                  "-" + this.$MyFunc.NumberFormat(trailingSell, 2);
              }
            }
          }
          if (this.tcsOption == "THB") {
            if (isNaN(trailingSell)) {
              this.trailingStepSell = "";
            } else if (trailingSell == 0) {
              this.trailingStepSell = "";
            } else {
              if (this.trailingStepSell.startsWith("-")) {
                this.trailingStepSell = this.$MyFunc.NumberFormat(
                  trailingSell,
                  this.bathScale
                );
              } else {
                this.trailingStepSell =
                  "-" + this.$MyFunc.NumberFormat(trailingSell, this.bathScale);
              }
            }
          }
        } else {
          this.trailingStepSell = "";
        }
      }, 100);
    },
    handleInputBuyInput(e) {
      this.trailingStepBuy = e.target.value.replace(/[^+\d.]/g, "");
    },
    handleInputSellInput(e) {
      this.trailingStepSell = e.target.value.replace(/[^-\d.]/g, "");
    },
    handelTPSLTut() {
      if (this.tpslTut) {
        localStorage.setItem("TPSLTut", true);
      }
      if (this.order) {
        this.ModalNoti.push(this.order);
        this.setChekModalNoti(this.ModalNoti);
      }

      this.$bvModal.hide("limitorder-TPSL");
      if (this.order == "limitTPSL") {
        this.$bvModal.show("limitorder-STPSL");
      } else {
        this.$bvModal.show("limitorder-STPSL-market");
      }
    },
    handelSLTut() {
      if (this.showSlTut) {
        localStorage.setItem("SLTut", true);
      }
      if (this.order) {
        this.ModalNoti.push(this.order);
        this.setChekModalNoti(this.ModalNoti);
      }

      this.$bvModal.hide("limitorder-stop-loss");
      if (!this.SL) {
        if (this.order == "limitSL") {
          this.$bvModal.show("limitorder-SSL");
        } else {
          this.$bvModal.show("limitorder-SSLM");
        }
      }
    },
    submitOrder(orderCreationMethod, modalId, pin) {
      HTTP.post("/uc/validate-pin", { pin })
        .then((res) => {
          if (res.data.code === 0) {
            this[orderCreationMethod]();
            this.$bvModal.hide(modalId);
          } else if (res.data.code === 4000){
            this.$store.dispatch("logout");
          }else {
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },

    submitTPSLLimitBuy() {
      this.submitOrder(
        "createLimitBuyOrder",
        "TPSL-Confirmation",
        this.tpslLimitBuyOrderPin
      );
    },
    submitTPSLMarketBuy() {
      this.submitOrder(
        "createMarketBuyOrder",
        "TPSL-Confirmation-m",
        this.tpslMarketBuyOrderPin
      );
    },
    submitLimitBuy() {
      this.submitOrder(
        "createLimitBuyOrder",
        "limitorder-buy-TPSLB",
        this.lbConfirmationPin
      );
    },
    submitLimitSell() {
      this.submitOrder(
        "createLimitSellOrder",
        "limitorder-sell-TPSLB",
        this.lsConfirmationPin
      );
    },
    submitMarketBuy() {
      this.submitOrder(
        "createMarketBuyOrder",
        "market-order-buy-TPSLB",
        this.mbConfirmationPin
      );
    },
    submitMarketSell() {
      this.submitOrder(
        "createMarketSellOrder",
        "market-order-sell-TPSLB",
        this.msConfirmationPin
      );
    },
    submitTrailingStopBuy() {
      this.submitOrder(
        "sendTrailingStopBuy",
        "TSBC-buy-modal",
        this.TSBCConfirmationPin
      );
    },
    submitTrailingStopSell() {
      this.submitOrder(
        "sendTrailingStopSell",
        "TSBC-sell-modal",
        this.TSSCConfirmationPin
      );
    },
    to_link_video(value){
      if(this.selectedorder == "Limit Order"){
        if(value == "pdf"){
          var url_pdf = "https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Apr/TPSL-BUY_Manual_FDA_PC.pdf";
            window.open(url_pdf, '_blank');
        }
        if(value == "video"){
          var url_video = "https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Video/2024/Mar/FDA-Tutorial-VDO-TP-SL-Buy_PC.mp4";
            window.open(url_video, '_blank');
        }
      }
      if(this.selectedorder == "Market Order"){
        if(value == "pdf"){
          var url_pdf = "https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/3-MarketOrderTakeProfitStop-Loss_Manual_for_PC.pdf";
            window.open(url_pdf, '_blank');
        }
        if(value == "video"){
          var url_video = "https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Video/2024/Mar/FDA-Tutorial-VDO-Market-Order_PC.mp4";
            window.open(url_video, '_blank');
        }
      }
    },
    openconfirmationTPSLLimitBuy(isOpen) {
      if (isOpen) {
        EventBus.$emit("update-model-tpsl-confirmation");
      }
    },
    openconfirmationTPSLMarketBuy(isOpen) {
      if (isOpen) {
        EventBus.$emit("update-model-tpsl-confirmation-m");
      }
    },
    openconfirmationLimitSell(isOpen) {
      if (isOpen) {
        EventBus.$emit("update-model-limitorder-sell-tpslb");
      }
    },
    openConfirmationMarketSell(isOpen) {
      if (isOpen) {
        EventBus.$emit("update-model-market-order-sell-tpslb");
      }
    },
    openLimitBuyConfirmationModal() {
      if (parseFloat(this.limit_buy_coin) < this.currentScale.minVolume) {
          return this.notify(
            "error",
            "Notification",
            "คุณซื้อเหรียญน้อยกว่าที่กำหนดไว้ ซื้อขั้นต่ำ " +  this.$MyFunc.NumberFormat(this.currentScale.minVolume,8)  + " " + this.coinName
        );
      } else if (parseFloat(this.limit_buy_coin) > this.currentScale.maxVolume) {
          return this.notify(
            "error",
            "Notification",
            "คุณซื้อเหรียญมากกว่าที่กำหนดไว้ ลิมิต " +   this.$MyFunc.NumberFormat(this.currentScale.maxVolume,2)  + " " + this.coinName
        );
      } 
      if (!this.isUserLogin) {
        return this.notify(
          "error",
          "Notification",
          "Please login to submit order"
        );
      }
      if (
        parseFloat(this.limit_buy_bath) > parseFloat(this.Thaibaht_balance) &&
        this.limit_buy_slide != 100
      ) {
        return this.notify("error", "ยอดเงินไม่เพียงพอ");
      }
      if (this.profitLossLimit) {
        if (
          (!this.limitTpslData.sl || this.limitTpslData.sl === 0) &&
          (!this.limitTpslData.tp || this.limitTpslData.tp === 0)
        ) {
          this.$bvModal.show("limitorder-STPSL");
        } else {
          this.$bvModal.show("TPSL-Confirmation");
        }
      } else {
        if (!this.reslockStatusBuy || !this.systemBuySellLock) {
          if (this.$store.state.ChkOrderConfirm) {
            this.createLimitBuyOrder();
          } else {
            this.$bvModal.show("limitorder-buy-TPSLB");
          }
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน",
          });
        }
      }
    },
    openLimitSellConfirmationModal() {
      if (!this.isUserLogin) {
        return this.notify(
          "error",
          "Notification",
          "Please login to submit order"
        );
      }
      if (
        parseFloat(this.limit_sell_coin) >
          parseFloat(
            this.$MyFunc.mathFloorPrice(this.limitSellBalance, this.coinScale)
          ) &&
        this.limit_sell_slide != 100
      ) {
        this.$notify({
          group: "notification",
          type: "error",
          text: "จำนวนเหรียญไม่เพียงพอ",
        });
      } else {
        if (this.slTut) {
          if (!this.limitSlData.sl || this.limitSlData.sl === 0) {
            this.$bvModal.show("limitorder-SSL");
          } else {
            this.$bvModal.show("limitorder-sell-TPSLB");
          }
        } else {
          if (!this.reslockStatusSell || !this.systemBuySellLock) {
            //if (this.orderConfirm) {
            if (this.$store.state.ChkOrderConfirm) {
              this.createLimitSellOrder();
            } else {
              this.$bvModal.show("limitorder-sell-TPSLB");
            }
          } else {
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: "คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน",
            });
          }
        }
      }
    },
    openMarketBuyConfirmationModal() {
      if (parseFloat(this.market_buy_coin) < this.currentScale.minVolume) {
          return this.notify(
            "error",
            "Notification",
            "คุณซื้อเหรียญน้อยกว่าที่กำหนดไว้ ซื้อขั้นต่ำ " + this.$MyFunc.NumberFormat(this.currentScale.minVolume,8) + " " + this.coinName
        );
      } else if (parseFloat(this.market_buy_coin) > this.currentScale.maxVolume) {
          return this.notify(
            "error",
            "Notification",
            "คุณซื้อเหรียญมากกว่าที่กำหนดไว้ ลิมิต " + this.$MyFunc.NumberFormat(this.currentScale.maxVolume ,2)+ " " + this.coinName
        );
      } 
      if (!this.isUserLogin) {
        return this.notify(
          "error",
          "Notification",
          "Please login to submit order"
        );
      }
      if (
        parseFloat(this.market_buy_bath) > parseFloat(this.Thaibaht_balance) &&
        this.market_buy_slide != 100
      ) {
        return this.notify("error", "ยอดเงินไม่เพียงพอ");
      }
      if (this.profitLossMarket) {
        if (
          (!this.marketTpslData.sl || this.marketTpslData.sl === 0) &&
          (!this.marketTpslData.tp || this.marketTpslData.tp === 0)
        ) {
          this.$bvModal.show("limitorder-STPSL-market");
        } else {
          this.$bvModal.show("TPSL-Confirmation-m");
        }
      } else {
        if (!this.reslockStatusBuy || !this.systemBuySellLock) {
          //if (this.orderConfirm) {
          if (this.$store.state.ChkOrderConfirm) {
            this.createMarketBuyOrder();
          } else {
            this.$bvModal.show("market-order-buy-TPSLB");
          }
        } else {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน",
          });
        }
      }
    },
    openMarketSellConfirmationModal() {
      if (!this.isUserLogin) {
        return this.notify(
          "error",
          "Notification",
          "Please login to submit order"
        );
      }
      if (
        parseFloat(this.market_sell_coin) >
          parseFloat(
            this.$MyFunc.mathFloorPrice(this.limitSellBalance, this.coinScale)
          ) &&
        this.market_sell_slide != 100
      ) {
        this.$notify({
          group: "notification",
          type: "error",
          text: "จำนวนเหรียญไม่เพียงพอ",
        });
      } else {
        if (this.slTutMarket) {
          if (!this.marketSlData.sl || this.marketSlData.sl === 0) {
            this.$bvModal.show("limitorder-SSLM");
          } else {
            this.$bvModal.show("market-order-sell-TPSLB");
          }
        } else {
          if (!this.reslockStatusSell || !this.systemBuySellLock) {
            //if (this.orderConfirm) {
            if (this.$store.state.ChkOrderConfirm) {
              this.createMarketSellOrder();
            } else {
              this.$bvModal.show("market-order-sell-TPSLB");
            }
          } else {
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: "คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน",
            });
          }
        }
      }
    },
    handleLimitBuyCash() {
      this.limitBuyType = 1;
    },
    handleLimitBuyCoin() {
      this.limitBuyType = 2;
    },
    handleLimitSellCash() {
      this.limitSellType = 1;
    },
    handleLimitSellCashSlideBar() {
      this.limitSellType = 2;
    },
    handleLimitSellCoin() {
      this.limitSellType = 3;
    },
    handleMarketBuyCash() {
      this.marketBuyType = 1;
    },
    handleMarketBuyCoin() {
      this.marketBuyType = 2;
    },
    handlemarketSellCash() {
      this.marketSellType = 1;
    },
    handlemarketSellSlideBar() {
      this.marketSellType = 2;
    },
    handlemarketSellCoin() {
      this.marketSellType = 3;
    },
    createLimitBuyOrder() {
      if (this.washSellCheck(0, this.limit_buy_score)) {
        // this.setChkOrderConfirm(e.target.checked);
        this.btn_disable = true;
        let self = this;
        HTTP.post("/exchange/order/add", {
          symbol: this.currentCoin.symbol,
          price: this.limit_buy_score,
          amount: this.limit_buy_coin,
          allinbalance: this.checkLimitBuyOneHundred,
          direction: "BUY",
          type: "LIMIT_PRICE",
          stoploss: this.profitLossLimit
            ? this.limitTpslData.sl
              ? this.limitTpslData.sl
              : 0
            : 0,
          takeprofit: this.profitLossLimit
            ? this.limitTpslData.tp
              ? this.limitTpslData.tp
              : 0
            : 0,
          amountCondition: this.profitLossLimit
            ? this.limitTpslData.sellAmountPercentage
            : null,
          stoplossPercent: this.profitLossLimit
            ? this.limitTpslData.slPercentage
              ? this.limitTpslData.slPercentage
              : 0
            : 0,
          takeprofitPercent: this.profitLossLimit
            ? this.limitTpslData.tpPercentage
              ? this.limitTpslData.tpPercentage
              : 0
            : 0,
        })
          .then((res) => {
            if (res.data.code === 0) {
              self.$bvModal.hide("limitorder-buy-TPSLB");
              self.exchangeOrderId = res.data.data;
              if (self.profitLossLimit) {
                this.profitLossLimit = false;
                this.limit_buy_bath = null;
              } else {
                self.limit_buy_bath = null;
                self.limit_buy_coin = null;
              }
              // update footer open order
              self.getPersonalCurrent();
              // getting latest thb balance
              self.getThbBalance();
            }
            // getting latest active orders
            self.$notify({
              group: "notification",
              type: res.data.message === "success" ? "success" : "error",
              // title: res.data.message === "success" ? "Success" : "Error",
              text: res.data.message,
            });
            self.btn_disable = false;
          })
          .catch((err) => {
            console.log(err);
            this.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: err,
            });
          });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "Wash Sell Detected",
        });
      }
    },
    createLimitSellOrder() {
      if (this.washSellCheck(1, this.limit_sell_score)) {
        this.btn_disable = true;
        let self = this;
        HTTP.post("/exchange/order/add", {
          symbol: this.currentCoin.symbol,
          price: this.limit_sell_score,
          amount: this.limit_sell_coin,
          allinbalance: this.checkLimitSellOneHundred,
          direction: "SELL",
          type: "LIMIT_PRICE",
          stoploss: this.slTut
            ? this.limitSlData.sl
              ? this.limitSlData.sl
              : 0
            : 0,
          takeprofit: this.slTut
            ? this.limitSlData.tp
              ? this.limitSlData.tp
              : 0
            : 0,
          amountCondition: this.slTut
            ? this.limitSlData.sellAmountPercentage
            : null,
          stoplossPercent: this.slTut
            ? this.limitSlData.slPercentage
              ? this.limitSlData.slPercentage
              : 0
            : 0,
          takeprofitPercent: this.slTut
            ? this.limitSlData.tpPercentage
              ? this.limitSlData.tpPercentage
              : 0
            : 0,
        })
          .then((res) => {
            //console.log(res);
            if (res.data.code === 0) {
              self.$bvModal.hide("limitorder-sell-TPSLB");
              self.exchangeOrderId = res.data.data;
              if (self.slTut) {
                this.slTut = false;
                this.limit_sell_bath = null;
              } else {
                self.limit_sell_bath = null;
                self.limit_sell_coin = null;
              }
              // update footer open order
              self.getPersonalCurrent();
              // getting latest thb balance
              self.getThbBalance();
            }
            // getting latest active orders
            self.$notify({
              group: "notification",
              type: res.data.message === "success" ? "success" : "error",
              title: res.data.message === "success" ? "Success" : "Error",
              text: res.data.message,
            });
            self.btn_disable = false;
          })
          .catch((err) => {
            console.log(err);
            self.$notify({
              group: "notification",
              type: "error",
              title: "Error",
              text: err,
            });
          });
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "Wash Sell Detected",
        });
      }
    },
    createMarketBuyOrder() {
      this.btn_disable = true;
      HTTP.post("/exchange/order/add", {
        symbol: this.currentCoin.symbol,
        price: this.coinCurrentValue,
        amount: this.market_buy_coin,
        allinbalance: this.checkMarketBuyOneHundred,
        direction: "BUY",
        type: "MARKET_PRICE",
        stoploss: this.profitLossMarket
          ? this.marketTpslData.sl
            ? this.marketTpslData.sl
            : 0
          : 0,
        takeprofit: this.profitLossMarket
          ? this.marketTpslData.tp
            ? this.marketTpslData.tp
            : 0
          : 0,
        amountCondition: this.profitLossMarket
          ? this.marketTpslData.sellAmountPercentage
          : null,
        stoplossPercent: this.profitLossMarket
          ? this.marketTpslData.slPercentage
            ? this.marketTpslData.slPercentage
            : 0
          : 0,
        takeprofitPercent: this.profitLossMarket
          ? this.marketTpslData.tpPercentage
            ? this.marketTpslData.tpPercentage
            : 0
          : 0,
      })
        .then((res) => {
          //console.log(res);
          if (res.data.code === 0) {
            this.$bvModal.hide("market-order-buy-TPSLB");
            this.exchangeOrderId = res.data.data;
            if (this.profitLossMarket) {
              this.profitLossMarket = false;
              this.market_buy_bath = null;
            } else {
              this.market_buy_bath = null;
              this.market_buy_coin = null;
            }
          }

          this.$notify({
            group: "notification",
            type: res.data.message === "success" ? "success" : "error",
            // title: res.data.message === "success" ? "Success" : "Error",
            text: res.data.message,
          });
          this.btn_disable = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: err,
          });
        });
    },
    createMarketSellOrder() {
      this.btn_disable = true;
      HTTP.post("/exchange/order/add", {
        symbol: this.currentCoin.symbol,
        price: this.coinCurrentValue,
        amount: this.market_sell_coin,
        allinbalance: this.checkMarketSellOneHundred,
        direction: "SELL",
        type: "MARKET_PRICE",
        stoploss: this.slTutMarket
          ? this.marketSlData.sl
            ? this.marketSlData.sl
            : 0
          : 0,
        takeprofit: this.slTutMarket
          ? this.marketSlData.tp
            ? this.marketSlData.tp
            : 0
          : 0,
        amountCondition: this.slTutMarket
          ? this.marketSlData.sellAmountPercentage
          : null,
        stoplossPercent: this.slTutMarket
          ? this.marketSlData.slPercentage
            ? this.marketSlData.slPercentage
            : 0
          : 0,
        takeprofitPercent: this.slTutMarket
          ? this.marketSlData.tpPercentage
            ? this.marketSlData.tpPercentage
            : 0
          : 0,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.$bvModal.hide("market-order-sell-TPSLB");
            this.exchangeOrderId = res.data.data;
            if (this.slTutMarket) {
              this.slTutMarket = false;
              this.market_sell_bath = null;
            } else {
              this.market_sell_bath = null;
              this.market_sell_coin = null;
            }
          }

          this.$notify({
            group: "notification",
            type: res.data.message === "success" ? "success" : "error",
            title: res.data.message === "success" ? "Success" : "Error",
            text: res.data.message,
          });
          this.btn_disable = false;
        })
        .catch((err) => {
          console.log(err);
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: err,
          });
        });
    },
    // TEKUB
    createTrailingBuyOrder() {
      if (!this.reslockStatusBuy || !this.systemBuySellLock) {
        if (!this.isUserLogin) {
          return this.notify(
            "error",
            "Notification",
            "Please login to submit order"
          );
        } else {
          if (
            parseFloat(this.trailing_buying_value) > this.Thaibaht_balance &&
            this.trailing_buy_slide != 100
          ) {
            this.$notify({
              group: "notification",
              type: "error",
              text: "ยอดเงินไม่เพียงพอ",
            });
          } else {
            if (this.$store.state.ChkOrderConfirm) {
              this.sendTrailingStopBuy();
            } else {
              this.$bvModal.show("TSBC-buy-modal");
            }
          }
        }
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน",
        });
      }
    },
    sendTrailingStopBuy() {
      if (this.tcbOption == "THB") {
        this.tcbTrailingStepType = 0;
      } else {
        this.tcbTrailingStepType = 1;
      }
      HTTP.post("/exchange/order/create-exchange-order-condition", {
        symbol: this.currentCoin.symbol,
        amount: this.trailing_buying_value,
        targetAmount: this.trailing_buying_value,
        price: this.trailingBuyPrice,
        triggerPrice: this.trailingBuyPrice,
        direction: "BUY",
        type: "TRAILING_STOP",
        trailingStep: this.trailingStepBuy.replace(/[^\d.]/g, ""),
        basePrice: this.coinCurrentValue,
        expirationDate: this.calculatedBuyTimestamp() +"000",
        status: this.trailingBuyImmediately ? "ACTIVATED" : "OPENED",
        trailingStepType: this.tcbTrailingStepType,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.trailing_buying_value = null;
            this.trailingBuyPrice = null;
            this.trailingStepBuy = null;
            this.trailingBuyImmediately = false;
            HTTP.post("/exchange/order/get-all-exchange-order-condition", {
              type: "TPLS",
            })
              .then((res) => {
                EventBus.$emit("get-Trailing-Stop-Data");
              })
              .catch((err) => {});
          }
          this.$notify({
            group: "notification",
            type: res.data.code === 0 ? "success" : "error",
            title: res.data.code === 0 ? "Success" : "Error",
            text: res.data.message,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    createTrailingSellOrder() {
      if (!this.reslockStatusSell || !this.systemBuySellLock) {
        var stringNumber = this.$MyFunc.NumberFormat(
          this.CalculatedSellBalance,
          this.bathScale
        );
        if (
          parseFloat(this.trailing_selling_value) >
            parseFloat(stringNumber.replace(/,/g, "")) &&
          this.trailing_sell_slide != 100
        ) {
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text: "จำนวนเหรียญไม่เพียงพอ",
          });
        } else {
          if (this.$store.state.ChkOrderConfirm) {
            this.sendTrailingStopSell();
          } else {
            this.$bvModal.show("TSBC-sell-modal");
          }
        }
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน",
        });
      }
    },
    sendTrailingStopSell() {
      if (this.tcsOption == "THB") {
        this.tcsTrailingStepType = 0;
      } else {
        this.tcsTrailingStepType = 1;
      }
      HTTP.post("/exchange/order/create-exchange-order-condition", {
        symbol: this.currentCoin.symbol,
        amount: this.trailing_selling_value,
        targetAmount: this.trailing_selling_value,
        price: this.trailingSellPrice,
        triggerPrice: this.trailingSellPrice,
        direction: "SELL",
        type: "TRAILING_STOP",
        stopLossPrice: this.trailingSL ? this.trailingSL : null,
        trailingStep: this.trailingStepSell.replace(/[^\d.]/g, ""),
        basePrice: this.coinCurrentValue,
        expirationDate: this.calculatedSellTimestamp() +"000",
        status: this.trailingSellImmediately ? "ACTIVATED" : "OPENED",
        trailingStepType: this.tcsTrailingStepType,
      })
        .then((res) => {
          if (res.data.code === 0) {
            this.trailing_selling_value = null;
            this.trailingSellPrice = null;
            this.trailingStepSell = null;
            this.trailingSellImmediately = false;
            this.SL = false;
            HTTP.post("/exchange/order/get-all-exchange-order-condition", {
              type: "TPLS",
            })
              .then((res) => {
                EventBus.$emit("get-Trailing-Stop-Data");
              })
              .catch((err) => {});
          }
          this.$notify({
            group: "notification",
            type: res.data.code === 0 ? "success" : "error",
            title: res.data.code === 0 ? "Success" : "Error",
            text: res.data.message,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },

    calculatePercentage(balance, value) {
      let percentage = 0;
      if (balance) {
        let percentages = [0.25, 0.5, 0.75, 1];
        percentage = percentages.find(
          (percentage) => Math.floor(balance * percentage) == parseInt(value)
        );
      }
      if (this.limit_buy_bath == 0) {
        this.limit_buy_bath = "";
      }
      if (this.limit_sell_bath == 0) {
        this.limit_sell_bath = "";
      }

      return percentage > 0 ? Math.floor(percentage * 100) : 0;
    },
    setSliderValues(d, type) {
      const types = {
        limitBuy: {
          prop: "limit_buy_bath",
          balance: this.Thaibaht_balance,
        },
        limitSell: {
          prop: "limit_sell_bath",
          balance: this.CalculatedSellBalance,
        },
        marketBuy: {
          prop: "market_buy_bath",
          balance: this.Thaibaht_balance,
        },
        marketSell: {
          prop: "market_sell_bath",
          balance: this.CalculatedSellBalance,
        },
        trailingBuy: {
          prop: "trailing_buying_value",
          balance: this.Thaibaht_balance,
        },
        trailingSell: {
          prop: "trailing_selling_value",
          balance: this.CalculatedSellBalance,
        },
      };
      if (type == "limitBuy") {
        if (d == 100) {
          this.checkLimitBuyOneHundred = true;
        } else {
          this.checkLimitBuyOneHundred = false;
        }
        this.handleLimitBuyCash();
      }
      if (type == "limitSell") {
        if (d == 100) {
          this.checkLimitSellOneHundred = true;
        } else {
          this.checkLimitSellOneHundred = false;
        }
        this.handleLimitSellCashSlideBar();
      }
      if (type == "marketBuy") {
        if (d == 100) {
          this.checkMarketBuyOneHundred = true;
        } else {
          this.checkMarketBuyOneHundred = false;
        }
        this.handleMarketBuyCash();
      }
      if (type == "marketSell") {
        if (d == 100) {
          this.checkMarketSellOneHundred = true;
        } else {
          this.checkMarketSellOneHundred = false;
        }
        this.handlemarketSellSlideBar();
      }

      this[types[type].prop] =
        d == 0
          ? 0
          : this.$MyFunc.toFixedDigits(
              (types[type].balance * d) / 100,
              this.bathScale
            );
    },
    notify(type, title, text) {
      this.$notify({
        group: "notification",
        type: type,
        title: title,
        text: text,
      });
    },
    PageResizeHandler() {
      if (window.innerWidth >= 1200) {
        this.market_text = true;
      } else {
        this.market_text = false;
      }
    },
    getMemberConfigOrderConfirm() {
      let self = this;
      HTTP.get("/uc/member/get-member-config")
        .then((res) => {
          if (res.data.code === 0) {
            const resData = res?.data?.data;
            const searchConfig = resData.find((item) => {
              return (
                item.configName === "ORDER_CONFIRM_BEFORE_SUBMIT" &&
                item.configValue === 0
              );
            });
            if (searchConfig) {
              self.orderConfirm = true;
            } else {
              self.orderConfirm = false;
            }
          }
        })
        .catch((error) => {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Unable to Get Member Config Order Confirm",
            text: error,
          });
        });
    },
    getPersonalCurrent() {
      let self = this;
      HTTP.post("/exchange/order/personal/current", {
        pageNo: 1,
        pageSize: 100,
      })
        .then((res) => {
          let data = res.data.content;
          data.forEach((item) => {
            self.assetsWallet.forEach((asset) => {
              if (item.coinSymbol == asset.coin.unit) {
                item.bathScale = asset.bathScale;
                item.coinScale = asset.coinScale;
              }
            });
          });
          self.setLimitOrderData(data);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getThbBalance() {
      this.limitSellBalance = 0
      this.assetsWallet.map((item) => {
            if (item.coin.unit == "THB") {
              this.Thaibaht_balance = item.balance;
            }
            if (item.coin.unit == this.coinName && this.limitSellBalance == 0) {
              this.limitSellBalance = item.balance;
              //console.log("limitSellBalance", this.limitSellBalance);
            }
          });
      this.deleyAssets = false;
      return false
      if (!this.deleyAssets) {
        this.deleyAssets = true;
        if(this.assetsWallet != undefined){
          if (this.assetsWallet.length == 0) {
          setTimeout(() => {
            this.deleyAssets = false;
            this.getThbBalance();
          }, 100);
        } else {
       
        }
        }
      }
    },
    FNorderConfirm() {
      console.log("FNorderConfirm");
    },
    checkTrailingBuyImmediately() {
      this.trailingBuyPrice = null;
      //checked
      if (!this.trailingBuyImmediately) {
        this.trailingBuyPrice = this.coinCurrentValue;
      }
    },
    checkTrailingSellImmediately() {
      this.trailingSellPrice = null;
      //checked
      if (!this.trailingSellImmediately) {
        this.trailingSellPrice = this.coinCurrentValue;
      }
    },
    statusCheckbooxTpsl(data) {
      if (data == "tpslLimi-b") {
        this.profitLossLimit = false;
      } else if (data == "tpslMarket-b") {
        this.profitLossMarket = false;
      } else {
        this.slTut = false;
      }
    },
    getCommission(){
      let self = this;
      HTTP.get("/exchange/order/get-commission")
        .then((res) => {
          self.commissions = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fee(type) {
      let fee = 0;
      let volume = 0;
      if(type === 'limit_buy'){
        volume = this.limit_buy_score;
      }else if(type === 'limit_sell'){
        volume = this.limit_sell_score;
      }else if(type === 'market_buy' || type === 'market_sell'){
        volume = this.coinCurrentValue;
      }
      if(this.commissions.length > 0){
        for (let i = 0; i < this.commissions.length; i++) {
            const commission = this.commissions[i];
            if (volume >= commission.minVolume && volume <= commission.maxVolume) {
              fee = commission.commission * 100;
            }
        }
      }else{
        fee = this.currentCoin.fee;
      }
      return this.$MyFunc.NumberFormat(fee, 2);
    },
  },
  created() {
    window.addEventListener("resize", this.PageResizeHandler);
    this.PageResizeHandler();
    this.coinName = this.currentCoin.symbol.split("/")[0];
    this.pairName = this.currentCoin.symbol.split("/")[1];
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
      this.memberId = user_data.id;
      let resMember = JSON.parse(localStorage.getItem("resMember") || "{}");
      if (resMember.resMember?.id != undefined) {
        this.reslockStatusBuy = resMember?.reslockStatus.cannotBuy;
        this.reslockStatusSell = resMember?.reslockStatus.cannotSell;
      }
      this.getMemberConfigOrderConfirm();
      this.getThbBalance();
      this.getCommission();
    }
    if(this.$store.state.hide_menu == false){
      this.ordertabs.push("Trailing Stop")
    }
  },
  mounted() {
    this.limit_buy_score = this.selected_buy_score.toFixed(this.bathScale);
    this.limit_sell_score = this.selected_sell_score.toFixed(this.bathScale);
    EventBus.$on("status-checkbox-tpsl", (data) => {
      this.statusCheckbooxTpsl(data);
    });
  },
  destroyed() {
    window.removeEventListener("resize", this.PageResizeHandler);
  },
};
</script>
<style lang="scss">
#TSBC-buy-modal___BV_modal_content_,
#TSBC-sell-modal___BV_modal_content_,
#TPSL-Confirmation___BV_modal_body_,
#TPSL-Confirmation-m___BV_modal_body_,
#limitorder-buy-TPSLB___BV_modal_body_,
#limitorder-sell-TPSLB___BV_modal_body_,
#limitorder-TPSLB2___BV_modal_body_,
#limitorder-stop-loss___BV_modal_body_ {
  padding: 0px 16px 20px 16px !important;
}
#limitorder-stop-loss___BV_modal_content_ {
  margin-top: 0% !important;
}
#limitorder-TPSL___BV_modal_content_ {
  margin-top: 0% !important;
}
#limitorder-TPSLB2___BV_modal_content_ {
  width: 360px;
  margin: 0 auto;
}
#TSBC-buy-modal___BV_modal_content_,
#TSBC-sell-modal___BV_modal_content_ {
  width: 420px;
  margin: 0 auto;
}
.timeline-bar {
  width: 100%;
  margin: auto !important;
  padding: 19px 5px 42px !important;
  position: relative;
  margin-left: -5px !important;
}
.progress-bar {
  background-color: #09BE8B !important;
}
.pin-input {
  width: 160px;
  height: 40px;
  background: #38454d;
  color: rgb(255, 255, 255);
  border-radius: 4px;
  padding: 0 8px;
  caret-color: white;
}
</style>
<style scoped lang="scss">
.pin {
  padding: 8px;
  width: 160px;
  height: 36px;
  border-radius: 4px;
  background: #38454d;
  color: #ffffff;
}
.massage {
  width: 224px;
  height: 24px;
  padding: 4px 8px;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  color: #0a0d10;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.dropdown-trailing {
  padding-left: 8px;
  border-left: 1px solid #28363e;
}
.dropdown-trailing svg {
  width: 6px;
  margin-top: -4px;
}
.order-confirm .col-4 {
  padding-left: 15px !important;
}
.order-confirm span {
  line-height: 24px;
}
.order-confirm span.ctitle {
  color: #9bacb6;
}
.spots {
  position: absolute;
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-top: -5px;
}

.spots .spot {
  font-size: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: #9bacb6;
}
.spots .spot .active {
  background: #09BE8B !important;
  border-color: #203864 !important;
}
.spots .spot .dot {
  background: #191b1c;
  border: 1px solid #566a76;
  height: 8px;
  width: 8px;
  border-radius: 50%;
  display: block;
  margin-bottom: 4px;
}
.font-s {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
}
.modal-th {
  padding: 24px 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  span {
    position: absolute;
    right: 30px;
    top: 25px;
  }
}
.modal-th > h5 {
  margin-bottom: 0px;
}
.limitorder-TPSLB > h5 {
  // margin-right: 52px !important;
  text-align: center;
}
</style>
<style lang="scss">
.modal-content {
  // width: 360px;
  background-color: #141b1f !important;
}

.modal-body {
  background-color: #141b1f !important;
  border-radius: 8px;
  font-style: normal;
  padding: 0 16px 20px 16px !important;
}
.header {
  width: 360px;
  height: 75px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  position: relative;
}
.checkbox-input {
  input {
    background: #191b1c;
    border: 1px solid #3c515d;
    border-radius: 2px;
    height: 17px;
    width: 17px;
  }
  input:checked {
    background-color: #09BE8B;
    border: 1px solid #3c515d;
  }
  span {
    display: inline-block;
    margin-left: 8px;
    line-height: 24px;
    // margin-top: 3px;
  }
}
.limitorder-STPSL {
  width: 513px;
  height: 702px;
  font-family: "Roboto Flex";
}
.modal-body {
  font-family: "Roboto Flex";
  .modal-close-button {
    cursor: pointer;
  }
}
.modal-body h5 {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  color: rgb(255, 255, 255);
  line-height: 27px;
  display: inline-block;
}
.modal-th {
  text-align: center;
  span {
    float: right;
  }
}
.modal-body p {
  color: #677f8e;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
}
.modal-body p.c-title {
  color: #677f8e;
}
.modal-body span {
  color: rgb(255, 255, 255);
}
.modal-body span.tpsls {
  color: #9bacb6;
}
.modal-body .buy-badge {
  background: #529a6029;
  color: #0c9;
  padding: 4px 8px;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 18px !important;
  text-align: center;
}

.modal-body .sell-badge {
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 8px;
  background: #eb475129;
  color: #eb4751;
  border-radius: 2px;
  line-height: 18px !important;
  text-align: center;
  text-transform: uppercase;
}

.modal-body label {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgb(255, 255, 255);
}

.checkboxes {
  margin-top: 0px !important;
  vertical-align: middle;
}

.sells .form-check input[type="checkbox"] {
  border: none !important;
  box-shadow: none !important;
  &:focus {
    box-shadow: none !important;
  }
}

.modal-body .checkbox:checked {
  background-color: #09BE8B;
  outline: none !important;
}

.modal-body .yellow-btn {
  // padding: 8px 16px;
  gap: 4px;
  background: #09BE8B;
  color: #0a0d10;
  border-radius: 4px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  height: 40px;
  &:focus {
    outline: none;
  }
}

.modal-body .grey-btn {
  // padding: 8px 16px;
  gap: 4px;
  background: #191b1c;
  color: #ffffff;
  border-radius: 4px;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  width: 100%;
  height: 40px;
  &:focus {
    outline: none;
  }
}

.modal-footer {
  display: none !important;
}

/* Buy/Sells sells */
.buy-sell .sells .sells-ul ul {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  height: 38px;
  padding: 0px;
  gap: 24px;
  margin-top: 10px;
}

.buy-sell .sells li button {
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  background: none;
  align-items: center;
  color: #677f8e;
  border: none;
  padding: 20px 0px 10px 0px;
  // margin-left: 24px;
}

.buy-sell .sells li button:hover {
  color: rgb(255, 255, 255);
  cursor: pointer;
}

.buy-sell .sells li .active {
  border-bottom: 2px solid rgb(9, 190, 139);
  width: auto;
  color: rgb(255, 255, 255);
}
.bs-lp-input {
  position: relative;
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    display: none;
  }
  span.currency {
    font-family: "Roboto Flex";
    position: absolute;
    right: 10px;
    top: 56%;
    transform: translateY(-95%);
    color: #9bacb6;
    pointer-events: none;
  }
  span.text {
    position: absolute;
    left: 10px;
    color: #9bacb6;
    top: 56%;
    transform: translateY(-95%);
    pointer-events: none;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    font-family: "Sarabun";
  }
}
.bs-lp-input input.sml {
  padding-left: 167px;
}
.input-group-bottom-text {
  font-size: 14px;
  font-weight: 400;
  color: #677f8e;
  margin-top: -8px;
  // margin-bottom: 8px;
  p {
    margin-bottom: 0 !important;
  }
}
.sells .tab-content {
  margin-top: 16px;
  margin-bottom: 10px;
}
.sells input {
  width: 100%;
  text-align: right;
  border: none;
  outline: none !important ;
  padding: 8px 50px 8px 125px;
  margin-bottom: 14px;
  height: 40px;
  font-style: normal;
  font-size: 14px;
  line-height: 24px;
  background: #191b1c;
  border-radius: 4px;
  &::placeholder {
    color: #566a76;
  }
}
.c-white {
  color: rgb(255, 255, 255);
}
.c-wt {
  color: #9bacb6;
}
.c-dull {
  color: #566a76;
}

.sells .buy-btn {
  width: 100%;
  display: block;
  background-color:#226afd;
  color: #ffffff;
  border: none;
  margin-bottom: 12px;
  height: 40px;
  border-radius: 4px;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  &:hover {
    background-color:#4467e0;
  }
}

.tpsl-btn {
  width: 100%;
  height: 40px;
  border-radius: 4px;
  background: #09BE8B;
  color: #0a0d10;
  font-family: "sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
  font-weight: 500;
  margin-top: 16px;
  // margin-bottom: 16px;
  &:hover {
    background-color: #66cdb0;
  }
}

.sells .sell-btn {
  width: 100%;
  display: block;
  background-color: #ff2500;
  color: #ffffff;
  border: none;
  margin-bottom: 12px;
  height: 40px;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  border-radius: 4px;
  &:hover {
    background-color: var(--red-negativeHover);
  }
}

.sells p {
  color: #677f8e;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 24px;
}
.sells .fees {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  color: #677f8e;
}

.sells label p {
  color: rgb(255, 255, 255);
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  margin-left: 3px;
  font-family: "Roboto Flex";
  input {
    margin-top: -0.5px;
  }
  span {
    margin-left: 3px;
  }
}

.sells .form-check input {
  background: #191b1c;
  border: 1px solid #3c515d;
  border-radius: 2px;
  padding: 0px;
  gap: 0px;
  height: 17px;
  width: 17px;
}

.sells .form-check input:checked {
  background-color: #09BE8B;
}

.timeline-bar {
  // margin-bottom: 10px;
  padding: 25px 0;
  margin: 0 -44px;
  padding-left: 18px;

  .timeline {
    position: relative;
    left: -30px;
    margin-top: -12px;
  }

  .timeline li {
    list-style: none;
    float: left;
    width: 20%;
    position: relative;
    text-align: center;
    font-size: 10px;
    padding-top: 10px;
    color: #9bacb6;
  }
  .timeline li span {
    display: inline-block;
    margin-top: 4px;
  }

  .timeline li:before {
    content: "";
    width: 8px;
    height: 8px;
    border: 1px solid #566a76;
    border-radius: 50%;
    display: block;
    text-align: center;
    line-height: 50px;
    margin: 0px auto 10px auto;
    background: #191b1c;
    color: #000;
    transition: all ease-in-out 0.3s;
    cursor: pointer;
    position: absolute;
    z-index: 2;
    top: 0px;
    left: 40%;
  }

  .timeline li:after {
    content: "";
    position: absolute;
    width: 100%;
    height: 2px;
    background-color: #566a76;
    top: 3px;
    left: -50%;
    z-index: 1;
    transition: all ease-in-out 0.3s;
  }

  .timeline li:first-child:after {
    content: none;
  }
  .timeline li.active {
    color: #203864;
  }

  .timeline li.active span {
    color: #9bacb6;
    padding: 0 3px;
    border-radius: 5px;
  }
  .timeline li.active:before {
    background: #09BE8B;
    color: #203864;
    border-color: #203864;
    height: 11px;
    width: 11px;
    margin-top: -1px;
    margin-left: 1px;
  }
}
.stop-loss-detail-modal {
  // margin: 20px 0;
  .modal-title {
    color: white;
    text-align: left;
    font-size: 18px;
    font-weight: 600;
    span {
      margin-left: 5px;
    }
  }
  .ts-title {
    color: #677f8e;
  }
  .ts-value {
    color: rgb(255, 255, 255);
  }
  .w-title {
    color: rgb(255, 255, 255);
    line-height: 24px;
    font-family: "Roboto Flex";
  }
  .buy-tag {
    color: #0c9;
    background: rgba(82, 154, 96, 0.16);
    border-radius: 2px;
    font-size: 12px;
    font-weight: 600;
    line-height: 18px;
    padding: 2px 4px;
    margin-right: 6px;
  }
  .sell-tag {
    color: #eb4751;
    background: rgba(235, 71, 81, 0.16);
    border-radius: 2px;
    font-style: normal;
    font-weight: 600;
    font-size: 12px;
    line-height: 18px;
    padding: 2px 4px;
    margin-right: 6px;
  }
  .gs {
    color: #677f8e;
  }
  .tabs-container {
    width: 344px;
    border: 1px solid #28363e;
    border-radius: 4px;
    text-align: center;
    padding: 1px;
  }
  .tab {
    font-size: 12px;
    padding: 4px 8px;
    border-radius: 2px;
    cursor: pointer;
  }
  .tab.active {
    background-color: #191b1c;
    cursor: default;
  }
  .input-row {
    position: relative;
    padding: 0;
    input {
      text-align: right;
      margin-top: 10px;
      width: 344px;
      border-radius: 4px;
      background-color: #191b1c;
      padding: 10px 8px;
      color: white;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }
    input::placeholder {
      color: #9bacb6;
    }
    input:focus {
      outline: none !important;
      // border: ;
    }
    span {
      position: absolute;
      left: 10px;
      top: 22px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #677f8e;
      pointer-events: none;
    }
  }
  .sl-ul {
    margin: 10px 0;
    padding: 0 20px;
    color: #9bacb6;
    list-style-type: disc;
    line-height: 24px;
  }
  button.stpsl-submit {
    background-color: #0c9;
    border-radius: 4px;
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 16px auto;
    width: 255px;
    height: 40px;
    color: white;
  }
  button.stpsl-submit:hover {
    background-color: #5c8ff7;
  }
  button.ssl-submit {
    color: white;
    background-color: #f72000;
    border-radius: 4px;
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    margin: 0 auto;
    width: 255px;
    height: 40px;
    margin-top: 11px;
  }
  button.ssl-submit:hover {
    background-color: var(--red-negativeHover);
  }
  .sa-tabs {
    position: relative;
    .sa-tab {
      background-color: #191b1c;
      margin-left: 1px;
      padding: 8px;
      cursor: pointer;
      line-height: 24px;
    }
    .sa-tab.active,
    .sa-tab-first.active,
    .sa-tab-last.active {
      background-color: #38454d;
      cursor: default;
    }
    .sa-tab-first {
      border-radius: 4px 0px 0px 4px;
      background-color: #191b1c;
      margin-left: 1px;
      padding: 8px;
      cursor: pointer;
      line-height: 24px;
    }
    .sa-tab-last {
      border-radius: 0px 4px 4px 0px;
      background-color: #191b1c;
      margin-left: 1px;
      padding: 8px;
      cursor: pointer;
      line-height: 24px;
    }
  }
  .top-row {
    // padding-bottom: 5px;
    margin-bottom: 16px;
    border-bottom: 5px solid #0a0d10;
  }
}
.mheader {
  padding: 0;
}
@media only screen and (min-width: 1024px) {
  .sells .tab-content {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 1024px) {
  .sells .tab-content {
    padding: 0 10px;
  }
}
@media only screen and (max-width: 991px) {
  .sells .tab-content {
    padding: 0 35px;
  }
}
@media only screen and (max-width: 848px) {
  .sells .tab-content {
    padding: 0 40px;
  }
}
@media only screen and (min-width: 375px) {
  .menu-dynamic {
    margin-left: 30px;
  }
}
@media only screen and (min-width: 991px) {
  .menu-dynamic {
    margin-left: 0;
  }
}

.hidden {
  display: none;
}
.show {
  display: block;
}
.sells {
  .select-container-1 {
    color: rgb(255, 255, 255);
    border-radius: 4px;
    height: 35px;
    width: 70px;
    margin: auto 0;
    display: flex;
    top: 4px;
    align-items: center;
    right: -8px;
    position: absolute;
    cursor: pointer;
    .icon {
      pointer-events: none;
      position: absolute;
      right: 15px;
    }
    .rotate-sc-icon {
      transform: rotateX(180deg);
    }
    .text {
      padding-left: 15px;
    }
    .options {
      position: absolute;
      bottom: -82px;
      width: 90%;
      background-color: #191b1c;
      border-radius: 4px;
      z-index: 100;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 15px;
          border-radius: 4px;
          &:hover {
            background-color: #191b1c;
          }
        }
      }
    }
    .hidden {
      display: none;
    }
    .show {
      display: block;
    }
  }
  .select-container-2 {
    color: rgb(255, 255, 255);
    border-radius: 4px;
    height: 35px;
    width: 70px;
    margin: auto 0;
    display: flex;
    top: 4px;
    align-items: center;
    right: -8px;
    position: absolute;
    cursor: pointer;
    .icon {
      pointer-events: none;
      position: absolute;
      right: 15px;
    }
    .rotate-sc-icon {
      transform: rotateX(180deg);
    }
    .text {
      padding-left: 15px;
    }
    .options {
      position: absolute;
      bottom: -82px;
      width: 90%;
      background-color: #191b1c;
      border-radius: 4px;
      z-index: 100;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 15px;
          border-radius: 4px;
          &:hover {
            background-color: #191b1c;
          }
        }
      }
    }
    .hidden {
      display: none;
    }
    .show {
      display: block;
    }
  }
  .select-container-3 {
    color: rgb(255, 255, 255);
    height: 35px;
    width: 100px;
    margin: auto 0;
    display: flex;
    top: 4px;
    align-items: center;
    right: 7px;
    position: absolute;
    cursor: pointer;
    .icon {
      pointer-events: none;
      position: absolute;
      right: 1px;
    }
    .rotate-sc-icon {
      transform: rotateX(180deg);
    }
    .text {
      padding-left: 15px;
      width: 100%;
      text-align: right;
      margin-right: 13px;
    }
    .options {
      position: absolute;
      top: 38px;
      width: 145%;
      right: -6px;
      background-color: #191b1c;
      border-radius: 4px;
      z-index: 100;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 15px;
          border-radius: 4px;
          &:hover {
            background-color: #191b1c;
          }
        }
      }
    }
    .hidden {
      display: none;
    }
    .show {
      display: block;
    }
  }
}
.in-h-on {
  height: 35px;
}
.in-h-off {
  height: 35px;
}
</style>
