<template>
  <div>
    <div
      class="deposite-cash-container"
      :class="currentSettings === 'qr' ? 'qr-h' : 'bc-h'"
    >
      <!-- top section start -->
      <div class="dcc-head">
        <div class="col-12" style="display: inline-flex">
          <router-link to="/deposit/cash" :style="DepositCashDisplay"
            ><button :class="ActiveDepositCash">
              Deposit Cash
            </button></router-link
          >
          <router-link to="/deposit/coin" :style="DepositCoinDisplay"
            ><button :class="ActiveDepositCoin">
              Deposit Coin
            </button></router-link
          >
        </div>
      </div>
      <!-- top section end -->
      <!-- center section start -->
      <div
        v-if="!reslockStatusDepositCash"
        class="row center-section"
        style="margin-top: 24px"
      >
        <div class="col-12 p-lg-0">
          <div class="row margin-l-24">
            <div
              class="col-5 col-md-4 my-auto thai-font p-0 l-24"
              style="width: 142px"
            >
              เลือกช่องทางการฝาก
            </div>
            <div class="col-7 col-md-8">
              <div
                class="select-container"
                tabindex="0"
                @click="open_dropdown = !open_dropdown"
                @blur="open_dropdown = false"
              >
                <span class="text">{{ selected_option }}</span>
                <span :class="open_dropdown ? 'rotate-sc-icon icon' : 'icon'">
                  <svg
                    width="12"
                    height="8"
                    viewBox="0 0 12 8"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                      fill="#677F8E"
                    />
                  </svg>
                </span>
                <div :class="open_dropdown ? 'show options' : 'hidden options'">
                  <ul>
                    <li
                      @click="
                        (selected_option = 'Bank Transfer'),
                          (currentSettings = 'bank_transfer')
                      "
                    >
                      Bank Transfer
                    </li>
                    <li
                      @click="
                        (selected_option = 'QR Code (Prompt Pay)'),
                          (currentSettings = 'qr'),
                          CheckQRCode()
                      "
                    >
                      QR Code (Prompt Pay)
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <form @submit.prevent="sendQrPayment">
            <div
              class="row margin-l-24"
              v-if="currentSettings === 'qr'"
              style="margin-top: 24px"
            >
              <div
                class="col-5 col-md-4 my-auto thai-font p-0 l-24"
                style="width: 142px"
              >
                จำนวนเงินที่ต้องการฝาก
              </div>
              <div class="col-7 col-md-8 input-container">
                <cleave
                  ref="inputamount"
                  v-model="cashDepositAmount"
                  :options="options"
                  placeholder="0.00"
                ></cleave>
                <span>THB</span>
              </div>
            </div>
            <div class="row mt-4 margin-l-24" v-if="currentSettings === 'qr'">
              <div class="col-12 info thai-font p-0">
                <p>ยอมรับเงื่อนไขการฝากเงิน</p>
                <ul
                  class="p-0 margin-l-24 mt-1"
                  style="margin-bottom: 20px; font-weight: 400; color: #9bacb6"
                >
                  <li>โอนเงินได้ตลอด 24 ชั่วโมง (ขึ้นอยู่กับธนาคารต้นทาง)</li>
                  <li>QR Code ใช้งานได้ไม่เกิน 24 ชั่วโมง</li>
                </ul>
              </div>
            </div>
            <div
              class="row mt-4 margin-l-24 bt-sec"
              style="line-height: 24px"
              v-if="currentSettings === 'bank_transfer'"
            >
              <div
                class="row mt-4 margin-l-24 bt-sec"
                style="line-height: 24px"
              >
                <div class="col-5 col-md-4 p-0" style="width: 142px">
                  ชื่อบัญชี
                </div>
                <div class="col-7 col-md-8 lr noto-sans-thai-font">
                  {{ bank_company_name }}
                  <span
                    class="thai-font"
                    style="cursor: pointer"
                    @click="copy(bank_company_name)"
                    >คัดลอก</span
                  >
                </div>
                <div class="col-5 col-md-4 p-0 mt-3" style="width: 142px">
                  ธนาคาร
                </div>
                <div class="col-7 col-md-8 mt-3">{{ bankName }}</div>
                <div
                  class="col-5 col-md-4 p-0 mt-3 d-none"
                  style="width: 142px"
                >
                  Reference Code1
                </div>
                <div class="col-7 col-md-8 mt-3 d-none">{{ referenceOne }}</div>
                <div
                  class="col-5 col-md-4 p-0 mt-3 d-none"
                  style="width: 142px"
                >
                  Reference Code2
                </div>
                <div class="col-7 col-md-8 mt-3 d-none">{{ referenceTwo }}</div>
              </div>
            </div>
            <!-- bottom section start -->
            <div
              class="bottom-section thai-font"
              v-if="currentSettings === 'qr'"
            >
              <label>
                <input
                  class="form-check-input shadow"
                  v-model="isConfirm"
                  type="checkbox"
                />
                <span>ยอมรับเงื่อนไขทั้งหมด</span>
              </label>
              <div class="last-btn">
                <button
                  type="submit"
                  class="thai-font"
                  :class="isConfirm ? '' : 'inactive'"
                  :disabled="!isConfirm"
                >
                  ยืนยันการฝากเงิน
                </button>
              </div>
            </div>
            <!-- bottom section end -->
          </form>

          <div v-if="currentSettings === 'bank_transfer'">
            <div class="bcb"></div>
            <div class="row p-0 margin-l-24">
              <div class="col-12 bc-info thai-font p-0">
                <p>ยอมรับเงื่อนไขการฝากเงิน</p>
                <ul class="" style="line-height: 24px">
                  <li>โอนเงินได้ตลอด 24 ชั่วโมง (ขึ้นอยู่กับธนาคาร)</li>
                </ul>
              </div>
            </div>
            <div class="row p-0 margin-l-24">
              <div class="col-12 bc-info thai-font p-0">
                <p>วิธีโอนเงิน</p>
                <ul class="number" style="line-height: 19px">
                  <li>เข้าสู่เมนู “จ่ายบิล” จาก Mobile Banking</li>
                  <li>ค้นหา “{{ bank_company_name }}”</li>
                  <li>
                    กรอกรหัสอ้างอิง 1 (Ref.1) เลขที่บัญชีซื้อขาย 8 หลัก ด้วย
                    {{ referenceOne }}
                  </li>
                  <li>
                    กรอกรหัสอ้างอิง 2 (Ref.2) เลขบัตรประชาชน 13 หลัก ด้วย
                    {{ referenceTwo }}
                  </li>
                  <li>ระบุจำนวนเงินที่ต้องการ เเละทำตามขั้นตอน</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        
      </div>
      <!-- center section start -->
      <div v-else class="text-center" style="padding: 150px">
        คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน
      </div>

      <!-- <div class="right-info">
        <p style="color: #677f8e">คู่มือแนะนำการทำรายการฝาก</p>
        <ul>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการฝาก แบบ QR Code</a
            >
          </li>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-Bank-Transfer.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการฝาก แบบ Bank Transfer</a
            >
          </li>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%a2%e0%b8%81%e0%b9%80%e0%b8%a5%e0%b8%b4%e0%b8%81%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการยกเลิกฝาก แบบ QR Code</a
            >
          </li>
        </ul>
      </div> -->
    </div>
    <RightInfo />
    <DepositCashModal
      :data="qrdata"
      :qrId="qrId"
      :qrFlag="qrFlag"
      :isLoading="isLoading"
    />
  </div>
</template>

<script>
import { HTTP } from "../../../config/axios";
import DepositCashModal from "./components/DepositCashModal";
import RightInfo from "../../../components/deposit-withdraw/RightInfo";
export default {
  components: { DepositCashModal, RightInfo },
  data() {
    return {
      open_dropdown: false,
      selected_option: "QR Code (Prompt Pay)",
      currentSettings: "qr",
      qrdata: {
        image: "",
        amount: "",
        fromAccountName: "",
        toAccountName: "",
        ref2: "",
      },
      cashDepositAmount: null,
      isConfirm: false,
      options: {
        numeral: true,
        numeralIntegerScale: 10,
        numeralDecimalScale: 2,
      },
      qrId: null,
      qrFlag: null,
      referenceOne: null,
      referenceTwo: null,
      bankName: null,
      isLoading: false,
      bank_company_name: "BITBULL THAILAND CO.,LTD",
      reslockStatusDepositCash: false,
      reslockStatusDepositCoin: false,
      DepositCashDisplay: "display: block;",
      DepositCoinDisplay: "display: block;",
      ActiveDepositCash: "active",
      ActiveDepositCoin: "not-active",
    };
  },
  beforeMount() {
    this.banktransfer();
  },
  watch: {
    cashDepositAmount(value) {},
  },
  methods: {
    sendQrPayment() {
      if (this.cashDepositAmount > 0 && this.cashDepositAmount <= 2000000) {
        let self = this;
        if (this.isConfirm) {
          this.isLoading = true;
          HTTP.post("/uc/depositfiat/qrpayment", {
            amount: this.cashDepositAmount,
          })
            .then((res) => {
              self.isLoading = false;
              if (res.data.code == 200) {
                self.qrdata = res.data.data;
                self.qrFlag = "Cancel";
                self.cashDepositAmount = null;
                self.$bvModal.show("deposit-qr-modal");
              } else if (res.data.code == 500) {
                // self.$notify({
                //   group: "notification",
                //   type: "error",
                //   title: "error",
                //   // "กรุณายกเลิกรายการฝากเงิน QR Code ก่อนหน้า"
                //   text: res.data.message,
                // });
                if (res.data.message) {
                  const matches = res.data.message.match(/\(id=(\d+),/g);
                  if (matches) {
                    const idMatch = matches[0].match(/\(id=(\d+),/);
                    if (idMatch) {
                      if (parseInt(idMatch[1]) > 0) {
                        self.getDeposit(idMatch[1]);
                      }
                    }
                  } else {
                    self.$notify({
                      group: "notification",
                      type: "error",
                      title: "error",
                      text: res.data.message,
                    });
                  }
                }
              } else {
                self.$notify({
                  group: "notification",
                  type: "error",
                  title: "error",
                  text: res.data.message,
                });
              }
            })
            .catch((err) => {
              console.log(err);
            })
            .finally(() => {
              self.isLoading = false;
              self.isConfirm = false;
              self.cashDepositAmount = null;
            });
        } else {
          self.$notify({
            group: "notification",
            type: "error",
            title: "error",
            text: "Accept conditions to continue",
          });
        }
      } else {
        this.$notify({
          group: "notification",
          type: "error",
          title: "error",
          text: "The amount must not be 0 and not exceed 2,000,000",
        });
      }
    },
    getDeposit(qrid) {
      let self = this;
      HTTP.get("/uc/bank/qr/" + qrid, {})
        .then((res) => {
          if (res.data.code == 200) {
            self.qrId = qrid;
            self.qrFlag = "Cancel";
            self.qrdata = res.data.data;
            self.$bvModal.show("deposit-qr-modal");
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    banktransfer() {
      let self = this;
      HTTP.post("/uc/depositfiat/banktransfer")
        .then((res) => {
          if (res.data.code == 0) {
            this.bankRef = res.data.data;
            this.bank_company_name =
              this.bankRef.bankAccName !== undefined
                ? this.bankRef.bankAccName
                : "BITBULL THAILAND CO.,LTD";
            this.referenceOne = this.bankRef.ref1;
            this.referenceTwo = this.bankRef.ref2;
            this.bankName = this.bankRef.bankName;
          } else {
            self.$notify({
              group: "notification",
              type: "error",
              title: "error",
              text: res.data.message,
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    copy(d) {
      navigator.clipboard
        .writeText(d)
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: "Success",
            text: "Copy Success",
          });
          // console.log("Text copied to clipboard:", d);
          // Optionally, you can show a success message or perform other actions
        })
        .catch((error) => {
          console.error("Unable to copy text to clipboard:", error);
          // Handle the error, show an error message, etc.
        });
    },
    CheckQRCode() {
      let self = this;
      HTTP.post("/uc/depositfiat/QRprevious")
        .then((res) => {
          if (res.data.code == 200) {
            self.qrId = res.data.data.id;
            self.qrFlag = "Cancel";
            self.qrdata = res.data.data;
            self.$bvModal.show("deposit-qr-modal");
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (user_data?.id) {
      let storedData = JSON.parse(localStorage.getItem("resMember") || "{}");
      if (storedData) {
        this.reslockStatusDepositCash =
          storedData?.reslockStatus?.cannotDepositCash;
        this.reslockStatusDepositCoin =
          storedData?.reslockStatus?.cannotDepositCoin;
      }
    }
  },
};
</script>
<style lang="scss">
.deposite-cash-container {
  border-radius: 8px;
  position: relative;
  .margin-l-24 {
    margin-left: 24px;
  }
  .bt-sec {
    font-family: "Sarabun";
  }
  .dcc-head {
    button {
      display: inline-block;
      font-size: 16px;
      line-height: 19px;
      padding: 12.5px 0;
      margin-left: 24px;
      border-bottom: 2px solid transparent;
      color: #677f8e;
      &:hover {
        border-bottom: 2px solid #09BE8B;
        color: #d6dde1;
      }
    }

    button.active {
      border-bottom: 2px solid #09BE8B;
      color: #d6dde1;
    }
  }
  .center-section {
    .l-24 {
      line-height: 24px;
    }
    .select-container {
      background-color: #191b1c;
      color: #d6dde1;
      border-radius: 4px;
      height: 40px;
      width: 240px;
      line-height: 24px;
      margin: auto 0;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .icon {
        pointer-events: none;
        position: absolute;
        right: 12px;
      }
      .rotate-sc-icon {
        transform: rotateX(180deg);
      }
      .text {
        padding-left: 15px;
      }
      .options {
        position: absolute;
        bottom: -82px;
        width: 100%;
        background-color: #191b1c;
        border-radius: 4px;
        ul {
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 15px;
            border-radius: 4px;
            &:hover {
              background-color: #2c3b44;
            }
          }
        }
      }
      .hidden {
        display: none;
      }
      .show {
        display: block;
      }
    }
    .input-container {
      input {
        background-color: #191b1c;
        color: #d6dde1;
        border-radius: 4px;
        height: 40px;
        padding: 0 50px 0 18px;
        line-height: 24px;
        &::placeholder {
          color: #566a76;
        }
      }
      span {
        color: #9bacb6;
        margin-left: -40px;
        line-height: 24px;
      }
    }
    .info {
      color: #d6dde1;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 700;
      font-size: 14px;
      line-height: 24px;
      p {
        margin: 0;
      }
      ul {
        list-style: disc;
      }
    }
    .lr {
      font-size: 16px;
      span {
        font-size: 14px;
        color: #09BE8B;
        margin-left: 8px;
      }
    }
    .bcb {
      margin-top: 24px;
      margin-bottom: 16px;
      border-top: 1px solid #28363e;
    }
    .bc-info {
      p {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 8px;
      }
      ul {
        list-style: disc;
        padding: 0 24px;
        margin-bottom: 16px;
        li {
          color: #9bacb6;
          width: 70%;
        }
      }
      ul.number {
        list-style: decimal;
      }
    }
  }

  .bottom-section {
    padding-top: 24px;
    border-top: 1px solid #28363e;
    input {
      background: #191b1c;
      border: 1px solid #3c515d;
      border-radius: 2px;
      height: 17px;
      width: 17px;
    }
    input:checked {
      background-color: #09BE8B;
      border: 1px solid #3c515d;
    }
    span {
      margin-left: 10px;
      line-height: 24px;
    }
    .last-btn {
      text-align: center;
      margin-top: 14px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      button {
        color: #0a0d10;
        background-color: #09BE8B;
        border-radius: 4px;
        height: 36px;
        width: 132px;
        &:hover {
          background-color: #66cdb0;
        }
      }
      button.inactive {
        opacity: 0.2;
      }
    }
  }
}
.right-info {
  position: absolute;
  top: 3px;
  left: 630px;
  color: #677f8e;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  right: -260px;
  line-height: 24px;
  p {
    margin-bottom: 10px;
  }
  ul {
    font-weight: 400;
    list-style: disc;
    position: relative;
    left: -8px;
    li {
      &:hover {
        color: #d6dde1;
      }
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
@media (max-width: 640px) {
  .deposite-cash-container {
    width: auto;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
    .center-section {
      padding: 0;
    }
    // .select-container {
    //   select {
    //     width: 190px;
    //   }
    // }
    .input-container {
      input {
        width: 190px;
      }
    }
  }
}
@media (min-width: 640px) {
  .deposite-cash-container {
    width: 616px;
    padding-bottom: 0;
    .center-section {
      padding: 0 14px;
    }
    // .select-container {
    //   select {
    //     width: 240px;
    //   }
    // }
    .input-container {
      input {
        width: 240px;
      }
    }
  }
  .qr-h {
    height: 458px;
  }
  .bc-h {
    height: 580px;
  }
}
@media (min-width: 100px) {
  .right-info {
    display: none;
  }
}
@media (min-width: 1024px) {
  .right-info {
    display: block;
    // right: -14px;
    // margin-right: -255px;
    top: 0;
  }
  .deposite-cash-container {
    transform: translateX(-50%);
  }
}
@media (max-width: 848px) {
  .deposite-cash-container {
    background-color: none;
    // margin-left: 40px;
    width: 100%;
    .dcc-head {
      background-color: #121516;
    }
    .bottom-section {
      margin: 0 40px;
    }
    .bcb {
      margin: 0 20px;
    }
  }
}
@media (min-width: 848px) {
  .deposite-cash-container {
    background-color: #121516;
    margin-left: 50%;
    margin-top: 32px;
    transform: translateX(-75.7%);
    .dcc-head {
      border-bottom: 1px solid #28363e;
    }
    .bottom-section {
      margin: 0 25px;
    }
    // .bcb {
    //   margin: 0 -4px;
    // }
  }
}
</style>
