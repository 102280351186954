import Vue from "vue";
import Vuex from "vuex";
import router from "./routes";
import { HTTP } from "./axios";
Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    user: null,
    limitOrderData: null,
    marketCoinSearchField: "",
    ChkHiddenMinCoin: false,
    ChkOrderConfirm: false,
    currentPair: "btc_thb",
    dCoinName: "",
    chekModalNoti:false,
    hide_menu: false
  },
  mutations: {
    SET_USER(state, user) {
      state.user = user;
    },
    setMarketCoinSearchField(state, value) {
      state.marketCoinSearchField = value;
    },
    setLimitOrderData(state, value) {
      state.limitOrderData = value;
    },
    setHiddenMinCoin(state, value) {
      state.ChkHiddenMinCoin = value;
    },
    setChkOrderConfirm(state, value) {
      state.ChkOrderConfirm = value;
    },
    setCurrentPair(state, value) {
      state.currentPair = value;
    },
    setdCoinName(state, value) {
      state.dCoinName = value;
    },
    setChekModalNoti(state, value){
      state.chekModalNoti = value;
    }
  },
  getters: {
    user: (state) => state.user,
  },
  actions: {
    setUser({ commit }, user) {
      commit("SET_USER", user);
    },
    logout() {
      HTTP.post("/uc/loginout").then(function (res) {
        localStorage.clear();
        router.go(0);
      }).catch((err) => {
        console.log(err);
      });
    },
    updateMemberConfigOrderConfirm(value) {
      HTTP.post("/uc/member/update-member-config", {
        name: "ORDER_CONFIRM_BEFORE_SUBMIT",
        value: value.state.ChkOrderConfirm ? "0" : "1",
      })
        .then((res) => {
          
        })
        .catch((error) => {
          console.log(err);
        });
    },
  },
});
