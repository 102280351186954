<template>
  <div :class="tl ? 'sloss' : ''">
    <div class="container-fluid p-0" style="margin-left: 24px">
      <div class="justify-content-start d-flex flex-row scan">
        <div class="second-selectbox">
          <div
            class="select-container"
            tabindex="0"
            @click="open_dropdown = !open_dropdown"
            @blur="open_dropdown = false"
          >
            <span class="text">{{ list[scannnerValue] }}</span>
            <span class="icon" :class="open_dropdown ? 'rotate-sc-icon' : ''"
              ><svg
                width="12"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <div class="options" :class="open_dropdown ? 'show' : 'hidden'">
              <ul>
                <li v-if="type_scanner.length > 0">--------------- Scanner ------------</li>
                <!-- type_scanner -->
                <li v-if="type_scanner.length > 0" v-for="(item, index) in type_scanner" @click="checkscanner(item)">{{item.formularName}}</li>
                <!-- <li @click="scannnerValue = '5'">Pull the Bull</li>
                <li @click="scannnerValue = '8'">Pull the Bull (60 Min)</li>
                <li @click="scannnerValue = '6'">HH_RSI</li>
                <li @click="scannnerValue = '7'">HH_MACD Cross</li> -->
                <li>--------------- Market Movement ------------</li>
                <li @click="scannnerValue = '1'">Most Active</li>
                <li @click="scannnerValue = '2'">Top Gain/Loss</li>
                <li @click="scannnerValue = '4'">New High/ Low</li>
                <li @click="scannnerValue = '3'">Volume Outperform</li>
              </ul>
            </div>
          </div>
        </div>

        <div
          class="info"
          :style="scannnerValue == 3 ? 'margin-right:55px' : ''"
        >
          <svg
            data-v-1252a5dc=""
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            fill="currentColor"
            viewBox="0 0 16 16"
            class="bi bi-question-circle"
          >
            <path
              data-v-1252a5dc=""
              d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z"
            ></path>
            <path
              data-v-1252a5dc=""
              d="M5.255 5.786a.237.237 0 0 0 .241.247h.825c.138 0 .248-.113.266-.25.09-.656.54-1.134 1.342-1.134.686 0 1.314.343 1.314 1.168 0 .635-.374.927-.965 1.371-.673.489-1.206 1.06-1.168 1.987l.003.217a.25.25 0 0 0 .25.246h.811a.25.25 0 0 0 .25-.25v-.105c0-.718.273-.927 1.01-1.486.609-.463 1.244-.977 1.244-2.056 0-1.511-1.276-2.241-2.673-2.241-1.267 0-2.655.59-2.75 2.286zm1.557 5.763c0 .533.425.927 1.01.927.609 0 1.028-.394 1.028-.927 0-.552-.42-.94-1.029-.94-.584 0-1.009.388-1.009.94z"
            ></path>
          </svg>
          <p v-if="scannnerValue == 1" style="top: -5px; left: 45px">
            เหรียญที่มีปริมาณาการซื้อขายสูงสุด
          </p>
          <p v-if="scannnerValue == 2" style="top: -5px; left: 45px">
            เหรียญที่ราคาซื้อขายเพิ่มขึ้นหรือลดลงมากที่สุด
          </p>
          <p v-if="scannnerValue == 3" style="top: -5px; left: 45px">
            เหรียญที่มีปริมาณการซื้อขายมากกว่าปกติ
          </p>
          <p v-if="scannnerValue == 4" style="top: -40px">
            เหรียญที่ทำราคาสูงสุดหรือต่ำสุดใหม่
          </p>

          <p v-if="scannnerValue == 5" style="top: -63px">
            เหรียญที่มีโมเมนตัมเป็นบวกต่อเนื่อง
            <br />
            คอนเฟิร์มแนวโน้มขาขึ้นด้วยราคาและอินดิเคเตอร์ที่กำลังร้อนแรงและเป็นที่สนใจ
          </p>
          <p v-else-if="scannnerValue == 8" style="top: -63px">
            เหรียญที่มีโมเมนตัมเป็นบวกต่อเนื่องใน Timeframe 60 นาที
            <br />
            คอนเฟิร์มแนวโน้มขาขึ้นด้วยราคาและอินดิเคเตอร์ที่กำลังร้อนแรงและเป็นที่สนใจ
          </p>
          <p v-else-if="scannnerValue == 6" style="top: -63px">
            เหรียญที่ราคาเพิ่งกลับตัวจากโซนล่าง ต้นทุนต่ำเพราะอยู่ต้นน้ำ
            <br />
            พร้อมคอนเฟิร์มด้วยวอลลุ่มการซื้อขายที่โดดเด่น
          </p>
          <p v-else-if="scannnerValue == 7" style="top: -63px">
            เหรียญที่เริ่มมีสัญญาณเชิงบวก MACD เพิ่งตัด Signal ในวันแรก
            <br />
            พร้อมคอนเฟิร์มด้วยวอลลุ่มการซื้อขายที่โดดเด่น
          </p>
        </div>

        <div
          class="third-selectbox"
          v-if="scannnerValue == 4"
          style="margin-right: 28px"
        >
          <div
            class="select topGain lh-24"
            style="
              border-radius: 2px;
              width: 120px;
              margin-left: 38px;
              height: 32px;
            "
            tabindex="0"
            @click="open_dropdown_topGain = !open_dropdown_topGain"
            @blure="open_dropdown_topGain = false"
          >
            <span class="text lh-24" style="left: 10px">{{
              volumeList[top]
            }}</span>
            <span
              class="icon"
              :class="open_dropdown_topGain ? 'rotate-sc-icon' : ''"
              ><svg
                width="8"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <div
              class="options"
              v-show="open_dropdown_topGain"
              style="left: 0px; top: 33px"
            >
              <ul>
                <li @click="top = '0'">New High</li>
                <li @click="top = '1'">New Low</li>
              </ul>
            </div>
          </div>
          <!-- <select
            class="form-select form-select-sm w-auto text-light shadow-none borderColor"
            aria-label=".form-select-sm example"
            style="background-color: #191b1c"
            v-model="top"
          >
            <option value="0" selected>New High</option>
            <option value="1">New Low</option>
          </select> -->
        </div>

        <div
          class="third-selectbox"
          v-if="scannnerValue == 2"
          style="margin-right: 24px"
        >
          <div
            class="select topGain lh-24"
            style="margin-left: 26px; width: 122px"
            tabindex="0"
            @click="open_dropdown_topGain = !open_dropdown_topGain"
            @blure="open_dropdown_topGain = false"
          >
            <span class="text lh-24" style="left: 10px">{{
              topList[top]
            }}</span>
            <span
              class="icon"
              :class="open_dropdown_topGain ? 'rotate-sc-icon' : ''"
              ><svg
                width="8"
                height="8"
                viewBox="0 0 12 8"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <div
              class="options"
              v-show="open_dropdown_topGain"
              style="left: 0px; top: 33px"
            >
              <ul>
                <li @click="top = '0'">Top Gain</li>
                <li
                  @click="
                    top = '1';
                    tl = true;
                  "
                >
                  Top Loss
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div
          class="radio_btn"
          v-if="scannnerValue == 3 || scannnerValue == 4"
          style="margin-left: 0px"
        >
          <input @click="change_time('m')" type="radio" id="min" name="time" />
          <label for="min" class="m-0">Min</label>
        </div>
        <div
          class="radio_btn"
          v-if="scannnerValue == 3 || scannnerValue == 4"
          style="margin-left: 21px"
        >
          <input @click="change_time('h')" type="radio" id="hour" name="time" />
          <label for="hour" class="m-0">Hour</label>
        </div>
        <div
          class="radio_btn"
          v-if="scannnerValue == 3 || scannnerValue == 4"
          style="margin-left: 21px"
        >
          <input
            @click="change_time('d')"
            type="radio"
            id="day"
            name="time"
            checked
          />
          <label for="day" class="m-0">Day</label>
        </div>
        <div
          class="radio_btn position-relative"
          style="margin-left: 11px"
          v-if="scannnerValue == 3 || scannnerValue == 4"
        >
          <!-- <input type="number" v-model="day" min="1" class="from-control" /> -->
          <div
            v-if="day != '5' && show_input == false"
            class="select daydropdown topGain lh-24"
            tabindex="0"
            @click="open_dropdown_day = !open_dropdown_day"
            @blure="open_dropdown_day = false"
          >
            <span class="text lh-24" style="left: 10px">{{
              dayList[day]
            }}</span>
            <div class="icons">
              <span><b-icon icon="caret-up-fill"></b-icon></span>
              <span><b-icon icon="caret-down-fill"></b-icon></span>
            </div>
            <div
              class="options"
              v-show="open_dropdown_day"
              @onchange="change_period()"
            >
              <ul>
                <li @click="day = '0'">Period 5 Days</li>
                <li @click="day = '1'">Period 10 Days</li>
                <li @click="day = '2'">Period 25 Days</li>
                <li @click="day = '3'">Period 75 Days</li>
                <li @click="day = '4'">Period 200 Days</li>
                <li @click="show_input == true">Period Direct Input</li>
              </ul>
            </div>
          </div>
          <div v-if="show_input == true">
            <input
              type="number"
              v-model="day_input"
              min="10"
              class="from-control"
            />
          </div>

          <label class="m-0" style="position: relative; left: -3px"
            >Day Ago</label
          >
        </div>
      </div>
    </div>
    <div class="" v-if="top == 0 && scannnerValue == 2 && scannnerValue != 4">
      <!-- Tree map -->
      <div class="text-center">
        <div v-if="isLoading == true" class="loader-wrapper">
          <div class="loader"></div>
        </div>
        <div v-if="this.chartOptions.xaxis.categories.length != 0 && !isLoading" class="custom-charts pad">
          <div  id="chart1" :class="class_chart_1">
            <apexchart
              type="bar"
              height="200"
              :options="chartOptionsdata_1"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </div>

      <!-- Tree map end -->
    </div>
    <div class="" v-if="top == 1 && scannnerValue == 2 && scannnerValue != 4">
      <!-- Tree map -->
      <div class="text-center">
        <div v-if="isLoading == true" class="loader-wrapper">
          <div class="loader"></div>
        </div>
        <div  v-if="this.chartOptions_2.xaxis.categories.length != 0 && !isLoading" class="custom-charts pad">
          <div id="chart2" :class="class_chart_2">
            <apexchart
              v-if="series_2.length != 0"
              type="bar"
              height="200"
              :options="chartOptionsdata_2"
              :series="series_2"
            ></apexchart>
          </div>
        </div>
        <!-- Tree map end -->
      </div>
    </div>
    <div v-if="scannnerValue == 4 && top == 0">
      <!-- Tree map -->
      <div class="text-center">
        <div v-if="isLoading == true" class="loader-wrapper">
          <div class="loader"></div>
        </div>
        <div v-if="this.chartOptions.xaxis.categories.length != 0 && !isLoading" class="custom-charts pad">
          <div id="chart1" :class="class_chart_1">
            <apexchart
              type="bar"
              height="200"
              :options="chartOptionsdata_1"
              :series="series"
            ></apexchart>
          </div>
        </div>
      </div>

      <!-- Tree map end -->
    </div>
    <div v-if="scannnerValue == 4 && top == 1">
      <!-- Tree map -->
      <div class="text-center">
        <div v-if="isLoading == true" class="loader-wrapper">
          <div class="loader"></div>
        </div>
        <div  v-if="this.chartOptions_2.xaxis.categories.length != 0 && !isLoading" class="custom-charts pad">
          <div id="chart2" :class="class_chart_2">
            <apexchart
              v-if="series_2.length > 0"
              type="bar"
              height="200"
              :options="chartOptionsdata_2"
              :series="series_2"
            ></apexchart>
          </div>
        </div>
        <!-- Tree map end -->
      </div>
      <!-- Tree map end -->
    </div>
    <!-- <volume-table
      :TableData="data_volume"
      :favCoinRows="favCoinRows"
      :Showfavorite="true"
      v-if="scannnerValue == 3"
      class="s1 s2"
    ></volume-table> -->
    <Dynamic-Table
      v-if="scannnerValue == 3"
      :TableHeader="TableHeading_volume"
      :TableData="data_volume"
      :favCoinRows="favCoinRows"
      :scannnerValue="scannnerValue"
      :showvolume="true"
      :Showfavorite="true"
    ></Dynamic-Table>
    <!-- <highTableVue class="s1 nhl" v-else-if="scannnerValue == 4"> </highTableVue> -->
    <Dynamic-Table
      v-else-if="scannnerValue == 4 && top == 0 && TableDatas.length != 0"
      :TableHeader="TableHeading_scan"
      :TableData="TableDatas"
      :favCoinRows="favCoinRows"
      :Showfavorite="true"
    ></Dynamic-Table>
    <Dynamic-Table
    v-else-if="scannnerValue == 4 && top == 1 && TableDatas2.length !=0"
      :TableHeader="TableHeading_scan"
      :TableData="TableDatas2"
      :favCoinRows="favCoinRows"
      :Showfavorite="true"
    ></Dynamic-Table>
    <Dynamic-Table
      v-else-if="scannnerValue == 2 && top == '0' && TableDatas.length !=0"
      :TableHeader="TableHeading"
      :TableData="TableDatas"
      :favCoinRows="favCoinRows"
      :ShowGainLoss="true"
    ></Dynamic-Table>
    <Dynamic-Table
      v-else-if="scannnerValue == 2 && top == '1' && TableDatas2.length !=0"
      :TableHeader="TableHeading"
      :TableData="TableDatas2"
      :favCoinRows="favCoinRows"
      :ShowGainLoss="true"
    ></Dynamic-Table>
    <Dynamic-Table
      v-else-if="scannnerValue == 1"
      :TableHeader="TableHeading"
      :favCoinRows="favCoinRows"
      :TableData="filteredCoinMarket"
      :Showfavorite="true"
      :PChartLabels="chart_labels"
      :PSeries="chart_series"
      current_page="allCoin"
    ></Dynamic-Table>
    <Dynamic-Table
      v-else-if="scannnerValue == 5 && filteredCoinMarket_scan.length != 0"
      :TableHeader="TableHeading"
      :favCoinRows="favCoinRows"
      :TableData="filteredCoinMarket_scan"
      :Showfavorite="true"
      :PChartLabels="chart_labels"
      :PSeries="chart_series"
      current_page="allCoin"
    ></Dynamic-Table>
    <p v-else class="text-center">--ไม่มีข้อมูล--</p>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import Table from "../../components/table/Table.vue";
import VolumeTable from "@/components/scanner/VolumeTable.vue";
import highTableVue from "@/components/scanner/highTable.vue";
import VueApexCharts from "vue-apexcharts";
export default {
  name: "scan",

  components: {
    "Dynamic-Table": Table,
    apexchart: VueApexCharts,
    VolumeTable,
    highTableVue,
  },
  data() {
    return {
      data_volume: [],
      show_input: false,
      day_input: 0,
      series: [
        {
          name: "Cash Flow",
          data: [],
        },
      ],
      chartOptions: {
        legend: { show: false },
        tooltip: { enabled: false },
        xaxis: {
          categories: [],
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "rgb(255, 255, 255)" },
          },
        },
        yaxis: {
          show: true,
        },
        chart: {
          type: "bar",
          height: 360,
          width: "50%",
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
                { from: 0, to: 100, color: "#0c9" },
                { from: -100, to: 0, color: "#f72000" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -50,
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["rgb(255, 255, 255)"] },
        },
        grid: { show: false },
      },
      series_2: [
        {
          name: "Cash Flow",
          data: [],
        },
      ],
      series_high: [
        {
          name: "Cash Flow",
          data: [],
        },
      ],
      series_low: [
        {
          name: "Cash Flow",
          data: [],
        },
      ],
      class_chart_1: "mx-auto",
      class_chart_2: "mx-auto",
      chartOptions_2: {
        legend: { show: false },
        tooltip: { enabled: false },
        xaxis: {
          position: "top",
          categories: [],
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "rgb(255, 255, 255)" },
          },
        },
        yaxis: {
          show: true,
        },
        chart: {
          type: "bar",
          height: 360,
          width: "50%",
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
                { from: 0, to: 100, color: "#0c9" },
                { from: -100, to: 0, color: "#f72000" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 0,
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["rgb(255, 255, 255)"] },
        },
        grid: { show: false },
      },
      tl: false,
      chart_labels: [],
      chart_series: [],
      open_dropdown_day: false,
      day: "",
      dayList: [
        "Period 5 Days",
        "Period 10 Days",
        "Period 25 Days",
        "Period 75 Days",
        "Period 200 Days",
        "Period Direct Input",
      ],
      scannnerValue: "1",
      open_dropdown_topGain: false,
      topList: ["Top Gain", "Top Loss"],
      volumeList: ["New High", "New Low"],
      top: "0",
      open_dropdown: false,
      list: [
        "",
        "Most Active",
        "Top Gain/ Loss",
        "Volume Outperform",
        "New High/ Low",
        "Pull the Bull",
        "HH_RSI",
        "HH_MACD Cross",
        "Pull the Bull (60 Min)",
      ],
      data_all: [],
      TableHeading: [
        {
          headingCoin: "Coin",
          headingLastPrice: "Last Price",
          headingHourChange: "Change (24h)",
          headingVolume: "Volume",
          headingMarketCap: "Market Cap",
          headingChart: "Chart (24h)",
        },
      ],
      TableHeading_scan: [
        {
          headingCoin: "Coin",
          headingLastPrice: "Last Price",
          headingHourChange: "%Change",
          headingVolume: "Volume",
          headingChart: "%Prev Vo",
          headingVolume: "200D High",
          headingMarketCap: "Market Cap",
        },
      ],
      TableHeading_volume: [
        {
          headingCoin: "Coin",
          headingLastPrice: "Last Price",
          // headingChart: "Chart (24h)",
          headingHourChange: "%Change",
          headingVolumeHour: "Vol. (1 hour ago)",
          headingMarketCap: "Market Cap",
          headingVolume: "Volume",
          headingVolumeUP: "Vol Up",
          headingUp: "%Vol Up",
        },
      ],
      coinMarket: [],
      currentCoin: {
        base: "",
        coin: "",
        symbol: "BTC/USDT",
      },
      TableDatas: [],
      TableDatas2: [],
      series: [],
      seriesLoss: [
        {
          name: "Cash Flow",
          data: [
            "-98%",
            "-96%",
            "-94%",
            "-90%",
            "-85%",
            "-80%",
            "-75%",
            "-70%",
            "-60%",
            "-55%",
            "-53%",
            "-50%",
            "-48%",
            "-45%",
            "-40%",
            "-33%",
            "-30%",
            "-28%",
            "-25%",
            "-18%",
          ],
        },
      ],
      favCoinRows: [],
      isLoading: false,
      type_scanner: [],
      data_scanner:[],
    };
  },
  watch: {},
  computed: {
    chartOptionsdata_1() {
      this.isLoading = true;
      var chartOptions = {
        legend: { show: false },
        tooltip: {
          fillSeriesColor: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const xValue = w.globals.labels[dataPointIndex]; // Access X-axis label
            const yValue = series[seriesIndex][dataPointIndex]; // Access Y-axis value

            return (
              `<div class="arrow_box">` +
              `<span class="box" style="color: #398648;"> ${xValue} : +${yValue} %</span>` +
              `<span class="tooltip">This is the tooltip content.</span>` +
              "</div>"
            );
          },
          enabled: true,
          hideEmptySeries: true,
          inverseOrder: true,
          // marker: {
          //   show: true,
          // },
        },
        xaxis: {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
          position: "bottom",
          categories: [],
          opposite: false,
          axisTicks: {
            show: false,
          },

          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "rgb(255, 255, 255)" },
          },
        },
        yaxis: {
          min: 0,
          max: 0,
          show: false,
        },
        chart: {
          type: "bar",
          height: 200,
          width: "100%",
          // offsetX: 22,
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "55",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
                { from: 0, to: 100, color: "#0c9" },
                { from: -100, to: 0, color: "#f72000" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          position: "top",
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["rgb(255, 255, 255)"] },
        },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      };
      var r1 = [];
      r1 = this.check_d_1();
      if (r1.categories.length != 0) {
        chartOptions.xaxis.categories = r1.categories;
      }
      if (r1.chg.length != 0) {
        this.series[0].data = r1.chg;
      }
      let y_max = r1.chg[r1.chg.length - r1.chg.length];
      chartOptions.yaxis.max = y_max;
      this.isLoading = false;
      return chartOptions;
    },
    chartOptionsdata_2() {
      this.isLoading = true;
      var r = [];
      var dataoption_2 = {
        legend: { show: false },
        tooltip: {
          fillSeriesColor: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const xValue = w.globals.labels[dataPointIndex]; // Access X-axis label
            const yValue = series[seriesIndex][dataPointIndex]; // Access Y-axis value
            return (
              `<div class="arrow_box">` +
              `<span class="box" style="color: #f72000;"> ${xValue} : ${yValue} %</span>` +
              `<span class="tooltip">This is the tooltip content.</span>` +
              "</div>"
            );
          },
          enabled: true,
          hideEmptySeries: true,
          inverseOrder: true,
          // marker: {
          //   show: true,
          // },
        },
        xaxis: {
          gridLines: {
            display: false,
          },
          position: "top",
          categories: r,
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "rgb(255, 255, 255)" },
          },
        },
        yaxis: {
          min: -1,
          max: 0,
          ticks: {
            beginAtZero: false,
          },
          suggestedMin: 0,
          show: false,
        },
        chart: {
          type: "bar",
          height: 200,
          width: "100%",
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "55",
            dataLabels: { position: "top" },
            offsetY: -100,
            colors: {
              ranges: [
                { from: 0, to: 100, color: "#0c9" },
                { from: -100, to: 0, color: "#f72000" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          position: "top",
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["rgb(255, 255, 255)"] },
        },
        grid: { show: false },
      };
      dataoption_2.xaxis.categories = [];
      r = this.check_d();
      if (r.categories.length != 0) {
        dataoption_2.xaxis.categories = r.categories;
      }
      if (r.chg.length != 0) {
        this.series_2[0].data = r.chg;
      }
      let y_min = r.chg[r.chg.length - r.chg.length];
      
      dataoption_2.yaxis.min = y_min;
      this.isLoading = false;
      return dataoption_2;
    },
    chartOptionsdata_high() {
      var chartOptions = {
        legend: { show: false },
        tooltip: { enabled: false },
        xaxis: {
          position: "bottom",
          categories: [],
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "rgb(255, 255, 255)" },
          },
        },
        yaxis: {
          show: false,
        },
        chart: {
          type: "bar",
          height: 300,
          // width: "50%",
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
                { from: 0, to: 100, color: "#0c9" },
                { from: -100, to: 0, color: "#f72000" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -50,
          position: "top",
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["rgb(255, 255, 255)"] },
        },
        grid: { show: false },
      };
      var r1 = [];
      r1 = this.check_d_1();
      if (r1.categories.length != 0) {
        chartOptions.xaxis.categories = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ];
      }
      if (r1.chg.length != 0) {
        this.series_high[0].data = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ];
      }
      return chartOptions;
    },
    chartOptionsdata_low() {
      const tmp = [];
      var r = [];
      var dataoption_2 = {
        legend: { show: false },
        tooltip: { enabled: false },
        xaxis: {
          position: "top",
          categories: r,
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "rgb(255, 255, 255)" },
          },
        },
        scales: {
          y: {
            beginAtZero: true,
          },
        },
        yaxis: {
          show: false,
        },
        chart: {
          type: "bar",
          height: 300,
          // width: "50%",
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            columnWidth: "50%",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
                { from: 0, to: 100, color: "#0c9" },
                { from: -100, to: 0, color: "#f72000" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["rgb(255, 255, 255)"] },
        },
        grid: { show: false },
      };
      dataoption_2.xaxis.categories = [];
      r = this.check_d();
      if (r.categories.length != 0) {
        dataoption_2.xaxis.categories = [
          1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20,
        ];
      }
      if (r.chg.length != 0) {
        this.series_low[0].data = [
          -20, -19, -18, -17, -16, -15, -14, -13, -12, -11, -10, -9, -8, -7, -6,
          -5, -4, -3, -2, -1,
        ];
      }
      return dataoption_2;
    },
    filteredCoinMarket() {
      let compareWith = this.$store.state.marketCoinSearchField;
      const filteredCoins = this.coinMarket;
      // .filter(
      //   (coin) =>
      //     coin.Coinsybmol.toLowerCase().indexOf(compareWith.toLowerCase()) !==
      //     -1
      // )
      // .map((coin) => {
      //   coin.volume = coin.volume.toString();
      //   return coin;
      // })

      // filteredCoins.sort(
      //   (a, b) => parseFloat(b.hourChange) - parseFloat(a.hourChange)
      // );
      // return false
      return filteredCoins;
    },
    filteredCoinMarket_scan() {
      let compareWith = this.$store.state.marketCoinSearchField;
      const filteredCoins = this.data_scanner;
      // .filter(
      //   (coin) =>
      //     coin.Coinsybmol.toLowerCase().indexOf(compareWith.toLowerCase()) !==
      //     -1
      // )
      // .map((coin) => {
      //   coin.volume = coin.volume.toString();
      //   return coin;
      // })

      filteredCoins.sort(
        (a, b) => parseFloat(b.hourChange) - parseFloat(a.hourChange)
      );
      // return false
      return filteredCoins;
    },
    isLoss() {
      if (this.top == 1 && this.scannnerValue == 2) {
        return true;
      } else {
        return false;
      }
    },
  },
  mounted() {},
  created() {
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if (!user_data?.id) {
      this.isUserLogin = false;
    } else {
      this.isUserLogin = true;
    }
    this.getSymbolThumb();
    this.get_scantype();
    // this.getSymbolThumb();
    this.getGainLoss(2);
    this.getGainLoss(3);
  },
  methods: {
    change_period(value) {
      // console.log(value)
    },
    checkscanner(value) {
      this.data_scanner = []
      if(value.id == '' || value.id == undefined){
        return false
      }
      var obj = {
        formularId  : value.id
      }
      HTTP.post("/market/scanner-thumb",obj).then((response) => {
        if(response.data.length > 0){
          
          var data_volume = [];
        for (let i = 0; i < response.data.length; i++) {
          // console.log(response.data)
          let symbolArr = response.data[i].symbol.split("/");
          let symbol = (symbolArr[0] + "_" + symbolArr[1]).toLowerCase();
          let color = "";
          if (response.data[i].chg > 0) {
            color = "green";
          } else if (response.data[i].chg < 0) {
            color = "red";
          }
          let coinMarket = {
            Coinsybmol: response.data[i].symbol,

            StarIcon: require("../../assets/images/favoriteTable/star.png"),
            CoinImage:
              this.MARKET_WS_URL + `/market/coin/image/${response.data[i].logoFileId}`,
            CoinName: response.data[i].coinName,
            lastPrice: response.data[i].close,
            hourChange: response.data[i].chg,
            volume: response.data[i].marketCapVolume24h,
            volumeup: response.data[i].marketCapVolume24h,
            marketCap: response.data[i].marketCap,
            series: [
              {
                name: "STOCK ABC",
                data: response.data[i].trend,
              },
            ],

            //
            // price: response.data[i].close,
            // pChg: response.data[i].chg,
            // url: "/exchange/" + symbol,
            // color: color,
          };
          this.data_scanner.push(coinMarket);
          let dataVolumeEntry = {
            Coinsybmol: coinMarket.Coinsybmol,
            StarIcon: coinMarket.StarIcon,
            CoinImage: coinMarket.CoinImage,
            CoinName: coinMarket.CoinName,
            marketCap: response.data[i].marketCap,
            lastPrice: coinMarket.lastPrice,
            hourChange: coinMarket.hourChange,
            volume: coinMarket.volume,
            volumeUp: coinMarket.volumeup,
          };

          // เพิ่มอ็อบเจกต์ dataVolumeEntry เข้าไปใน data_volume
          data_volume.push(dataVolumeEntry);
          if (this.currentCoin.symbol == response.data[i].symbol) {
            if (response.data[i].chg < 0) {
              this.priceInfo.textColor = "red";
            } else if (response.data[i].chg > 0) {
              this.priceInfo.textColor = "green";
            }

            this.priceInfo.chg = response.data[i].change;
            this.priceInfo.high24 = response.data[i].high;
            this.priceInfo.low24 = response.data[i].low;
            this.priceInfo.vol24 = response.data[i].volume;
            this.priceInfo.pChg = response.data[i].chg;
          }
        }
        this.data_scanner.sort(
          (a, b) => parseFloat(b.hourChange) - parseFloat(a.hourChange)
        );
          this.scannnerValue = 5
        }
      });
    },
    get_scantype() {
      HTTP.get("/market/get-all-scanner-formular-name").then((response) => {
        if (response.data.data.length > 0) {
          this.type_scanner = response.data.data;
        }
      });
    },
    change_time(value) {
      // console.log(value)
      return true;
    },
    getSymbolThumb() {
      this.isLoading = true;
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["size"] = 15;

      HTTP.post("/market/symbol-thumb-trend", {
        emulateJSON: true,
      }).then((response) => {
        this.isLoading = false;
        let resp = response.data;
        this.coinMarket = [];
        var data_volume = [];
        for (let i = 0; i < resp.length; i++) {
          // console.log(resp)
          let symbolArr = resp[i].symbol.split("/");
          let symbol = (symbolArr[0] + "_" + symbolArr[1]).toLowerCase();
          let color = "";
          if (resp[i].chg > 0) {
            color = "green";
          } else if (resp[i].chg < 0) {
            color = "red";
          }
          let coinMarket = {
            Coinsybmol: resp[i].symbol,

            StarIcon: require("../../assets/images/favoriteTable/star.png"),
            CoinImage:
              this.MARKET_WS_URL + `/market/coin/image/${resp[i].logoFileId}`,
            CoinName: resp[i].coinName,
            lastPrice: resp[i].close,
            hourChange: resp[i].chg,
            volume: resp[i].marketCapVolume24h,
            volumeup: resp[i].marketCapVolume24h,
            marketCap: resp[i].marketCap,
            series: [
              {
                name: "STOCK ABC",
                data: resp[i].trend,
              },
            ],

            //
            // price: resp[i].close,
            // pChg: resp[i].chg,
            // url: "/exchange/" + symbol,
            // color: color,
          };
          this.coinMarket.push(coinMarket);
          let dataVolumeEntry = {
            Coinsybmol: coinMarket.Coinsybmol,
            StarIcon: coinMarket.StarIcon,
            CoinImage: coinMarket.CoinImage,
            CoinName: coinMarket.CoinName,
            marketCap: resp[i].marketCap,
            lastPrice: coinMarket.lastPrice,
            hourChange: coinMarket.hourChange,
            volume: coinMarket.volume,
            volumeUp: coinMarket.volumeup,
          };

          // เพิ่มอ็อบเจกต์ dataVolumeEntry เข้าไปใน data_volume
          data_volume.push(dataVolumeEntry);
          if (this.currentCoin.symbol == resp[i].symbol) {
            if (resp[i].chg < 0) {
              this.priceInfo.textColor = "red";
            } else if (resp[i].chg > 0) {
              this.priceInfo.textColor = "green";
            }

            this.priceInfo.chg = resp[i].change;
            this.priceInfo.high24 = resp[i].high;
            this.priceInfo.low24 = resp[i].low;
            this.priceInfo.vol24 = resp[i].volume;
            this.priceInfo.pChg = resp[i].chg;
          }
        }
        this.coinMarket.sort(
          (a, b) => parseFloat(b.hourChange) - parseFloat(a.hourChange)
        );
        this.data_volume = data_volume.sort(
          (a, b) => parseFloat(a.volume) - parseFloat(b.volume)
        );
        if (this.isUserLogin) {
          this.getFov();
        } else {
          let fav_local = localStorage.getItem("fav");
          if (fav_local != null || fav_local != undefined) {
            var fav_local_s = fav_local.split(",");
          }
          if (fav_local != "" && fav_local != null) {
            for (let i = 0; i < this.coinMarket.length; i++) {
              for (let j = 0; j < fav_local_s.length; j++) {
                let typeSymbol = this.coinMarket[i].Coinsybmol;
                if (fav_local_s[j] == typeSymbol) {
                  this.$set(this.favCoinRows, typeSymbol, true);
                  break;
                } else {
                  this.$set(this.favCoinRows, typeSymbol, false);
                }
              }
            }
          }
        }
      });
    },
    getFov() {
      HTTP.post("uc/member/get-member-favorite").then((result) => {
        let memberFavorite = result.data;
        if (memberFavorite.code == 0) {
          if (this.isUserLogin) {
            for (let keyy in this.coinMarket) {
              if (memberFavorite.data.length > 0) {
                for (let key in memberFavorite.data) {
                  let typeSymbol = this.coinMarket[keyy].Coinsybmol;
                  if (memberFavorite.data[key].coinId == typeSymbol) {
                    if (memberFavorite.data[key].favorite) {
                      this.$set(this.favCoinRows, typeSymbol, true);
                      break;
                    } else {
                      this.$set(this.favCoinRows, typeSymbol, false);
                    }
                  } else {
                    this.$set(
                      this.favCoinRows,
                      this.coinMarket[keyy].Coinsybmol,
                      false
                    );
                  }
                }
              } else {
                this.$set(
                  this.favCoinRows,
                  this.coinMarket[keyy].Coinsybmol,
                  false
                );
              }
            }
          }
        }
      });
    },

    async getGainLoss(val) {
      await HTTP.get("/market/symbol-thumb")
        .then((res) => {
          const data = res.data;
          let categories = [];
          let chg = [];
          let tableDatas = [];

          data.forEach((element) => {
            tableDatas.push({
              StarIcon: "",
              CoinImage:
                this.MARKET_WS_URL + "/market/coin/image/" + element.logoFileId,
              Coinsybmol: element.symbol,
              CoinName: element.fullName,
              lastPrice: element.close,
              hourChange: element.chg,
              volume: element.marketCapVolume24h,
              marketCap: element.marketCap,
              chg: element.chg,
            });
          });
          this.data_all = tableDatas;
          if (val == 2) {
            const gainItems = tableDatas
              .sort((a, b) => b.chg - a.chg)
              .slice(0, 20);
            gainItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];

              this.chartOptions = {
                xaxis: {
                  ...this.chartOptions.xaxis,
                  categories: [...categories],
                },
              };
              if (x.chg > 0) {
                categories.push(coinName);
                chg.push(x.chg);
              }
              this.series = [{ ...this.series, data: [...chg] }];
            });
            let d_green = [];
            if (gainItems.length > 0) {
              for (let i = 0; i < gainItems.length; i++) {
                if (gainItems[i].chg > 0) {
                  d_green.push(gainItems[i]);
                }
              }
            }
            let class1 = 0.6 * chg.length;
            var r = Number(class1.toFixed(0)) + 3;
            this.class_chart_1 = r > 12 ? `col-12 mx-auto` : `col-${r} mx-auto`;
            this.TableDatas = d_green;
          } else if (val == 3) {
            const lossItems = tableDatas
              .sort((a, b) => b.chg - a.chg)
              .slice(0, 20)
              .reverse();
            let categories_2 = [];
            let chg_2 = [];
            lossItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];
              this.chartOptions_2 = {
                xaxis: {
                  ...this.chartOptions_2.xaxis,
                },
              };
              if (x.chg < 0) {
                categories_2.push(coinName);
                chg_2.push(x.chg);
              }
              this.series_2 = [{ data: [...chg_2] }];
            });
            
            this.chartOptions_2.xaxis.categories = categories_2
            const tmp = tableDatas.sort((a, b) => b.chg - a.chg).slice(0, 20);
            let d_red = [];
            if (lossItems.length > 0) {
              for (let i = 0; i < lossItems.length; i++) {
                if (lossItems[i].chg < 0) {
                  d_red.push(lossItems[i]);
                }
              }
            }
            let class2 = 0.6 * chg_2.length;
            var r = Number(class2.toFixed(0)) + 3;
            this.class_chart_2 = r > 12 ? `col-12 mx-auto` : `col-${r} mx-auto`;

            this.TableDatas2 = d_red;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    check_d() {
      var data_1 = {
        categories: this.chartOptions_2.xaxis.categories,
        chg: this.series_2[0].data,
      };
      return data_1;
    },
    check_d_1() {
      var data = {
        categories: this.chartOptions.xaxis.categories,
        chg: this.series[0].data,
      };
      return data;
    },
    decrement() {
      let d = this.day - 1;
      if (d <= 0) {
        return false;
      } else {
        this.day = this.day - 1;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
#app {
  overflow: hidden;
}

.topGain {
  width: 140px;
  cursor: pointer;
  height: 40px;
  background: #191b1c;
  border-radius: 4px;
  margin-left: 26px;
  padding-left: 12px;
  position: relative;
  padding-right: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.topGain svg {
  width: 12px !important;
}

.topGain ul {
  margin: 0px;
  list-style: none;
  padding: 0px;
  line-height: 24px;
}

.topGain ul li {
  padding: 10px;
}

.topGain ul li:hover {
  background-color: #2c3b44;
  cursor: pointer;
}

.topGain .options {
  z-index: 1;
  top: 34px !important;
  width: 100%;
  position: absolute;
  background: #191b1c;
  border-radius: 4px;
}

.radio_btn label {
  line-height: 24px;
}

.s1 table {
  position: unset !important;
  top: unset !important;

  thead {
    position: unset !important;
    top: unset !important;
  }
}

.s1 .Header-Name {
  /* padding-left: 27px !important; */
  padding-left: 42px !important;
}

.s1 thead th div {
  // padding: 0px !important;
  align-items: end;
}

.s1 thead th .d-flex > div:nth-child(2) {
  height: 18px;
}

.s1 thead .textGreyDark {
  vertical-align: initial;
}

.s1 .align-Icon-Heading {
  margin-bottom: 0px !important;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
  font-size: 12px;
}

.s1 .textGreyDark-table {
  line-height: 24px;
}

.s1 .h-t .textGreyDark-table {
  line-height: 24px;
  font-family: "Roboto Flex";
}

.s1 .textYellow {
  line-height: 19px;
  font-family: "Roboto Flex";
  font-weight: 400;
}

.s1 .coin-symbol-container span {
  font-family: "Roboto Flex" !important;
}

.s1 td:nth-child(1) {
  width: 433px;
}

.s1 td:nth-child(2) {
  width: 198px !important;
}

.sloss td:nth-child(2) {
  display: none;
}

.sloss th:nth-child(2) {
  display: none !important;
}

// .s1 table tr td {
//   padding-right: 30px !important;
// }
// .s1 table tr td:last-child {
//   padding-right: 0px !important;
// }
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 43, 47, 0.7);
  /* สีดำที่โปร่งแสง 70% */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* สร้าง loader ที่หมุน */
.loader {
  border: 4px solid rgba(255, 255, 255, 0.4);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.s1 {
  overflow: auto;
  padding-bottom: 512px !important;
}

.s1 table {
  min-width: 1000px;
}

.s2 table {
  min-width: 1200px;
  margin-top: 23px;
}

.s1 table tbody tr td {
  height: 72px;
}

.s1 .chevronUp {
  margin-top: -6px !important;
}

.s1 table tbody tr td {
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
}

.s1 table tbody tr td {
  // height: 81px;
  vertical-align: middle;
}

.nhl table tbody tr td {
  height: 54px !important;
  vertical-align: middle;
}

.s2 td:nth-child(1) {
  width: 270px !important;
}

.s2 .textSeprator {
  margin: 0px 12px !important;
}

// .loss table thead th:nth-child(2) {
//   display: none;
// }
// .loss table tbody tr td:nth-child(2) {
//   display: none;
// }
.loss table tbody tr td:nth-child(4) {
  color: #f72000 !important;
}

.nhl table tbody tr td:nth-child(2),
.nhl table tbody tr td:nth-child(6) {
  color: rgb(255, 255, 255) !important;
}
</style>

<style lang="scss">
.second-selectbox {
  .select-container {
    background-color: #191b1c;
    color: rgb(255, 255, 255);
    border-radius: 4px;
    height: 40px;
    width: 240px;
    margin: auto 0;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;

    .icon {
      pointer-events: none;
      position: absolute;
      right: 16px;

      svg {
        width: 12px !important;
        height: 8px;
      }
    }

    .rotate-sc-icon {
      transform: rotateX(180deg);
    }

    .text {
      padding-left: 12px;
      line-height: 24px;
    }

    .options {
      position: absolute;
      top: 42px;
      z-index: 1;
      width: 346px;
      background-color: #191b1c;
      border-radius: 4px;

      ul {
        margin: 0;
        padding: 0;

        li {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 8px;
          border-radius: 4px;
          font-weight: 400;
          font-size: 14px;
          line-height: 24px;

          &:hover {
            background-color: #2c3b44;
          }
        }
      }
    }

    .hidden {
      display: none;
    }

    .show {
      display: block;
    }
  }

  .input-container {
    input {
      background-color: #191b1c;
      color: rgb(255, 255, 255);
      border-radius: 4px;
      height: 40px;
      padding: 0 18px;

      &::placeholder {
        color: #566a76;
      }
    }

    span {
      color: #9bacb6;
      margin-left: -40px;
    }
  }

  .info {
    color: rgb(255, 255, 255);

    p {
      margin: 0;
    }

    ul {
      list-style: disc;
    }
  }

  .lr {
    font-size: 16px;

    span {
      font-size: 14px;
      color: #09BE8B;
      margin-left: 8px;
    }
  }

  .bcb {
    margin: 0 -27px;
    margin-top: 30px;
    margin-bottom: 20px;
    border-top: 1px solid #28363e;
  }

  .bc-info {
    ul {
      list-style: disc;
      padding: 0 24px;

      li {
        color: #9bacb6;
        width: 70%;
      }
    }

    ul.number {
      list-style: decimal;
    }
  }
}
</style>
<style scoped>
.pad {
  margin-right: 25px;
  margin-left: 25px;
}

.daydropdown {
  width: 155px !important;
  height: 36px !important;
  border-radius: 5px;
  margin: 0px;
}

.daydropdown .options {
  width: 143px;
  margin-top: 4px;
  left: 0px;
}

.daydropdown ul li {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  padding: 8px 12px;
  color: #ffffff;
}

#tooltip-target-1 {
  background: transparent;
  border: none;
  padding: 0px;
  margin: 0px 0px;
  outline: none !important;
  box-shadow: none !important;
}

.info {
  position: relative;
}

.info svg {
  cursor: pointer;
}

.info p {
  position: absolute;
  width: max-content;
  color: #677f8e;
  font-weight: 400;
  font-size: 13px;
  padding: 4px 8px;
  line-height: 24px;
  /* height: 56px; */
  z-index: 10;
  background: rgb(255, 255, 255);
  border-radius: 4px;
  left: 18px;
  display: none;
  font-family: "Noto Sans Thai";
}

.info:hover p {
  display: block;
}

.scan {
  align-items: center;
  margin-top: 21.44px;
  margin-bottom: 21px;
}

.scan svg {
  margin-left: 10px;
  width: 20px;
  height: 20px;
}

.scan select {
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #28363e !important;
  background: #677f8e
    url("data:image/svg+xml;utf8,<svg viewBox=%270 0 140 140%27 width=%2714%27 height=%2714%27 xmlns=%27http://www.w3.org/2000/svg%27><g><path d=%27m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z%27 fill=%27white%27/></g></svg>")
    no-repeat;
  background-position: right 7px top 50%;
}

.second-selectbox select {
  width: 240px !important;
  height: 40px;
  margin-left: 10px;
  border-radius: 4px;
  color: white;
  border: none;
}

.second-selectbox select option {
  font-size: 14px;
  line-height: 24px;
  -webkit-padding: 18px !important;
  /* display: inline-block; */
}

.third-selectbox select {
  width: 122px !important;
  height: 40px;
  margin-left: 26px;
  border-radius: 4px;
  color: white;
  border: none;
}

.scan .radio_btn {
  margin-left: 24px;
  display: flex;
  align-items: center;
  gap: 10px;
}

.scan .radio_btn label {
  margin-left: 8px;
  font-family: Roboto Flex;
  font-size: 14px;
}

input[type="radio"]:after {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  top: -4px;
  right: 4px;
  position: relative;
  background-color: #191b1c;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 2px solid #38454d;
}

input[type="radio"]:checked:after {
  width: 20px;
  height: 20px;
  border-radius: 100%;
  position: relative;
  background-color: rgb(255, 255, 255);
  content: "";
  display: inline-block;
  visibility: visible;
  border: 4px solid #09BE8B;
}

input[type="number"] {
  text-align: right;
  padding: 3px 24px;
  color: white;
  width: 85px;
  height: 36px;
  background-color: rgb(34, 43, 47);
  border-radius: 4px;
  border: none;
  outline: none;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.icons {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  right: 9px;
}

.icons span {
  line-height: 0px;
}

.icons span svg {
  width: 10px;
  height: 10px;
  color: #677f8e;
  cursor: pointer;
}

.number-wrapper {
  position: relative;
}
</style>
