<template>
  <div>
    <b-modal
      class="dc-qr-modal"
      id="deposit-coin-qr-modal"
      :hide-footer="true"
      :hide-header="true"
      no-close-on-backdrop
    >
      <div class="dc-qr-modal">
        <div id="html2print" style=" color: #999; background-color: #141b1f;">
          <div class="modal-head">
            <p>สแกน QR Code หรือ Copy Address</p>
            <p>เพื่อฝากเหรียญเข้า Portfolio ของคุณ</p>
            <span v-if="hiddenIconQrCode" @click="$bvModal.hide('deposit-coin-qr-modal')">
              <svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <span v-if="hiddenIconQrCode" style="right: 56px">
              <!-- <svg
                width="21"
                height="20"
                viewBox="0 0 21 20"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M9.5 16H11.5V14H9.5V16ZM10.5 0C4.98 0 0.5 4.48 0.5 10C0.5 15.52 4.98 20 10.5 20C16.02 20 20.5 15.52 20.5 10C20.5 4.48 16.02 0 10.5 0ZM10.5 18C6.09 18 2.5 14.41 2.5 10C2.5 5.59 6.09 2 10.5 2C14.91 2 18.5 5.59 18.5 10C18.5 14.41 14.91 18 10.5 18ZM10.5 4C8.29 4 6.5 5.79 6.5 8H8.5C8.5 6.9 9.4 6 10.5 6C11.6 6 12.5 6.9 12.5 8C12.5 10 9.5 9.75 9.5 13H11.5C11.5 10.75 14.5 10.5 14.5 8C14.5 5.79 12.71 4 10.5 4Z"
                  fill="#677F8E"
                />
              </svg> -->
            </span>
          </div>
          <h2
            style="
              text-align: center;
              font-family: 'Noto Sans Thai';
              padding-top: 20px;
            "
          >
            {{ coinData.coinName }}
          </h2>
          <img class="barcode-image" :src="qrCodeUrl" alt="barcode-image" />
          <div class="body-content">
            <p class="body-title" style="margin-top: 16px">Deposit Address</p>
            <div class="body-value" style="height: 64px">
              <p
                style="
                  line-height: 24px;
                  width: 286px;
                  color: #d6dde1;
                  overflow-wrap: break-word;
                "
              >
                {{ coinData.address }}
              </p>
              <span
                @click="
                  copySuccess('Address');
                  copy(coinData.address);
                "
              >
                <svg
                  width="17"
                  height="20"
                  viewBox="0 0 17 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3332 0.833252H2.33317C1.4165 0.833252 0.666504 1.58325 0.666504 2.49992V14.1666H2.33317V2.49992H12.3332V0.833252ZM14.8332 4.16658H5.6665C4.74984 4.16658 3.99984 4.91658 3.99984 5.83325V17.4999C3.99984 18.4166 4.74984 19.1666 5.6665 19.1666H14.8332C15.7498 19.1666 16.4998 18.4166 16.4998 17.4999V5.83325C16.4998 4.91658 15.7498 4.16658 14.8332 4.16658ZM14.8332 17.4999H5.6665V5.83325H14.8332V17.4999Z"
                    fill="#677F8E"
                  />
                </svg>
              </span>
            </div>
            <p class="body-title" style="margin-top: 16px">MEMO</p>
            <div class="body-value" style="height: 40px">
              <p style="line-height: 24px">{{ coinData.memoId }}</p>
              <span @click="copySuccess('MEMO');copy(coinData.memoId);">
                <svg
                  width="17"
                  height="20"
                  viewBox="0 0 17 20"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M12.3332 0.833252H2.33317C1.4165 0.833252 0.666504 1.58325 0.666504 2.49992V14.1666H2.33317V2.49992H12.3332V0.833252ZM14.8332 4.16658H5.6665C4.74984 4.16658 3.99984 4.91658 3.99984 5.83325V17.4999C3.99984 18.4166 4.74984 19.1666 5.6665 19.1666H14.8332C15.7498 19.1666 16.4998 18.4166 16.4998 17.4999V5.83325C16.4998 4.91658 15.7498 4.16658 14.8332 4.16658ZM14.8332 17.4999H5.6665V5.83325H14.8332V17.4999Z"
                    fill="#677F8E"
                  />
                </svg>
              </span>
            </div>
          </div>
        </div>
        <div class="body-content">
          <button
            class="f-btn thai-font"
            @click="
              $bvModal.hide('deposit-coin-qr-modal'),
              $bvModal.show('qr-success-modal');
              downloadImage();
            "
          >
            บันทึกรูป
          </button>
        </div>
      </div>
    </b-modal>
    <QrSuccessModal />
    <notifications position="center center" classes="">
      <template slot="body" slot-scope="{ item, close }">
        <div class="my-notification">
          <p class="title">
            {{ item.title }}
          </p>
          <div class="success-notification">
            <span>
              <svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.4987 9.49984L1.9987 5.99984L0.832031 7.1665L5.4987 11.8332L15.4987 1.83317L14.332 0.666504L5.4987 9.49984Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Copy {{ success_text }} สำเร็จแล้ว</span>
          </div>
        </div>
      </template>
    </notifications>
  </div>
</template>
<script>
import html2canvas from "html2canvas";
import QrSuccessModal from "./QrSuccessModal.vue";
import QRCode from "qrcode";

export default {
  components: { QrSuccessModal, QRCode },
  props: ["coinData"],
  data() {
    return {
      success_text: "",
      text: "",
      qrCodeUrl: "",
      hiddenIconQrCode: true,
    };
  },
  created() {
    console.log("coinData=", this.coinData);
  },
  updated() {
    this.generateQRCode();
  },
  methods: {
    copy(d) {
      navigator.clipboard
        .writeText(d)
        .then(() => {
          console.log("Text copied to clipboard:", d);
        })
        .catch((error) => {
          console.error("Unable to copy text to clipboard:", error);
        });
    },
    copySuccess(e) {
      this.success_text = e;
      this.$notify("Hello!");
    },
    generateQRCode(text) {
      QRCode.toDataURL(this.coinData.address, (error, url) => {
        if (error) {
          console.error(error);
        } else {
          this.qrCodeUrl = url;
        }
      });
    },
    downloadImage() {
      this.hiddenIconQrCode = false;
      /*
      const link = document.createElement("a");
      link.download = "barcode.png";
      link.href = document.querySelector(".barcode-image").src;
      link.click();
      */
      setTimeout(() => {
        html2canvas(document.querySelector("#html2print")).then((canvas) => {
          const link = document.createElement("a");
          link.setAttribute("download", "barcode.png");
          link.setAttribute(
            "href",
            canvas
              .toDataURL("image/png")
              .replace("image/png", "image/octet-stream")
          );
          link.click();
        });
      }, 100);
    },
  },
};
</script>
<style lang="scss">
#deposit-coin-qr-modal___BV_modal_content_ {
  background-color: #141b1f;
  width: 480px;
  margin-top: 51px !important;
  z-index: 999;
  #deposit-coin-qr-modal___BV_modal_body_ {
    font-family: var(--bs-roboto-font-sans-serif);
    margin-top: 5px;
    .barcode-image {
      height: 140px;
      width: 140px;
      margin: 22px auto 16px;
      display: block;
    }
    p {
      margin: 0;
    }
    .modal-head {
      margin-top: 20px;
      text-align: center;
      position: relative;
      p {
        font-size: 18px;
        font-family: "Noto Sans Thai";
        font-weight: 600;
        color: #999;
      }
      span {
        float: right;
        position: absolute;
        top: 10px;
        right: 20px;
        cursor: pointer;
      }
    }
    .body-content {
      width: 332px;
      margin: auto;
      padding-bottom: 16px;
      .body-title {
        color: #9bacb6;
        line-height: 24px;
        padding-top: 8px;
      }
      .body-value {
        display: flex;
        align-items: center;
        position: relative;
        width: 332px;
        min-height: 40px;
        background-color: #222b2f;
        color: #d6dde1;
        padding: 10px 0;
        padding-left: 6px;
        border-radius: 4px;
        margin-top: 4px;
        span {
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
          cursor: pointer;
        }
      }
      .f-btn {
        width: 332px;
        height: 36px;
        display: block;
        margin: auto;
        border-radius: 4px;
        line-height: 24px;
        font-weight: 500;
        color: #0a0d10;
        margin-top: 33px;
        background-color: #09BE8B;
        &:hover {
          background-color: #66cdb0;
        }
      }
    }
  }
}
.my-notification,
.vue-notification-wrapper,
.vue-notification-group {
  margin-top: 80px;
}
.success-notification {
  position: absolute;
  top: 200px;
  left: 50px;
  z-index: 0;
  padding: 8px 0px;
  // margin-top: 400px;
  pointer-events: none;
  background: #0c9;
  width: 206px;
  color: white;
  border-radius: 3px;
  text-align: center;
  span {
    margin: 0 3px;
  }
}
</style>
