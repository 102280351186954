<template>
  <div>
    <b-modal
      id="annoumentModal"
      centered
      title="Carousel Modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <VueSlickCarousel
        class="mb-2"
        :arrows="false"
        :dots="true"
        :infinite="false"
        :initialSlide="0"
        :speed="500"
        :slidesToShow="1"
        :slidesToScroll="1"
        :swipeToSlide="true"
      >
        <template>
          <div>
            <div class="row px-2 pt-3">
              <div class="col">
                <h5>Custodian Wallet</h5>
              </div>
            </div>
            <!-- {{page}} -->
            {{ page }}
            <div class="row px-2 pt-2 content-size">
              <div class="card-body">
                <div class="d-flex justify-content-between cardt">
                  <img
                    src="../../market/01.png"
                    alt="Image"
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div class="row px-2 pt-2">
              <div class="col">
                <!-- <div > Custodian Wallet</div> -->
              </div>
            </div>
          </div>
        </template>
        <template>
          <div>
            <div class="row px-2 pt-3">
              <div class="col">
                <h5>เปิดบัญชีเดือนนี้รับฟรี 100 USDT</h5>
              </div>
            </div>
            <!-- {{page}} -->
            {{ page }}
            <div class="row px-2 pt-2 content-size">
              <div class="card-body">
                <div class="d-flex justify-content-between cardt">
                  <img
                    src="../../market/02.png"
                    alt="Image"
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div class="row px-2 pt-2">
              <div class="col">
                <div > เปิดบัญชีเดือนนี้รับฟรี 100 USDT</div>
              </div>
            </div>
          </div>
        </template>
        <template>
          <div>
            <div class="row px-2 pt-3">
              <div class="col">
                <h5>White Lablel</h5>
              </div>
            </div>
            <!-- {{page}} -->
            {{ page }}
            <div class="row px-2 pt-2 content-size">
              <div class="card-body">
                <div class="d-flex justify-content-between cardt">
                  <img
                    src="../../market/03.png"
                    alt="Image"
                    height="100%"
                    width="100%"
                  />
                </div>
              </div>
            </div>
            <div class="row px-2 pt-2">
              <div class="col">
                <!-- <div > White Lablel</div> -->
              </div>
            </div>
          </div>
        </template>
      </VueSlickCarousel>

      <b-form>
        <b-form-group class="forgetbtn-group text-center">
          <button
            class="forgetbtn-primary"
            type="button"
            @click="closeAnnoumentModal"
          >
            <span class="text-dark">ปิด</span>
          </button>
        </b-form-group>
      </b-form>
    </b-modal>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import VueSlickCarousel from "vue-slick-carousel";
export default {
  components: {
    VueSlickCarousel,
  },
  props: [],
  data() {
    return {
      carouselData: [
        {
          title: "Page 1",
          content: "<p>This is the content of the first page.</p>", // Replace with your actual content
          image: "../../market/01.png",
        },
        {
          title: "Page 2",
          content: "<p>This is the content of the second page.</p>", // Replace with your actual content
          image: "../../market/02.png",
        },
        {
          title: "Page 3",
          content: "<p>This is the content of the second page.</p>", // Replace with your actual content
          image: "../../market/03.png",
        },
      ],
      announcementData: [],
    };
  },
  mounted() {
    this.getAnnouncement();
  },
  methods: {
    async getAnnouncement() {
      let self = this;
      HTTP.get("/market/announcement")
        .then(function (res) {
          self.announcementData = [...res.data.data];
          self.$bvModal.show("annoumentModal");
        })
        .catch(function (error) {
          self.$notify({
            group: "notification",
            type: "error",
            title: "Get Announcement Error",
            text: error,
          });
        });
    },
    closeAnnoumentModal() {
      this.$bvModal.hide("annoumentModal");
    },
  },
};
</script>

<style scoped>
.forgetbtn-primary {
  width: 120px;
  height: 36px;
  font-family: "sarabun";
  background-color: #09be8b;
  border-radius: 4px;
  color: #0a0d10;
  font-weight: 500;
}
.forgetbtn-primary:hover {
  background-color: #66cdb0;
}
.forgetbtn-group {
  position: absolute;
  margin-top: 15%;
  padding-bottom: 5%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.content-size {
  height: auto;
  max-height: 470px;
  overflow-y: auto;
  overflow-x: hidden;
  margin-right: 5px;
}
</style>
