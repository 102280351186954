<template>
  <div>
    <b-modal
      class="portfolio-detail"
      id="portfolio-detail"
      :hide-footer="true"
      :hide-header="true"
    >
      <!-- v-if="show_SSL_order" -->
      <div class="portfolio-detail-modal">
        <div class="row m-0">
          <div class="col-12 p-0 heading">
            <span class="close-btn" @click="$bvModal.hide('portfolio-detail')"
              ><svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="row btns">
          <div class="col-6">
            <p class="pair">
              <span v-if="portfolioDetail.coin?.name != 'THB'">{{ portfolioDetail.coin?.name }} / THB</span>
              <span v-else>THB</span>
            </p>
            <p v-if="portfolioDetail.coin?.name == 'THB'" class="live-scores">
              {{ $MyFunc.NumberFormat(portfolioDetail.actual, portfolioDetail.coinScale) }}
            </p>
            <p v-else class="live-scores">
              {{ $MyFunc.NumberFormat(portfolioDetail.lastPrice, portfolioDetail.baseCoinScale) }}
            </p>
          </div>
          <div v-if="portfolioDetail.coin?.name != 'THB'" class="col-6">
            <div class="row">
              <div class="col-6 p-0">
                <button
                  class="buy-btn mr-14"
                  @click="goToBuySell"
                >
                  Buy
                </button>
              </div>
              <div class="col-6 m-0 p-0">
                <button
                  class="sell-btn"
                  @click="goToBuySell"
                >
                  Sell
                </button>
              </div>
            </div>
          </div>
        </div>
        <div class="row body-container">
          <div class="col-5">
            <p class="c-title">Avg. Cost</p>
            <p class="c-title">Actual</p>
            <p class="c-title mb-0">Available</p>
          </div>
          <div class="col-6">
            <p class="c-value">
              {{ $MyFunc.NumberFormat(portfolioDetail.averagePrice, portfolioDetail.baseCoinScale) }}
              <span>THB/Coin</span>
            </p>
            <p class="c-value">
              {{
                $MyFunc.NumberFormat(
                  portfolioDetail.actual , portfolioDetail.coin?.name == "THB" ? portfolioDetail.baseCoinScale : portfolioDetail.coinScale
                )
              }}
              <span>{{ portfolioDetail.coin?.name }}</span>
            </p>
            <p class="c-value mb-0">
              {{
                $MyFunc.NumberFormat(
                  portfolioDetail.available, portfolioDetail.coin?.name == "THB" ? portfolioDetail.baseCoinScale : portfolioDetail.coinScale
                )
              }}

              <span>{{ portfolioDetail.coin?.name }}</span>
            </p>
          </div>
        </div>
        <div class="row body-container dc1" style="margin-top: 1px">
          <div class="col-5">
            <p class="c-title">Market Value</p>
            <p class="c-title" v-if="currentCoin == 'portfolio'">Cost Value</p>
            <p class="c-title" v-else>Total Cost</p>
            <hr v-if="currentCoin == 'portfolio'" class="p-divider" />
            <p class="c-title">Unrealized</p>
            <p class="c-title mb-0">Realized (Today)</p>
          </div>
          <div class="col-6">
            <p class="c-value">
              {{ $MyFunc.NumberFormat(portfolioDetail.marketValue, portfolioDetail.baseCoinScale) }}
              <span>THB</span>
            </p>
            <p class="c-value" v-if="currentCoin == 'portfolio'">
              {{ $MyFunc.NumberFormat(portfolioDetail.costValue, portfolioDetail.baseCoinScale) }}
              <span>THB</span>
            </p>
            <p class="c-value" v-else>
              {{ $MyFunc.NumberFormat(portfolioDetail.total, portfolioDetail.baseCoinScale) }}
              <span>THB</span>
            </p>
            <hr v-if="currentCoin == 'portfolio'" class="p-divider" />
            <p
              :class="
                portfolioDetail.unrealized >= 0
                  ? 'c-value-green'
                  : 'c-value-red'
              "
            >
              {{ $MyFunc.NumberFormat(portfolioDetail.unrealized, 2) }}
              <span
                style="color: #0c9"
                v-if="portfolioDetail.unRealizedPercent >= 0"
              ></span
              >({{ $MyFunc.NumberFormat(portfolioDetail.unRealizedPercent) }}%)
            </p>
            <p
              :class="
                portfolioDetail.realized >= 0
                  ? 'mb-0 c-value-green'
                  : 'mb-0 c-value-red'
              "
            >
              {{ $MyFunc.NumberFormat(portfolioDetail.realized, 2) }}
              (<span
                style="color: #0c9"
                v-if="portfolioDetail.realizedPercent >= 0"
              ></span
              >{{ $MyFunc.NumberFormat(portfolioDetail.realizedPercent) }}%)
            </p>
          </div>
        </div>
        <div class="f-buttons">
          <div>
            <router-link
              :to="
                portfolioDetail.coin?.name == 'THB'
                  ? '/deposit/cash'
                  : '/deposit/coin'
              "
            >
              <button class="thai-font">
                ฝาก {{ portfolioDetail.coin?.name }}
              </button>
            </router-link>
          </div>
          <div>
            <router-link
              :to="
                portfolioDetail.coin?.name == 'THB'
                  ? '/withdraw/cash'
                  : '/withdraw/coin'
              "
            >
              <button class="thai-font">
                ถอน {{ portfolioDetail.coin?.name }}
              </button>
            </router-link>
          </div>
          <router-link to="/order-history">
            <div>
              <button>Order History</button>
            </div>
          </router-link>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  props: ["portfolioDetail", "currentCoin"],
  data() {
    return {};
  },
  methods: {
    goToBuySell() {
      this.$bvModal.hide("portfolio-detail");
      let Coinsybmol = this.portfolioDetail.coin?.name.toLowerCase() + "_thb";
      if(this.currentCoin == "portfolio"){
        this.$router.push("/exchange/" + Coinsybmol );
      }else{
        if(this.$route.path.includes(Coinsybmol)){
          window.scrollTo(0,0);
        } else {
          this.$router.push("/exchange/" + Coinsybmol );
          this.$router.go(0);
        }
      }
    },
  },
  computed: {

  },
  created() {
    //console.log("PortfolioDetail",this.portfolioDetail);
  },
};
</script>

<style>
#portfolio-detail .modal-dialog {
  width: 456px;
}

#portfolio-detail___BV_modal_body_ {
  padding: 0px !important;
  background-color: #141b1f;
}
</style>
<style scoped>
.btns {
  margin-bottom: 24px !important;
}
</style>
<style lang="scss">
.portfolio-detail-modal {
  padding: 0px 24px !important;
}
.portfolio-detail-modal .heading {
  padding-bottom: 8px !important;
  height: 56px !important;
  padding-top: 24px !important;
}
.mr-14 {
  margin-right: 14px !important;
}
.dc1 {
  padding: 12px 0px 7px !important;
  border-radius: 8px 8px 0px 0px;
}
.portfolio-detail-modal {
  padding: 0 10px;
  padding-top: 8px;
  .close-btn {
    float: right;
    cursor: pointer;
  }
  .pair {
    margin: 0;
    font-weight: 600;
    font-size: 24px;
    line-height: 24px;
    color: #d6dde1;
    span {
      color: #677f8e;
    }
  }
  .live-scores {
    color: #0c9;
    margin: 0;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
  }
  .buy-btn {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
    background-color:#226afd;
    border-radius: 4px;
    width: 96px;
    height: 40px;
    &:hover {
      background-color: #4467e0;
    }
  }
  .sell-btn {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 24px;
    color: white;
    background-color: #ff2500;
    border-radius: 4px;
    width: 96px;
    height: 40px;
    &:hover {
      background-color: var(--red-negativeHover);
    }
  }
  .c-title {
    color: #677f8e;
    margin-bottom: 8px;
  }
  .c-value {
    color: #d6dde1;
    margin-bottom: 8px;
    span {
      margin-left: 5px;
      color: #677f8e;
    }
  }
  .c-value-green {
    color: #0c9;
    margin-bottom: 8px;
  }
  .c-value-red {
    color: #de2d40;
    margin-bottom: 8px;
  }
  .f-buttons {
    text-align: center;
    margin-top: 24px;
    display: flex;
    margin-bottom: 21px;
    button {
      background-color: #222b2f;
      color: white;
      border-radius: 4px;
      height: 40px;
      width: 124px;
      margin-right: 16px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      &:active {
        color: var(--bs-btn-active-color);
        background-color: var(--bs-btn-active-bg);
        border: 1px solid white !important;
      }
    }
    a {
      color: #d6dde1 !important;
    }
  }
  .body-container {
    margin-top: 2px;
    background-color: #192126;
    padding: 12px 0px 13px;
    margin: 0;
  }
}
</style>
