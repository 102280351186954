<template>
  <div>
    <div class="deposite-coin-container">
      <!-- top section start -->

      <div class="dcc-head">
        <div class="col-12" style="display: inline-flex;">
          <router-link to="/deposit/cash" :style="DepositCashDisplay"><button :class="ActiveDepositCash">Deposit Cash</button></router-link>
          <router-link to="/deposit/coin" :style="DepositCoinDisplay"><button :class="ActiveDepositCoin">Deposit Coin</button></router-link>
        </div>
      </div>
      <!-- top section end -->
      <div v-if="!reslockStatusDepositCoin">
        <!-- center section start -->
        <div class="row center-section margin-l-12">
          <div class="col-12 p-lg-0">
            <div class="row" style="margin-top: 24px">
              <div class="col-5 col-md-4 my-auto thai-font" style="line-height: 24px; width: 170px">
                เลือกเหรียญที่ต้องการฝาก
              </div>
              <div
                ref="dropdownElement"
                class="col-7 col-md-8 input-container"
                style="line-height: 24px"
                @click="open_coin_dropdown = true; searchCoin = '';"
                tabindex="0"
                v-if="network_selection"
              >
                <input
                  style="width: 388px"
                  type="text"
                  v-model="searchCoin"
                  @blur="chkSearchCoin"
                  :style="[
                    selected_coin_option.length > 0
                      ? {}
                      : { padding: '16px 16px 16px 9px' },
                  ]"
                />
                <span
                  v-if="searchCoin.length == 0"
                  style="
                    position: absolute;
                    left: 51px;
                    top: 7px;
                    color: #566a76;
                    pointer-events: none;
                  "
                >
                  เลือกเหรียญ
                </span>
                <!-- :value="selected_coin_option" -->
                  <span v-if="filteredCoinsSelect?.coin.logoFileId" style=" position: absolute; left: 50px; top: 7px; ">
                    <img :src="filteredCoinsSelect.coin.logoFileId" width="24" />
                  </span>
                <span style="position: absolute; top: 6px; right: 18px"
                  ><svg
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <g clip-path="url(#clip0_8655_21723)">
                      <path
                        d="M12.9167 11.6667H12.2583L12.025 11.4417C12.8417 10.4917 13.3333 9.25833 13.3333 7.91667C13.3333 4.925 10.9083 2.5 7.91667 2.5C4.925 2.5 2.5 4.925 2.5 7.91667C2.5 10.9083 4.925 13.3333 7.91667 13.3333C9.25833 13.3333 10.4917 12.8417 11.4417 12.025L11.6667 12.2583V12.9167L15.8333 17.075L17.075 15.8333L12.9167 11.6667ZM7.91667 11.6667C5.84167 11.6667 4.16667 9.99167 4.16667 7.91667C4.16667 5.84167 5.84167 4.16667 7.91667 4.16667C9.99167 4.16667 11.6667 5.84167 11.6667 7.91667C11.6667 9.99167 9.99167 11.6667 7.91667 11.6667Z"
                        fill="#677F8E"
                      />
                    </g>
                    <defs>
                      <clipPath id="clip0_8655_21723">
                        <rect width="20" height="20" fill="white" />
                      </clipPath>
                    </defs>
                  </svg>

                  <!-- search? -->
                </span>
                <div
                  style="width: 388px; max-height: 400px; overflow-y: scroll;"
                  class="coin-options"
                  :class="open_coin_dropdown ? 'show' : 'hidden'"
                >
                  <ul>
                    <li
                      v-for="(item, index) in filteredCoins"
                      :key="index"
                      @click.stop="
                        selected_coin_unit = item.coin.unit;
                        selected_coin_option = item.coin.name;
                        selected_network_option = '';
                        searchCoin = item.coin.name;
                        open_coin_dropdown = false;
                      "
                      style="gap: 5px;"
                    >
                      <span v-if="item.coin.logoFileId" style="float: left; width: 50px;">
                        <img :src="item.coin.logoFileId" width="24" />
                      </span>
                      {{ item.coin.name }} <span style="color: grey; margin: 0;">{{ item.coin.fullName }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <span v-if="!network_selection"
                ><svg
                  width="16"
                  height="16"
                  viewBox="0 0 16 16"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M10.9167 9.66667H10.2583L10.025 9.44167C10.8417 8.49167 11.3333 7.25833 11.3333 5.91667C11.3333 2.925 8.90833 0.5 5.91667 0.5C2.925 0.5 0.5 2.925 0.5 5.91667C0.5 8.90833 2.925 11.3333 5.91667 11.3333C7.25833 11.3333 8.49167 10.8417 9.44167 10.025L9.66667 10.2583V10.9167L13.8333 15.075L15.075 13.8333L10.9167 9.66667ZM5.91667 9.66667C3.84167 9.66667 2.16667 7.99167 2.16667 5.91667C2.16667 3.84167 3.84167 2.16667 5.91667 2.16667C7.99167 2.16667 9.66667 3.84167 9.66667 5.91667C9.66667 7.99167 7.99167 9.66667 5.91667 9.66667Z"
                    fill="#677F8E"
                  />
                </svg>
              </span>
            </div>
            <div class="row" style="margin-top: 24px" v-if="selected_coin_option">
              <div
                class="col-5 col-md-4 my-auto thai-font"
                style="line-height: 24px; width: 170px"
              >
                Network
              </div>
              <div class="col-7 col-md-8" style="line-height: 24px">
                <div
                  class="select-container"
                  tabindex="0"
                  @click="open_network_dropdown = !open_network_dropdown"
                  @blur="open_network_dropdown = false"
                >
                  <span class="text" v-if="selected_network_option"
                    >{{ selected_network_option }}
                  </span>
                  <span class="text" v-else>เลือก Network</span>
                  <span class="icon"
                    ><svg
                      width="12"
                      height="8"
                      viewBox="0 0 12 8"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.41 0.589966L6 5.16997L10.59 0.589966L12 1.99997L6 7.99997L0 1.99997L1.41 0.589966Z"
                        fill="#677F8E"
                      />
                    </svg>
                  </span>
                  <div
                    class="options"
                    :class="open_network_dropdown ? 'show' : 'hidden'"
                  >
                    <ul>
                      <li
                        v-for="(network, index) in filteredNetworkData"
                        :key="index"
                        @click="selected_network_option = network.networkName"
                      >
                        <!-- v-if="selected_coin_option == 'Bitcoin'" -->
                        {{ network.networkName }}
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div class="row info" v-if="selected_coin_option">
              <div class="col-12 col-md-4" style="width: 152px"></div>
              <div
                class="col-12 col-md-8 thai-font"
                style="line-height: 24px; margin-left: 22px"
              >
                กรุณาตรวจสอบ Network ก่อนโอนทุกครั้ง <br />
                “มิเช่นนั้นทรัพย์สินของคุณอาจสูญหายได้”
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-section thai-font" v-if="selected_coin_option">
          <div class="last-btn">
            <button
              @click="submitDepositCoin"
              class="thai-font"
              :class="
                selected_network_option == 'เลือก Network' ||
                selected_network_option == ''
                  ? 'btn-disable'
                  : 'btn-active'
              "
            >
              ยืนยันการฝากเหรียญ
            </button>
          </div>
        </div>
      </div>
      <div v-else class="text-center" style="padding: 150px;">
        คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน
      </div>

      <!-- bottom section end -->
      <!-- <div class="right-info">
        <p style="color: #677f8e">คู่มือแนะนำการทำรายการฝาก</p>
        <ul>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการฝาก แบบ QR Code</a
            >
          </li>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-Bank-Transfer.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการฝาก แบบ Bank Transfer</a
            >
          </li>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%a2%e0%b8%81%e0%b9%80%e0%b8%a5%e0%b8%b4%e0%b8%81%e0%b8%9d%e0%b8%b2%e0%b8%81-%e0%b9%81%e0%b8%9a%e0%b8%9a-QR-Code.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >คู่มือแนะนำการยกเลิกฝาก แบบ QR Code</a
            >
          </li>
        </ul>
      </div> -->
    </div>
    <RightInfo />
    <DepositCoinQrModal :coinData="coinData" />
    <div v-if="!reslockStatusDepositCoin">
      <DepositCoinTutorialModal />
    </div>
  </div>
</template>
<script>
import { HTTP } from "../../../config/axios";
import DepositCoinQrModal from "./components/DepositCoinQrModal.vue";
import DepositCoinTutorialModal from "./components/DepositCoinTutorialModal.vue";
import RightInfo from "../../../components/deposit-withdraw/RightInfo";
import { set } from 'vue';
export default {
  components: { DepositCoinQrModal, DepositCoinTutorialModal, RightInfo },
  data() {
    return {
      open_network_dropdown: false,
      selected_network_option: "เลือก Network",
      open_coin_dropdown: false,
      selected_coin_option: "",
      network_selection: true,
      btc_img: "../../../assets/images/coin_32/btc.png",
      eth_img: "../../../assets/images/coin_32/eth.png",
      coinData: {
        mainCurrency: "",
        coinName: "",
        address: "",
        memo: ""
      },
      coin: [],
      selected_coin_unit: "",
      searchCoin: "",
      networkData: [],
      reslockStatusDepositCash: false,
      reslockStatusDepositCoin: false,
      DepositCashDisplay: "display: block;",
      DepositCoinDisplay: "display: block;",
      ActiveDepositCash:"not-active",
      ActiveDepositCoin:"active",
      logoFileId_img: [],
      logoFileId_icon: [],
    };
  },
  watch: {
    searchCoin(val) {
      if (val.length == 0) {
        this.selected_coin_option = "";
      }
    },
    selected_network_option(val) {
      console.log(val);
    },
  },
  methods: {
    async getCName() {
       await HTTP.get("/uc/asset/wallet/").then((res) => {
          this.coin = res.data.data.filter(
            (item) => item.coin.name !== "THB" && item.coin.canDeposit == 1
          );
          this.coin.forEach((coins,key) => {
            if(coins.coin.logoFileId){
              coins.coin.logoFileId = this.MARKET_WS_URL + "/market/coin/image/" + coins.coin.logoFileId;
            }else{
              coins.coin.logoFileId = null;
            }
          });
          //console.log(this.coin);
      }).catch((err) => {
        console.log(err);
      });
    },
    getNetwork() {
      HTTP.post("/market/get-all-coin-networks")
        .then((res) => {
          this.networkData = res.data.data;
          console.log(this.networkData);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    submitDepositCoin() {
      let self = this;
      if (!this.selected_network_option || this.selected_network_option == "เลือก Network") {
        self.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "please select network to continue",
        });
      } else {
        HTTP.post("/uc/copper/deposit", {
          mainCurrency: this.selected_coin_unit,
        })
          .then((res) => {            
            if (res.status == 200) {     
              if(res.data.code == 0){
                this.coinData = res.data.data;
                this.coinData.coinName = this.searchCoin;
                this.$bvModal.show("deposit-coin-qr-modal");
              } 
              else{
                self.$notify({
                  group: "notification",
                  type: "error",
                  title: "Error",
                  text: res.data.message,
                });
              }      
              
            } else {
              self.$notify({
                group: "notification",
                type: "error",
                title: "Error",
                text: res.data.message,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    checkIfClickedOutside(event) {
      if(!this.reslockStatusDepositCoin){
        const dropdownElement = this.$refs.dropdownElement;

        if (!dropdownElement.contains(event.target)) {
          this.open_coin_dropdown = false;
        }
      }
    },
    chkSearchCoin() {
      setTimeout(() => {
        if (this.searchCoin == "") {
          this.searchCoin = this.selected_coin_unit;
          this.selected_coin_option = this.selected_coin_unit;
          this.selected_network_option = '';
          this.open_coin_dropdown = false;
        }
      }, 500);
    },
  },
  created() {
    this.getCName();
    this.getNetwork();
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if(user_data?.id){
      let storedData = JSON.parse(localStorage.getItem("resMember") || "{}");
      if (storedData) {
        this.reslockStatusDepositCash = storedData?.reslockStatus?.cannotDepositCash;
        this.reslockStatusDepositCoin = storedData?.reslockStatus?.cannotDepositCoin;
      }
    }
  },
  computed: {
    filteredCoins() {
      return this.coin.filter((item) =>
        item.coin.name.toLowerCase().includes(this.searchCoin.toLowerCase())
      );
    },
    filteredCoinsSelect() {
      if(!this.selected_coin_option){
        return null;
      }
      return this.coin.filter((item) =>
        item.coin.name.toLowerCase().includes(this.selected_coin_option.toLowerCase())
      )[0];
    },
    filteredNetworkData() {
      return this.networkData.filter((item) =>
        item.coinId
          .toLowerCase()
          .includes(this.selected_coin_option.toLowerCase())
      );
    },
  },
  mounted() {
    window.addEventListener("click", this.checkIfClickedOutside);
    let showTut = localStorage.getItem("dCoinTut");
    if (!showTut) {
      this.$bvModal.show("deposit-coin-tutorial");
    }
  },
  beforeDestroy() {
    window.removeEventListener("click", this.checkIfClickedOutside);
  },
};
</script>
<style lang="scss">
.deposite-coin-container {
  height: 334px;
  border-radius: 8px;
  font-family: "Roboto Flex";
  position: relative;
  .margin-l-12 {
    margin-left: 12px;
  }
  .dcc-head {
    font-family: "Roboto Flex";
    button {
      display: inline-block;
      font-size: 16px;
      line-height: 19px;
      padding: 12.5px 0;
      margin-left: 24px;
      border-bottom: 2px solid transparent;
      color: #677f8e;
      &:hover {
        border-bottom: 2px solid #09BE8B;
        color: #d6dde1;
      }
    }

    button.active {
      border-bottom: 2px solid #09BE8B;
      color: #d6dde1;
    }
  }
  .center-section {
    .select-container {
      background-color: #191b1c;
      color: #d6dde1;
      border-radius: 4px;
      height: 40px;
      width: 388px;
      margin: auto 0;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .icon {
        pointer-events: none;
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
      }
      .rotate-sc-icon {
        transform: rotateX(180deg);
      }
      .text {
        padding-left: 15px;
        span {
          position: absolute;
          right: 45px;
        }
        span.red {
          color: #ff2500;
        }
        span.yellow {
          color: #f8c417;
        }
      }
      .options {
        position: absolute;
        top: 45px;
        width: 100%;
        background-color: #191b1c;
        border-radius: 4px;
        z-index: 9999;
        ul {
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            height: 50px;
            padding: 0 15px;
            border-radius: 4px;
            &:hover {
              background-color: #2c3b44;
            }
            span.t-red {
              color: #ff2500;
              position: absolute;
              right: 20px;
            }
            span.t-yellow {
              color: #f8c417;
              position: absolute;
              right: 20px;
            }
          }
        }
      }
    }
    .input-container {
      position: relative;
      input {
        background-color: #191b1c;
        color: #d6dde1;
        border-radius: 4px;
        height: 40px;
        padding: 0 40px;
        text-transform: capitalize;
        &::placeholder {
          color: #566a76;
        }
      }
      span {
        color: #9bacb6;
        margin-left: -30px;
      }
      .coin-image {
        position: absolute;
        left: 50px;
        top: 6px;
        pointer-events: none;
        img {
          width: 23px;
        }
      }
    }
    .info {
      margin-top: 16px;
      color: #ff2500;
      cursor: pointer;
      p {
        margin: 0;
      }
    }
  }
  .hidden {
    display: none;
  }
  .show {
    display: block;
  }
  .coin-options {
    position: absolute;
    top: 45px;
    width: 59%;
    background-color: #191b1c;
    border-radius: 4px;
    z-index: 10;
    img {
      width: 23px;
      margin-left: 26px;
      margin-right: 10px;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        display: flex;
        align-items: center;
        height: 40px;
        padding: 0 15px;
        border-radius: 4px;
        &:hover {
          background-color: #2c3b44;
        }
      }
    }
  }
  .bottom-section {
    input {
      background: #191b1c;
      border: 1px solid #3c515d;
      border-radius: 2px;
      height: 17px;
      width: 17px;
      margin-top: -0.1px;
    }
    input:checked {
      background-color: #09BE8B;
      border: 1px solid #3c515d;
    }
    span {
      margin-left: 10px;
    }
    .last-btn {
      text-align: center;
      margin-top: 26px;
      margin-right: -15px;
      button {
        color: #0a0d10;
        border-radius: 4px;
        height: 36px;
        width: 152px !important;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
      }
      .btn-non-active {
        background-color: #453425;
        &:hover {
          background-color: #6b4e35;
        }
      }
      .btn-active {
        background-color: #09BE8B;
        &:hover {
          background-color: #66cdb0;
        }
      }
    }
  }
}

.right-info {
  position: absolute;
  top: 3px;
  color: #677f8e;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  right: -260px;
  line-height: 24px;
  p {
    margin-bottom: 10px;
  }
  ul {
    font-weight: 400;
    list-style: disc;
    position: relative;
    left: -8px;
    li {
      &:hover {
        color: #d6dde1;
      }
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
.btn-disable {
  opacity: 0.5;
  pointer-events: none;
}
input:focus {
  outline: none;
}
@media (max-width: 640px) {
  .deposite-coin-container {
    width: auto;
    height: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
    .center-section {
      padding: 0;
    }
    .input-container {
      input {
        width: 190px;
      }
    }
  }
}
@media (min-width: 640px) {
  .deposite-coin-container {
    width: 616px;
    padding-bottom: 0;
    .center-section {
      padding: 0 15px;
    }

    .input-container {
      input {
        width: 240px;
      }
    }
  }
}

@media (min-width: 100px) {
  .right-info {
    display: none;
  }
}
@media (min-width: 1024px) {
  .right-info {
    display: block;
    // right: -14px;
    // margin-right: -255px;
    top: 0;
  }
  .deposite-coin-container {
    transform: translateX(-50%);
  }
}
@media (max-width: 848px) {
  .deposite-coin-container {
    background-color: none;
    // margin-left: 40px;
    width: 100%;
    .dcc-head {
      background-color: #192126;
    }
    .bottom-section {
      margin: 0 40px;
    }
    .center-section {
      margin: 0 3px;
    }
  }
}
@media (min-width: 848px) {
  .deposite-coin-container {
    background-color: #192126;
    margin-left: 50%;
    margin-top: 32px;
    transform: translateX(-75.7%);
    .dcc-head {
      border-bottom: 1px solid #28363e;
    }
    .bottom-section {
      margin: 0 25px;
    }
  }
}
</style>
