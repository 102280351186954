<template>
  <div>
    <!-- cash withdraw modal -->
    <transition name="modal">
      <div class="modal-mask" style="z-index: 0">
        <div class="modal-wrapper">
          <div class="modal-container center-section">
            <div class="modalHeading">
              <label class="noto-sans-thai-font"
                >ลงทะเบียน Whitelist
                <!-- <span>
                  <svg
                    width="20"
                    height="21"
                    viewBox="0 0 20 21"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M9 16.5H11V14.5H9V16.5ZM10 0.5C4.48 0.5 0 4.98 0 10.5C0 16.02 4.48 20.5 10 20.5C15.52 20.5 20 16.02 20 10.5C20 4.98 15.52 0.5 10 0.5ZM10 18.5C5.59 18.5 2 14.91 2 10.5C2 6.09 5.59 2.5 10 2.5C14.41 2.5 18 6.09 18 10.5C18 14.91 14.41 18.5 10 18.5ZM10 4.5C7.79 4.5 6 6.29 6 8.5H8C8 7.4 8.9 6.5 10 6.5C11.1 6.5 12 7.4 12 8.5C12 10.5 9 10.25 9 13.5H11C11 11.25 14 11 14 8.5C14 6.29 12.21 4.5 10 4.5Z"
                      fill="#677F8E"
                    />
                  </svg> </span> -->
                </label>
              <span @click="closeModel()">&times;</span>
            </div>

            <!-- OTP INPUT FIELD -->
            <form @submit.prevent="submitAddress">
              <div class="row" style="width: 120%">
                <div class="col-4 whitelist-h my-2">
                  <p class="text-dark-grey">ตั้งชื่อ Wallet</p>
                </div>
                <div class="col-8 input-container">
                  <input
                    required
                    type="text"
                    placeholder="4-20 ตัวอักษร เช่น กระเป๋าของฉัน"
                    v-model="walletName"
                    maxlength="20"
                    minlength="4"
                  />
                </div>
                <div class="col-4 whitelist-h my-2">
                  <p class="text-dark-grey">ใช้สำหรับเหรียญ</p>
                </div>
                <div class="col-8 input-container">
                  <input
                    required
                    @click="
                      $bvModal.show('choose-coin');
                      selectedNetwork = '';
                    "
                    type="text"
                    class="prepend-icon"
                    :value="selectedCoin"
                    style="cursor: pointer"
                    readonly
                  />
                  <!-- <span class="prepend"
                  ><img src="@/assets/images/wallet/eth.png" alt="eth-image"
                /></span> -->
                  <span class="append" style="pointer-events: none"
                    ><img
                      src="@/assets/images/wallet/search.png"
                      alt="search-image"
                    />
                  </span>
                  <span
                    v-if="!selectedCoin"
                    style="
                      position: absolute;
                      top: 7px;
                      left: 22px;
                      color: #566a76;
                      pointer-events: none;
                    "
                    >กรุณาเลือกเหรียญ</span
                  >
                </div>
                <div class="col-4 whitelist-h my-2">
                  <p class="text-dark-grey">Network ที่ใช้</p>
                </div>
                <div class="col-8" style="position: relative">
                  <input
                    required
                    type="text"
                    readonly
                    style="padding-left: 7px; width: 240px; cursor: pointer"
                    :value="selectedNetwork"
                    @click="openNetwork()"
                  />
                  <span
                    style="
                      position: absolute;
                      right: 142px;
                      top: 8px;
                      pointer-events: none;
                    "
                    ><svg
                      width="12"
                      height="24"
                      viewBox="0 0 12 24"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M1.41 8.58984L6 13.1698L10.59 8.58984L12 9.99984L6 15.9998L0 9.99984L1.41 8.58984Z"
                        fill="#677F8E"
                      />
                    </svg>
                  </span>
                  <span
                    v-if="!selectedNetwork"
                    style="
                      position: absolute;
                      top: 7px;
                      left: 22px;
                      color: #566a76;
                      pointer-events: none;
                    "
                    >กรุณาเลือก Network</span
                  >
                  <!-- <div
                  class="select-container font-roboto-flex"
                  tabindex="0"
                  @click="
                    open_dropdown = !open_dropdown;
                    $bvModal.show('choose-network');
                  "
                  @blur="open_dropdown = false"
                >
                  <span class="text">{{ selected_option }}</span>
                  <span
                    class="icon"
                    :class="open_dropdown ? 'rotate-sc-icon' : ''"
                  >
                    <img
                      src="@/assets/images/wallet/dropIcon.png"
                      alt="drop-icon"
                    />
                  </span>
                  <div
                    class="options"
                    :class="open_dropdown ? 'show' : 'hidden'"
                  >
                    <ul>
                      <li
                        @click="
                          (selected_option = 'Network ABC'),
                            (currentSettings = 'qr')
                        "
                      >
                        Network ABC
                      </li>
                      <li
                        @click="
                          (selected_option = 'Network XYZ'),
                            (currentSettings = 'bank_transfer')
                        "
                      >
                        Network XYZ
                      </li>
                    </ul>
                  </div>
                </div> -->
                </div>
                <div class="col-4 whitelist-h my-2"></div>
                <div class="col-8" style="margin-bottom: 10px; margin-top: 4px">
                  <p class="text-dark-grey negative" style="margin-bottom: 0">
                    กรุณาตรวจสอบ Network ก่อนโอนทุกครั้ง <br />
                    “มิเช่นนั้นทรัพย์สินของคุณอาจสูญหายได้”
                  </p>
                </div>
                <div class="col-4 whitelist-h my-2">
                  <p class="text-dark-grey">Address</p>
                </div>
                <div class="col-8 input-container" style="margin-bottom: 0">
                  <input
                    class="with-icons"
                    :class="wAdressError ? 'adress-error' : ''"
                    type="text"
                    placeholder="เช่น 0x5124...4a77"
                    v-model="wAdress"
                    style="padding-right: 46px; padding-left: 8px"
                  />
                  <span class="append">
                    <img
                      @click="$bvModal.show('qr-camera')"
                      style="
                        width: 17px;
                        height: 17px;
                        position: relative;
                        top: -2px;
                        cursor: pointer;
                      "
                      class="searchImg"
                      src="@/assets/images/wallet/scanner.png"
                      alt=""
                  /></span>
                </div>
                <div class="col-4 whitelist-h my-2"></div>
                <div class="col-8" style="margin-bottom: 10px; margin-top: 4px">
                  <p
                    class="text-dark-grey negative"
                    style="margin-bottom: 0"
                    v-if="wAdressError"
                  >
                    การลงทะเบียนไม่สำเร็จ เนื่องจากคุณ <br />
                    ไม่สามารถ ทำการโอน/ถอนเหรียญไปยัง <br />
                    Wallet ลูกค้าคนอื่นใน BITBULL Crypto ได้
                  </p>
                </div>
                <div class="col-4 whitelist-h my-2">
                  <p class="text-dark-grey">Memo</p>
                </div>
                <div class="col-8 input-container">
                  <input
                    class="font-roboto-flex"
                    type="text"
                    placeholder="(Optional)"
                    v-model="memo"
                  />
                </div>
              </div>

              <!-- buttons -->

              <div class="flexCoinWithdraw">
                <button class="btn1" type="submit">บันทึก</button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </transition>
    <!-- cash withdraw done modal -->
    <ChooseCoin @coin="selectcoin" />
    <ChooseNetwork
      @network="selectnetwork"
      @networkId="selectnetworkId"
      @networkChain="selectnetworkChain"
      :selectedCoin="coinName"
      :coinUnit="coinUnit"
    />
    <QrScanner @qrCode="getQrCode" />
  </div>
</template>
<script>
import ChooseCoin from "@/pages/deposit-withdraw/withdraw/components/ChooseCoin.vue";
import ChooseNetwork from "@/pages/deposit-withdraw/withdraw/components/ChooseNetwork.vue";
import { HTTP } from "@/config/axios";
import { mapState, mapMutations } from "vuex";
import QrScanner from "./QrScanner.vue";
export default {
  name: "model",
  components: { ChooseCoin, ChooseNetwork, QrScanner },
  props: {
    refCode: {
      type: String
    }
  },
  data() {
    return {
      open_dropdown: false,
      selected_option: "ABC Network",
      currentSettings: "qr",
      wAdress: "",
      wAdressError: false,
      selectedCoin: "",
      selectedNetwork: "",
      networkId: "",
      networkChain: "",
      memo: "",
      walletName: "",
      coinName: "",
      walletId: "",
      coinUnit: null,
      // showWithdrawOTPModal:true
    };
  },
  methods: {
    openNetwork() {
      if (this.selectedCoin.length == 0) {
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "please select coin",
        });
      } else {
        this.$bvModal.show("choose-network");
      }
    },
    ...mapMutations(["setdCoinName"]), // map mutation from Vuex store

    selectcoin(e) {
      //console.log(e)
      this.coinName = e.coin.name;
      this.coinUnit = e.coin.unit;
      this.selectedCoin = e.coin.name + " (" + e.coin.fullName + ")";
      this.walletId = e.id
      //console.log(e);
    },
    selectnetwork(e) {
      this.selectedNetwork = e;
      //console.log(e);
    },
    selectnetworkId(e) {
      this.networkId = e;
      //console.log(this.networkId);
    },
    selectnetworkChain(e) {
      this.networkChain = e;
      //console.log(this.networkId);
    },
    submitAddress() {
      let self = this;
      //console.log(this.selectedCoin);
      //console.log(this.walletId)
      if (this.selectedCoin.length > 0 && this.selectedNetwork.length > 0) {
        
        HTTP.post("/uc/copper/address-validator/", {
          address: this.wAdress,
          mainCurrency: this.networkChain == null ? this.coinUnit: this.networkChain,
          currency: this.coinUnit,
          memoId: this.memo
        })
          .then((res) => {
            if (res.data.code == 0) {
              self.$notify({
                group: "notification",
                type: "success",
                title: "Success",
                text: res.data.message,
              });
              HTTP.post("/uc/sendOtp")
                .then((res) => {
                  self.$notify({
                      group: "notification",
                      type: res.data.code == "0" ? "success" : "error",
                      title:
                      res.data.code == "0"
                          ? "OTP Send to Mobile Successful"
                          : "Unable to Send OTP to Mobile",
                      text: res.data.message,
                });
                if (res.data.code == 0)
                {
                  this.$emit("update:refCode", res.data.data);
                  this.showOTPModel();
                }
                })
                .catch((err) => {
                  console.log(err);
                });
              //
            } else {
              this.$notify({
                group: "notification",
                type: "error",
                title: "error",
                text: "Address is not valid for this network",
              });
            }
          })
          .catch((err) => {
            self.$notify({
              group: "notification",
              type: "error",
              title: "error",
              text: err,
            });
            console.log(err);
          });
      } else {
        this.wAdressError = true;
        this.$notify({
          group: "notification",
          type: "error",
          title: "Error",
          text: "Please fill missing fields to continue",
        });
      }
    },
    closeModel() {
      this.$emit("closeModel");
    },
    showOTPModel() {
      if (this.wAdress.length >= 1) {
        this.wAdressError = false;
        this.$emit("showOTPModel");
        // this.$emit("coinName", this.coinName);
        this.setdCoinName(this.coinUnit); // commit mutation to update Vuex store
        this.$emit("wData", {
          selectedCoin: this.selectedCoin,
          wAdress: this.wAdress,
          selectedNetwork: this.selectedNetwork,
          memo: this.memo,
          walletName: this.walletName,
          coinNetworkId: this.networkId,
          coinName: this.coinName,
          walletId: this.walletId,
        });
      } else {
        this.wAdressError = true;
      }
    },
    getQrCode(code) {
      this.wAdress = code;
      //console.log(code);
    },
  },
};
</script>
<style scoped lang="scss">
.negative {
  color: #ff2500 !important;
}
.center-section {
  .select-container {
    background-color: #191b1c;
    color: rgb(255, 255, 255);
    border-radius: 4px;
    height: 40px;
    width: 240px;
    margin: auto 0;
    display: flex;
    align-items: center;
    position: relative;
    cursor: pointer;
    .icon {
      pointer-events: none;
      position: absolute;
      right: 20px;
    }
    .rotate-sc-icon {
      transform: rotateX(180deg);
    }
    .text {
      padding-left: 12px;
      span {
        position: absolute;
        right: 45px;
      }
      span.red {
        color: #ff2500;
      }
      span.yellow {
        color: #f8c417;
      }
    }
    .options {
      z-index: 9;
      position: absolute;
      top: 45px;
      width: 100%;
      background-color: #191b1c;
      border-radius: 4px;
      ul {
        margin: 0;
        padding: 0;
        li {
          display: flex;
          align-items: center;
          height: 40px;
          padding: 0 15px;
          border-radius: 4px;
          &:hover {
            background-color: #2c3b44;
          }
          span.t-red {
            color: #ff2500;
            position: absolute;
            right: 20px;
          }
          span.t-yellow {
            color: #f8c417;
            position: absolute;
            right: 20px;
          }
        }
      }
    }
    .hidden {
      display: none;
    }
    .show {
      display: block;
    }
  }
  .input-container {
    position: relative;
    margin-bottom: 16px;
    input {
      background-color: #191b1c;
      color: rgb(255, 255, 255);
      border-radius: 4px;
      height: 40px;
      width: 240px;
      padding: 0 5px;
      &::placeholder {
        color: #566a76;
      }
    }

    .append {
      color: #9bacb6;
      margin-left: -30px;
    }
    .prepend-icon {
      padding-left: 7px;
      padding-right: 40px;
    }
    .append-icon {
      padding: 40px !important;
    }
    .prepend {
      position: absolute;
      left: 20px;
      top: 7.5px;
      pointer-events: none;
      img {
        border-radius: 20px;
        width: 23px;
      }
    }
  }
  .info {
    color: #ff2500;
    cursor: pointer;
    p {
      margin: 0;
    }
  }
}

.input-element {
  width: 350px;
  border-radius: 4px;
}
.text-dark-grey {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}

input {
  background-color: #191b1c;
  height: 40px;
  outline: none;
  padding: 12px;
  border: 3px solid #191b1c;
  color: rgb(255, 255, 255);
  box-sizing: border-box;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-container {
  width: 524px;
  min-height: 411px;
  /* left: 0px; */
  /* top: 75px; */
  background: #141b1f;
  border-radius: 8px 8px 8px 8px;
  margin: 0px auto;
  box-shadow: 0 2px 8px rgb(0 0 0 / 33%);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
  padding: 0 25px;
  padding-bottom: 25px;
  line-height: 24px;
}

.modalHeading {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
}
.modalHeading > span {
  position: absolute;
  right: 5px;
  top: 20px;
  font-size: 30px;
  color: rgb(155, 172, 182);
  cursor: pointer;
}
.modalHeading label {
  margin: 0px;
  font-weight: 600;
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 27px;
  margin: 20px;
  color: rgb(255, 255, 255);
}
.modalHeadingWithdraw {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.OTPInput {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 24px;
  gap: 10px;
}
.flexCoinWithdraw {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bottomLine {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
  gap: 8px;
}
.bottomLine label {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
}
.flexCoinWithdraw .btn1 {
  background-color: #09BE8B;
  margin-top: 24px;
  padding: 0px 16px;
  color: #0a0d10;
  width: 120px;
  height: 36px;
  cursor: pointer;
  border-radius: 4px;
  border-style: none;
  font-size: 14px;
  font-weight: 500;
}
.OTPInput input {
  width: 44px;
  height: 56px;
  background-color: #191b1c;
  border-radius: 4px;
  color: #fff;
  padding: 12px;
  font-size: 30px;
  border-style: none;
  outline: none;
}
.l-info {
  color: #677f8e;
  font-size: 14px;
  text-align: center;
  display: block;
  line-height: 24px;
  margin-top: 32px;
}
.whitelist-h {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgb(255, 255, 255);
  margin-top: auto;
  margin-bottom: auto;
  width: 118px;
  p {
    margin: 0;
  }
}
.adress-error {
  border: 1px solid #ff2500;
}
</style>
