<template>
  <div>
    <b-modal
      class="qr-status-modal"
      id="qr-status-modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <!-- {{ wallet.address }} -->
      <!-- {{ data.data.fee }} -->
      <!-- {{ address }} -->
      <div
        class="qr-status-modal"
        style="line-height: 24px; padding: 0 10px; margin-top: 16px"
      >
        <span
          @click="onCloseStatusModel()"
          style="position: absolute; right: 28px; cursor: pointer"
          ><svg
            width="14"
            height="14"
            viewBox="0 0 14 14"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <div class="d-flex justify-content-center">
          <span class="top-heading px-2 mt-3" style="color: #9bacb6"
            >ถอนเหรียญ</span
          >
        </div>
        <div class="d-flex justify-content-center">
          <span class="h6 px-2 bh">0.00034 BTC</span>
        </div>

        <div class="d-flex justify-content-center mt-3 thai-font">
          <span class="px-2" style="color: #09BE8B">
            <span style="position: relative; top: -2px"
              ><svg
                width="14"
                height="14"
                viewBox="0 0 14 14"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M6.99398 0.333984C3.31398 0.333984 0.333984 3.32065 0.333984 7.00065C0.333984 10.6807 3.31398 13.6673 6.99398 13.6673C10.6807 13.6673 13.6673 10.6807 13.6673 7.00065C13.6673 3.32065 10.6807 0.333984 6.99398 0.333984ZM7.00065 12.334C4.05398 12.334 1.66732 9.94732 1.66732 7.00065C1.66732 4.05398 4.05398 1.66732 7.00065 1.66732C9.94732 1.66732 12.334 4.05398 12.334 7.00065C12.334 9.94732 9.94732 12.334 7.00065 12.334ZM7.33398 3.66732H6.33398V7.66732L9.83398 9.76732L10.334 8.94732L7.33398 7.16732V3.66732Z"
                  fill="#09BE8B"
                />
              </svg>
            </span>
            กำลังดำเนินการ</span
          >
        </div>
        <div class="status-content">
          <div class="row font-roboto mb-1">
            <div class="col-4 del-font">Confirmation</div>
            <div class="col-8">1/13</div>
          </div>
          <div class="row font-roboto mb-1">
            <div class="col-4 del-font">Network</div>
            <div class="col-8">{{ wallet.network.networkName }}</div>
          </div>
          <div class="row font-roboto mb-1">
            <div class="col-4 del-font">Memo</div>
            <div class="col-8">{{ wallet.memo }}</div>
          </div>
          <div class="row font-roboto mb-1">
            <div class="col-4 del-font">Network Fee</div>
            <div class="col-8" v-if="this.cfee">{{ this.cfee }} BTC</div>
            <div class="col-8" v-else>0 BTC</div>
          </div>
          <div class="row font-roboto mb-1">
            <div class="col-4 del-font"></div>
            <div class="col-8">~ 500 THB</div>
          </div>
          <div class="row font-roboto mb-1">
            <div class="col-4 del-font">ถอนไปยัง</div>
            <div v-if="address" class="col-8" style="word-break: break-all">
              {{ address }}
            </div>
          </div>
          <div class="row font-roboto mb-1">
            <div class="col-4 del-font">หมายเลขธุรกรรม</div>
            <div
              class="col-8"
              style="
                position: relative;
                word-break: break-all;
                padding-right: 45px;
              "
              v-if="this.$store.state.withdrawData.data.address"
            >
              <!-- {{ data.data.address }} -->
              <span
                v-if="this.$store.state.withdrawData.data.transactionNumber"
              >
                -
              </span>
              <span v-else>
                -
              </span>
              <div
                @click="copy()"
                style="
                  position: absolute;
                  right: 12px;
                  top: 5px;
                  cursor: pointer;
                "
              >
                <img src="@/assets/images/wallet/copyIcon.png" alt="copy" />
              </div>
            </div>
          </div>
          <!-- <div class="row mb-1">
            <div class="col-4 thai-font del-font">หมายเลขธุรกรรม</div>
            <div class="col-5" style="position: relative">
              <span class="thai-font">XXXXXXXXXXXXXXXX XXXXXXXXXXXXX</span>
              <div
                @click="copySuccess"
                style="
                  position: absolute;
                  right: -73px;
                  top: 0;
                  cursor: pointer;
                "
              >
                <img src="@/assets/images/wallet/copyIcon.png" alt="copy" />
              </div>
            </div>
          </div> -->

          <div class="row">
            <div class="col-4 thai-font del-font">วันที่</div>
            <div class="col-8">
              {{ formattedDate }}
            </div>
          </div>
        </div>
        <!-- <div class="d-flex justify-content-center" style="margin-top: 37px">
          <button @click="onCloseStatusModel()" class="btn-y">ปิด</button>
        </div> -->
      </div>
    </b-modal>
    <!-- <notifications group="foo">
      <template slot="body" slot-scope="{ item, close }">
        <div class="my-notification">
          <p class="title">
            {{ item.title }}
          </p>
          <button class="close" @click="close">
            <i class="fa fa-fw fa-close"></i>
          </button>
          <div class="success-notification">
            <span
              ><svg
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M5.4987 9.49984L1.9987 5.99984L0.832031 7.1665L5.4987 11.8332L15.4987 1.83317L14.332 0.666504L5.4987 9.49984Z"
                  fill="white"
                />
              </svg>
            </span>
            <span>Copied Successful</span>
          </div>
        </div>
      </template>
    </notifications> -->
  </div>
</template>
<script>
export default {
  props: ["wallet", "cfee", "address"],
  data() {
    return {
      data: {},
    };
  },
  created() {
    this.data = this.$store.state.withdrawData;
    // console.log(this.data.data.id);
  },
  computed: {
    formattedDate() {
      const originalDate = this.$store.state.withdrawData.data.createTime;
      const formatted = originalDate.replace(/-/g, "/");
      return formatted;
    },
  },
  methods: {
    copy() {
      let d = this.$store.state.withdrawData.data.address;
      navigator.clipboard
        .writeText(d)
        .then(() => {
          this.$notify({
            group: "notification",
            type: "success",
            title: "Success",
            text: "Copy Success",
          });
          console.log("Text copied to clipboard:", d);
          // Optionally, you can show a success message or perform other actions
        })
        .catch((error) => {
          console.error("Unable to copy text to clipboard:", error);
          // Handle the error, show an error message, etc.
        });
    },
    onCloseStatusModel() {
      this.$bvModal.hide("qr-status-modal");
      this.$emit("onCloseStatusModel");
    },
    copySuccess(e) {
      this.$notify({
        group: "foo",
      });
    },
  },
};
</script>
<style lang="scss">
.del-font {
  color: #677f8e;
  line-height: 24px;
  font-size: 14px;
}
.top-heading {
  font-family: "Noto Sans Thai";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 24px;
  text-align: center;
  color: #9bacb6;
  display: flex;
  align-items: center;
}
.bh {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 600;
  font-size: 18px;
  line-height: 24px;
}
.btn-y {
  min-width: 120px !important;
  font-weight: 500;
  color: #0a0d10;
  background-color: #09BE8B;
  border-radius: 4px;
  height: 36px;
  font-family: "Sarabun";
  &:hover {
    color: #0a0d10;
    background-color: #66cdb0 !important;
  }
}
#qr-status-modal___BV_modal_content_ {
  background-color: #141b1f;
  width: 482px;
  margin: auto;
  margin-top: 130px !important;
  height: 504px;
  font-family: "Roboto Flex";
  #qr-status-modal___BV_modal_body_ {
    .close-btn {
      cursor: pointer;
      position: absolute;
      top: 20px;
      right: 30px;
    }
    .tick-img {
      display: flex;
      justify-content: center;
      margin-top: 55px;
    }
    .status-content {
      background-color: #191b1c;
      padding: 16px 12px;
      margin-top: 22px;
    }
    .m-center {
      text-align: center;
      margin-top: 40px;
      p {
        margin: 0;
      }
      .c-title {
        color: #d6dde1;
        font-size: 18px;
        font-weight: 600;
        margin-bottom: 10px;
      }
      .c-value {
        color: #677f8e;
      }
    }
    .f-btn {
      width: 163px;
      height: 44px;
      display: block;
      margin: auto;
      border-radius: 4px;
      color: white;
      font-size: 16px;
      font-weight: 700;
      margin-top: 40px;
      background-color: #222b2f;
    }
  }
}
.my-notification,
.vue-notification-wrapper,
.vue-notification-group {
  margin-top: 160px;
  z-index: 99999999 !important;
}
.success-notification {
  // position: absolute;
  // top: 180px;
  // left: 50px;
  z-index: 99999999 !important;

  padding: 8px 0px;
  // margin-top: 400px;
  pointer-events: none;
  background: #0c9;
  width: 206px;
  color: white;
  border-radius: 3px;
  text-align: center;
  span {
    margin: 0 3px;
  }
}
</style>
