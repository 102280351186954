<template>
  <div>
    <b-modal
      class="quote-modal"
      id="quote-modal"
      :hide-footer="true"
      :hide-header="true"
    >
      <div>
        <p class="q-h">เลือก Indicators</p>
        <span class="q-close" @click="$bvModal.hide('quote-modal')">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <p class="i-h">Indicator selected (7)</p>
        <div class="q-items">
          <div class="item" v-for="(i, index) in quoteItems" :key="index">
            <label class="na-cb">
              <input type="checkbox" class="form-check-input" />
              <span>{{ i }}</span>
            </label>
            <span class="drop-item">
              <svg
                width="8"
                height="12"
                viewBox="0 0 8 12"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.593906 10.59L5.17391 6L0.593906 1.41L2.00391 0L8.00391 6L2.00391 12L0.593906 10.59Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
          </div>
        </div>
        <div class="q-f">
          <button @click="$bvModal.hide('quote-modal')">บันทึก</button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
export default {
  data() {
    return {
      quoteItems: [
        "Strong Trend",
        "Trend Ribbon",
        "EMA",
        "SMA",
        "Volume",
        "MACD",
        "RSI",
        "Stochastic Fast",
        "Stochastic Slow",
        "ADX",
        "Bollinger Bands",
        "Ichimoku",
        "Parabolic SAR",
      ],
    };
  },
};
</script>
<style lang="scss">
#quote-modal___BV_modal_content_ {
  width: 480px;
  height: 819px;
}
#quote-modal___BV_modal_body_ {
  padding: 16px !important;
  p {
    margin-bottom: 0;
  }
  .q-h {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #d6dde1;
    text-align: center;
    padding-top: 8px;
  }
  .q-close {
    position: absolute;
    right: 27px;
    top: 27px;
    cursor: pointer;
  }
  .i-h {
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #566a76;
    margin-top: 24px;
  }
  .q-items {
    height: 576px;
    margin-top: 24px;
    overflow-y: auto;
    .item {
      margin-top: 4px;
      .drop-item {
        float: right;
        margin-right: 15px;
        cursor: pointer;
      }
    }
    .na-cb {
      margin-bottom: 13px;
      input {
        background: #222b2f;
        border: 1px solid #3c515d;
        border-radius: 2px;
        height: 17px;
        width: 17px;
      }
      input:checked {
        background-color: #09BE8B;
        border: 1px solid #3c515d;
      }
      span {
        display: inline-block;
        margin-left: 8px;
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
      }
    }
  }
  .q-f {
    button {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #0a0d10;
      width: 448px;
      height: 40px;
      background-color: #09BE8B;
      border-radius: 4px;
      position: absolute;
      bottom: 46px;
    }
  }
}
</style>
