<template>
  <div class="wrapper">
    <div class="container p-0">
      <div class="row m-0">
        <div class="col p-0">
          <div class="Heading">
            <h1 class="page-heading">เพิ่มวงเงินซื้อขายฯ</h1>
          </div>
        </div>
      </div>
      <!-- row1 -->
      <div class="row justify-content-start mb-31" style="margin-left: 105px">
        <div class="col-3 p-0" style="width: 195px">
          <p class="m-0" style="color: rgb(255, 255, 255)">ชื่อ-นามสกุล</p>
        </div>
        <div class="col-3 p-0">
          <p class="m-0" style="color: #9bacb6">วาเลนติโน่ โปรนันซ่า</p>
        </div>
      </div>
      <!-- row 2 -->
      <div class="row justify-content-start mb-31" style="margin-left: 105px">
        <div class="col-3 p-0" style="width: 195px">
          <p class="m-0" style="color: rgb(255, 255, 255)">เลขที่บัญชี</p>
        </div>
        <div class="col-3 p-0">
          <p class="m-0" style="color: #9bacb6">123123</p>
        </div>
      </div>
      <!-- row3 -->
      <div
        class="row justify-content-start marginBottom-48"
        style="margin-left: 105px"
      >
        <div class="col-3 p-0" style="width: 195px">
          <p class="m-0" style="color: rgb(255, 255, 255)">วงเงินปัจจุบัน (บาท)</p>
        </div>
        <div class="col-3 p-0">
          <p class="m-0" style="color: #9bacb6">1,000,000.00</p>
        </div>
      </div>

      <!-- row4 -->
      <div
        class="row justify-content-start borderBottom"
        style="margin-left: 103px; max-width: 768px; align-items: center"
      >
        <div class="col-3 p-0" style="width: 195px">
          <p class="m-0" style="color: rgb(255, 255, 255)">ขอเพิ่มวงเงิน (บาท)</p>
        </div>
        <div class="col-5 p-0">
          <div class="name-input">
            <label for="inputPassword2" class="visually-hidden"
              >ขอเพิ่มวงเงิน (บาท)</label
            >
            <input
              type="text"
              class="form-control inputIncrement"
              placeholder=""
            />
          </div>
        </div>
      </div>
      <div class="ri-content">
        <span class="upload" style="top: 26px; right: 277px">เพิ่มเอกสาร</span>
        <p class="title">เอกสารแนบ</p>
        <p>
          สำเนาบัญชีเงินฝากธนาคาร (ย้อนหลัง 3 เดือน)
          <span class="upload"
            ><svg
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.95328 10.8851L9.43447 3.40393C10.9123 1.92608 13.3094 1.69274 14.9216 3.0221C16.7672 4.54238 16.8662 7.28595 15.2115 8.94058L6.4717 17.6804C5.54539 18.6067 4.03925 18.7835 3.02809 17.9562C1.86136 17.0016 1.79772 15.2833 2.83717 14.2439L10.2618 6.81926C10.6507 6.43035 11.2871 6.43035 11.676 6.81926C12.0649 7.20817 12.0649 7.84456 11.676 8.23347L5.48882 14.4207C5.19891 14.7106 5.19891 15.1914 5.48882 15.4813C5.77873 15.7712 6.25957 15.7712 6.54948 15.4813L12.6377 9.39313C13.564 8.46682 13.7408 6.96068 12.9134 5.94952C11.9588 4.78279 10.2406 4.71915 9.20113 5.7586L1.89672 13.063C0.418863 14.5409 0.185519 16.938 1.51488 18.5502C3.04223 20.4028 5.77873 20.4947 7.43336 18.8401L16.1096 10.1639C18.139 8.13448 18.4713 4.83229 16.6399 2.61905C14.5256 0.0805323 10.7709 -0.0538181 8.49402 2.22307L0.892625 9.82446C0.602711 10.1144 0.602711 10.5952 0.892625 10.8851C1.18254 11.175 1.66337 11.175 1.95328 10.8851Z"
                fill="#09BE8B"
              />
            </svg>
          </span>
        </p>
        <p>
          สำเนาเอกสารแสดงเงินเดือน/รายได้ เดือนล่าสุด
          <span class="upload"
            ><svg
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.95328 10.8851L9.43447 3.40393C10.9123 1.92608 13.3094 1.69274 14.9216 3.0221C16.7672 4.54238 16.8662 7.28595 15.2115 8.94058L6.4717 17.6804C5.54539 18.6067 4.03925 18.7835 3.02809 17.9562C1.86136 17.0016 1.79772 15.2833 2.83717 14.2439L10.2618 6.81926C10.6507 6.43035 11.2871 6.43035 11.676 6.81926C12.0649 7.20817 12.0649 7.84456 11.676 8.23347L5.48882 14.4207C5.19891 14.7106 5.19891 15.1914 5.48882 15.4813C5.77873 15.7712 6.25957 15.7712 6.54948 15.4813L12.6377 9.39313C13.564 8.46682 13.7408 6.96068 12.9134 5.94952C11.9588 4.78279 10.2406 4.71915 9.20113 5.7586L1.89672 13.063C0.418863 14.5409 0.185519 16.938 1.51488 18.5502C3.04223 20.4028 5.77873 20.4947 7.43336 18.8401L16.1096 10.1639C18.139 8.13448 18.4713 4.83229 16.6399 2.61905C14.5256 0.0805323 10.7709 -0.0538181 8.49402 2.22307L0.892625 9.82446C0.602711 10.1144 0.602711 10.5952 0.892625 10.8851C1.18254 11.175 1.66337 11.175 1.95328 10.8851Z"
                fill="#09BE8B"
              />
            </svg>
          </span>
        </p>
        <p>
          สำเนาหลักฐานการเป็นเจ้าของพันธบัตร
          <span class="upload"
            ><svg
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.95328 10.8851L9.43447 3.40393C10.9123 1.92608 13.3094 1.69274 14.9216 3.0221C16.7672 4.54238 16.8662 7.28595 15.2115 8.94058L6.4717 17.6804C5.54539 18.6067 4.03925 18.7835 3.02809 17.9562C1.86136 17.0016 1.79772 15.2833 2.83717 14.2439L10.2618 6.81926C10.6507 6.43035 11.2871 6.43035 11.676 6.81926C12.0649 7.20817 12.0649 7.84456 11.676 8.23347L5.48882 14.4207C5.19891 14.7106 5.19891 15.1914 5.48882 15.4813C5.77873 15.7712 6.25957 15.7712 6.54948 15.4813L12.6377 9.39313C13.564 8.46682 13.7408 6.96068 12.9134 5.94952C11.9588 4.78279 10.2406 4.71915 9.20113 5.7586L1.89672 13.063C0.418863 14.5409 0.185519 16.938 1.51488 18.5502C3.04223 20.4028 5.77873 20.4947 7.43336 18.8401L16.1096 10.1639C18.139 8.13448 18.4713 4.83229 16.6399 2.61905C14.5256 0.0805323 10.7709 -0.0538181 8.49402 2.22307L0.892625 9.82446C0.602711 10.1144 0.602711 10.5952 0.892625 10.8851C1.18254 11.175 1.66337 11.175 1.95328 10.8851Z"
                fill="#09BE8B"
              />
            </svg>
          </span>
        </p>
        <p>
          สำเนาหลักฐานการเป็นเจ้าของหุ้นกู้
          <span class="upload"
            ><svg
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.95328 10.8851L9.43447 3.40393C10.9123 1.92608 13.3094 1.69274 14.9216 3.0221C16.7672 4.54238 16.8662 7.28595 15.2115 8.94058L6.4717 17.6804C5.54539 18.6067 4.03925 18.7835 3.02809 17.9562C1.86136 17.0016 1.79772 15.2833 2.83717 14.2439L10.2618 6.81926C10.6507 6.43035 11.2871 6.43035 11.676 6.81926C12.0649 7.20817 12.0649 7.84456 11.676 8.23347L5.48882 14.4207C5.19891 14.7106 5.19891 15.1914 5.48882 15.4813C5.77873 15.7712 6.25957 15.7712 6.54948 15.4813L12.6377 9.39313C13.564 8.46682 13.7408 6.96068 12.9134 5.94952C11.9588 4.78279 10.2406 4.71915 9.20113 5.7586L1.89672 13.063C0.418863 14.5409 0.185519 16.938 1.51488 18.5502C3.04223 20.4028 5.77873 20.4947 7.43336 18.8401L16.1096 10.1639C18.139 8.13448 18.4713 4.83229 16.6399 2.61905C14.5256 0.0805323 10.7709 -0.0538181 8.49402 2.22307L0.892625 9.82446C0.602711 10.1144 0.602711 10.5952 0.892625 10.8851C1.18254 11.175 1.66337 11.175 1.95328 10.8851Z"
                fill="#09BE8B"
              />
            </svg>
          </span>
        </p>
        <p>
          สำเนากองทุนรวม
          <span class="upload"
            ><svg
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.95328 10.8851L9.43447 3.40393C10.9123 1.92608 13.3094 1.69274 14.9216 3.0221C16.7672 4.54238 16.8662 7.28595 15.2115 8.94058L6.4717 17.6804C5.54539 18.6067 4.03925 18.7835 3.02809 17.9562C1.86136 17.0016 1.79772 15.2833 2.83717 14.2439L10.2618 6.81926C10.6507 6.43035 11.2871 6.43035 11.676 6.81926C12.0649 7.20817 12.0649 7.84456 11.676 8.23347L5.48882 14.4207C5.19891 14.7106 5.19891 15.1914 5.48882 15.4813C5.77873 15.7712 6.25957 15.7712 6.54948 15.4813L12.6377 9.39313C13.564 8.46682 13.7408 6.96068 12.9134 5.94952C11.9588 4.78279 10.2406 4.71915 9.20113 5.7586L1.89672 13.063C0.418863 14.5409 0.185519 16.938 1.51488 18.5502C3.04223 20.4028 5.77873 20.4947 7.43336 18.8401L16.1096 10.1639C18.139 8.13448 18.4713 4.83229 16.6399 2.61905C14.5256 0.0805323 10.7709 -0.0538181 8.49402 2.22307L0.892625 9.82446C0.602711 10.1144 0.602711 10.5952 0.892625 10.8851C1.18254 11.175 1.66337 11.175 1.95328 10.8851Z"
                fill="#09BE8B"
              />
            </svg>
          </span>
        </p>
        <p>
          สำเนาพอร์ตหุ้น
          <span class="upload"
            ><svg
              width="18"
              height="21"
              viewBox="0 0 18 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M1.95328 10.8851L9.43447 3.40393C10.9123 1.92608 13.3094 1.69274 14.9216 3.0221C16.7672 4.54238 16.8662 7.28595 15.2115 8.94058L6.4717 17.6804C5.54539 18.6067 4.03925 18.7835 3.02809 17.9562C1.86136 17.0016 1.79772 15.2833 2.83717 14.2439L10.2618 6.81926C10.6507 6.43035 11.2871 6.43035 11.676 6.81926C12.0649 7.20817 12.0649 7.84456 11.676 8.23347L5.48882 14.4207C5.19891 14.7106 5.19891 15.1914 5.48882 15.4813C5.77873 15.7712 6.25957 15.7712 6.54948 15.4813L12.6377 9.39313C13.564 8.46682 13.7408 6.96068 12.9134 5.94952C11.9588 4.78279 10.2406 4.71915 9.20113 5.7586L1.89672 13.063C0.418863 14.5409 0.185519 16.938 1.51488 18.5502C3.04223 20.4028 5.77873 20.4947 7.43336 18.8401L16.1096 10.1639C18.139 8.13448 18.4713 4.83229 16.6399 2.61905C14.5256 0.0805323 10.7709 -0.0538181 8.49402 2.22307L0.892625 9.82446C0.602711 10.1144 0.602711 10.5952 0.892625 10.8851C1.18254 11.175 1.66337 11.175 1.95328 10.8851Z"
                fill="#09BE8B"
              />
            </svg>
          </span>
        </p>
        <p class="type">หมายเหตุ: รองรับไฟล์ .pdf, .png, ........</p>
        <p>เอกสารแนบที่อัปโหลดแล้ว</p>
        <div class="file" style="margin-top: 27px">
          <span
            >Bank Statement.pdf

            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#09BE8B"
              />
            </svg>
          </span>
          <span>
            Bank Statement.pdf

            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#09BE8B"
              />
            </svg>
          </span>
        </div>
        <div
          class="file"
          style="
            margin-bottom: 34px;
            border-bottom: 1px solid #28363e;
            padding-bottom: 45px;
          "
        >
          <span
            >Stock Balance.pdf
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#09BE8B" />
            </svg>
          </span>
          <span
            >Stock Balance.pdf
            <svg
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#09BE8B"
              />
            </svg>
          </span>
        </div>
      </div>
      <!-- checkbox -->
      <div class="row justify-content-start" style="margin-left: 104px">
        <div class="col-8 p-0">
          <div class="form-check">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              id="flexCheckDefault"
            />
            <label
              class="form-check-label term m-0"
              for="flexCheckDefault"
              style="color: #9bacb6"
            >
              ข้าพเจ้าขอยืนยันว่าได้ทำการขอเพิ่มวงเงินซื้อขายฯ
              โดยได้แนบหลักฐานที่บริษัทฯ ระบุ <br />
              พร้อมลงรองรับสำเนาถูกต้องเรียบร้อยแล้ว
            </label>
          </div>
        </div>
      </div>
      <!-- buttons -->
      <div
        class="row justify-content-start"
        style="margin: 24px 0px 66px 104px"
      >
        <div class="col-4 p-0">
          <div class="button text-center d-flex">
            <button
              type="button"
              style="margin-right: 16px; height: 36px"
              class="btn-primary btn-text-sarabun buttonWidth"
              @click="showModalOTPSuccess = true"
            >
              ยืนยัน
            </button>
            <button
              type="button"
              style="height: 36px; color: white"
              class="btn-cancel btn-text-sarabun buttonWidth w-400"
            >
              ยกเลิก
            </button>
          </div>
        </div>
      </div>
    </div>
    <!-- common success modal -->

    <!-- common success modal -->
    <div v-if="showModalOTPSuccess">
      <transition name="modal">
        <div class="modal-mask" style="background-color: #0a0d10">
          <div class="modal-wrapper">
            <div class="modal-dialog otp-success" role="document">
              <div class="modal-content" style="border-radius: 8px">
                <div class="modal-body scroller-otp p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <div class="checkTick">
                          <img
                            class="image-tick-modal"
                            src="../../assets/images/profile-setting/tick.png"
                            alt=""
                          />
                        </div>
                        <div class="success-message">
                          <p
                            class="text-grey"
                            style="
                              font-family: 'Noto Sans Thai';
                              font-style: normal;
                              font-weight: 600;
                              font-size: 24px;
                              line-height: 36px;
                            "
                          >
                            ระบบได้รับข้อมูลของคุณแล้ว
                          </p>
                        </div>
                        <div
                          style="margin-bottom: 32px"
                          @click="showModalOTPSuccess = false"
                        >
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              height: 44px;
                              width: 163px;
                              color: white;
                              color: white;
                              border-radius: 4px;
                            "
                          >
                            เสร็จสิ้น
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </transition>
    </div>

    <!-- common success modal end -->
  </div>
</template>

<script>
export default {
  name: "PersonalInformation",
  data: function () {
    return {
      showModalOTPSuccess: false,
    };
  },
  methods: {},
};
</script>
<style lang="scss" scoped>
.w-400 {
  font-weight: 400 !important;
}
input[type="text"] {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: rgb(255, 255, 255) !important;
  caret-color: #09BE8B;
  line-height: 24px;
}
label {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgb(255, 255, 255);
}
.page-heading {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  margin: 0px;
  color: rgb(255, 255, 255);
}
.Heading {
  margin: 24px 0px 53px 113px !important;
}

.btn-text-sarabun {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.borderBottom {
  border-bottom: 1px solid #28363e;
  padding-bottom: 31px;
  width: 95%;
}
.InputMarginleft {
  margin-left: 18px;
}
.btn-cancel {
  background-color: #191b1c;
}
.text-grey {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}

.text-dark-grey {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  font-family: "Sarabun" !important;
}
.text-white {
  color: #ffff;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.marginBottom-48 {
  margin-bottom: 48px;
}
.mb-31 {
  margin-bottom: 31px;
}
.inputIncrement {
  width: 170px !important;
}
.margin-row9 {
  margin-top: 14px;
  margin-bottom: 24px;
}
.form-check-input {
  background-color: #38454d !important;
}
input [type="checkbox"] {
  width: 16px;
  height: 16px;
  border: 1px solid #3c515d;
  border-radius: 2px;
}
.form-check-input:checked {
  background-color: #09BE8B !important;
}
input[type="checkbox"] {
  width: 16px;
  height: 16px;
}
input[type="radio"]:checked {
  background-color: #191b1c !important;
}
input[type="radio"] {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #38454d;
  margin-right: 12px;
  cursor: pointer;
  background-color: #191b1c !important;
}
input[type="radio"]:focus {
  border: 1px solid #3c515d !important;
}

input[type="radio"]:checked::before {
  content: "";
  display: block;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  background-color: #09BE8B;
  margin: 4px;
}

.form-control {
  outline: none;
}
.attachment-wrap {
  background-color: #192126;
  margin-top: 16px;
  border-radius: 4px;
  justify-content: center;
  border: 1px dashed #28363e;
  width: 332px;
  height: 119px;
}
.buttonWidth {
  width: 140px !important;
  border-radius: 4px;
}

/*  select box address */
select > option:checked,
select > option:hover {
  color: #09BE8B !important;
}
/* select:focus{
    border-color: #09BE8B;
    outline:none;
} */
select {
  border-radius: 4px;
  height: 40px;
  width: 170px !important;
  color: #566a76 !important;
  font-size: 14px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #28363e !important;
  background: #677f8e
    url("../../../src/assets/images/profile-setting/dropdownChevron.png");
  background-repeat: no-repeat;
  background-position: right 7px top 50%;
  padding: 6px 0px 6px 8px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.modal-padding {
  margin-left: 1rem;
}
/*  select box end */

/* modal succes */
.buttonSuccess {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: "Noto Sans Thai" !important;
}
.otp-input {
  width: 44px !important;
  height: 56px !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  text-align: center;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-content {
  width: 100% !important;
  background-color: #141b1f !important;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-dialog {
  background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}
.otp-success {
  max-width: 480px !important;
}
.otp-error {
  max-width: 409px !important;
}
.image-tick-modal {
  background-color: #277c44;
  border-radius: 100%;
  padding: 15px;
  width: 67px;
  height: 67px;
}
.image-cross-modal {
  background-color: linear-gradient(360deg, #de2d40 0%, #ee5d6d 100%);
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.ri-content {
  margin-left: 105px;
  margin-top: 37px;
  position: relative;
  p.title {
    color: rgb(255, 255, 255);
    margin-bottom: 26px;
  }
  p.type {
    color: #566a76;
    margin-bottom: 28px;
  }
  p {
    margin: 0;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #9bacb6;
    margin-bottom: 11px;
    .upload {
      cursor: pointer;
      right: 294px;
    }
  }
  .upload {
    position: absolute;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 24px;
    color: #09BE8B;
    right: 0;
  }
  .file {
    margin-bottom: 9px;
    span {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      color: rgb(255, 255, 255);
      display: inline-block;
      width: 175px;
      svg {
        margin-left: 5px;
      }
    }
  }
}
.checkTick {
  padding-top: 58px;
}
.success-message {
  padding-top: 24px;
  padding-bottom: 40px;
}
.finisButton-SuccesOtp {
  padding-bottom: 100px;
}
/* modal  succes end */

/* // %%%  scroller  start %%%% */

.scroller {
  height: 400px;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}
.pinInput-width {
  width: 240px !important;
}
.SurnameWidth {
  width: 280px !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #192126;
}
.term {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 19px;
}
</style>
