<template>
  <div class="wraper">
    <div class="container" style="width: 965px">
      <p class="history-heading">History</p>
      <section>
        <b-container
          class="pLR-24 bg-MarKetHeader d-flex justify-content-between border-bottom" fluid
        >
          <div class="page-MarKetHeader">
            <b-navbar>
              <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
              <b-collapse id="nav-collapse" is-nav>
                <b-navbar-nav>
                  <div class="mx-auto">
                    <div class="menu-item">
                      <b-link class="colormiddle" :to="{ path: '/uc/history' }" :active="$route.name == 'ByDetail'">
                        By Detail
                      </b-link>
                    </div>
                    <div class="menu-item">
                      <b-link class="colormiddle" :to="{ path: '/uc/history/bydate' }" :active="$route.name == 'ByDate'">
                        By Date
                      </b-link>
                    </div>
                    <div class="menu-item">
                      <b-link class="colormiddle" :to="{ path: '/uc/history/bycoin' }" :active="$route.name == 'ByCoin'">
                        By Coin
                      </b-link>
                    </div>
                  </div>
                </b-navbar-nav>
              </b-collapse>
            </b-navbar>
          </div>
        </b-container>
        <router-view></router-view>
      </section>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {};
  },
  methods: {},
  mounted() {},
};
</script>
<style scoped>
.colormiddle {
  font-family: "Roboto Flex";
}
.border-bottom {
  border-bottom: 1px solid #28363e !important;
}
.pLR-24 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.page-MarKetHeader {
  font-family: var(--bs-roboto-font-sans-serif);
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
}
.mx-auto {
  display: flex;
  gap: 0px !important;
}
.colormiddle {
  text-decoration: none;
  color: #677f8e !important;
}
.menu-item {
  margin-right: 32px !important;
  position: relative !important;
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 19px !important;
}
.mx-auto .colormiddle {
  padding: 30px 0px;
  text-align: center;
  height: 64px;
  border-bottom: 2px solid transparent;
  display: flex;
  transition: 0.4s;
  font-weight: 400;
  cursor: pointer !important;
}
.mx-auto .colormiddle.active,
.mx-auto .colormiddle:hover {
  border-bottom-color: #09BE8B !important;
  text-align: center;
  color: var(--primary) !important;
}
.history-heading {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 24px;
  margin-top: 24px;
  margin-bottom: 0;
  color: #d6dde1;
}
</style>
