import { render, staticRenderFns } from "./SlMarket.vue?vue&type=template&id=45105d79&scoped=true"
import script from "./SlMarket.vue?vue&type=script&lang=js"
export * from "./SlMarket.vue?vue&type=script&lang=js"
import style0 from "./SlMarket.vue?vue&type=style&index=0&id=45105d79&prod&lang=css"
import style1 from "./SlMarket.vue?vue&type=style&index=1&id=45105d79&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "45105d79",
  null
  
)

export default component.exports