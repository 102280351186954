<template>
    <div>
      <b-modal
        class="dwcoin"
        id="dwcoin"
        :hide-footer="true"
        :hide-header="true"
      >
      <div>
        <div class="mh">
          <span class="close" @click="$bvModal.hide('dwcoin')">
            <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M14 1.41L12.59 0L7 5.59L1.41 0L0 1.41L5.59 7L0 12.59L1.41 14L7 8.41L12.59 14L14 12.59L8.41 7L14 1.41Z"
                fill="#677F8E"
              />
            </svg>
          </span>
          <p class="sub-h" v-if="DWDetail.transactionType==0">ฝากเหรียญ</p>
          <p class="sub-h" v-if="DWDetail.transactionType==1">ถอนเหรียญ</p>
          <h2 style="margin-top: 20px;">{{ $MyFunc.NumberFormat(DWDetail.coinAmount, DWDetail.coinScale) }} {{ DWDetail.coinUnit }}</h2>
          <p :class="getStyle().colorText">
            <span>
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                  :d="getStyle().textPath"
                  :fill="getStyle().colorFill" />
              </svg>
            </span>
            {{getStyle().textStatus}}
          </p>
        </div>
        <div class="d-box">
          <div class="row" v-if="DWDetail.transactionType==0">
            <div class="col-6 title">Confirmation</div>
            <div class="col-6 value">{{DWDetail.confirmations}}</div>
            <div class="col-6 title">Network</div>
            <div class="col-6 value">{{ DWDetail.network }}</div>
            <div class="col-6 title">หมายเลขธุรกรรม</div>
            <div class="col-6 value">
              {{ DWDetail.transactionId?DWDetail.transactionId:"-" }} 
              <button @click="copyText(DWDetail.transactionId ? DWDetail.transactionId : '-')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1950_271157)">
                    <path opacity="0.3" d="M8.37305 7H19.1172V21H8.37305V7Z" fill="#566A76"/>
                    <path d="M16.1861 1H4.46521C3.39079 1 2.51172 1.9 2.51172 3V17H4.46521V3H16.1861V1ZM19.1164 5H8.37218C7.29777 5 6.4187 5.9 6.4187 7V21C6.4187 22.1 7.29777 23 8.37218 23H19.1164C20.1908 23 21.0699 22.1 21.0699 21V7C21.0699 5.9 20.1908 5 19.1164 5ZM19.1164 21H8.37218V7H19.1164V21Z" fill="#677F8E"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1950_271157">
                      <rect width="23.4419" height="24" fill="white" transform="translate(0.558594)"/>
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div class="col-6 title">วันที่</div>
            <div class="col-6 value">
              {{ DWDetail.transactionDate < DWDetail.updatedTimestamp ? getFormatDate(DWDetail.updatedTimestamp) : getFormatDate(DWDetail.transactionDate) }}
            </div> 
            <div v-if="DWDetail.status==2 || DWDetail.status==9" class="col-6 value btext center">
              <br>
              <p class="red-h" v-if="DWDetail.status==2">
                ถูกยกเลิก 
              </p>
              <p class="red-h" v-else-if="DWDetail.status==9">
                {{ DWDetail.transactionType != null ? getDWDetailStatus(DWDetail.transactionType) : '' }}ไม่สำเร็จ
              </p>
              <p class="red-h">
                เนื่องจาก {{DWDetail.reason}}
              </p>
            </div>
          </div>
          <div class="row" v-if="DWDetail.transactionType==1">
            <div class="col-6 title">Confirmation</div>
            <div class="col-6 value">{{DWDetail.confirmations}}</div>
            <div class="col-6 title">Network</div>
            <div class="col-6 value">{{ DWDetail.network }}</div>
            <div class="col-6 title">Memo</div>
            <div class="col-6 value">{{ DWDetail.memoId }}</div>
            <div class="col-6 title">Network Fee</div>
            <div class="col-6 value">
              {{ DWDetail.withdrawFee }} {{ DWDetail.coinUnit }}
              <br>
              <span>~ {{thaiBaht}} THB</span>
            </div>
            <div class="col-6 title">ถอนไปยัง</div>
            <div class="col-6 value">{{ DWDetail.address }}</div>
            <div class="col-6 title">หมายเลขธุรกรรม</div>
            <div class="col-6 value">
              {{ DWDetail.transactionId?DWDetail.transactionId:"-" }} 
              <button @click="copyText(DWDetail.transactionId ? DWDetail.transactionId : '-')">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g clip-path="url(#clip0_1950_271157)">
                    <path opacity="0.3" d="M8.37305 7H19.1172V21H8.37305V7Z" fill="#566A76"/>
                    <path d="M16.1861 1H4.46521C3.39079 1 2.51172 1.9 2.51172 3V17H4.46521V3H16.1861V1ZM19.1164 5H8.37218C7.29777 5 6.4187 5.9 6.4187 7V21C6.4187 22.1 7.29777 23 8.37218 23H19.1164C20.1908 23 21.0699 22.1 21.0699 21V7C21.0699 5.9 20.1908 5 19.1164 5ZM19.1164 21H8.37218V7H19.1164V21Z" fill="#677F8E"/>
                  </g>
                  <defs>
                    <clipPath id="clip0_1950_271157">
                      <rect width="23.4419" height="24" fill="white" transform="translate(0.558594)"/>
                    </clipPath>
                  </defs>
                </svg>
              </button>
            </div>
            <div class="col-6 title">วันที่</div>
            <div class="col-6 value">
              {{ DWDetail.transactionDate < DWDetail.updatedTimestamp ? getFormatDate(DWDetail.updatedTimestamp) : getFormatDate(DWDetail.transactionDate) }}
            </div> 
            <div v-if="DWDetail.status==2" class="col-6 value btext center">
              <br>
              <p class="red-h">
                ถูกยกเลิก 
              </p>
              <p class="red-h">
                เนื่องจาก {{DWDetail.reason}}
              </p>
            </div>
          </div>
        </div>
      </div>
      </b-modal>
    </div>
  </template>
  <script>
  import moment from "moment";
  export default {
    components:{

    },
    props:["DWDetail","thaiBaht"],
    data(){
      return {

      }
    },
    methods:{
      getFormatDate(date){
        return moment(date).format(this.TIMEPICKER+" HH:mm:ss")
      },
      getDWDetailStatus(statusType){
        let statusText = null;
        if(statusType == 0){
          statusText = "ฝาก";
        }else if(statusType == 1){
          statusText = "ถอน";
        }
        return statusText; 
      },
      async copyText(transactionId) {
        try {
          await navigator.clipboard.writeText(transactionId);
        } catch (err) {
        }
      },

      getStyle(){
        let colorFill= "";
        let textPath =  "";
        let colorText= "";
        let textStatus= "";
        if(this.DWDetail.status==0){
          // อยู่ระหว่างการถอน => อยู่ระหว่างการถอน
          colorFill = "#0c9";
          textPath = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          colorText = "orange-h";
          textStatus= "อยู่ระหว่างการถอน";
        }else if(this.DWDetail.status==1){
          // สำเร็จ => สำเร็จ
          colorFill = "#0c9";
          textPath = "M3.99896 7.80007L1.19896 5.00006L0.265625 5.9334L3.99896 9.66673L11.999 1.66673L11.0656 0.733398L3.99896 7.80007Z";
          colorText = "green-h";
          textStatus= this.DWDetail.transactionType != null ? this.getDWDetailStatus(this.DWDetail.transactionType) + "สำเร็จ" : '';
        }else if(this.DWDetail.status==4 && this.DWDetail.transactionType == 0){
          // กำลังดำเนินการ => กำลังดำเนินการ
          colorFill = "#0c9";
          textPath = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          colorText = "orange-h";
          textStatus= "กำลังดำเนินการ";
        }else if(this.DWDetail.status==4 && this.DWDetail.transactionType == 1){
          // อยู่ระหว่างการถอน => อยู่ระหว่างการถอน
          colorFill = "#0c9";
          textPath = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          colorText = "orange-h";
          textStatus= "อยู่ระหว่างการถอน";
        }else if(this.DWDetail.status==7){
          // รอระบุต้นทุน => รอระบุต้นทุน
          colorFill = "#0c9";
          textPath = "M6.99398 0.333374C3.31398 0.333374 0.333984 3.32004 0.333984 7.00004C0.333984 10.68 3.31398 13.6667 6.99398 13.6667C10.6807 13.6667 13.6673 10.68 13.6673 7.00004C13.6673 3.32004 10.6807 0.333374 6.99398 0.333374ZM7.00065 12.3334C4.05398 12.3334 1.66732 9.94671 1.66732 7.00004C1.66732 4.05337 4.05398 1.66671 7.00065 1.66671C9.94732 1.66671 12.334 4.05337 12.334 7.00004C12.334 9.94671 9.94732 12.3334 7.00065 12.3334ZM7.33398 3.66671H6.33398V7.66671L9.83398 9.76671L10.334 8.94671L7.33398 7.16671V3.66671Z";
          colorText = "orange-h";
          textStatus= "รอระบุต้นทุน";
        }else if(this.DWDetail.status==9 || this.DWDetail.status==2){
          // ไม่สำเร็จ => ไม่สำเร็จ
          colorFill = "#DE2D40";
          textPath = "M9.66732 1.27301L8.72732 0.333008L5.00065 4.05967L1.27398 0.333008L0.333984 1.27301L4.06065 4.99967L0.333984 8.72634L1.27398 9.66634L5.00065 5.93967L8.72732 9.66634L9.66732 8.72634L5.94065 4.99967L9.66732 1.27301Z";
          colorText = "red-h";
          textStatus= this.DWDetail.transactionType != null ? this.getDWDetailStatus(this.DWDetail.transactionType)+ "ไม่สำเร็จ" : '' ;
        }
        return { colorFill,textPath,colorText,textStatus }
      }
    },
    created(){
      //console.log(this.DWDetail);
    }
  };
  </script>
  <style lang="scss">
  #dwcoin___BV_modal_content_ {
    width: 600px;
  }
  #dwcoin___BV_modal_body_ {
    .mh {
      text-align: center;
      p {
        margin: 0;
      }
      .sub-h {
        font-family: "Noto Sans Thai";
        font-style: normal;
        font-weight: 600;
        font-size: 16px;
        line-height: 24px;
        text-align: center;
        color: #9bacb6;
        margin-top: 40px;
      }
      h2 {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 600;
        font-size: 18px;
        line-height: 24px;
        color: #ffffff;
      }
      .green-h {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 20px;
        color: #0c9;
      }
      .orange-h {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 20px;
        color:#0c9;
      }
      .red-h {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        margin-top: 20px;
        color:#DE2D40;
      }
  
      .close {
        position: absolute;
        right: 31px;
        top: 29px;
        cursor: pointer;
      }
    }
    .d-box {
      padding-left: 24px;
      padding-top: 15px;
      padding-bottom: 15px;
      width: 500px;
      background-color: #191b1c;
      border-radius: 4px;
      margin: auto;
      margin-top: 20px;
      margin-bottom: 20px;
      .title {
        font-family: "Sarabun";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #677f8e;
        padding-top: 3px;
        width: 28%;
      }
      .value {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
        padding-top: 3px;
        padding-right: 20px;
        width: 72%;
        overflow-wrap: break-word;
      }
      .btext {
        border-top: 1px solid #28363e;
        width: 358px;
        margin-top: 9px;
        padding: 10px;
        p{
          color: #FFFFFF;
          line-height: 10px;
        }
      }
    }
  }
  </style>
  