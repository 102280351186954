<template>
  <p>marketupdate tabs</p>
</template>

<script>
import Table from "../../components/table/Table.vue";
export default {
  name: "marketupdate",

  components: {
    "Dynamic-Table": Table,
  },
  data() {
    return {

    };
  },
};
</script>
<style lang="scss">
</style>
