<template>
  <div>
    <b-modal
      class="choose-coin"
      id="choose-coin"
      :hide-footer="true"
      :hide-header="true"
    >
      <div>
        <h5>เลือกเหรียญ</h5>
        <span class="close" @click="$bvModal.hide('choose-coin')">
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <p class="info">กรุณาเลือกเหรียญที่ต้องการถอน</p>
        <div class="coin-box-c">
          <span class="mc">My Coin</span>
          <span class="ac">Available</span>
          <div class="coin-box" v-for="(coin, index) in coinData" :key="index">
            <div @click="selectedCoin = coin">
              <div class="radio_btn a1">
                <!-- :class="test.answer_visible ? 'red' : 'normal'" -->
                <input
                  class="normal"
                  type="radio"
                  id="12"
                  name="coin"
                  @click="selectedCoin = coin"
                  :checked="selectedCoin == coin"
                />
              </div>
              <p class="coin-name">
                {{ coin.coin.name }} ({{ coin.coin.fullName }})
              </p>
              <p class="coin-quantity">
                {{ coin.coin.unit }}
              </p>
              <p class="coin-balance">
                {{ coin.balance }}
              </p>
            </div>
          </div>
        </div>
        <div class="fb">
          <button
            @click="
              $bvModal.hide('choose-coin');
              selectedcoin();
            "
          >
            บันทึก
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";

export default {
  data() {
    return {
      coinData: [],
      selectedCoin: "",
      walletId:""
    };
  },
  methods: {
    selectedcoin() {
      this.$emit("coin", this.selectedCoin);
    },
  },
  created() {
    HTTP.get("/uc/asset/wallet/")
      .then((res) => {
        this.coinData = res.data.data.filter(
          (item) => item.coin.name !== "THB" && item.coin.canWithdraw == 1
        );
      })
      .catch((err) => {
        console.log(err);
      });
  },
};
</script>
<style lang="scss">
#choose-coin___BV_modal_content_ {
  width: 480px;
  min-height: 500px;
}
#choose-coin___BV_modal_body_ {
  padding-right: 4px !important;
  .radio_btn {
    position: absolute;
    left: -30px;
    top: 20px;
  }
  input[type="radio"]:after {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    top: -4px;
    right: 4px;
    position: relative;
    background-color: #222b2f;
    content: "";
    display: inline-block;
    visibility: visible;
    border: 3px solid #38454d;
  }

  input[type="radio"]:checked:after {
    width: 20px;
    height: 20px;
    border-radius: 20px;
    position: relative;
    background-color: #d6dde1;
    content: "";
    display: inline-block;
    visibility: visible;
  }
  .normal:checked:after {
    border: 4px solid #09BE8B;
  }
  h5{ 
    width: 100%;
    text-align: center;
    padding: 24px 0;
  }
  span.close {
    cursor: pointer;
    position: absolute;
    right: 30px;
    top: 25px;
  }
  .info {
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    color: #d6dde1;
    text-align: center;
  }
  .coin-box-c {
    position: relative;
    overflow-y: scroll;
    max-height: 430px;
    .mc {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #677f8e;
      position: absolute;
      left: 75px;
      top: -23px;
    }
    .ac {
      font-family: "Roboto Flex";
      font-style: normal;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: #677f8e;
      position: absolute;
      right: 20px;
      top: -23px;
    }
    .coin-box {
      cursor: pointer;
      width: 408px;
      height: 56px;
      background: #191b1c;
      border-radius: 4px;
      left: 40px;
      position: relative;
      margin-bottom: 13px;
      .coin-name {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 24px;
        color: #d6dde1;
        display: inline-block;
        margin-bottom: 0;
        padding: 16px;
      }
      .coin-balance {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #9bacb6;
        float: right;
        margin-bottom: 0;
        padding: 16px 5px;
        text-align: right;
      }
      .coin-quantity {
        font-family: "Roboto Flex";
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 24px;
        color: #9bacb6;
        float: right;
        margin-bottom: 0;
        padding: 16px 5px;
        min-width: 75px;
      }
    }
  }
  .fb {
    text-align: center;
    margin-top: 10px;
    button {
      width: 120px;
      height: 36px;
      background-color: #09BE8B;
      border-radius: 4px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      color: #0a0d10;
    }
  }
}
</style>
