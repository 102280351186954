<template>
  <div class="wraper-main">
    <div class="wrapper-treeMap" v-if="ShowAllCategories">
      <div class="heading-AllCategoaries" style="margin-left: 24px">
        <P
          class="textGrey-heading AllCategoriesCurrency"
          @click="ShowAllCategories = !ShowAllCategories"
          >All Categories / Currency</P
        >
      </div>
      <!-- SelectBox start -->

      <!-- <div
        class="justify-content-start d-flex flex-row"
        style="margin-left: 24px"
      >
        <div class="firt-selectbox">
          <div
            class="hmc"
            tabindex="0"
            @click="show_time_options = !show_time_options"
            @blur="show_time_options = false"
            @change="get_categories()"
            style="width: 128px"
          >
            <span class="text" :class="show_time_options ? 'active' : ''">{{
              selected_time_option
            }}</span>
            <span class="icon"
              ><svg
                width="7"
                height="5"
                viewBox="0 0 7 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <div class="options" :class="show_time_options ? 'show' : 'hidden'">
              <ul>
                <li
                  :class="
                    selected_time_option == 'Indicator' ? 'li-active' : ''
                  "
                  @click="selected_time_option = 'Indicator'"
                >
                  %Chg (24h)
                </li>
                <li
                  :class="selected_time_option == '15m' ? 'li-active' : ''"
                  @click="selected_time_option = '%Chg (15 mins)'"
                >
                  %Chg (15 mins)
                </li>
                <li
                  :class="selected_time_option == '30m' ? 'li-active' : ''"
                  @click="selected_time_option = '%Chg (30 mins)'"
                >
                  %Chg (30 mins)
                </li>
                <li
                  :class="selected_time_option == '1h' ? 'li-active' : ''"
                  @click="selected_time_option = '%Chg (1hr)'"
                >
                  %Chg (1hr)
                </li>
                <li
                  :class="selected_time_option == '4h' ? 'li-active' : ''"
                  @click="selected_time_option = '%Chg (4hr)'"
                >
                  %Chg (4hr)
                </li>
                <li
                  :class="selected_time_option == '1w' ? 'li-active' : ''"
                  @click="selected_time_option = '%Chg (1Week)'"
                >
                  %Chg (1Week)
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div class="second-selectbox">
          <div
            class="hmc"
            tabindex="0"
            @click="show_sort_options = !show_sort_options"
            @blur="show_sort_options = false"
            style="width: 160px; margin-left: 17px"
          >
            <span class="text" :class="show_sort_options ? 'active' : ''"
              >Sort By: {{ selected_sort_option }}</span
            >
            <span class="icon"
              ><svg
                width="7"
                height="5"
                viewBox="0 0 7 5"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                  fill="#677F8E"
                />
              </svg>
            </span>
            <div class="options" :class="show_sort_options ? 'show' : 'hidden'">
              <ul>
                <li
                  :class="
                    selected_sort_option == 'Marketcap' ? 'li-active' : ''
                  "
                  @click="sortby('Marketcap')"
                >
                  Marketcap
                </li>
                <li
                  :class="
                    selected_sort_option == 'Traded Volume' ? 'li-active' : ''
                  "
                  @click="sortby('Traded Volume')"
                >
                  Traded Volume
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> -->
      <!-- End SelectBox -->

      <!-- Tree map -->
      <div v-if="isLoading == true" class="loader-wrapper">
        <div class="loader"></div>
      </div>
      <div
        v-else
        style="margin-top: 27px; margin-bottom: 44px; cursor: pointer"
        class="card-body"
      >
        <div class="custom-charts">
          <!-- <apexchart
          type="treemap"
          height="400"
          :options="chart_heatmap_detail"
          :series="series_heatmap_detail"
          ref="chart2"
          class="tab_bar"
        ></apexchart> -->
        </div>
      </div>
      <!-- <Dynamic-Table
        :TableHeader="TableHeading"
        :TableData="Table_heatmap"
        :favCoinRows="favCoinRows"
        :ShowGainLoss="true"
      ></Dynamic-Table> -->
      <!-- <div id="chart" class="custom-charts">
        <apexchart
          type="treemap"
          height="310"
          :options="chartOptions"
          :series="series"
        ></apexchart>
      </div> -->
      <!-- Tree map end -->

      <!-- Table Treemap -->

      <!-- End Table Treemap -->
    </div>

    <!-- heatmap After click on all categories coin -->
    <div class="wrapper-HaetaMap pt-4" v-if="!ShowAllCategories">
      <!-- SelectBox start -->
      <!-- <div class="justify-content-start d-flex flex-row px-3 three-dropdown">
        <div
          class="hmc"
          tabindex="0"
          @click="show_category_options = !show_category_options"
          @blur="show_category_options = false"
          style="width: 196px"
        >
          <span class="text" :class="show_category_options ? 'active' : ''"
            >Show: {{ formatselect(selected_category_option) }}</span
          >
          <span class="icon"
            ><svg
              width="7"
              height="5"
              viewBox="0 0 7 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                fill="#677F8E"
              />
            </svg>
          </span>
          <div
            class="options"
            :class="show_category_options ? 'show' : 'hidden'"
          >
            <ul>
              <g class="cell" transform="translate(2,2)">
                <rect
                  id="bitgur.com.BTC"
                  width="734"
                  height="561"
                  fill="rgb(49, 133, 76)"
                ></rect>
                <clipPath id="clip-bitgur.com.BTC">
                  <use xlink:href="#bitgur.com.BTC"></use> </clipPath
                ><text
                  class="title symbol"
                  clip-path="url(#clip-bitgur.com.BTC)"
                  x="367"
                  y="178.43333333333334"
                  style="
                    font-size: 195.733px;
                    text-shadow: rgb(0, 0, 0) 1px 1px 1px;
                    fill: white;
                  "
                >
                  <tspan>BTC</tspan> </text
                ><text
                  class="title change"
                  clip-path="url(#clip-bitgur.com.BTC)"
                  x="367"
                  y="374.16666666666674"
                  style="
                    font-size: 122.333px;
                    text-shadow: rgb(0, 0, 0) 1px 1px 1px;
                    fill: white;
                  "
                >
                  <tspan>+</tspan>
                  <tspan>4</tspan>
                  <tspan>.</tspan>
                  <tspan>4</tspan>
                  <tspan>2</tspan>
                  <tspan>%</tspan>
                </text>
              </g>
              <li
                :class="
                  selected_category_option == '' ? 'li-active' : ''
                "
                @click="selected_category_option = '', get_categories()"
              >
                รวมทุกเหรียญ
              </li>
              <li
                :class="
                  selected_category_option == 'ยกเว้น BTC' ? 'li-active' : ''
                "
                @click="selected_category_option = 'exBTC', get_categories()"
              >
                ยกเว้น BTC
              </li>
              <li
                :class="
                  selected_category_option == 'ยกเว้น Stablecoin'
                    ? 'li-active'
                    : ''
                "
                @click="selected_category_option = 'exStCoin', get_categories()"
              >
                ยกเว้น Stablecoin
              </li>
            </ul>
          </div>
        </div>
        <div
          class="hmc"
          tabindex="0"
          @click="show_24h_options = !show_24h_options"
          @blur="show_24h_options = false"
          style="width: 124px; margin-left: 16px"
        >
          <span class="text" :class="show_24h_options ? 'active' : ''">{{
            selected_24h_option
          }}</span>
          <span class="icon"
            ><svg
              width="7"
              height="5"
              viewBox="0 0 7 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                fill="#677F8E"
              />
            </svg>
          </span>
          <div class="options" :class="show_24h_options ? 'show' : 'hidden'">
            <ul>
              <li
                :class="selected_24h_option == '%Chg (24h)' ? 'li-active' : ''"
                @click="selected_24h_option = '%Chg (24h)'"
              >
                %Chg (24h)
              </li>
              <li
                :class="selected_24h_option == '15 mins' ? 'li-active' : ''"
                @click="selected_24h_option = '15 mins'"
              >
                15 mins
              </li>
              <li
                :class="selected_24h_option == '30 mins' ? 'li-active' : ''"
                @click="selected_24h_option = '30 mins'"
              >
                30 mins
              </li>
              <li
                :class="selected_24h_option == '1 hr' ? 'li-active' : ''"
                @click="selected_24h_option = '1 hr'"
              >
                1 hr
              </li>
              <li
                :class="selected_24h_option == '4 hr' ? 'li-active' : ''"
                @click="selected_24h_option = '4 hr'"
              >
                4 hr
              </li>
              <li
                :class="selected_24h_option == '24 hr' ? 'li-active' : ''"
                @click="selected_24h_option = '24 hr'"
              >
                24 hr
              </li>
              <li
                :class="selected_24h_option == '1 week' ? 'li-active' : ''"
                @click="selected_24h_option = '1 week'"
              >
                1 week
              </li>
            </ul>
          </div>
        </div>
        <div
          class="hmc"
          tabindex="0"
          @click="show_sortby_options = !show_sortby_options"
          style="width: 180px; margin-left: 16px"
        >
          <span class="text" :class="show_sortby_options ? 'active' : ''"
            >Sort by: {{ formatselect(selected_sortby_option) }}</span
          >
          <span class="icon"
            ><svg
              width="7"
              height="5"
              viewBox="0 0 7 5"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M0.8225 0.0107422L3.5 2.68241L6.1775 0.0107422L7 0.833242L3.5 4.33324L0 0.833242L0.8225 0.0107422Z"
                fill="#677F8E"
              />
            </svg>
          </span>
          <div class="options" :class="show_sortby_options ? 'show' : 'hidden'">
            <ul>
              <li
                :class="
                  selected_sortby_option == 'Marketcap' ? 'li-active' : ''
                "
                @click="selected_sortby_option = 'mcap', get_categories()"
              >
                Marketcap
              </li>
              <li
                :class="
                  selected_sortby_option == 'Traded Volume' ? 'li-active' : ''
                "
                @click="selected_sortby_option = 'tradeVol', get_categories()"
              >
                Traded Volume
              </li>
            </ul>
          </div>
        </div>
      </div> -->
      <!-- End SelectBox -->
      <!-- <div class="heading-AllCategories px-3">
        <P class="textGrey-heading c-heading">All Categories</P>
      </div> -->

      <!-- Tree map -->
      <div v-if="isLoading == true" class="loader-wrapper">
        <div class="loader"></div>
      </div>
      <div v-else id="chart" class="custom-charts">
        <!-- <treechart :TableData="TableDatas" /> -->
        <div class="card-body pr-3">
          <!-- TradingView Widget BEGIN -->
          <div  class="tradingview-widget-container pt-0">
      <div  class="tradingview-widget-container__widget pt-0"></div>
      <div class="tradingview-widget-copyright">
      </div>
    </div>
        </div>
        <!-- TradingView Widget END -->
        <!-- <apexchart
            type="treemap"
            height="400"
            :options="chart_heatmap"
            :series="series_heatmap"
            ref="chart2"
            class="tab_bar"
          ></apexchart> -->
      </div>
      <!-- <Dynamic-Table
          :TableHeader="TableHeading2"
          :TableData="TableDatas"
          :favCoinRows="false"
          v-on:child-method="updateParent"
          :ShowAllCategoriesCurrency="true"
          current_page="heatmap"
        ></Dynamic-Table> -->

      <!-- <apexchart
          v-if="ShowAllCategories"
          type="treemap"
          height="310"
          :options="chartOptions"
          :series="series"
        >
        </apexchart> -->
    </div>
    <!-- Tree map end -->

    <!-- Table Treemap -->

    <!-- End Table Treemap -->
  </div>
  <!-- heatmap After click on all categories coin -->
</template>
<script
  type="text/javascript"
  src="https://s3.tradingview.com/external-embedding/embed-widget-crypto-coins-heatmap.js"
  async
>
  {
  "dataSource": "Crypto",
  "blockSize": "market_cap_calc",
  "blockColor": "change",
  "locale": "en",
  "symbolUrl": "",
  "colorTheme": "dark",
  "hasTopBar": false,
  "isDataSetEnabled": false,
  "isZoomEnabled": true,
  "hasSymbolTooltip": true,
  "isMonoSize": false,
  "width": "100%",
  "height": "100%"
}
</script>
<script>
import { HTTP } from "@/config/axios";
import VueApexCharts from "vue-apexcharts";
import Table from "../../components/table/Table.vue";
import treechart from "./treechart.vue";
import hmc from "./hmc.vue";
export default {
  name: "HeatmapExample",
  components: {
    apexchart: VueApexCharts,
    "Dynamic-Table": Table,
    treechart: treechart,
    hmc: hmc,
  },
  data: function () {
    return {
      series_heatmap_detail: [
        {
          data: [],
        },
      ],
      series_heatmap: [
        {
          data: [],
        },
      ],
      isLoading: false,
      show_time_options: false,
      selected_time_option: "%Chg (24h)",
      show_sort_options: false,
      selected_sort_option: "Marketcap",
      show_category_options: false,
      selected_category_option: "",
      show_24h_options: false,
      selected_24h_option: "%Chg (24h)",
      show_sortby_options: false,
      selected_sortby_option: "mcap",
      ShowAllCategories: false,
      show_hmc: false,
      series: [
        {
          name: "first",
          data: [
            {
              x: "BTC",
              y: "+4.89%",
            },
            {
              x: "ETH",
              y: "-8.89%",
            },
            {
              x: "USDC",
              y: "+3.49%",
            },
            {
              x: "USDT",
              y: "+3.21%",
            },
            {
              x: "ETH",
              y: "-4.89%",
            },
            {
              x: "SOL",
              y: "-5.89%",
            },
            {
              x: "BUS",
              y: "+3.29%",
            },
            {
              x: "USDC",
              y: "+2.39%",
            },
            {
              x: "USDT",
              y: "+2.21%",
            },
            {
              x: "BNP",
              y: "+4.89%",
            },
            {
              x: "UNP",
              y: "+4.89%",
            },
            {
              x: "DOT",
              y: "-7.89%",
            },
            {
              x: "ADX",
              y: "+4.89%",
            },
            {
              x: "TRX",
              y: "+2.89%",
            },
            {
              x: "DOGE",
              y: "-9.89%",
            },
          ],
        },
      ],
      chartOptions: {
        stroke: {
          colors: ["#141B1F"],
        },
        xaxis: {
          type: "numeric",
          lines: {
            show: false,
          },
          axisBorder: {
            show: false,
          },

          labels: {
            show: false,
          },
        },
        yaxis: [
          {
            y: 10,
            offsetX: 10,
            offsetY: 10,
            padding: {
              left: 10,
              right: 10,
            },
          },
        ],
        legend: {
          show: false,
        },
        chart: {
          height: 20,
          type: "treemap",
          toolbar: {
            show: false,
          },
        },
        // title: {
        //   text: "Treemap with Color scale",
        // },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "14px",
            // fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
          },
          formatter: function (text, op) {
            return [text, op.value];
          },
          offsetY: -4,
        },

        plotOptions: {
          treemap: {
            enableShades: false,
            // shadeIntensity: 0.5,
            // reverseNegativeShade: false,
            // useFillColorAsStroke: true,
            colorScale: {
              ranges: [
                {
                  from: 4,
                  to: 10,
                  color: "#6EA028",
                },
                {
                  from: 0,
                  to: 3.5,
                  color: "#D1BB4C",
                },
                {
                  from: -10,
                  to: -1,
                  color: "#CE5033",
                },
              ],
            },
          },
        },
      },
      currentCoin: {
        base: "",
        coin: "",
        symbol: "BTC/USDT",
      },
      TableHeading: [
        {
          headingCoin: "Coin ",
          headingLastPrice: "Last Price",
          headingHourChange: "Change (24h)",
          headingVolume: "Volume (M)",
          headingMarketCap: "Market Cap",
          headingMarketCapM: "Market Cap (M)",
          headingMarketCapPercentage: "%Market Cap",
          headingDescription: "Description",
          headingCategories: "Categories",
        },
      ],
      TableHeading2: [
        {
          headingCoin: "Coin ",
          headingLastPrice: "Last Price",
          headingHourChange: "24h % Change",
          headingVolume: "Volume",
          headingMarketCap: "Market Cap",
          headingMarketCapM: "Market Cap (M)",
          // headingMarketCapPercentage: "% Market Cap",
          headingDescription: "Description",
          headingCategories: "Categories",
        },
      ],
      TableDatas: [],
      TableDatas2: [
        {
          StarIcon: "",
          CoinImage: require("../../assets/images/favoriteTable/bitcoin.png"),
          Coinsybmol: "BTC/THB",
          CoinName: "Bitcoin",
          lastPrice: "98,500,000",
          hourChange: "+3.78%",
          volume: "100,000 M",
          marketCap: "168,987 M",
          marketCapM: "100,000",
          Description:
            "เหรียญที่ถูกสร้างขึ้นมาโดยมีวัตถุประสงค์ว่าจะเป็นสกุล เงินของโลก...",
          Categories: "Currency",
          MarketCapPercentageS: "768,987 M",
          MarketCapPercentage: "50.16%",
        },
        {
          StarIcon: "",
          CoinImage: require("../../assets/images/favoriteTable/bitcoin.png"),
          Coinsybmol: "BTC/THB",
          CoinName: "Bitcoin",
          lastPrice: "98,500,000",
          hourChange: "+3.78%",
          volume: "100,000 M",
          marketCap: "168,987 M",
          marketCapM: "100,000",
          Description:
            "เหรียญที่ถูกสร้างขึ้นมาโดยมีวัตถุประสงค์ว่าจะเป็นสกุล เงินของโลก...",
          Categories: "Currency",
          MarketCapPercentageS: "768,987 M",
          MarketCapPercentage: "50.16%",
        },
        {
          StarIcon: "",
          CoinImage: require("../../assets/images/favoriteTable/bitcoin.png"),
          Coinsybmol: "BTC/THB",
          CoinName: "Bitcoin",
          lastPrice: "98,500,000",
          hourChange: "+3.78%",
          volume: "100,000 M",
          marketCap: "168,987 M",
          marketCapM: "100,000",
          Description:
            "เหรียญที่ถูกสร้างขึ้นมาโดยมีวัตถุประสงค์ว่าจะเป็นสกุล เงินของโลก...",
          Categories: "Currency",
          MarketCapPercentageS: "768,987 M",
          MarketCapPercentage: "50.16%",
        },
      ],
      favCoinRows: [],
      Table_heatmap: [
        {
          StarIcon: "",
          CoinImage: require("../../assets/images/favoriteTable/bitcoin.png"),
          Coinsybmol: "BTC/THB",
          CoinName: "Bitcoin",
          lastPrice: "98,500,000",
          hourChange: "+3.78%",
          volume: "100,000 M",
          marketCap: "168,987 M",
          marketCapM: "100,000",
          Description:
            "เหรียญที่ถูกสร้างขึ้นมาโดยมีวัตถุประสงค์ว่าจะเป็นสกุล เงินของโลก...",
          Categories: "Currency",
          MarketCapPercentageS: "768,987 M",
          MarketCapPercentage: "50.16%",
        },
        {
          StarIcon: "",
          CoinImage: require("../../assets/images/favoriteTable/bitcoin.png"),
          Coinsybmol: "BTC/THB",
          CoinName: "Bitcoin",
          lastPrice: "98,500,000",
          hourChange: "+3.78%",
          volume: "100,000 M",
          marketCap: "168,987 M",
          marketCapM: "100,000",
          Description:
            "เหรียญที่ถูกสร้างขึ้นมาโดยมีวัตถุประสงค์ว่าจะเป็นสกุล เงินของโลก...",
          Categories: "Currency",
          MarketCapPercentageS: "768,987 M",
          MarketCapPercentage: "50.16%",
        },
        {
          StarIcon: "",
          CoinImage: require("../../assets/images/favoriteTable/bitcoin.png"),
          Coinsybmol: "BTC/THB",
          CoinName: "Bitcoin",
          lastPrice: "98,500,000",
          hourChange: "+3.78%",
          volume: "100,000 M",
          marketCap: "168,987 M",
          marketCapM: "100,000",
          Description:
            "เหรียญที่ถูกสร้างขึ้นมาโดยมีวัตถุประสงค์ว่าจะเป็นสกุล เงินของโลก...",
          Categories: "Currency",
          MarketCapPercentageS: "768,987 M",
          MarketCapPercentage: "50.16%",
        },
      ],
      favCoinRows: [],
      show_tree: false,
      data_cat_all: [],
      show_hmc: false,
      sort_by: "",
      show:0,
    };
  },
  computed: {
    chart_heatmap() {
      this.isLoading = true;
      var chartOptions = {
        toolbar: {
          show: false,
        },
        legend: {
          show: false,
        },
        chart: {
          toolbar: {
            show: false, // Set to false to hide the toolbar
          },
          height: 350,
          type: "treemap",
        },
        stroke: {
          show: true,
          curve: "straight",
          lineCap: "butt",
          colors: ["#141b1f"],
          width: 5,
          dashArray: 0,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "20px",
          },
          formatter: function (text, op) {
            var t = op.value + " %";
            return [text];
          },
          offsetY: 0,
        },
        plotOptions: {
          treemap: {
            useFillColorAsStroke: false,
            distributed: false,
            enableShades: false,
            shadeIntensity: 0.5,
            reverseNegativeShade: false,
            colorScale: {
              ranges: [
                {
                  from: -10000,
                  to: 0,
                  foreColor: "#0cad6e",
                  // color: "#f86b7d",
                  color: require("./heatmap_bg/image.png"),
                },
                {
                  from: 0.001,
                  to: 500000000,
                  color: "#0cad6e",
                },
              ],
            },
          },
        },
      };
      this.isLoading = false;
      return chartOptions;
    },
    chart_heatmap_detail() {
      this.isLoading = true;
      var chartOptions = {
        legend: {
          show: false,
        },
        chart: {
          toolbar: {
            show: false, // Set to false to hide the toolbar
          },
          height: 350,
          type: "treemap",
        },
        stroke: {
          show: true,
          curve: "straight",
          lineCap: "butt",
          colors: ["#141b1f"],
          width: 10,
          dashArray: 0,
        },
        dataLabels: {
          enabled: true,
          style: {
            fontSize: "12px",
          },
          formatter: function (text, op) {
            var t = op.value + " %";
            return [text];
          },
          // formatter: function (val) {
          //   return val + "%";
          // },
          offsetY: -4,
        },
        plotOptions: {
          treemap: {
            distributed: false,
            enableShades: false,
            shadeIntensity: 0.5,
            reverseNegativeShade: false,
            colorScale: {
              ranges: [
                {
                  from: -10000,
                  to: 0,
                  color: "#f86b7d",
                },
                {
                  from: 0.001,
                  to: 500000000,
                  color: "#0cad6e",
                },
              ],
            },
          },
        },
      };
      this.isLoading = false;
      return chartOptions;
    },
  },
  methods: {
    sortby(value) {
      this.isLoading = true;
      if (this.ShowAllCategories) {
        this.show_hmc = false;
        if (value == "Marketcap") {
          this.Table_heatmap.sort((a, b) => b.marketCap - a.marketCap);
        } else {
          this.Table_heatmap.sort((a, b) => b.volume - a.volume);
        }
        this.selected_sort_option = value;
        this.show_hmc = true;
      } else {
        this.show_tree = false;
        if (value == "marketcap") {
          this.TableDatas.sort((a, b) => b.marketCapM - a.marketCapM);
          this.sort_by = "mcap";
        } else {
          this.TableDatas.sort((a, b) => b.volume - a.volume);
          this.sort_by = "tradeVol";
        }
        this.selected_sortby_option = value;
        this.show_tree = true;
      }
      this.isLoading = false;
    },
    async getSymbolThumb() {
      var coininfo = await this.getCoinInfo();
      if (coininfo == false) {
        return false;
      }
      this.isLoading = true;
      var params = {};
      params["symbol"] = this.currentCoin.symbol;
      params["size"] = 15;

      HTTP.post("/market/symbol-thumb-trend", {
        emulateJSON: true,
      }).then((response) => {
        this.isLoading = false;
        let resp = response.data;
        this.coinMarket = [];
        for (let i = 0; i < resp.length; i++) {
          let symbolArr = resp[i].symbol.split("/");
          let symbol = (symbolArr[0] + "_" + symbolArr[1]).toLowerCase();
          let color = "";
          if (resp[i].chg > 0) {
            color = "green";
          } else if (resp[i].chg < 0) {
            color = "red";
          }
          this.decimal = 2;
          this.coinMarketScale.forEach((item, index) => {
            if (item.coinSymbol == resp[i].coinName) {
              this.decimal = item.baseCoinScale;
            }
          });
          const coinMarket = {
            bathScale: this.decimal,
            Coinsybmol: resp[i].symbol,
            StarIcon: require("../../assets/images/favoriteTable/star.png"),
            CoinImage:
              this.MARKET_WS_URL + `/market/coin/image/${resp[i].logoFileId}`,
            CoinName: resp[i].coinName,
            lastPrice: this.$MyFunc.NumberFormat(resp[i].close, this.decimal),
            hourChange: resp[i].chg,
            chg: resp[i].chg,
            volume: this.$MyFunc.mathFloorPrice(
              resp[i].marketCapVolume24h,
              this.decimal
            ),
            marketCap: this.$MyFunc.mathFloorPrice(
              resp[i].marketCap,
              this.decimal
            ),
            series: [
              {
                name: "STOCK ABC",
                data: resp[i].trend,
              },
            ],
          };
          this.coinMarket.push(coinMarket);

          if (this.currentCoin.symbol == resp[i].symbol) {
            if (resp[i].chg < 0) {
              this.priceInfo.textColor = "red";
            } else if (resp[i].chg > 0) {
              this.priceInfo.textColor = "green";
            }

            this.priceInfo.chg = resp[i].change;
            this.priceInfo.high24 = resp[i].high;
            this.priceInfo.low24 = resp[i].low;
            this.priceInfo.vol24 = resp[i].volume;
            this.priceInfo.pChg = resp[i].chg;
          }
        }

        if (this.isUserLogin) {
          this.getFov();
        } else {
          let fav_local = localStorage.getItem("fav");
          if (fav_local != null || fav_local != undefined) {
            var fav_local_s = fav_local.split(",");
          }
          if (fav_local != "" && fav_local != null) {
            for (let i = 0; i < this.coinMarket.length; i++) {
              for (let j = 0; j < fav_local_s.length; j++) {
                let typeSymbol = this.coinMarket[i].Coinsybmol;
                if (fav_local_s[j] == typeSymbol) {
                  this.$set(this.favCoinRows, typeSymbol, true);
                  break;
                } else {
                  this.$set(this.favCoinRows, typeSymbol, false);
                }
              }
            }
          }
        }
      });
    },
    getFov() {
      HTTP.post("uc/member/get-member-favorite").then((result) => {
        let memberFavorite = result.data;
        if (memberFavorite.code == 0) {
          if (this.isUserLogin) {
            for (let keyy in this.coinMarket) {
              if (memberFavorite.data.length > 0) {
                for (let key in memberFavorite.data) {
                  let typeSymbol = this.coinMarket[keyy].Coinsybmol;
                  if (memberFavorite.data[key].coinId == typeSymbol) {
                    if (memberFavorite.data[key].favorite) {
                      this.$set(this.favCoinRows, typeSymbol, true);
                      break;
                    } else {
                      this.$set(this.favCoinRows, typeSymbol, false);
                    }
                  } else {
                    this.$set(
                      this.favCoinRows,
                      this.coinMarket[keyy].Coinsybmol,
                      false
                    );
                  }
                }
              } else {
                this.$set(
                  this.favCoinRows,
                  this.coinMarket[keyy].Coinsybmol,
                  false
                );
              }
            }
          }
        }
      });
    },
    formatselect(value) {
      var t = "";
      if (value == "") {
        t = "รวมทุกเหรียญ";
      }
      if (value == "exBTC") {
        t = "ยกเว้น BTC";
      }
      if (value == "exStCoin") {
        t = "ยกเว้น Stablecoin";
      }
      if (value == "mcap") {
        t = "MarketCap";
      }
      if (value == "tradeVol") {
        t = "Traded Volume";
      }
      return t;
    },
    async getCoinInfo() {
      let self = this;
      await HTTP.post("/market/symbol-info-all")
        .then((res) => {
          self.coinMarketScale = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      return true;
    },
    async get_categories() {
      try {
        var obj = {
          cond: this.selected_category_option,
          size: this.selected_sortby_option,
        };
        // exBTC,exStCoin,ว่าง,
        this.isLoading = true;
        this.TableDatas = [];
        await HTTP.post("/market/announcement/get-heatmap", obj).then(
          (response) => {
            var data = response.data;
            this.data_cat_all = data.data;
            if (data.code == 0) {
              let i = 0;
              this.series_heatmap[0].data = [];
              data.data.forEach((element) => {
                var a = {
                  CoinImage: require("../../assets/images/favoriteTable/bitcoin.png"),
                  CoinName: element.cateName,
                  hourChange: element.chgAvg,
                  volume: element.volumeTotal,
                  marketCapM: element.marketCapTotal,
                  Description: element.cateDetail,
                  Categories: element.cateName,
                  MarketCapPercentageS: element.marketCapTotal,
                  length: i,
                  // MarketCapPercentage: "50.16%",
                };
                i++;

                if (element.chgAvg != null) {
                  this.series_heatmap[0].data.push({
                    x: element.cateName,
                    y: this.$MyFunc.PercentChg(element.chgAvg),
                  });
                }

                this.TableDatas.push(a);
              });

              this.show_tree = true;
            }
          }
        );
        this.isLoading = false;
      } catch (error) {
        console.log(error);
      }
    },
    async get_heatmap_detail(value) {
      this.isloading = true;
      this.show_hmc = false;
      try {
        this.Table_heatmap = [];
        var v = value.length;
        var data_l = this.data_cat_all[value.length];
        var data = this.data_cat_all[value.length].details;
        if (data == null) {
          this.ShowAllCategories = false;
          this.$notify({
            group: "notification",
            type: "error",
            title: "Error",
            text:
              "ไม่พบรายละเอียดใน  " +
              data_l.cateName +
              " นี้ กรุณาจัดการที่ Backoffice",
          });
          return false;
        } else {
          this.ShowAllCategories = true;
        }
        this.series_heatmap_detail[0].data = [];
        data.forEach((element) => {
          // coin_32/
          var a = {
            StarIcon: "",
            CoinImage: require("../../assets/images/coin_32/" +
              element.coinName.toLowerCase() +
              ".png"),
            Coinsybmol: element.symbol,
            CoinName: element.coinName,
            lastPrice: element.close,
            hourChange: element.chg,
            volume: element.volume,
            marketCap: element.marketCap,
          };
          this.series_heatmap_detail[0].data.push({
            x: element.coinName,
            y: this.$MyFunc.PercentChg(element.chg),
          });
          this.Table_heatmap.push(a);
        });
        this.show_hmc = true;
        this.isloading = false;
      } catch (error) {
        console.log(error);
      }
    },
    updateParent(value) {
      this.get_heatmap_detail(value);
      // this.ShowBuySell = value_from_child.child_msg;

      // alert("hello child" + value_from_child)
    },
  },
  watch: {},
  mounted() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.async = true;
    script.src = 'https://s3.tradingview.com/external-embedding/embed-widget-crypto-coins-heatmap.js';
    script.innerHTML = `
    {
    "dataSource": "Crypto",
    "blockSize": "market_cap_calc",
    "blockColor": "change",
    "locale": "en",
    "symbolUrl": "",
    "colorTheme": "dark",
    "hasTopBar": false,
    "isDataSetEnabled": false,
    "isZoomEnabled": true,
    "hasSymbolTooltip": true,
    "isMonoSize": false,
    "width": "100%",
    "height": "800"
  }`;
    // this.$el.querySelector('.tradingview-widget-container__widget').appendChild(script);
    this.$el.querySelector('.tradingview-widget-container__widget').appendChild(script);



    // console.log(a)
    // this.get_categories();
  },
};
</script>
<style scoped lang="scss">
.hmc {
  border: 1px solid #191b1c;
  border-radius: 4px;
  position: relative;
  height: 32px;
  display: inline-block;
  background-color: #191b1c;

  .text {
    width: 100%;
    height: 100%;
    padding-left: 8px;
    font-family: "Roboto Flex";
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 24px;
    display: inline-block;
    color: #d6dde1;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  span.active {
    color: white;
    background-color: #191b1c;
    border-radius: 2px;
  }

  svg {
    position: absolute;
    right: 5px;
    top: 50%;
    transform: translateY(-50%);
  }

  .options {
    position: absolute;
    left: 0;
    background-color: #28363e;
    width: 100%;
    text-align: left;
    z-index: 20;
    top: 33px;
    border-radius: 4px;

    ul {
      padding: 0;
      margin: 0;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        height: 30px;
        border-radius: 4px;
        padding-left: 5px;
        color: #d6dde1;

        &:hover {
          color: white;
          background-color: #222b2f;
        }
      }
    }
  }
}

.heading-AllCategories {
  width: 30%;
}

.three-dropdown {
  margin-top: 32px;
  margin-bottom: 24px;
}

.custom-charts {
  padding-right: 23px;
  padding-left: 23px;
}

.firt-selectbox select {
  width: 124px !important;
  height: 32px;
  border-radius: 4px;
  padding: 4px, 8px, 4px, 8px !important;
  gap: 4px;
}
.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 43, 47, 0.7);
  /* สีดำที่โปร่งแสง 70% */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* สร้าง loader ที่หมุน */
.loader {
  border: 4px solid rgba(255, 255, 255, 0.4);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

.second-selectbox select {
  width: 292px !important;
  height: 32px;
  border-radius: 4px;
  padding: 4px, 8px, 4px, 8px !important;
  gap: 4px;
  margin-left: 17px;
}

.borderColor {
  border-color: #28363e;
}

.textGrey-heading {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  line-height: 24px;
  font-family: "Roboto Flex";
}

.c-heading:hover {
  color: #09be8b;
  cursor: pointer;
}

.AllCategoriesCurrency {
  cursor: pointer;
  margin-top: 32px;
  margin-bottom: 24px;
}

.AllCategoriesCurrency:hover {
  color: #09be8b;
}

select > option:checked,
select > option:hover {
  /* box-shadow: 0 0 10px 100px #dc6900 inset; */
  color: #09be8b !important;
}

/* select:focus{
    border-color: #09BE8B;
    outline:none;
} */
select {
  padding: 5px;
  border-radius: 4px;
  height: 32px;
  color: #ffffff;
  line-height: 24px;
  font-family: "Roboto Flex";
  padding-right: 30px;
  font-size: 14px;
  position: relative;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  border: 1px solid #28363e !important;
  background: #677f8e
    url("data:image/svg+xml;utf8,<svg viewBox='0 0 140 140' width='14' height='14' xmlns='http://www.w3.org/2000/svg'><g><path d='m121.3,34.6c-1.6-1.6-4.2-1.6-5.8,0l-51,51.1-51.1-51.1c-1.6-1.6-4.2-1.6-5.8,0-1.6,1.6-1.6,4.2 0,5.8l53.9,53.9c0.8,0.8 1.8,1.2 2.9,1.2 1,0 2.1-0.4 2.9-1.2l53.9-53.9c1.7-1.6 1.7-4.2 0.1-5.8z' fill='white'/></g></svg>")
    no-repeat;
  background-position: right 7px top 50%;
}

.show {
  display: block;
}
.tradingview-widget-container {
  width: 100%;
  height: 100vh; /* Adjust height as necessary */
}
.hidden {
  display: none;
}

.li-active {
  color: #09be8b !important;
}
</style>
