import moment from "moment";
export const MyFunc = {
  NumberFormat(value, digits = 2) {
    let val = Number(value);
    if (val) {
      let formattedValue = val.toFixed(digits);
      let parts = formattedValue.split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + parts[1];
    } else {
      if (val != 0 && value != "NaN") {
        return value;
      } else {
        return (0).toFixed(digits);
      }
    }
  },
  NumberFormatPrice(value, digits = 2) {
    let val = Number(value);
    if(value == 0){ 
      return (0)
    }
    if (val) {
      let type = "";
      let kilo = 10 ** 3;
      let million = 10 ** 6;
      let billion = 10 ** 9;
      let trillion = 10 ** 12;
      let quadrillion = 10 ** 15;
      if (val >= kilo && val < million) {
        val = val / kilo;
        type = "k";
      } else if (val >= million && val < billion) {
        val = val / million;
        type = "M";
      } else if (val >= billion && val < trillion) {
        val = val / billion;
        type = "B";
      } else if (val >= trillion && val < quadrillion) {
        val = val / trillion;
        type = "T";
      }
      let formattedValue = val.toFixed(digits);
      let parts = formattedValue.split(".");
      return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + parts[1] + " " + type;
    } else if (val != 0 && value != "NaN") {
      return value;
    } else {
      return (0).toFixed(digits);
    }
  },
  mathFloorPrice(value, digit) {
    let scale = 10 ** digit;
    let val = (Math.floor(Math.trunc(value * scale)) / scale).toFixed(digit);
    return val;
  },
  FormatDateTime(data,valueformat) { 
    return moment(data).format(valueformat) 
  },
  priceFormat(value) {
    let parts = value.split('.');
    return parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + "." + parts[1];
  },
  PercentChg(val) {
    return (val * 100).toFixed(2);
  },
  FnChkHiddenMinCoin(item, state = false) {
    let hid = true;

    if (state) {
      if (item.coin.name != "THB") {
        if (item.total < 100) {
          hid = false;
        }
      }
    }
    return hid;
  },
  recStatusCash(st) {
    let text = '';
    if (Array.isArray(st)) {
      if (st.includes(2) || st.includes(5) || st.includes(8) || st.includes(9)) {
        text = "ไม่สำเร็จ";
      } else if (st.includes(1) || st.includes(11)) {
        text = "สำเร็จ";
      } else if (st.includes(0) || st.includes(4)) {
        text = "กำลังดำเนินการ";
      } else {
        text = "ทั้งหมด";
      }
    } else if (st == 6) {
      text = "รอคืนเงิน";
    } else if (st == 10) {
      text = "รอฝากเงิน";
    } else {
      text = "ทั้งหมด";
    }
    return text;
  },
  recStatusCoin(st) {
    let text = '';
    if (Array.isArray(st)) {
      if (st.includes(0) || st.includes(4)) {
        text = "อยู่ระหว่างการถอน";
      } else if (st.includes(9) || st.includes(2)) {
        text = "ไม่สำเร็จ";
      } else {
        text = "ทั้งหมด";
      }
    } else if (st == 1) {
      text = "สำเร็จ";
    } else if (st == 4) {
      text = "กำลังดำเนินการ";
    } else if (st == 7) {
      text = "รอระบุต้นทุน";
    } else {
      text = "ทั้งหมด";
    }
    return text;
  },
  recStatusColor(st) {
    let text = '';
    if (st == 0) {
      text = "yellow";
    } else if (st == 1) {
      text = "green";
    } else if (st == 2) {
      text = "red";
    } else if (st == 3) {
      text = "yellow";
    } else if (st == 4) {
      text = "yellow";
    } else if (st == 5) {
      text = "red";
    } else if (st == 6) {
      text = "red";
    } else if (st == 7) {
      text = "orange";
    } else {
      text = "white";
    }
    return text;
  },
  recHistory(st) {
    let text = '';
    if (st == 0) {
      text = "Pending";
    } else if (st == 1) {
      text = "Match";
    } else if (st == 2) {
      text = "Canceled";
    } else if (st == 3) {
      text = "Overtimed";
    } else if (st == 4) {
      text = "Rejected";
    } else {
      text = "All Status";
    }
    return text;
  },
  recHistoryColor(st) {
    let text = '';
    if (st == 0) {
      text = "yellow";
    } else if (st == 1) {
      text = "green";
    } else if (st == 2) {
      text = "red";
    } else if (st == 3) {
      text = "yellow";
    } else if (st == 4) {
      text = "yellow";
    } else {
      text = "white";
    }
    return text;
  },
  BuySellDirection(st, sd = false) {
    let text = '';
    if (st == 0) {
      if (sd) {
        text = "B";
      } else {
        text = "Buy";
      }
    } else if (st == 1) {
      if (sd) {
        text = "S";
      } else {
        text = "Sell";
      }
    } else {
      text = "All Status";
    }
    return text;
  },
  BuySellType(st) {
    let text = '';
    if (st == 0) {
      text = "Market";
    } else if (st == 1) {
      text = "Limit";
    } else {
      text = "All Status";
    }
    return text;
  },
  toFixedDigits(value, digit) {
    const stringValue = value.toString();
    const parts = stringValue.split('.');
    const result = parts[0] + (parts[1] ? '.' + parts[1].slice(0, digit) : '');
    return parseFloat(result).toFixed(digit);
  },
  calPersent(total, pl) {
    const plChange = pl;
    const plBase = total - pl;
    let plPersent = (plChange / plBase) * 100;
    return (Math.floor(Math.trunc(plPersent * 100)) / 100).toFixed(2);
  },
  sortingColor(st, type) {
    //DW
    let arrowUpDate = "#677F8E";
    let arrowDownDate = "#677F8E";
    let arrowUpAmout = "#677F8E";
    let arrowDownAmout = "#677F8E";
    let arrowUpCoinUnit = "#677F8E";
    let arrowDownCoinUnit = "#677F8E";
    let arrowUpCoinAmount = "#677F8E";
    let arrowDownCoinAmount = "#677F8E";
    let arrowUpCashAmount = "#677F8E";
    let arrowDownCashAmount = "#677F8E";
    if (type == "transactionDate") {   //DW
      if (st == "asc") {
        arrowUpDate = "#09BE8B"; //desc
      } else if (st == "desc") {
        arrowDownDate = "#09BE8B"; //asc
      }
    } else if (type == "amount") {
      if (st == "asc") {
        arrowUpAmout = "#09BE8B"; //desc
      } else if (st == "desc") {
        arrowDownAmout = "#09BE8B"; //asc
      }
    } else if (type == "coinUnit") {
      if (st == "asc") {
        arrowUpCoinUnit = "#09BE8B"; //desc
      } else if (st == "desc") {
        arrowDownCoinUnit = "#09BE8B"; //asc
      }
    } else if (type == "coinAmount") {
      if (st == "asc") {
        arrowUpCoinAmount = "#09BE8B"; //desc
      } else if (st == "desc") {
        arrowDownCoinAmount = "#09BE8B"; //asc
      }
    } else if (type == "cashAmount") {
      if (st == "asc") {
        arrowUpCashAmount = "#09BE8B"; //desc
      } else if (st == "desc") {
        arrowDownCashAmount = "#09BE8B"; //asc
      }
    }
    return {
      arrowUpDate, arrowDownDate, arrowUpAmout, arrowDownAmout, arrowUpCoinUnit
      , arrowDownCoinUnit, arrowUpCoinAmount, arrowDownCoinAmount, arrowUpCashAmount, arrowDownCashAmount
    };
  },
  sortingColorHistory(st, type, page) {
    //History
    //by Detail
    let arrowUpDatetime = "#677F8E";
    let arrowDownDatetime = "#677F8E";
    let arrowUpCoin = "#677F8E";
    let arrowDownCoin = "#677F8E";
    let arrowUpSide = "#677F8E";
    let arrowDownSide = "#677F8E";
    let arrowUpActual = "#677F8E";
    let arrowDownActual = "#677F8E";
    let arrowUpAvgCost = "#677F8E";
    let arrowDownAvgCost = "#677F8E";
    let arrowUpFeeVat = "#677F8E";
    let arrowDownFeeVat = "#677F8E";
    let arrowUpPl = "#677F8E";
    let arrowDownPl = "#677F8E";
    //by Date
    let arrowUpDateTimeDate = "#677F8E";
    let arrowDownDateTimeDate = "#677F8E";
    let arrowUpTotalBuy = "#677F8E";
    let arrowDownTotalBuy = "#677F8E";
    let arrowUpTotalSell = "#677F8E";
    let arrowDownTotalSell = "#677F8E";
    let arrowUpFeeVatDate = "#677F8E";
    let arrowDownFeeVatDate = "#677F8E";
    let arrowUpPlDate = "#677F8E";
    let arrowDownPlDate = "#677F8E";
    //by Coin
    let arrowUpDateTimeCoin = "#677F8E";
    let arrowDownDateTimeCoin = "#677F8E";
    let arrowUpCoins = "#677F8E";
    let arrowDownCoins = "#677F8E";
    let arrowUpFee = "#677F8E";
    let arrowDownFee = "#677F8E";
    let arrowUpPlCoin = "#677F8E";
    let arrowDownPlCoin = "#677F8E";
    if (page == "byDetail") { //History by Detail
      if (type == "datetime") {
        if (st == "asc") {
          arrowUpDatetime = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownDatetime = "#09BE8B"; //asc
        }
      }
      else if (type == "coin") {
        if (st == "asc") {
          arrowUpCoin = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownCoin = "#09BE8B"; //asc
        }
      }
      else if (type == "side") {
        if (st == "asc") {
          arrowUpSide = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownSide = "#09BE8B"; //asc
        }
      }
      else if (type == "actual") {
        if (st == "asc") {
          arrowUpActual = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownActual = "#09BE8B"; //asc
        }
      }
      else if (type == "avgCost") {
        if (st == "asc") {
          arrowUpAvgCost = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownAvgCost = "#09BE8B"; //asc
        }
      }
      else if (type == "feeVat") {
        if (st == "asc") {
          arrowUpFeeVat = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownFeeVat = "#09BE8B"; //asc
        }
      }
      else if (type == "pl") {
        if (st == "asc") {
          arrowUpPl = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownPl = "#09BE8B"; //asc
        }
      }
    } else if (page == "byDate") { //History by date
      if (type == "datetime") {
        if (st == "asc") {
          arrowUpDateTimeDate = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownDateTimeDate = "#09BE8B"; //asc
        }
      } else if (type == "totalBuy") {
        if (st == "asc") {
          arrowUpTotalBuy = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownTotalBuy = "#09BE8B"; //asc
        }
      } else if (type == "totalSell") {
        if (st == "asc") {
          arrowUpTotalSell = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownTotalSell = "#09BE8B"; //asc
        }
      } else if (type == "feeVat") {
        if (st == "asc") {
          arrowUpFeeVatDate = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownFeeVatDate = "#09BE8B"; //asc
        }
      } else if (type == "pl") {
        if (st == "asc") {
          arrowUpPlDate = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownPlDate = "#09BE8B"; //asc
        }
      }
    } else if (page == "byCoin") { //History by coin
      if (type == "datetime") {
        if (st == "asc") {
          arrowUpDateTimeCoin = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownDateTimeCoin = "#09BE8B"; //asc
        }
      } else if (type == "coin") {
        if (st == "asc") {
          arrowUpCoins = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownCoins = "#09BE8B"; //asc
        }
      } else if (type == "fee") {
        if (st == "asc") {
          arrowUpFee = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownFee = "#09BE8B"; //asc
        }
      } else if (type == "pl") {
        if (st == "asc") {
          arrowUpPlCoin = "#09BE8B"; //desc
        } else if (st == "desc") {
          arrowDownPlCoin = "#09BE8B"; //asc
        }
      }
    }
    return {
      arrowUpDatetime, arrowDownDatetime, arrowUpCoin, arrowDownCoin, arrowUpSide, arrowDownSide, arrowUpActual, arrowDownActual,
      arrowUpAvgCost, arrowDownAvgCost, arrowUpFeeVat, arrowDownFeeVat, arrowUpPl, arrowDownPl
      , arrowUpDateTimeDate, arrowDownDateTimeDate, arrowUpTotalBuy, arrowDownTotalBuy, arrowUpTotalSell
      , arrowDownTotalSell, arrowUpFeeVatDate, arrowDownFeeVatDate, arrowUpPlDate, arrowDownPlDate
      , arrowUpDateTimeCoin, arrowDownDateTimeCoin, arrowUpCoins, arrowDownCoins, arrowUpFee, arrowDownFee, arrowUpPlCoin, arrowDownPlCoin
    };
  }
};
