<template>
  <div>
    <template>
      <verte 
       picker="square"
       model="rgb"
       display="widget"  
       :rgbSliders="true" 
       :showHistory="null" 
       :enableAlpha="false"
       @input="colorChanged($event)"
       >
      </verte>
    </template>
  </div>
</template>
<script>
export default {
  data() {
    return {
    }
  },
  methods:{
    colorChanged(color){
      
    }
  }
}
</script>
<style lang="scss">
.verte__menu {
  background-color: #141b1f !important;
  width: 340px !important;
  box-shadow: none !important;

}

.verte-picker__cursor {
  box-shadow: none !important;
  border: 2px solid #eb8125 !important;
  width: 9px !important;
  height: 9px !important;

}

.verte-picker__origin {
  background: linear-gradient(to right, #4f3af9, #2ee6d7, #7afc46,#e5422c);
  border-radius: 5px;
}

.verte-picker__canvas {
  width: 340px;
}

.slider {
  margin-left: 5px !important;
  margin-right: -1px !important;
}

.slider__input {
  color: aliceblue;
  background-color: #222b2f;
  border-radius: 5px;
  margin: 6px !important;
  padding: 6px !important;
  margin-right: -15px !important;
}

.slider__track {
  background: #222b2f !important;
  height: 4px !important;
  // margin-right: 15px !important;
  // accent-color:red !important;
}

.slider__handle {
  border: 2px solid #eb8125 !important;
  width: 9px !important;
  height: 9px !important;
}

.slider__fill {
  // background-image: red !important;
  height: 0 !important;
  accent-color: red !important;
}

.verte__input {
  color: aliceblue;
  display: none;
}

.verte__model,
.verte__submit {
  display: none !important;
}

.verte__icon {
  display: none;
}
</style>






  

  






