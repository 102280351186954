<template>
  <div>
    <div class="withdraw-coin-container">
      <div class="dcc-head">
        <div class="col-12"  style="display: inline-flex;">
          <router-link to="/withdraw/cash">
            <button :class="ActiveWithdrawCash" :style="withdrawCashDisplay">Withdraw Cash</button>
          </router-link>
          <router-link to="/withdraw/coin">
            <button :class="ActiveWithdrawCoin" :style="withdrawCoinDisplay">Withdraw Coin</button>
          </router-link>
        </div>
      </div>
      <div v-if="!reslockStatusWithdrawCoin" class="container">
        <div
          v-if="!showTable"
          class="vertical-center"
          align="center"
          style="left: 52px"
        >
          <div class="row" align="center" style="85px">
            <label class="col-12 wc-main-text m-0">
              ผู้ใช้งานต้องลงทะเบียน Wallet ก่อน
            </label>
            <label class="col-12 wc-main-text" style="margin-bottom: 16px">
              จึงจะสามารถถอนเหรียญไปยัง Address ปลายทางได้
            </label>
          </div>
          <div class="last-btn">
            <button @click="showWithdrawModal = true" class="thai-font">
              ลงทะเบียน Wallet
            </button>
          </div>
        </div>
        <coinsListTable @create="createWallet" v-else></coinsListTable>
      </div>
      <div v-else class="text-center" style="padding: 150px;">
        คุณถูกล๊อคการทำธุรกรรม โปรดติดต่อฝ่ายสนับสนุน
      </div>
      <!-- <div class="right-info">
        <p style="color: #677f8e">คู่มือแนะนำการทำรายการถอน</p>
        <ul>
          <li>
            <a
              href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Mar/%e0%b8%84%e0%b8%b9%e0%b9%88%e0%b8%a1%e0%b8%b7%e0%b8%ad%e0%b9%81%e0%b8%99%e0%b8%b0%e0%b8%99%e0%b8%b3%e0%b8%81%e0%b8%b2%e0%b8%a3%e0%b8%96%e0%b8%ad%e0%b8%99%e0%b9%80%e0%b8%87%e0%b8%b4%e0%b8%99.pdf"
              target="_blank"
              style="
                width: 107px;
                height: 24px;
                margin-right: 16px;
                line-height: 19px;
                color: #677f8e;
                text-decoration: none;
              "
              >	คู่มือแนะนำการถอนเงิน</a
            >
          </li>
        </ul>
      </div> -->
    </div>
    <RightInfo />
    <withdrawCoin
      v-if="showWithdrawModal"
      @showOTPModel="showWithdrawOTPModal = true"
      @closeModel="showWithdrawModal = false"
      @wData="CWData"
      :refCode.sync="refCode"
    ></withdrawCoin>
    <OtpModel
      v-if="showWithdrawOTPModal"
      @onOTPSubmitted="onOTPSubmitted()"
      @closeModel="showWithdrawOTPModal = false"
      :whitelist="true"
      :createWalletData="createWalletData"
      :refCode="refCode"
    ></OtpModel>
    <QrSuccessModal @onCloseSuccessModel="showTable = true"></QrSuccessModal>
    <div v-if="!reslockStatusWithdrawCoin">
      <withdrawCoinTutorialModal />
    </div>
  </div>
</template>
<script>
import { HTTP } from "@/config/axios";
import { mapState, mapMutations } from "vuex";
import withdrawCoin from "@/components/Modal/withdrawCoin.vue";
import OtpModel from "@/components/Modal/OtpModel.vue";
import QrSuccessModal from "./components/QrSuccessModal.vue";
import coinsListTable from "./components/coinsListTable.vue";
import withdrawCoinTutorialModal from "./components/withdrawCoinTutorialModal.vue";
import RightInfo from "../../../components/deposit-withdraw/RightInfo.vue";

// import ChooseCoin from "./components/ChooseCoin.vue";
// import ChooseNetwork from "./components/ChooseNetwork.vue";
export default {
  components: {
    withdrawCoin,
    OtpModel,
    QrSuccessModal,
    coinsListTable,
    withdrawCoinTutorialModal,
    RightInfo,
    // ChooseCoin,
    // ChooseNetwork,
  },
  data() {
    return {
      showWithdrawModal: false,
      showWithdrawOTPModal: false,
      showQrSuccessModal: false,
      showTable: true,
      createWalletData: null,
      walletListData: [],
      refCode: "",
      reslockStatusWithdrawCash: false,
      reslockStatusWithdrawCoin: false,
      withdrawCashDisplay: "display: block;",
      withdrawCoinDisplay: "display: block;",
      ActiveWithdrawCash:"not-active",
      ActiveWithdrawCoin:"active"
    };
  },
  created() {
    this.getInitialWallets();
    let user_data = JSON.parse(localStorage.getItem("user-data") || "{}");
    if(user_data?.id){
      let storedData = JSON.parse(localStorage.getItem("resMember") || "{}");
      if (storedData) {
        this.reslockStatusWithdrawCash = storedData.reslockStatus.cannotWithdrawCash;
        this.reslockStatusWithdrawCoin = storedData.reslockStatus.cannotWithdrawCoin;
      }
    }
  },
  methods: {
    ...mapMutations(["setdCoinName"]), // map mutation from Vuex store

    getInitialWallets() {
      this.setdCoinName(""); // commit mutation to update Vuex store
      HTTP.post("/uc/member/get-wallet-whitelist", {
        coinUnit: this.$store.state.dCoinName,
      })
        .then((res) => {
          this.walletListData = res.data.data;
          if (this.walletListData.length > 0) {
            this.showTable = true;
          } else {
            this.showTable = false;
          }
        })
        .catch((err) => {
          console.log(err);
        });
    },
    onOTPSubmitted() {
      this.showWithdrawModal = false;
      this.showWithdrawOTPModal = false;
      this.showQrSuccessModal = true;
      this.showTable = true;
    },
    openOTPModel() {
      this.showWithdrawOTPModal = true;
    },
    createWallet(e) {
      if (e) {
        this.showTable = false;
      }
    },
    CWData(e) {
      this.createWalletData = e;
    },
  },
  mounted() {
    if (!localStorage.getItem("WithdrawCoinAlert")) {
      this.$bvModal.show("withdraw-coin-tutorial");
    }
  },
};
</script>
<style lang="scss">
.withdraw-coin-container {
  .container {
    position: relative;
    min-height: 354px;

    .wc-main-text {
      color: #9bacb6;
      line-height: 24px;
      font-family: "Sarabun";
    }
  }
  .last-btn {
    text-align: center;
    justify-content: center;
    button {
      width: 145px !important;
      height: 36px;
      color: #0a0d10;
      background-color: #09BE8B;
      border-radius: 4px;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      &:hover {
        background-color: #66cdb0;
      }
    }
  }
  border-radius: 8px;
  min-height: auto;
  position: relative;
  .dcc-head {
    button {
      display: inline-block;
      font-size: 16px;
      line-height: 19px;

      padding: 12.5px 0;

      margin-left: 24px;
      border-bottom: 2px solid transparent;
      color: #677f8e;
      &:hover {
        border-bottom: 2px solid #09BE8B;
        color: #d6dde1;
      }
    }

    button.active {
      border-bottom: 2px solid #09BE8B;
      color: #d6dde1;
    }
  }
  .center-section {
    .select-container {
      background-color: #191b1c;
      color: #d6dde1;
      border-radius: 4px;
      height: 40px;
      width: 375px;
      margin: auto 0;
      display: flex;
      align-items: center;
      position: relative;
      cursor: pointer;
      .icon {
        pointer-events: none;
        position: absolute;
        right: 20px;
      }
      .rotate-sc-icon {
        transform: rotateX(180deg);
      }
      .text {
        padding-left: 15px;
        span {
          position: absolute;
          right: 45px;
        }
        span.red {
          color: #ff2500;
        }
        span.yellow {
          color: #f8c417;
        }
      }
      .options {
        position: absolute;
        top: 45px;
        width: 100%;
        background-color: #191b1c;
        border-radius: 4px;
        ul {
          margin: 0;
          padding: 0;
          li {
            display: flex;
            align-items: center;
            height: 40px;
            padding: 0 15px;
            border-radius: 4px;
            &:hover {
              background-color: #2c3b44;
            }
            span.t-red {
              color: #ff2500;
              position: absolute;
              right: 20px;
            }
            span.t-yellow {
              color: #f8c417;
              position: absolute;
              right: 20px;
            }
          }
        }
      }
      .hidden {
        display: none;
      }
      .show {
        display: block;
      }
    }
    .input-container {
      position: relative;
      input {
        background-color: #191b1c;
        color: #d6dde1;
        border-radius: 4px;
        height: 40px;
        padding: 0 40px 0 8px;
        text-transform: capitalize;
        &::placeholder {
          color: #566a76;
        }
      }
      span {
        color: #9bacb6;
        margin-left: -30px;
      }
      .coin-image {
        position: absolute;
        left: 50px;
        top: 7.5px;
        pointer-events: none;
        img {
          width: 23px;
        }
      }
    }
    .info {
      color: #ff2500;
      p {
        margin: 0;
      }
    }
  }
}
.right-info {
  position: absolute;
  top: 3px;
  color: #677f8e;
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  right: -260px;
  line-height: 24px;
  p {
    margin-bottom: 10px;
  }
  ul {
    font-weight: 400;
    list-style: disc;
    position: relative;
    left: -8px;
    li {
      &:hover {
        color: #d6dde1;
      }
      margin-bottom: 5px;
      cursor: pointer;
    }
  }
}
@media (max-width: 640px) {
  .withdraw-coin-container {
    width: auto;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
    .center-section {
      padding: 0;
    }
    .input-container {
      input {
        width: 190px;
      }
    }
  }
}
@media (min-width: 640px) {
  .withdraw-coin-container {
    width: 616px;
    padding-bottom: 0;
    .center-section {
      padding: 0 18px;
    }

    .input-container {
      input {
        width: 240px;
      }
    }
  }
}

@media (min-width: 100px) {
  .right-info {
    display: none;
  }
}

@media (min-width: 1024px) {
  .right-info {
    display: block;
    // right: -14px;
    // margin-right: -255px;
    top: 0;
  }
  .withdraw-coin-container {
    transform: translateX(-50%);
  }
}
@media (max-width: 848px) {
  .withdraw-coin-container {
    background-color: none;
    // margin-left: 40px;
    width: 100%;
    .dcc-head {
      background-color: #192126;
    }
    .bottom-section {
      margin: 0 40px;
    }
    .bcb {
      margin: 0 20px;
    }
    .vertical-center {
      margin-top: 50px;
    }
  }
}
@media (min-width: 848px) {
  .withdraw-coin-container {
    background-color: #192126;
    margin-left: 50%;
    margin-top: 32px;
    transform: translateX(-75.7%);
    .dcc-head {
      border-bottom: 1px solid #28363e;
    }
    .bottom-section {
      margin: 0 25px;
    }
    .bcb {
      margin: 0 -4px;
    }
    .vertical-center {
      margin: 0;
      position: absolute;
      top: 50%;
      -ms-transform: translateY(-50%);
      transform: translateY(-53%);
      left: 54px;
    }
  }
}
</style>
<style scoped>
.sec {
  margin-top: 16px;
}
.heading {
  margin-top: 32px;
  margin-bottom: 20px;
}
.d-info {
  background: #28363e;
  border-radius: 4px;
  height: 69px;
  display: flex;
  justify-content: space-between;
  color: #9bacb6;
  padding: 13px 10px 12px;
  font-size: 14px;
  flex-grow: 1;
  margin-left: 7px;
}
.d-info > div {
  width: 235px;
  display: flex;
  flex-direction: column;
  /* justify-content: space-between; */
  position: relative;
  color: #9bacb6;
  font-weight: 400;
  font-size: 14px;
}

input[type="radio"]:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  top: 26px;
  right: 2px;
  position: relative;
  background-color: #191b1c;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid #38454d;
}

input[type="radio"]:checked:after {
  width: 15px;
  height: 15px;
  border-radius: 15px;
  position: relative;
  background-color: #d6dde1;
  content: "";
  display: inline-block;
  visibility: visible;
  border: 3px solid #09BE8B;
}
.link {
  width: 60px;
  color: #09BE8B;
  margin-top: 9px;
}
.iner-list {
  margin: 32px 0px;
  list-style: inherit;
  padding: 0px;
  list-style-position: inside;
}
</style>
