<template>
  <div class="padding-table scroller bc-table">
    <table class="table ma-3" id="myTable">
      <thead>
        <tr>
          <th style="border: #141b1f"></th>
          <th style="border: #141b1f"></th>
          <th
            scope="colgroup"
            colspan="3"
            class="HeadingBuyGroup BuySellHeading-table"
          >
            Buy
          </th>
          <th
            scope="colgroup"
            colspan="3"
            class="HeadingSellGroup BuySellHeading-table"
          >
            sell
          </th>
        </tr>
      </thead>
      <thead v-for="(item, index) in TableHeader" :key="index">
        <!-- Heading Date -->
        <th scope="col" class="textGreyDark borderBottom hmt">
          <div class="d-flex" >
            <p class="align-Icon-Heading mb-0" style="gap: 6px;">
              {{ item.Date }}
              <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('datetime')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downDateTimeCoin"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upDateTimeCoin"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
            </p>

          </div>
        </th>
        <!-- End Heading Date -->

        <!-- Heading CoinSymbolHeading -->
        <th scope="col" class="textGreyDark borderBottom hmt">
          <p class="align-Icon-Heading mb-0" style="gap: 6px;">
            {{ item.CoinSymbolHeading }}
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('coin')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downCoins"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upCoins"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </p>
        </th>
        <!-- End Heading CoinSymbolHeading -->

        <!-- Heading BuyActualHeading -->
        <th
          scope="col"
          class="textGreyDark border-left-table borderTopBottom pad-r text-right"
        >
          <p class="align-Icon-Heading mb-0">
            {{ item.BuyActualHeading }}
          </p>
        </th>
        <!-- End Heading BuyActualHeading -->

        <!-- Heading BuyAvrgCostHeading -->
        <th scope="col" class="textGreyDark borderTopBottom pad-r text-right">
          <p class="align-Icon-Heading mb-0">
            {{ item.BuyAvrgCostHeading }}
          </p>
        </th>
        <!-- End Heading BuyAvrgCostHeading -->

        <!-- Heading BuyTotalHeading -->
        <th scope="col" class="textGreyDark borderTopBottom pad-r text-right">
          <p class="align-Icon-Heading mb-0">
            {{ item.BuyTotalHeading }}
          </p>
        </th>
        <!-- End Heading BuyTotalHeading -->

        <!-- Heading SellActualHeading -->
        <th
          scope="col"
          class="textGreyDark border-left-table borderTopBottom pad-r text-right"
        >
          <p class="align-Icon-Heading mb-0">
            {{ item.SellActualHeading }}
          </p>
        </th>
        <!-- End Heading SellActualHeading -->

        <!-- Heading SellAvrgCostHeading -->
        <th scope="col" class="textGreyDark borderTopBottom pad-r text-right">
          <p class="align-Icon-Heading mb-0">
            {{ item.SellAvrgCostHeading }}
          </p>
        </th>
        <!-- End Heading SellAvrgCostHeading -->

        <!-- Heading SellTotalHeading -->
        <th
          scope="col"
          class="textGreyDark border-right-table borderTopBottom pad-r text-right"
          style="padding-right: 3px"
        >
          <p class="align-Icon-Heading mb-0">
            {{ item.SellTotalHeading }}
          </p>
        </th>
        <!-- End Heading SellTotalHeading -->

        <!-- Heading FreeVAT -->
        <th scope="col" class="textGreyDark borderBottom pad-r hmt text-right">
          <p class="align-Icon-Heading mb-0" style="gap: 6px;">
            {{ item.FreeVAT }}
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('feeVat')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downFee"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upFee"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </p>
        </th>
        <!-- End Heading FreeVAT -->

        <!-- Heading Total -->
        <th scope="col" class="textGreyDark borderBottom pad-r hmt text-right">
          <p class="align-Icon-Heading mb-0">
            {{ item.Total }}
          </p>
        </th>
        <!-- End Heading Total -->

        <!-- Heading ProfitLoss -->
        <th scope="col" class="textGreyDark borderBottom pad-r hmt text-right">
          <p class="align-Icon-Heading mb-0" style="gap: 6px;">
            {{ item.ProfitLoss }}
            <svg
              width="6"
              height="12"
              viewBox="0 0 6 12"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              @click="getHistorys('pl')"
            >
              <g clip-path="url(#clip0_6556_6983)">
                <path d="M0 8L3 11L6 8H0Z" :fill="downPlCoin"/>
                <path d="M6 4L3 1L0 4L6 4Z" :fill="upPlCoin"/>
              </g>
              <defs>
                <clipPath id="clip0_6556_6983"><rect width="6" height="12" fill="white" /></clipPath>
              </defs>
            </svg>
          </p>
        </th>
        <!-- End Heading ProfitLoss -->
      </thead>
      <tbody>
        <tr class="textDarkgrey-Border" v-if="filteredItems?.length == 0">
          <td colspan="11" class="textGrey text-center">-- ไม่พบรายการ --</td>
        </tr>
        <tr
          class="textDarkgrey-Border"
          v-for="(item, index) in filteredItems"
          :key="index"
        >
          <!-- {{ index }}: {{ item.lastPrice }} -->

          <!-- Datetime -->
          <td>
            <p class="Datetime-one textGrey mb-0">
              {{ item.datetime }}
            </p>
          </td>
          <!-- End Datetime -->

          <!-- CoinSymbol -->
          <td>
            <p class="CoinSymbol-one textGrey mb-0">
              {{ item.coin }}
            </p>
          </td>
          <!-- End CoinSymbol -->

          <!-- BuyActualValue -->
          <td class="border-left-table pad-r text-right">
            <p class="BuyActualValue-one textGrey mb-0">
              {{ $MyFunc.mathFloorPrice(item.actualBuy, 8) }}
            </p>
          </td>
          <!-- End BuyActualValue -->

          <!-- BuyAvrgCostValue -->
          <td class="pad-r text-right">
            <p class="BuyAvrgCostValue-one textGrey mb-0">
              {{ $MyFunc.NumberFormat(item.avgCostBuy, 2) }}
            </p>
          </td>
          <!-- End BuyAvrgCostValue -->

          <!-- BuyTotalValue -->
          <td class="pad-r text-right">
            <p class="BuyTotalValue-one textGrey mb-0">
              {{  $MyFunc.NumberFormat(item.totalBuy, 2) }}
            </p>
          </td>
          <!-- End BuyTotalValue -->

          <!-- SellActualValue -->
          <td class="border-left-table pad-r text-right">
            <p class="SellActualValue-one textGrey mb-0">
              {{ $MyFunc.NumberFormat(item.actualSell, 2) }}
            </p>
          </td>
          <!-- End SellActualValue -->

          <!-- SellAvrgCostValue -->
          <td class="pad-r text-right">
            <p class="SellAvrgCostValue-one textGrey mb-0">
              {{ $MyFunc.NumberFormat(item.avgCostSell, 2) }}
            </p>
          </td>
          <!-- End SellAvrgCostValue -->

          <!-- SellTotalValue -->
          <td class="border-right-table pad-r text-right">
            <p class="SellTotalValue-one textGrey mb-0">
              {{  $MyFunc.NumberFormat(item.totalSell, 2) }}
            </p>
          </td>
          <!-- End SellTotalValue -->

          <!-- last FreeVAT -->
          <td class="pad-r text-right">
            <p class="mb-0 textGrey">{{   $MyFunc.NumberFormat(item.feeVat, 2) }}</p>
          </td>
          <!-- End FreeVAT -->

          <!-- TotalValue -->
          <td class="textGrey pad-r text-right">
            <p class="mb-0">{{    $MyFunc.NumberFormat(item.totalValue, 2) }}</p>
          </td>
          <!-- End TotalValue -->

          <!-- profitloss -->
          <td class="pad-r text-right">
            <p class="mb-2 text-size-custom" :class="item.pl < 0 ? 'textRed' : item.pl > '0' ? 'textGreen' : 'textGrey' ">
              {{ item.pl != 0 ? $MyFunc.priceFormat($MyFunc.mathFloorPrice(item.pl, 2)) + " (" + $MyFunc.priceFormat($MyFunc.calPersent($MyFunc.mathFloorPrice(item.totalSell, 2), $MyFunc.mathFloorPrice(item.pl, 2))) + "%)" : "0.00 (0.00%)" }}
            </p>
          </td>
          <!-- end profitloss -->
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "tableBydetail",
  props: ["TableHeader", "TableData","filteredItems"
          ,"upDateTimeCoin"
          ,"downDateTimeCoin"
          ,"upCoins"
          ,"downCoins"
          ,"upFee"
          ,"downFee"
          ,"upPlCoin"
          ,"downPlCoin"
  ],
  data() {
    return {
      // child_msg: "message from child"
      child_msg: true,
      pageFields: "",
      pageSorts: "desc",
    };
  },

  methods: {
    childMethod() {
      this.$emit("child-method", this.child_msg);
    },
    handleBuySell() {
      this.$router.push("/exchange");
    },
    handleDetail() {
      this.$router.push("/coin-detail");
    },
    getHistorys(secField = "datetime"){
      if(secField == this.pageFields){
        if(this.pageSorts == "asc"){
          this.pageSorts = "desc";
        }else{
          this.pageSorts = "asc";
        }
      }else{
        this.pageFields = secField;
        this.pageSorts = "desc";
      }
      this.$emit('getHistoryCoin', { pageSort: this.pageSorts, pageField:  this.pageFields });
    }
  },
};
</script>
<style lang="scss" scoped>
.table > :not(caption) > * > * {
  padding: 5px 0;
}
.pad-r {
  padding-right: 5px !important;
}
tbody tr td {
  padding-top: 17px !important;
  padding-bottom: 5.5px !important;
  line-height: 24px;

  // padding-left: 5px !important;
}
.dateChevron {
  margin-left: 50px;
  top: 3px;
  position: relative;
}
.textGreen {
  color: #0c9;
  // text-align: center;
  // vertical-align: middle;
}
.textRed {
  color: #de2d40;
  // text-align: center;
  // vertical-align: middle;
}
.textYellow {
  color: #09BE8B;
  cursor: pointer;
}
.textYellow:hover {
  color: #d6dde1;
}
.text-size-custom{
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
}

.textGrey {
  color: rgb(255, 255, 255);
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  // vertical-align: middle;
}
.textGreyDark {
  color: #677f8e;
  line-height: 14px;
  // vertical-align: middle;
}
.textGreyDark-table {
  color: #677f8e;
  // vertical-align: sub;
  font-size: 14px;
  // font-family: "Roboto Flex";
  font-weight: 400;
  line-height: 24px;
}
.textGreyDark-description {
  color: #677f8e;
  // vertical-align: middle;
  font-family: "Sarabun";
}
.textDarkgrey-Border {
  border-color: #28363e;
}

.align-Icon-Heading {
  font-size: 12px;
  font-weight: 400;
  color: #677f8e;
  font-family: "Roboto Flex";
}

.Coin-Symbol {
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 14px;
}
.question-circle {
  position: relative;
  margin-top: -3px;
  left: 8px;
  cursor: pointer;
}
.border-left-table {
  border-left: 1px solid #28363e;
}
.border-right-table {
  border-right: 1px solid #28363e;
}
.borderTopBottom {
  border-bottom: 1px solid #28363e;
  border-top: 1px solid #28363e;
}
.borderBottom {
  border-bottom: 1px solid #28363e;
}
.BuySellHeading-table {
  color: #ffff;
  font-size: 12px;
  // font-family: "Roboto Flex";
  font-weight: 400;
  text-transform: uppercase;
  line-height: 14px;
}
.HeadingBuyGroup {
  text-align: center;
  border: none;
  border-right: 1px solid #28363e;
  border-left: 1px solid #28363e;
  font-family: "Roboto Flex";
}
.HeadingSellGroup {
  text-align: center;
  border: none;
  border-right: 1px solid #28363e;
  // border-left: 1px solid #28363E;
}

// %%%  scroller  start %%%%

.scroller {
  min-height: 200px;
}
.hmt {
  position: relative;
  top: -10px;
}
/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #192126;
}
</style>
