<template>
  <b-modal id="withdraw-coin-tutorial" :hide-footer="true" :hide-header="true">
    <div class="row">
      <div class="col-12 modal-th">
        <h5>
          ถอนเหรียญ
          <!-- <span style="right: 130px"
            ><svg
              width="21"
              height="21"
              viewBox="0 0 21 21"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M9.5 16.5H11.5V14.5H9.5V16.5ZM10.5 0.5C4.98 0.5 0.5 4.98 0.5 10.5C0.5 16.02 4.98 20.5 10.5 20.5C16.02 20.5 20.5 16.02 20.5 10.5C20.5 4.98 16.02 0.5 10.5 0.5ZM10.5 18.5C6.09 18.5 2.5 14.91 2.5 10.5C2.5 6.09 6.09 2.5 10.5 2.5C14.91 2.5 18.5 6.09 18.5 10.5C18.5 14.91 14.91 18.5 10.5 18.5ZM10.5 4.5C8.29 4.5 6.5 6.29 6.5 8.5H8.5C8.5 7.4 9.4 6.5 10.5 6.5C11.6 6.5 12.5 7.4 12.5 8.5C12.5 10.5 9.5 10.25 9.5 13.5H11.5C11.5 11.25 14.5 11 14.5 8.5C14.5 6.29 12.71 4.5 10.5 4.5Z"
                fill="#677F8E" />
            </svg>
          </span> -->
        </h5>
        <span
          class="modal-close-button"
          @click="$bvModal.hide('withdraw-coin-tutorial')"
        >
          <svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E" />
          </svg>
        </span>
      </div>
    </div>

    <div class="row body thai-font">
      <div class="col-md-12">
        <p style="color: #9bacb6; margin-bottom: 10px">
          ถอนเหรียญของคุณออกจาก BITBULL THAILAND ได้ง่ายๆ
        </p>
        <a
          href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Documents/2024/Apr/%e0%b8%96%e0%b8%ad%e0%b8%99%e0%b9%80%e0%b8%ab%e0%b8%a3%e0%b8%b5%e0%b8%a2%e0%b8%8d_Manual_FDA_PC.pdf" 
          target="_blank"
          class="d-inline"
          style="width: 107px; height: 24px; margin-right: 16px; color: #09BE8B"
        >
          อ่านคู่มือการใช้งาน
      </a>
        <p class="d-inline" style="width: 24; height: 24px; margin-right: 16px">
          หรือ
        </p>
        <a
          href="https://fda-web-media-prd.s3.ap-southeast-1.amazonaws.com/fda/Video/2024/Apr/VDO_%e0%b8%96%e0%b8%ad%e0%b8%99%e0%b9%80%e0%b8%ab%e0%b8%a3%e0%b8%b5%e0%b8%a2%e0%b8%8d_PC.mp4" 
          target="_blank"
          class="d-inline"
          style="width: 99px; height: 24px; margin-right: 16px; color: #09BE8B"
        >
          ดูวิดีโอสอนใช้งาน
        </a>

        <div class="form-group form-check mt-3">
          <label class="checkbox-input">
            <input
              type="checkbox"
              class="form-check-input"
              v-model="dontShow"
            />
            <span class="lh-24">ไม่ต้องแจ้งเตือนอีก</span>
          </label>
        </div>
        <button @click="closeModal()" type="button" class="tut-btn text-center">
          เริ่มต้นถอนเหรียญ
        </button>
      </div>
    </div>
  </b-modal>
</template>
<script>
export default {
  data() {
    return {
      dontShow: false,
    };
  },
  methods: {
    closeModal() {
      if (this.dontShow) {
        localStorage.setItem("WithdrawCoinAlert", this.dontShow);
      }
      this.$bvModal.hide("withdraw-coin-tutorial");
    },
  },
};
</script>
<style lang="scss">
#withdraw-coin-tutorial___BV_modal_content_ {
  width: 400px;
  background-color: #141b1f;
  margin-top: 195px !important;
  #withdraw-coin-tutorial___BV_modal_body_ {
    .modal-close-button {
      cursor: pointer;
    }
    h5 {
      font-family: "Noto Sans Thai";
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      color: #d6dde1;
      line-height: 27px;
      display: inline-block;
    }
    .modal-th {
      padding: 24px 0;
    }
    p {
      color: #677f8e;
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
    }

    span {
      color: #d6dde1;
    }
    label {
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 400;
      font-size: 14px;
      color: #d6dde1;
    }

    .checkbox-input {
      input {
        background-color: #222b2f;
        border: 1px solid #3c515d;
        border-radius: 2px;
        height: 17px;
        width: 17px;
      }
      input:checked {
        background-color: #09BE8B;
        border: 1px solid #3c515d;
      }
      span {
        display: inline-block;
        margin-left: 8px;
      }
    }
    .tut-btn {
      width: 100%;
      height: 40px;
      border-radius: 4px;
      background: #09BE8B;
      color: #0a0d10;
      font-family: "Sarabun";
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      margin-top: 10px;
      margin-bottom: 10px;
      &:hover {
        background-color: #66cdb0;
      }
    }
  }
}
</style>
