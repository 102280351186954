<template>
  <div class="wraper-main m-gainloss">
    <div class="Tab-wrapper px-3">
      <ul class="nav nav-pills lh-24">
        <li>
          <a
            class="tabInactive border-raduis-left"
            :class="activeTab == 1 ? 'is-active' : ''"
            @click="onChangeActiveTap(1)"
            style="width: 82px"
          >
            All
          </a>
        </li>
        <li>
          <a
            class="tabInactive"
            :class="activeTab == 2 ? 'is-active' : ''"
            @click="onChangeActiveTap(2)"
            style="width: 82px"
          >
            Top Gain
          </a>
        </li>
        <li>
          <a
            class="tabInactive border-raduis-right"
            :class="activeTab == 3 ? 'is-active' : ''"
            @click="onChangeActiveTap(3)"
            style="width: 83px"
          >
            Top Loss
          </a>
        </li>
      </ul>
    </div>
    <div class="mr-0">
      <div class="">
        <div v-if="isLoading == true" class="loader-wrapper">
          <div class="loader"></div>
        </div>
        <div v-else>
          <div v-if="activeTab == 1 && isLoading == false" class="row">
            <div
              v-if="
                this.series[0].data.length != 0 &&
                this.series_2[0].data.length != 0
              "
              class="d-flex justify-content-center"
            >
              <div name="start" class="col-0"></div>
              <div
                name="chart1"
                :class="class_chart_1"
                style="padding-right: 0px; padding-left: 0px"
              >
                <apexchart
                  ref="chart"
                  type="bar"
                  height="200"
                  :options="chartOptionsdata_1"
                  :series="series"
                ></apexchart>
              </div>
              <div
                name="chart2"
                :class="class_chart_2"
                style="padding-right: 0px; padding-left: 0px"
              >
                <div style="height: 121px"></div>
                <apexchart
                  ref="chart"
                  type="bar"
                  height="200"
                  :options="chartOptionsdata_2"
                  :series="series_2"
                ></apexchart>
              </div>
              <div name="end" class="col-0"></div>
            </div>

            <div v-else>
              <div v-if="this.series[0].data.length != 0" class="col-12">
                <div id="chart3" :class="class_chart_1">
                  <apexchart
                    type="bar"
                    height="200"
                    :options="chartOptionsdata_1"
                    :series="series"
                  ></apexchart>
                </div>
              </div>
              <div v-if="this.series_2[0].data.length != 0" class="col-12">
                <div id="chart4" :class="class_chart_2">
                  <apexchart
                    type="bar"
                    height="200"
                    :options="chartOptionsdata_2"
                    :series="series_2"
                  ></apexchart>
                </div>
              </div>
            </div>
          </div>
          <div v-if="activeTab == 2 && isLoading == false" class="row pad">
            <div v-if="this.series[0].data.length != 0" class="col-12">
              <div id="chart3" :class="class_chart_1">
                <apexchart
                  type="bar"
                  height="200"
                  :options="chartOptionsdata_1"
                  :series="series"
                ></apexchart>
              </div>
            </div>
          </div>
          <div v-if="activeTab == 3 && isLoading == false" class="row pad">
            <div v-if="this.series_2[0].data.length != 0" class="col-12">
              <div id="chart4" :class="class_chart_2">
                <apexchart
                  type="bar"
                  height="200"
                  :options="chartOptionsdata_2"
                  :series="series_2"
                ></apexchart>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div>
      <Dynamic-Table
        v-if="TableDatas.length > 0"
        :TableHeader="TableHeading"
        :TableData="TableDatas"
        :favCoinRows="favCoinRows"
        :ShowGainLoss="true"
      ></Dynamic-Table>
      <p v-else class="text-center">--ไม่มีข้อมูล--</p>
    </div>
  </div>
</template>

<script>
import { HTTP } from "@/config/axios";
import Table from "../../components/table/Table.vue";
import VueApexCharts from "vue-apexcharts";

export default {
  name: "Gainloss",
  components: {
    apexchart: VueApexCharts,
    "Dynamic-Table": Table,
  },
  data() {
    return {
      class_chart_1: "mx-auto",
      class_chart_2: "mx-auto",
      isLoading: false,
      ac: false,
      data_chart_option: "",
      activeTab: 1,
      TableHeading: [
        {
          headingCoin: "Coin ",
          headingLastPrice: "Last Price (THB)",
          headingHourChange: "Change (24h)",
          headingVolume: "Volume (THB 24h)",
          headingMarketCap: "Market Cap (THB)",
          headingChart: "Chart (24h)",
        },
      ],
      TableDatas: [],
      favCoinRows: [],
      data_1: [],
      data_2: [],
      series: [
        {
          name: "Cash Flow",
          data: [],
          label: {
            show: true,
            position: "top",
            formatter: function (data) {
              return "Bar #" + (data.dataIndex + 1);
            },
          },
          type: "bar",
        },
      ],
      chartOptions: {
        legend: { show: false },
        tooltip: { enabled: false },
        xaxis: {
          categories: [],
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "#D6DDE1" },
          },
        },
        yaxis: {
          show: true,
        },
        chart: {
          type: "bar",
          height: 360,
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            columnWidth: "55",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
              { from: 0, to: 100, color: "rgb(0, 255, 0)" },
                { from: -100, to: 0, color: "rgb(255, 0, 0)" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -50,
          formatter: function (val) {
            return val + " %";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["#D6DDE1"] },
        },
        grid: { show: false },
      },
      series_3: [
        {
          name: "Cash Flow",
          data: [],
          label: {
            show: true,
            position: "top",
            formatter: function (data) {
              return "Bar #" + (data.dataIndex + 1);
            },
          },
          type: "bar",
        },
      ],
      series_2: [
        {
          name: "Cash Flow",
          data: [],
          label: {
            show: true,
            position: "top",
            formatter: function (data) {
              return "Bar #" + (data.dataIndex + 1);
            },
          },
          type: "bar",
        },
      ],
      decimal: 2,
      chartOptions_2: {
        legend: { show: false },
        tooltip: { enabled: false },
        xaxis: {
          position: "top",
          categories: [],
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "#D6DDE1" },
          },
        },
        yaxis: {
          suggestedMin: 0,
          beginAtZero: true,
          show: true,
        },
        chart: {
          type: "bar",
          height: 360,
          // width: "50%",
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "55",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
                { from: 0, to: 100, color: "rgb(0, 255, 0)" },
                { from: -100, to: 0, color: "rgb(255, 0, 0)" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: 0,
          formatter: function (val) {
            return val + " %";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["#D6DDE1"] },
        },
        grid: { show: false },
      },
    };
  },
  methods: {
    async getCoinInfo() {
      let self = this;
      await HTTP.post("/market/symbol-info-all")
        .then((res) => {
          self.coinMarketScale = res.data;
        })
        .catch((err) => {
          console.log(err);
        });
      return true;
    },
    async getGainLoss(val) {
      this.isLoading = true;
      await HTTP.get("/market/symbol-thumb")
        .then((res) => {
          const data = res.data;
          let categories = [];
          let chg = [];
          let tableDatas = [];
          this.chartOptions.xaxis.categories = [];
          this.chartOptions_2.xaxis.categories = [];
          this.series_2[0].data = [];
          this.series[0].data = [];
          data.forEach((element) => {
            this.coinMarketScale.forEach((item, index) => {
              if (element.symbol.includes(item.coinSymbol) == true) {
                this.decimal = item.baseCoinScale;
              }
            });

            tableDatas.push({
              StarIcon: "",
              bathScale: this.decimal,
              CoinImage:
                this.MARKET_WS_URL + "/market/coin/image/" + element.logoFileId,
              Coinsybmol: element.symbol,
              CoinName: element.fullName,
              lastPrice: element.close,
              hourChange: element.chg,
              volume: element.volume,
              marketCap: element.marketCap,
              chg: element.chg,
            });
          });

          if (val == 2) {
            const gainItems = tableDatas
              .sort((a, b) => b.chg - a.chg)
              .slice(0, 20);
            gainItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];
              if (x.chg > 0) {
                categories.push(coinName);
                chg.push((x.chg * 100).toFixed(2));
              }
              /*  
              else{
                categories.push("");
                chg.push(0);
              } */
            });
            let d_green = [];
            if (gainItems.length > 0) {
              for (let i = 0; i < gainItems.length; i++) {
                if (gainItems[i].chg > 0) {
                  d_green.push(gainItems[i]);
                }
              }
            }
            let class1 = 0.6 * chg.length;
            var r = Number(class1.toFixed(0)) + 3;
            this.class_chart_1 = `col-${r} mx-auto`;
            this.chartOptions.xaxis.categories = categories;
            this.series[0].data = chg;
            this.TableDatas = d_green;
          } else if (val == 3) {
            const lossItems = tableDatas
              .sort((a, b) => a.chg - b.chg)
              .slice(0, 20)
              .reverse();
            let categories_2 = [];
            let chg_2 = [];
            lossItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];

              // this.chartOptions_2 = {
              //   xaxis: {
              //     ...this.chartOptions_2.xaxis,
              //     categories: [...categories_2],
              //   },
              // };
              if (x.chg < 0) {
                chg_2.push((x.chg * 100).toFixed(2));
                categories_2.push(coinName);
              } /*  else {
                chg_2.push(0);
                categories_2.push("");
              } */
              this.series_2 = [{ data: [...chg_2] }];
            });
            this.chartOptions_2.xaxis.categories = categories_2;
            let d_red = [];
            if (lossItems.length > 0) {
              for (let i = 0; i < lossItems.length; i++) {
                if (lossItems[i].chg < 0) {
                  d_red.push(lossItems[i]);
                }
              }
            }
            let class1 = 0.6 * chg_2.length;
            var r = Number(class1.toFixed(0)) + 3;
            this.class_chart_2 = `col-${r} mx-auto`;
            this.TableDatas = d_red;
          }
        })
        .catch((err) => {
          console.log(err);
        });
      this.isLoading = false;
    },
    async getGainLoss_2(val) {
      this.isLoading = true;
      var coininfo = await this.getCoinInfo();
      if (coininfo == false) {
        return false;
      }
      this.isLoading = false;
      await HTTP.get("/market/symbol-thumb")
        .then((res) => {
          const data = res.data;
          let categories = [];
          let chg = [];
          let tableDatas = [];
          this.chartOptions.xaxis.categories = [];
          this.chartOptions_2.xaxis.categories = [];
          this.series_2[0].data = [];
          this.series[0].data = [];
          let chg_2 = [];
          this.series_3[0].data = [];

          data.forEach((element) => {
            this.coinMarketScale.forEach((item, index) => {
              if (element.symbol.includes(item.coinSymbol) == true) {
                this.decimal = item.baseCoinScale;
              }
            });
            tableDatas.push({
              bathScale: this.decimal,
              StarIcon: "",
              CoinImage:
                this.MARKET_WS_URL + "/market/coin/image/" + element.logoFileId,
              Coinsybmol: element.symbol,
              CoinName: element.fullName,
              lastPrice: element.close,
              hourChange: element.chg,
              volume: element.volume,
              marketCap: element.marketCap,
              chg: element.chg,
            });
          });
          let categories_2 = [];
          if (val == 0) {
            const gainItems = tableDatas
              .sort((a, b) => b.chg - a.chg)
              .slice(0, 10);
            const lossItems = tableDatas
              .sort((a, b) => a.chg - b.chg)
              .slice(0, 10)
              .reverse();
            this.data_1 = gainItems;
            this.data_2 = lossItems;
            this.TableDatas = tableDatas.sort((a, b) => b.chg - a.chg);
            gainItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];
              if (x.chg > 0) {
                categories.push(coinName);
                chg.push((x.chg * 100).toFixed(2));
              } /*  else{
                categories.push("");
                chg.push(0);
              } */
            });
            lossItems.forEach((x) => {
              let coinName = x.Coinsybmol.split("/")[0];
              if (x.chg < 0) {
                chg_2.push((x.chg * 100).toFixed(2));
                categories_2.push(coinName);
              } /*  else {
                chg_2.push(0);
                categories_2.push("");
              } */
            });

            this.series[0].data = chg;
            this.chartOptions.xaxis.categories = categories;
            this.series_3[0].data = chg;

            this.chartOptions_2.xaxis.categories = categories_2;
            this.series_2[0].data = chg_2;

            const chartElement = document.getElementsByName("chart1");
            const chartElement2 = document.getElementsByName("chart2");
            const chartElement_start = document.getElementsByName("start");
            const chartElement2_end = document.getElementsByName("end");
            if (chartElement != null) {
              // chartElement.classList.remove(...chartElement.classList);
              // chartElement2.classList.remove(...chartElement2.classList);
              // chartElement_start.classList.remove(
              //   ...chartElement_start.classList
              // );
              // chartElement2_end.classList.remove(
              //   ...chartElement2_end.classList
              // );
            }

            if (chg.length == 0) {
              let class1 = 0.6 * chg_2.length;
              var r = Number(class1.toFixed(0)) + 3;
              if (chartElement != null) {
                // chartElement.setAttribute("hidden", "");
                // chartElement_start.setAttribute("hidden", "");
                // chartElement2_end.setAttribute("hidden", "");
              }

              // chartElement2.classList.add(`col-${r} mx-auto`);
              this.class_chart_2 = `col-${r} mx-auto`;
            }
            if (chg_2.length == 0) {
              let class1 = 0.6 * chg.length;
              var r = Number(class1.toFixed(0)) + 3;
              if (chartElement != null) {
                // chartElement2.setAttribute("hidden", "");
                // chartElement_start.setAttribute("hidden", "");
                // chartElement2_end.setAttribute("hidden", "");
              }

              // chartElement.classList.add(`col-${r}`);
              this.class_chart = `col-${r} mx-auto`;
            }
            if (chg.length != 0 && chg_2.length != 0) {
              // Calculate total length (considering both chg and chg_2)
              const totalLength = Math.max(chg.length, chg_2.length);
              // Assign equal classes if lengths are equal
              if (chg.length === chg_2.length) {
                if (chartElement != null) {
                  chartElement.classList.add(`col-6`);
                  chartElement2.classList.add(`col-6`);
                }
              } else {
                // Calculate class sizes based on length ratio
                const largerProportion =
                  Math.max(chg.length, chg_2.length) / totalLength;
                const smallerProportion =
                  Math.min(chg.length, chg_2.length) / totalLength;

                let col_c1 = chg.length * 1.67;
                let col_c2 = chg_2.length * 1.67;
                // var w_a = 0
                // var w_b = 0
                // var count = (12 / (Number(col_c1) + Number(col_c2)))
                // w_a = Number(count * col_c1)
                // w_b = Number(count * col_c2)
                // var w_a1_f = Number(w_a.toFixed(0))
                // var w_b1_f = Number(w_b.toFixed(0))
                // var sum = w_a1_f + w_b1_f
                // if (sum > 12) {
                //   if (w_a.toFixed(0) > w_b.toFixed(0)) {
                //     w_a1_f--
                //   } else {
                //     w_b1_f--
                //   }
                // }
                var cola = parseInt(col_c1.toFixed(0));
                var colb = parseInt(col_c2.toFixed(0));
                var space = 12 - (cola + colb);
                var haft = space / 2;
                var colstart = parseInt(haft.toFixed(0));
                var colend = parseInt(haft.toFixed(0));
                var sum = colstart + cola + colb + colend;
                if (sum > 12) {
                  colstart--;
                }
                // Assign classes based on proportion
                let class1 = 0.6 * chg.length;
                let class2 = 0.6 * chg_2.length;
                var r = Number(class1.toFixed(0)) ;
                var b = Number(class2.toFixed(0)) ;
                if(r > b){
                  r+=2
                } else{
                  b+=2
                }
                this.class_chart_1 = `col-${r} `;
                this.class_chart_2 = `col-${b} `;
                // chartElement_start.classList.add(`col-${colstart}`);
                // chartElement.classList.add(`col-${cola}`);
                // chartElement2.classList.add(`col-${colb}`);
                // chartElement2_end.classList.add(`col-${colend}`);
              }
            }
          }

          this.isLoading = false;
          // this.$refs.chart.updateOptions(this.chartOptions);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    check_chart_2() {
      HTTP.get("/market/symbol-thumb")
        .then((res) => {
          const data = res.data;
          let tableDatas = [];
          data.forEach((element) => {
            tableDatas.push({
              StarIcon: "",
              CoinImage:
                this.MARKET_WS_URL + "/market/coin/image/" + element.logoFileId,
              Coinsybmol: element.symbol,
              CoinName: element.fullName,
              lastPrice: element.close,
              hourChange: element.chg,
              volume: element.volume,
              marketCap: element.marketCap,
              chg: element.chg,
            });
          });
          const lossItems = tableDatas
            .sort((a, b) => a.chg - b.chg)
            .slice(0, 10)
            .reverse();
          this.data_2 = lossItems;
          let categories = [];
          let chg = [];
          lossItems.forEach((x) => {
            let coinName = x.Coinsybmol.split("/")[0];
            categories.push(coinName);
            if (x.chg > 0) {
              chg.push(0);
            } else {
              chg.push(x.chg);
            }
          });
          this.chartOptions_2.xaxis.categories = categories;
          this.series_2[0].data = chg;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    check_d() {
      var data_1 = {
        categories: this.chartOptions_2.xaxis.categories,
        chg: this.series_2[0].data,
      };
      return data_1;
    },
    check_d_1() {
      var data = {
        categories: this.chartOptions.xaxis.categories,
        chg: this.series[0].data,
      };
      return data;
    },

    onChangeActiveTap(val) {
      if (val == 1) {
        this.isLoading = true;
        this.chartOptions.xaxis.categories = [];
        this.chartOptions_2.xaxis.categories = [];
        this.series_2[0].data = [];
        this.series[0].data = [];
        this.ac = true;
        this.getGainLoss_2(0);
        this.activeTab = val;
      } else if (val == 2) {
        this.ac = false;
        this.activeTab = val;
        this.getGainLoss(2);
      } else {
        this.ac = false;
        this.activeTab = val;
        this.getGainLoss(3);
      }
    },
  },
  mounted() {},
  created() {
    this.getGainLoss_2(0);
  },
  computed: {
    chartOptionsdata_1() {
      this.isLoading = true;
      var chartOptions = {
        legend: { show: false },
        tooltip: {
          fillSeriesColor: true,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const xValue = w.globals.labels[dataPointIndex]; // Access X-axis label
            const yValue = series[seriesIndex][dataPointIndex]; // Access Y-axis value

            return (
              `<div class="arrow_box">` +
              `<span class="box" style="color: #398648;"> ${xValue} : +${yValue} %</span>` +
              `<span class="tooltip">This is the tooltip content.</span>` +
              "</div>"
            );
          },
          enabled: false,
          hideEmptySeries: false,
          inverseOrder: false,
          // marker: {
          //   show: true,
          // },
        },
        xaxis: {
          ticks: {
            beginAtZero: true,
          },
          gridLines: {
            display: false,
          },
          position: "bottom",
          categories: [],
          opposite: false,
          axisTicks: {
            show: false,
          },

          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "#D6DDE1" },
          },
        },
        yaxis: {
          show: false,
        },
        chart: {
          type: "bar",
          height: 200,
          width: "100%",
          // offsetX: 22,
          offsetX: 0,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "55",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
              { from: 0, to: 100, color: "rgb(0, 255, 0)" },
                { from: -100, to: 0, color: "rgb(255, 0, 0)" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          position: "top",
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["#D6DDE1"] },
        },
        grid: {
          show: true,
          borderColor: "#90A4AE",
          strokeDashArray: 0,
          position: "back",
          xaxis: {
            lines: {
              show: false,
            },
          },
          yaxis: {
            lines: {
              show: false,
            },
          },
          row: {
            colors: undefined,
            opacity: 0.5,
          },
          column: {
            colors: undefined,
            opacity: 0.5,
          },
          padding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      };
      var r1 = [];
      r1 = this.check_d_1();
      if (r1.categories.length != 0) {
        chartOptions.xaxis.categories = r1.categories;
      }
      if (r1.chg.length != 0) {
        this.series[0].data = r1.chg;
      }
      this.isLoading = false;
      return chartOptions;
    },
    chartOptionsdata_2() {
      this.isLoading = true;
      var r = [];
      var dataoption_2 = {
        legend: { show: false },
        tooltip: {
          fillSeriesColor: false,
          custom: function ({ series, seriesIndex, dataPointIndex, w }) {
            const xValue = w.globals.labels[dataPointIndex]; // Access X-axis label
            const yValue = series[seriesIndex][dataPointIndex]; // Access Y-axis value
            return (
              `<div class="arrow_box">` +
              `<span class="box" style="color: #f72000;"> ${xValue} : ${yValue} %</span>` +
              `<span class="tooltip"></span>` + // Empty tooltip content
              "</div>"
            );
          },
          enabled: false,
          hideEmptySeries: true,
          inverseOrder: true,
          // marker: {
          //   show: true,
          // },
        },
        xaxis: {
          gridLines: {
            display: false,
          },
          position: "top",
          categories: r,
          opposite: true,
          axisTicks: { show: false },
          axisBorder: { show: false },
          labels: {
            show: true,
            offsetY: 0,
            style: { colors: "#D6DDE1" },
          },
        },
        yaxis: {
          ticks: {
            beginAtZero: true,
          },
          suggestedMin: 0,
          show: false,
        },
        chart: {
          type: "bar",
          height: 200,
          width: "102%",
          offsetX: -17,
          toolbar: { show: false },
        },
        plotOptions: {
          bar: {
            borderRadius: 3,
            columnWidth: "55",
            dataLabels: { position: "top" },
            colors: {
              ranges: [
              { from: 0, to: 100, color: "rgb(0, 255, 0)" },
                { from: -100, to: 0, color: "rgb(255, 0, 0)" },
              ],
            },
          },
        },
        dataLabels: {
          enabled: true,
          offsetY: -20,
          position: "top",
          formatter: function (val) {
            return val + "%";
          },
          style: { fontSize: "14px", fontWeight: "400", colors: ["#D6DDE1"] },
        },
        grid: { show: false },
      };
      dataoption_2.xaxis.categories = [];
      r = this.check_d();
      // let a = r.categories.length  +"0"
      // dataoption_2.chart.width = Number(a) +'%'
      if (r.categories.length != 0) {
        dataoption_2.xaxis.categories = r.categories;
      }
      if (r.chg.length != 0) {
        this.series_2[0].data = r.chg;
      }
      // type: 'bar'
      this.isLoading = false;
      return dataoption_2;
    },
  },
};
</script>

<style lang="scss" scoped>
.Tab-wrapper {
  margin-top: 15px;

  ul {
    li {
      a {
        display: block;
        text-align: center;
      }
    }
  }
}

.border-raduis-left {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.border-raduis-right {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.is-active {
  color: #ffffff !important;
  background-color: #191b1c !important;
  text-decoration: none;
  // padding: 10px;
}

.tabInactive {
  background-color: #000000;
  text-decoration: none;
  height: 40px;
  margin: 1px;
  display: flex !important;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.pad {
  margin-left: 25px;
  margin-right: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

.tabInactive:hover {
  background-color: #38454d;
}

a {
  color: #9bacb6 !important;
  font-family: "Roboto Flex";
  font-style: normal;
  font-weight: 400;
}

.loader-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(34, 43, 47, 0.7);
  /* สีดำที่โปร่งแสง 70% */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
}

/* สร้าง loader ที่หมุน */
.loader {
  border: 4px solid rgba(255, 255, 255, 0.4);
  border-top: 4px solid #fff;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
