<template>
  <div class="wrapper">
    <div class="container p-0">
      <div class="row m-0">
        <div class="col p-0">
          <div class="Heading">
            <h1 class="page-heading">การรับข้อมูลข่าวสาร</h1>
          </div>
        </div>
      </div>

      <div style="margin: 0px 0px 12px 52px">
        <p class="text-dark-grey mb-0">เลือกประเภทข่าวสารที่ต้องการ</p>
      </div>

      <div v-if="configResponse == null">
        <!-- Skeleton loading for checkboxes -->
        <div style="margin-left: 52px">
          <div class="form-check m-0">
            <div class="skeleton-checkbox" style="height: 16px; width: 100px">
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="100%"
              >
              </b-skeleton>
            </div>
          </div>
          <div class="form-check" style="margin-top: 14px">
            <div class="skeleton-checkbox" style="height: 16px; width: 100px">
              <b-skeleton
                class="skc custom-skeleton-wave"
                animation="wave"
                width="100%"
              >
              </b-skeleton>
            </div>
          </div>
        </div>

        <!-- Skeleton loading for text -->
        <div style="margin-top: 24px; margin-left: 52px">
          <div class="skeleton-text" style="height: 16px; width: 400px">
            <b-skeleton
              class="skc custom-skeleton-wave"
              animation="wave"
              width="100%"
            >
            </b-skeleton>
          </div>
        </div>

        <!-- Skeleton loading for buttons -->
        <div
          class="row justify-content-start"
          style="margin: 32px 0px 0px 120px"
        >
          <div class="col-4 p-0">
            <div class="button text-center d-flex">
              <div class="skeleton-button" style="height: 36px; width: 100px">
                <b-skeleton
                  class="skc custom-skeleton-wave"
                  animation="wave"
                  width="100%"
                >
                </b-skeleton>
              </div>
              <div class="skeleton-button" style="height: 36px; width: 100px">
                <b-skeleton
                  class="skc custom-skeleton-wave"
                  animation="wave"
                  width="100%"
                >
                </b-skeleton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div style="margin-left: 52px">
          <div class="form-check m-0">
            <input
              class="form-check-input"
              type="checkbox"
              v-model="articleChecked"
              value=""
              id="flexCheckDefault"
            />
            <label class="form-check-label m-0" for="flexCheckDefault">
              บทความ
            </label>
          </div>
          <div class="form-check" style="margin-top: 14px">
            <input
              class="form-check-input"
              type="checkbox"
              value=""
              v-model="promotionChecked"
              id="flexCheckChecked"
              checked
            />
            <label class="form-check-label m-0" for="flexCheckChecked">
              โปรโมชั่น
            </label>
          </div>
        </div>

        <div style="margin-top: 24px; margin-left: 52px">
          <p class="text-dark-grey mb-0">
            ทางบริษัทจะส่งข่าวสารที่ท่านเลือกผ่าน {{ user.email }} ภายในวันถัดไป
          </p>
        </div>
        <!-- buttons -->
        <div
          class="row justify-content-start"
          style="margin: 32px 0px 0px 120px"
        >
          <div class="col-4 p-0">
            <div class="button text-center d-flex">
              <button
                type="button"
                style="margin-right: 16px; height: 36px"
                class="btn btn-primary btn-text-sarabun buttonWidth"
                @click="UpdateConfig()"
              >
                บันทึก
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- common success modal -->
    <div v-if="ModalSuccess">
      <transition name="modal">
        <div class="modal-mask">
          <div class="modal-wrapper">
            <div class="modal-dialog otp-success" role="document">
              <div class="modal-content" style="border-radius: 8px">
                <div class="modal-body scroller-otp p-0">
                  <div class="container p-0">
                    <div class="row m-0">
                      <div class="col text-center p-0">
                        <div class="checkTick" style="padding-top: 55px">
                          <span>
                            <svg
                              width="76"
                              height="78"
                              viewBox="0 0 76 78"
                              fill="none"
                              xmlns="http://www.w3.org/2000/svg"
                            >
                              <g filter="url(#filter0_di_1067_134123)">
                                <circle
                                  cx="38"
                                  cy="36"
                                  r="33.75"
                                  fill="url(#paint0_linear_1067_134123)"
                                />
                              </g>
                              <g filter="url(#filter1_d_1067_134123)">
                                <path
                                  d="M20.8709 41.3572C19.366 39.8511 19.366 37.4068 20.8709 35.9007C22.3743 34.3932 24.8142 34.3932 26.3176 35.9007L32.8108 42.4054L49.4137 21.4154C50.7271 19.7502 53.1388 19.4677 54.8012 20.782C56.4634 22.0978 56.7454 24.5138 55.4319 26.1791L36.3636 50.2886C36.2197 50.5102 36.052 50.7198 35.8561 50.9146C34.3527 52.4221 31.9127 52.4221 30.4093 50.9146L20.871 41.3593L20.8709 41.3572Z"
                                  fill="url(#paint1_linear_1067_134123)"
                                />
                              </g>
                              <defs>
                                <filter
                                  id="filter0_di_1067_134123"
                                  x="0.25"
                                  y="0.25"
                                  width="75.5"
                                  height="77.5"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset dy="4" />
                                  <feGaussianBlur stdDeviation="2" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_1067_134123"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_1067_134123"
                                    result="shape"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset dy="-2" />
                                  <feGaussianBlur stdDeviation="2" />
                                  <feComposite
                                    in2="hardAlpha"
                                    operator="arithmetic"
                                    k2="-1"
                                    k3="1"
                                  />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.486369 0 0 0 0 0.789038 0 0 0 0 0.588355 0 0 0 0.4 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="shape"
                                    result="effect2_innerShadow_1067_134123"
                                  />
                                </filter>
                                <filter
                                  id="filter1_d_1067_134123"
                                  x="15.7422"
                                  y="19.9551"
                                  width="44.5156"
                                  height="40.0898"
                                  filterUnits="userSpaceOnUse"
                                  color-interpolation-filters="sRGB"
                                >
                                  <feFlood
                                    flood-opacity="0"
                                    result="BackgroundImageFix"
                                  />
                                  <feColorMatrix
                                    in="SourceAlpha"
                                    type="matrix"
                                    values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                                    result="hardAlpha"
                                  />
                                  <feOffset dy="4" />
                                  <feGaussianBlur stdDeviation="2" />
                                  <feComposite in2="hardAlpha" operator="out" />
                                  <feColorMatrix
                                    type="matrix"
                                    values="0 0 0 0 0.168627 0 0 0 0 0.505882 0 0 0 0 0.278431 0 0 0 1 0"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in2="BackgroundImageFix"
                                    result="effect1_dropShadow_1067_134123"
                                  />
                                  <feBlend
                                    mode="normal"
                                    in="SourceGraphic"
                                    in2="effect1_dropShadow_1067_134123"
                                    result="shape"
                                  />
                                </filter>
                                <linearGradient
                                  id="paint0_linear_1067_134123"
                                  x1="37.4467"
                                  y1="69.75"
                                  x2="37.4467"
                                  y2="2.25"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="#277C44" />
                                  <stop offset="1" stop-color="#44A765" />
                                </linearGradient>
                                <linearGradient
                                  id="paint1_linear_1067_134123"
                                  x1="39.1069"
                                  y1="32.6805"
                                  x2="37.4471"
                                  y2="52.0452"
                                  gradientUnits="userSpaceOnUse"
                                >
                                  <stop stop-color="white" />
                                  <stop offset="1" stop-color="#BDE6CA" />
                                </linearGradient>
                              </defs>
                            </svg>
                          </span>
                        </div>
                        <div
                          class="success-message"
                          style="padding-bottom: 22px"
                        >
                          <p
                            style="
                              font-family: 'Noto Sans Thai';
                              font-style: normal;
                              font-weight: 600;
                              font-size: 24px;
                              line-height: 36px;
                              color: rgb(255, 255, 255);
                            "
                          >
                            ระบบได้รับข้อมูลของคุณแล้ว
                          </p>
                        </div>
                        <div @click="ModalSuccess = false">
                          <button
                            type="button"
                            class="btn-text-sarabun btn-cancel buttonSuccess"
                            style="
                              height: 44px;
                              width: 163px;
                              color: white;
                              border-radius: 4px;
                              margin-bottom: 32px;
                              font-weight: 500 !important;
                            "
                          >
                            เสร็จสิ้น
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
    <!-- common success modal end -->
  </div>
</template>

<script>
import { HTTP } from "../../config/axios";
import memberConfigMixin from "./MemberConfig.js";
import EventBus from "../../event-bus";
export default {
  mixins: [memberConfigMixin],
  name: "ReceivingInformation",
  data: function () {
    return {
      ModalSuccess: false,
      user: {
        email: "",
      },
    };
  },
  methods: {
    UpdateConfig() {
      this.updateMemberConfigs(
        "NEWS_TYPE_ARTICLE",
        this.articleChecked == true ? 1 : 0
      );
      this.updateMemberConfigs(
        "NEWS_TYPE_PROMOTION",
        this.promotionChecked == true ? 1 : 0
      );
    },
  },
  created() {
    this.getMemberConfigs();
    const resMember = JSON.parse(localStorage.getItem("resMember")) || "{}";
    this.user.email = resMember?.resMember.email || "";
  },
};
</script>
<style scoped>
.w-400 {
  font-weight: 400 !important;
}
.buttonWidth {
  width: 140px !important;
}
.form-check-input:checked {
  background-color: #09BE8B !important;
  border-color: #09BE8B !important;
}
.form-check-input {
  background: #191b1c;
  border-color: #3c515d;
  width: 16px;
  height: 16px;
  border-radius: 2px;
}
label {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: rgb(255, 255, 255);
}
.bg-box {
  background-color: #191b1c;
}
.page-heading {
  font-weight: 600 !important;
  font-size: 24px !important;
  line-height: 36px !important;
  font-family: "Noto Sans Thai" !important;
  color: rgb(255, 255, 255);
  margin: 0px;
}
.Heading {
  padding: 24px 0px 16px 52px;
}

.btn-text-sarabun {
  font-family: "Sarabun";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 24px;
}
.width-table {
  /* width: 764px; */
}

.table-text {
  color: #9bacb6;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}
.table-text-white {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.table-text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
  cursor: pointer;
}

.border-bottom-table {
  border-bottom: 1px solid #28363e;
}
/* modal */
.buttonSuccess {
  font-size: 16px !important;
  font-weight: 700 !important;
  font-family: "Noto Sans Thai" !important;
}
.form-control {
  color: #09BE8B !important;
  outline: none;
}
.modal-title {
  color: rgb(255, 255, 255);
  font-size: 18px;
  font-weight: 600;
  font-family: "Noto Sans Thai" !important;
  line-height: 28px;
}
.text-grey {
  color: rgb(255, 255, 255);
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.text-yellow {
  color: #09BE8B;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.correct-button {
  float: right;
  clear: both;
}
.text-dark-grey {
  color: #9bacb6;
  line-height: 24px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Sarabun" !important;
}
.borderBottom-in-modal {
  border-bottom: 1px solid #28363e;
}
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}
.modal-content {
  width: 100% !important;
  background-color: #141b1f !important;
}
.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}
.modal-dialog {
  background-color: #141b1f !important;
  max-width: 480px !important;
  border-radius: 8px;
}
.btn-cancel {
  background-color: #191b1c;
}
.button-confirm {
  display: flex;
  justify-content: center;
  margin-left: 15px;
}
ul {
  list-style: none;
  color: #9bacb6 !important;
}
ul li::before {
  content: "\2022";
  color: #9bacb6 !important;
  font-weight: bold;
  display: inline-block;
  width: 1em;
  margin-left: -1em;
}

/* otp modal */
.otp-input {
  width: 44px !important;
  height: 56px !important;
  border-radius: 4px !important;
  margin-left: 16px !important;
  text-align: center;
}
/* otp modal end */

/* modal otp finish succes */
.otp-success {
  max-width: 480px !important;
}
.otp-error {
  max-width: 409px !important;
}
.image-tick-modal {
  background-color: #277c44;
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.image-cross-modal {
  background-color: linear-gradient(360deg, #f72000 0%, #ee5d6d 100%);
  border-radius: 50%;
  height: auto;
  width: 100%;
  max-width: 16%;
  padding: 11px;
}
.checkTick {
  padding-top: 100px;
}
.success-message {
  padding-top: 24px;
  padding-bottom: 40px;
}
.finisButton-SuccesOtp {
  padding-bottom: 100px;
}
/* modal otp finish succes end */

/* // %%%  scroller  start %%%% */

.scroller {
  height: 400px;
  overflow-y: scroll;
}

/* width */
::-webkit-scrollbar {
  width: 5px;
}
/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #28363e;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #3c515d;
  border-radius: 10px;
}
.pinInput-width {
  width: 240px !important;
}
.SurnameWidth {
  width: 280px !important;
}
/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #192126;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

@media only screen and (max-device-width: 768px) {
  .table-text {
    font-size: 10px;
  }
  .table-text-white {
    font-size: 10px;
  }

  .table-text-yellow {
    font-size: 10px;
  }
}
</style>
