<template>
  <div>
    <b-modal class="cp2" id="cp2" :hide-footer="true" :hide-header="true">
      <div>
        <h2>เลือกสี</h2>
        <span @click="$bvModal.hide('cp2')" class="close"
          ><svg
            width="14"
            height="15"
            viewBox="0 0 14 15"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z"
              fill="#677F8E"
            />
          </svg>
        </span>
        <div class="d-flex justify-content-center" style="margin-top: 52px">
          <Rectangles @change-color="changeColor" />
        </div>
        <div class="text-center">
          <button class="cp2-b"
           @click="$bvModal.hide('cp2');
                   $bvModal.show('cp1')">
            เลือกสีอื่น
          </button>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import Rectangles from "./Rectangles.vue";
export default {
  components: {
    Rectangles,
  },
  props: [ "send-color" ],
  data() {
    return {};
  },
  methods:{
    changeColor(color){
      this.$emit("send-color",color);
    }
  }
};
</script>
<style lang="scss">
#cp2___BV_modal_content_ {
  width: 480px;
  height: 446px;
  margin-top: 76px !important;
}
#cp2___BV_modal_body_ {
  .close {
    position: absolute;
    top: 28px;
    right: 30px;
    cursor: pointer;
  }
  h2 {
    font-family: "Noto Sans Thai";
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 27px;
    color: #d6dde1;
    text-align: center;
    margin-top: 8px;
    margin-bottom: 32px;
  }
  .cp2-b {
    width: 294px;
    height: 40px;
    background: #09BE8B;
    border-radius: 4px;
    font-family: "Sarabun";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 24px;
    color: #0a0d10;
    margin-top: 80px;
  }
}
</style>
